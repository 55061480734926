import styles from './Sales.module.css';

import { Card } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
// sections
// mock
import SalesDetails from './SalesDetails';
import { useEffect, useState } from 'react';
import { useLayoutEffect } from 'react';
import GlobalModal from 'src/Modals/GlobalModal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useAuth } from 'src/hooks/useAuth';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import Filter1 from 'src/pages/Filter/Filter1';
import LiveLocation from 'src/pages/LiveLocation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import moment from 'moment';
import { BallTriangle } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';
import bus from 'src/bus';
import { downloadFile2 } from 'src/utils';
import { Pagination } from 'src/components';

// ----------------------------------------------------------------------
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const TABLE_HEAD = [
  { id: 'sn', label: 'S/N', alignRight: false },
  { id: 'customerName', label: 'Customer Name', alignRight: false },
  { id: 'productUnit', label: 'Product Purchased', alignRight: false },
  // { id: 'customerLocation', label: 'Location', alignRight: false },
  { id: 'total discount', label: 'Total Discount', alignRight: false },
  { id: 'payment type', label: 'Payment Type', alignRight: false },
  { id: 'order ID', label: 'Order ID', alignRight: false },
  { id: 'price', label: 'Total Price', alignRight: false },
  { id: 'created_at', label: 'Date Purchased', alignRight: false },
  { id: 'patronage', label: 'Patronage ', alignRight: false },
];

// ----------------------------------------------------------------------

// ptvs=PAGE TO VIEW (SALES)

let app_view = localStorage.getItem('ptvs') ?? 0;
export default function SalesRetailPage() {
  const { data } = useAuth();
  const [appView] = useState(app_view);
  const [category, setCategory] = useState(1);
  const [showDiv] = useState(data.merchant_type === 'brand');
  const navigate = useNavigate();
  // const dropdownFilters = useDropDowns()
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dataCount, setDataCount] = useState({
    total_products: {},
    total_sales: {},
  });
  const [isPending, setIsPending] = useState(false);
  const [brandPending, setBrandPending] = useState(false);
  const [salesData, setSalesData] = useState([]);
  const [searchvalue, setSearchvalue] = useState([]);
  const [categoryPagination, setCategoryPagination] = useState('');
  const notifyError = (err) => toast.error(err);
  const [tooltipData, setToolTipData] = useState({ product_items: [] });
  const [tooltipLoading, setToolTipLoading] = useState(false);
  const [filterSearch, setFilterSearch] = useState({
    location: '',
    min_price: 0,
    min_order: 0,
    max_price: 0,
    max_order: 0,
    min_patronage: 0,
    max_patronage: 0,
    dateFrom: '',
    dateTo: '',
    period: '',
  });
  const [dataFilter, setDataFilter] = useState('today');
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [exporting, setExporting] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
    setFilterSearch({
      location: '',
      min_price: 0,
      min_order: 0,
      max_price: 0,
      max_order: 0,
      min_patronage: 0,
      max_patronage: 0,
      dateFrom: null,
      dateTo: null,
    });
    fetchData();
  };
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleFilterSearch = (key, value) => {
    setFilterSearch((formValues) => ({
      ...formValues,
      [key]: value,
    }));
  };

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvs');
    },
    []
  );

  const getHoverProduct = (id) => {
    setToolTipLoading(true);
    setToolTipData({ product_items: [] });
    axiosInstance
      .get(`/shop/sale/get-hover/${id}`)
      .then((res) => {
        if (res.data.data) {
          setToolTipData(res.data.data || { product_items: [] });
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Something went wrong , please try again later');
        }
      })
      .finally(() => setToolTipLoading(false));
  };

  const handleViewClick = (d) => {
    localStorage.setItem('sales_details_shop', JSON.stringify(d));
    navigate('/app/sales_details_shop');
    // handle view click action
  };

  const handleChange = (/* args passed from the child component */) => {
    handleViewClick(0);
  };
  const processLocation = (e) => {};

  const searchFilter = (e) => {
    setSearchvalue(e.target.value);
  };

  const salesTotalCount = () => {
    setBrandPending(true);
    handleClose(false);
    setModalOpen(false);
    axiosInstance
      .get('/shop/sale/get/card?period=' + dataFilter)
      .then((res) => {
        if (res.status < 300) {
          setDataCount(res.data.data);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch subscriptions, try again later');
        }
      })
      .finally(() => setBrandPending(false));
  };

  const fetchData = async (page = 1, pageSize = 10, file = null) => {
    let filter_payload = {};
    if (filterSearch.min_price) {
      filter_payload.min_price = filterSearch.min_price;
    }
    if (filterSearch.max_price) {
      filter_payload.max_price = filterSearch.max_price;
    }
    if (searchvalue !== '') {
      filter_payload.search = searchvalue;
    }
    if (filterSearch.location !== '') {
      filter_payload.location = filterSearch.location;
    }
    if (filterSearch.min_order !== '') {
      filter_payload.min_order = filterSearch.min_order;
    }
    if (filterSearch.max_order !== '') {
      filter_payload.max_order = filterSearch.max_order;
    }
    if (filterSearch.min_patronage !== '') {
      filter_payload.min_patronage = filterSearch.min_patronage;
    }
    if (filterSearch.max_patronage !== '') {
      filter_payload.max_patronage = filterSearch.max_patronage;
    }
    if (endDate && endDate !== '') {
      filter_payload.dateTo = endDate;
    }
    if (startDate && startDate !== '') {
      filter_payload.dateFrom = startDate;
    }
    if (file) {
      setExporting(true);
      let queryParams = new URLSearchParams({
        page,
        pageSize,
        ...filter_payload,
        export_type: file,
      }).toString();

      try {
        await downloadFile2('All Sales', `/shop/sale/get?${queryParams}`, file);
      } finally {
        // alert()
        setExporting(false);
      }
      return;
    }

    setIsPending(true);
    axiosInstance
      .get('/shop/sale/get?', {
        params: {
          page,
          pageSize,
          ...filter_payload,
        },
      })
      .then((res) => {
        if (res.status < 300) {
          setSalesData(res.data.data.data);
          setModalOpen(false);
        }
        setStartDate(null);
        setEndDate(null);
        setCategoryPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch sales!');
        }
      })
      .finally(() => setIsPending(false));
  };

  useEffect(() => {
    bus.on('dataFilter', (el) => {
      setDataFilter(el);
    });
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchvalue, dataFilter]);

  useEffect(() => {
    salesTotalCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter]);

  const getInitials = (val) => {
    const splitName = val?.full_name?.split(' ');
    const initials = splitName
      ?.map((name) => name.charAt(0))
      .join('')
      .toUpperCase();
    return initials;
  };

  return Number(appView) === 0 ? (
    <>
      <div className="wrapper mt-4 pt-2 px-2">
        <div className="header d-block d-md-none mb-4">
          <h4 className={`mb-0 pb-0`}>Sales</h4>
          <p style={{ fontSize: '14px' }} className={`mb-0 pb-0`}>
            Keep track of your sales
          </p>
        </div>
        <div className={`${styles.analytics}  `}>
          <Filter1 width="100%" />
          <div className={`row`}>
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Total Product Sold</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        {dataCount.total_products?.current_total_products_sold
                          ? Number(
                              dataCount.total_products
                                ?.current_total_products_sold
                            ).toLocaleString()
                          : 0}
                      </span>
                      <small className="ml-1">
                        from
                        {dataCount.total_products?.previous_total_products_sold
                          ? Number(
                              dataCount.total_products
                                ?.previous_total_products_sold
                            ).toLocaleString()
                          : 0}
                      </small>
                    </div>
                    {Number(
                      dataCount.total_products?.current_total_products_sold
                    ).toLocaleString() >=
                    Number(
                      dataCount.total_products?.previous_total_products_sold
                    ).toLocaleString() ? (
                      <div className={styles.percent}>
                        <img src="/assets/icons/up.svg" alt="" />
                        <small className="pl-1">
                          {
                            dataCount.total_products
                              ?.total_products_sold_percent
                          }
                          %
                        </small>
                      </div>
                    ) : (
                      <div className={`${styles.percent2}`}>
                        <img src="/assets/icons/downr.svg" alt="" />
                        <small className="pl-1">
                          {dataCount.total_products
                            ? Math.abs(
                                dataCount.total_products
                                  ?.total_products_sold_percent
                              )
                            : 0}
                          %
                        </small>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Total Discount Value</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        ₦{' '}
                        {dataCount?.total_discount?.current_total_discount_value
                          ? Number(
                              dataCount.total_discount
                                ?.current_total_discount_value
                            ).toLocaleString()
                          : 0}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Total Sales</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        ₦{' '}
                        {dataCount.total_sales.current_total_sales
                          ? Number(
                              dataCount.total_sales.current_total_sales
                            ).toLocaleString()
                          : 0}
                      </span>
                      <small className="ml-1">
                        from ₦
                        {dataCount.total_sales.previous_total_products_sold
                          ? Number(
                              dataCount.total_sales.previous_total_products_sold
                            ).toLocaleString()
                          : 0}
                      </small>
                    </div>
                    <div>
                      {Number(
                        dataCount.total_sales.current_total_sales
                      ).toLocaleString() >=
                      Number(
                        dataCount.total_sales.previous_total_products_sold
                      ).toLocaleString() ? (
                        <div className={styles.percent}>
                          <img src="/assets/icons/up.svg" alt="" />
                          <small className="pl-1">
                            {dataCount.total_sales.total_sales_sold_percent}%
                          </small>
                        </div>
                      ) : (
                        <div className={`${styles.percent2}`}>
                          <img src="/assets/icons/downr.svg" alt="" />
                          <small className="pl-1">
                            {dataCount.total_sales
                              ? Math.abs(
                                  dataCount.total_sales.total_sales_sold_percent
                                )
                              : 0}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-md-3 col-6`}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={searchvalue}
                onChange={searchFilter}
                placeholder="Search"
                style={{
                  textIndent: '25px',
                  width: '100%',
                }}
                className=" form-control"
              />
            </div>
            <div className="col-md-9 col-6  text-right">
              <button
                className="btn border"
                onClick={() => setModalOpen(true)}
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px',
                }}
              >
                <img
                  className=""
                  style={{
                    display: 'inline',
                    width: '28%',
                  }}
                  src="/assets/icons/filterlines.svg"
                  alt=""
                />
                <span className="d-none d-md-inline-block">&nbsp;</span>
                <small className="d-none d-md-inline-block">Filters</small>
              </button>
            </div>
          </div>
        </div>
        <Card
          style={{
            marginTop: '20px',
            borderRadius: '10px',
          }}
          className="p-0"
        >
          <div className="p-3 ">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">All sales</h5>
                <small
                  style={{
                    fontWeight: '200',
                    fontSize: '12px',
                    color: '#4b5563',
                  }}
                >
                  A list of all your business sales
                </small>
              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`${styles.export_btn} btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: '8px',
                      }}
                    >
                      <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                      {!exporting && <span>Export</span>}
                      {exporting && (
                        <CircularProgress
                          style={{
                            color: 'white',
                            display: 'inline-block',
                          }}
                          size="1rem"
                        />
                      )}
                    </span>
                    &nbsp; &nbsp;
                    <span
                      style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <img src="/assets/icons/arrowDown.svg" alt="" />
                    </span>
                  </button>
                  <div
                    className="dropdown-menu drop-left"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      onClick={() => fetchData(currentPage, 200000, 'csv')}
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      CSV
                    </button>
                    <button
                      onClick={() => fetchData(currentPage, 200000, 'excel')}
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      Excel
                    </button>
                    <button
                      onClick={() => fetchData(currentPage, 200000, 'pdf')}
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`pt-3 pb-3 pl-0 pr-0`}>
              <table
                className={`${styles.table} table-hover table-striped`}
                style={{ minWidth: '100em' }}
              >
                <thead className={`${styles.thead}`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      let sIndex = i < 1 ? 1 : false;
                      return (
                        <th className={`text-muted `}>
                          {el.label}{' '}
                          {sIndex && (
                            <span style={{ cursor: 'pointer' }}>
                              <img
                                className="pl-2"
                                src="/assets/icons/down.svg"
                                alt=""
                              />
                            </span>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {!isPending &&
                    salesData?.length >= 1 &&
                    salesData?.map((el, i) => (
                      <tr className={`${styles.tr}`}>
                        <td>
                          <span className="ml-2">
                            {(currentPage - 1) * itemsPerPage + i + 1}
                          </span>
                        </td>
                        <td onClick={() => handleViewClick(el)}>
                          <div>
                            {!el?.image && (
                              <div
                                className="float-md-left mr-2 initials"
                                style={{
                                  borderRadius: '50%',
                                  width: '40px',
                                  height: '40px',
                                  backgroundColor: 'whitesmoke',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                {getInitials(el)}
                              </div>
                            )}
                            {el?.image && (
                              <img
                                className="float-md-left mr-2"
                                src={el?.image}
                                alt=""
                                style={{
                                  borderRadius: '50%',
                                  width: '40px',
                                  height: '40px',
                                }}
                              />
                            )}
                            <div className=" ">
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: '14px',
                                }}
                              >
                                <strong>{el?.full_name}</strong>
                              </p>
                              <span style={{ fontSize: '10px' }}>
                                {el?.transaction_id}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p className="">
                            <BootstrapTooltip
                              title={
                                <div>
                                  {!tooltipLoading &&
                                    tooltipData.product_items.length > 0 &&
                                    tooltipData.product_items.map(
                                      (tooltip, ind) => (
                                        <span key={ind} className="mr-1">
                                          {tooltip.product_name},
                                        </span>
                                      )
                                    )}
                                  {!tooltipLoading &&
                                    tooltipData.product_items.length < 1 && (
                                      <span>Empty</span>
                                    )}
                                  {tooltipLoading && <span>Loading</span>}
                                </div>
                              }
                            >
                              <span
                                onMouseEnter={() => getHoverProduct(el?._id)}
                              >
                                {el?.product_items}
                              </span>
                            </BootstrapTooltip>
                          </p>
                        </td>
                        {/* <td>
                            {el.location?.delivery_address}
                        </td> */}

                        <td>
                          ₦{' '}
                          {el?.total_discount || 0
                            ? Number(el?.total_discount || 0).toLocaleString()
                            : 'Unknown'}
                        </td>

                        <td>{el?.payment_method || 0}</td>

                        <td>{el?.order_number || 0}</td>
                        <td>
                          ₦{' '}
                          {el?.total_price
                            ? Number(el?.total_price).toLocaleString()
                            : 0}
                        </td>
                        <td>{moment(el?.date_ordered).format('DD/MM/YYYY')}</td>
                        <td>{el?.patronage}</td>
                        <td>
                          <div className="dropleft ">
                            <span
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              className={`${styles.action_button} px-3 py-2`}
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <img src="/assets/icons/action.svg" alt="" />
                            </span>
                            <div
                              className="dropdown-menu drop-left"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <button
                                type="button"
                                onClick={() => handleViewClick(el)}
                                className="rss dropdown-item btn border-0"
                              >
                                View
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!salesData.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={categoryPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#FFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>
          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                {/* <div className="mb-3">
                  <LiveLocation
                    label='Location'
                    passData={processLocation}
                  />
                </div> */}
                {showDiv && (
                  <div className="mb-3">
                    <label htmlFor="cat">Category</label>
                    <Select
                      labelId="s"
                      id="cat"
                      fullWidth
                      size="small"
                      value={category}
                      required
                      onChange={handleCategoryChange}
                    >
                      <MenuItem value={1}>Select Category</MenuItem>
                    </Select>
                  </div>
                )}
                <label htmlFor="name">Date </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex align-items-center "
                    style={{
                      height: '40px',
                    }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{
                        width: '30px',
                      }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                      />
                    </div>
                  </div>
                </div>
                {!showDiv && (
                  <div>
                    <label htmlFor="patronage">Patronage</label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            id="patronage"
                            placeholder="Min"
                            size="small"
                            value={filterSearch.min_patronage}
                            name="min_patronage"
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                handleFilterSearch('min_patronage', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="px-1 d-flex align-items-center "
                        style={{
                          height: '40px',
                        }}
                      >
                        <img
                          src="/assets/icons/line.svg"
                          style={{
                            width: '30px',
                          }}
                          alt=""
                        />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            placeholder="Max "
                            size="small"
                            value={filterSearch.max_patronage}
                            name="max_patronage"
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                handleFilterSearch('max_patronage', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Price </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="price"
                        placeholder="Min"
                        size="small"
                        value={filterSearch.min_price}
                        name="min_price"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterSearch('min_price', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex align-items-center "
                    style={{
                      height: '40px',
                    }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{
                        width: '30px',
                      }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        placeholder="Max "
                        size="small"
                        value={filterSearch.max_price}
                        name="max_price"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterSearch('max_price', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                {showDiv && (
                  <div>
                    <label htmlFor="ora">Order amount </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            id="ora"
                            placeholder="Min"
                            size="small"
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                handleFilterSearch(
                                  'min_total_order',
                                  inputValue
                                );
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="px-1 d-flex align-items-center"
                        style={{
                          height: '40px',
                        }}
                      >
                        <img
                          src="/assets/icons/line.svg"
                          style={{
                            width: '30px',
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        className=""
                        style={{
                          width: '100%',
                        }}
                      >
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            placeholder="Max "
                            size="small"
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                handleFilterSearch(
                                  'max_total_order',
                                  inputValue
                                );
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        value={filterSearch.min_order}
                        name="min_order"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterSearch('min_order', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex align-items-center "
                    style={{
                      height: '40px',
                    }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{
                        width: '30px',
                      }}
                      alt=""
                    />
                  </div>
                  <div
                    className=""
                    style={{
                      width: '100%',
                    }}
                  >
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        placeholder="Max "
                        size="small"
                        value={filterSearch.max_order}
                        name="max_order"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterSearch('max_order', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={() => fetchData()}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
    </>
  ) : (
    <SalesDetails key="1" onChange={handleChange} />
  );
}
