import { createContext } from 'react';

export const BaseUrlContext = createContext();

export function BaseUrlContextProvider({ children }) {
  // https://retailxpress.herokuapp.com/api/v1
  //http://localhost:1445/api/v1
  // http://143.198.155.229/api/v1
  // https://admin.retailxpress.africa/api/v1
  // https://stageback.flexdeals.com.ng/api/v1
  // https://admin.flexdeals.com.ng/api/v1

  const API_BASE_URL = 'https://admin.flexdeals.com.ng/api/v1';

  return (
    <BaseUrlContext.Provider value={API_BASE_URL}>
      {children}
    </BaseUrlContext.Provider>
  );
}
