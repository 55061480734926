// ----------------------------------------------------------------------
import styles from '../index.module.css';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Card, Avatar } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

// mock
import { useLayoutEffect } from 'react';
import GlobalModal from 'src/Modals/GlobalModal';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useAuth } from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useCategories } from 'src/hooks/useCategories';
import { useProducts } from 'src/hooks/useProducts';
import { useDropDowns } from 'src/hooks/useDropDowns';
import Filter1 from 'src/pages/Filter/Filter1';
import toast from 'react-hot-toast';
import bus from 'src/bus';
import axiosInstance from 'src/axiosInstance';
import { BallTriangle } from 'react-loader-spinner';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { downloadFile2, shortenText } from 'src/utils';
import LiveLocation from 'src/pages/LiveLocation';
import { Pagination } from 'src/components';
// ----------------------------------------------------------------------
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
// ptvp=PAGE TO VIEW (PRODUCT)
export default function Shoppers() {
  const { data } = useAuth();
  const navigate = useNavigate();
  const categories = useCategories();
  const productLists = useProducts();
  const dropdownFilters = useDropDowns();
  const [modalOpen, setModalOpen] = useState(false);
  const [showDiv, setShowDiv] = useState(data.merchant_type === 'brand');
  // const [product, setProduct] = useState(1);
  const [productsAdAdded, setProductsAdded] = useState([]);
  const [category, setCategory] = useState(1);
  const [paymentMode, setPaymentMode] = useState(1);
  const [selectedFilter, setValue] = useState(dropdownFilters[0]?.name);
  const [selectedFilter1, setValue1] = useState(dropdownFilters[0]?.name);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const [submitFilter, setSubmitFilter] = useState(false);
  const handleClickList = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClickList1 = (e) => {
    setAnchorEl1(e.currentTarget);
  };
  const handleSelectFilter = (val, i) => {
    switch (i) {
      case 0:
        setAnchorEl(null);
        setValue(val);

        break;
      case 1:
        setAnchorEl1(null);
        setValue1(val);
        break;

      default:
        break;
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvp');

      // Your code here.
    },
    []
  );

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handlePaymentMode = (event) => {
    setPaymentMode(event.target.value);
  };
  const handleProductChange = (event) => {
    setProduct(event.target.value);
    let data = productsAdAdded;
    let val = event.target.value;
    if (val != 1) {
      if (data.includes(val) == false) {
        data.push(val);
        setProductsAdded(data);
      }
    }
  };

  const removeFromList = (index) => {
    let data = [...productsAdAdded];
    data = data.filter((_, i) => i !== index);
    if (data['length'] === 0) setProduct(1);
    setProductsAdded(data);
  };

  const handleViewClick = (d) => {
    navigate('/app/purchase_details_s/' + d);
    // handle view click action
  };

  // pagination
  const [purchaseData, setPurchaseData] = useState([]);
  const [analyticsDataMyOrder, setAnalyticsDataMyOrder] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [purchasePagination, setPurchasePagination] = useState({ total: 0 });
  const notifyError = (err) => toast.error(err);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  //filter fields

  const [location, setLocation] = useState('');
  const [product, setProduct] = useState('select');
  const [status, setStatus] = useState('select');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [minOrderUnit, setMinOrderUnit] = useState('');
  const [maxOrderUnit, setMaxOrderUnit] = useState('');
  const [searchvalue, setSearch] = useState('');
  const [filter, setFilter] = useState(false);
  const [filterSearch, setFilterSearch] = useState({
    filterLocation: '',
    category: '',
    filterStatus: '',
    startDateFilter: '',
    endDateFilter: '',
    priceFilter: '',
    priceToFilter: '',
    orderUnitFilter: '',
    orderUnitToFilter: '',
  });

  const searchFilter = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    fetchData();
  }, [filter, searchvalue, submitFilter]);

  // useEffect(() => {
  //  console.log(purchaseData);
  // }, [purchaseData]);

  bus.on('reload', () => {
    fetchData();
  });

  const processLocation = (e) => {
    handleFilterStatus('filterLocation', e.formatted_address);
  };

  const handleFilterStatus = (key, value) => {
    setFilterSearch((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleSubmitFilter = () => {
    handleClose();
    setSubmitFilter(!submitFilter);
  };

  const handleDownloadFile = async (extension) => {
    try {
      const result = `/admin/purchases/get-shoppers-purchases?page=${purchasePagination?.currentPage}&perpage=${purchasePagination?.total}&export_type=${extension}`;
      await downloadFile2('Shoppers Purchases', result, extension);
    } catch (err) {
      notifyError(`Unble to export ${extension} file`);
    }
  };
  useEffect(() => {
    fetchData();
  }, [filter, searchvalue, submitFilter]);

  bus.on('reload', () => {
    fetchData();
  });

  //Getting Table Data
  const fetchData = (page = 1, perpage = 10) => {
    let filter_payload = {};
    if (filterSearch.priceToFilter != '') {
      filter_payload.max_price = filterSearch.priceToFilter;
    }
    if (filterSearch.priceFilter != '') {
      filter_payload.min_price = filterSearch.priceFilter;
    }
    if (
      filterSearch.filterStatus != 'select' &&
      filterSearch.filterStatus != 'all'
    ) {
      filter_payload.status = filterSearch.filterStatus;
    }
    if (searchvalue != '') {
      filter_payload.search = searchvalue;
    }
    if (startDate != '') {
      filter_payload.dateStart = startDate;
    }
    if (endDate != '') {
      filter_payload.dateEnd = endDate;
    }
    if (filterSearch.orderUnitToFilter != '') {
      filter_payload.max_order_unit = filterSearch.orderUnitToFilter;
    }
    if (filterSearch.orderUnitFilter != '') {
      filter_payload.min_order_unit = filterSearch.orderUnitFilter;
    }
    if (filterSearch.category != '') {
      filter_payload.category = filterSearch.category;
    }
    if (filterSearch.filterLocation != '') {
      filter_payload.location = filterSearch.filterLocation;
    }
    setIsPending(true);
    axiosInstance
      .get('/admin/purchases/get-shoppers-purchases', {
        params: {
          page,
          perpage,
          ...filter_payload,
        },
      })
      .then((res) => {
        console.log(res.data.data.data);
        setPurchaseData(res.data.data.data);
        setPurchasePagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch purchases, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  return (
    <>
      <div className="pt-4 px-2">
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-md-3 col-6  `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={searchvalue}
                onChange={searchFilter}
                placeholder="Search"
                style={{
                  textIndent: '25px',
                  width: '100%',
                }}
                className=" form-control"
              />
            </div>
            <div className="col-md-9 col-6 text-right">
              <button
                onClick={() => setModalOpen(true)}
                className="btn  border"
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px',
                }}
              >
                <img
                  className=""
                  style={{
                    display: 'inline',
                    width: '28%',
                  }}
                  src="/assets/icons/filterlines.svg"
                  alt=""
                />
                &nbsp;
                <small className="d-none d-md-inline-block">Filters</small>
              </button>
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: '50px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-4 ">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">Shoppers Purchase</h5>
                <small style={{ fontWeight: '200', fontSize: '14px' }}>
                  Details of orders placed by shoppers on Flexdeals
                </small>
              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`${styles.export_btn} btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: '8px',
                      }}
                    >
                      <img src="/assets/icons/downloadblue.svg" alt="" /> &nbsp;
                      Export
                    </span>
                    &nbsp; &nbsp;
                    <span
                      style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <img src="/assets/icons/chevron-downblue.svg" alt="" />
                    </span>
                  </button>
                  <div
                    className="dropdown-menu drop-left"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('csv')}
                    >
                      CSV
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('excel')}
                    >
                      Excel
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('pdf')}
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`table_responsive pt-3 pb-3 pl-0 pr-0`}>
              <table
                className={`${styles.table} table-hover table-striped`}
                style={{ minWidth: '100em' }}
              >
                <thead className={`${styles.thead}`}>
                  <tr>
                    <th className="text-muted">S/N</th>
                    <th
                      style={{
                        width: '20%',
                      }}
                      className="text-muted"
                    >
                      Customer Name
                      <img
                        className="pl-2"
                        src="/assets/icons/down.svg"
                        alt=""
                      />
                    </th>
                    <th className="text-muted">Order Unit</th>
                    <th className="text-muted">Brands</th>
                    <th className="text-muted">Status</th>
                    <th className="text-muted">Store Location</th>

                    <th className="text-muted">Discount Value (₦)</th>
                    <th className="text-muted">Payment Method</th>

                    <th className="text-muted">Price (₦)</th>
                    <th className="text-muted">Date Ordered</th>
                    <th className="text-muted">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!isPending &&
                    purchaseData?.map((el, i) => (
                      <tr key={i} className={`${styles.tr} `}>
                        <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                        <td>
                          <Avatar
                            className={`${styles.avatrr} mr-3 float-md-left`}
                            alt=""
                            src={el?.profile_image}
                          />
                          <div className=" ">
                            <p className="mb-0" style={{ fontSize: '14px' }}>
                              {el?.full_name}
                            </p>
                            <span style={{ fontSize: '12px' }}>
                              {el?.email}
                            </span>
                          </div>
                        </td>
                        <td>
                          <p>{el?.orders}</p>
                        </td>
                        <td>
                          <div className="row">
                            <Avatar
                              className={`${styles.avatrr} mr-3 float-md-left`}
                              alt=""
                              src={el?.brands[0]?.business_logo}
                            />
                            <div className="">
                              <p className="mb-0" style={{ fontSize: '14px' }}>
                                {el?.brands[0]?.business_name}
                              </p>
                              <span style={{ fontSize: '12px' }}>
                                {el?.brands[0]?.brand_id}
                              </span>
                            </div>
                            {el?.brands?.length > 1 && (
                              <div
                                className="text-center p-2"
                                style={{ cursor: 'pointer' }}
                              >
                                <BootstrapTooltip
                                  title={
                                    <div>
                                      {el?.brands.length > 0 &&
                                        el?.brands
                                          .slice(1)
                                          .map((tooltip, ind) => (
                                            <span key={ind} className="mr-1">
                                              {tooltip.business_name},
                                            </span>
                                          ))}
                                    </div>
                                  }
                                >
                                  <span style={{ fontSize: '12px' }}>
                                    +{el?.brands?.length - 1}
                                  </span>
                                </BootstrapTooltip>
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <span
                            className={`${
                              el?.status === 'pending'
                                ? styles.pending
                                : styles.customer
                            }`}
                          >
                            {el?.status}
                          </span>
                        </td>
                        <td>
                          {el?.location ? shortenText(el.location) : '---'}
                        </td>

                        <td>{el?.total_discount || 0}</td>
                        <td>{el?.payment_method}</td>

                        <td>{Number(el?.price).toLocaleString()}</td>
                        <td>{moment(el?.date_ordered).format('DD/MM/YYYY')}</td>
                        {/* <td>
                        2
                      </td> */}
                        <td>
                          &nbsp; &nbsp; &nbsp; &nbsp;
                          <div className="d-inline-block dropleft ">
                            <span
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              className={`${styles.action_button} px-3 py-2`}
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <img src="/assets/icons/action.svg" alt="" />
                            </span>

                            <div
                              className="dropdown-menu drop-left"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <button
                                type="button"
                                onClick={() => handleViewClick(el?._id)}
                                className="rss dropdown-item btn border-0"
                              >
                                View
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!purchaseData.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={purchasePagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#FFFFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <LiveLocation label="Location" passData={processLocation} />
                </div>
                <div className="mb-3">
                  <label className="font-weight-boldd" htmlFor="name">
                    Status
                  </label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={filterSearch.category}
                    defaultValue={'select'}
                    name="category"
                    required
                    onChange={(e) =>
                      handleFilterStatus('category', e.target.value)
                    }
                  >
                    <MenuItem value="select">Select Status</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                  </Select>
                </div>

                <div className="mb-3">
                  <label className="font-weight-boldd" htmlFor="name">
                    Payment Method
                  </label>
                  <Select
                    labelId="mod"
                    id="mod"
                    fullWidth
                    size="small"
                    name="filterStatus"
                    value={filterSearch.filterStatus}
                    defaultValue={'select'}
                    required
                    onChange={(e) =>
                      handleFilterStatus('filterStatus', e.target.value)
                    }
                  >
                    <MenuItem value="select">Select Payment Method</MenuItem>
                    <MenuItem value="cash">Cash</MenuItem>
                    <MenuItem value="card">Card</MenuItem>
                    <MenuItem value="transfer">Transfer</MenuItem>
                    <MenuItem value="wallet">Wallet</MenuItem>
                  </Select>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Date </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
                {showDiv && (
                  <div>
                    <label htmlFor="ora">Total amount </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            id="price"
                            placeholder="Min"
                            size="small"
                            name="priceFilter"
                            value={filterSearch.priceFilter}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) > 0
                              ) {
                                handleFilterStatus('priceFilter', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="px-1 d-flex   align-items-center "
                        style={{ height: '40px' }}
                      >
                        <img
                          src="/assets/icons/line.svg"
                          style={{ width: '30px' }}
                          alt=""
                        />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            placeholder="Max "
                            size="small"
                            name="priceToFilter"
                            value={filterSearch.priceToFilter}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                handleFilterStatus('priceToFilter', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <label htmlFor="name">Date </label>

                    <div className=" d-flex">
                      <div className="" style={{ width: '70%' }}>
                        <div className="mb-3">
                          <DatePicker
                            showIcon
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            placeholderText="Start"
                            required
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                      <div
                        className="px-1 d-sflex   align-items-center "
                        style={{ heigsht: '40px' }}
                      >
                        {/* sss */}
                        <img
                          src="/assets/icons/line.svg"
                          style={{ width: '30px' }}
                          alt=""
                        />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <DatePicker
                            showIcon
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            placeholderText="End"
                            required
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!showDiv && (
                  <div>
                    <label htmlFor="patronage">Price </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            id="price"
                            placeholder="Min"
                            size="small"
                            name="priceFilter"
                            value={filterSearch.priceFilter}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                handleFilterStatus('priceFilter', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="px-1 d-flex   align-items-center "
                        style={{ height: '40px' }}
                      >
                        <img
                          src="/assets/icons/line.svg"
                          style={{ width: '30px' }}
                          alt=""
                        />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            placeholder="Max "
                            size="small"
                            name="priceToFilter"
                            value={filterSearch.priceToFilter}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                handleFilterStatus('priceToFilter', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        name="orderUnitFilter"
                        value={filterSearch.orderUnitFilter}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterStatus('orderUnitFilter', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        name="orderUnitToFilter"
                        value={filterSearch.orderUnitToFilter}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterStatus('orderUnitToFilter', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={handleSubmitFilter}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
    </>
  );
}
