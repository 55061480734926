import styles from "../Sales.module.css"

import {
  Card,
} from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import SalesDetails from "../SalesDetails"
import { useState } from "react"
import { useLayoutEffect } from "react";
import GlobalModal from "src/Modals/GlobalModal";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useAuth } from "src/hooks/useAuth";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import Menu from '@mui/material/Menu';
import { useDropDowns } from "src/hooks/useDropDowns";
import { useCategories } from "src/hooks/useCategories";
import { useProducts } from "src/hooks/useProducts";
import LiveLocation from "src/pages/LiveLocation";

// ----------------------------------------------------------------------
import Filter1 from "src/pages/Filter/Filter1";

const TABLE_HEAD = [
  { id: 'customerName', label: 'Merchant', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'productUnit', label: 'Product Purchased', alignRight: false },
  { id: 'customerLocation', label: 'Customer Location', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'created_at', label: 'Date Purchased', alignRight: false },
  { id: 'created_at', label: 'Total Quantity', alignRight: false },
  { id: 'created_at', label: 'Total Amount', alignRight: false },
];
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
// ----------------------------------------------------------------------

function changeView(d) {
  localStorage.setItem('ptvs', d);
}

// ptvs=PAGE TO VIEW (SALES)

let app_view = localStorage.getItem('ptvs') ?? 0;
export default function ShopOwners() {
  const { data } = useAuth();
  const dropdownFilters = useDropDowns()
  const categories = useCategories();
  const productLists = useProducts()


  const [appView, setAppview] = useState(app_view);
  const [product, setProduct] = useState(1);
  const [productsAdAdded, setProductsAdded] = useState([]);
  const [category, setCategory] = useState('select');
  const [showDiv, setShowDiv] = useState(data.merchant_type === "brand");
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const [selectedFilter, setValue] = useState(dropdownFilters[0]?.name);
  const [selectedFilter1, setValue1] = useState(dropdownFilters[0]?.name);





  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handleProductChange = (event) => {
    setProduct(event.target.value);
    let data = productsAdAdded
    let val = event.target.value
    if (val != 1) {
      if (data.includes(val) == false) {
        data.push(val)
        setProductsAdded(data)

      }
    }
  };

  const removeFromList = (index) => {
    let data = [...productsAdAdded]
    data = data.filter((_, i) => i !== index);
    if (data['length'] === 0) setProduct(1)
    setProductsAdded(data)




  }
  const processLocation = (e) => {
    // console.log(e);
  }


  useLayoutEffect(() => () => {
    localStorage.removeItem('ptvs')
  }, [])




  const handleViewClick = (d) => {
    navigate('/app/sales_details')
    // handle view click action
  };

  const handleChange = (/* args passed from the child component */) => {
    handleViewClick(0)
  }

  const handleClickList = (e) => {
    setAnchorEl(e.currentTarget)
  }
  const handleClickList1 = (e) => {
    setAnchorEl1(e.currentTarget)
  }
  const handleSelectFilter = (val, i) => {
    switch (i) {
      case 0:
        setAnchorEl(null);
        setValue(val)

        break;
      case 1:
        setAnchorEl1(null);
        setValue1(val)
        break;

      default:
        break;
    }


  }


  return Number(appView) === 0 ? (
    <>
      <div className="pt-4">
        <div className={`${styles.analytics}  `}>
          <Filter1 width='100%' />

          <div className={`row`}>
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Total Product sold</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>70,976</span><small> from 67,000</small>
                    </div>
                    <div>
                      <div className={styles.percent}>
                        <img src="/assets/icons/up.svg" alt="" /> <small>12%</small>

                      </div>
                    </div>



                  </div>

                </div>


              </div>

            </div>
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Total Sales</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>₦ 3,206,976</span><small> from ₦ 2,907,000</small>
                    </div>
                    <div>
                      <div className={styles.percent}>
                        <img src="/assets/icons/up.svg" alt="" /> <small>12%</small>

                      </div>
                    </div>



                  </div>

                </div>


              </div>

            </div>

          </div>
        </div>
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-md-3 col-6   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input type="text" placeholder="Search" style={{ textIndent: '25px', width: '100%' }} className=" form-control" />

            </div>
            <div className="col-md-9 col-6  text-right">
              <button className="btn  border" onClick={() => setModalOpen(true)}
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px'
                }}>
                <img className="" style={{ display: 'inline', width: '17px' }} src="/assets/icons/filterlines.svg" alt="" />
                &nbsp;
                <small className="d-none d-md-inline-block">
                  Filters
                </small>
              </button>

            </div>
          </div>


        </div>

        <Card style={{ marginTop: '50px', borderRadius: '10px' }} className='p-0'>
          <div className='p-3 '>
            <div className="d-md-flex justify-content-between" >
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">
                  All sales

                </h5>
                <small style={{ fontWeight: '200', fontSize: '12px' }}>
                  Detailed Information of all Recent Sales
                </small>

              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className={`${styles.export_btn} btn m-0`}>
                    <span style={{
                      paddingTop: '8px',


                    }}>
                      <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                      Export
                    </span>
                    &nbsp;
                    &nbsp;
                    <span style={{
                      borderLeft: '1px solid white',
                      display: 'inline-block',
                      height: 'inherit',
                      paddingTop: '8px',
                      paddingLeft: '8px',
                      textAlign: 'center',

                    }}>
                      <img src="/assets/icons/arrowDown.svg" alt="" />

                    </span>


                  </button>

                  <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                    <button type="button" className="rss dropdown-item btn border-0">CSV</button>
                    <button type="button" className="rss dropdown-item btn border-0">Excel </button>
                    <button type="button" className="rss dropdown-item btn border-0">PDF </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`pt-3 pb-3 pl-0 pr-0`}  >
              <table className={`${styles.table} table-hover table-striped`} style={{ minWidth: '100em', }}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      let sIndex = i < 1 ? 1 : false;

                      return (
                        <th key={i} className="text-muted">{el.label} {sIndex && (
                          <span style={{ cursor: 'pointer' }}>
                            <img className="pl-2" src="/assets/icons/down.svg" alt="" />
                          </span>
                        )}</th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ cursor: 'pointer' }} className={`${styles.tr}`}>
                    <td onClick={() => handleViewClick(1)}>
                      <div className=" ">
                        <img
                          className="float-md-left  mr-2"
                          src={'/assets/outlet1.svg'}
                          alt=""
                          style={{
                          }}
                        />
                        <div className=" ">
                          <p className="mb-0" style={{ fontSize: '14px' }}>
                            <strong>
                              Outlet 1
                            </strong>


                          </p>
                          <span style={{ fontSize: '10px' }}>
                            1234VK


                          </span>
                        </div>

                      </div>

                    </td>
                    <td onClick={() => handleViewClick(1)}>
                      <p>
                        Mom & Pop
                      </p>
                    </td>
                    <td onClick={() => handleViewClick(1)}>
                      <p className="">
                        <BootstrapTooltip title={
                          <div>
                            Nestle Milo, Pure life table water, <br /> Maggi, Nestcafe
                          </div>
                        }>
                          <span>
                            4
                          </span>

                        </BootstrapTooltip>

                      </p>
                    </td>
                    <td onClick={() => handleViewClick(1)}>
                      <p>
                        Lagos, Nigeria

                      </p>
                    </td>
                    <td onClick={() => handleViewClick(1)}>
                      <p>
                        ₦5.63M

                      </p>
                    </td>



                    <td onClick={() => handleViewClick(1)}>
                      <p>
                        25/02/22

                      </p>
                    </td>
                    <td>
                      20
                    </td>
                    <td>
                      ₦5.63M
                    </td>
                    <td>
                      <div className="dropleft ">

                        <span
                          id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"

                          className={`${styles.action_button} px-3 py-2`}  style={{
                            cursor: 'pointer',

                          }}>
                          <img src="/assets/icons/action.svg" alt="" />

                        </span>

                        <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                          <button type="button" onClick={() => handleViewClick(1)} className="rss dropdown-item btn border-0">View</button>


                        </div>
                      </div>

                    </td>
                  </tr>

                </tbody>

              </table>

            </div>


          </Scrollbar>

          <div className="card border-0 p-0 m-0">
            <div className="card-body pt-0 mt-0">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-item-center pt-2">
                  Page 1 of <b className="pl-1"> 5</b>
                </div>
                <div className={`${styles.pagination_button_container}`}>
                  <button className="btn">Previous</button>
                  &nbsp;
                  <button className="btn">Next</button>
                </div>

              </div>

            </div>
          </div>
        </Card>

      </div>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position='absolute'
        top='50%'
        left='50%'
        transform='translate(-50%, -50%)'
        width='740px !important'
        overflow='auto'
        bgcolor='#FFFFFF'
        border='1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow='24'
        p='25px'
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5 style={{
              fontSize: '19px',
              fontWeight: '700',

            }}>Filter</h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>


          <form action="" className="mt-0 form_label">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <LiveLocation label='Location' passData={processLocation} />


                </div>

                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="ounit"
                        placeholder="Min"
                        size="small"

                      />


                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"

                      />


                    </div>

                  </div>

                </div>
                <div className="mb-3">
                  <label htmlFor="name">Category</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={category}
                    name="category"
                    placeholder="Select category"
                    required
                    onChange={handleCategoryChange}
                  >

                    <MenuItem value='select'>Select Category</MenuItem>
                    <MenuItem value='Kiosk'>Kiosk</MenuItem>
                    <MenuItem value='Neighborhood store'>Neighborhood store</MenuItem>
                    <MenuItem value='Supermarket'>Supermarket</MenuItem>
                    <MenuItem value='E-commerce store'>E-commerce store</MenuItem>
                  </Select>


                </div>


              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Price </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="price"
                        placeholder="Min"
                        size="small"

                      />


                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"

                      />


                    </div>

                  </div>

                </div>
                {
                  showDiv &&
                  <div>
                    <label htmlFor="ora">Total amount  </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type='number'
                            id="ora"
                            placeholder="Min"
                            size="small"

                          />


                        </div>

                      </div>
                      <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                        <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type='number'
                            placeholder="Max "
                            size="small"

                          />


                        </div>

                      </div>

                    </div>
                    <label htmlFor="name">Date </label>

                    <div className=" d-flex">
                      <div className="" style={{ width: '70%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type='date'
                            id="name"
                            placeholder="Start"
                            size="small"

                          />


                        </div>

                      </div>
                      <div className="px-1 d-sflex   align-items-center " style={{ heigsht: '40px' }}>
                        {/* sss */}
                        <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type='date'
                            placeholder="End "
                            size="small"

                          />


                        </div>

                      </div>

                    </div>

                  </div>
                }

                {
                  !showDiv &&
                  <div>
                    <label htmlFor="patronage">Patronage  </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type='number'
                            id="patronage"
                            placeholder="Min"
                            size="small"

                          />


                        </div>

                      </div>
                      <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                        <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type='number'
                            placeholder="Max "
                            size="small"

                          />


                        </div>

                      </div>

                    </div>

                  </div>
                }

              </div>

              {/* <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="name">Product</label> <div></div>
                  {productLists.map((el, i) => (
                    <div className="categories mr-2 mb-3" key={i}>
                      <input onClick={(e) => handleProductChange(e, el.name)} type="checkbox" value={el.name} name="" className="myCheckbox" />
                      &nbsp;
                      {el.name}


                    </div>

                  ))}


                </div>
              </div> */}
            </div>
            <div className="mb-4">
              <button type="button" onClick={handleClose} className={`${styles.modal_btn} btn btn-block`}>
                Proceed

              </button>


            </div>

          </form>


        </div>


      </GlobalModal>
    </>
  ) : <SalesDetails key={1} onChange={handleChange} />
}
