// ----------------------------------------------------------------------
import styles from './BrandOwner.module.css';
import { Card, Avatar } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Filter1 from '../Filter/Filter1';
import { BallTriangle } from 'react-loader-spinner';
import DiscountTimer from 'src/hooks/useDiscountCountDown';
import axiosInstance from 'src/axiosInstance';
import Header from 'src/layouts/dashboard/header/index';
import Nav from 'src/layouts/dashboard/nav';
import toast from 'react-hot-toast';
import bus from 'src/bus';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from 'src/hooks/useAuth';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { shortenText } from 'src/utils';
import moment from 'moment';
import Scrollbar from '../../components/scrollbar';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'sn', label: 'S/N', alignRight: false },
  { id: 'customerName', label: 'Customer Name', alignRight: false },
  { id: 'productUnit', label: 'Product Units', alignRight: false },
  { id: 'paymentMethod', label: 'Payment Method', alignRight: false },
  { id: 'totalCost', label: 'Total Cost', alignRight: false },
  { id: 'Patronage ', label: 'Patronage' },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'created_at', label: 'Date ordered', alignRight: false },
  // { id: 'Order Confirmation', label: 'Order Confirmation' },
  // { id: 'customerLocation', label: 'Location', alignRight: false },
  // { id: 'Action ', label: ' ' },
];

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  overflow: 'auto',
  overflowX: 'hidden',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  overflow: 'auto',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

export default function DashboardAppPage() {
  const navigate = useNavigate();

  function navigateTo() {
    localStorage.setItem('tab', 3);
    navigate(`/app/orders`);
  }
  const [openDialog, setDiologOpen] = useState(false);

  const handleDialogClose = () => {
    setDiologOpen(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const [openGoToCart, setopenGoToCart] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCartModal = () => setopenGoToCart(true);
  const handleModalClose = () => setOpenModal(false);
  const handleGoToCartClose = () => setopenGoToCart(false);
  const notifyError = (err) => toast.error(err);
  const { data } = useAuth();
  const [discountData, setDiscountData] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [cartPending, setCartPending] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchvalue, setSearchValue] = useState('');
  const [cartValue, setCartValue] = useState(0);
  const [orderData, setOrderData] = useState([]);
  const [tooltipData, setToolTipData] = useState({ product_items: [] });
  const [tooltipLoading, setToolTipLoading] = useState(false);
  const [trx_id, setTrx_id] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenConfirm, setModalOpenConfirm] = useState(false);
  const [modalOpenConfirm1, setModalOpenConfirm1] = useState(false);
  const [confirmOrder, setConfirmOrder] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [brandPending, setBrandPending] = useState(false);
  const [dataDetails, setDetailsData] = useState({
    balance: 0,
    account_details: {
      account_number: '',
      bank_name: '',
    },
    sales_card: {
      total_order: {
        current_order_count: 0,
        previous_order_count: 0,
        percentage_order: 0,
      },
      total_sales: {
        current_total_sales: 0,
        previous_total_sales: 0,
        percentage_sales: 0,
      },
      total_product_sold: {
        current_product_sold_count: 0,
        previous_product_sold_count: 0,
        percentage_product_sold: 0,
      },
    },
  });
  const [dataFilter, setDataFilter] = useState('today');
  const [dataItem, setdata] = useState({
    items: [],
    total: 0,
    shipping_fee: 0,
  });
  const [open1, setOpen1] = useState(false);
  const handleClose = () => setOpen1(false);
  const [weight, setWeight] = useState(20);
  const handleChange = (event) => {
    setWeight(event.target.value);
  };

  // const shuffledObjects = discountData.sort(() => Math.random() - 0.5);
  const [quantityValue, setQuantityValue] = useState('1');
  const [randomValue, setRandomValue] = useState({});
  const [selectedObject, setSelectedObject] = useState({});
  const [emittedValue, setEmittedVaue] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [categoryPagination, setCategoryPagination] = useState({ total: 0 });
  const [dataCount, setDataCount] = useState({
    order_completed: 0,
    order_abandoned: 0,
  });
  const [filterSearch, setFilterSearch] = useState({
    type: '',
    status: '',
    min_price: 0,
    max_price: 0,
    max_order_unit: 0,
    min_order_unit: 0,
    order_confirmation: '',
    payment_method: '',
    startDate: '',
    endDate: '',
    period: 'today',
  });

  // const selectRandomObject = () => {
  //   const randomIndex = Math.floor(Math.random() * discountData.length);
  //   setSelectedObject(discountData[randomIndex]);
  // };

  // const handleQuantityValue = (e) => {
  //   setQuantityValue(e.target.value)
  // }

  // const buyNow = (e) => {
  //   e.preventDefault();
  //   setCartPending(true)
  //   axiosInstance.post('/shop/cart/add-to-cart/' + randomValue.product_id, {
  //     quantity: quantityValue
  //   })
  //     .then(res => {
  //       if (res.status < 300) {
  //         setEmittedVaue(!emittedValue)
  //         handleModalClose()
  //         handleCartModal()
  //         setReload(!reload)
  //         setQuantityValue('')
  //       }
  //     }).catch(err => {
  //       notifyError(err.response.data.message)
  //     }).finally(() => {
  //       setCartPending(false)
  //     })

  // }

  const handleViewClick = (val) => {
    localStorage.setItem('order_details_info', JSON.stringify(val));
    navigate('/app/order_details_shop');
  };

  const handleSendReminderClick = (val) => {
    // handle send reminder click action
    // axiosInstance.get('/brand/order/send-reminder/'+val)
    // .then(res => {
    //   notify(res.data.message)
    // }).catch(err => {
    //   try{
    //     notifyError(err.response.data.message)
    //   } catch{
    //     notifyError('Something went wrong, Please try again later')
    //   }
    // })
  };

  useEffect(() => {
    bus.on('dashboardSearch', (val) => {
      setSearchValue(val);
    });
  }, []);

  const ordersTotalCount = () => {
    let filter_payload = {};
    if (filterSearch.period !== '') {
      filter_payload.period = filterSearch.period;
    }
    setBrandPending(true);
    handleClose(false);
    setModalOpen(false);
    let periodValue = typeof dataFilter === 'object' ? 'All time' : dataFilter;

    axiosInstance
      .get('/shop/order/customer-order-cards?period=' + periodValue)
      .then((res) => {
        // console.log(res.data.data);
        if (res.status < 300) {
          setDataCount(res.data.data);
          // setOrderRender(!orderRender)
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch subscriptions, try again later');
        }
      })
      .finally(() => setBrandPending(false));
  };

  const fetchData = (
    page = 1,
    perpage = 10
    // search = searchvalue,
    // status = '',
    // min_price = '',
    // max_price = '',
    // min_unit = '',
    // max_unit = '',
    // payment_method = ''
  ) => {
    let filter_payload = {};
    if (filterSearch.min_price) {
      filter_payload.min_price = filterSearch.min_price;
    }
    if (filterSearch.max_price) {
      filter_payload.max_price = filterSearch.max_price;
    }
    if (filterSearch.min_order_unit) {
      filter_payload.min_unit = filterSearch.min_order_unit;
    }
    if (filterSearch.max_order_unit) {
      filter_payload.max_unit = filterSearch.max_order_unit;
    }
    if (searchvalue !== '') {
      filter_payload.search = searchvalue;
    }
    if (startDate !== null) {
      filter_payload.startDate = startDate;
    }
    if (endDate !== null) {
      filter_payload.endDate = endDate;
    }
    if (
      filterSearch.order_confirmation !== '' ||
      filterSearch.order_confirmation !== 'all'
    ) {
      filter_payload.order_status = filterSearch.order_confirmation;
    }
    if (
      filterSearch.payment_method !== '' &&
      filterSearch.payment_method !== 'all'
    ) {
      filter_payload.payment_method = filterSearch.payment_method;
    }
    if (filterSearch.status !== '' && filterSearch.status !== 'all') {
      filter_payload.status = filterSearch.status;
    }
    setIsPending(true);
    handleClose(false);
    setModalOpen(false);
    axiosInstance
      .get('/shop/order/get-customer-orders?', {
        params: {
          page,
          perpage,
          ...filter_payload,
          // search,
          // status,
          // min_price,
          // max_price,
          // min_unit,
          // max_unit,
          // payment_method,
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        if (res.status < 300) {
          setOrderData(res.data.data.data);
        }
        setCategoryPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch subscriptions, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  useEffect(() => {
    bus.on('dataFilter', (el) => {
      setDataFilter(el);
    });
    fetchData();
  }, [searchvalue, dataFilter]);

  useEffect(() => {
    ordersTotalCount();
  }, [dataFilter]);

  const notifyJsx = () =>
    toast.error(
      'Please visit your profile and the bottom left to complete your onboarding'
    );
  useEffect(() => {
    const fetchStoreDetails = async () => {
      try {
        const response = await axiosInstance.get('/merchant/store-profile');
        const { data } = response;
        if (data.success && data.data) {
          const { onboarded } = data.data;
          if (!onboarded) {
            // Show toast if not onboarded
            notifyJsx();
          }
        } else {
          // Handle error case
          console.error('Failed to fetch store details:', data.message);
        }
      } catch (error) {
        console.error('Error fetching store details:', error);
      }
    };

    fetchStoreDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDetails = (period = dataFilter) => {
    setIsPending(true);
    axiosInstance
      .get('/shop/dashboard/card?period=' + period)
      .then((res) => {
        setDetailsData(res.data.data);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Something went wrong , please try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const getHoverProduct = (id) => {
    setToolTipLoading(true);
    setToolTipData({ product_items: [] });
    axiosInstance
      .get(`/shop/order/get-customer-order-hover/${id}`)
      .then((res) => {
        if (res.data.data !== null) {
          setToolTipData(res.data.data || { product_items: [] });
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Something went wrong , please try again later');
        }
      })
      .finally(() => setToolTipLoading(false));
  };

  const handleConfirmOrder = (val, order_number, transaction_id) => {
    setTrx_id(val);
    setOrderNumber(order_number);
    setTransactionId(transaction_id);
    setModalOpenConfirm(true);
  };

  useEffect(() => {
    bus.on('dataFilter', (el) => {
      // console.log(el);
      setDataFilter(el);
    });
    fetchDetails();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, searchvalue, dataFilter]);

  useEffect(() => {
    bus.on('added-to-cart', () => {
      setopenGoToCart(true);
      setReload(!reload);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [open, setOpen] = useState(false);

  return (
    <>
      {/* <Header onOpenNav={() => setOpen(!open), console.log('i click')} /> */}
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <div className={`wrapper  mt-4 pt-2 px-2`}>
        <div className={`${styles.header} d-block d-md-none mb-4`}>
          <h4 className={`mb-0 pb-0`}>Dashboard</h4>
          <p style={{ fontSize: '14px' }} className={`mb-0 pb-0`}>
            A brief update on your business transactions
          </p>
        </div>

        <div className={`${styles.analytics}  `}>
          <div className={`${styles.customized_row}`}>
            <div>
              <Filter1 width="100%" />
              <div className={`${styles.customized_row1} m-0`}>
                <div className={` mb-5 pr-0`}>
                  <div className={`${styles.card}`}>
                    <div className={`card-body`}>
                      <div className="d-flex justify-content-between">
                        <small>Total Orders Received</small>
                      </div>
                      <div className={`${styles.numbers}`}>
                        <div>
                          <span>
                            {Number(
                              dataDetails?.sales_card?.total_order
                                ?.current_order_count
                            ).toLocaleString()}
                          </span>
                          <small>
                            {'  '}
                            from{' '}
                            {Number(
                              dataDetails?.sales_card?.total_order
                                ?.previous_order_count
                            ).toLocaleString()}
                          </small>
                        </div>
                        <div>
                          {Number(
                            dataDetails?.sales_card?.total_order
                              ?.current_order_count
                          ).toLocaleString() >=
                          Number(
                            dataDetails?.sales_card?.total_order
                              ?.previous_order_count
                          ).toLocaleString() ? (
                            <div className={styles.percent}>
                              <img
                                src="/assets/icons/up.svg"
                                className=" "
                                alt=""
                              />
                              <small>
                                {dataDetails?.sales_card?.total_order
                                  ?.percentage_order >= 0
                                  ? Math.floor(
                                      Number(
                                        dataDetails?.sales_card?.total_order
                                          ?.percentage_order
                                      )
                                    )
                                  : 0}
                                %
                              </small>
                            </div>
                          ) : (
                            <div className={`${styles.percent2}`}>
                              <img
                                src="/assets/icons/downr.svg"
                                className=" "
                                alt=""
                              />
                              <small>
                                {dataDetails?.sales_card?.total_order
                                  ? Math.abs(
                                      dataDetails?.sales_card?.total_order
                                        ?.percentage_order
                                    )
                                  : 0}
                                %
                              </small>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`mb-2`}>
                  <div className={`${styles.card}`}>
                    <div className={`card-body`}>
                      <div className="d-flex justify-content-between">
                        <small>Total Sales</small>
                      </div>
                      <div className={`${styles.numbers}`}>
                        <div>
                          <span>
                            ₦
                            {Number(
                              dataDetails?.sales_card?.total_sales
                                ?.current_total_sales
                            ).toLocaleString()}
                          </span>
                          <small>
                            &nbsp;&nbsp; from ₦
                            {Number(
                              dataDetails?.sales_card?.total_sales
                                ?.current_total_sales
                            ).toLocaleString()}
                          </small>
                        </div>
                        <div>
                          {Number(
                            dataDetails?.sales_card?.total_sales
                              ?.current_total_sales
                          ).toLocaleString() >=
                          Number(
                            dataDetails?.sales_card?.total_sales
                              ?.previous_total_sales
                          ).toLocaleString() ? (
                            <div className={styles.percent}>
                              <img
                                src="/assets/icons/up.svg"
                                className=""
                                alt=""
                              />
                              <small>
                                {dataDetails?.sales_card?.total_sales
                                  ?.percentage_sales >= 0
                                  ? Math.floor(
                                      Number(
                                        dataDetails?.sales_card?.total_sales
                                          ?.percentage_sales
                                      )
                                    )
                                  : 0}
                                %
                              </small>
                            </div>
                          ) : (
                            <div className={`${styles.percent2}`}>
                              <img
                                src="/assets/icons/downr.svg"
                                className=" "
                                alt=""
                              />
                              <small>
                                {dataDetails?.sales_card?.total_sales
                                  ? Math.abs(
                                      dataDetails?.sales_card?.total_sales
                                        ?.percentage_sales
                                    )
                                  : 0}
                                %
                              </small>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={` mb-2 pr-0`}>
                <div className={`${styles.card}`}>
                  <div className={`card-body`}>
                    <div className="d-flex justify-content-between">
                      <small>Total Products Sold</small>
                    </div>
                    <div className={`${styles.numbers}`}>
                      <div>
                        <span>
                          {Number(
                            dataDetails?.sales_card?.total_product_sold
                              ?.current_product_sold_count
                          ).toLocaleString() || 0}
                        </span>
                        <small>
                          {'  '}
                          from{' '}
                          {Number(
                            dataDetails?.sales_card?.total_product_sold
                              ?.previous_product_sold_count
                          ).toLocaleString() || 0}
                        </small>
                      </div>
                      <div>
                        {Number(
                          dataDetails?.sales_card?.total_product_sold
                            ?.current_product_sold_count
                        ).toLocaleString() >=
                        Number(
                          dataDetails?.sales_card?.total_product_sold
                            ?.previous_product_sold_count
                        ).toLocaleString() ? (
                          <div className={styles.percent}>
                            <img
                              src="/assets/icons/up.svg"
                              className=" "
                              alt=""
                            />
                            <small>
                              {dataDetails?.sales_card?.total_product_sold
                                ?.percentage_product_sold >= 0
                                ? Math.floor(
                                    Number(
                                      dataDetails?.sales_card
                                        ?.total_product_sold
                                        ?.percentage_product_sold
                                    )
                                  )
                                : 0}
                              %
                            </small>
                          </div>
                        ) : (
                          <div className={`${styles.percent2}`}>
                            <img
                              src="/assets/icons/downr.svg"
                              className=" "
                              alt=""
                            />
                            <small>
                              {dataDetails?.sales_card?.total_product_sold
                                ? Math.abs(
                                    dataDetails?.sales_card?.total_product_sold
                                      ?.percentage_product_sold
                                  )
                                : 0}
                              %
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={` mb-2 pr-0`}>
                <div className={`${styles.card}`}>
                  <div className={`card-body`}>
                    <div className="d-flex justify-content-between">
                      <small>Total Discount Value</small>
                    </div>
                    <div className={`${styles.numbers}`}>
                      <div>
                        <span>
                          {Number(
                            dataDetails?.sales_card?.total_discount
                              ?.current_total_discount
                          ).toLocaleString() || 0}
                        </span>
                        <small>
                          {'  '}
                          from{' '}
                          {Number(
                            dataDetails?.sales_card?.total_discount
                              ?.previous_total_discount
                          ).toLocaleString() || 0}
                        </small>
                      </div>
                      <div>
                        {Number(
                          dataDetails?.sales_card?.total_discount
                            ?.current_total_discount
                        ).toLocaleString() >=
                        Number(
                          dataDetails?.sales_card?.total_discount
                            ?.previous_total_discount
                        ).toLocaleString() ? (
                          <div className={styles.percent}>
                            <img
                              src="/assets/icons/up.svg"
                              className=" "
                              alt=""
                            />
                            <small>
                              {dataDetails?.sales_card?.total_discount
                                ?.percentage_discount >= 0
                                ? Math.floor(
                                    Number(
                                      dataDetails?.sales_card?.total_discount
                                        ?.percentage_discount
                                    )
                                  )
                                : 0}
                              %
                            </small>
                          </div>
                        ) : (
                          <div className={`${styles.percent2}`}>
                            <img
                              src="/assets/icons/downr.svg"
                              className=" "
                              alt=""
                            />
                            <small>
                              {dataDetails?.sales_card?.total_discount
                                ? Math.abs(
                                    dataDetails?.sales_card?.total_discount
                                      ?.percentage_discount
                                  )
                                : 0}
                              %
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={`${styles.card2} card`}>
                <div className={`${styles.wallet}`} style={{ height: '290px' }}>
                  <img
                    className={`${styles.img} ${styles.img3}`}
                    src="/assets/newccr.png"
                    alt=""
                    style={{ borderRadius: '50px' }}
                  />

                  {/* Background color */}
                  <div
                    className={` ${styles.darkcardbg}`}
                    style={{ borderRadius: '50px' }}
                  ></div>

                  <div
                    className={`${styles.content}`}
                    style={{
                      height: '245px',
                      width: '80%',
                      borderRadius: '50px',
                    }}
                  >
                    <div className={`${styles.content_header}`}>
                      <div>
                        ₦‎ {Number(dataDetails.balance).toLocaleString()}
                      </div>
                      <div>
                        <img src="/assets/icons/Vector.svg" alt="" />
                      </div>
                    </div>
                    <p className={`${styles.sub_text}`}>Your Balance</p>
                    <div
                      className={`${styles.content_body} ${styles.content_body1}`}
                    >
                      <p className={`${styles.sub_text}`}>Account Details</p>
                      <div className={`${styles.bank_info}`}>
                        <div>
                          <p className={`${styles.name}`}>
                            {dataDetails?.account_details?.account_name}
                          </p>
                          <p className={`${styles.account_number}`}>
                            {dataDetails?.account_details?.account_number}
                          </p>
                        </div>
                        <span
                          style={{
                            fontSize: '9px',
                            fontWeight: '700',
                            color: '#FFFFFF',
                          }}
                        >
                          {dataDetails?.account_details?.bank_name}
                        </span>
                        {/* <img className="mt-2"
                          src="/assets/Logomark.svg"
                          alt=""
                        /> */}
                        {/* <span style={{
                            fontSize:'14px',
                            fontWeight:'700',
                            color:'#FFFFFF'
                          }}>
                            {dataDetails?.account_details?.bank_name}
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${styles.footer} ${
                      data.merchant_type === 'brand' ? 'mt-4' : ''
                    }`}
                  >
                    <div className="">
                      <p
                        className={`${
                          data.merchant_type === 'brand' ? 'pt-2' : 'pt-0'
                        }`}
                      >
                        <RouterLink
                          to="/app/wallets"
                          style={{ color: '#af3585' }}
                        >
                          Manage Wallet
                          <img src="/assets/icons/arrow-right.svg" alt="" />
                          &nbsp;
                        </RouterLink>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card
          style={{
            marginTop: '50px',
            borderRadius: '10px',
          }}
          className="p-0"
        >
          <div className="p-3">
            <h5 className="table_desc_header">All Orders</h5>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p
                style={{
                  fontWeight: '200',
                  fontSize: '12px',
                  color: '#4b5563',
                }}
              >
                Customers who purchased from your Store recently
              </p>
              <small
                style={{
                  color: '#af3585',
                  cursor: 'pointer',
                }}
                onClick={() => navigateTo()}
              >
                View all
                <img src="/assets/icons/arrow-right.svg" alt="arrow" />
              </small>
            </div>
          </div>
          <div
            style={{
              height: '500px',
              overflowX: 'auto',
            }}
          >
            <Scrollbar>
              <div className={`pt-3 pl-0 pr-0`}>
                <table className={`table-hover`} style={{ minWidth: '90em' }}>
                  <thead className={`${styles.thead}`}>
                    <tr>
                      {TABLE_HEAD.map((el, i) => {
                        let sIndex = i === 1 ? 1 : false;
                        return (
                          <th className="text-muted">
                            {el.label}{' '}
                            {sIndex && (
                              <span style={{ cursor: 'pointer' }}>
                                <img
                                  className="pl-2"
                                  src="/assets/icons/down.svg"
                                  alt=""
                                />
                              </span>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {!isPending &&
                      orderData.length >= 1 &&
                      orderData.map((el, i) => {
                        return (
                          <tr
                            className={`${styles.tr}`}
                            onClick={() => handleViewClick(el)}
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            <td>
                              <div className="ml-3">{i + 1}</div>
                            </td>
                            <td>
                              <div className=" ">
                                <Avatar
                                  className={`${styles.avatrr} mr-3 float-md-left`}
                                  alt={el.full_name}
                                  src={el.image}
                                />
                                <div className=" ">
                                  <p
                                    className="mb-0"
                                    style={{ fontSize: '14px' }}
                                  >
                                    <strong>{el.full_name}</strong>
                                  </p>
                                  <span
                                    style={{
                                      fontSize: '16px',
                                      fontStyle: 'bold',
                                      color: '#000',
                                    }}
                                  >
                                    {el.transaction_id}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="">
                              <BootstrapTooltip
                                title={
                                  <div>
                                    {!tooltipLoading &&
                                      tooltipData.product_items.length > 0 &&
                                      tooltipData.product_items.map(
                                        (tooltip, ind) => (
                                          <span key={ind} className="mr-1">
                                            {tooltip.product_name},
                                          </span>
                                        )
                                      )}
                                    {!tooltipLoading &&
                                      tooltipData.product_items.length < 1 && (
                                        <span>Empty</span>
                                      )}
                                    {tooltipLoading && <span>Loading</span>}
                                  </div>
                                }
                              >
                                <span
                                  onMouseEnter={() =>
                                    getHoverProduct(el.order_id)
                                  }
                                >
                                  {el.product_units}
                                </span>
                              </BootstrapTooltip>
                            </td>
                            <td className="">
                              {el.payment_method.charAt(0).toUpperCase() +
                                el.payment_method.slice(1)}
                            </td>
                            <td className="">
                              ₦{' '}
                              {el.total_cost
                                ? Number(
                                    el.total_cost?.$numberDecimal
                                  ).toLocaleString()
                                : 0}
                            </td>
                            <td className="">{el?.patronage}</td>
                            <td>
                              <span>
                                {el?.order_status === 'completed' ? (
                                  <span className={`${styles.customer}`}>
                                    Completed
                                  </span>
                                ) : el?.order_status === 'pending' ? (
                                  <span className={`${styles.pending}`}>
                                    Pending
                                  </span>
                                ) : el?.order_status === 'refunded' ? (
                                  <span className={`${styles.refunded}`}>
                                    Refunded
                                  </span>
                                ) : (
                                  <span className={`${styles.abandoned}`}>
                                    Abandoned
                                  </span>
                                )}
                              </span>
                            </td>
                            <td className="">
                              {moment(el?.date_ordered).format('DD/MM/YYYY')}
                            </td>
                            {/* <td>
                              <div className="dropleft ">
                                <span
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  className={`${styles.action_button} px-3 py-2`}  style={{
                                    cursor: 'pointer',
                                  }}>
                                    {
                                      el.order_status !== 'refunded' && (
                                      <img src="/assets/icons/action.svg" alt="" />
                                    )}
                                    {
                                      el.order_status === 'refunded' && (
                                      <img disabled src="/assets/icons/action.svg" alt="" />
                                      )}
                                </span>
                                {
                                  el.order_status !== 'refunded' && (
                                  <div
                                    className="dropdown-menu drop-left"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <button
                                      type="button"
                                      onClick={() => handleViewClick(el)}
                                      className="rss dropdown-item btn border-0">
                                      View
                                    </button>
                                    {el.order_status === 'abandoned' && (
                                      <button
                                        className="rss btn border-0 dropdown-item"
                                        type="button"
                                        onClick={() => handleSendReminderClick(el?._id)}
                                      >
                                        Send Reminder
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>
                            </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {/* {
                !data.is_verified &&
                <div className="alert alert-primary text-center">
                  <p>This account is yet to be verified by the admin.
                    Kindly send an email to support@retailxpress.africa
                    if it is over 24hours of registeration. Thanks 🤝
                  </p>
                </div>
              } */}
              <div className="d-flex justify-content-center">
                {isPending && (
                  <BallTriangle
                    height={50}
                    width={55}
                    radius={5}
                    color="#af3585"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />
                )}
              </div>
              {!orderData.length && !isPending && (
                <div className="alert alert-[#af3585] text-center">
                  <h4>No record found</h4>
                </div>
              )}
            </Scrollbar>
          </div>
        </Card>
      </div>
      {/* Product Edit Modals */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open1}>
          <Box className={`mobile_modal_size`} sx={style}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3>Edit Product</h3>
                <span
                  onClick={handleClose}
                  className={`${styles.pointer_cursor}`}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p>Edit product in this category</p>

              <p className={`${styles.modal_product_title}`}>Product Image </p>
              <div className={`${styles.retailer_modal_product_img}`}>
                <img
                  className={`${styles.retailer_product_image}`}
                  src="/assets/images/products/Milo2.png"
                />
              </div>

              <form action="">
                <div className="mb-4">
                  <label
                    className={`${styles.modal_product_title}`}
                    htmlFor="amount"
                  >
                    Product Name
                  </label>
                  <TextField
                    required
                    type="text"
                    id="amount"
                    placeholder=""
                    size="small"
                    fullWidth
                  />
                </div>
                <div className="mb-4">
                  <label
                    className={`${styles.modal_product_title}`}
                    htmlFor="name"
                  >
                    Weight
                  </label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={weight}
                    required
                    onChange={handleChange}
                  >
                    <MenuItem value={20}>20kg</MenuItem>
                    <MenuItem value={30}>30kg</MenuItem>
                  </Select>
                </div>
                <div className="mb-4">
                  <label
                    className={`${styles.modal_product_title}`}
                    htmlFor="cnumber"
                  >
                    Stock{' '}
                  </label>
                  <TextField
                    required
                    id="cnumber"
                    type="number"
                    placeholder="4000"
                    size="small"
                    fullWidth
                  />
                </div>

                <div className="mb-4">
                  <button
                    type="submit"
                    className={`${styles.modal_btn} btn btn-block`}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* Product Buy Now Modals */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openModal}>
          <Box className={`mobile_modal_size`} sx={style}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3></h3>
                <span
                  onClick={handleModalClose}
                  className={`${styles.pointer_cursor}`}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>

              <div className={`${styles.retailer_modal_buyNow_img}`}>
                <img
                  className={`${styles.retailer_modal_buynow_image}`}
                  src={randomValue?.product_image}
                  alt=""
                />
              </div>
              <div className={`row mt-4`}>
                <div className={`col-md-4 pr-0`}>
                  <div className={`row mb-2 ml-2`}>
                    <span className={`${styles.retailer_modal_buynow_tag}`}>
                      Brand:
                    </span>
                    <span className={`${styles.retailer_modal_buynow_value}`}>
                      {randomValue?.brand_name}
                    </span>
                  </div>
                  <div className={`row ml-2`}>
                    <span className={`${styles.retailer_modal_buynow_tag}`}>
                      Price:
                    </span>
                    <span className={`${styles.retailer_modal_buynow_value}`}>
                      ₦
                      {selectedObject?.price
                        ? Number(
                            selectedObject?.price?.$numberDecimal -
                              (Math.floor(
                                selectedObject?.discount?.$numberDecimal
                              ) /
                                100) *
                                selectedObject?.price?.$numberDecimal
                          ).toLocaleString()
                        : 0}
                    </span>
                    <span
                      className={`${styles.retailer_modal_buynow_value}`}
                      style={{ textDecoration: 'line-through' }}
                    >
                      ₦
                      {selectedObject?.price
                        ? Number(
                            selectedObject?.price?.$numberDecimal
                          ).toLocaleString()
                        : 0}
                    </span>
                  </div>
                </div>
                <div className={`col-md-4`}> </div>
                <div className={`col-md-4 `}>
                  <div className={`row mb-2`}>
                    <span className={`${styles.retailer_modal_buynow_tag}`}>
                      Product:
                    </span>
                    <span className={`${styles.retailer_modal_buynow_value}`}>
                      {randomValue?.product_name}
                    </span>
                  </div>
                  <div className={`row`}>
                    <span className={`${styles.retailer_modal_buynow_tag}`}>
                      Size:
                    </span>
                    <span className={`${styles.retailer_modal_buynow_value}`}>
                      {randomValue?.product_spec
                        ? randomValue?.product_spec
                        : ''}
                    </span>
                  </div>
                </div>
              </div>

              <hr />
              {/* <div className={`${styles.retailer_modal_buynow_desc} mt-4 mb-4`}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. In, ea harum minus
                volup tatem, pariatur id nisi sequi deserunt iure vero tenetur.
                Recusandae exercitationem, assumenda nam ab consequatur tempore facilis autem?
                <br />
                Lorem ipsum dolor sit amet consectetur adipisicing elit. In, ea harum minus
                volup tatem, pariatur id nisi sequi deserunt iure vero tenetur.
              </div>
              <hr /> */}

              {/* <form action="" onSubmit={buyNow}>

                <div className="mb-4">
                  <label className={`${styles.modal_product_title}`} htmlFor="cnumber">Quantity </label>
                  <TextField
                    required
                    id="cnumber"
                    type="number"
                    placeholder="100"
                    size="small"
                    fullWidth
                    value={quantityValue}
                    onChange={handleQuantityValue}
                    name="quantityValue"

                  />


                </div>

                <div className="mb-4">
                  <button type="submit" className={`${styles.modal_btn} btn btn-block`}>
                    Add to Cart {cartPending && <CircularProgress style={{ color: '#fff' }} size="1rem" />}
                  </button>


                </div>

              </form> */}
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* Go to cart Modals */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openGoToCart}
        onClose={handleGoToCartClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openGoToCart}>
          <Box className={`mobile_modal_size ${styles.modal_bg}`} sx={style1}>
            <div className={`text-center ${styles.add_to_cart_content}`}>
              <img src="/assets/icons/Illustration.svg" alt="" />
              <h5 className="mt-3 font-weight-bold">Product Added to Cart</h5>
              {/* <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing  elit. Eius aliquam
                laudantium explicabo pariatur iste dolorem animi vitae error totam. At
                sapiente aliquam accusamus facere veritatis.

              </p> */}
              <div className={`${styles.buttons}  pt-2`}>
                <button
                  className={`${styles.cancel} `}
                  onClick={handleGoToCartClose}
                >
                  Cancel
                </button>
                &nbsp;
                <button
                  className={`${styles.go_to} `}
                  onClick={() => navigate('/app/cart')}
                >
                  Go to cart
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/*  DIALOG*/}
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="text-center">
                <img
                  style={{ width: '40px' }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <h4>Delete Product</h4>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6B7280',
                  }}
                >
                  Are you sure you want to delete this product? All your data
                  will be permanently removed from our servers forever. This
                  action cannot be undone.
                </p>
              </div>
            </div>

            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={handleDialogClose}
              >
                Cancel
              </Button>
              <Button
                className={`${styles.delete_product}`}
                onClick={handleDialogClose}
              >
                Delete
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
      <Header emitCount={dataItem.items.length} />
    </>
  );
}
