import styles from './Page.module.css';
import Invite from './Modals/Invite';
import Backdrop from '@mui/material/Backdrop';
import { Card, Avatar, Modal, Fade, Box, TextField } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { forwardRef, useEffect } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import moment from 'moment';
import { useState } from 'react';
import bus from 'src/bus';
import CircularProgress from '@mui/material/CircularProgress';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import { Pagination } from 'src/components';
// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// ----------------------------------------------------------------------

export default function ShopownerAdministration() {
  const [isPending, setIsPending] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [searchvalue, setSearchValue] = useState('');
  const [adminList, setAdminList] = useState([]);
  const [reload, setReload] = useState(false);
  const [adminPagination, setAdminPagination] = useState({ total: 0 });
  const notify = (msg) => toast.success(msg);
  const notifyError = (error) => toast.error(error);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [preview, setPreview] = useState('');
  const [adminDetial, setAdminDetial] = useState();
  const [isEditView, setIsEditView] = useState(false);
  const [listPermisssionChange, setListPermissionChange] = useState([]);
  const [isEditPermissionLoading, setIsEditPermissionLoading] = useState(false);

  const searchFilter = (e) => {
    setSearchValue(e.target.value);
  };

  const [openDialog, setDiologOpen] = useState(false);

  const handleDialogClose = () => {
    setDiologOpen(false);
  };

  const auth = JSON.parse(localStorage.getItem('auth')) || {};
  console.log(auth, 'user mar -000');

  useEffect(() => {
    bus.on('imageUrl', (val) => {
      setPreview(val);
    });
  }, []);

  const [openInviteAdmin, setOpenInviteAdmin] = useState(false);

  const handleCloseInviteAdmin = () => {
    setListPermissionChange([]);
    setOpenInviteAdmin(false);
  };

  const handleDeleteAdmin = (id) => {
    axiosInstance
      .put('/shop/administration/remove-member/' + id)
      .then((res) => {
        notify(res.data.message);
        setReload(!reload);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch {
          notifyError('Something went wrong, Please try again later');
        }
      });
  };

  const fetchData = (search = searchvalue) => {
    setIsPending(true);
    axiosInstance
      .get('/shop/administration/get-members', {
        params: {
          search,
        },
      })
      .then((res) => {
        setAdminList(res.data.data.data);
        setAdminPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch {
          notifyError('Something went wrong, Please try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const handleViewAdmin = (el) => {
    const adminData = { ...el };

    const finalPermissionModifed = [];

    const permissionName = [];

    // Gt all permission
    for (let permission of el.assigned_permissions) {
      if (!permissionName.includes(permission.permission_name)) {
        permissionName.push(permission.permission_name);
      }
    }

    // Set permiission values

    for (let permissionItem of permissionName) {
      let permission_description = [];
      let id = '';

      for (let permissionDetails of el.assigned_permissions) {
        if (permissionDetails?.permission_name === permissionItem) {
          id = permissionDetails?._id;

          permission_description.push(
            permissionDetails?.permission_description
          );
        }
      }

      // To avoid empty permisions
      if (permission_description.length > 0) {
        finalPermissionModifed.push({
          id,
          permission_name: `${permissionItem}`,
          permission_descriptions: permission_description,
        });
      }
    }

    adminData.assigned_permissions = finalPermissionModifed;

    setAdminDetial(adminData);
    setOpenInviteAdmin(true);
  };

  const updatePermission = async () => {
    // Call endpoint
    setIsEditPermissionLoading(true);

    const commbinedPermission = [...adminDetial?.assigned_permissions];

    let acceptedPermission = [];

    for (let item of commbinedPermission) {
      if (!listPermisssionChange.includes(item?.id)) {
        acceptedPermission.push(item?.id);
      }
    }

    const payload = {
      permissions: acceptedPermission,
    };

    await axiosInstance
      .post(
        `/shop/administration/modify-team-permission/` + adminDetial?._id,
        payload
      )
      .then((res) => {
        console.log(res);
        notify(res.data.message);
        setIsEditPermissionLoading(false);
        setReload(!reload);
      })
      .catch((err) => {
        notifyError(err.response.data.message || 'Unexpected error occured');
        setIsEditPermissionLoading(false);
      });

    handleCloseInviteAdmin();
  };

  const handleEditPermission = () => {
    if (!isEditView) {
      setIsEditView(true);
    } else {
      // Call update
      updatePermission();
    }
  };

  const handleSelectedPermisssion = (data) => {
    const listPermissioonCopy = [...listPermisssionChange];

    if (listPermissioonCopy.includes(data)) {
      // Remove it
      const listPermissionCopyModified = listPermissioonCopy
        .filter((item) => item !== data)
        .map((data) => data);

      setListPermissionChange(listPermissionCopyModified);
    } else {
      const listPermissionCopyModified = [...listPermissioonCopy, data];

      setListPermissionChange(listPermissionCopyModified);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchvalue, reload]);

  const processCallback = () => fetchData();
  console.log(adminList, 'Liist here ---');
  return (
    <>
      <div className="wrapper mt-4 mt-md-0 px-2">
        <div className="header d-block d-md-none mb-4">
          <div className="d-md-flex justify-content-between">
            <div className="mb-3">
              <h4 className={`mb-1 mb-md-0 pb-0`}>Administration</h4>
              <p style={{ fontSize: '14px' }} className={`mb-0 pb-0`}>
                There are 12 roles
              </p>
            </div>
            <button
              type="button"
              onClick={() => bus.emit('open-invite-modal')}
              className={`btn btn-lg btn_primary`}
            >
              <img src="/assets/icons/plus.svg" className="pr-1" alt="" />
              <span>Invite Admin</span>
            </button>
          </div>
        </div>
        <div className="px-2 mt-4">
          <div className={`${styles.filters}`}>
            <div className="d-flex justify-content-between px-0 ">
              <div className={`${styles.hold_input} col-md-4 col-12 px-0  `}>
                <img src="/assets/icons/search.svg" alt="" />
                <input
                  type="text"
                  placeholder="Search"
                  name="search"
                  value={searchvalue}
                  onChange={searchFilter}
                  style={{
                    textIndent: '25px',
                    width: '100%',
                  }}
                  className=" form-control"
                />
              </div>
            </div>
          </div>
          <Scrollbar>
            <Card
              style={{
                marginTop: '20px',
                borderRadius: '0px',
                boxShadow: 'none',
              }}
              className="p-0"
            >
              <div className={`table-responsive-sm pl-0 pr-0`}>
                <table className={`${styles.table} table-hover table-striped`}>
                  <thead className={`${styles.thead}`}>
                    <tr>
                      <th>S/N</th>
                      <th className="text-muted">
                        Admin name
                        <span style={{ cursor: 'pointer' }}>
                          <img
                            className="pl-2"
                            src="/assets/icons/down.svg"
                            alt=""
                          />
                        </span>
                      </th>
                      {/* <th>
                                                Password
                                            </th> */}
                      <th>
                        Date Created &nbsp;
                        <span style={{ cursor: 'pointer' }}>
                          <img
                            className="pl-2"
                            src="/assets/icons/down.svg"
                            alt=""
                          />
                        </span>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isPending &&
                      adminList?.length > 0 &&
                      adminList?.map((el, i) => (
                        <tr key={i} className={`${styles.tr} `}>
                          <td>{i + 1}</td>
                          <td>
                            <Avatar
                              className={`${styles.avatrr} mr-3 float-md-left`}
                              alt=""
                              src={preview}
                            />
                            <div className=" ">
                              <p className="mb-0" style={{ fontSize: '14px' }}>
                                {el?.store_name ? el?.store_name : '---'}
                              </p>
                              <span style={{ fontSize: '10px' }}>
                                {el?.email}
                              </span>
                            </div>
                          </td>
                          {/* <td>
                                                        <input disbaled type={showText ? 'text' : 'password'} value="12345678" className="d-inline-block text-center input_ form-control" />
                                                        &nbsp;
                                                        &nbsp;
                                                        <div className="d-inline-block cursor_pointer mt-2" onClick={changeType}>
                                                            {
                                                                !showText &&
                                                                <img src="/assets/icons/eye.svg" alt="" />

                                                            }
                                                            {
                                                                showText &&
                                                                <img src="/assets/icons/eye-off.svg" alt="" />


                                                            }

                                                        </div>
                                                    </td> */}
                          <td>
                            <p>
                              {moment(el?.invite_token_expires).format(
                                'DD/MM/YYYY'
                              )}
                            </p>
                          </td>
                          <td>
                            <div className="dropleft ">
                              <span
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                className={`${styles.action_button} px-3 py-2`}
                                style={{
                                  cursor: 'pointer',
                                }}
                              >
                                <img src="/assets/icons/action.svg" alt="" />
                              </span>
                              <div
                                className="dropdown-menu drop-left"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <button
                                  type="button"
                                  className="rss dropdown-item btn border-0 text-danger"
                                  onClick={() => handleDeleteAdmin(el?._id)}
                                >
                                  Delete
                                </button>
                                {auth?.is_main_merchant && (
                                  <button
                                    style={{ color: '#4b5563' }}
                                    type="button"
                                    className="rss dropdown-item btn border-0 "
                                    onClick={() => {
                                      handleViewAdmin(el);
                                      setOpenInviteAdmin(true);
                                    }}
                                  >
                                    View
                                  </button>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Card>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!adminList?.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={adminPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>

          {/* Modals */}
          <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleDialogClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <div className="card border-0">
              <div className="card-body border-0 ">
                <div className={`${styles.customized_row}`}>
                  <div className="text-center">
                    <img
                      style={{ width: '40px' }}
                      src="/assets/icons/caution.svg"
                      alt=""
                    />
                  </div>
                  <div>
                    <h5>Delete</h5>
                    <p
                      style={{
                        fontSize: '14px',
                        color: '#6B7280',
                      }}
                    >
                      Are you sure you want to delete this outlet? All your data
                      will be permanently removed from our servers forever. This
                      action cannot be undone.
                    </p>
                  </div>
                </div>

                <DialogActions>
                  <Button
                    className={`${styles.cancel_delete}`}
                    onClick={handleDialogClose}
                  >
                    Cancel
                  </Button>
                  {!isDeleting && (
                    <Button className={`${styles.delete_product}`}>
                      Delete
                    </Button>
                  )}

                  {isDeleting && (
                    <button disabled className={`btn ${styles.delete_product}`}>
                      {isDeleting && (
                        <CircularProgress
                          style={{ color: 'white' }}
                          size="1rem"
                        />
                      )}
                    </button>
                  )}
                </DialogActions>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
      <Invite refresh={processCallback} />

      {/* Invite Admin Modals */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openInviteAdmin}
        onClose={() => handleCloseInviteAdmin()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openInviteAdmin}>
          <Box className={`${styles.view_content}`}>
            <div className={`${styles.view_content_header}`}>
              <div>
                <h4>View Admin</h4>

                <p>View Admin to manage the dashboard</p>
              </div>

              <div
                className={`${styles.view_content_header_close}`}
                onClick={() => handleCloseInviteAdmin()}
              >
                <img src="/assets/close-x.svg" alt="cllose icon" />
              </div>
            </div>

            <div className={`${styles.view_input_field}`}>
              <label>Email address</label>
              <TextField
                required
                disabled
                type="text"
                value={adminDetial?.email}
                name="email"
                onChange={() => {}}
                placeholder=""
                size="small"
                fullWidth
                InputProps={{
                  style: {
                    color: '#4b5563', // Change the text color of the input field
                  },
                }}
              />
            </div>
            {!isEditPermissionLoading ? (
              <div className={`${styles.view_permissions}`}>
                {adminDetial &&
                  adminDetial.assigned_permissions.map(
                    ({ permission_name, permission_descriptions, id }) => (
                      <div key={id}>
                        <h5>{permission_name}</h5>

                        <div className={`${styles.permission_con}`}>
                          <div className={`${styles.checker_permission_con}`}>
                            {isEditView && (
                              <div
                                className={`${styles.checker_permission_outer}`}
                                onClick={() => handleSelectedPermisssion(id)}
                              >
                                {!listPermisssionChange.includes(id) && (
                                  <div
                                    className={`${styles.checker_permission_inner}`}
                                  ></div>
                                )}
                              </div>
                            )}

                            <span>{permission_descriptions}</span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            ) : (
              <BallTriangle
                height={50}
                width={55}
                radius={5}
                color="#af3585"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            )}
            <button
              className={`${styles.view_content_btn}`}
              onClick={() => {
                handleEditPermission();
              }}
            >
              {isEditView ? 'Save' : 'Edit Role'}
            </button>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
