import styles from '../Order.module.css';

import { Card } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
import { Pagination } from 'src/components';
// sections
// mock
import MenuItem from '@mui/material/MenuItem';
import { useLayoutEffect } from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// ----------------------------------------------------------------------
import Filter1 from 'src/pages/Filter/Filter1';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import bus from 'src/bus';
import axiosInstance from 'src/axiosInstance';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { BallTriangle } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';
import { downloadFile2 } from 'src/utils';

const TABLE_HEAD = [
  { id: 'orderNumber', label: 'Order Number', alignRight: false },
  { id: 'productsOrdered', label: 'Products Ordered', alignRight: false },
  { id: 'totalCost', label: 'Total cost', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'created_at', label: 'Date ordered', alignRight: false },
  { id: '' },
];
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 730,
  overflow: 'auto',
  bgcolor: '#FFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

// ptvo=PAGE TO VIEW (ORDER)
// export default function OrderRetailerPage({ viewChange }) {
export default function OrderRetailerPage() {
  const [orderType, setOrderType] = useState('All');
  const navigate = useNavigate();
  const [tooltipData, setToolTipData] = useState({ product_items: [] });
  const [tooltipLoading, setToolTipLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvo');
    },
    []
  );
  const handleViewClick = (d) => {
    navigate(d);
  };

  // pagination
  const [shoppersData, setdata] = useState([]);
  const [analyticsDataMyOrder, setAnalyticsDataMyOrder] = useState({
    order: {},
    total_purchase: {},
  });
  const [isPending, setIsPending] = useState(false);
  const [shoppersPagination, setShoppersPagination] = useState({ total: 0 });
  const notifyError = (err) => toast.error(err);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [status, setStatus] = useState('select');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [minOrderUnit, setMinOrderUnit] = useState('');
  const [maxOrderUnit, setMaxOrderUnit] = useState('');
  const [searchvalue, setSearchvalue] = useState('');
  const [filter, setFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState('today');
  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    fetchData();
    fetchAnalyticsDataMyOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, dataFilter, searchvalue]);

  useEffect(() => {
    bus.on('dataFilter', (el) => {
      setDataFilter(el);
    });

    bus.on('reload', () => {
      fetchData();
      fetchAnalyticsDataMyOrder();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchFilter = (e) => {
    setSearchvalue(e.target.value);
  };

  //Getting Table Data
  const fetchData = async (
    page = 1,
    perpage = 10,
    search = searchvalue,
    file = null
  ) => {
    let filter_payload = {};
    if (maxPrice !== '') {
      filter_payload.max_price = maxPrice;
    }
    if (minPrice !== '') {
      filter_payload.min_price = minPrice;
    }
    if (status !== 'select' && status !== 'all') {
      filter_payload.status = status;
    }
    if (startDate !== '') {
      filter_payload.start_date = startDate;
    }
    if (endDate !== '') {
      filter_payload.end_date = endDate;
    }
    if (maxOrderUnit !== '') {
      filter_payload.max_order_unit = maxOrderUnit;
    }
    if (minOrderUnit !== '') {
      filter_payload.min_order_unit = minOrderUnit;
    }

    if (file) {
      setExporting(true);

      let queryParams = new URLSearchParams({
        page,
        perpage,
        search,
        ...filter_payload,
        export_type: file,
      }).toString();

      try {
        await downloadFile2('All My orders', `?${queryParams}`, file);
      } finally {
        // alert()
        setExporting(false);
      }
      return;
    }

    setIsPending(true);
    axiosInstance
      .get('shop/order/get-orders', {
        params: {
          page,
          perpage,
          search,
          ...filter_payload,
        },
      })
      .then((res) => {
        setdata(res.data.data.data);
        setShoppersPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch orders, please try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const getHoverProduct = (id) => {
    setToolTipLoading(true);
    setToolTipData({ product_items: [] });
    axiosInstance
      .get(`/shop/order/get-orders-hover/${id}`)
      .then((res) => {
        if (res.data.data !== null) {
          setToolTipData(res.data.data || { product_items: [] });
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Something went wrong , please try again later');
        }
      })
      .finally(() => setToolTipLoading(false));
  };

  //Getting Analytics Data
  const fetchAnalyticsDataMyOrder = () => {
    setIsPending(true);
    let period = typeof dataFilter === 'object' ? 'All time' : dataFilter;

    axiosInstance
      .get('/shop/order/get-order-cards?period=' + period)
      .then((res) => {
        setAnalyticsDataMyOrder(res.data.data);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError(
            'Unable to fetch order analytics, please try again later'
          );
        }
      })
      .finally(() => setIsPending(false));
  };

  return (
    <>
      <div className=" ">
        <div className={`${styles.analytics}`}>
          <Filter1 width="100%" />
          <div className={`row`}>
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Completed Orders</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        {analyticsDataMyOrder.order?.current_order_count
                          ? Number(
                              analyticsDataMyOrder.order?.current_order_count
                            ).toLocaleString()
                          : 0}
                      </span>{' '}
                      <small>
                        from{' '}
                        {analyticsDataMyOrder.order?.previous_order_count
                          ? Number(
                              analyticsDataMyOrder.order?.previous_order_count
                            ).toLocaleString()
                          : 0}
                      </small>
                    </div>
                    <div>
                      {Number(
                        analyticsDataMyOrder.order?.current_order_count
                      ).toLocaleString() >=
                      Number(
                        analyticsDataMyOrder.order?.previous_order_count
                      ).toLocaleString() ? (
                        <div className={styles.percent}>
                          <img
                            src="/assets/icons/up.svg"
                            className=" "
                            alt=""
                          />{' '}
                          <small>
                            {analyticsDataMyOrder.order
                              ? Math.abs(
                                  analyticsDataMyOrder.order?.percentage_order
                                )
                              : 0}
                            %
                          </small>
                        </div>
                      ) : (
                        <div className={`${styles.percent2}`}>
                          <img
                            src="/assets/icons/downr.svg"
                            className=" "
                            alt=""
                          />{' '}
                          <small>
                            {analyticsDataMyOrder.order?.percentage_order >= 0
                              ? analyticsDataMyOrder.order?.percentage_order
                              : 0}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Total Purchases</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        {analyticsDataMyOrder.total_purchase
                          ?.current_total_purchases
                          ? Number(
                              analyticsDataMyOrder.total_purchase
                                ?.current_total_purchases
                            ).toLocaleString()
                          : 0}
                      </span>{' '}
                      <small>
                        from{' '}
                        {analyticsDataMyOrder.total_purchase
                          ?.previous_total_purchases
                          ? Number(
                              analyticsDataMyOrder.total_purchase
                                ?.previous_total_purchases
                            ).toLocaleString()
                          : 0}
                      </small>
                    </div>
                    <div>
                      {Number(
                        analyticsDataMyOrder.total_purchase
                          ?.current_total_purchases
                      ).toLocaleString() >=
                      Number(
                        analyticsDataMyOrder.total_purchase
                          ?.previous_total_purchases
                      ).toLocaleString() ? (
                        <div className={styles.percent}>
                          <img
                            src="/assets/icons/up.svg"
                            className=" "
                            alt=""
                          />{' '}
                          <small>
                            {analyticsDataMyOrder.total_purchase
                              ? Math.abs(
                                  analyticsDataMyOrder.total_purchase
                                    ?.percentage_purchase
                                )
                              : 0}
                            %
                          </small>
                        </div>
                      ) : (
                        <div className={`${styles.percent2}`}>
                          <img
                            src="/assets/icons/downr.svg"
                            className=" "
                            alt=""
                          />{' '}
                          <small>
                            {analyticsDataMyOrder.total_purchase
                              ?.percentage_purchase >= 0
                              ? analyticsDataMyOrder.total_purchase
                                  ?.percentage_purchase
                              : 0}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.filters} mt-2`}>
          <div className="row">
            <div className={`${styles.hold_input} col-6 col-md-3   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={searchvalue}
                placeholder="Search"
                style={{
                  textIndent: '25px',
                  width: '100%',
                }}
                className=" form-control"
                onChange={searchFilter}
              />
            </div>
            <div className="col-6 col-md-9  text-right">
              <div className="dropleft ">
                <button
                  className="btn border"
                  onClick={() => setModalOpen(true)}
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    backgroundColor: '#fff',
                    border: '1px solid #D0D5DD',
                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    borderRadius: '4px',
                  }}
                >
                  <img
                    className=""
                    style={{
                      display: 'inline',
                      width: '28%',
                    }}
                    src="/assets/icons/filterlines.svg"
                    alt=""
                  />
                  &nbsp;
                  <small className="d-none d-md-inline-block">Filters</small>
                </button>
                <div
                  className="dropdown-menu drop-left"
                  aria-labelledby="dropdownMenuButton"
                >
                  <button
                    type="button"
                    onClick={() => setOrderType('All')}
                    className="rss dropdown-item btn border-0"
                  >
                    All
                  </button>
                  <button
                    type="button"
                    onClick={() => setOrderType('Completed')}
                    className="rss dropdown-item btn border-0"
                  >
                    Completed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card
          style={{
            marginTop: '10px',
            borderRadius: '10px',
          }}
          className="p-0"
        >
          <div className="p-3 ">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">{orderType} Orders</h5>
                <small
                  style={{
                    fontWeight: '200',
                    fontSize: '12px',
                    display: 'inline-block',
                    marginTop: '0px',
                  }}
                >
                  A list of orders you placed recently
                </small>
              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`${styles.export_btn} btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: '8px',
                      }}
                    >
                      <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                      {!exporting && <span>Export</span>}
                      {exporting && (
                        <CircularProgress
                          style={{
                            color: 'white',
                            display: 'inline-block',
                          }}
                          size="1rem"
                        />
                      )}
                    </span>
                    &nbsp; &nbsp;
                    <span
                      style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <img src="/assets/icons/arrowDown.svg" alt="" />
                    </span>
                  </button>
                  <div
                    className="dropdown-menu drop-left"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      onClick={() =>
                        fetchData(
                          currentPage,
                          shoppersPagination?.total,
                          searchvalue,
                          'csv'
                        )
                      }
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      CSV
                    </button>
                    <button
                      onClick={() =>
                        fetchData(
                          currentPage,
                          shoppersPagination?.total,
                          searchvalue,
                          'excel'
                        )
                      }
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      Excel
                    </button>
                    <button
                      onClick={() =>
                        fetchData(
                          currentPage,
                          shoppersPagination?.total,
                          searchvalue,
                          'pdf'
                        )
                      }
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            {shoppersData.length > 0 && !isPending && (
              <div className={`table-responsive-sm pt-3 pb-4 pl-0 pr-0`}>
                <table
                  className={`${styles.table} table-hover table-striped text-center`}
                >
                  <thead className={`${styles.thead}`}>
                    <tr>
                      {TABLE_HEAD.map((el, i) => {
                        let sIndex = i < 1 ? 1 : false;
                        return (
                          <th key={el.id} className="text-muted">
                            {el.label}{' '}
                            {sIndex && (
                              <span style={{ cursor: 'pointer' }}>
                                <img
                                  className="pl-2"
                                  src="/assets/icons/down.svg"
                                  alt=""
                                />
                              </span>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {!isPending &&
                      shoppersData.length > 0 &&
                      shoppersData?.map((el, i) => {
                        return (
                          <tr
                            style={{
                              cursor: 'pointer',
                            }}
                            className={`${styles.tr}`}
                            key={el._id}
                          >
                            <td
                              onClick={() =>
                                handleViewClick(
                                  '/app/order_details_my_orders/' + el._id
                                )
                              }
                            >
                              <div className=" ">{el?.order_number}</div>
                            </td>
                            <td
                              onClick={() =>
                                handleViewClick(
                                  '/app/order_details_my_orders/' + el._id
                                )
                              }
                            >
                              <p>
                                <BootstrapTooltip
                                  title={
                                    <div>
                                      {!tooltipLoading &&
                                        tooltipData.product_items.length > 0 &&
                                        tooltipData.product_items.map(
                                          (tooltip, ind) => (
                                            <span key={ind} className="mr-1">
                                              {tooltip.product_name},
                                            </span>
                                          )
                                        )}
                                      {!tooltipLoading &&
                                        tooltipData.product_items.length <
                                          1 && <span>Empty</span>}
                                      {tooltipLoading && <span>Loading</span>}
                                    </div>
                                  }
                                >
                                  <span
                                    onMouseEnter={() =>
                                      getHoverProduct(el?._id)
                                    }
                                  >
                                    {Number(
                                      el?.products_ordered
                                    ).toLocaleString()}
                                  </span>
                                </BootstrapTooltip>
                              </p>
                            </td>
                            <td
                              onClick={() =>
                                handleViewClick(
                                  '/app/order_details_my_orders/' + el._id
                                )
                              }
                            >
                              <p>₦ {Number(el?.subtotal).toLocaleString()}</p>
                            </td>
                            <td
                              onClick={() =>
                                handleViewClick(
                                  '/app/order_details_my_orders/' + el._id
                                )
                              }
                            >
                              <p>
                                <span
                                  className={`${
                                    el?.status === 'pending'
                                      ? styles.pending
                                      : el?.status === 'completed'
                                      ? styles.customer
                                      : el?.status === 'refunded'
                                      ? styles.refunded
                                      : styles.abandoned
                                  }`}
                                >
                                  {el?.status === 'completed'
                                    ? 'Completed'
                                    : el?.status === 'pending'
                                    ? 'Pending'
                                    : el?.status === 'refunded'
                                    ? 'Refunded'
                                    : 'Abandoned'}
                                </span>
                              </p>
                            </td>
                            <td
                              onClick={() =>
                                handleViewClick('/app/order_details_my_orders')
                              }
                            >
                              <p>
                                {moment(el?.date_order).format('DD/MM/YYYY')}
                              </p>
                            </td>
                            <td>
                              <div className="dropleft ">
                                <span
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  className={`${styles.action_button} px-3 py-2`}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                >
                                  <img src="/assets/icons/action.svg" alt="" />
                                </span>

                                <div
                                  className="dropdown-menu drop-left"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleViewClick(
                                        '/app/order_details_my_orders/' + el._id
                                      )
                                    }
                                    className="rss dropdown-item btn border-0"
                                  >
                                    View
                                  </button>
                                  {el?.status === 'abandoned' && (
                                    <button
                                      className="rss btn border-0 dropdown-item"
                                      type="button"
                                      onClick={() =>
                                        handleViewClick('/app/carts/' + el?._id)
                                      }
                                    >
                                      Complete Order
                                    </button>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!shoppersData.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          {/* pagination */}
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={shoppersPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={modalOpen}>
          <Box sx={style} className={`mobile_modal_size`}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header} mb-0`}>
                <div>
                  <h5
                    style={{
                      fontSize: '19px',
                      fontWeight: '700',
                    }}
                  >
                    Filter
                  </h5>
                </div>
                <div>
                  <span
                    onClick={handleClose}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <img src="/assets/icons/x.svg" alt="" />
                  </span>
                </div>
              </div>
              <form action="" className="mt-0">
                <div className="row">
                  <div className="col-md-6 mb-1">
                    <div className="mb-3">
                      <label htmlFor="name">Status</label>
                      <Select
                        labelId="s"
                        id="s"
                        fullWidth
                        size="small"
                        value={status}
                        required
                        onChange={handleChange}
                      >
                        <MenuItem value={'select'}>Select</MenuItem>
                        <MenuItem value={'all'}>All</MenuItem>
                        <MenuItem value={'completed'}>Completed</MenuItem>
                        <MenuItem value={'abandoned'}>Abandoned</MenuItem>
                        <MenuItem value={'pending'}>Pending</MenuItem>
                      </Select>
                    </div>
                    <label htmlFor="name">Date </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '45%' }}>
                        <div className="mb-3">
                          <DatePicker
                            showIcon
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            placeholderText="Start"
                            required
                          />
                        </div>
                      </div>
                      <div
                        className="px-1 d-flex align-items-center"
                        style={{
                          height: '40px',
                        }}
                      >
                        {/* sss */}
                        <img
                          src="/assets/icons/line.svg"
                          style={{
                            width: '30px',
                          }}
                          alt=""
                        />
                      </div>
                      <div className="" style={{ width: '45%' }}>
                        <div className="mb-3">
                          <DatePicker
                            showIcon
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            placeholderText="End"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-1">
                    <label htmlFor="price">Price </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            id="price"
                            placeholder="Min"
                            size="small"
                            value={minPrice}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                setMinPrice(inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="px-1 d-flex align-items-center"
                        style={{
                          height: '40px',
                        }}
                      >
                        <img
                          src="/assets/icons/line.svg"
                          style={{
                            width: '30px',
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        className=""
                        style={{
                          width: '100%',
                        }}
                      >
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            placeholder="Max "
                            size="small"
                            value={maxPrice}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                setMaxPrice(inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <label htmlFor="ounit">Order Unit </label>
                    <div className=" d-flex">
                      <div
                        className=""
                        style={{
                          width: '100%',
                        }}
                      >
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            id="ounit"
                            placeholder="Min"
                            size="small"
                            value={minOrderUnit}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                setMinOrderUnit(inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="px-1 d-flex align-items-center"
                        style={{
                          height: '40px',
                        }}
                      >
                        <img
                          src="/assets/icons/line.svg"
                          style={{
                            width: '30px',
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        className=""
                        style={{
                          width: '100%',
                        }}
                      >
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            placeholder="Max "
                            size="small"
                            value={maxOrderUnit}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                setMaxOrderUnit(inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <button
                    type="button"
                    onClick={() => {
                      handleClose();
                      setFilter(!filter);
                    }}
                    className={`${styles.modal_btn} btn btn-block`}
                  >
                    Proceed
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
