/* eslint-disable no-unreachable */

// ----------------------------------------------------------------------
// import styles from './Page.module.css'
import styles from './inviteAdmin.module.css';
import Logo from '../logo/appLogo';
import { NavLink as RouterLink } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useParams, useNavigate } from 'react-router-dom';
import { BaseUrlContext } from 'src/context/BaseUrlContext';

export default function InviteStore() {
  let navigate = useNavigate();
  const { shop_id, token } = useParams();
  const { dispatch } = useParams();
  const [category, setCategory] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setCPassword] = useState('');
  //   const [agree] = useState(false);
  const [doesNotMath, setDoesNotMath] = useState(false);
  const [signUpError, setSignUpError] = useState(null);
  const [response, setResponse] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [showText, setShowText] = useState(false);
  const [showText1, setShowText1] = useState(false);
  const baseUrl = useContext(BaseUrlContext);
  const [pageContent, setPageContent] = useState(
    'Track and monitor <br /> your customers on <br /> a single dashboard'
  );
  const [pageDescription, setDescription] = useState(
    '  Make informed sales decisions by monitoring consumer <br /> behaviours, brand acceptance and market performance.'
  );
  const [bg, setBg] = useState('/assets/happyMan.jpg');
  const prevCategoryRef = useRef('');
  const notify = () => toast.success('Registration successful .');
  const notifyError = () =>
    toast.error("That didn't work please try again later .");
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = mm + '/' + dd + '/' + yyyy;
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    // Function to update the year every second
    const updateYear = () => {
      setCurrentYear(new Date().getFullYear());
    };

    // Set up an interval to update the year every second
    const intervalId = setInterval(updateYear, 1000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    if (category !== '' && category !== prevCategoryRef.current) {
      if (category === 'brand') {
        setPageContent(
          'Track and monitor <br /> your customers on <br /> a single dashboard'
        );
        setDescription(
          'Make informed sales decisions by monitoring consumer <br /> behaviours, brand acceptance and market performance.'
        );
        setBg('/assets/happyMan.jpg');
      } else if (category === 'shop') {
        setPageContent(
          ' Buy, sell and monitor <br />  your customers on a <br /> single platform'
        );
        setDescription(
          ' Make purchases and keep track of all your business transactions in one <br /> convenient place.'
        );
        setBg('/assets/retailerBg.svg');
      } else {
        setPageContent('');
      }
      prevCategoryRef.current = category;
    }
  }, [category]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleCPasswordChange = (event) => {
    setCPassword(event.target.value);
  };

  const changeType = () => {
    setShowText(!showText);
  };
  const changeType1 = () => {
    setShowText1(!showText1);
  };

  function HandleFormSubmit(e) {
    e.preventDefault();

    if (confirm_password !== password) {
      setDoesNotMath(true);
      return;
    }

    if (password.length < 8) {
      setSignUpError('Password must be at least 8 characters long');
      return;
    }

    setSignUpError(null);
    let payload = {
      first_name: '',
      name: name,
      password: password,
      confirmPassword: confirm_password,
      shop_id,
      token,
    };

    const fetchData = async () => {
      try {
        setIsPending(true);
        // Make the API request
        const res = await axios({
          method: 'PUT',
          url: `${baseUrl}/shop/administration/complete-member/${shop_id}/${token}`,
          data: payload,
          headers: {
            Authorization: `x-auth-token ${localStorage.getItem('token')}`,
          },
        });
        localStorage.setItem('e', name);
        localStorage.setItem('name', payload.last_name);
        localStorage.setItem('auth', JSON.stringify(res.data.data));
        // localStorage.setItem('dl', today);
        // dispatch({ type: 'LOGIN', payload: res.data.data })

        setResponse(res.data.message);
        setPassword('');
        setCPassword('');
        setName('');
        setTimeout(() => {
          navigate('/app/dashboard');
        }, 2000);
        setCategory('manufacturer');
      } catch (err) {
        notifyError();
      } finally {
        setIsPending(false);
      }
    };
    fetchData(true);
  }

  return (
    <>
      <div className={`row ${styles.sav}`}>
        <div className="col-md-6 d-none d-md-block">
          <div
            className={styles.bg}
            style={{
              backgroundImage: `
                url(${
                  category === 'brand'
                    ? '/assets/happyMan.jpg'
                    : category === 'shop'
                    ? '/assets/retailerBg.svg'
                    : '/assets/happyMan.jpg'
                })`,
              backgroundPosition: 'top center',
            }}
          >
            <img className={styles.star} src="/assets/Stars.svg" alt="stars" />
            <div className={styles.content}>
              <h5 className={`${styles.header_text}`}>
                Find and manage
                <br />
                customers in one
                <br />
                platform.
              </h5>
              <p style={{ width: '27em', maxWidth: '90%' }}>
                Create a free account and get full access to all features for
                30-days. No credit card needed. Get started in 2 minutes.
              </p>
              <div className={styles.icon_n_text}>
                <img
                  className={styles.icons}
                  src="/assets/Avatars.svg"
                  alt="avatars"
                />
                <span style={{ color: '#EAECF0' }}>Join 40,000+ users</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 ">
          <div className={`${styles.sign_up} container`}>
            <form
              style={{ width: '30em', maxWidth: '100%' }}
              onSubmit={HandleFormSubmit}
            >
              <Logo />
              <h4
                className="mb-4 mt-5"
                style={{
                  fontSize: '36px',
                  fontWeight: '600',
                  color: '#111827',
                }}
              >
                Sign In
              </h4>
              {signUpError && (
                <Alert
                  style={{
                    border: '1px solid red',
                  }}
                  severity="error"
                >
                  {signUpError}
                </Alert>
              )}
              {response && (
                <Alert
                  style={{
                    border: '1px dotted gray',
                  }}
                  severity="success"
                >
                  {response}
                </Alert>
              )}

              <div className="my-4 inputs_n_label">
                <label htmlFor="name">Full Name*</label>
                <TextField
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                  id="name"
                  placeholder="Enter Name"
                  size="small"
                  fullWidth
                />
                {/* <input   id='email' className='form-control' placeholder='' /> */}
              </div>
              <div className="my-4 inputs_n_label">
                <label htmlFor="password"> Create Password*</label>
                <TextField
                  type={showText ? 'text' : 'password'}
                  value={password}
                  onChange={handlePasswordChange}
                  id="password"
                  placeholder="Enter password"
                  size="small"
                  fullWidth
                />
                <div className="icon mt-2" onClick={changeType}>
                  {!showText && <img src="/assets/icons/eye.svg" alt="" />}
                  {showText && <img src="/assets/icons/eye-off.svg" alt="" />}
                </div>
              </div>
              <div className="my-4 inputs_n_label">
                <label htmlFor="c_pass">Confirm password</label>
                <TextField
                  type={showText1 ? 'text' : 'password'}
                  value={confirm_password}
                  onInput={handleCPasswordChange}
                  id="c_pass"
                  placeholder="Enter password"
                  size="small"
                  fullWidth
                />
                <div className="icon mt-2" onClick={changeType1}>
                  {!showText1 && <img src="/assets/icons/eye.svg" alt="" />}
                  {showText1 && <img src="/assets/icons/eye-off.svg" alt="" />}
                </div>
                {doesNotMath && (
                  <small className="text-danger">
                    Password confirmation does not match
                  </small>
                )}
              </div>
              {/* <div className='mt-4 mb-3 inputs_n_label'>
                <label htmlFor='email'>Verification Code*</label>
                <div style={{width: '100%', position: 'relative'}}>
                    <TextField
                    type='text'
                    value={email}
                    onChange={handleNameChange}
                    id="verify"
                    placeholder="Verification Code"
                    size="small"
                    fullWidth
                    />
                    <button
                      className='btn text-primary'
                      style={{
                        position: 'absolute',
                        top: '50%',
                        right: '3px',
                        transform: 'translate(0%, -50%)'}}>
                          Get Code
                    </button>
                </div>
              </div>
              <div className="form-check-inline mb-3">
                <label htmlFor='agree' className='form-check-label'>
                    <span>A 4 digit code would be sent to your mail.</span>
                </label>
              </div> */}

              <div className={styles.buttons}>
                {!isPending && (
                  <button
                    type="submit"
                    className={`btn btn-block ${styles.btn_custom_color}`}
                  >
                    <span className={`${styles.span}`}>Sign in</span>
                  </button>
                )}
                {isPending && (
                  <button
                    disabled
                    className={`btn btn-block ${styles.btn_custom_color}`}
                  >
                    Signing you in &nbsp;
                    {isPending && (
                      <CircularProgress
                        style={{ color: 'white' }}
                        size="1rem"
                      />
                    )}
                  </button>
                )}
                <p className="text-center mt-5">
                  Contact Customer Services
                  <RouterLink to="mailto:admin@flexdeals.com.ng">
                    admin@flexdeals.com.ng
                  </RouterLink>
                </p>
              </div>
            </form>
            <div className={`${styles.signin_footer} mt-5`}>
              <p>© FlexDeals {currentYear}</p>
              <p>
                <RouterLink href="mailto:admin@flexdeals.com.ng">
                  admin@flexdeals.com.ng
                </RouterLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
