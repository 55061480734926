// ----------------------------------------------------------------------
import styles from '../../BrandOwner.module.css';
import { NavLink as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ProductsModal from '../../Modals/ProductsModal.js';
import bus from 'src/bus';
import axiosInstance from 'src/axiosInstance';
import { BallTriangle } from 'react-loader-spinner';
import { downloadFile2 } from 'src/utils';
import CircularProgress from '@mui/material/CircularProgress';

import { Card } from '@mui/material';
import Scrollbar from '../../../../components/scrollbar';
// sections
// mock
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useLayoutEffect } from 'react';
import SingleView from './SingleView';
import { useAuth } from 'src/hooks/useAuth';
import { Pagination } from 'src/components';
// ----------------------------------------------------------------------
import toast from 'react-hot-toast';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 190,
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 1,
};

let app_view = localStorage.getItem('ptvp') ?? 0;
export default function ProductsPageBrandowwner() {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();
  const { data } = useAuth();
  const [category, setCategory] = useState(1);
  const [appView, setAppview] = useState(app_view);
  const [categoriesData, setdata] = useState([]);
  const [categoryPagination, setCategoryPagination] = useState({ total: 0 });
  const notifyError = (err) => toast.error(err);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchvalue, setsearch] = useState('');
  const [exporting, setExporting] = useState(false);

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvp');

      // Your code here.
    },
    []
  );

  const searchFilter = (e) => {
    setsearch(e.target.value);
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const handleViewClick = (d) => {
    localStorage.setItem('shopSingleProduct', JSON.stringify(d.category_name));
    navigate('/app/products/' + d._id);
    // handle view click action
  };

  const reloadPage = (val) => {
    setAppview(val);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchvalue]);
  bus.on('reload', () => {
    fetchData();
  });
  const fetchData = async (
    page = 1,
    perpage = 10,
    search = searchvalue,
    file = null
  ) => {
    if (file) {
      setExporting(true);
      let queryParams = new URLSearchParams({
        page,
        perpage,
        search,
        export_type: file,
      }).toString();

      try {
        await downloadFile2(
          'All Products',
          `/shop/product/inventory/list?${queryParams}`,
          file
        );
      } finally {
        // alert()
        setExporting(false);
      }
      return;
    }

    setIsPending(true);
    axiosInstance
      .get('/shop/product/inventory/list', {
        params: {
          page,
          perpage,
          search,
        },
      })
      .then((res) => {
        setdata(res.data.data.data);
        setCategoryPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch inventory list, please try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  return Number(appView) === 0 ? (
    <>
      <div className="mt-0 px-2">
        <div className={`${styles.filters}  mss`}>
          <div className="row">
            <div className={`${styles.hold_input1} col-md-3   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={searchvalue}
                onChange={searchFilter}
                placeholder="Search"
                style={{
                  textIndent: '25px',
                  width: '100%',
                }}
                className=" form-control"
              />
            </div>
          </div>
          <Card
            style={{ marginTop: '15px', borderRadius: '10px' }}
            className="p-0"
          >
            <div className="p-4 border-bottom">
              <div className="d-md-flex justify-content-between">
                <div className="pb-3 pb-md-0">
                  <h5 className="table_desc_header">Product Inventory</h5>
                  <small style={{ fontWeight: '200', fontSize: '12px' }}>
                    Add product to your store so shoppers can buy from you
                  </small>
                </div>
                <div className="pt-md-2 d-md-flex">
                  <div className="dropleft ">
                    <button
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className={`${styles.export_btn} btn m-0`}
                    >
                      <span
                        style={{
                          paddingTop: '8px',
                        }}
                      >
                        <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                        {!exporting && <span>Export</span>}
                        {exporting && (
                          <CircularProgress
                            style={{
                              color: 'white',
                              display: 'inline-block',
                            }}
                            size="1rem"
                          />
                        )}
                      </span>
                      &nbsp; &nbsp;
                      <span
                        style={{
                          borderLeft: '1px solid white',
                          display: 'inline-block',
                          height: 'inherit',
                          paddingTop: '8px',
                          paddingLeft: '8px',
                          textAlign: 'center',
                        }}
                      >
                        <img src="/assets/icons/arrowDown.svg" alt="" />
                      </span>
                    </button>
                    <div
                      className="dropdown-menu drop-left"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <button
                        onClick={() =>
                          fetchData(currentPage, 200000, searchvalue, 'csv')
                        }
                        type="button"
                        className="rss dropdown-item btn border-0"
                      >
                        CSV
                      </button>
                      <button
                        onClick={() =>
                          fetchData(currentPage, 200000, searchvalue, 'excel')
                        }
                        type="button"
                        className="rss dropdown-item btn border-0"
                      >
                        Excel
                      </button>
                      <button
                        onClick={() =>
                          fetchData(currentPage, 200000, searchvalue, 'pdf')
                        }
                        type="button"
                        className="rss dropdown-item btn border-0"
                      >
                        PDF
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Scrollbar>
              <div className={`table-responsive-sm  pt-3 pb-3 pl-0 pr-0`}>
                <table className={`${styles.table} table-hover table-striped`}>
                  <thead className={`${styles.thead}`}>
                    <tr>
                      <th
                        style={{
                          width: '10%',
                        }}
                        className="text-muted"
                      >
                        S/N
                      </th>
                      <th
                        style={{
                          width: '40%',
                        }}
                        className="text-muted"
                      >
                        Category Name
                        <img
                          className="pl-2"
                          src="/assets/icons/down.svg"
                          alt=""
                        />
                      </th>
                      <th
                        style={{
                          width: '30%',
                        }}
                        className="text-muted "
                      >
                        No of Product
                      </th>
                      <th
                        style={{
                          width: '30%',
                        }}
                        className="text-muted"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isPending &&
                      categoriesData?.length > 0 &&
                      categoriesData?.map((el, i) => (
                        <tr key={i} className={`${styles.tr} `}>
                          <td>
                            <span className="ml-2">
                              {(currentPage - 1) * itemsPerPage + i + 1}
                            </span>
                          </td>
                          <td>
                            <div className=" ">
                              <p className="mb-0" style={{ fontSize: '14px' }}>
                                {el?.category_name}
                              </p>
                            </div>
                          </td>
                          <td>
                            <p>
                              {/* <BootstrapTooltip title={
                                <div>
                                  Nestle Milo, Pure life table water, <br /> Maggi, Nestcafe
                                </div>
                              }> */}
                              <span>{el?.total_product}</span>

                              {/* </BootstrapTooltip> */}
                            </p>
                          </td>
                          <td>
                            {data.is_verified && (
                              <button
                                onClick={() => handleViewClick(el)}
                                className={`btn ${styles.view_action}`}
                              >
                                <img
                                  src="/assets/icons/eyewhite.svg"
                                  className="pr-1"
                                  alt=""
                                />
                                View
                              </button>
                            )}
                            {!data.is_verified && (
                              <button
                                disabled
                                className={`btn ${styles.view_action}`}
                              >
                                <img
                                  src="/assets/icons/eyewhite.svg"
                                  className="pr-1"
                                  alt=""
                                />
                                View
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center">
                {isPending && (
                  <BallTriangle
                    height={50}
                    width={55}
                    radius={5}
                    color="#af3585"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />
                )}
              </div>
              {!categoriesData.length && !isPending && (
                <div className="alert alert-[#af3585] text-center">
                  <h4>No record found</h4>
                </div>
              )}
            </Scrollbar>
            <div>
              <Pagination
                currentPage={currentPage}
                dataPagination={categoryPagination}
                itemsPerPage={itemsPerPage}
                setCurrentPage={setCurrentPage}
                fetchData={fetchData}
              />
            </div>
          </Card>
        </div>
        {/* Modals */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={open}>
            <Box className={`mobile_modal_size`} sx={style}>
              <div className={`${styles.modal_content}`}>
                <div className={`${styles.modal_header}`}>
                  <h3>Choose Category</h3>
                  <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                    <img src="/assets/icons/x.svg" alt="" />
                  </span>
                </div>

                <form action="">
                  <div className="mb-3">
                    <label htmlFor="name">Category Name</label>
                    <Select
                      labelId="s"
                      id="s"
                      fullWidth
                      size="small"
                      value={category}
                      required
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>Breveges</MenuItem>
                      <MenuItem value={2}>Table water</MenuItem>
                      <MenuItem value={3}>Snacks</MenuItem>
                    </Select>
                  </div>
                  <div className="mb-4">
                    <button
                      type="button"
                      onClick={handleClose}
                      className={`${styles.modal_btn} btn btn-block`}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </Box>
          </Fade>
        </Modal>

        <ProductsModal closeModal={() => setShowDiscount(false)} />
      </div>
    </>
  ) : (
    <SingleView key={1} reload={reloadPage} />
  );
}
