import styles from '../index.module.css';

import { Card } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import SalesDetails from '../Details';
import { useState, useEffect } from 'react';
import { useLayoutEffect } from 'react';
import GlobalModal from 'src/Modals/GlobalModal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useAuth } from 'src/hooks/useAuth';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { useDropDowns } from 'src/hooks/useDropDowns';
import { useCategories } from 'src/hooks/useCategories';
import { useProducts } from 'src/hooks/useProducts';
import { Pagination } from 'src/components';
import { Avatar } from '@mui/material';
// import USERLIST from '../../../_mock/order';
import moment from 'moment';
import { BallTriangle } from 'react-loader-spinner';
import { downloadFile2 } from 'src/utils';
// ----------------------------------------------------------------------
import Filter1 from 'src/pages/Filter/Filter1';
import bus from 'src/bus';
import toast from 'react-hot-toast';
import axiosInstance from 'src/axiosInstance';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const TABLE_HEAD = [
  { id: 'shopOwnersName', label: 'Merchants Name', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'orderUnit', label: 'Order Unit', alignRight: false },
  { id: 'brands', label: 'Brands', alignRight: false },
  { id: 'customerLocation', label: 'Customer Location', alignRight: false },
  { id: 'price (₦)', label: 'Price', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'dateOrdered', label: 'Date Ordered', alignRight: false },
  // { id: 'created_at', label: 'Total Quantity', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
// ----------------------------------------------------------------------

function changeView(d) {
  localStorage.setItem('ptvs', d);
}

// ptvs=PAGE TO VIEW (SALES)

let app_view = localStorage.getItem('ptvs') ?? 0;
export default function ShopOwners() {
  const { data } = useAuth();
  const dropdownFilters = useDropDowns();
  const categories = useCategories();
  const productLists = useProducts();

  const [appView, setAppview] = useState(app_view);
  // const [product, setProduct] = useState(1);
  const [productsAdAdded, setProductsAdded] = useState([]);
  const [category, setCategory] = useState(1);
  const [showDiv, setShowDiv] = useState(data.merchant_type === 'brand');
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const [selectedFilter, setValue] = useState(dropdownFilters[0]?.name);
  const [selectedFilter1, setValue1] = useState(dropdownFilters[0]?.name);

  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handleProductChange = (event) => {
    setProduct(event.target.value);
    let data = productsAdAdded;
    let val = event.target.value;
    if (val != 1) {
      if (data.includes(val) == false) {
        data.push(val);
        setProductsAdded(data);
      }
    }
  };

  const removeFromList = (index) => {
    let data = [...productsAdAdded];
    data = data.filter((_, i) => i !== index);
    if (data['length'] === 0) setProduct(1);
    setProductsAdded(data);
  };

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvs');
    },
    []
  );

  const handleViewClick = (d) => {
    navigate('/app/purchase_details_so/' + d);
    // handle view click action
  };

  const handleChange = (/* args passed from the child component */) => {
    handleViewClick(0);
  };

  const handleClickList = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClickList1 = (e) => {
    setAnchorEl1(e.currentTarget);
  };
  const handleSelectFilter = (val, i) => {
    switch (i) {
      case 0:
        setAnchorEl(null);
        setValue(val);

        break;
      case 1:
        setAnchorEl1(null);
        setValue1(val);
        break;

      default:
        break;
    }
  };

  // pagination
  const [purchaseData, setPurchaseData] = useState([]);
  const [analyticsDataMyOrder, setAnalyticsDataMyOrder] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [purchasePagination, setPurchasePagination] = useState({ total: 0 });
  const notifyError = (err) => toast.error(err);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  //filter fields

  const [location, setLocation] = useState('');
  const [product, setProduct] = useState('select');
  const [status, setStatus] = useState('select');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [minOrderUnit, setMinOrderUnit] = useState('');
  const [maxOrderUnit, setMaxOrderUnit] = useState('');
  const [searchvalue, setSearchvalue] = useState('');
  const [filter, setFilter] = useState(false);
  const [submitFilter, setSubmitFilter] = useState(false);
  const [filterSearch, setFilterSearch] = useState({
    filterLocation: '',
    category: '',
    filterStatus: '',
    startDateFilter: '',
    endDateFilter: '',
    priceFilter: '',
    priceToFilter: '',
    orderUnitFilter: '',
    orderUnitToFilter: '',
  });

  useEffect(() => {
    fetchData();
  }, [filter, searchvalue, submitFilter]);

  const searchFilter = (e) => {
    setSearchvalue(e.target.value);
  };

  // useEffect(() => {
  //  console.log(purchaseData);
  // }, [purchaseData]);

  bus.on('reload', () => {
    fetchData();
  });

  const handleFilterStatus = (key, value) => {
    setFilterSearch((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleSubmitFilter = () => {
    handleClose();
    setSubmitFilter(!submitFilter);
  };

  const handleDownloadFile = async (extension) => {
    try {
      const result = `/admin/purchases/get-shopowners-purchases?page=${purchasePagination.currentPage}&perpage=${purchasePagination.total}&export_type=${extension}`;
      await downloadFile2('Store Purchases', result, extension);
    } catch (err) {
      notifyError(`Unble to export ${extension} file`);
    }
  };

  //Getting Table Data
  const fetchData = (page = 1, perpage = 10) => {
    let filter_payload = {};
    if (filterSearch.priceToFilter != '') {
      filter_payload.max_price = filterSearch.priceToFilter;
    }
    if (filterSearch.priceFilter != '') {
      filter_payload.min_price = filterSearch.priceFilter;
    }
    if (
      filterSearch.filterStatus != 'select' &&
      filterSearch.filterStatus != 'all'
    ) {
      filter_payload.status = filterSearch.filterStatus;
    }
    if (searchvalue != '') {
      filter_payload.search = searchvalue;
    }
    if (startDate != '') {
      filter_payload.dateStart = startDate;
    }
    if (endDate != '') {
      filter_payload.dateEnd = endDate;
    }
    if (filterSearch.orderUnitToFilter != '') {
      filter_payload.max_order_unit = filterSearch.orderUnitToFilter;
    }
    if (filterSearch.orderUnitFilter != '') {
      filter_payload.min_order_unit = filterSearch.orderUnitFilter;
    }
    if (filterSearch.category != '') {
      filter_payload.category = filterSearch.category;
    }
    if (filterSearch.filterLocation != '') {
      filter_payload.location = filterSearch.filterLocation;
    }
    setIsPending(true);
    axiosInstance
      .get('/admin/purchases/get-shopowners-purchases', {
        params: {
          page,
          perpage,
          ...filter_payload,
        },
      })
      .then((res) => {
        setPurchaseData(res.data.data.data);
        setPurchasePagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch purchases, please try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  return Number(appView) === 0 ? (
    <>
      <div className="mt-4 pt-2 ">
        <div className="header d-block d-md-none mb-4">
          <h4 className={`mb-0 pb-0 `}>Purchases</h4>

          <p style={{ fontSize: '14px' }} className={`mb-0 pb-0`}>
            Manage and monitor your purchases here.
          </p>
        </div>

        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-6 col-md-3   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={searchvalue}
                onChange={searchFilter}
                placeholder="Search"
                style={{ textIndent: '25px', width: '100%' }}
                className=" form-control"
              />
            </div>
            <div className="col-6 col-md-9  text-right">
              <div className="dropleft ">
                <button
                  className="btn  border"
                  onClick={() => setModalOpen(true)}
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    backgroundColor: '#fff',
                    border: '1px solid #D0D5DD',
                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    borderRadius: '4px',
                  }}
                >
                  <img
                    className=""
                    style={{ display: 'inline', width: '17px' }}
                    src="/assets/icons/filterlines.svg"
                    alt=""
                  />
                  &nbsp;
                  <small className="d-none d-md-inline-block">Filters</small>
                </button>

                <div
                  className="dropdown-menu drop-left"
                  aria-labelledby="dropdownMenuButton"
                >
                  {/* <button type="button" onClick={() => setOrderType('All')} className="rss dropdown-item btn border-0">All</button>
                  <button type="button" onClick={() => setOrderType('Abandoned')} className="rss dropdown-item btn border-0">Abandoned </button>
                  <button type="button" onClick={() => setOrderType('Completed')} className="rss dropdown-item btn border-0">Completed</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Card
          style={{ marginTop: '50px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-3 ">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">
                  {/* {orderType}  */}
                  Store Purchase
                </h5>
                <small
                  style={{
                    fontWeight: '200',
                    fontSize: '14px',
                    display: 'inline-block',
                    marginTop: '0px',
                  }}
                >
                  Details of orders placed by stores on Retail Xpress
                </small>
              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`${styles.export_btn} btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: '8px',
                      }}
                    >
                      <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                      Export
                    </span>
                    &nbsp; &nbsp;
                    <span
                      style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <img src="/assets/icons/arrowDown.svg" alt="" />
                    </span>
                  </button>

                  <div
                    className="dropdown-menu drop-left"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('csv')}
                    >
                      CSV
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('excel')}
                    >
                      Excel
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('pdf')}
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`table_responsive pt-3 pb-3 pl-0 pr-0`}>
              <table
                className={`${styles.table} table-hover table-striped`}
                style={{ minWidth: '120em' }}
              >
                <thead className={`${styles.thead}`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      let sIndex = i < 1 ? 1 : false;

                      return (
                        <th key={el.id} className="text-muted">
                          {el.label}{' '}
                          {sIndex && (
                            <span style={{ cursor: 'pointer' }}>
                              <img
                                className="pl-2"
                                src="/assets/icons/down.svg"
                                alt=""
                              />
                            </span>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {!isPending &&
                    purchaseData.map((el, i) => {
                      return (
                        <tr key={el._id} className={`${styles.tr}`}>
                          <td>
                            <Avatar
                              className={`${styles.avatrr} mr-3 float-md-left`}
                              alt=""
                              src={el.store_logo}
                            />
                            <div className=" ">
                              <span>{el.store_name}</span>
                              <br />
                              <span>{el.store_id}</span>
                            </div>
                          </td>
                          <td>
                            <p>{el.store_category}</p>
                          </td>
                          <td onClick={() => handleViewClick(el._id)}>
                            <p>{el.orders}</p>
                          </td>
                          <td onClick={() => handleViewClick(el._id)}>
                            <Avatar
                              className={`${styles.avatrr} mr-3 float-md-left`}
                              alt=""
                              src={el.brands[0].business_logo}
                            />
                            <div className=" ">
                              <p className="mb-0" style={{ fontSize: '14px' }}>
                                <strong>{el.brands[0]?.business_name}</strong>
                              </p>
                              <span style={{ fontSize: '13px' }}>
                                {el.brands[0]?.brand_id}
                              </span>
                            </div>
                          </td>
                          <td onClick={() => handleViewClick(el._id)}>
                            <p>{el.location}</p>
                          </td>
                          <td onClick={() => handleViewClick(el._id)}>
                            <p>{Number(el.price).toLocaleString()}</p>
                          </td>
                          <td onClick={() => handleViewClick(el._id)}>
                            {/* <span className="verified_badge">
                                {el.status}
                            </span> */}
                            <p>
                              <span
                                className={`${
                                  el.status === 'pending'
                                    ? styles.pending
                                    : styles.customer
                                }`}
                              >
                                {el.status}
                              </span>
                            </p>
                          </td>
                          <td onClick={() => handleViewClick(el._id)}>
                            <p>{moment(el.date_order).format('DD/MM/YYYY')}</p>
                          </td>
                          <td>
                            <div className="dropleft ">
                              <span
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                className={`${styles.action_button} px-3 py-2`}
                                style={{
                                  cursor: 'pointer',
                                }}
                              >
                                <img src="/assets/icons/action.svg" alt="" />
                              </span>

                              <div
                                className="dropdown-menu drop-left"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <button
                                  type="button"
                                  onClick={() => handleViewClick(el._id)}
                                  className="rss dropdown-item btn border-0"
                                >
                                  View
                                </button>
                                {/* {customStatus === 'Abandoned' && <button
                                className="rss btn border-0 dropdown-item"
                                type="button"
                                onClick={handleSendReminderClick}
                              >
                                Send Reminder
                              </button>} */}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!purchaseData.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={purchasePagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#FFFFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label className="font-weight-bold" htmlFor="loc">
                    Location
                  </label>
                  <TextField
                    required
                    type="text"
                    id="loc"
                    placeholder="Enter Location"
                    size="small"
                    name="filterLocation"
                    fullWidth
                    value={filterSearch.filterLocation}
                    onChange={(e) =>
                      handleFilterStatus('filterLocation', e.target.value)
                    }
                  />
                </div>
                <div className="mb-3">
                  <label className="font-weight-bold" htmlFor="name">
                    Status
                  </label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={filterSearch.category}
                    defaultValue={'select'}
                    name="category"
                    required
                    onChange={(e) =>
                      handleFilterStatus('category', e.target.value)
                    }
                  >
                    <MenuItem value="select">Select Status</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                  </Select>
                </div>

                <div className="mb-3">
                  <label className="font-weight-bold" htmlFor="name">
                    Payment Method
                  </label>
                  <Select
                    labelId="mod"
                    id="mod"
                    fullWidth
                    size="small"
                    name="filterStatus"
                    value={filterSearch.filterStatus}
                    defaultValue={'select'}
                    required
                    onChange={(e) =>
                      handleFilterStatus('filterStatus', e.target.value)
                    }
                  >
                    <MenuItem value="select">Select Payment Method</MenuItem>
                    <MenuItem value="cash">Cash</MenuItem>
                    <MenuItem value="card">Card</MenuItem>
                    <MenuItem value="transfer">Transfer</MenuItem>
                    <MenuItem value="wallet">Wallet</MenuItem>
                  </Select>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <label className="font-weight-bold" htmlFor="date">
                  Date{' '}
                </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
                {showDiv && (
                  <div>
                    <label className="font-weight-bold" htmlFor="ora">
                      Total amount{' '}
                    </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            id="price"
                            placeholder="Min"
                            size="small"
                            name="priceFilter"
                            value={filterSearch.priceFilter}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) > 0
                              ) {
                                handleFilterStatus('priceFilter', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="px-1 d-flex   align-items-center "
                        style={{ height: '40px' }}
                      >
                        <img
                          src="/assets/icons/line.svg"
                          style={{ width: '30px' }}
                          alt=""
                        />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            placeholder="Max "
                            size="small"
                            name="priceToFilter"
                            value={filterSearch.priceToFilter}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) > 0
                              ) {
                                handleFilterStatus('priceToFilter', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <label className="font-weight-bold" htmlFor="name">
                      Date{' '}
                    </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '70%' }}>
                        <div className="mb-3">
                          <DatePicker
                            showIcon
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            placeholderText="Start"
                            required
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                      <div
                        className="px-1 d-sflex   align-items-center "
                        style={{ heigsht: '40px' }}
                      >
                        {/* sss */}
                        <img
                          src="/assets/icons/line.svg"
                          style={{ width: '30px' }}
                          alt=""
                        />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <DatePicker
                            showIcon
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            placeholderText="End"
                            required
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!showDiv && (
                  <div>
                    <label className="font-weight-bold" htmlFor="patronage">
                      Price{' '}
                    </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            id="price"
                            placeholder="Min"
                            size="small"
                            name="priceFilter"
                            value={filterSearch.priceFilter}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) > 0
                              ) {
                                handleFilterStatus('priceFilter', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="px-1 d-flex   align-items-center "
                        style={{ height: '40px' }}
                      >
                        <img
                          src="/assets/icons/line.svg"
                          style={{ width: '30px' }}
                          alt=""
                        />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            placeholder="Max "
                            size="small"
                            name="priceToFilter"
                            value={filterSearch.priceToFilter}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) > 0
                              ) {
                                handleFilterStatus('priceToFilter', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <label className="font-weight-bold" htmlFor="ounit">
                  Order Unit{' '}
                </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        name="orderUnitFilter"
                        value={filterSearch.orderUnitFilter}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === '' || parseFloat(inputValue) > 0) {
                            handleFilterStatus('orderUnitFilter', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        name="orderUnitToFilter"
                        value={filterSearch.orderUnitToFilter}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === '' || parseFloat(inputValue) > 0) {
                            handleFilterStatus('orderUnitToFilter', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={handleSubmitFilter}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
    </>
  ) : (
    <SalesDetails key={1} onChange={handleChange} />
  );
}
