

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
import styles from './Page.module.css'
import Logo from "../logo/appLogo"
import { useNavigate } from "react-router-dom";
import bus from 'src/bus'
import { useState, forwardRef } from "react"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useAuth } from "src/hooks/useAuth";

export default function DeleteAccountSteps() {

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  }

  return (
    <>

      <div className={`row ${styles.sav}`}>
        <div className="col-md-5 pr-md-0 d-none d-md-block">
          <div className={styles.bg}    >
            <div className={styles.content}>
              <h5>
                Welcome, Let's get to know <br /> more about your business
              </h5>

              <ul className={`${styles.features} list-unstyled`}>
                <li>
                  <img src='/assets/check-circle.svg' alt='ic' />
                  <small>
                    Your business becomes public to wholesalers and retailers
                  </small>
                </li>
                <li>
                  <img src='/assets/check-circle.svg' alt='ic' />

                  <small>
                    Businesses with completed profiles are 3x more likely to be <br /> seen by customers

                  </small>
                </li>
                <li>
                  <img src='/assets/check-circle.svg' alt='ic' />

                  <small>
                    Robust dashboard including real time data for making sales <br /> decisions

                  </small>
                </li>
                <li>
                  <img src='/assets/check-circle.svg' alt='ic' />

                  <small>
                    Unlimited access to all the features on your dashboard

                  </small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={`col-md-7 pl-md-0 ${styles.kyc_cover}`}>
          <div className='px-md-5'>
            <div className='py-3'>
              <Logo />
            </div>

            <div className={styles.kyc_content}>
              <div className={styles.body_content}>
              <div className=''>
                <h4>
                  Steps to Delete Your Account on Our Platforms

                </h4>

                <div className='mt-4'>
                <p style={{
                  fontSize: '14px',
                  lineHeight: '2',
                  color: '#374151',
                }}>
                  Learn how to delete your account efficiently, whether you're using the web admin or the mobile app. This guide provides step-by-step instructions for each platform, ensuring a smooth account deletion process.
                </p>
                <p style={{
                  fontSize: '14px',
                  lineHeight: '2',
                  color: '#374151',
                }}>
                  On Web Admin:
                </p>

                <p style={{
                  fontSize: '14px',
                  lineHeight: '2',
                  color: '#374151',
                }}>
                  <ol style={{
                    paddingLeft: '30px'
                  }}>
                    <li>Navigate to the settings page.</li>
                    <li>Find and select the option to delete your account.</li>
                  </ol>
                </p>

                <p style={{
                  fontSize: '14px',
                  lineHeight: '2',
                  color: '#374151',
                }}>
                  On Mobile App:
                </p>

                <p style={{
                  fontSize: '14px',
                  lineHeight: '2',
                  color: '#374151',
                }}>
                  <ol style={{
                    paddingLeft: '30px'
                  }}>
                    <li>Open the mobile app and go to your profile.</li>
                    <li>Look for the option to delete your account and select it.</li>
                  </ol>
                </p>

                <p style={{
                  fontSize: '14px',
                  lineHeight: '2',
                  color: '#374151',
                }}>
                  This concise guide empowers users to delete their accounts with ease, regardless of the platform they're using.
                </p>
                </div>
              </div>
              </div>

            </div>
          </div>
          <div className={`${styles.download} px-md-4`}>
            <div style={{ height: '50px' }} className="align-items-center  d-flex  justify-content-between">
              <div className={`${styles.content_control}`}>
                <button type='button' className='btn btn-[#53138D] bg-[#8c2d915d] pl-0' onClick={() => handleBack()}>
                  <i className='bi bi-chevron-left'></i> Back
                </button>
              </div>

              {/* <div className={`${styles.content_control} px-4`}>
                <button type='button' className='btn btn-[#53138D] bg-[#8c2d915d]' onClick={handleClickOpen}>
                  Delete Account ?
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
