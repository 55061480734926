

// ----------------------------------------------------------------------
import styles from "../Sales.module.css"
import { useState } from "react"
import TextField from '@mui/material/TextField';
import {
  Card,
  Avatar,
} from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import LiveLocation from "src/pages/LiveLocation";

// mock
import { useLayoutEffect } from "react";
import GlobalModal from "src/Modals/GlobalModal";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useAuth } from "src/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useCategories } from "src/hooks/useCategories";
import { useProducts } from "src/hooks/useProducts";
import { useDropDowns } from "src/hooks/useDropDowns";
import Filter1 from "src/pages/Filter/Filter1";

// ----------------------------------------------------------------------
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

// ptvp=PAGE TO VIEW (PRODUCT)

export default function Shoppers() {
  const { data } = useAuth();
  const navigate = useNavigate()
  const categories = useCategories();
  const productLists = useProducts()


  const dropdownFilters = useDropDowns()



  const [modalOpen, setModalOpen] = useState(false);
  const [showDiv, setShowDiv] = useState(data.merchant_type === "brand");
  const [product, setProduct] = useState(1);
  const [productsAdAdded, setProductsAdded] = useState([]);
  const [category, setCategory] = useState(1);
  const [paymentMode, setPaymentMode] = useState('select');
  const [selectedFilter, setValue] = useState(dropdownFilters[0]?.name);
  const [selectedFilter1, setValue1] = useState(dropdownFilters[0]?.name);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);



  const handleClickList = (e) => {
    setAnchorEl(e.currentTarget)
  }
  const handleClickList1 = (e) => {
    setAnchorEl1(e.currentTarget)
  }
  const handleSelectFilter = (val, i) => {
    switch (i) {
      case 0:
        setAnchorEl(null);
        setValue(val)

        break;
      case 1:
        setAnchorEl1(null);
        setValue1(val)
        break;

      default:
        break;
    }


  }

  const handleClose = () => {
    setModalOpen(false);
  };


  useLayoutEffect(() => () => {
    localStorage.removeItem('ptvp');

    // Your code here.
  }, []);

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handlePaymentMode = (event) => {
    setPaymentMode(event.target.value);
  };
  const handleProductChange = (event) => {
    setProduct(event.target.value);
    let data = productsAdAdded
    let val = event.target.value
    if (val != 1) {
      if (data.includes(val) == false) {
        data.push(val)
        setProductsAdded(data)

      }
    }
  };

  const removeFromList = (index) => {
    let data = [...productsAdAdded]
    data = data.filter((_, i) => i !== index);
    if (data['length'] === 0) setProduct(1)
    setProductsAdded(data)




  }

  const handleViewClick = (d) => {
    navigate('/app/sales_details')
    // handle view click action
  };


  const processLocation = (e) => {
    // console.log(e);
  }

  return (
    <>
      <div className="pt-4 px-2">
        <div className={`${styles.analytics}  `}>
          <Filter1 width='100%' />


          <div className={`row`}>
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Total Product sold</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>70,976</span><small> from 67,000</small>
                    </div>
                    <div>
                      <div className={styles.percent}>
                        <img src="/assets/icons/up.svg" alt="" /> <small>12%</small>

                      </div>
                    </div>



                  </div>

                </div>


              </div>

            </div>
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Total Sales</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>₦ 3,206,976</span><small> from ₦ 2,907,000</small>
                    </div>
                    <div>
                      <div className={styles.percent}>
                        <img src="/assets/icons/up.svg" alt="" /> <small>12%</small>

                      </div>
                    </div>



                  </div>

                </div>


              </div>

            </div>

          </div>
        </div>
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-md-3 col-6  `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input type="text" placeholder="Search" style={{ textIndent: '25px', width: '100%' }} className=" form-control" />

            </div>
            <div className="col-md-9 col-6 text-right">
              <button
                onClick={() => setModalOpen(true)}
                className="btn  border"
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px'
                }}>
                <img className="" style={{ display: 'inline', width: '17px' }} src="/assets/icons/filterlines.svg" alt="" />
                &nbsp;
                <small className="d-none d-md-inline-block">
                  Filters
                </small>
              </button>



            </div>

          </div>


        </div>
        <Card style={{ marginTop: '50px', borderRadius: '10px' }} className='p-0'>
          <div className='p-4 '>

            <div className="d-md-flex justify-content-between" >
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">
                  Shoppers
                </h5>
                <small style={{ fontWeight: '200', fontSize: '12px' }}>
                  Customers who have    purchased from your outlets recently
                </small>

              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className={`${styles.export_btn} btn m-0`}>
                    <span style={{
                      paddingTop: '8px',


                    }}>
                      <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                      Export
                    </span>
                    &nbsp;
                    &nbsp;
                    <span style={{
                      borderLeft: '1px solid white',
                      display: 'inline-block',
                      height: 'inherit',
                      paddingTop: '8px',
                      paddingLeft: '8px',
                      textAlign: 'center',

                    }}>
                      <img src="/assets/icons/arrowDown.svg" alt="" />

                    </span>


                  </button>

                  <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                    <button type="button" className="rss dropdown-item btn border-0">CSV</button>
                    <button type="button" className="rss dropdown-item btn border-0">Excel </button>
                    <button type="button" className="rss dropdown-item btn border-0">PDF </button>

                  </div>
                </div>


              </div>

            </div>

          </div>
          <Scrollbar>
            <div className={`table-responsive-sm  pt-3 pb-3 pl-0 pr-0`}  >
              <table className={`${styles.table} table-hover table-striped`}>
                <thead className={`${styles.thead}`}>


                  <tr>
                    <th style={{
                      width: '20%'
                    }} className="text-muted">
                      Shopowner Name
                      <img className="pl-2" src="/assets/icons/down.svg" alt="" />

                    </th>
                    <th style={{
                      width: '20%'
                    }} className="text-muted">
                      Customer Location

                    </th>
                    <th style={{
                      width: '10%'
                    }} className="text-muted">
                      Recent Orders

                    </th>
                    <th style={{
                      width: '10%'
                    }} className="text-muted">
                      Mode Of Payment
                    </th>

                    <th style={{
                      width: '10%'
                    }} className="text-muted">
                      Date

                    </th>
                    <th style={{
                      width: '10%'
                    }} className="text-muted">
                      Price

                    </th>
                    <th style={{
                      width: '10%'
                    }} className="text-muted">
                      Total Quantity

                    </th>
                    <th style={{
                      width: '10%'
                    }} className="text-muted">
                      Total Amount

                    </th>

                  </tr>

                </thead>

                <tbody>
                  <tr className={`${styles.tr} `}>
                    <td>
                      <Avatar className={`${styles.avatrr} mr-3 float-md-left`} alt="" src="/assets/image1.svg" />
                      <div className=" ">
                        <p className="mb-0" style={{ fontSize: '14px' }}>
                          Michael Gillard


                        </p>
                        <span style={{ fontSize: '10px' }}>
                          michael@gmail.com


                        </span>

                      </div>

                    </td>
                    <td>
                      <p>
                        Lagos, Nigeria
                      </p>
                    </td>
                    <td>
                      <BootstrapTooltip title={
                        <div>
                          Nestle Milo, Pure life table water, <br /> Maggi, Nestcafe
                        </div>
                      }>
                        <span>
                          8
                        </span>

                      </BootstrapTooltip>
                    </td>
                    <td>
                      Mobile Transfer
                    </td>
                    <td>
                      12/11/22
                    </td>
                    <td>
                      ₦5.63M
                    </td>
                    <td>
                      20
                    </td>
                    <td>
                      ₦5.63M
                      &nbsp;
                      &nbsp;
                      &nbsp;
                      &nbsp;
                      <div className="d-inline-block dropleft ">

                        <span
                          id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"

                          className={`${styles.action_button} px-3 py-2`}  style={{
                            cursor: 'pointer',

                          }}>
                          <img src="/assets/icons/action.svg" alt="" />

                        </span>

                        <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                          <button type="button" onClick={() => handleViewClick(1)} className="rss dropdown-item btn border-0">View</button>


                        </div>
                      </div>
                    </td>


                  </tr>

                </tbody>

              </table>

            </div>


          </Scrollbar>
          <div className="card border-0 p-0 m-0">
            <div className="card-body pt-0 mt-0">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-item-center pt-2">
                  Page 1 of <b className="pl-1"> 5</b>
                </div>
                <div className={`${styles.pagination_button_container}`}>
                  <button className="btn">Previous</button>
                  &nbsp;
                  <button className="btn">Next</button>
                </div>

              </div>

            </div>
          </div>

        </Card>
      </div>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position='absolute'
        top='50%'
        left='50%'
        transform='translate(-50%, -50%)'
        width='740px !important'
        overflow='auto'
        bgcolor='#FFFFFF'
        border='1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow='24'
        p='25px'
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5 style={{
              fontSize: '19px',
              fontWeight: '700',

            }}>Filter</h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>


          <form action="" className="mt-0 form_label">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <LiveLocation label='Location' passData={processLocation} />



                </div>
                <div className="mb-3">
                  <label htmlFor="mode">Mode Of Payment</label>
                  <Select
                    labelId="mode"
                    id="mode"
                    fullWidth
                    size="small"
                    value={paymentMode}
                    required
                    onChange={handlePaymentMode}
                  >

                    <MenuItem value='select'>Select Mode of Payment</MenuItem>
                    <MenuItem value='Mobile Transfer'>Mobile Transfer</MenuItem>
                    <MenuItem value='Card Payment'>Card Payment</MenuItem>
                  </Select>


                </div>
                {/* {showDiv &&
                  <div className="mb-3">
                    <label htmlFor="cat">Category</label> <div />
                    {categories.map((el, i) => (
                      <div className="categories mr-2 mb-3" key={i}>
                        <input type="checkbox" value={el.name} name="" className="myCheckbox" />
                        &nbsp;
                        {el.name}


                      </div>

                    ))}


                  </div>

                } */}
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="ounit"
                        placeholder="Min"
                        size="small"

                      />


                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"

                      />


                    </div>

                  </div>

                </div>




              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Price </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="price"
                        placeholder="Min"
                        size="small"

                      />


                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"

                      />


                    </div>

                  </div>

                </div>
                {/* {
                  showDiv &&
                  <div>
                    <label htmlFor="ora">Order amount  </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type='number'
                            id="ora"
                            placeholder="Min"
                            size="small"

                          />


                        </div>

                      </div>
                      <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                        <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type='number'
                            placeholder="Max "
                            size="small"

                          />


                        </div>

                      </div>

                    </div>

                  </div>
                } */}

                {
                  !showDiv &&
                  <div>
                    <label htmlFor="patronage">Patronage  </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type='number'
                            id="patronage"
                            placeholder="Min"
                            size="small"

                          />


                        </div>

                      </div>
                      <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                        <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type='number'
                            placeholder="Max "
                            size="small"

                          />


                        </div>

                      </div>

                    </div>

                  </div>
                }
                <label htmlFor="name">Date </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '70%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='date'
                        id="name"
                        placeholder="Start"
                        size="small"

                      />


                    </div>

                  </div>
                  <div className="px-1 d-sflex   align-items-center " style={{ heigsht: '40px' }}>
                    {/* sss */}
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='date'
                        placeholder="End "
                        size="small"

                      />


                    </div>

                  </div>

                </div>

              </div>
              {/* <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="name">Product</label> <div></div>
                  {productLists.map((el, i) => (
                    <div className="categories mr-2 mb-3" key={i}>
                      <input onClick={(e) => handleProductChange(e, el.name)} type="checkbox" value={el.name} name="" className="myCheckbox" />
                      &nbsp;
                      {el.name}


                    </div>

                  ))}


                </div>
              </div> */}
            </div>
            <div className="mb-4">
              <button type="button" onClick={handleClose} className={`${styles.modal_btn} btn btn-block`}>
                Proceed

              </button>


            </div>

          </form>


        </div>


      </GlobalModal>
    </>
  );
}
