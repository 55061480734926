// ----------------------------------------------------------------------
import styles from '../Sales.module.css';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Card, Avatar } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import bus from 'src/bus';
import { Pagination } from 'src/components';

// mock
import { useLayoutEffect } from 'react';
import GlobalModal from 'src/Modals/GlobalModal';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import { useAuth } from "src/hooks/useAuth";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import { BallTriangle } from 'react-loader-spinner';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { downloadFile2, shortenText } from 'src/utils';
import LiveLocation from 'src/pages/LiveLocation';
import DateFormat from 'src/hooks/useDateFormat';

// ----------------------------------------------------------------------
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

// ptvp=PAGE TO VIEW (PRODUCT)

export default function Shoppers() {
  // const { data } = useAuth();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [brandData, setdata] = useState([]);
  const [isPending, setPending] = useState(false);
  const [categoryPagination, setCategoryPagination] = useState({ total: 0 });
  const notifyError = (err) => toast.error(err);
  const [searchvalue, setSearchvalue] = useState('');
  const [statusFilter, setStatusFilter] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [startDates, setStartDates] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endDates, setEndDates] = useState(null);
  const [filterSearch, setFilterSearch] = useState({
    filterLocation: '',
    filterStatus: '',
    startDateFilter: null,
    endDateFilter: null,
    priceFilter: '',
    priceToFilter: '',
    orderUnitFilter: '',
    orderUnitToFilter: '',
    paymentMode: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const searchFilter = (e) => {
    setSearchvalue(e.target.value);
  };

  const handleClose = () => {
    setModalOpen(false);
    filterSearch.filterLocation = '';
    filterSearch.filterStatus = '';
    filterSearch.startDateFilter = '';
    filterSearch.endDateFilter = '';
    filterSearch.priceFilter = '';
    filterSearch.priceToFilter = '';
    filterSearch.orderUnitFilter = '';
    filterSearch.orderUnitToFilter = '';
    filterSearch.paymentMode = '';
  };

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvp');

      // Your code here.
    },
    []
  );

  const handleViewClick = (d) => {
    localStorage.setItem('sales_details', JSON.stringify(d));
    navigate('/app/brand_sales_details');
    // handle view click action
  };

  const processLocation = (e) => {
    handleFilterStatus('filterLocation', e.formatted_address);
    // handleFilterStatus('latitude', e.lat)
    // handleFilterStatus('longitude', e.lng)
  };

  const handleFilterStatus = (key, value) => {
    setFilterSearch((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleSubmitFilter = () => {
    setModalOpen(false);
    fetchData();
  };

  const handleDownloadFile = async (extension) => {
    try {
      const result = `/admin/sales/get-sales-brand?page=${categoryPagination.currentPage}&perpage=${categoryPagination.total}&export_type=${extension}`;
      await downloadFile2('Brand Sales', result, extension);
    } catch (err) {
      notifyError(`Unble to export ${extension} file`);
    }
  };

  const fetchData = (page = 1, perpage = 10, file = null) => {
    let filter_payload = {};
    if (filterSearch.filterLocation != '') {
      filter_payload.location = filterSearch.filterLocation;
      filter_payload.latitude = filterSearch.latitude;
      filter_payload.longitude = filterSearch.longitude;
    }
    if (filterSearch.filterStatus != 'select') {
      filter_payload.status = filterSearch.filterStatus;
    }
    if (searchvalue != '') {
      filter_payload.search = searchvalue;
    }
    if (filterSearch.priceFilter != '') {
      filter_payload.min_price = filterSearch.priceFilter;
    }
    if (filterSearch.priceToFilter != '') {
      filter_payload.max_price = filterSearch.priceToFilter;
    }
    if (startDate) {
      filter_payload.dateFrom = DateFormat(startDate);
    } else if (startDate === '1970-01-01') {
      filter_payload.dateFrom = '';
    } else {
      filter_payload.dateFrom = '';
    }
    if (endDate) {
      filter_payload.dateTo = DateFormat(endDate);
    } else if (endDate === '1970-01-01') {
      filter_payload.dateTo = '';
    } else {
      filter_payload.dateTo = '';
    }
    if (filterSearch.orderUnitToFilter != '') {
      filter_payload.max_order_unit = filterSearch.orderUnitToFilter;
    }
    if (filterSearch.orderUnitFilter != '') {
      filter_payload.min_order_unit = filterSearch.orderUnitFilter;
    }
    if (filterSearch.paymentMode != '') {
      filter_payload.payment_mode = filterSearch.paymentMode;
    }
    setPending(true);
    axiosInstance
      .get('/admin/sales/get-sales-brand', {
        params: {
          page,
          perpage,
          file,
          ...filter_payload,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setdata(res.data.data.data);
        setCategoryPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch sales, please try again later');
        }
      })
      .finally(() => setPending(false));
  };

  useEffect(() => {
    setFilterSearch((prev) => {
      return {
        ...prev,
        startDateFilter: startDates,
        endDateFilter: endDates,
      };
    });
  }, [startDates, endDates]);

  useEffect(() => {
    bus.on('search-filter', (el) => {
      setFilterSearch(el);
      setStatusFilter(!statusFilter);
    });
    fetchData();
  }, [statusFilter, searchvalue]);

  return (
    <>
      <div className="pt-4 px-2">
        {/* <div className={`${styles.analytics}  `}>
        <Filter1 width='100%' />


          <div className={`row`}>
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Total Product sold</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>70,976</span><small> from 67,000</small>
                    </div>
                    <div>
                      <div className={styles.percent}>
                        <img src="/assets/icons/up.svg" alt="" /> <small>12%</small>

                      </div>
                    </div>



                  </div>

                </div>


              </div>

            </div>
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Total Sales</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>₦ 3,206,976</span><small> from ₦ 2,907,000</small>
                    </div>
                    <div>
                      <div className={styles.percent}>
                        <img src="/assets/icons/up.svg" alt="" /> <small>12%</small>

                      </div>
                    </div>



                  </div>

                </div>


              </div>

            </div>

          </div>
      </div> */}
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-md-3 col-6  `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={searchvalue}
                onChange={searchFilter}
                placeholder="Search"
                style={{
                  textIndent: '25px',
                  width: '100%',
                }}
                className=" form-control"
              />
            </div>
            <div className="col-md-9 col-6 text-right">
              <button
                onClick={() => setModalOpen(true)}
                className="btn  border"
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px',
                }}
              >
                <img
                  className=""
                  style={{
                    display: 'inline',
                    width: '28%',
                  }}
                  src="/assets/icons/filterlines.svg"
                  alt=""
                />
                &nbsp;
                <small className="d-none d-md-inline-block">Filters</small>
              </button>
            </div>
          </div>
        </div>
        <Card
          style={{
            marginTop: '50px',
            borderRadius: '10px',
          }}
          className="p-0"
        >
          <div className="p-4 ">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">Brand sales</h5>
                <small style={{ fontWeight: '200', fontSize: '14px' }}>
                  Details of sales made by brands on Retail Xpress
                </small>
              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`${styles.export_btn} btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: '8px',
                      }}
                    >
                      <img src="/assets/icons/download.svg" alt="" />
                      &nbsp; Export
                    </span>
                    &nbsp; &nbsp;
                    <span
                      style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <img src="/assets/icons/arrowDown.svg" alt="" />
                    </span>
                  </button>
                  <div
                    className="dropdown-menu drop-left"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('csv')}
                    >
                      CSV
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('excel')}
                    >
                      Excel
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('pdf')}
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`pt-3 pb-3 pl-0 pr-0`}>
              <table
                className={`${styles.table} table-hover table-striped`}
                style={{ minWidth: '80em' }}
              >
                <thead className={`${styles.thead}`}>
                  <tr>
                    <th className="text-muted">S/N</th>
                    <th className="text-muted">
                      Brand Name
                      <img
                        className="pl-2"
                        src="/assets/icons/down.svg"
                        alt=""
                      />
                    </th>
                    <th className="text-muted">Order Unit</th>
                    <th className="text-muted">Shoppers</th>
                    <th className="text-muted">Price</th>
                    <th className="text-muted">Date Ordered</th>

                    <th className="text-muted">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {brandData.length > 0 &&
                    !isPending &&
                    brandData.map((el, i) => (
                      <tr key={i} className={`${styles.tr} `}>
                        <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                        <td>
                          <div className=" ">
                            <p className="mb-0" style={{ fontSize: '14px' }}>
                              <strong>{el?.brand_name}</strong>
                            </p>
                            <span style={{ fontSize: '13px' }}>
                              {el?.brand_id}
                            </span>
                          </div>
                        </td>
                        <td>
                          <p>{el?.order_unit}</p>
                        </td>
                        <td>
                          {/* <Avatar className={`${styles.avatrr} mr-3 float-md-left`} alt="" src={el?.logo} /> */}
                          <div className=" ">
                            <p className="mb-0" style={{ fontSize: '14px' }}>
                              <strong>{el?.full_name}</strong>
                            </p>
                            <span style={{ fontSize: '13px' }}>
                              {el?.email}
                            </span>
                          </div>
                        </td>
                        <td>₦{Number(el?.price).toLocaleString()}</td>
                        {/* <td>
                          <span className="">
                            {el?.status === "completed" ? (
                                <span className="verified_badge">{el?.status}</span>
                              ) : el?.status === "pending" ? (
                                <span className="pending_badge">{el?.status}</span>
                              ) : (
                                <span className="not_verified_badge">{el?.status}</span>
                              )
                            }
                          </span>
                        </td> */}
                        <td>{moment(el?.date_ordered).format('DD/MM/YYYY')}</td>
                        <td>
                          &nbsp; &nbsp; &nbsp; &nbsp;
                          <div className="d-inline-block dropleft ">
                            <span
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              className={`${styles.action_button} px-3 py-2`}
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <img src="/assets/icons/action.svg" alt="" />
                            </span>

                            <div
                              className="dropdown-menu drop-left"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <button
                                type="button"
                                onClick={() => handleViewClick(el)}
                                className="rss dropdown-item btn border-0"
                              >
                                View
                              </button>
                              {/* <button type="button" className="rss dropdown-item btn border-0">Verify</button> */}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!brandData.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={categoryPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#FFFFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <LiveLocation label="Location" passData={processLocation} />
                </div>

                <label htmlFor="price">Price </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="price"
                        placeholder="Min"
                        size="small"
                        name="priceFilter"
                        value={filterSearch.priceFilter}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterStatus('priceFilter', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        placeholder="Max "
                        size="small"
                        name="priceToFilter"
                        value={filterSearch.priceToFilter}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterStatus('priceToFilter', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="mb-3">
                  <label htmlFor="mod">Status</label>
                  <Select
                    labelId="mod"
                    id="mod"
                    fullWidth
                    size="small"
                    name='filterStatus'
                    value={filterSearch.filterStatus}
                    required
                    onChange={(e) => handleFilterStatus('filterStatus', e.target.value)}
                  >
                    <MenuItem value='select'>Select Status</MenuItem>
                    <MenuItem value='pending'>Pending</MenuItem>
                    <MenuItem value='completed'>Completed</MenuItem>
                    <MenuItem value='failed'>Failed</MenuItem>
                  </Select>
                </div> */}
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="name">Date </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-sflex   align-items-center "
                    style={{ heigsht: '40px' }}
                  >
                    {/* sss */}
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        name="orderUnitFilter"
                        value={filterSearch.orderUnitFilter}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterStatus('orderUnitFilter', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        placeholder="Max "
                        size="small"
                        name="orderUnitToFilter"
                        value={filterSearch.orderUnitToFilter}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterStatus('orderUnitToFilter', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={handleSubmitFilter}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
    </>
  );
}
