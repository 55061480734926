import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';
import Brand from './BrandOwner';
import Retailer from './Retailer';
import Superadmin from './Superadmin';
// localStorage.setItem('merchant_type',1) then reload
export default function OrderIndex() {
  const { data } = useAuth();

  return data.merchant_type === 'brand' ? (
    <Brand />
  ) : data?.user_type === 'admin' ? (
    <Superadmin />
  ) : (
    <Retailer />
  );
}
