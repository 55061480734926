import { useAuth } from "src/hooks/useAuth";
import Brand from "./BrandOwner";
// import Retailer from "./Retailer";
import Retailer from "./Superadmin/Retailer";
import SuperAdmin from "./Superadmin";

export default function DataInsight() {
  const {data} = useAuth();

    
  return data.merchant_type === "brand"  ?  (
    <Brand />  
) : (
  data?.user_type === "admin" ? <SuperAdmin /> :
  <Retailer />
)
}