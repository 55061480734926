// ----------------------------------------------------------------------
import styles from '../BrandOwner.module.css';
import '../BrandOwner.module.css';
import ReactApexChart from 'react-apexcharts';

import { Card, CardContent, Avatar } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import GlobalModal from 'src/Modals/GlobalModal';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import bus from 'src/bus';
import Filter2 from 'src/pages/Filter/Filter2';
import Filter3 from 'src/pages/Filter/Filter3';
import { BallTriangle } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { GoogleMapHeatmap } from '../../../components';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { useTheme } from '@mui/material/styles';
import Icon from '@mui/material/Icon';

// ----------------------------------------------------------------------

const useIsDarkMode = () => {
  const theme = useTheme();
  return theme.palette.mode === 'dark';
};

const TABLE_HEAD = [
  { id: 'sn', label: 'S/N', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'storename', label: 'Store Name', alignRight: false },
  { id: 'averagesales', label: 'Average Sales', alignRight: false },
  { id: 'averagerevenue', label: 'Average Revenue', alignRight: false },
  { id: 'totalshoppers', label: 'Total Shoppers', alignRight: false },
  // { id: 'totalsale', label: 'Total Sale', alignRight: false },
];

export default function DashboardAppPage() {
  const isDarkMode = useIsDarkMode();
  const { id } = useParams();
  const userData = JSON.parse(localStorage.getItem('auth'));
  const [storeData, setStoreData] = useState([]);
  const [brand, setBrand] = useState(
    JSON.parse(localStorage.getItem('selected_user_insight')) || ''
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isPending1, setIsPending1] = useState(false);
  const [isDepStatPending, setIsDepStatPending] = useState(false);
  const [isRevGrowthPending, setIsRevGrowthPending] = useState(false);
  const [dataFilter, setDataFilter] = useState('today');
  const [chartFilter, setChartFilter] = useState('today');
  const [country, setCountry] = useState('Nigeria');
  const [categoriesData, setCategoriesData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productCatData, setProductCatData] = useState([]);
  const [catProductData, setCatProductData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [modalVal, setModalVal] = useState('');
  const [mapData, setMapData] = useState([]);
  const [states, setStates] = useState([]);
  const [state, setState] = useState('');
  const [searchStates2, setSearchStates2] = useState('');
  const [searchStates3, setSearchStates3] = useState('');
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectAllProduct, setSelectAllProduct] = useState(false);
  const [selectAllStates, setSelectAllStates] = useState(false);
  const [isProductPending, setIsProductPending] = useState(false);
  const [isPurchasedProductPending, setIsPurchasedProductPending] =
    useState(false);
  const [isDepletionStatsPending, setIsDepletionStatsPending] = useState(false);
  const [isProductSalesPending, setIsProductSalesPending] = useState(false);
  const [isBHPLPending, setIsBHPLPending] = useState(false);
  const [isBPPending, setIsBPPending] = useState(false);
  const [isOBLPending, setIsOBLPending] = useState(false);
  const [isHighestPerformingStorePending, setIsHighestPerformingStorePending] =
    useState(false);
  const [isTrendsOfPurchasePending, setIsTrendsOfPurchasePending] =
    useState(false);
  const [isMarketSharePending, setIsMarketSharePending] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [cardLoader, setCardLoader] = useState(false);
  const [cardsData, setCardsData] = useState({
    abandonOrder: {
      boldCount: 0,
      percentageDiff: 0,
      smallCount: 0,
    },
    completedOrder: {
      boldCount: 0,
      percentageDiff: 0,
      smallCount: 0,
    },
    grossMerchandiseValue: {
      boldCount: 0,
      percentageDiff: 0,
      smallCount: 0,
    },
    netMerchandiseValue: {
      boldCount: 0,
      percentageDiff: 0,
      smallCount: 0,
    },
    totalOrder: {
      boldCount: 0,
      percentageDiff: 0,
      smallCount: 0,
    },
  });

  const allChartData = [
    { value: 'r_g', name: 'Revenue Growth' },
    { value: 'd_s', name: 'Depletion Stats' },
    { value: 'p_p_b_s_v', name: 'Purchased Product by SKU Variation' },
    { value: 'd_s_b_s_v', name: 'Depletion Stats by SKU Variation' },
    { value: 'p_s', name: 'Product Sales' },
    // {value: 'p_p_b_b', name: 'Purchased Product by Brand'},
    { value: 'h_p_l', name: 'High Performing Store/Location' },
    // {value: 'h_p_s', name: 'High Performing Store'},
    { value: 't_o_p', name: 'Trends of Purchases' },
    { value: 'm_s', name: 'Market Share' },
    { value: 's_b_p', name: 'Shopper Buying Power' },
    { value: 'o_b_l', name: 'Orders by Location (Heat Map)' },
  ];
  const [selectedProductCatIds, setSelectedProductCatIds] = useState([]);
  const [isProductCatPending, setIsProductCatPending] = useState(false);
  const [isCategoryPending, setIsCategoryPending] = useState(false);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [searchBrands, setSearchBrands] = useState('');
  const [selectedState2, setSelectedState2] = useState('');
  const [selectedState3, setSelectedState3] = useState('');
  const [selectAllBrand, setSelectAllBrand] = useState(false);
  const [categoryProductId, setCategoryProductId] = useState('');
  const [selectAllProductCategory, setSelectAllProductCategory] =
    useState(false);
  const [categorySelectedIds, setCategorySelectedIds] = useState([]);
  const [categoryPending, setCategoryPending] = useState(false);
  const [searchCategory, setSearchCategory] = useState('');
  const [searchProducts, setSearchProducts] = useState('');
  const [searchStates, setSearchStates] = useState('');
  const navigate = useNavigate();
  const notifyError = (err) => toast.error(err);
  const [statFilter1, setStatFilter1] = useState(false);
  const [statFilter01, setStatFilter01] = useState(false);
  const [statFilter2, setStatFilter2] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedYear1, setSelectedYear1] = useState(currentYear);

  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedTab01, setSelectedTab01] = useState(1);
  const [selectedTab1, setSelectedTab1] = useState(1);
  const [visibleItems, setVisibleItems] = useState(5);

  const handleSeeMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 5);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const handleTabChange01 = (tab) => {
    setSelectedTab01(tab);
  };
  const handleTabChange1 = (tab) => {
    setSelectedTab1(tab);
    emitDataFilter(tab);
  };

  const emitDataFilter = (selectedValue) => {
    bus.emit('dataFilter', selectedValue);
  };

  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);

  const handleClickInsideDropdown = (e) => {
    const dropdownRefs = [dropdownRef, dropdownRef2, dropdownRef3];

    // Check if the clicked element is part of any of the dropdown menus
    if (
      dropdownRefs.some((ref) => ref.current && ref.current.contains(e.target))
    ) {
      // Allow propagation for clicks inside any of the dropdown menus
      return;
    }

    // Prevent propagation for clicks outside the dropdown menu
    e.stopPropagation();
  };

  const handleClose = () => {
    setModalOpen(false);
    setSelectedProductIds([]);
    setSelectedState('');
  };

  const handleClose2 = () => {
    setModalOpen2(false);
    setSelectedProductIds([]);
    setSelectedState('');
  };
  const handleClose3 = () => {
    setModalOpen3(false);
    setSelectedProductIds([]);
    setSelectedState('');
  };

  const handleYearChange = (direction) => {
    if (direction === 'down') {
      setSelectedYear(selectedYear - 1);
    } else if (direction === 'up' && selectedYear < currentYear) {
      setSelectedYear(selectedYear + 1);
    }
  };

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          const formatAmount = (amount) => {
            if (amount >= 1000000) {
              return '₦' + (amount / 1000000).toFixed(0) + 'm';
            } else if (amount >= 1000) {
              return '₦' + (amount / 1000).toFixed(0) + 'k';
            } else {
              return '₦' + amount;
            }
          };

          // Formatting the value using the formatAmount function
          const formattedValue = formatAmount(Number(val));

          return formattedValue;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '₦ ' + Number(val).toLocaleString();
        },
      },
    },
  });
  const [chartSeries, setChartSeries] = useState([
    {
      name: 'Revenue',
      data: [],
    },
  ]);
  const [chartOptions1, setChartOptions1] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
  });
  const [chartSeries1, setChartSeries1] = useState([
    {
      name: 'Remaining Stock',
      data: [],
    },
    {
      name: 'Total Stock',
      data: [],
    },
  ]);
  const [chartSeries2, setChartSeries2] = useState([]);
  const [chartOptions2, setChartOptions2] = useState({
    chart: {
      type: 'pie',
      toolbar: {
        show: true,
        customToolbar: 'custom-toolbar-classms',
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      '#af3585',
      '#D444F1',
      '#16B364',
      '#06AED4',
      '#2E90FA',
      '#875BF7',
      '#F63D68',
      '#EAAA08',
    ],
    labels: [],

    plotOptions: {
      pie: {
        labels: {
          formatter: function (val, opts) {
            var value = chartSeries2[opts.index];
            return val + ' - ' + value;
          },
        },
        style: {
          padding: '40px',
          justifyContent: 'space-between',
        },
      },
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
  });

  const [chartSeries02, setChartSeries02] = useState([]);
  const [chartOptions02, setChartOptions02] = useState({
    chart: {
      type: 'pie',
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      '#85E13A',
      '#D444F1',
      '#16B364',
      '#06AED4',
      '#2E90FA',
      '#875BF7',
      '#F63D68',
      '#EAAA08',
    ],
    labels: [],
    plotOptions: {
      pie: {
        labels: {
          formatter: function (val, opts) {
            var value = chartSeries02[opts.index];
            return val + ' - ' + value;
          },
        },
        style: {
          padding: '40px',
          justifyContent: 'space-between',
        },
      },
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
  });

  const [chartOptions3, setChartOptions3] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#53138D'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          const formatAmount = (amount) => {
            if (amount >= 1000000) {
              return '₦' + (amount / 1000000).toFixed(0) + 'm';
            } else if (amount >= 1000) {
              return '₦' + (amount / 1000).toFixed(0) + 'k';
            } else {
              return '₦' + amount;
            }
          };

          // Formatting the value using the formatAmount function
          const formattedValue = formatAmount(Number(val));

          return formattedValue;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '₦ ' + Number(val).toLocaleString();
        },
      },
    },
  });
  const [chartSeries3, setChartSeries3] = useState([
    {
      name: 'Buying Power',
      data: [],
    },
  ]);
  // ----------------------Product SKU--------------------
  const [chartOptions4, setChartOptions4] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#53138D', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  });
  const [chartSeries4, setChartSeries4] = useState([
    {
      name: 'Total Sales',
      data: [],
    },
  ]);

  const [chartOptions014, setChartOptions014] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#53138D', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
  });
  const [chartSeries014, setChartSeries014] = useState([
    {
      name: 'Remaining Stock',
      data: [],
    },
    {
      name: 'Total Stock',
      data: [],
    },
  ]);

  const [chartOptions04, setChartOptions04] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#53138D', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  });
  const [chartSeries04, setChartSeries04] = useState([
    {
      name: 'Total Sales',
      data: [],
    },
  ]);

  const [chartOptions5, setChartOptions5] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#53138D', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  });
  const [chartSeries5, setChartSeries5] = useState([
    {
      name: 'Order',
      data: [900, 550, 800, 810, 810, 600, 400, 400],
    },
  ]);
  const [chartOptions6, setChartOptions6] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    annotations: {
      xaxis: [],
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#53138D', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  });
  const [chartSeries6, setChartSeries6] = useState([
    {
      name: 'Order',
      data: [900, 550, 800, 810, 810, 600, 400, 400, 600, 400, 400],
    },
  ]);
  const [chartOptions7, setChartOptions7] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },

    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#53138D', '#d792e5', '#D1E9FF'],
    // annotations: {
    //   xaxis: [{
    //     x: 500,
    //     borderColor: '#af3585',

    //   }],
    //   yaxis: [{
    //     y: 'July',
    //     y2: 'September',

    //   }]
    // },
    plotOptions: {
      bar: {
        horizontal: true,
        rowWidth: '10%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
      maxWidth: '10px',
    },
    xaxis: {
      categories: [],
      labels: {
        formatter: function (val) {
          const formatAmount = (amount) => {
            if (amount >= 1000000) {
              return (amount / 1000000).toFixed(2) + 'm';
            } else if (amount >= 1000) {
              return (amount / 1000).toFixed(2) + 'k';
            } else {
              return amount;
            }
          };

          // Formatting the value using the formatAmount function
          const formattedValue = formatAmount(Number(val));

          return formattedValue;
        },
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    yaxis: {
      reversed: false,
      axisTicks: {
        show: true,
      },
    },
  });
  const [chartSeries7, setChartSeries7] = useState([
    {
      name: 'HPS',
      data: [],
    },
  ]);
  const [chartOptions8, setChartOptions8] = useState({
    chart: {
      type: 'line',
      toolbar: {
        show: true,
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: {
            download: {
              icon: 'custom-toolbar-icon',
            },
          },
        },
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#53138D', '#198f51', '#e03e1a'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  });
  const [chartSeries8, setChartSeries8] = useState([]);
  const [chartOptions9, setChartOptions9] = useState({
    chart: {
      type: 'bar',
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#53138D'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    xaxis: {
      categories: [
        'Nigeria',
        'Ghana',
        'South Africa',
        'Egypt',
        'Benin',
        'Liberia',
        'Togo',
        'Sierrelone',
        'Senegal',
      ],
    },
    yaxis: {
      labels: {
        formatter: function (val, index) {
          return '₦' + val;
        },
      },
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '$ ' + val;
        },
      },
    },
  });
  const [chartSeries9, setChartSeries9] = useState([
    {
      name: 'Buying Power',
      data: [920, 550, 800, 1000, 810, 600, 400, 400],
    },
  ]);

  const handleStatFilter1 = () => {
    setStatFilter1(!statFilter1);
  };

  const handleStatFilter01 = () => {
    setStatFilter01(!statFilter01);
  };

  const handleStatFilter2 = () => {
    setStatFilter2(!statFilter2);
  };

  const handleSelectAllProductCategories = () => {
    setSelectAllProductCategory(!selectAllProductCategory);
    const selectedIds = productCatData?.map((option) => option._id);
    setSelectedProductCatIds(selectAllProductCategory ? [] : selectedIds);
  };

  const handleSearchCategory = (e) => {
    setSearchCategory(e.target.value);
  };

  const handleSearchBrand = (e) => {
    setSearchBrands(e.target.value);
  };

  const handleSearchState3 = (e) => {
    setSearchStates3(e.target.value);
  };

  const handleStateChange3 = (val) => {
    setSelectedState3(val);
  };

  const handleProceedProductSalesChartFilter = () => {
    fetchDepletionStat();
    fetchHighestPerformingStoreStat();
    handleStatFilter1();
    setSearchStates3('');
  };

  const handleProceedOBLFilter = () => {
    handleStatFilter01();
    fetchOBLStat();
  };

  const handleFetchProductCategories = () => {
    setIsProductCatPending(true);
    axiosInstance
      .get(`/admin/data-insights/brand-categoriesWithProduct/${brand._id}`, {
        params: {
          search: searchProducts ? searchProducts : null,
        },
      })
      .then((res) => {
        setProductCatData(res.data.data);
        setIsProductCatPending(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch {
          notifyError('Unable to fetch products!');
        }
      })
      .finally(() => setIsProductCatPending(false));
  };

  const fetchRevGrowthStat = () => {
    setIsRevGrowthPending(true);

    axiosInstance
      .get(
        `/admin/data-insights/brand-revenue-growth/${brand._id}?dateFilter=${chartFilter}`
      )
      .then((res) => {
        const data = [
          {
            name: 'Revenue',
            data: res.data.data.map((el) => `${el?.totalAmount}`),
          },
        ];

        setChartSeries((prev) => {
          return data;
        });

        setChartOptions((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: res.data.data.length
                ? res.data.data.map((el) => {
                    if (el.type === 'week') {
                      return `Week ${parseInt(el.date, 10) + 1}`;
                    } else if (el.type === 'hour') {
                      return moment(el.date).format('h A');
                    } else if (el.type === 'month') {
                      const monthNames = [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ];
                      const yearMonth = el.date.split('-');
                      const monthNumber = parseInt(yearMonth[1], 10);
                      return `${monthNames[monthNumber - 1]} ${yearMonth[0]}`;
                    } else {
                      return moment(el.date).format('YYYY-MM-DD');
                    }
                  })
                : [''],
            },
          };
        });
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsRevGrowthPending(false));
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const randomColors = Array.from({ length: 8 }, () => getRandomColor());

  const fetchDepletionStat = () => {
    setIsDepStatPending(true);
    axiosInstance
      .get(`/admin/data-insights/brand-depletion/${brand._id}`, {
        params: {
          dateFilter: chartFilter,
          products: selectedCategoryIds ? selectedCategoryIds : null,
          location: selectedState3 ? selectedState3 : null,
          categories: selectedProductCatIds ? selectedProductCatIds : null,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Remaining Stock',
            data: res.data.data.map((el) => el?.remainingStock),
          },
          {
            name: 'Total Stock',
            data: res.data.data.map((el) => el?.totalPushed),
          },
        ];

        setChartSeries1((prev) => {
          return data;
        });
        setChartOptions1((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: res.data.data.length
                ? res.data.data.map((el) => `${el?.product_name}`)
                : [''],
            },
          };
        });
        setSelectedProductCatIds([]);
        setSelectedCategoryIds([]);
        setSelectedState3('');
        setSearchStates3('');
        setCategorySelectedIds([]);
        setSelectAllStates(false);
        setSelectAllProductCategory(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsDepStatPending(false));
  };

  const fetchPurchasedProductStat = () => {
    setIsPurchasedProductPending(true);
    axiosInstance
      .get(`/admin/data-insights/brand-product-sales-sku/${brand._id}`, {
        params: {
          dateFilter: chartFilter,
          products: selectedProductIds ? selectedProductIds : null,
          location: selectedState ? selectedState : null,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Brand SKU',
            data: res.data.data.map((el) => el.totalSale),
          },
        ];

        setChartSeries4((prev) => {
          return data;
        });
        setChartOptions4((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: res.data.data.length
                ? res.data.data.map((el) => {
                    const productNameWords = el.product_name.split(' ');
                    const specWords = el.spec.split(' ');

                    const productNameFormatted = productNameWords.join('\n');
                    const specFormatted = specWords.join('\n');

                    return `${productNameFormatted}\n(${specFormatted})`;
                  })
                : [''],
            },
          };
        });
        setSelectedProductIds([]);
        setSelectedState('');
        setSelectAllStates(false);
        setSelectAllProductCategory(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsPurchasedProductPending(false));
  };

  const fetchDepletionStatsStat = () => {
    setIsDepletionStatsPending(true);
    axiosInstance
      .get(`/admin/data-insights/brand-depletion/${brand._id}`, {
        params: {
          dateFilter: chartFilter,
          products: selectedProductIds ? selectedProductIds : null,
          location: selectedState ? selectedState : null,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'SKU Variations',
            data: res.data.data.map((el) => el.totalPushed),
          },
        ];

        setChartSeries04((prev) => {
          return data;
        });
        setChartOptions04((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: res.data.data.length
                ? res.data.data.map((el) => {
                    const productNameWords = el.product_name.split(' ');
                    const specWords = el.product_spec.split(' ');

                    const productNameFormatted = productNameWords.join('\n');
                    const specFormatted = specWords.join('\n');

                    return `${productNameFormatted}\n(${specFormatted})`;
                  })
                : [''],
            },
          };
        });
        setSelectedProductIds([]);
        setSelectedState('');
        setSelectAllStates(false);
        setSelectAllProductCategory(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsDepletionStatsPending(false));
  };

  const fetchProductSalesStat = () => {
    setIsProductSalesPending(true);
    axiosInstance
      .get(`/admin/data-insights/brand-product-sales/${brand._id}`, {
        params: {
          dateFilter: chartFilter,
          products: selectedProductIds ? selectedProductIds : null,
          locations: selectedState ? selectedState : null,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Product Sales',
            data: res.data.data.map((el) => el.totalSale),
          },
        ];

        setChartSeries5((prev) => {
          return data;
        });
        setChartOptions5((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: res.data.data.length
                ? res.data.data.map((el) => `${el.product_name}`)
                : [''],
            },
          };
        });
        setModalVal('');
        setSelectedProductIds([]);
        setSelectedState('');
        setSelectAllStates(false);
        setSelectAllProductCategory(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsProductSalesPending(false));
  };

  const fetchHighestLocationStat = () => {
    setIsBHPLPending(true);
    axiosInstance
      .get(
        `/admin/data-insights/brand-highest-performing-location/${brand._id}`,
        {
          params: {
            dateFilter: chartFilter,
            location: selectedState ? selectedState : null,
            // products: selectedCategoryIds ? selectedCategoryIds : null,
            // categories: selectedProductCatIds ? selectedProductCatIds : null,
          },
        }
      )
      .then((res) => {
        setStoreData(res.data.data);
        setSelectedProductCatIds([]);
        setSelectedCategoryIds([]);
        setSelectedState('');
        setSearchStates('');
        setSelectAllStates(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsBHPLPending(false));
  };

  const fetchHighestPerformingStoreStat = () => {
    setIsHighestPerformingStorePending(true);
    axiosInstance
      .get(`/admin/data-insights/brand-highest-performing-store/${brand._id}`, {
        params: {
          dateFilter: chartFilter,
          products: selectedCategoryIds ? selectedCategoryIds : null,
          location: selectedState3 ? selectedState3 : null,
          categories: selectedProductCatIds ? selectedProductCatIds : null,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'HPS',
            data: res.data.data.map((el) => el.totalAmount),
          },
        ];

        setChartSeries7((prev) => {
          return data;
        });
        setChartOptions7((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: res.data.data.length
                ? res.data.data.map((el) => `${el.store_name}`)
                : [''],
            },
          };
        });
        setSelectedProductCatIds([]);
        setSelectedCategoryIds([]);
        setSelectedState3('');
        setSearchStates3('');
        setSelectAllStates(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsHighestPerformingStorePending(false));
  };

  const fetchTrendsOfPurchase = () => {
    setIsTrendsOfPurchasePending(true);
    axiosInstance
      .get(`/admin/data-insights/brand-trendsOfPurchase/${brand._id}`, {
        params: {
          dateFilter: chartFilter,
          products: selectedProductIds ? selectedProductIds : null,
          locations: selectedState ? selectedState : null,
        },
      })
      .then((res) => {
        const uniqueProductNames = Array.from(
          new Set(
            res.data.data.flatMap((item) =>
              item.products.map((product) => product.product_name)
            )
          )
        );

        const data = uniqueProductNames.map((productName) => {
          const productData = res.data.data.map((item) => {
            const product = item.products.find(
              (p) => p.product_name === productName
            );
            return product ? product.totalProducts : 0;
          });

          return {
            name: productName,
            data: productData,
          };
        });

        setChartSeries8((prev) => {
          return data;
        });
        setChartOptions8((prev) => {
          return {
            ...prev,
            colors: randomColors,
            xaxis: {
              categories: res.data.data.length
                ? res.data.data.map((el) => {
                    if (el.type === 'week') {
                      return `Week ${parseInt(el.date, 10) + 1}`;
                    } else if (el.type === 'hour') {
                      return moment(el.date).format('h A');
                    } else if (el.type === 'month') {
                      const monthNames = [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ];
                      const yearMonth = el.date.split('-');
                      const monthNumber = parseInt(yearMonth[1], 10);
                      return `${monthNames[monthNumber - 1]} ${yearMonth[0]}`;
                    } else {
                      return moment(el.date).format('YYYY-MM-DD');
                    }
                  })
                : [''],
            },
          };
        });
        setSelectedProductIds([]);
        setSelectedState('');
        setSelectAllStates(false);
        setModalVal('');
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsTrendsOfPurchasePending(false));
  };

  const fetchMarketShareChart = () => {
    setIsMarketSharePending(true);
    axiosInstance
      .get(`/admin/data-insights/brand-marketShare/${brand._id}`, {
        params: {
          dateFilter: chartFilter,
          category: selectedProductCatIds ? selectedProductCatIds : null,
        },
      })
      .then((res) => {
        const data = res.data.data.length
          ? res.data.data.map((el) => el.totalProducts)
          : [''];

        setChartSeries2((prev) => {
          return data;
        });
        setChartOptions2((prev) => {
          return {
            ...prev,
            labels: res.data.data.length
              ? res.data.data.map(
                  (el) => `${el.product_name} (${el?.percentage}%)`
                )
              : ['No Chart Record Found'],
            colors: randomColors,
          };
        });
        setSelectedProductCatIds([]);
        setSelectedProductCatIds('');
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsMarketSharePending(false));
  };

  const fetchBuyingPowerStat = () => {
    setIsBPPending(true);
    axiosInstance
      .get(`/admin/data-insights/brand-shopper-buying-power/${brand._id}`, {
        params: {
          dateFilter: chartFilter,
          products: selectedCategoryIds ? selectedCategoryIds : null,
          locations: selectedState ? selectedState : null,
          categories: selectedProductCatIds ? selectedProductCatIds : null,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Buying Power',
            data: res.data.data.map((el) => el.totalAmount),
          },
        ];

        setChartSeries3((prev) => {
          return data;
        });

        setChartOptions3((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: res.data.data.length
                ? res.data.data.map((el) => `${el._id}`)
                : [''],
            },
          };
        });
        const data2 = res.data.data.length
          ? res.data.data.map((el) => el.totalAmount)
          : [''];

        setChartSeries02((prev) => {
          return data2;
        });
        setChartOptions02((prev) => {
          return {
            ...prev,
            labels: res.data.data.length
              ? res.data.data.map((el) => `${el._id}`)
              : ['No Chart Record Found'],
            colors: randomColors,
          };
        });
        setSelectedProductCatIds([]);
        setSelectedCategoryIds([]);
        setSelectedState([]);
        setSelectedStates(false);
        setSearchStates3('');
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsBPPending(false));
  };

  const fetchOBLStat = () => {
    setIsOBLPending(true);
    axiosInstance
      .get(`/admin/data-insights/brand-heatmap/${brand._id}`, {
        params: {
          dateFilter: chartFilter,
          products: selectedCategoryIds ? selectedCategoryIds : null,
          location: selectedState3 ? selectedState3 : null,
          categories: selectedProductCatIds ? selectedProductCatIds : null,
        },
      })
      .then((res) => {
        setMapData(res.data.data);
        setSelectedProductCatIds([]);
        setSelectedCategoryIds([]);
        setSelectedState3([]);
        setSelectAllStates(false);
        setSearchStates3('');
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsOBLPending(false));
  };

  useEffect(() => {
    // fetchTopBrandStat(dataFilter)
    handleFetchProductCategories();
    fetchRevGrowthStat();
    fetchDepletionStat();
    fetchPurchasedProductStat();
    fetchDepletionStatsStat();
    fetchProductSalesStat();
    fetchHighestLocationStat();
    fetchHighestPerformingStoreStat();
    fetchTrendsOfPurchase();
    fetchMarketShareChart();
    fetchBuyingPowerStat();
    fetchOBLStat();
  }, [chartFilter]);

  useEffect(() => {
    bus.on('dataFilter', (el) => {
      setDataFilter(el);
    });

    bus.on('chartFilter', (el) => {
      setChartFilter(el);
    });

    bus.on('filterList', () => {
      setModalOpen(!modalOpen);
    });
  }, []);

  useEffect(() => {
    // fetchCategories()
  }, [searchCategory]);

  function handleClick(event) {
    event.preventDefault();
    navigate(-1);
  }

  const handleFetchStates = () => {
    setLoadingState(true);
    axiosInstance
      .get(`/admin/data-insights/brand-product-location/${brand._id}`, {
        params: {
          search: searchStates3 || searchStates,
        },
      })
      .then((res) => {
        // setStates(res.data.data[0].states)
        const filteredStates = res.data.data[0].states.filter((state) =>
          state
            .toLowerCase()
            .includes(
              (searchStates || searchStates2 || searchStates3).toLowerCase()
            )
        );

        setStates(filteredStates);
      })
      .finally(() => setLoadingState(false));
  };

  useEffect(() => {
    handleFetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStates, searchStates2, searchStates3]);

  useEffect(() => {
    // fetchStates();
    // handleFetchStates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, state, searchStates, searchStates2, searchStates3]);

  const handleSearchProduct = (e) => {
    setSearchProducts(e.target.value);
  };

  const handleSearchState = (e) => {
    setSearchStates(e.target.value);
  };

  const handleSelectAllProduct = () => {
    setSelectAllProduct(!selectAllProduct);
    const selectedIds = productData?.map((option) => option._id);
    setSelectedProductIds(selectAllProduct ? [] : selectedIds);
  };

  const handleSelectAllStates = () => {
    setSelectAllStates(!selectAllStates);
    const selectedStates = states?.map((option) => option);
    setSelectedState(selectAllStates ? [] : selectedStates);
  };

  const handleCheckboxChange = (id) => {
    if (selectedProductIds.includes(id)) {
      setSelectedProductIds(
        selectedProductIds.filter((checkboxId) => checkboxId !== id)
      );
    } else {
      setSelectedProductIds([...selectedProductIds, id]);
    }
  };

  const handleCheckboxChange2 = (id) => {
    setSelectedProductIds([id]);
  };

  const handleProductCatCheckBoxChange = (id) => {
    if (selectedProductCatIds.includes(id)) {
      setSelectedProductCatIds(
        selectedProductCatIds.filter((checkboxId) => checkboxId !== id)
      );
    } else {
      setSelectedProductCatIds([...selectedProductCatIds, id]);
    }
  };

  const handleProductCatCheckBoxChange1 = (id) => {
    setSelectedProductCatIds([id]);
  };

  const handleProductCatCheckBoxChange2 = (id) => {
    setSelectedProductCatIds(id);
  };

  const handleCategoryBoxChange = (id) => {
    if (selectedCategoryIds.includes(id)) {
      setSelectedCategoryIds(
        selectedCategoryIds.filter((checkboxId) => checkboxId !== id)
      );
    } else {
      setSelectedCategoryIds([...selectedCategoryIds, id]);
    }
  };

  const handleStateChange = (val) => {
    setSelectedState(val);
    // if (selectedState.includes(val)) {
    //   setSelectedState(selectedState.filter((checkbox) => checkbox !== val));
    // } else {
    //   setSelectedState([...selectedState, val]);
    // }
  };

  const handleStateChange2 = (val) => {
    if (selectedState.includes(val)) {
      setSelectedState(selectedState.filter((checkbox) => checkbox !== val));
    } else {
      setSelectedState([...selectedState, val]);
    }
  };

  const handleFetchCategoryProducts = (id) => {
    setIsCategoryPending(true);
    setCategoryProductId(id);
    axiosInstance
      .get(`/admin/product/in-category/${id}`, {
        params: {
          search: searchCategory ? searchCategory : null,
        },
      })
      .then((res) => {
        setCatProductData(res?.data?.data[0]?.products);
        setIsCategoryPending(false);
      })
      .finally(() => setIsCategoryPending(false));
  };

  useEffect(() => {
    handleFetchCategoryProducts(categoryProductId);
  }, [searchCategory]);

  const handleFetchCardData = (products, locations) => {
    setCardLoader(true);
    axiosInstance
      .get(`/admin/data-insights/cards?`, {
        params: {
          dateFilter: dataFilter,
          brands: [brand?._id],
          products: products ? products : null,
          locations: locations ? locations : null,
        },
      })
      .then((res) => {
        if (res.status < 300) {
          setCardsData(res.data.data);
          setSelectedProductIds([]);
          setSelectedState('');
        }
      })
      .catch((err) => {
        // notifyError(err.response.data.message)
        // notifyError('Unable to fetch card data')
      })
      .finally(() => setCardLoader(false));
  };

  const handleFetchProduct = () => {
    setIsProductPending(true);
    axiosInstance
      .get(`/admin/data-insights/brand-product/${brand._id}`, {
        params: {
          search: searchProducts,
        },
      })
      .then((res) => {
        setProductData(res.data.data);
        setIsProductPending(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch {
          // notifyError('Unable to fetch product!')
        }
      })
      .finally(() => setIsProductPending(false));
  };

  useEffect(() => {
    handleFetchCardData();
  }, [dataFilter]);

  useEffect(() => {
    handleFetchProduct();
    handleFetchProductCategories();
  }, [searchProducts]);

  const handleModalVal = (val) => {
    setModalOpen(true);
    setModalVal(val);
  };

  const handleProceedFilter = () => {
    setModalOpen(!modalOpen);
    if (modalVal === 'p_s') {
      fetchProductSalesStat();
    } else if (modalVal === 't_o_p') {
      fetchTrendsOfPurchase();
    } else {
      handleFetchCardData(selectedProductIds, selectedState);
    }
  };

  const [whichVal, setWhichVal] = useState('');

  const handleWhichVal = (val) => {
    setModalOpen2(true);
    setWhichVal(val);
  };

  const handleProceedFilter2 = () => {
    setModalOpen2(!modalOpen2);
    if (whichVal === 'ppbsv') {
      fetchPurchasedProductStat();
    } else {
      fetchDepletionStatsStat();
    }
  };

  const handleProceedFilter3 = () => {
    setModalOpen3(!modalOpen3);
    fetchBuyingPowerStat();
  };

  const handleMSChartFilter = () => {
    fetchMarketShareChart();
  };

  const handleHPLChartFilter = () => {
    fetchHighestLocationStat();
  };

  const initialSelectedChartData = allChartData.map((item) => item.value);

  const [openEnableFilterModal, setOpenEnableFilterModal] = useState(false);
  const [selectedChartData, setSelectedChartData] = useState(
    initialSelectedChartData
  );

  const handleOpenEnableFilterModal = () => {
    setOpenEnableFilterModal(true);
  };

  const handleCloseEnableFilterModal = () => {
    setOpenEnableFilterModal(false);
  };

  const handleChartDataChange = (val) => {
    if (selectedChartData.includes(val)) {
      setSelectedChartData(
        selectedChartData.filter((checkboxVal) => checkboxVal !== val)
      );
    } else {
      setSelectedChartData([...selectedChartData, val]);
    }
  };

  function formatAmount(amount) {
    if (amount >= 1000000) {
      const formattedAmount = (amount / 1000000).toFixed(1);
      return formattedAmount + 'M';
    } else {
      return Number(amount).toLocaleString();
    }
  }

  const renderRows = () => {
    return storeData.slice(0, visibleItems).map((el, i) => (
      <tr key={i} className={`${styles.tr}`}>
        <td>
          <p>{i + 1}</p>
        </td>
        <td>
          <p>{el?.state}</p>
        </td>
        <td>
          <p>{el?.storeName}</p>
        </td>
        <td>
          <p>{formatAmount(el?.avgSales)}</p>
        </td>
        <td>
          <p>₦ {formatAmount(el?.avgRevenue)}</p>
        </td>
        <td>
          <p>{formatAmount(el?.totalShoppers)}</p>
        </td>
        {/* <td>
          <p>{formatAmount(el?.totalSale)}</p>
        </td> */}
      </tr>
    ));
  };

  const visibleDataCount =
    storeData.length > visibleItems ? visibleItems : storeData.length;

  return (
    <>
      <div className="mt-md-0 mt-4 pt-2 px-2">
        <button onClick={handleClick} className="btn pl-0">
          <div
            className="p-1"
            style={{
              border: '1px solid lightgrey',
              borderRadius: '3px',
            }}
          >
            <img src="/assets/icons/Chevronleft.svg" alt="" />
          </div>
        </button>
        <div className="row mt-3 mb-4 p-3">
          <div
            className={`${styles.wallet_actions} d-md-flex justify-content-between`}
          >
            <div className=" ">
              <h4 className={`mb-0 pb-0 font-weight-bold`}>
                {brand ? brand.name : '---'}
              </h4>
              <p
                style={{ fontSize: '14px', color: '#4b5563' }}
                className={`mb-0 mt-2 pb-0`}
              >
                An overview of your orders and performance metrics
              </p>
            </div>
          </div>
        </div>

        <Filter2 width="100%" show={true} />
        <div className="row mb-2" style={{ justifyContent: 'space-between' }}>
          <div className="col mb-2 p-0 m-1">
            <div className={`${styles.card}`} style={{ height: '100%' }}>
              <div className={`card-body h-100`}>
                <div
                  className="d-flex justify-content-between h-100"
                  style={{ flexDirection: 'column' }}
                >
                  <p
                    className="font-weight-bold p-0"
                    style={{ color: '#4b5563' }}
                  >
                    Gross Merchandise Value (GMV)
                  </p>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        ₦
                        {!cardLoader &&
                          Number(
                            cardsData?.grossMerchandiseValue?.boldCount
                          ).toLocaleString()}
                        {cardLoader && (
                          <CircularProgress
                            style={{ color: '#af3585' }}
                            size="1rem"
                          />
                        )}
                      </span>
                    </div>
                    <div>
                      {cardsData?.grossMerchandiseValue?.boldCount <
                      cardsData?.grossMerchandiseValue?.smallCount ? (
                        <div className={styles.percent2}>
                          <img src="/assets/icons/down.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.grossMerchandiseValue?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      ) : (
                        <div className={styles.percent}>
                          <img src="/assets/icons/up.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.grossMerchandiseValue?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col mb-2 p-0 m-1">
            <div className={`${styles.card}`} style={{ height: '100%' }}>
              <div className={`card-body h-100`}>
                <div
                  className="d-flex justify-content-between h-100"
                  style={{ flexDirection: 'column' }}
                >
                  <p
                    className="font-weight-bold p-0"
                    style={{ color: '#4b5563' }}
                  >
                    Net Merchandise Value (NMV)
                  </p>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        ₦
                        {!cardLoader &&
                          Number(
                            cardsData?.netMerchandiseValue?.boldCount
                          ).toLocaleString()}
                        {cardLoader && (
                          <CircularProgress
                            style={{ color: '#af3585' }}
                            size="1rem"
                          />
                        )}
                      </span>
                    </div>
                    <div>
                      {cardsData?.netMerchandiseValue?.boldCount <
                      cardsData?.netMerchandiseValue?.smallCount ? (
                        <div className={styles.percent2}>
                          <img src="/assets/icons/down.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.netMerchandiseValue?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      ) : (
                        <div className={styles.percent}>
                          <img src="/assets/icons/up.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.netMerchandiseValue?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col mb-2 p-0 m-1">
            <div className={`${styles.card}`} style={{ height: '100%' }}>
              <div className={`card-body h-100`}>
                <div
                  className="d-flex justify-content-between h-100"
                  style={{ flexDirection: 'column' }}
                >
                  <p
                    className="font-weight-bold p-0"
                    style={{ color: '#4b5563' }}
                  >
                    Total Orders
                  </p>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        {!cardLoader &&
                          Number(
                            cardsData?.totalOrder?.boldCount
                          ).toLocaleString()}
                        {cardLoader && (
                          <CircularProgress
                            style={{ color: '#af3585' }}
                            size="1rem"
                          />
                        )}
                      </span>
                    </div>
                    <div>
                      {cardsData?.totalOrder?.boldCount <
                      cardsData?.totalOrder?.smallCount ? (
                        <div className={styles.percent2}>
                          <img src="/assets/icons/down.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.totalOrder?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      ) : (
                        <div className={styles.percent}>
                          <img src="/assets/icons/up.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.totalOrder?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col mb-2 p-0 m-1">
            <div className={`${styles.card}`} style={{ height: '100%' }}>
              <div className={`card-body h-100`}>
                <div
                  className="d-flex justify-content-between h-100"
                  style={{ flexDirection: 'column' }}
                >
                  <p
                    className="font-weight-bold p-0"
                    style={{ color: '#4b5563' }}
                  >
                    Completed Orders
                  </p>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        {!cardLoader &&
                          Number(
                            cardsData?.completedOrder?.boldCount
                          ).toLocaleString()}
                        {cardLoader && (
                          <CircularProgress
                            style={{ color: '#af3585' }}
                            size="1rem"
                          />
                        )}
                      </span>
                    </div>
                    <div>
                      {cardsData?.completedOrder?.boldCount <
                      cardsData?.completedOrder?.smallCount ? (
                        <div className={styles.percent2}>
                          <img src="/assets/icons/down.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.completedOrder?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      ) : (
                        <div className={styles.percent}>
                          <img src="/assets/icons/up.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.completedOrder?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col mb-2 p-0 m-1">
            <div className={`${styles.card}`} style={{ height: '100%' }}>
              <div className={`card-body h-100`}>
                <div
                  className="d-flex justify-content-between h-100"
                  style={{ flexDirection: 'column' }}
                >
                  <p
                    className="font-weight-bold p-0"
                    style={{ color: '#4b5563' }}
                  >
                    Abandoned Orders
                  </p>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        {!cardLoader &&
                          Number(
                            cardsData?.abandonOrder?.boldCount
                          ).toLocaleString()}
                        {cardLoader && (
                          <CircularProgress
                            style={{ color: '#af3585' }}
                            size="1rem"
                          />
                        )}
                      </span>
                    </div>
                    <div>
                      {cardsData?.abandonOrder?.boldCount <
                      cardsData?.abandonOrder?.smallCount ? (
                        <div className={styles.percent2}>
                          <img src="/assets/icons/down.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.abandonOrder?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      ) : (
                        <div className={styles.percent}>
                          <img src="/assets/icons/up.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.abandonOrder?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex">
          <button
            className="btn btn-light ml-auto"
            type="button"
            onClick={handleOpenEnableFilterModal}
            style={{ border: '1px solid lightgrey', cursor: 'pointer' }}
          >
            <span>
              <img src="/assets/icons/trending-up.svg" alt="" /> &nbsp; View
              Charts
            </span>
          </button>

          {openEnableFilterModal && (
            <div
              className={`${styles.side_modal_overlay}`}
              onClick={handleCloseEnableFilterModal}
            >
              <div
                className={`${styles.side_modal_content}`}
                onClick={(e) => e.stopPropagation()}
              >
                {/* Your modal content goes here */}
                <div className="d-flex">
                  <h5>
                    <strong>Select Chart</strong>
                  </h5>
                  <span
                    onClick={handleCloseEnableFilterModal}
                    className="ml-auto"
                    style={{ cursor: 'pointer' }}
                  >
                    <img src="/assets/icons/x.svg" alt="" />
                  </span>
                </div>
                <p>
                  <small>Select Insight to display</small>
                </p>

                {allChartData.map((el, i) => {
                  return (
                    <div className="p-2 d-md-flex" key={i}>
                      <div className="mr-3">
                        <input
                          type="checkbox"
                          checked={selectedChartData.includes(el.value)}
                          onChange={() => handleChartDataChange(el.value)}
                        />
                      </div>
                      <div className=" ">
                        <p
                          className="mb-0 text-left"
                          style={{ fontSize: '14px' }}
                        >
                          {el?.name}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        {selectedChartData.includes('r_g') && (
          <Card
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              borderRadius: '10px',
            }}
            className="p-0"
          >
            <Filter3 width="100%" show={false} />
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Revenue Growth</h5>
                <div className="d-md-flex justify-content-between">
                  <p>Track your financial growth</p>
                </div>
                <div className="row mt-md-0 mt-4"></div>
                <div className="d-flex">
                  <div className="dropleft "></div>
                  &nbsp; &nbsp;
                </div>
                <div className="row mt-md-0 mt-4">
                  <div className="col-md-4 mb-2 mt-4"></div>
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <div className="card p-0 mb-2 mt-4">
                      <div className="card-body d-flex justify-content-around  py-1">
                        <label htmlFor="revgrowth">
                          <input
                            type="radio"
                            id="revgrowth"
                            name="revgrowth"
                            value={1}
                            checked={selectedTab === 1}
                            onChange={() => handleTabChange(1)}
                            className="mt-2 mr-2"
                          />
                          Bar Chart
                        </label>
                        <label htmlFor="revgrowth2">
                          <input
                            type="radio"
                            id="revgrowth2"
                            name="revgrowth"
                            value={2}
                            checked={selectedTab === 2}
                            onChange={() => handleTabChange(2)}
                            className="mt-2 mr-2"
                          />
                          Line Chart
                        </label>
                      </div>
                    </div>
                  </div>
                  <span className="ml-3 mt-5">
                    <img src="/assets/icons/ordercolor.svg" alt="" />
                    &nbsp; Revenue
                  </span>
                </div>
              </div>
              <span className="ml-md-3" style={{ fontSize: '12px' }}>
                REVENUE
              </span>
              {isRevGrowthPending && (
                <BallTriangle
                  height={35}
                  width={45}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
              <>
                {selectedTab === 1 && (
                  <div>
                    {/* Content for Tab 1 */}
                    <div className="chart-container">
                      <ReactApexChart
                        type="bar"
                        series={chartSeries}
                        options={chartOptions}
                        height={500}
                      />
                    </div>
                  </div>
                )}
                {selectedTab === 2 && (
                  <div className="chart-container">
                    {/* Content for Tab 2 */}
                    <ReactApexChart
                      type="line"
                      series={chartSeries}
                      options={chartOptions}
                      height={500}
                    />
                  </div>
                )}
              </>
            </CardContent>
          </Card>
        )}

        {selectedChartData.includes('d_s') && (
          <Card
            style={{ marginBottom: '20px', borderRadius: '10px' }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Depletion Stats</h5>
                <div className="d-md-flex justify-content-between">
                  <p>
                    Compare your orders against the depletion rate of your
                    product
                  </p>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6 mb-2 mt-4"></div>
                  <div className="col-md-2"></div>
                  <div className="col-md-4"></div>
                </div>
                <div className="d-flex mbf-5">
                  <div className="dropleft ml-auto">
                    <button
                      className="btn  border"
                      onClick={() => setStatFilter1(true)}
                      style={{
                        backgroundColor: '#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px',
                      }}
                    >
                      <img
                        className=""
                        style={{ display: 'inline', width: '17px' }}
                        src="/assets/icons/filterlines.svg"
                        alt=""
                      />
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                        Filters
                      </small>
                    </button>
                  </div>
                  &nbsp; &nbsp;
                </div>
              </div>
              <span className="ml-md-3" style={{ fontSize: '12px' }}>
                QUANTITY
              </span>
              {isDepStatPending && (
                <BallTriangle
                  height={35}
                  width={45}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
              <div className="chart-container">
                <ReactApexChart
                  type="bar"
                  series={chartSeries1}
                  options={chartOptions1}
                  height={500}
                />
              </div>
            </CardContent>
          </Card>
        )}

        {selectedChartData.includes('p_p_b_s_v') && (
          <Card
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              borderRadius: '10px',
            }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Purchased Product By SKU Variations</h5>
                <div className="d-md-flex justify-content-between">
                  <p>View the total sales of your individual products</p>
                </div>
                <div className="d-flex mbf-5">
                  <div className="dropleft ml-auto">
                    <button
                      className="btn  border"
                      onClick={() => handleWhichVal('ppbsv')}
                      style={{
                        backgroundColor: '#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px',
                      }}
                    >
                      <img
                        className=""
                        style={{ display: 'inline', width: '17px' }}
                        src="/assets/icons/filterlines.svg"
                        alt=""
                      />
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                        Filters
                      </small>
                    </button>
                  </div>
                </div>
              </div>
              <span className="ml-md-3" style={{ fontSize: '12px' }}>
                QUANTITY
              </span>
              {isPurchasedProductPending && (
                <BallTriangle
                  height={35}
                  width={45}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
              <div className="chart-container">
                <ReactApexChart
                  type="bar"
                  series={chartSeries4}
                  options={chartOptions4}
                  height={500}
                />
              </div>
            </CardContent>
          </Card>
        )}
        {selectedChartData.includes('d_s_b_s_v') && (
          <Card
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              borderRadius: '10px',
            }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Depletion Stats By SKU Variations</h5>
                <div className="d-md-flex justify-content-between">
                  <p>
                    Compare your orders against the depletion rate of your{' '}
                    <br />
                    product
                  </p>
                </div>
                <div className="d-flex mbf-5">
                  <div className="dropleft ml-auto">
                    <button
                      className="btn  border"
                      onClick={() => handleWhichVal('dsbsv')}
                      style={{
                        backgroundColor: '#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px',
                      }}
                    >
                      <img
                        className=""
                        style={{ display: 'inline', width: '17px' }}
                        src="/assets/icons/filterlines.svg"
                        alt=""
                      />
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                        Filters
                      </small>
                    </button>
                  </div>
                </div>
              </div>
              <span className="ml-md-3" style={{ fontSize: '12px' }}>
                QUANTITY
              </span>
              {isDepletionStatsPending && (
                <BallTriangle
                  height={35}
                  width={45}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
              <div className="chart-container">
                <ReactApexChart
                  type="bar"
                  series={chartSeries04}
                  options={chartOptions04}
                  height={500}
                />
              </div>
            </CardContent>
          </Card>
        )}

        {selectedChartData.includes('p_s') && (
          <Card
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              borderRadius: '10px',
            }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Product Sales</h5>
                <div className="d-md-flex justify-content-between">
                  <p>View the total sales of your individual products</p>
                </div>
              </div>
              <div className="d-flex mbf-5">
                <div className="dropleft ml-auto">
                  <button
                    className="btn  border"
                    onClick={() => handleModalVal('p_s')}
                    style={{
                      backgroundColor: '#fff',
                      border: '1px solid #D0D5DD',
                      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                      borderRadius: '4px',
                    }}
                  >
                    <img
                      className=""
                      style={{ display: 'inline', width: '17px' }}
                      src="/assets/icons/filterlines.svg"
                      alt=""
                    />
                    &nbsp;
                    <small className="d-none d-md-inline-block">Filters</small>
                  </button>
                </div>
                &nbsp; &nbsp;
              </div>
              <span className="ml-md-3" style={{ fontSize: '12px' }}>
                QUANTITY
              </span>
              {isProductSalesPending && (
                <BallTriangle
                  height={35}
                  width={45}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
              <div className="chart-container">
                <ReactApexChart
                  type="bar"
                  series={chartSeries5}
                  options={chartOptions5}
                  height={500}
                />
              </div>
            </CardContent>
          </Card>
        )}

        {selectedChartData.includes('h_p_l') && (
          <Card
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              borderRadius: '10px',
              overflow: 'visible',
            }}
            className={`${styles.zindex} p-0`}
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Highest Performing Store/Location</h5>
                <div className="d-md-flex justify-content-between">
                  <p>Monitor how your stores perform in different areas</p>
                </div>
                <div className="d-flex mbf-5">
                  <div>
                    <p
                      className="mt-4 font-bold"
                      style={{ fontWeight: 'bold' }}
                    >
                      Showing {visibleDataCount} of {storeData.length} entries
                    </p>
                  </div>
                  <div className="dropleft ml-auto">
                    <button
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{ fontSize: '15px', cursor: 'pointer' }}
                      className="btn p-2 border"
                    >
                      <img
                        className=""
                        style={{ display: 'inline', width: '17px' }}
                        src="/assets/icons/filterlines.svg"
                        alt=""
                      />
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                        Filters
                      </small>
                    </button>
                    <div
                      className={`${styles.product_dialog_two} dropdown-menu p-3`}
                      aria-labelledby="dropdownMenuButton"
                      ref={dropdownRef}
                      onClick={handleClickInsideDropdown}
                      style={{ maxHeight: '330px', overflowY: 'auto' }}
                    >
                      <div className="allprod_wrapper mt-m3">
                        <h6
                          className="modal-title mb-2"
                          id="exampleModalLabel"
                          style={{ fontWeight: 'bold' }}
                        >
                          Location/Store
                        </h6>
                        <div className={`${styles.modal_body} mt-1`}>
                          <div className="row my-0 py-0">
                            <div className="col-7 py-0 my-0">
                              <label htmlFor="country">Nigeria</label>
                            </div>
                            <div className="col-5 py-0 my-0">
                              <span
                                className={`${styles.span}`}
                                data-toggle="collapse"
                                data-target="#collapseExample001"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                              >
                                <label>
                                  <input type="checkbox" name="" />
                                  <span onClick={(e) => e.stopPropagation()}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      class="bi bi-chevron-down"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </span>
                                </label>
                              </span>
                            </div>
                          </div>
                          <div className="collapse" id="collapseExample001">
                            <div
                              className="card card-body pb-0"
                              style={{ border: 'none' }}
                            >
                              <p>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  name=""
                                  id=""
                                  onChange={handleSearchState}
                                  value={searchStates}
                                />
                              </p>
                              <div className="row mt-3">
                                {!loadingState &&
                                  states.length > 0 &&
                                  states?.slice(0, 14).map((el, i) => {
                                    return (
                                      <div
                                        className="col-6 py-0 my-0 d-md-flex"
                                        key={i}
                                      >
                                        <label htmlFor={`state-${i}`}>
                                          <input
                                            type="radio"
                                            id={`state-${i}`}
                                            className="mr-2"
                                            checked={selectedState.includes(el)}
                                            onChange={() =>
                                              handleStateChange(el)
                                            }
                                          />
                                        </label>
                                        <div>{el}</div>
                                      </div>
                                    );
                                  })}
                              </div>
                              {!loadingState && states?.length < 1 && (
                                <p>
                                  <i>No Records Found...</i>
                                </p>
                              )}
                              <div className="d-flex justify-content-center">
                                {loadingState && (
                                  <BallTriangle
                                    height={50}
                                    width={55}
                                    radius={5}
                                    color="#af3585"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperClass={{}}
                                    wrapperStyle=""
                                    visible={true}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5">
                          <button
                            className={`${styles.btn_primary} btn w-100 d-block`}
                            data-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            onClick={() => handleHPLChartFilter()}
                          >
                            Proceed
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  &nbsp; &nbsp;
                </div>
              </div>
              <span className="ml-md-3" style={{ fontSize: '12px' }}></span>
              <Scrollbar>
                <div className={`pt-3 pb-3 pl-0 pr-0`}>
                  <table
                    className={`${styles.table} table-hover table-striped`}
                    style={{ minWidth: '80em' }}
                  >
                    <thead className={`${styles.thead}`}>
                      <tr>
                        {TABLE_HEAD.map((el, i) => {
                          let sIndex = i < 1 ? 1 : false;

                          return (
                            <th key={el.id} className="text-muted">
                              {el.label}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>{!isBHPLPending && renderRows()}</tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-center">
                  {isBHPLPending && (
                    <BallTriangle
                      height={50}
                      width={55}
                      radius={5}
                      color="#53138D"
                      ariaLabel="ball-triangle-loading"
                      wrapperClass={{}}
                      wrapperStyle=""
                      visible={true}
                    />
                  )}
                </div>
                {!storeData.length && !isBHPLPending && (
                  <div className="alert alert-[#53138D] text-center">
                    <h4>No record found</h4>
                  </div>
                )}
              </Scrollbar>
              {storeData.length > visibleItems && (
                <span
                  className="d-flex ml-auto"
                  style={{ cursor: 'pointer' }}
                  onClick={handleSeeMore}
                >
                  <img
                    className="ml-auto"
                    src="/assets/icons/Chevronleft.svg"
                    alt=""
                  />{' '}
                  See more
                </span>
              )}
            </CardContent>
          </Card>
        )}

        {/* {
          selectedChartData.includes('h_p_s') &&
          <Card className={`${styles.card_wrapper} p-0`} style={{marginTop:'0px',marginBottom:'20px',borderRadius:'10px'}}>
            <CardContent>
              <div className={`${styles.insight_header}`}>
                <h5>
                  Highest Performing Store
                </h5>
                <div className="d-lg-flex justify-content-between">
                  <p>
                    See the stores that are performing best
                  </p>
                </div>
                <div className="d-flex">
                  <div className="dropleft ml-auto">
                    <button className="btn  border"  onClick={() => setStatFilter1(true)}
                      style={{
                        backgroundColor:'#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow:  '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px',
                        width: '100px',
                        height: '40px',
                      }}>
                      <img className="" style={{display:'inline',width:'28%'}} src="/assets/icons/filterlines.svg" alt="" />
                      &nbsp;
                      <small className="d-none d-sm-inline-block">
                      Filter
                      </small>
                    </button>

                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      <button type="button"  className=" dropdown-item btn border-0" style={{fontWeight:'lighter'}}>All Brands </button>
                      <button type="button"  className=" dropdown-item btn border-0">Brands you buy</button>
                    </div>
                  </div>
                  &nbsp;
                  &nbsp;
                </div>
              </div>
              <span className="ml-md-3" style={{fontSize: '12px'}}></span>
              <div className="chart-container">
                <ReactApexChart type="bar" series={chartSeries7} options={chartOptions7} height={500} />
              </div>

            </CardContent>
          </Card>
        } */}

        {selectedChartData.includes('t_o_p') && (
          <Card
            style={{
              marginBottom: '20px',
              borderRadius: '10px',
              zIndex: 'auto !important',
            }}
            className={`${styles.zindex} p-0`}
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Trends of Purchase</h5>
                <div className="d-md-flex justify-content-between">
                  <p>View how well each products are doing in the market </p>
                </div>
                <div className="d-flex mbf-5">
                  <div className="dropleft ml-auto">
                    <button
                      className="btn  border"
                      onClick={() => handleModalVal('t_o_p')}
                      style={{
                        backgroundColor: '#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px',
                      }}
                    >
                      <img
                        className=""
                        style={{ display: 'inline', width: '17px' }}
                        src="/assets/icons/filterlines.svg"
                        alt=""
                      />
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                        Filters
                      </small>
                    </button>
                  </div>
                </div>
              </div>
              <span className="ml-md-3" style={{ fontSize: '12px' }}>
                QUANTITY
              </span>
              {isTrendsOfPurchasePending && (
                <BallTriangle
                  height={35}
                  width={45}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
              <div className="chart-container">
                <ReactApexChart
                  type="line"
                  series={chartSeries8}
                  options={chartOptions8}
                  height={500}
                />
              </div>
            </CardContent>
          </Card>
        )}

        {selectedChartData.includes('m_s') && (
          <Card
            style={{ marginBottom: '20px', borderRadius: '10px' }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Market Share</h5>
                <div className="d-md-flex justify-content-between">
                  <p>A view of your product's market share</p>
                </div>
                <div className="d-flex">
                  <div className="dropleft ml-auto">
                    <button
                      className="btn border"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{
                        backgroundColor: '#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px',
                      }}
                    >
                      <img
                        className=""
                        style={{ display: 'inline', width: '17px' }}
                        src="/assets/icons/filterlines.svg"
                        alt=""
                      />
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                        Filters
                      </small>
                    </button>

                    <div
                      className={`${styles.product_dialog_three} dropdown-menu p-3`}
                      aria-labelledby="dropdownMenuButton3"
                      ref={dropdownRef3}
                      onClick={handleClickInsideDropdown}
                      style={{ maxHeight: '330px', overflowY: 'auto' }}
                    >
                      <div className="allprod_wrapper mt-m3">
                        <div className={`${styles.modal_header} d-flex`}>
                          <h6
                            className="modal-title mb-2"
                            id="exampleModalLabel"
                            style={{ fontWeight: 'bold' }}
                          >
                            Categories
                          </h6>
                          <span
                            className="ml-auto"
                            style={{ cursor: 'pointer' }}
                          >
                            <img src="/assets/icons/x.svg" alt="" />
                          </span>
                        </div>
                        <p className="ml-md-1">Select Categories</p>
                        <div className={`${styles.modal_body} mt-1`}>
                          <div
                            className="card card-body pl-1 pb-0"
                            style={{ border: 'none' }}
                          >
                            <p>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                name=""
                                id=""
                                onChange={handleSearchProduct}
                                value={searchProducts}
                              />
                            </p>
                            {!isProductCatPending &&
                              productCatData?.length > 0 &&
                              productCatData?.slice(0, 5).map((el, i) => {
                                const filteredProducts = el?.products?.filter(
                                  (product) =>
                                    product?.product_name
                                      ?.toLowerCase()
                                      .includes(searchCategory.toLowerCase())
                                );
                                return (
                                  <>
                                    <div className="p-2 pl-3 d-md-flex" key={i}>
                                      <div className="mr-3 mt-1">
                                        <input
                                          type="radio"
                                          checked={selectedProductCatIds.includes(
                                            el?._id
                                          )}
                                          onChange={() =>
                                            handleProductCatCheckBoxChange2(
                                              el?._id
                                            )
                                          }
                                        />
                                      </div>
                                      <Avatar
                                        className={`${styles.avatrr} mr-3 floadt-md-left`}
                                        alt={el?.category_name}
                                        src={el?.category_image}
                                      />
                                      <div className=" ">
                                        <p
                                          className="mb-0 text-left"
                                          style={{ fontSize: '14px' }}
                                        >
                                          <strong>{el?.category_name}</strong>
                                        </p>
                                        <p
                                          className="mb-0 text-left"
                                          style={{ fontSize: '10px' }}
                                        >
                                          {el?.number_of_product < 2
                                            ? el?.number_of_product + ' product'
                                            : el?.number_of_product +
                                              ' products'}
                                        </p>
                                      </div>
                                      <div></div>
                                    </div>
                                  </>
                                );
                              })}
                            {!isProductCatPending &&
                              productCatData?.length < 1 && (
                                <p>
                                  <i>No Records Found...</i>
                                </p>
                              )}
                            <div className="d-flex justify-content-center">
                              {isProductCatPending && (
                                <BallTriangle
                                  height={50}
                                  width={55}
                                  radius={5}
                                  color="#af3585"
                                  ariaLabel="ball-triangle-loading"
                                  wrapperClass={{}}
                                  wrapperStyle=""
                                  visible={true}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="mt-5">
                          <button
                            className={`${styles.btn_primary} btn w-100 d-block`}
                            data-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            onClick={() => handleMSChartFilter()}
                          >
                            Proceed
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  &nbsp; &nbsp;
                </div>
              </div>
              {isMarketSharePending && (
                <BallTriangle
                  height={35}
                  width={45}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
              <div className="row">
                <div className="col-12">
                  <div className="chart-container chartOptions2">
                    <div className="pie_chart_wrap">
                      <ReactApexChart
                        type="pie"
                        series={chartSeries2}
                        options={chartOptions2}
                        height={400}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        )}

        {selectedChartData.includes('s_b_p') && (
          <Card
            style={{ marginBottom: '20px', borderRadius: '10px' }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Shopper's purchasing power</h5>
                <div className="d-md-flex justify-content-between">
                  <p>An overview of the average amount your shoppers buy</p>
                </div>
                <div className="row mt-md-5 mt-4">
                  <div className="col-md-12 mb-2">
                    <div className="row">
                      <div className="col-md-8 p-0 mt-3d">
                        <div className="d-flex">
                          <div className="dropleft ml-auto mr-2">
                            <button
                              className="btn  border"
                              onClick={() => setModalOpen3(true)}
                              style={{
                                backgroundColor: '#fff',
                                border: '1px solid #D0D5DD',
                                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                borderRadius: '4px',
                              }}
                            >
                              <img
                                className=""
                                style={{ display: 'inline', width: '17px' }}
                                src="/assets/icons/filterlines.svg"
                                alt=""
                              />
                              &nbsp;
                              <small className="d-none d-md-inline-block">
                                Filters
                              </small>
                            </button>
                            <div
                              className="dropdown-menu drop-left"
                              aria-labelledby="dropdownMenuButton"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-0 mt-3d">
                        <div className="card p-0">
                          <div className="card-body d-flex justify-content-around  py-1">
                            <label htmlFor="bar-chart">
                              <input
                                type="radio"
                                name="insight_shoppers"
                                id="bar-chart"
                                value={1}
                                checked={selectedTab01 === 1}
                                onChange={() => handleTabChange01(1)}
                              />{' '}
                              Bar Chart
                            </label>
                            <label htmlFor="line-chart">
                              <input
                                type="radio"
                                name="insight_shoppers"
                                id="line-chart"
                                value={2}
                                checked={selectedTab01 === 2}
                                onChange={() => handleTabChange01(2)}
                              />{' '}
                              Line Chart
                            </label>
                            <label htmlFor="pie-chart">
                              <input
                                type="radio"
                                name="insight_shoppers"
                                id="pie-chart"
                                value={3}
                                checked={selectedTab01 === 3}
                                onChange={() => handleTabChange01(3)}
                              />{' '}
                              Pie Chart
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span className="ml-md-3" style={{ fontSize: '12px' }}></span>
              {isBPPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
              {selectedTab01 === 1 && (
                <div className="chart-container">
                  <ReactApexChart
                    type="bar"
                    series={chartSeries3}
                    options={chartOptions3}
                    height={500}
                  />
                </div>
              )}
              {selectedTab01 === 2 && (
                <div className="chart-container">
                  <ReactApexChart
                    type="line"
                    series={chartSeries3}
                    options={chartOptions3}
                    height={500}
                  />
                </div>
              )}
              {selectedTab01 === 3 && (
                <div className="chart-container">
                  <ReactApexChart
                    type="pie"
                    series={chartSeries02}
                    options={chartOptions02}
                    height={500}
                  />
                </div>
              )}
            </CardContent>
          </Card>
        )}

        {selectedChartData.includes('o_b_l') && (
          <Card
            className={`${styles.card_wrapper} p-0`}
            style={{ marginBottom: '20px', borderRadius: '10px' }}
          >
            <CardContent>
              <div className="row">
                <div className="col-md-7">
                  <GoogleMapHeatmap data={mapData} />
                </div>
                <div className="col-md-4">
                  <div className={`${styles.insight_header} mt-5`}>
                    <h5>Order by Location</h5>
                    <div className="d-lg-flex justify-content-between">
                      <p>View how your order is doing on various location</p>
                    </div>
                    <div>
                      <div className="dropleft ">
                        <button
                          className="btn  border"
                          onClick={() => setStatFilter01(true)}
                          style={{
                            backgroundColor: '#fff',
                            border: '1px solid #D0D5DD',
                            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                            borderRadius: '4px',
                          }}
                        >
                          <img
                            className=""
                            style={{ display: 'inline', width: '17px' }}
                            src="/assets/icons/filterlines.svg"
                            alt=""
                          />
                          &nbsp;
                          <small className="d-none d-md-inline-block">
                            Filters
                          </small>
                        </button>
                        <div
                          className="dropdown-menu drop-left"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {/* <button type="button" className="rss dropdown-item btn border-0">All</button>
                          <button type="button"  className="rss dropdown-item btn border-0">Abandoned </button>
                          <button type="button"  className="rss dropdown-item btn border-0">Completed</button> */}
                        </div>
                      </div>
                    </div>
                    {isOBLPending && (
                      <BallTriangle
                        height={50}
                        width={55}
                        radius={5}
                        color="#af3585"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        )}

        <GlobalModal
          open={modalOpen}
          onClose={handleClose}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="740px !important"
          height="auto !important"
          maxHeight="500px !important"
          overflow="auto"
          bgcolor="#fff"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          p="25px"
          style={{ maxWidth: '500px', overflow: 'auto' }}
        >
          <div className={`${styles.modal_content}`}>
            <div className={`${styles.modal_header} mb-0`}>
              <h5
                style={{
                  fontSize: '19px',
                  fontWeight: '700',
                }}
              >
                {/* Filter */}
              </h5>
              <span
                onClick={handleClose}
                className="justify-content-end"
                style={{ cursor: 'pointer' }}
              >
                <img src="/assets/icons/x.svg" alt="" />
              </span>
            </div>
            <div className={`${styles.modal_content} row`}>
              <div className="col-md-6">
                <div className="allprod_wrapper">
                  <div className="modal-header" style={{ border: 'none' }}>
                    <h5 className="modal-title" id="exampleModalLabel">
                      Products
                    </h5>
                  </div>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-7 py-0 my-0">
                        <label htmlFor="allprod">
                          <input
                            type="checkbox"
                            checked={selectAllProduct}
                            onChange={handleSelectAllProduct}
                          />
                          &nbsp;&nbsp;All Products
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample01"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse" id="collapseExample01">
                      <div
                        className="card card-body pl-3 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchProduct}
                            value={searchProducts}
                          />
                        </p>
                        {!isProductPending &&
                          productData.length > 0 &&
                          productData.slice(0, 5).map((el, i) => {
                            return (
                              <div className="p-2 d-md-flex" key={i}>
                                <div className="mr-3 mt-1">
                                  <input
                                    type="checkbox"
                                    checked={selectedProductIds.includes(
                                      el._id
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(el._id)
                                    }
                                  />
                                </div>
                                <div className=" ">
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '14px' }}
                                  >
                                    <strong>{el?.product_name}</strong>
                                  </p>
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '10px' }}
                                  >
                                    {el?.product_spec}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        {!isProductPending && productData.length < 1 && (
                          <p>
                            <i>No Records Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {isProductPending && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`${styles.bordered_sect} col-md-6`}
                style={{
                  borderLeft: '2px solid #ddd',
                }}
              >
                <div className="allprod_wrapper">
                  <div className="modal-header" style={{ border: 'none' }}>
                    <h5 className="modal-title" id="exampleModalLabel">
                      Location/Store
                    </h5>
                  </div>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-5 py-0 my-0">
                        <label htmlFor="country">Nigeria</label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample02"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse pl-1" id="collapseExample02">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchState}
                            value={searchStates}
                          />
                        </p>
                        <div className="row mt-3">
                          {!loadingState &&
                            states.length > 0 &&
                            states?.slice(0, 14).map((el, i) => {
                              return (
                                <div
                                  className="col-6 py-0 my-0 d-md-flex"
                                  key={i}
                                >
                                  <label htmlFor={`state-${i}`}>
                                    <input
                                      type="radio"
                                      id={`state-${i}`}
                                      className="mr-2"
                                      checked={selectedState.includes(el)}
                                      onChange={() => handleStateChange(el)}
                                    />
                                  </label>
                                  <div>{el}</div>
                                </div>
                              );
                            })}
                        </div>
                        {!loadingState && states?.length < 1 && (
                          <p>
                            <i>No Records Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {loadingState && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <button
                className={`${styles.btn_primary} btn w-100 d-block`}
                data-dismiss="modal"
                aria-label="Close"
                type="button"
                onClick={() => handleProceedFilter()}
              >
                Proceed
              </button>
            </div>
          </div>
        </GlobalModal>

        <GlobalModal
          open={modalOpen2}
          onClose={handleClose2}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="740px !important"
          height="auto !important"
          maxHeight="500px !important"
          overflow="auto"
          bgcolor="#fff"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          p="25px"
          style={{ maxWidth: '500px', overflow: 'auto' }}
        >
          <div className={`${styles.modal_content}`}>
            <div className={`${styles.modal_header} mb-0`}>
              <h5
                style={{
                  fontSize: '19px',
                  fontWeight: '700',
                }}
              >
                {/* Filter */}
              </h5>
              <span
                onClick={handleClose2}
                className="justify-content-end"
                style={{ cursor: 'pointer' }}
              >
                <img src="/assets/icons/x.svg" alt="" />
              </span>
            </div>
            <div className={`${styles.modal_content} row`}>
              <div className="col-md-6">
                <div className="allprod_wrapper">
                  <div className="modal-header" style={{ border: 'none' }}>
                    <h5 className="modal-title" id="exampleModalLabel">
                      Products
                    </h5>
                  </div>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-5 py-0 my-0">
                        <label htmlFor="allprod">
                          {whichVal === 'ppbsv' && (
                            <>
                              <input
                                type="checkbox"
                                checked={selectAllProduct}
                                onChange={handleSelectAllProduct}
                              />
                              &nbsp;&nbsp;All Products
                            </>
                          )}
                          {whichVal !== 'ppbsv' && <>Select Product</>}
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample01"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse" id="collapseExample01">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchProduct}
                            value={searchProducts}
                          />
                        </p>
                        {!isProductPending &&
                          productData.length > 0 &&
                          productData.slice(0, 5).map((el, i) => {
                            return (
                              <div className="p-2 d-md-flex" key={i}>
                                <div className="mr-3 mt-1">
                                  <input
                                    type="radio"
                                    checked={selectedProductIds.includes(
                                      el._id
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange2(el._id)
                                    }
                                  />
                                </div>
                                <div className=" ">
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '14px' }}
                                  >
                                    <strong>{el?.product_name}</strong>
                                  </p>
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '10px' }}
                                  >
                                    {el?.product_spec}
                                  </p>
                                </div>
                              </div>
                              // <div className="row" key={i}>
                              //   <p className="col-6">
                              //     <label htmlFor="milo1">
                              //       <div>
                              //         <input type="checkbox" name="milo1" id="milo1" />
                              //       </div>
                              //       <div>
                              //         <span>{el.product_name}</span>
                              //       </div>
                              //     </label>
                              //   </p>
                              // </div>
                            );
                          })}
                        {!isProductPending && productData.length < 1 && (
                          <p>
                            <i>No Records Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {isProductPending && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`${styles.bordered_sect} col-md-6`}
                style={{
                  borderLeft: '2px solid #ddd',
                }}
              >
                <div className="allprod_wrapper">
                  <div className="modal-header" style={{ border: 'none' }}>
                    <h5 className="modal-title" id="exampleModalLabel">
                      Location/Store
                    </h5>
                  </div>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-5 py-0 my-0">
                        <label htmlFor="country">
                          <input
                            type="checkbox"
                            checked={selectAllStates}
                            onChange={handleSelectAllStates}
                          />
                          &nbsp;&nbsp; Nigeria
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample02"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse pl-1" id="collapseExample02">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchState}
                            value={searchStates}
                          />
                        </p>
                        <div className="row mt-3">
                          {!loadingState &&
                            states?.length > 0 &&
                            states?.slice(0, 14).map((el, i) => {
                              return (
                                <div
                                  className="col-6 py-0 my-0 d-md-flex"
                                  key={i}
                                >
                                  <label htmlFor={`state-${i}`}>
                                    <input
                                      type="checkbox"
                                      id={`state-${i}`}
                                      className="mr-2"
                                      checked={selectedState.includes(el)}
                                      onChange={() => handleStateChange2(el)}
                                    />
                                  </label>
                                  <div>{el}</div>
                                </div>
                              );
                            })}
                        </div>
                        {!loadingState && states.length < 1 && (
                          <p>
                            <i>No Records Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {loadingState && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <button
                className={`${styles.btn_primary} btn w-100 d-block`}
                data-dismiss="modal"
                aria-label="Close"
                type="button"
                onClick={() => handleProceedFilter2()}
              >
                Proceed
              </button>
            </div>
          </div>
        </GlobalModal>

        <GlobalModal
          open={modalOpen3}
          onClose={handleClose3}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="740px !important"
          height="auto !important"
          maxHeight="500px !important"
          overflow="auto"
          bgcolor="#fff"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          p="25px"
          style={{ maxWidth: '500px', overflow: 'auto' }}
        >
          <div className={`${styles.modal_content}`}>
            <div className={`${styles.modal_header} mb-0`}>
              <h5
                style={{
                  fontSize: '19px',
                  fontWeight: '700',
                }}
              >
                {/* Filter */}
              </h5>
              <span
                onClick={handleClose3}
                className="justify-content-end"
                style={{ cursor: 'pointer' }}
              >
                <img src="/assets/icons/x.svg" alt="" />
              </span>
            </div>
            <div className={`${styles.modal_content} row`}>
              <div className="col-md-6">
                <div className="allprod_wrapper mt-m3">
                  <h6
                    className="modal-title mb-2"
                    id="exampleModalLabel"
                    style={{ fontWeight: 'bold' }}
                  >
                    Products/Categories
                  </h6>
                  <div className={`${styles.modal_body} mt-1`}>
                    <div className="row my-0 py-0">
                      <div className="col-7 py-0 my-0">
                        <label htmlFor="allprod">
                          <input
                            type="checkbox"
                            checked={selectAllProductCategory}
                            onChange={handleSelectAllProductCategories}
                          />
                          &nbsp;&nbsp;All Products
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample01"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div className="collapse" id="collapseExample01">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchProduct}
                            value={searchProducts}
                          />
                        </p>
                        {!isProductCatPending &&
                          productCatData?.length > 0 &&
                          productCatData?.slice(0, 5).map((el, i) => {
                            const filteredProducts = el?.products?.filter(
                              (product) =>
                                product?.product_name
                                  ?.toLowerCase()
                                  .includes(searchCategory.toLowerCase())
                            );
                            return (
                              <>
                                <div className="p-2 d-md-flex" key={i}>
                                  <div className="mr-3 mt-1">
                                    <input
                                      type="radio"
                                      checked={selectedProductCatIds.includes(
                                        el?._id
                                      )}
                                      onChange={() =>
                                        handleProductCatCheckBoxChange1(el?._id)
                                      }
                                    />
                                  </div>
                                  <Avatar
                                    className={`${styles.avatrr} mr-3 floadt-md-left`}
                                    alt={el?.category_name}
                                    src={el?.category_image}
                                  />
                                  <div className=" ">
                                    <p
                                      className="mb-0 text-left"
                                      style={{ fontSize: '14px' }}
                                    >
                                      <strong>{el?.category_name}</strong>
                                    </p>
                                    <p
                                      className="mb-0 text-left"
                                      style={{ fontSize: '10px' }}
                                    >
                                      {el?.number_of_product < 2
                                        ? el?.number_of_product + ' product'
                                        : el?.number_of_product + ' products'}
                                    </p>
                                  </div>
                                  <div>
                                    <span
                                      className={`${styles.span}`}
                                      data-toggle="collapse"
                                      data-target={`#collapseExample${el?._id}`}
                                      aria-expanded="false"
                                      aria-controls={`collapseExample${el?._id}`}
                                      onClick={() =>
                                        handleFetchCategoryProducts(el?._id)
                                      }
                                    >
                                      <label>
                                        <input type="checkbox" name="" />
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-chevron-down"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                            />
                                          </svg>
                                        </span>
                                      </label>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="collapse"
                                  id={`collapseExample${el?._id}`}
                                >
                                  <div
                                    className="card card-body pl-5 pb-0"
                                    style={{ border: 'none' }}
                                  >
                                    <p>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        name=""
                                        id=""
                                        onChange={handleSearchCategory}
                                        value={searchCategory}
                                      />
                                    </p>
                                    {!isCategoryPending &&
                                      filteredProducts?.length > 0 &&
                                      filteredProducts
                                        ?.slice(0, 5)
                                        .map((el, i) => {
                                          return (
                                            <div
                                              className="p-2 d-md-flex"
                                              key={i}
                                            >
                                              <div className="mr-3 mt-1">
                                                <input
                                                  type="checkbox"
                                                  checked={selectedCategoryIds.includes(
                                                    el?._id
                                                  )}
                                                  onChange={() =>
                                                    handleCategoryBoxChange(
                                                      el?._id
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className=" ">
                                                <p
                                                  className="mb-0 text-left"
                                                  style={{ fontSize: '14px' }}
                                                >
                                                  <strong>
                                                    {el?.product_name}
                                                  </strong>
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    {!isCategoryPending &&
                                      filteredProducts?.length < 1 && (
                                        <p>
                                          <i>No Record Found...</i>
                                        </p>
                                      )}
                                    <div className="d-flex justify-content-center">
                                      {isCategoryPending && (
                                        <BallTriangle
                                          height={30}
                                          width={35}
                                          radius={5}
                                          color="#af3585"
                                          ariaLabel="ball-triangle-loading"
                                          wrapperClass={{}}
                                          wrapperStyle=""
                                          visible={true}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        {!isProductCatPending && productCatData?.length < 1 && (
                          <p>
                            <i>No Records Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {isProductCatPending && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`${styles.bordered_sect} col-md-6`}
                style={{
                  borderLeft: '2px solid #ddd',
                }}
              >
                <div className="allprod_wrapper">
                  <h6
                    className="modal-title mb-2"
                    id="exampleModalLabel"
                    style={{ fontWeight: 'bold' }}
                  >
                    Location/Store
                  </h6>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-5 py-0 my-0">
                        <label htmlFor="country">
                          <input
                            type="checkbox"
                            checked={selectAllStates}
                            onChange={handleSelectAllStates}
                          />
                          &nbsp;&nbsp; Nigeria
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample02"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse pl-1" id="collapseExample02">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchState}
                            value={searchStates}
                          />
                        </p>
                        <div className="row mt-3">
                          {!loadingState &&
                            states?.length > 0 &&
                            states?.slice(0, 14).map((el, i) => {
                              return (
                                <div
                                  className="col-6 py-0 my-0 d-md-flex"
                                  key={i}
                                >
                                  <label htmlFor={`state-${i}`}>
                                    <input
                                      type="checkbox"
                                      id={`state-${i}`}
                                      className="mr-2"
                                      checked={selectedState.includes(el)}
                                      onChange={() => handleStateChange2(el)}
                                    />
                                  </label>
                                  <div>{el}</div>
                                </div>
                              );
                            })}
                        </div>
                        {!loadingState && states.length < 1 && (
                          <p>
                            <i>No Records Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {loadingState && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <button
                className={`${styles.btn_primary} btn w-100 d-block`}
                data-dismiss="modal"
                aria-label="Close"
                type="button"
                onClick={() => handleProceedFilter3()}
              >
                Proceed
              </button>
            </div>
          </div>
        </GlobalModal>

        <GlobalModal
          open={statFilter1}
          onClose={handleStatFilter1}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="740px !important"
          maxWidth="500px !important"
          overflow="auto"
          bgcolor="#F5F5F5"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          background="#FFFFFF"
          p="25px"
          style={{ maxWidth: '500px', overflow: 'auto' }}
        >
          <div className={`${styles.modal_content}`}>
            <div className={`${styles.modal_header} mb-0`}>
              <span
                className="ml-auto"
                onClick={handleStatFilter1}
                style={{ cursor: 'pointer' }}
              >
                <img src="/assets/icons/x.svg" alt="" />
              </span>
            </div>
            <div className={`${styles.modal_content} row`}>
              <div className="col-md-6">
                <div className="allprod_wrapper mt-m3">
                  <h6
                    className="modal-title mb-2"
                    id="exampleModalLabel"
                    style={{ fontWeight: 'bold' }}
                  >
                    Products/Categories
                  </h6>
                  <div className={`${styles.modal_body} mt-1`}>
                    <div className="row my-0 py-0">
                      <div className="col-7 py-0 my-0">
                        <label htmlFor="allprod">
                          <input
                            type="checkbox"
                            checked={selectAllProductCategory}
                            onChange={handleSelectAllProductCategories}
                          />
                          &nbsp;&nbsp;All Products
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample01"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div className="collapse" id="collapseExample01">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchProduct}
                            value={searchProducts}
                          />
                        </p>
                        {!isProductCatPending &&
                          productCatData?.length > 0 &&
                          productCatData?.slice(0, 5).map((el, i) => {
                            const filteredProducts = el?.products?.filter(
                              (product) =>
                                product?.product_name
                                  ?.toLowerCase()
                                  .includes(searchCategory.toLowerCase())
                            );
                            return (
                              <>
                                <div className="p-2 d-md-flex" key={i}>
                                  <div className="mr-3 mt-1">
                                    <input
                                      type="checkbox"
                                      checked={selectedProductCatIds.includes(
                                        el?._id
                                      )}
                                      onChange={() =>
                                        handleProductCatCheckBoxChange(el?._id)
                                      }
                                    />
                                  </div>
                                  <Avatar
                                    className={`${styles.avatrr} mr-3 floadt-md-left`}
                                    alt={el?.category_name}
                                    src={el?.category_image}
                                  />
                                  <div className=" ">
                                    <p
                                      className="mb-0 text-left"
                                      style={{ fontSize: '14px' }}
                                    >
                                      <strong>{el?.category_name}</strong>
                                    </p>
                                    <p
                                      className="mb-0 text-left"
                                      style={{ fontSize: '10px' }}
                                    >
                                      {el?.number_of_product < 2
                                        ? el?.number_of_product + ' product'
                                        : el?.number_of_product + ' products'}
                                    </p>
                                  </div>
                                  <div>
                                    <span
                                      className={`${styles.span}`}
                                      data-toggle="collapse"
                                      data-target={`#collapseExample${el?._id}`}
                                      aria-expanded="false"
                                      aria-controls={`collapseExample${el?._id}`}
                                      onClick={() =>
                                        handleFetchCategoryProducts(el?._id)
                                      }
                                    >
                                      <label>
                                        <input type="checkbox" name="" />
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-chevron-down"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                            />
                                          </svg>
                                        </span>
                                      </label>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="collapse"
                                  id={`collapseExample${el?._id}`}
                                >
                                  <div
                                    className="card card-body pl-5 pb-0"
                                    style={{ border: 'none' }}
                                  >
                                    <p>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        name=""
                                        id=""
                                        onChange={handleSearchCategory}
                                        value={searchCategory}
                                      />
                                    </p>
                                    {!isCategoryPending &&
                                      filteredProducts?.length > 0 &&
                                      filteredProducts
                                        ?.slice(0, 5)
                                        .map((el, i) => {
                                          return (
                                            <div
                                              className="p-2 d-md-flex"
                                              key={i}
                                            >
                                              <div className="mr-3 mt-1">
                                                <input
                                                  type="checkbox"
                                                  checked={selectedCategoryIds.includes(
                                                    el?._id
                                                  )}
                                                  onChange={() =>
                                                    handleCategoryBoxChange(
                                                      el?._id
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className=" ">
                                                <p
                                                  className="mb-0 text-left"
                                                  style={{ fontSize: '14px' }}
                                                >
                                                  <strong>
                                                    {el?.product_name}
                                                  </strong>
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    {!isCategoryPending &&
                                      filteredProducts?.length < 1 && (
                                        <p>
                                          <i>No Record Found...</i>
                                        </p>
                                      )}
                                    <div className="d-flex justify-content-center">
                                      {isCategoryPending && (
                                        <BallTriangle
                                          height={30}
                                          width={35}
                                          radius={5}
                                          color="#af3585"
                                          ariaLabel="ball-triangle-loading"
                                          wrapperClass={{}}
                                          wrapperStyle=""
                                          visible={true}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        {!isProductCatPending && productCatData?.length < 1 && (
                          <p>
                            <i>No Records Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {isProductCatPending && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`${styles.bordered_sect} col-md-6`}
                style={{
                  borderLeft: '2px solid #ddd',
                }}
              >
                <div className="allprod_wrapper">
                  <h6
                    className="modal-title mb-2"
                    id="exampleModalLabel"
                    style={{ fontWeight: 'bold' }}
                  >
                    Location
                  </h6>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-5 py-0 my-0">
                        <label htmlFor="country">Nigeria</label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample02"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse pl-1" id="collapseExample02">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchState3}
                            value={searchStates3}
                          />
                        </p>
                        <div className="row mt-3">
                          {!loadingState &&
                            states?.length > 0 &&
                            states?.slice(0, 14).map((el, i) => {
                              return (
                                <div
                                  className="col-6 py-0 my-0 d-md-flex"
                                  key={i}
                                >
                                  <label htmlFor={`state-${i}`}>
                                    <input
                                      type="radio"
                                      id={`state-${i}`}
                                      className="mr-2"
                                      checked={selectedState3.includes(el)}
                                      onChange={() => handleStateChange3(el)}
                                    />
                                  </label>
                                  <div>{el}</div>
                                </div>
                              );
                            })}
                        </div>
                        {!loadingState && states.length < 1 && (
                          <p>
                            <i>No Records Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {loadingState && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>

                        <div className="collapse pl-1" id="collapseExample03">
                          <div
                            className="card card-body pl-5 pb-0"
                            style={{ border: 'none' }}
                          >
                            <p>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                name=""
                                id=""
                              />
                            </p>
                            <p>
                              <label htmlFor="milo2">
                                <input
                                  type="checkbox"
                                  name="milo2"
                                  id="milo2"
                                />
                                &nbsp;&nbsp;<span>Mama tee superstore</span>
                              </label>
                            </p>
                            <p>
                              <label htmlFor="water2">
                                <input
                                  type="checkbox"
                                  name="water2"
                                  id="water2"
                                />
                                &nbsp;&nbsp;<span>Shoprite</span>
                              </label>
                            </p>
                            <p>
                              <label htmlFor="nescafe2">
                                <input
                                  type="checkbox"
                                  name="nescafe2"
                                  id="nescafe2"
                                />
                                &nbsp;&nbsp;<span>Justrite</span>
                              </label>
                            </p>
                            <p>
                              <label htmlFor="kitkat2">
                                <input
                                  type="checkbox"
                                  name="kitkat2"
                                  id="kitkat2"
                                />
                                &nbsp;&nbsp;<span>Mallmart</span>
                              </label>
                            </p>
                            <p>
                              <label htmlFor="maggi2">
                                <input
                                  type="checkbox"
                                  name="maggi2"
                                  id="maggi2"
                                />
                                &nbsp;&nbsp;<span>Jendol</span>
                              </label>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <button
                className={`${styles.btn_primary} btn w-100 d-block`}
                data-dismiss="modal"
                aria-label="Close"
                type="button"
                onClick={() => handleProceedProductSalesChartFilter()}
              >
                Proceed
              </button>
            </div>
          </div>
        </GlobalModal>

        <GlobalModal
          open={statFilter01}
          onClose={handleStatFilter01}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="740px !important"
          maxWidth="500px !important"
          overflow="auto"
          bgcolor="#F5F5F5"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          background="#FFFFFF"
          p="25px"
          style={{ maxWidth: '500px', overflow: 'auto' }}
        >
          <div className={`${styles.modal_content}`}>
            <div className={`${styles.modal_header} mb-0`}>
              <span
                className="ml-auto"
                onClick={handleStatFilter01}
                style={{ cursor: 'pointer' }}
              >
                <img src="/assets/icons/x.svg" alt="" />
              </span>
            </div>
            <div className={`${styles.modal_content} row`}>
              <div className="col-md-6">
                <div className="allprod_wrapper mt-m3">
                  <h6
                    className="modal-title mb-2"
                    id="exampleModalLabel"
                    style={{ fontWeight: 'bold' }}
                  >
                    Categories
                  </h6>
                  <p className="ml-md-3">Select Categories</p>
                  <div className={`${styles.modal_body} mt-1`}>
                    <div
                      className="card card-body pl-3 pb-0"
                      style={{ border: 'none' }}
                    >
                      <p>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          name=""
                          id=""
                          onChange={handleSearchProduct}
                          value={searchProducts}
                        />
                      </p>
                      {!isProductCatPending &&
                        productCatData?.length > 0 &&
                        productCatData?.slice(0, 5).map((el, i) => {
                          const filteredProducts = el?.products?.filter(
                            (product) =>
                              product?.product_name
                                ?.toLowerCase()
                                .includes(searchCategory.toLowerCase())
                          );
                          return (
                            <>
                              <div className="p-2 pl-3 d-md-flex" key={i}>
                                <div className="mr-3 mt-1">
                                  <input
                                    type="checkbox"
                                    checked={selectedProductCatIds.includes(
                                      el?._id
                                    )}
                                    onChange={() =>
                                      handleProductCatCheckBoxChange(el?._id)
                                    }
                                  />
                                </div>
                                <Avatar
                                  className={`${styles.avatrr} mr-3 floadt-md-left`}
                                  alt={el?.category_name}
                                  src={el?.category_image}
                                />
                                <div className=" ">
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '14px' }}
                                  >
                                    <strong>{el?.category_name}</strong>
                                  </p>
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '10px' }}
                                  >
                                    {el?.number_of_product < 2
                                      ? el?.number_of_product + ' product'
                                      : el?.number_of_product + ' products'}
                                  </p>
                                </div>
                                <div></div>
                              </div>
                            </>
                          );
                        })}
                      {!isProductCatPending && productCatData?.length < 1 && (
                        <p>
                          <i>No Records Found...</i>
                        </p>
                      )}
                      <div className="d-flex justify-content-center">
                        {isProductCatPending && (
                          <BallTriangle
                            height={50}
                            width={55}
                            radius={5}
                            color="#af3585"
                            ariaLabel="ball-triangle-loading"
                            wrapperClass={{}}
                            wrapperStyle=""
                            visible={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`${styles.bordered_sect} col-md-6`}
                style={{
                  borderLeft: '2px solid #ddd',
                }}
              >
                <div className="allprod_wrapper">
                  <h6
                    className="modal-title mb-2"
                    id="exampleModalLabel"
                    style={{ fontWeight: 'bold' }}
                  >
                    Location
                  </h6>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-5 py-0 my-0">
                        <label htmlFor="country">Nigeria</label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample02"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse pl-1" id="collapseExample02">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchState3}
                            value={searchStates3}
                          />
                        </p>
                        <div className="row mt-3">
                          {!loadingState &&
                            states?.length > 0 &&
                            states?.slice(0, 14).map((el, i) => {
                              return (
                                <div
                                  className="col-6 py-0 my-0 d-md-flex"
                                  key={i}
                                >
                                  <label htmlFor={`state-${i}`}>
                                    <input
                                      type="radio"
                                      id={`state-${i}`}
                                      className="mr-2"
                                      checked={selectedState3.includes(el)}
                                      onChange={() => handleStateChange3(el)}
                                    />
                                  </label>
                                  <div>{el}</div>
                                </div>
                              );
                            })}
                        </div>
                        {!loadingState && states.length < 1 && (
                          <p>
                            <i>No Records Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {loadingState && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <button
                className={`${styles.btn_primary} btn w-100 d-block`}
                data-dismiss="modal"
                aria-label="Close"
                type="button"
                onClick={() => handleProceedOBLFilter()}
              >
                Proceed
              </button>
            </div>
          </div>
        </GlobalModal>
      </div>
    </>
  );
}
