import { useState, useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import TextField from "@mui/material/TextField";
import bus from "src/bus"

export default function LiveLocation({ label, passData }) {
  let [location, setLocation] = useState({ latitude: "", longitude: "" });
  const [business_address, setAddr] = useState("");

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const { lat, lng } = await getLatLng(results[0]);
    setLocation({
      latitude: lat,
      longitude: lng,
    });
    setAddr(value);
    passData({ lat, lng, formatted_address: results[0].formatted_address });
  };

  useEffect(() => {
    bus.on('location', () => {
      setAddr("");
    })
  }, [])

  return (
    <PlacesAutocomplete
      value={business_address}
      onChange={setAddr}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <div className="">
            <label htmlFor="loc">{label}</label>
            <TextField
              id="loc"
              placeholder="Input your location"
              style={{ background: 'white' }}

              size="small"
              fullWidth
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input",
              })}
            />
          </div>

          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, i) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  key={i}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
