

// ----------------------------------------------------------------------
import styles from "./BrandOwner.module.css"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {
  Card,
  CardContent,
} from '@mui/material';
// sections
// mock
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor:'#FFFF',
  border: '1px solid #F5F5F5',
  borderRadius:'5px',
  boxShadow: 24,
  p: 3,
  overflowX:'hidden'
};

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  overflow: 'auto',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};
export default function DashboardAppPage() {

  let navigate = useNavigate();

  const [monthlySubscription, setMonthlySubscription] = useState(false);
  const handleCloseMonthlySubscription = () => {
    setMonthlySubscription(false);
  };

  const [yearlySubscription, setYearlySubscription] = useState(false);
  const handleCloseYearlySubscription = () => {
    setYearlySubscription(false);
  };

  const [modal_desc, setmodal_desc] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [modalTitle, setmodalTitle] = useState('');
  const [otp, setOtp] = useState(false);
  const submitOtpWallet = (e) => {
      e.preventDefault()
      setOtp(false);
      setmodalTitle('Subscription successful!')
      setOpenDialog(true)
  }


  
 
  
  
  return (
    <>
     <div className={`wrapper mt-md-0 mt-4 pt-2 px-2`}>
      <div className="text-center">
        <p style={{fontSize:'13px', color:'#b051bc'}}>
          Pricing 
        </p>
       <h1>Plans and Pricing</h1>

        <div className={`${styles.content} container col-md-10 mx-auto mt-2`}>
          <p>
            We provide real-time data on the market performance of your competitors. Get <br /> access to these insights with a subscription and use them to grow your business.
          </p>
        </div>

      </div>
      <div className="container col-md-10 mx-auto">
        <Card style={{marginTop:'50px',borderRadius:'10px'}} className='p-0'>
          <CardContent className={`text-center ${styles.subscription}`}>
            <h1>
            ₦10,000 Monthly
            </h1>
            <h5>
            Incremental Insight subscription
            </h5>
            <p>
            Pay monthly and unlock all our features with this plan
            </p>

            <div className={`${styles.subscribe_button_container}`}>
              <button 
                onClick={() => setMonthlySubscription(true)}
              >
                Subscribe Now
              </button>
            </div>
          


          </CardContent>
          
          
          
        </Card>
        <Card style={{marginTop:'50px',borderRadius:'10px'}} className='p-0'>
          <CardContent className={`text-center ${styles.subscription}`}>
            <p className="text-center badge badge-pill badge-light"
            style={{fontSize:'12px'}}>
              Save 20%
            </p>
            <h1>
            ₦100,000 Yearly
            </h1>
            <h5>
            Incremental Insight subscription
            </h5>
            <p>
            Pay yearly and enjoy a 20% discount with this plan
            </p>

            <div className={`${styles.subscribe_button_container}`}>
              <button 
                // onClick={ () => navigate('/app/incremental_insight/more')}
                onClick={() => setYearlySubscription(true)}
              >
                Subscribe Now
              </button>
            </div>
          


          </CardContent>
          
          
          
        </Card>

      </div>
       
       

     </div>

     {/* View Monthly Subscription Modal Modal */}
     <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={monthlySubscription}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={monthlySubscription}>
          <Box sx={style} className={`mobile_modal_size`}>
            <div className={`modal_content`}>
              <div className={`modal_header`}>
                <h5 style={{
                  fontWeight: 'bold',
                  fontSize: '18px'
                }}>Subscription Info</h5>
                <span onClick={handleCloseMonthlySubscription} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />

                </span>

              </div>
              
                {/* Put something here */}
                <div className="row text-center">
                  <div className="col-6 mb-4">
                    Amount:
                  </div>
                  <div className="col-6 mb-4">
                    ₦ 10,000
                  </div>
                  <div className="col-6">
                    Duration:
                  </div>
                  <div className="col-6">
                    Monthly
                  </div>
                </div>
                <div className={`${styles.subscribe_button_container} mt-4 mb-3`}>
                  <button  onClick={submitOtpWallet}>
                    Confirm Subscription
                  </button>
                </div>


            </div>

          </Box>
        </Fade>
      </Modal>
      {/* View Yearly Subscription Modal Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={yearlySubscription}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={yearlySubscription}>
          <Box sx={style} className={`mobile_modal_size`}>
            <div className={`modal_content`}>
              <div className={`modal_header`}>
                <h5 style={{
                  fontWeight: 'bold',
                  fontSize: '18px'
                }}>Subscription Info</h5>
                <span onClick={handleCloseYearlySubscription} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />

                </span>

              </div>
              
                {/* Put something here */}
                <div className="row text-center">
                  <div className="col-6 mb-4">
                    Amount:
                  </div>
                  <div className="col-6 mb-4">
                    ₦ 100,000
                  </div>
                  <div className="col-6">
                    Duration:
                  </div>
                  <div className="col-6">
                    Yearly
                  </div>
                </div>
                <div className={`${styles.subscribe_button_container} mt-4 mb-3`}>
                  <button onClick={submitOtpWallet}>
                    Confirm Subscription
                  </button>
                </div>

            </div>

          </Box>
        </Fade>
      </Modal>

      {/* Success Dialogue */}
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
          timeout: 500,
          }}
          disableEnforceFocus
      >
          <Fade in={openDialog}>
          <Box className={`mobile_modal_size modal_bg`} sx={style1}>
              <div className={`text-center add_to_cart_content`}>
              <img src="/assets/icons/circleCheck.svg" alt="" />
              <h5 className="mt-3 mb-2" style={{ fontWeight: 'bold' }}>{modalTitle}</h5>
              <p className='text-center' style={{ textAlign: 'center !important' }}>
                  {modal_desc}

              </p>
              <button onClick={() => navigate('/app/incremental_insight/more')} className={`modal_btn hover btn btn-block`}>
                  View Insight
              </button>
              </div>
          </Box>
          </Fade>
      </Modal>
     
    </>
  );
}
