import styles from './Outlet.module.css';

import { Card, Typography } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import GlobalModal from 'src/Modals/GlobalModal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { forwardRef, useEffect } from 'react';
import { MutatingDots, BallTriangle } from 'react-loader-spinner';
import { useAuth } from 'src/hooks/useAuth';
import { downloadFile2 } from 'src/utils';
import { Pagination } from 'src/components';

// sections
// mock
import { useState } from 'react';
import { TimeAgo } from 'src/components';
import { ActionName } from 'src/components';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Select from '@mui/material/Select';
import bus from 'src/bus';
import CircularProgress from '@mui/material/CircularProgress';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import axios from 'axios';
import moment from 'moment';
// import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const TABLE_HEAD = [
  { id: 'customerName', label: 'Customer Name', alignRight: false },
  // { id: 'category', label: 'Category', alignRight: false },
  { id: 'outletManager', label: 'Outlet Manager', alignRight: false },
  { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
  { id: 'outletLocation', label: 'Outlet Location', alignRight: false },
];

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 580,
  overflow: 'auto',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};
export default function OrderPage() {
  const [open, setOpen] = useState(false);
  const [viewAction, setViewAction] = useState(false);
  const [actionValue, setActionValue] = useState({});
  let [shouldFetch, setShouldFetch] = useState(0);
  const [outletData, setdata] = useState([]);
  const [outletPagination, setOutletPagination] = useState({ total: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { data } = useAuth();
  const [locationData, setLocationData] = useState({});
  const [text, setText] = useState({
    title: 'Add Outlet',
    sub: 'Add information about this outlet',
  });
  const [isPending, setIsPending] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const notify = () => toast.success('Outlet saved .');
  const notifyError = (err) => toast.error(err);
  const [search, setSearch] = useState({
    search: '',
    category: '',
    location: '',
    role: '',
    action: '',
    startDate: '',
    endDate: '',
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [endpoint, changeEndPoint] = useState('/outlet/add-outlet');
  const [fetchPageSize, setPageSize] = useState(10);
  const [outlets, setOutLets] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    outlet_name: '',
    outlet_email: '',
    outlet_manager: '',
    phone_number: '',
    outlet_location: '',
    outlet_category: 1,
    outlet_country: 10,
    outlet_state: 10,
  });

  const auth = JSON.parse(localStorage.getItem('auth')) || {};

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((formValues) => ({
      ...formValues,
      [name]: value,
    }));
  };

  const handleOpen = (data = null) => {
    if (data !== null) {
      setText({
        title: 'Edit Outlet',
        sub: 'Edit information about this outlet',
      });
      changeEndPoint(`/outlet/update-outlet/${data._id}`);
      if (!data.hasOwnProperty('outlet_category')) {
        data.outlet_category = 1;
        data.outlet_country = 10;
        data.outlet_state = 10;
      }
      setFormValues(data);
      setOpen(true);
    } else {
      changeEndPoint('/outlet/add-outlet');
      setText({
        title: 'Add Outlet',
        sub: 'Add information about this outlet',
      });
      setOpen(true);
    }
  };
  const [showDiv, setShowDiv] = useState(data.merchant_type === 'brand');

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const [category, setCategory] = useState(1);
  const handleClose = () => setModalOpen(false);
  const handleCloseFilterModal = () => {
    setModalOpen(false);
    setSearch({ search: '', category: '', location: '', role: '', action: '' });
    setStartDate(null);
    setEndDate(null);
    fetchData();
  };

  const handleClose2 = () => {
    setModalOpen(false);
  };

  const [openDialog, setDiologOpen] = useState(false);

  const handleClickOpen = (data) => {
    setOutLets(data);
    changeEndPoint('/outlet/delete-outlet/' + data._id);
    setDiologOpen(true);
  };

  const handleDialogClose = () => {
    setDiologOpen(false);
  };

  useEffect(() => {
    bus.on('open-outlet', () => {
      changeEndPoint('/outlet/add-outlet');
      setOpen(true);
    });

    return () => {
      bus.off('open-outlet', () => {
        changeEndPoint('/outlet/add-outlet');
      });
    };
  }, []);

  const increasePageSize = (e) => {
    setPageSize(fetchPageSize + 1);
  };

  const decreasePageSize = (e) => {
    if (fetchPageSize > 0) setPageSize(fetchPageSize - 1);
  };

  const handleDownloadFile = async (extension) => {
    setExportLoading(true);
    try {
      const result = `/admin/audit?page=${outletPagination.currentPage}&perpage=${outletPagination.total}&export_type=${extension}`;
      await downloadFile2('Audit', result, extension);
    } catch (err) {
      notifyError(err.message);
    } finally {
      setExportLoading(false);
    }
  };

  const fetchData = (page = 1, perpage = fetchPageSize) => {
    setIsPending(true);
    let filter_payload = {};
    if (search.role !== '') {
      filter_payload.role = search.role;
    }
    if (search.action !== '') {
      filter_payload.action = search.action;
    }
    if (startDate !== '') {
      filter_payload.dateStart = startDate;
    }

    if (endDate !== '') {
      filter_payload.dateEnd = endDate;
    }

    // To get the right endpoint  to call o the audit page
    const baseUrlEndpoint =
      auth?.merchant_type === 'shop' ? '/shop/audit' : '/admin/audit';

    if (baseUrlEndpoint === null) return;

    axiosInstance
      .get(`${baseUrlEndpoint}`, {
        params: {
          search: search.search,
          page,
          perpage,
          ...filter_payload,
        },
      })
      .then((res) => {
        setdata(res.data.data.data);
        setOutletPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch outlet, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Do something with the form values
    setIsPending(true);
    axiosInstance[formValues.hasOwnProperty('_id') ? 'put' : 'post'](
      endpoint,
      formValues
    )
      .then((res) => {
        if (res.status === 201) {
          notify(res.data.message);
          fetchData(currentPage, itemsPerPage);
          setFormValues({
            outlet_name: '',
            outlet_email: '',
            outlet_manager: '',
            phone_number: '',
            outlet_location: '',
            outlet_category: 1,
            outlet_country: 10,
            outlet_state: 10,
          });
          handleClose();
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to save outlet, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const handleDelete = () => {
    setIsDeleting(true);
    axiosInstance
      .delete(endpoint)
      .then((res) => {
        if (res.status < 300) {
          notify(res.data.message);
          fetchData(currentPage, itemsPerPage);

          handleClose();
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to save outlet, try again later');
        }
      })
      .finally(() => {
        setIsDeleting(false);
        handleDialogClose();
      });
  };

  const processFilter = (e) => {
    const { name, value } = e.target;
    setSearch(() => ({
      ...search,
      [name]: value,
    }));
  };

  const fetchLocationData = async (ipAddress) => {
    try {
      const response = await fetch(`https://ipapi.co/${ipAddress}/json/`);
      const data = await response.json();
      var result = '';
      if (data.reserved) {
        result = data.reason;
      } else {
        result = `${data.city},${data.region}`;
      }
      return result || '---';
    } catch (error) {
      console.error('Error fetching location data:', error);
      return '---';
    }
  };

  const [locationIp, setLocationIp] = useState([
    '95.159.105.140',
    '94.227.63.178',
    '131.99.210.96',
    '184.147.100.164',
    '230.238.55.28',
    '64.15.146.67',
    '99.36.20.104',
    '235.245.31.120',
    '237.240.101.203',
    '21.78.51.215',
  ]);

  useEffect(() => {
    // Fetch location data for all outlet IP addresses
    const fetchLocationDataForOutlets = async () => {
      const locationPromises = outletData.map((el) =>
        fetchLocationData(el.ip_address)
      );
      const locationResults = await Promise.all(locationPromises);
      const locationMap = {};
      outletData.forEach((el, index) => {
        locationMap[el.ip_address] = locationResults[index];
      });
      setLocationData(locationMap);
      setIsPending(false);
    };

    fetchLocationDataForOutlets();
  }, [outletData]);

  useEffect(() => {
    console.log(locationData);
  }, [locationData]);

  useEffect(() => {
    fetchData();
  }, [shouldFetch, search.search, fetchPageSize]);

  return (
    <>
      <div className="wrapper pt-2 px-2">
        <div className="header d-block d-md-none mb-4">
          <div className="d-md-flex justify-content-between">
            <div className="mb-3">
              <h4 className={`mb-1 mb-md-0 pb-0`}>Audit</h4>
              <p style={{ fontSize: '14px' }} className={`mb-0 pb-0`}>
                Monitior activities of all admin members
              </p>
            </div>
          </div>
        </div>
        <div className={`${styles.filters} mt-4`}>
          <div className="d-flex justify-content-between px-0 ">
            <div className={`${styles.hold_input} col-md-4 col-12 px-0  `}>
              <img src="/assets/icons/search.svg" className="pr-4" alt="" />
              <input
                type="text"
                placeholder="Search"
                name="search"
                value={search.search}
                onChange={processFilter}
                style={{
                  textIndent: '25px',
                  width: '100%',
                }}
                className=" form-control"
              />
            </div>
            <div className="col-7 text-right">
              <div className="dropleft ">
                <button
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className={`${styles.export_btn} btn m-0`}
                >
                  <span
                    style={{
                      paddingTop: '8px',
                    }}
                  >
                    <img src="/assets/icons/downloadblue.svg" alt="" />
                    &nbsp; Export{' '}
                    {exportLoading && (
                      <CircularProgress
                        style={{ color: 'white' }}
                        size="1rem"
                      />
                    )}
                  </span>
                  &nbsp; &nbsp;
                  <span
                    style={{
                      borderLeft: '1px solid white',
                      display: 'inline-block',
                      height: 'inherit',
                      paddingTop: '8px',
                      paddingLeft: '8px',
                      textAlign: 'center',
                    }}
                  >
                    <img src="/assets/icons/chevron-downblue.svg" alt="" />
                  </span>
                </button>
                <div
                  className="dropdown-menu drop-left"
                  aria-labelledby="dropdownMenuButton"
                >
                  <button
                    type="button"
                    className="rss dropdown-item btn border-0"
                    onClick={() => handleDownloadFile('csv')}
                  >
                    CSV
                  </button>
                  <button
                    type="button"
                    className="rss dropdown-item btn border-0"
                    onClick={() => handleDownloadFile('excel')}
                  >
                    Excel
                  </button>
                  <button
                    type="button"
                    className="rss dropdown-item btn border-0"
                    onClick={() => handleDownloadFile('pdf')}
                  >
                    PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Scrollbar>
          <div className={`card p-0  m-0 mt-5 ${styles.card_table}`}>
            <div className="head_wrapper" style={{ fontSize: '14px' }}>
              <div className={`row px-4 px-md-5 py-3 ${styles.show_control}`}>
                <div className="col-4 p-0 py-1 py-md-0">
                  <div className={`${styles.show_numbers}`}>
                    <span className="font-weight-bold text-dark">Show </span>
                    <div className={`${styles.number_cont} d-flex py-1 px-3`}>
                      <span className="d-inline-block">{fetchPageSize}</span>
                      <span className={`d-inline-block ${styles.svg_div}`}>
                        <span onClick={increasePageSize}>
                          <svg
                            className="d-block mx-auto mt-1 mb-1"
                            width="8"
                            height="8"
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.58334 6.09039V0.743164M3.58334 0.743164L0.909729 3.41677M3.58334 0.743164L6.25695 3.41677"
                              stroke="black"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span onClick={decreasePageSize}>
                          <svg
                            className="d-block mx-auto mb-1 mt-1"
                            width="8"
                            height="8"
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.58334 0.743164V6.09039M3.58334 6.09039L6.25695 3.41677M3.58334 6.09039L0.909729 3.41677"
                              stroke="black"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-8 p-0 py-1 py-md-0">
                  <div
                    className="d-flex ml-auto"
                    style={{
                      width: ' fit-content',
                      justifyContent: 'space-between',
                      gap: '10px',
                    }}
                  >
                    <div className="col-md-12 col-6  text-right">
                      <button
                        className="btn  border"
                        onClick={() => setModalOpen(true)}
                        style={{
                          backgroundColor: '#fff',
                          border: '1px solid #D0D5DD',
                          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                          borderRadius: '4px',
                        }}
                      >
                        <img
                          className=""
                          style={{
                            display: 'inline',
                            width: '28%',
                          }}
                          src="/assets/icons/filterlines.svg"
                          alt=""
                        />
                        &nbsp;
                        <small className="d-none d-md-inline-block">
                          Filters
                        </small>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.table_section} border-bottom`}>
              <table
                className="table px-0 table-striped table-hover"
                style={{
                  fontSize: '12.76px',
                }}
              >
                <thead>
                  <tr>
                    <th scope="col">S/N</th>
                    <th scope="col">Email</th>
                    <th scope="col">Role</th>
                    <th scope="col">Login Time</th>
                    <th scope="col">Log out Time</th>
                    <th scope="col">Action</th>
                    <th scope="col">IP Address</th>
                  </tr>
                </thead>
                <tbody className="text-dark">
                  {!isPending &&
                    outletData.length > 0 &&
                    outletData.map((el, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <th style={{ fontWeight: 'bold' }}>
                          <ActionName actionValue={el?.name} />
                        </th>
                        <td>{el?.role || '---'}</td>
                        <td>
                          {el?.login_time &&
                            `${moment(el?.login_time).format(
                              'MMM, DD, YYYY'
                            )} ${moment(el?.login_time).format('hh:mm A')}`}
                          {!el?.login_time && '---'}
                        </td>
                        <td>
                          {el?.log_out_time &&
                            `${moment(el?.log_out_time).format(
                              'MMM, DD, YYYY'
                            )} ${moment(el?.log_out_time).format('hh:mm A')}`}
                          {!el?.log_out_time && '---'}
                        </td>
                        <td
                          className="text-primary"
                          style={{
                            textDecoration: 'underline',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setViewAction(true);
                            setActionValue(el);
                          }}
                        >
                          View Actions
                        </td>
                        <td>{el?.ip_address}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!outletData.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
            <div>
              <Pagination
                currentPage={currentPage}
                dataPagination={outletPagination}
                itemsPerPage={itemsPerPage}
                setCurrentPage={setCurrentPage}
                fetchData={fetchData}
              />
            </div>
          </div>
        </Scrollbar>

        {/* Modals */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={open}>
            <Box sx={style} className={`mobile_modal_size`}>
              <div className={`${styles.modal_content}`}>
                <div className={`${styles.modal_header} mb-0`}>
                  <div>
                    <h5
                      style={{
                        fontSize: '19px',
                        fontWeight: '700',
                      }}
                    >
                      {text.title}
                    </h5>
                    <p
                      style={{
                        fontSize: '11px',
                        color: '#71717A',
                      }}
                    >
                      {text.sub}
                    </p>
                  </div>
                  <div>
                    <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                      <img src="/assets/icons/x.svg" alt="" />
                    </span>
                  </div>
                </div>

                <form onSubmit={handleSubmit} className="mt-0">
                  <div className="mb-3">
                    <label htmlFor="name">Outlet Name</label>
                    <TextField
                      required
                      type="text"
                      value={formValues.outlet_name}
                      name="outlet_name"
                      onChange={handleChange}
                      id="name"
                      placeholder="Oulet 1"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name">Select Category</label>
                    <Select
                      labelId="s"
                      id="s"
                      fullWidth
                      size="small"
                      value={formValues.outlet_category}
                      name="outlet_category"
                      required
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>Kiosk</MenuItem>
                    </Select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="mname">Outlet Manager Name</label>
                    <TextField
                      required
                      value={formValues.outlet_manager}
                      name="outlet_manager"
                      onChange={handleChange}
                      type="text"
                      id="mname"
                      placeholder="Irina Shark"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email">Email</label>
                    <TextField
                      required
                      type="email"
                      value={formValues.outlet_email}
                      name="outlet_email"
                      onChange={handleChange}
                      id="email"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone">Phone Number</label>
                    <TextField
                      required
                      type="phone"
                      value={formValues.phone_number}
                      name="phone_number"
                      onChange={handleChange}
                      id="phone"
                      placeholder="+2349088772"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="businessDesc">Adddress </label>
                    <TextField
                      required
                      id="businessDesc"
                      placeholder="Outlet address"
                      size="small"
                      value={formValues.outlet_location}
                      name="outlet_location"
                      onChange={handleChange}
                      multiline
                      rows="2"
                      cols="2"
                      fullWidth
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-1">
                      <label htmlFor="s">Country*</label>

                      <Select
                        labelId="s"
                        id="s"
                        fullWidth
                        size="small"
                        required
                        value={formValues.outlet_country}
                        name="outlet_country"
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Nigeria</MenuItem>
                        <MenuItem value={20}>South Africa</MenuItem>
                      </Select>
                    </div>
                    <div className="col-md-6 mb-1">
                      <label htmlFor="state">State*</label>

                      <Select
                        id="state"
                        fullWidth
                        size="small"
                        required
                        value={formValues.outlet_state}
                        name="outlet_state"
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Lagos</MenuItem>
                        <MenuItem value={20}>Abuja</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div className="mb-1 mt-4">
                    {!isPending && (
                      <button
                        type="submit"
                        className={`${styles.modal_btn} btn btn-block`}
                      >
                        Save
                      </button>
                    )}
                    {isPending && (
                      <button
                        disabled
                        className={`btn btn-block ${styles.modal_btn}`}
                      >
                        Submitting &nbsp;
                        {isPending && (
                          <CircularProgress
                            style={{ color: 'white' }}
                            size="1rem"
                          />
                        )}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </Box>
          </Fade>
        </Modal>

        <GlobalModal
          open={modalOpen}
          onClose={handleCloseFilterModal}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="740px"
          maxWidth="95% !important"
          overflow="auto"
          bgcolor="#FFFFFF"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          p="25px"
        >
          <div className={`${styles.modal_content}`}>
            <div className={`${styles.modal_header} mb-0`}>
              <h5
                style={{
                  fontSize: '19px',
                  fontWeight: '700',
                }}
              >
                Filter
              </h5>
              <span
                onClick={handleCloseFilterModal}
                style={{ cursor: 'pointer' }}
              >
                <img src="/assets/icons/x.svg" alt="" />
              </span>
            </div>

            <form className="mt-0 w-100">
              <div className="row">
                <div className="col-md-6 mb-1">
                  <div className="mb-3">
                    <label className="font-weight-bold" htmlFor="name">
                      Select Role
                    </label>
                    <Select
                      labelId="s"
                      id="s"
                      fullWidth
                      size="small"
                      value={search.role}
                      name="role"
                      required
                      onChange={processFilter}
                    >
                      <MenuItem value="superadmin">Super Admin</MenuItem>
                      <MenuItem value="operations">Operations</MenuItem>
                      <MenuItem value="marketing">Marketing</MenuItem>
                      <MenuItem value="custom">Custom</MenuItem>
                    </Select>
                  </div>

                  {/* <div className=' pb-2'>
                    <div className='d-flex flex-wrap'>
                        <span className={`${styles.acc_details_span} my-1 mr-2`} >
                            <input id='super' type='checkbox' className={`${styles.acc_details_box}`} />
                            <label for='super' className='font-weight-normal m-0' style={{fontSize: '12px'}}>Super Admin</label>
                        </span>
                        <span className={`${styles.acc_details_span} my-1 mr-2`} >
                            <input id='operations' type='checkbox' className={`${styles.acc_details_box}`} />
                            <label for='operations' className='font-weight-normal m-0' style={{fontSize: '12px'}}>Operations</label>
                        </span>
                        <span className={`${styles.acc_details_span} my-1 mr-2`} >
                            <input id='marketing' type='checkbox' className={`${styles.acc_details_box}`} />
                            <label for='marketing' className='font-weight-normal m-0' style={{fontSize: '12px'}}>Marketing</label>
                        </span>
                        <span className={`${styles.acc_details_span} my-1 mr-2`} >
                            <input id='custom' type='checkbox' className={`${styles.acc_details_box}`} />
                            <label for='custom' className='font-weight-normal m-0' style={{fontSize: '12px'}}>Custom</label>
                        </span>
                        <span className={`${styles.acc_details_span} my-1 mr-2`} >
                            <input id='admin' type='checkbox' className={`${styles.acc_details_box}`} />
                            <label for='admin' className='font-weight-normal m-0' style={{fontSize: '12px'}}>Admin</label>
                        </span>
                    </div>
                </div> */}

                  <div className="mb-3">
                    <label htmlFor="name" className="font-weight-bold">
                      Select Action
                    </label>
                    <Select
                      labelId="s"
                      id="s"
                      fullWidth
                      size="small"
                      value={search.action}
                      name="action"
                      required
                      onChange={processFilter}
                    >
                      <MenuItem value="select">Select</MenuItem>
                      <MenuItem value="action1">Action 1</MenuItem>
                      <MenuItem value="action2">Action 2</MenuItem>
                      {/* <MenuItem value='Supermarket'>Supermarket</MenuItem>
                    <MenuItem value='E-commerce store'>E-commerce store</MenuItem> */}
                    </Select>
                  </div>

                  {/* <div className=' pb-2'>
                    <div className='d-flex flex-wrap'>
                        <span className={`${styles.acc_details_span} my-1 mr-2`} >
                            <input id='super' type='checkbox' className={`${styles.acc_details_box}`} />
                            <label for='super' className='font-weight-normal m-0' style={{fontSize: '12px'}}>Access</label>
                        </span>
                        <span className={`${styles.acc_details_span} my-1 mr-2`} >
                            <input id='operations' type='checkbox' className={`${styles.acc_details_box}`} />
                            <label for='operations' className='font-weight-normal m-0' style={{fontSize: '12px'}}>Download</label>
                        </span>
                        <span className={`${styles.acc_details_span} my-1 mr-2`} >
                            <input id='marketing' type='checkbox' className={`${styles.acc_details_box}`} />
                            <label for='marketing' className='font-weight-normal m-0' style={{fontSize: '12px'}}>Withdraw</label>
                        </span>
                    </div>
                </div> */}
                </div>
                <div className="col-md-6 mb-1">
                  <label htmlFor="price">Date </label>
                  <div className=" d-flex">
                    <div className="" style={{ width: '100%' }}>
                      <div className="mb-3">
                        <DatePicker
                          showIcon
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          placeholderText="Start"
                          required
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div
                      className="px-1 d-flex align-items-center "
                      style={{ height: '40px' }}
                    >
                      <img
                        src="/assets/icons/line.svg"
                        style={{ width: '30px' }}
                        alt=""
                      />
                    </div>
                    <div className="" style={{ width: '100%' }}>
                      <div className="mb-3">
                        <DatePicker
                          showIcon
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          placeholderText="End"
                          required
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                  </div>
                  {showDiv && (
                    <div>
                      <label htmlFor="ora">Total amount </label>
                      <div className=" d-flex">
                        <div className="" style={{ width: '100%' }}>
                          <div className="mb-3">
                            <TextField
                              required
                              type="number"
                              id="price"
                              placeholder="Min"
                              size="small"
                              name="priceFilter"
                            />
                          </div>
                        </div>
                        <div
                          className="px-1 d-flex align-items-center "
                          style={{ height: '40px' }}
                        >
                          <img
                            src="/assets/icons/line.svg"
                            style={{ width: '30px' }}
                            alt=""
                          />
                        </div>
                        <div className="" style={{ width: '100%' }}>
                          <div className="mb-3">
                            <TextField
                              required
                              type="number"
                              placeholder="Max "
                              size="small"
                              name="priceToFilter"
                            />
                          </div>
                        </div>
                      </div>
                      <label htmlFor="name">Date </label>
                      <div className=" d-flex">
                        <div className="" style={{ width: '70%' }}>
                          <div className="mb-3">
                            <DatePicker
                              showIcon
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                              placeholderText="Start"
                              required
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                        <div
                          className="px-1 d-sflex align-items-center "
                          style={{ heigsht: '40px' }}
                        >
                          {/* sss */}
                          <img
                            src="/assets/icons/line.svg"
                            style={{ width: '30px' }}
                            alt=""
                          />
                        </div>
                        <div className="" style={{ width: '100%' }}>
                          <div className="mb-3">
                            <DatePicker
                              showIcon
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                              placeholderText="End"
                              required
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div className="col-md-6 mb-1">
                <label htmlFor="price">Date </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                    <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                    />


                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                    <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                    />


                    </div>

                  </div>

                </div>
              </div> */}
              </div>
              <div className="mt-4">
                <button
                  type="button"
                  onClick={() => fetchData()}
                  className={`${styles.modal_btn} btn btn-block`}
                >
                  Proceed{' '}
                  {isPending && (
                    <CircularProgress style={{ color: 'white' }} size="1rem" />
                  )}
                </button>
              </div>
            </form>
          </div>
        </GlobalModal>

        <GlobalModal
          open={viewAction}
          onClose={() => setViewAction(false)}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="570px"
          maxWidth="95% !important"
          overflow="auto"
          bgcolor="#FFFFFF"
          border="1px solid #F5F5F5"
          boxShadow="24"
          p="25px"
        >
          <div className={`${styles.modal_content} p-2`}>
            <div className={`${styles.modal_header} mb-4`}>
              <div>
                <small>Email</small>
                <h5 style={{ fontWeight: 'bold' }}>
                  <ActionName actionValue={actionValue?.name} />
                </h5>
              </div>
              <div>
                <h6 style={{ fontWeight: 'bold', fontSize: '13px' }}>
                  IP Address
                </h6>
                <small>{actionValue?.ip_address || '---'}</small>
              </div>
            </div>
            <div>
              <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                <style>
                  {`
                  /* Hide the scrollbar */
                  ::-webkit-scrollbar {
                    width: 0; /* Remove scrollbar width */
                    background-color: transparent; /* Set background color of the scrollbar to transparent */
                  }
                `}
                </style>
                <table
                  className="px-0"
                  style={{
                    fontSize: '12.76px',
                  }}
                >
                  <thead>
                    <th className="pb-2" style={{ fontWeight: 'bold' }}>
                      Action
                    </th>
                    <th className="pb-2" style={{ width: '100%' }}></th>
                  </thead>
                  <tbody>
                    {actionValue?.actions &&
                      actionValue?.actions?.length &&
                      actionValue?.actions.map((el, i) => (
                        <tr key={i}>
                          <td class="pt-2 pb-1">
                            <ActionName actionValue={actionValue?.name} />
                            <small
                              className="ml-2"
                              style={{ color: 'grey', fontSize: '13px' }}
                            >
                              {el?.action ?? el}
                            </small>
                          </td>
                          <td class="pt-2 pb-1">
                            <small
                              className="float-right"
                              style={{ fontSize: '13px' }}
                            >
                              {el?.time ? <TimeAgo time={el?.time} /> : '---'}
                            </small>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {(!actionValue?.actions || !actionValue?.actions?.length) && (
                  <div className="alert alert-[#af3585] text-center">
                    <small>No record found</small>
                  </div>
                )}
              </div>
            </div>
            <form className="mt-0 w-100">
              <div className="mt-4 d-flex">
                <button
                  type="button"
                  onClick={() => setViewAction(false)}
                  className={`${styles.modal_btn} btn btn-light bg-light text-dark border ml-auto m-3`}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </GlobalModal>

        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleDialogClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="card border-0">
            <div className="card-body border-0 ">
              <div className={`${styles.customized_row}`}>
                <div className="text-center">
                  <img
                    style={{ width: '40px' }}
                    src="/assets/icons/caution.svg"
                    alt=""
                  />
                </div>
                <div>
                  <h5>Delete {outlets.outlet_name} outlet</h5>
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#6B7280',
                    }}
                  >
                    Are you sure you want to delete this outlet? All your data
                    will be permanently removed from our servers forever. This
                    action cannot be undone.
                  </p>
                </div>
              </div>

              <DialogActions>
                <Button
                  className={`${styles.cancel_delete}`}
                  onClick={handleDialogClose}
                >
                  Cancel
                </Button>
                {!isDeleting && (
                  <Button
                    className={`${styles.delete_product}`}
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                )}

                {isDeleting && (
                  <button disabled className={`btn ${styles.delete_product}`}>
                    {isDeleting && (
                      <CircularProgress
                        style={{ color: 'white' }}
                        size="1rem"
                      />
                    )}
                  </button>
                )}
              </DialogActions>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}
