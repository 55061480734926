
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import axiosInstance from "src/axiosInstance"
import toast from 'react-hot-toast';
import bus from "src/bus";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: '#FFFF',
    border: '1px solid #F5F5F5',
    borderRadius: '5px',
    boxShadow: 24,
    p: 3,
};

export default function ViewAdsRequest({ closeModal }) {

    const [open, setOpen] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const notifyToast = (msg) => toast.success(msg);
    const notifyError = (err) => toast.error(err);


    const [info, setInfo] = useState({
        audience: [],
    })

    useEffect(() => {
        bus.on('open-view', (val => {
            setInfo(val)
            setOpen(true)

        }))

    }, [])




    const save = (e) => {
        e.preventDefault();
        if (info.status === 'approved')
            return notifyError("Why you do that ? ");
        setIsPending(true)



        axiosInstance.get(`/admin/marketing/ads-request/approve/${info._id}`)
            .then(res => {
                notifyToast(res.data.message)
                closeModal()
                setOpen(false)




            }).catch(err => {

                if (err) {
                    notifyError(err.response.data.message);

                }
            }).finally(() => setIsPending(false))
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            disableEnforceFocus
        >
            <Fade in={open}>
                <Box sx={style} className={`mobile_modal_size`}>
                    <div className={`modal_content`}>
                        <div className={`modal_header mb-0`}>
                            <h5 style={{
                                fontWeight: 'bold',
                                fontSize: '18px'
                            }}>View Ad Request </h5>
                            <span onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
                                <img src="/assets/icons/x.svg" alt="" />

                            </span>

                        </div>
                        <p className='text-muted mt-0' style={{
                            fontSize: '14px'
                        }}>
                            Ad request from brand owner
                        </p>

                        <form onSubmit={save} className='mt-0'>
                            <div className="mb-4 inputs_n_label">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label htmlFor="target">Ads Post </label>


                                    </div>
                                    <div className="col-md-9">
                                        <div className='mx-auto' style={{ width: 'fit-content' }}>
                                            <img src={info?.ads_post} style={{ width: '100%' }} alt="" />
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="mb-4 inputs_n_label">
                                <label htmlFor="action">Ads description </label>
                                <TextField
                                    id="message"
                                    placeholder='Message'
                                    size="small"
                                    value={info.ads_description}
                                    multiline
                                    rows='5'
                                    cols='5'
                                    fullWidth

                                />


                            </div>
                            <div className="mb-4 inputs_n_label">
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <label htmlFor="action">Ads Duration </label>
                                    </div>
                                    <div className='col-md-9'>
                                        <TextField
                                            id="message"
                                            placeholder='Message'
                                            size="small"
                                            value={info.pricing_id?.duration}
                                            fullWidth

                                        />
                                    </div>
                                </div>


                            </div>
                            <div className="mb-4 inputs_n_label">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label htmlFor="message">Targeted Audience</label>

                                    </div>
                                    <div className="col-md-9">
                                        {
                                            info.audience.map((el, i) => (
                                                <div className="categories mr-2 mb-3" key={i}>
                                                    {el}
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>



                            </div>
                            {
                                info.status === 'pending' &&
                                <div className='d-flex justify-content-between h-auto' >

                                    <button
                                        type='submit'
                                        style={{ height: '50px' }}
                                        className={`btn btn-block modal_btn mr-2 my-0`}>
                                        Accept {isPending && <CircularProgress style={{color:'white'}}  size="1rem"   />}
                                    </button>

                                    <button type="button" className={` mr-2 my-0 btn btn-block`} style={{ height: '50px', border: '1px solid', background: 'unset !important' }}>
                                        <a href={`mailto:${info.business_email}`}>Send Email</a>
                                    </button>
                                </div>
                            }


                        </form>


                    </div>

                </Box>
            </Fade>
        </Modal>
    )

}