// ----------------------------------------------------------------------
import styles from "./BrandOwner.module.css"
import { useState } from "react"
import TextField from '@mui/material/TextField';
import * as React from 'react';
import bus from 'src/bus';

// sections
// mock

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useLayoutEffect } from "react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const style3 = {
  position: 'absolute',
  width: '700px',
  overflow: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#FFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  background: '#FFFFFF',
  p: 3,
};


export default function FilterModal({ closeModal }) {
  const [open1, setOpen1] = useState(false);
  const [searchValue, setSearch] = useState({
    status: ''
  });
  const [checkboxValue, setCheckboxValue] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterSearch, setFilterSearch] = useState({
    transId: '',
    searchMinAmount: '',
    searchMaxAmount: '',
  });

  const handleCheckboxChange = (event) => {
    setCheckboxValue(event.target.checked ? event.target.value : "");
  }

  const handleTransIdChange = (e) => {
    setFilterSearch(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  const handleMinAmountClick = (key, value) => {
    setFilterSearch(prev => {
      return {
        ...prev,
        [key]: value
      }
    })
  }

  const handleMaxAmountClick = (key, value) => {
    setFilterSearch(prev => {
      return {
        ...prev,
        [key]: value
      }
    })
  }

  const handleClose1 = () => {
    setOpen1(false);

  };
  const handleFilterChange = (e) => {
    let name = e.target.name
    let value = e.target.value
    setSearch((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const submitFilter = (e)=> {
    e.preventDefault();
    // setCloseFilter(false)
    bus.emit('search-filter', {
      transaction_id: filterSearch.transId,
      status: searchValue.status,
      dateFrom: startDate,
      dateTo: endDate,
      transaction_type: checkboxValue,
      min_amount: filterSearch.searchMinAmount,
      max_amount: filterSearch.searchMaxAmount,
    })
    setOpen1(false);
  }

  useEffect(() => {
    bus.on('reset-search-filter', () => {
      setFilterSearch({
        transaction_id: '',
        status: '',
        dateFrom: '',
        dateTo: '',
        transaction_type: '',
        min_amount: '',
        max_amount: '',
      });
    });
    bus.on('open-filter-wallet', (val) => {
      setOpen1(true)
    });

    return () => {

    };
  }, []);

  useLayoutEffect(() => () => {
    // Your code here.
  }, [])

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open1}>
          <Box className={`mobile_modal_size`} sx={style3}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3>Filter </h3>
                <span onClick={handleClose1} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>

              <form action=''>
                <div className="row">
                  <div className="col-md-6 mb-1">
                    <div className="mb-3">
                      <label htmlFor="transId">Transaction Id</label>
                      <TextField
                        required
                        type='text'
                        id="transId"
                        placeholder="Enter Transaction Id"
                        size="small"
                        fullWidth
                        name='transId'
                        value={filterSearch.transId}
                        onChange={handleTransIdChange}

                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="status">Status</label>
                      <Select
                        labelId="status"
                        id="status"
                        name="status"
                        fullWidth
                        size="small"
                        value={searchValue.status}
                        required
                        onChange={handleFilterChange}
                      >
                        <MenuItem value={''}>Select Status</MenuItem>
                        <MenuItem value={'pending'}>Pending</MenuItem>
                        <MenuItem value={'completed'}>Completed</MenuItem>
                        <MenuItem value={'failed'}>Failed</MenuItem>
                      </Select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="transId">Type</label>
                      <div>
                        {['withdrawal', 'top_up', 'credit', 'debit'].map((el, i) => (
                          <div className="categories" key={i}>
                            <input
                              type="checkbox"
                              id="myCheckbox"
                              name={el}
                              value={el}
                              checked={checkboxValue === el}
                              onChange={handleCheckboxChange}
                            />
                            &nbsp;
                            &nbsp;
                            {el.split("_").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-1">
                    <label htmlFor="price">Amount </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type='number'
                            id="price"
                            placeholder="Min"
                            size="small"
                            name='searchMinAmount'
                            value={filterSearch.searchMinAmount}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (inputValue === '' || parseFloat(inputValue) > 0) {
                                handleMinAmountClick('searchMinAmount', inputValue);
                              }
                            }}
                            inputProps={{
                                inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                    e.preventDefault();
                                }
                            }}
                          />
                        </div>
                      </div>
                      <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                        <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type='number'
                            placeholder="Max "
                            size="small"
                            name='searchMaxAmount'
                            value={filterSearch.searchMaxAmount}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (inputValue === '' || parseFloat(inputValue) > 0) {
                                handleMaxAmountClick('searchMaxAmount', inputValue);
                              }
                            }}
                            inputProps={{
                                inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                    e.preventDefault();
                                }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <label htmlFor="name">Date </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '48%' }}>
                        <div className="mb-3">
                          <DatePicker
                            showIcon
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            placeholderText="Start"
                            required
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                      <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                        {/* sss */}
                        <img src="/assets/icons/line.svg" style={{ width: '14px' }} alt="" />
                      </div>
                      <div className="" style={{ width: '48%' }}>
                        <div className="mb-3">
                          <DatePicker
                            showIcon
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            placeholderText="End"
                            required
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <button type="button" onClick={submitFilter} className={`${styles.modal_btn} btn btn-block`}>
                    Proceed
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  )
}
