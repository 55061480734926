
// sections
// mock
import { useLayoutEffect, useState, useEffect } from "react";
import { useAuth } from "src/hooks/useAuth";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import BrandOwners from "./SuperAdminComponent/BrandOwners";
import ShopOwners from "./SuperAdminComponent/ShopOwners";
import StoreOwners from "./SuperAdminComponent/StoreOwners";
import WithdrawRequest from "./SuperAdminComponent/WithdawRequest";
import axiosInstance from "src/axiosInstance"
import toast from 'react-hot-toast';
import bus from'src/bus'
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------



export default function BrandOwner() {
  const {data} = useAuth();
  const [value, setValue] = useState('1');
  const [count, setCount] = useState([]);
  const notifyError = (err) => toast.error(err);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useLayoutEffect(() => () => {
    localStorage.removeItem('ptvs')
  }, [])

  const fetch = () => {
    const queryParams = { total_pending_request: '1' };
    axiosInstance.get('/admin/shop-withdrawal-requests', {params: queryParams})
      .then(res => {
        const pendingCount = res.data.data
        setCount(pendingCount);
      })
      .catch(err => {
        if (err) {
          notifyError(err.response.data.message);
        }
      });
  }

  const hasPermission = (permission) => {
    const authData = JSON.parse(localStorage.getItem('auth')) || [];

    const hasPermission = authData?.direct_permissions?.some(el => {
        // console.log('el.permission_alias:', el.permission_alias);
        // console.log('permission:', permission);
        return el.permission_alias === permission;
    });

    return hasPermission;
 };

 useEffect(() => {
  fetch();

  const handleRefetch = () => {
    fetch();
  };

  bus.on('refetch', handleRefetch);

  // Cleanup the event listener on component unmount
  return () => {
    bus.off('refetch', handleRefetch);
  };
}, []);

  useEffect(() => {
    hasPermission('manage-shop-withdrawal-request');
  }, []);

  return   (
    <>
      <div className="wrapper  mt-4 mt-md-1 px-2">
        <div   className="header d-block d-md-none mb-4">
          <h4 className={`mb-0 pb-0`}>Sales</h4>
          
          <p style={{fontSize: '14px'}} className={`mb-0 pb-0`}>
          An overview of your sales performance
        

          </p>

        </div>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs ">
                <Tab label="Shoppers" value="1" />
                <Tab label="Merchants" value="2" />
                {hasPermission('manage-shop-withdrawal-request') && (
                  <Tab
                    label={
                      <span>
                        <span className="font-weight-bold">Withdrawal Request</span>{" "}
                        {value !== "3" && (
                          <span className="failed">{Number(count).toLocaleString()}</span>
                        )}
                      </span>
                    }
                    value="3"
                  />
                )}
                {/* <Tab label="Brand " value="3" /> */}
              </TabList>
            </Box>
            <TabPanel value="1"  style={{padding: '0'}}>
              <ShopOwners key={1} />
            </TabPanel>
            <TabPanel value="2" style={{padding: '0'}}>
              <StoreOwners key={2} />
            </TabPanel>
            <TabPanel value="3" style={{padding: '0'}}>
              <WithdrawRequest key={3} />
            </TabPanel>
            {/* <TabPanel value="3" style={{padding: '0'}}>
              <BrandOwners key={2} />
            </TabPanel> */}
          </TabContext>
        

      </div>
     
    </>
  ) 
}
