// ----------------------------------------------------------------------
import styles from './BrandOwner.module.css';
import { NavLink as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Filter1 from '../Filter/Filter1';
import { Card, Avatar } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
// sections
// mock
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';

//Carousel
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Typography } from '@mui/material';

import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import bus from 'src/bus';
import moment from 'moment';
import { BallTriangle } from 'react-loader-spinner';
import { shortenText } from 'src/utils';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'customerName', label: 'Customers', alignRight: false },
  { id: 'orderNumber', label: 'Order Number', alignRight: false },
  { id: 'productPurchased', label: 'Product Purchased', alignRight: false },
  { id: 'customerLocation', label: 'Location', alignRight: false },
  // { id: 'price', label: 'Total cost', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'created_at', label: 'Date ordered', alignRight: false },
];
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export default function DashboardAppPage() {
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const [searchvalue, setSearchValue] = useState('');
  const notifyError = (err) => toast.error(err);
  const notify = (msg) => toast.success(msg);
  const [tooltipData, setToolTipData] = useState({ product_items: [] });
  const [tooltipLoading, setToolTipLoading] = useState(false);

  const [dataFilter, setDataFilter] = useState('today');
  const [dashboardData, setDashboardData] = useState({
    total_sales: {
      bold: 0,
      small: 0,
      percentagDiff: 0,
    },
    recent_orders: [],
  });

  // for carousel
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000,
    fade: true,
    scrollBar: false,
  };

  function navigateTo() {
    navigate('/app/orders');
  }

  const handleViewClick = (val) => {
    localStorage.setItem('orders_info', JSON.stringify(val));
    bus.emit('getOrders');
    navigate('/app/order_details_brand');
    // handle view click action
  };

  // const getHoverProduct = (id) => {
  //   setToolTipLoading(true);
  //   setToolTipData({ product_items: [] })
  //   axiosInstance.get(`/brand/order/on-hover/${id}`)
  //     .then(res => {
  //       setToolTipData(res.data.data[0] || { product_items: [] })

  //     }).catch(err => {

  //       try {
  //         notifyError(err.response.data.message);

  //       } catch (error) {
  //         notifyError("Unable to fetch data, try again later");

  //       }

  //     }).finally(() => setToolTipLoading(false))
  // };

  const getHoverProduct = (id) => {
    setToolTipLoading(true);
    setToolTipData({ product_items: [] });
    axiosInstance
      .get(`/brand/sales/shoppers/on-hover/${id}`)
      .then((res) => {
        setToolTipData(res.data.data[0] || { product_items: [] });
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Something went wrong , please try again later');
        }
      })
      .finally(() => setToolTipLoading(false));
  };

  const handleSendReminderClick = (val) => {
    // handle send reminder click action
    axiosInstance
      .get('/brand/order/send-reminder/' + val)
      .then((res) => {
        notify(res.data.message);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch {
          notifyError('Something went wrong, Please try again later');
        }
      });
  };

  const fetchData = (
    page = 1,
    pageSize = 10,
    search = searchvalue,
    dateFilter = dataFilter
  ) => {
    setIsPending(true);
    axiosInstance
      .get('/brand/dashboard/get?', {
        params: {
          page,
          pageSize,
          search,
          dateFilter,
        },
      })
      .then((res) => {
        setDashboardData(res.data.data);
        // setReload(!reload)
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError(
            'Unable to fetch dashboard records, please try again later'
          );
        }
      })
      .finally(() => setIsPending(false));
  };

  useEffect(() => {
    bus.on('dashboardSearch', (val) => {
      setSearchValue(val);
    });
  }, []);

  useEffect(() => {
    bus.on('dataFilter', (el) => {
      // console.log(el);
      setDataFilter(el);
    });
    fetchData();
  }, [searchvalue, dataFilter]);

  return (
    <>
      <div className={`wrapper mt-md-0 mt-4 px-2`}>
        <div className={`${styles.header} d-block d-md-none mb-4`}>
          <h4 className={`mb-0 pb-0`}>Dashboard</h4>
          <p style={{ fontSize: '14px' }} className={`mb-0 pb-0`}>
            An overview of your business performance
          </p>
        </div>
        {/* Banner */}
        <div style={{ overflow: 'hidden' }}>
          <Slider {...settings}>
            <div>
              <Typography variant="h3" className={`${styles.banner}`}>
                <img
                  src="/assets/icons/bannerImageOne.png"
                  className={`${styles.banner_img1}`}
                  alt=""
                />
              </Typography>
            </div>
            <div>
              <Typography variant="h3" className={`${styles.banner}`}>
                <img
                  src="/assets/icons/bannerImageTwo.png"
                  alt="logo"
                  className={`${styles.banner_img1}`}
                />
              </Typography>
            </div>
          </Slider>
        </div>
        {/* Banner */}
        <div className={`${styles.analytics} mt-4`}>
          <Filter1 />
          <div>
            <div className="row">
              <div className={`col-12 mb-2 pr-0`}>
                <div className={`${styles.card}`}>
                  <div className={`card-body`}>
                    <div className="d-flex justify-content-between">
                      <small>Total Sales</small>
                    </div>
                    <div className={`${styles.numbers}`}>
                      <div>
                        <span>
                          {Number(
                            dashboardData?.total_sales?.bold
                          ).toLocaleString()}
                        </span>
                        <small>
                          {' '}
                          from{' '}
                          {Number(
                            dashboardData?.total_sales?.small
                          ).toLocaleString()}
                        </small>
                      </div>
                      <div>
                        {Number(
                          dashboardData?.total_sales?.bold
                        ).toLocaleString() >=
                        Number(
                          dashboardData?.total_sales?.small
                        ).toLocaleString() ? (
                          <div className={styles.percent}>
                            <img src="/assets/icons/up.svg" alt="" />
                            <small className="pl-1">
                              {dashboardData?.total_sales
                                ? Number(
                                    dashboardData?.total_sales?.percentagDiff
                                  ).toLocaleString()
                                : 0}
                              %
                            </small>
                          </div>
                        ) : (
                          <div className={`${styles.percent2}`}>
                            <img src="/assets/icons/downr.svg" alt="" />
                            <small className="pl-1">
                              {dashboardData?.total_sales
                                ? Math.abs(
                                    dashboardData?.total_sales?.percentagDiff
                                  )
                                : 0}
                              %
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className={`col-6 mb-2`}>
                <div className={`${styles.card}`}>
                  <div className={`card-body`}>
                    <div className="d-flex justify-content-between">
                      <small>Total Visitors</small>
                    </div>
                    <div className={`${styles.numbers}`}>
                      <div>
                        <span>{Number(dashboardData?.total_visitors?.bold).toLocaleString()}</span>
                        <small> from {Number(dashboardData?.total_visitors?.small).toLocaleString()}</small>
                      </div>
                      <div>
                      {
                        Number(dashboardData?.total_visitors?.bold).toLocaleString() >=
                        Number(dashboardData?.total_visitors?.small).toLocaleString() ?
                        <div className={styles.percent}>
                          <img src="/assets/icons/up.svg" alt="" />
                          <small className="pl-1">
                            {
                              dashboardData?.total_visitors ?
                              Number(dashboardData?.total_visitors?.percentageDiff).toLocaleString() :
                              0
                            }%
                          </small>
                        </div>
                        :
                        <div className={`${styles.percent2}`}>
                          <img src="/assets/icons/downr.svg" alt="" />
                          <small className="pl-1">
                            {
                              dashboardData?.total_visitors ?
                              Math.abs(dashboardData?.total_visitors?.percentageDiff) :
                              0
                            }%
                          </small>
                        </div>
                      }
                      </div>

                    </div>

                  </div>

                </div>

              </div> */}
            </div>
            {/* <div className={`mt-3 mb-2`} >
              <div className={`${styles.card}`} >
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Total Sales</small>

                  </div>
                  <div className={`${styles.numbers} mt-3`}>
                    <div>
                      <span>₦ {Number(dashboardData?.total_sales_count?.bold).toLocaleString()}</span>
                      <small> from ₦ {Number(dashboardData?.total_sales_count?.small).toLocaleString()}</small>
                    </div>
                    <div>
                    {
                        Number(dashboardData?.total_sales_count?.bold).toLocaleString() >=
                        Number(dashboardData?.total_sales_count?.small).toLocaleString() ?
                        <div className={styles.percent}>
                          <img src="/assets/icons/up.svg" alt="" />
                          <small className="pl-1">
                            {
                              dashboardData?.total_sales_count ?
                              Number(dashboardData?.total_sales_count?.percentagDiff).toLocaleString() :
                              0
                            }%
                          </small>
                        </div>
                        :
                        <div className={`${styles.percent2}`}>
                          <img src="/assets/icons/downr.svg" alt="" />
                          <small className="pl-1">
                            {
                              dashboardData?.total_sales_count ?
                              Math.abs(dashboardData?.total_sales_count?.percentagDiff) :
                              0
                            }%
                          </small>
                        </div>
                      }

                    </div>

                  </div>

                </div>

              </div>

            </div> */}
          </div>
          {/* <div>
            <div className={`${styles.card2} card    `} style={{ padding: '' }}>
              <div className={`${styles.wallet}`}  >
                <img className={`${styles.img} ${styles.img1}`} src="/assets/crc.png" alt="" />
                <div className={`${styles.content}`}>
                  <div className={`${styles.content_header}`}>
                    <div>
                      ₦‎ {Number(dashboardData?.wallet_info?.balance?.$numberDecimal).toLocaleString() + '.00'}

                    </div>
                    <div>
                      <img src="/assets/icons/Vector.svg" alt="" />
                    </div>

                  </div>
                  <p className={`${styles.sub_text}`}>
                    Your Balance
                  </p>
                  <div className={`${styles.content_body} ${styles.content_body2}`}>
                    <p className={`${styles.sub_text}`}>
                      Account Details
                    </p>

                    <div className={`${styles.bank_info}`}>
                      <div >
                        <p className={`${styles.name}`}>
                          {dashboardData?.wallet_info?.brand_id?.bank_account_name}

                        </p>
                        <p className={`${styles.account_number}`}>
                          {dashboardData?.wallet_info?.brand_id?.bank_account_number}
                        </p>

                      </div>
                      <img className="mt-2" src="/assets/Logomark.svg" alt="" />
                      <span style={{
                          fontSize:'9px',
                          fontWeight:'700',
                          color:'#FFFFFF'
                        }}>
                          {dashboardData?.wallet_info?.brand_id?.bank_name}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={`${styles.footer} ${styles.footer2}`}>
                  <div className="">
                    <p className="mb-0 pb-0">
                      <RouterLink to="/app/wallets">
                        Manage Wallet
                        <img src="/assets/icons/arrow-right.svg" alt="" />
                        &nbsp;

                      </RouterLink>


                    </p>

                  </div>

                </div>

              </div>

            </div>
          </div> */}
        </div>

        <Card
          style={{ marginTop: '50px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-3 ">
            <h5 className="table_desc_header">Recent Sales</h5>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <small style={{ fontWeight: '200', fontSize: '12px' }}>
                {dataFilter === 'today'
                  ? // `Orders made for Today`
                    `A record of all recent sales`
                  : dataFilter === 'alltime'
                  ? `A record of all recent sales`
                  : dataFilter === 'pastweek'
                  ? `Orders made for the Past Week`
                  : ` Orders made in the last ${dataFilter} `}
              </small>
              {/* <small style={{ color: '#af3585', cursor: 'pointer' }} onClick={() => navigateTo()}>
                View all <img src="/assets/icons/arrow-righty.svg" alt="" />
              </small> */}
            </div>
          </div>
          <Scrollbar>
            <div className={`table-responsive-sm pt-3 pb-3 pl-0 pr-0`}>
              <table className={`${styles.table} table-hover table-striped`}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      let sIndex = i < 1 ? 1 : false;

                      return (
                        <th key={i} className="text-muted">
                          {el.label}{' '}
                          {sIndex && (
                            <span style={{ cursor: 'pointer' }}>
                              <img
                                className="pl-2"
                                src="/assets/icons/down.svg"
                                alt=""
                              />
                            </span>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {!isPending &&
                    dashboardData?.recent_sales?.length > 0 &&
                    dashboardData?.recent_sales?.slice(0, 6).map((el, i) => {
                      return (
                        <tr className={`${styles.tr}`} key={i}>
                          <td>
                            <div className=" ">
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <Avatar
                                  className={`${styles.avatrr} mr-3`}
                                  alt={el?.user_info?.store_name}
                                  src={el?.user_info?.store_logo}
                                  style={{ width: '50px', height: '50px' }}
                                />
                                <div>
                                  <p
                                    className="mb-0"
                                    style={{ fontSize: '14px' }}
                                  >
                                    <strong>{el?.user_info?.user_name}</strong>
                                  </p>
                                  <span style={{ fontSize: '10px' }}>
                                    {el?.user_info?.user_email}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>{el?.order_number}</p>
                          </td>
                          <td>
                            <p className="text-center">
                              <BootstrapTooltip
                                title={
                                  <div>
                                    {!tooltipLoading &&
                                      tooltipData.product_items.length > 0 &&
                                      tooltipData.product_items.map(
                                        (tooltip, ind) => (
                                          <span key={ind} className="mr-1">
                                            {tooltip.product_name},
                                          </span>
                                        )
                                      )}
                                    {!tooltipLoading &&
                                      tooltipData.product_items.length < 1 && (
                                        <span>Empty</span>
                                      )}
                                    {tooltipLoading && <span>Loading</span>}
                                  </div>
                                }
                              >
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onMouseEnter={() =>
                                    getHoverProduct(el._id._id)
                                  }
                                >
                                  {el?.product_unit}
                                </span>
                              </BootstrapTooltip>
                            </p>
                          </td>
                          <td>
                            <BootstrapTooltip title={<div>{el?.location}</div>}>
                              <p>
                                {el?.location
                                  ? shortenText(el?.location)
                                  : '---'}
                              </p>
                            </BootstrapTooltip>
                          </td>
                          <td>
                            <p>
                              <span
                                className={`${
                                  el?.status === 'completed'
                                    ? styles.customer
                                    : el?.status === 'pending'
                                    ? styles.pending
                                    : el?.status === 'refunded'
                                    ? styles.refunded
                                    : styles.abandoned
                                }`}
                              >
                                {el?.status}
                              </span>
                            </p>
                          </td>
                          <td>
                            <p>
                              {moment(el?.date_ordered).format('DD/MM/YYYY')}
                            </p>
                          </td>
                          {/* <td>
                          <div className="dropleft ">

                            <span
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              className={`${styles.action_button} px-3 py-2`}  style={{
                                cursor: 'pointer'
                              }}>
                              <img src="/assets/icons/action.svg" alt="" />
                            </span>
                            <div
                              className="dropdown-menu drop-left"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <button
                                type="button"
                                onClick={() => handleViewClick(el)}
                                className="rss dropdown-item btn border-0">
                                  View
                              </button>
                              {el?.status === 'abandoned' && <button
                                className="rss btn border-0 dropdown-item"
                                type="button"
                                onClick={() => handleSendReminderClick(el?._id._id)}
                              >
                                Send Reminder
                              </button>}
                            </div>
                          </div>
                        </td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!dashboardData?.recent_sales?.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </div>
    </>
  );
}
