// ----------------------------------------------------------------------
import styles from '../BrandOwner.module.css';
import { NavLink as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import ProductsModal from '../Modals/ProductsModal.js';
import Voucher from '../Modals/Voucher.js';
import bus from 'src/bus';
import axiosInstance from 'src/axiosInstance';
import { BallTriangle } from 'react-loader-spinner';

import { Card } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { createRef } from 'react';
import { useLayoutEffect } from 'react';
import SingleView from './SingleView';
import { Pagination } from 'src/components';
// ----------------------------------------------------------------------
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useCategories } from 'src/hooks/useCategories';
import { useCategoriesApi } from 'src/context/CategoriesApi';
import toast from 'react-hot-toast';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 190,
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 1,
};
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const style1 = {
  position: 'absolute',
  height: '700px',
  overflow: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  backgroundColor: '#FFFFFF',
  p: 3,
};
function changeView(d) {
  localStorage.setItem('ptvp', d);
}
// ptvp=PAGE TO VIEW (PRODUCT)

let app_view = localStorage.getItem('ptvp') ?? 0;
export default function ProductsPageBrandowwner() {
  let textInput = createRef();
  const categoriesApi = useCategoriesApi();

  const [open, setOpen] = useState(false);
  const [notify, setNotify] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [openGoToCart, setopenGoToCart] = useState(false);
  const categories = useCategories();
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();

  const [newProducts, setNewProducts] = useState({
    notifications: [],
    cases_type: 'Cartons',
  });
  const handleGoToCartClose = () => setopenGoToCart(false);

  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [category, setCategory] = useState(1);
  const [url, setUrl] = useState('');
  const [appView, setAppview] = useState(app_view);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open2 = Boolean(anchorEl1);
  const [promoData, setPromoData] = useState([]);
  const [promoDataPagination, setPromoDataPagination] = useState({ total: 0 });
  const notifyError = (err) => toast.error(err);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchvalue, setSearchvalue] = useState('');

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvp');

      // Your code here.
    },
    []
  );
  const handleCloseList = (key, value) => {
    setAnchorEl1(null);
    setNewProducts((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const searchFilter = (e) => {
    setSearchvalue(e.target.value);
  };

  const handleProductSubmit = (e) => {
    e.preventDefault();
    setOpen1(false);
    setopenGoToCart(true);
  };

  const handleFile = (e) => {
    if (e.target.files[0]) {
      setUrl(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleButtonClick = () => {
    textInput.current.click();
  };

  const handleClickList1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const discountPage = () => {
    handleGoToCartClose();
    setShowDiscount(true);
    bus.emit('open-discount', true);
  };

  const handleViewClick = (d) => {
    navigate('/app/products/' + d);
    // handle view click action
  };

  const reloadPage = (val) => {
    setAppview(val);
  };

  useEffect(() => {
    fetchData();
  }, [searchvalue]);

  bus.on('reload', () => {
    fetchData();
  });
  const fetchData = (page = 1, perpage = 10, search = searchvalue) => {
    setIsPending(true);
    axiosInstance
      .get('/admin/product/promos-discount', {
        params: {
          page,
          perpage,
          search,
        },
      })
      .then((res) => {
        setPromoData(res.data.data.data);
        setPromoDataPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch discounts, please try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const getInitials = (val) => {
    if (val.name) {
      const splitName = val.name.split(' ');
      const initials = splitName
        .map((name) => name.charAt(0))
        .join('')
        .toUpperCase();
      return initials;
    }
  };

  return Number(appView) === 0 ? (
    <>
      <div className="mt-3 px-2">
        <div className={`${styles.filters}  mss`}>
          <div className="row">
            <div className={`${styles.hold_input1} col-md-3   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={searchvalue}
                onChange={searchFilter}
                placeholder="Search"
                style={{
                  textIndent: '25px',
                  width: '100%',
                }}
                className=" form-control"
              />
            </div>
          </div>
        </div>
        <Card
          style={{
            marginTop: '10px',
            borderRadius: '10px',
          }}
          className="p-0"
        >
          <div className="p-4 border-bottom">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">Promos And Discounts</h5>
                <small
                  style={{
                    fontWeight: '200',
                    fontSize: '14px',
                  }}
                >
                  Brands running discounts on FlexDeals
                </small>
              </div>
              <div className="pt-md-2 d-md-flex">
                &nbsp; &nbsp;
                <button
                  onClick={() => bus.emit('open-discount')}
                  className={` btn btn_primary`}
                >
                  Create Discount
                </button>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`table-responsive-sm  pt-3 pb-3 pl-0 pr-0`}>
              <table className={`${styles.table} table-hover table-striped`}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    <th
                      style={{
                        width: '40%',
                      }}
                      className="text-muted"
                    >
                      Brand Name
                      <img
                        className="pl-2"
                        src="/assets/icons/down.svg"
                        alt=""
                      />
                    </th>
                    <th
                      style={{
                        width: '30%',
                      }}
                      className="text-muted "
                    >
                      Email Address
                    </th>
                    <th
                      style={{
                        width: '30%',
                      }}
                      className="text-muted "
                    >
                      Number of Products
                    </th>
                    <th
                      style={{
                        width: '30%',
                      }}
                      className="text-muted"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!isPending &&
                    promoData.length > 0 &&
                    promoData.map((el, i) => (
                      <tr key={i} className={`${styles.tr} `}>
                        <td>
                          <div
                            className=" "
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            {/* <Avatar className={`${styles.avatrr} mr-3 float-md-left`} alt="" src="/assets/image1.svg" /> */}
                            <div className="initials">{getInitials(el)}</div>
                            &nbsp; &nbsp; &nbsp;
                            <div>
                              <strong>{el.name}</strong>
                              <br />
                              <small>{el.brandID}</small>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p>
                            <span>{el.email}</span>
                          </p>
                        </td>
                        <td>
                          <p>{el.count}</p>
                        </td>
                        <td>
                          <button
                            onClick={() => handleViewClick(el?.brand_id)}
                            className={`btn ${styles.view_action}`}
                          >
                            <img
                              src="/assets/icons/eyewhite.svg"
                              className="pr-1"
                              alt=""
                            />
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!promoData.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={promoDataPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>

      {/* Modals */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open}>
          <Box className={`mobile_modal_size`} sx={style}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3>Choose Category</h3>
                <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <form action="">
                <div className="mb-3">
                  <label htmlFor="name">Category Name</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={category}
                    required
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>Breveges</MenuItem>
                    <MenuItem value={2}>Table water</MenuItem>
                    <MenuItem value={3}>Snacks</MenuItem>
                  </Select>
                </div>
                <div className="mb-4">
                  <button
                    type="button"
                    onClick={handleClose}
                    className={`${styles.modal_btn} btn btn-block`}
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      <ProductsModal key={3} closeModal={() => setShowDiscount(false)} />
      <Voucher key={4} />
    </>
  ) : (
    <SingleView key={1} reload={reloadPage} />
  );
}
