import { terms } from "./data";
import * as DOMPurify from "dompurify";

let clean = DOMPurify.sanitize(terms);
const TermsAndCondition = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h3 style={{ padding: 30 }}>TERM AND CONDITIONS</h3>
      {<div dangerouslySetInnerHTML={{ __html: clean }} />}
    </div>
  );
};

export default TermsAndCondition;
