import styles from '../Order.module.css';
import { Card, Avatar } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { useLayoutEffect } from 'react';
import GlobalModal from 'src/Modals/GlobalModal';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import Filter1 from 'src/pages/Filter/Filter1';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import moment from 'moment';
import { BallTriangle } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';
import { downloadFile2 } from 'src/utils';
import { Pagination } from 'src/components';

// ----------------------------------------------------------------------
import bus from 'src/bus';

const TABLE_HEAD = [
  { id: 's/n', label: 'S/N', alignRight: false },
  { id: 'customerName', label: 'Transaction ID', alignRight: false },
  { id: 'productUnit', label: 'Product Type', alignRight: false },
  { id: 'paymentMethod', label: 'Payment Method', alignRight: false },
  { id: 'totalCost', label: 'Total Cost', alignRight: false },
  { id: 'created_at', label: 'Date ordered', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'Order Confirmation', label: 'Order Confirmation' },
  { id: 'Admin', label: 'Admin' },
  { id: 'Patronage ', label: 'Patronage' },
  { id: 'Action ', label: ' ' },
];
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
// ----------------------------------------------------------------------

// ptvo=PAGE TO VIEW (ORDER)
export default function OrderRetailerPage({ viewChange }) {
  const [trx_id, setTrx_id] = useState('');
  const [orderType, setOrderType] = useState('All');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenConfirm, setModalOpenConfirm] = useState(false);
  const [modalOpenConfirm1, setModalOpenConfirm1] = useState(false);
  const [confirmOrder, setConfirmOrder] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [status, setStatus] = useState('select');
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const [brandPending, setBrandPending] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [searchvalue, setSearchvalue] = useState([]);
  const [locationValue, setLocationValue] = useState('');
  const [categoryPagination, setCategoryPagination] = useState({ total: 0 });
  const [exporting, setExporting] = useState(false);

  // const notify = (msg =null) => toast.success(msg === null ?'Store saved .' : msg);
  const notifyError = (err) => toast.error(err);
  const [dataCount, setDataCount] = useState({
    order_completed: 0,
    order_abandoned: 0,
  });
  const [filterSearch, setFilterSearch] = useState({
    type: '',
    status: '',
    min_price: 0,
    max_price: 0,
    max_order_unit: 0,
    min_order_unit: 0,
    order_confirmation: '',
    payment_method: '',
    startDate: '',
    endDate: '',
    period: 'today',
  });
  const [search, setSearch] = useState({
    pmethod: 'select',
    orderConfirm: 'select',
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [dataFilter, setDataFilter] = useState('today');
  const [tooltipData, setToolTipData] = useState({ product_items: [] });
  const [tooltipLoading, setToolTipLoading] = useState(false);

  const processLocation = (e) => {
    // console.log(e);
    setLocationValue(e.value);
  };

  const handleFilterStatus = (key, value) => {
    setFilterSearch((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const handleTrxChange = (event) => {
    setTrx_id(event.target.value);
  };
  const handleConfirm = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setSearch((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleClose = () => {
    setModalOpen(false);
    setFilterSearch({
      type: '',
      status: '',
      min_price: 0,
      max_price: 0,
      max_order_unit: 0,
      min_order_unit: 0,
      order_confirmation: '',
      payment_method: '',
      startDate: '',
      endDate: '',
      period: 'today',
    });
    fetchData();
  };
  const handleCloseConfirm1 = () => {
    setModalOpenConfirm1(false);
    fetchData();
  };
  const handleCloseConfirm = () => {
    setModalOpenConfirm(false);
  };

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvo');
    },
    []
  );

  const handleViewClick = (val) => {
    localStorage.setItem('order_details_info', JSON.stringify(val));
    navigate('/app/order_details_shop');
  };

  const handleSendReminderClick = (val) => {
    // handle send reminder click action
    // axiosInstance.get('/brand/order/send-reminder/'+val)
    // .then(res => {
    //   notify(res.data.message)
    // }).catch(err => {
    //   try{
    //     notifyError(err.response.data.message)
    //   } catch{
    //     notifyError('Something went wrong, Please try again later')
    //   }
    // })
  };

  const getHoverProduct = (id) => {
    setToolTipLoading(true);
    setToolTipData({ product_items: [] });
    axiosInstance
      .get(`/shop/order/get-customer-order-hover/${id}`)
      .then((res) => {
        if (res.data.data !== null) {
          setToolTipData(res.data.data || { product_items: [] });
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Something went wrong , please try again later');
        }
      })
      .finally(() => setToolTipLoading(false));
  };

  const closeModals = () => {
    setConfirmOrder(true);
    axiosInstance
      .put('shop/order/mark-user-order/' + trx_id, {
        order_number: orderNumber,
      })
      .then((res) => {
        setModalOpenConfirm(false);
        if (res.status < 300) {
          setModalOpenConfirm1(true);
          ordersTotalCount();
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch {
          notifyError('Something went wrong, Please try again later');
        }
      })
      .finally(() => setConfirmOrder(false));
  };

  const handleConfirmOrder = (val, order_number, transaction_id, event) => {
    event.stopPropagation();
    setTrx_id(val);
    setOrderNumber(order_number);
    setTransactionId(transaction_id);
    setModalOpenConfirm(true);
  };

  const searchFilter = (e) => {
    setSearchvalue(e.target.value);
  };

  const ordersTotalCount = () => {
    let filter_payload = {};
    if (filterSearch.period !== '') {
      filter_payload.period = filterSearch.period;
    }
    setBrandPending(true);
    handleClose(false);
    setModalOpen(false);
    let periodValue = typeof dataFilter === 'object' ? 'All time' : dataFilter;

    axiosInstance
      .get('/shop/order/customer-order-cards?period=' + periodValue)
      .then((res) => {
        // console.log(res.data.data);
        if (res.status < 300) {
          setDataCount(res.data.data);
          // setOrderRender(!orderRender)
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch subscriptions, try again later');
        }
      })
      .finally(() => setBrandPending(false));
  };
  const fetchExportData = async (page, perpage, searchvalue, file) => {
    if (file) {
      setExporting(true);

      let queryParams = new URLSearchParams({
        page,
        perpage,
        search: searchvalue,
        status: '',
        min_price: '',
        max_price: '',
        min_unit: '',
        max_unit: '',
        payment_method: '',
        export_type: file,
      }).toString();

      try {
        await downloadFile2(
          'All Customers orders',
          `/shop/order/get-customer-orders?${queryParams}`,
          file
        );
      } finally {
        // alert()
        setExporting(false);
      }
      return;
    }
  };

  const fetchData = (
    page = 1,
    perpage = 10
    // search = searchvalue,
    // status = '',
    // min_price = '',
    // max_price = '',
    // min_unit = '',
    // max_unit = '',
    // payment_method = ''
  ) => {
    let filter_payload = {};
    if (filterSearch.min_price) {
      filter_payload.min_price = filterSearch.min_price;
    }
    if (filterSearch.max_price) {
      filter_payload.max_price = filterSearch.max_price;
    }
    if (filterSearch.min_order_unit) {
      filter_payload.min_unit = filterSearch.min_order_unit;
    }
    if (filterSearch.max_order_unit) {
      filter_payload.max_unit = filterSearch.max_order_unit;
    }
    if (searchvalue !== '') {
      filter_payload.search = searchvalue;
    }
    if (startDate !== null) {
      filter_payload.startDate = startDate;
    }
    if (endDate !== null) {
      filter_payload.endDate = endDate;
    }
    if (
      filterSearch.order_confirmation !== '' ||
      filterSearch.order_confirmation !== 'all'
    ) {
      filter_payload.order_status = filterSearch.order_confirmation;
    }
    if (
      filterSearch.payment_method !== '' &&
      filterSearch.payment_method !== 'all'
    ) {
      filter_payload.payment_method = filterSearch.payment_method;
    }
    if (filterSearch.status !== '' && filterSearch.status !== 'all') {
      filter_payload.status = filterSearch.status;
    }
    setIsPending(true);
    axiosInstance
      .get('/shop/order/get-customer-orders?', {
        params: {
          page,
          perpage,
          ...filter_payload,
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        if (res.status < 300) {
          setModalOpen(false);
          setOrderData(res.data.data.data);
        }
        setCategoryPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch subscriptions, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  useEffect(() => {
    bus.on('dataFilter', (el) => {
      setDataFilter(el);
    });
    fetchData();
  }, [searchvalue, dataFilter]);

  useEffect(() => {
    ordersTotalCount();
  }, [dataFilter]);

  return (
    <>
      <div className=" ">
        <div className={`${styles.analytics}`}>
          <Filter1 width="100%" />
          <div className={`row`}>
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Completed Orders</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        {dataCount?.order_completed
                          ? Number(
                              dataCount.order_completed.current_order_completed
                            ).toLocaleString()
                          : 0}
                      </span>
                      <small className="ml-1">
                        from{' '}
                        {dataCount?.order_completed
                          ? Number(
                              dataCount.order_completed.previous_order_completed
                            ).toLocaleString()
                          : 0}
                      </small>
                    </div>
                    <div>
                      {Number(
                        dataCount.order_completed.current_order_completed
                      ).toLocaleString() >=
                      Number(
                        dataCount.order_completed.previous_order_completed
                      ).toLocaleString() ? (
                        <div className={styles.percent}>
                          <img src="/assets/icons/up.svg" alt="" />
                          <small className="pl-1">
                            {
                              dataCount.order_completed
                                .percentage_completed_order
                            }
                            %
                          </small>
                        </div>
                      ) : (
                        <div className={`${styles.percent2}`}>
                          <img src="/assets/icons/downr.svg" alt="" />
                          <small className="pl-1">
                            {dataCount.order_completed
                              ? Math.abs(
                                  dataCount.order_completed
                                    .percentage_completed_order
                                )
                              : 0}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Pending Orders */}
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Pending Orders</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        {dataCount?.order_pending
                          ? Number(
                              dataCount?.order_pending.current_order_pending
                            ).toLocaleString()
                          : 0}
                      </span>
                      <small className="ml-1">
                        from{' '}
                        {dataCount?.order_pending
                          ? Number(
                              dataCount?.order_pending.previous_order_pending
                            ).toLocaleString()
                          : 0}
                      </small>
                    </div>
                    <div>
                      {Number(
                        dataCount?.order_pending?.current_order_pending
                      ).toLocaleString() >=
                      Number(
                        dataCount?.order_pending?.previous_order_pending
                      ).toLocaleString() ? (
                        <div className={styles.percent}>
                          <img src="/assets/icons/up.svg" alt="" />
                          <small className="pl-1">
                            {dataCount?.order_pending?.percentage_pending_order}
                            %
                          </small>
                        </div>
                      ) : (
                        <div className={`${styles.percent2}`}>
                          <img src="/assets/icons/downr.svg" alt="" />
                          <small className="pl-1">
                            {dataCount?.order_pending
                              ? Math.abs(
                                  dataCount?.order_pending
                                    ?.percentage_pending_order
                                )
                              : 0}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.filters} mt-2`}>
          <div className="row">
            <div className={`${styles.hold_input} col-6 col-md-3   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={searchvalue}
                onChange={searchFilter}
                placeholder="Search"
                style={{
                  textIndent: '25px',
                  width: '100%',
                }}
                className=" form-control"
              />
            </div>
            <div className="col-6 col-md-9 text-right">
              <div className="droplef ">
                <button
                  className="btn border"
                  onClick={() => setModalOpen(true)}
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    backgroundColor: '#fff',
                    border: '1px solid #D0D5DD',
                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    borderRadius: '4px',
                  }}
                >
                  <img
                    className=""
                    style={{
                      display: 'inline',
                      width: '28%',
                    }}
                    src="/assets/icons/filterlines.svg"
                    alt=""
                  />
                  &nbsp;
                  <small className="d-none d-md-inline-block">Filters</small>
                </button>
                <div
                  className="dropdown-menu drop-left"
                  aria-labelledby="dropdownMenuButton"
                >
                  <button
                    type="button"
                    onClick={() => setOrderType('All')}
                    className="rss dropdown-item btn border-0"
                  >
                    All
                  </button>
                  <button
                    type="button"
                    onClick={() => setOrderType('Abandoned')}
                    className="rss dropdown-item btn border-0"
                  >
                    Abandoned
                  </button>
                  <button
                    type="button"
                    onClick={() => setOrderType('Completed')}
                    className="rss dropdown-item btn border-0"
                  >
                    Completed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card
          style={{
            marginTop: '10px',
            borderRadius: '10px',
          }}
          className="p-0"
        >
          <div className="p-3 ">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">{orderType} Orders</h5>
                <small
                  style={{
                    fontWeight: '200',
                    fontSize: '12px',
                    display: 'inline-block',
                    marginTop: '0px',
                    color: '#4b5563',
                  }}
                >
                  Customers who purchased from your outlets recently
                </small>
              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`${styles.export_btn} btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: '8px',
                      }}
                    >
                      <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                      {!exporting && <span>Export</span>}
                      {exporting && (
                        <CircularProgress
                          style={{
                            color: 'white',
                            display: 'inline-block',
                          }}
                          size="1rem"
                        />
                      )}
                    </span>
                    &nbsp; &nbsp;
                    <span
                      style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <img src="/assets/icons/arrowDown.svg" alt="" />
                    </span>
                  </button>
                  <div
                    className="dropdown-menu drop-left"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      onClick={() =>
                        fetchExportData(
                          currentPage,
                          categoryPagination?.total,
                          searchvalue,
                          'csv'
                        )
                      }
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      CSV
                    </button>
                    <button
                      onClick={() =>
                        fetchExportData(
                          currentPage,
                          categoryPagination?.total,
                          searchvalue,
                          'excel'
                        )
                      }
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      Excel
                    </button>
                    <button
                      onClick={() =>
                        fetchExportData(
                          currentPage,
                          categoryPagination?.total,
                          searchvalue,
                          'pdf'
                        )
                      }
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`table_responsive pt-3 pb-3 pl-0 pr-0`}>
              <table
                className={`${styles.table} table-hover table-striped`}
                style={{ minWidth: '100em' }}
              >
                <thead className={`${styles.thead}`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      let sIndex = i < 1 ? 1 : false;
                      return (
                        <th className="text-muted" key={sIndex}>
                          {el.label}{' '}
                          {sIndex && (
                            <span style={{ cursor: 'pointer' }}>
                              <img
                                className="pl-2"
                                src="/assets/icons/down.svg"
                                alt=""
                              />
                            </span>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {!isPending &&
                    orderData.length >= 1 &&
                    orderData.map((el, i) => {
                      return (
                        <tr
                          className={`${styles.tr}`}
                          onClick={() => handleViewClick(el)}
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <td>
                            <span className="ml-3">
                              {(currentPage - 1) * itemsPerPage + i + 1}
                            </span>
                          </td>
                          <td>
                            <div className=" ">
                              <Avatar
                                className={`${styles.avatrr} mr-3 float-md-left`}
                                alt={el.full_name}
                                src={el.image}
                              />
                              <div className=" ">
                                <p
                                  className="mb-0"
                                  style={{ fontSize: '14px' }}
                                >
                                  <strong>{el.full_name}</strong>
                                </p>
                                <span
                                  style={{
                                    fontSize: '16px',
                                    fontStyle: 'bold',
                                    color: '#000',
                                  }}
                                >
                                  {el.transaction_id}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="">
                            <BootstrapTooltip
                              title={
                                <div>
                                  {!tooltipLoading &&
                                    tooltipData.product_items.length > 0 &&
                                    tooltipData.product_items.map(
                                      (tooltip, ind) => (
                                        <span key={ind} className="mr-1">
                                          {tooltip.product_name},
                                        </span>
                                      )
                                    )}
                                  {!tooltipLoading &&
                                    tooltipData.product_items.length < 1 && (
                                      <span>Empty</span>
                                    )}
                                  {tooltipLoading && <span>Loading</span>}
                                </div>
                              }
                            >
                              <span
                                onMouseEnter={() =>
                                  getHoverProduct(el.order_id)
                                }
                              >
                                {el.product_units}
                              </span>
                            </BootstrapTooltip>
                          </td>
                          <td className="">
                            {el.payment_method.charAt(0).toUpperCase() +
                              el.payment_method.slice(1)}
                          </td>
                          <td className="">
                            ₦ {el.total_cost ? el.total_cost.$numberDecimal : 0}
                          </td>
                          <td className="">
                            {moment(el?.date_ordered).format('DD/MM/YYYY')}
                          </td>
                          <td>
                            <span>
                              {el?.order_status === 'completed' ? (
                                <span className={`${styles.customer}`}>
                                  Completed
                                </span>
                              ) : el?.order_status === 'pending' ? (
                                <span className={`${styles.pending}`}>
                                  Pending
                                </span>
                              ) : el?.order_status === 'refunded' ? (
                                <span className={`${styles.refunded}`}>
                                  Refunded
                                </span>
                              ) : (
                                <span className={`${styles.abandoned}`}>
                                  Abandoned
                                </span>
                              )}
                            </span>
                          </td>
                          <td>
                            <div className={`${styles.btn_controls}`}>
                              {el?.order_status === 'completed' ? (
                                <button
                                  className={`btn  ${styles.confirmed_disabled}`}
                                  style={{
                                    cursor: 'auto',
                                  }}
                                >
                                  Confirmed
                                </button>
                              ) : el?.order_status === 'pending' ? (
                                <button
                                  className={`btn ${styles.confirm}`}
                                  onClick={(event) =>
                                    handleConfirmOrder(
                                      el?.order_id,
                                      el?.order_number,
                                      el?.transaction_id,
                                      event
                                    )
                                  }
                                >
                                  Confirm
                                </button>
                              ) : el?.order_status === 'refunded' ? (
                                <button
                                  className={`btn  ${styles.confirmed_disabled}`}
                                  style={{
                                    cursor: 'auto',
                                  }}
                                >
                                  Refunded
                                </button>
                              ) : (
                                <span className={`${styles.abandoned}`}>
                                  Abandoned
                                </span>
                              )}
                            </div>
                          </td>
                          <td className="">{el?.admin || 'Unknown'}</td>
                          <td className="">{el?.patronage}</td>
                          <td>
                            <div className="dropleft ">
                              <span
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                className={`${styles.action_button} px-3 py-2`}
                                style={{
                                  cursor: 'pointer',
                                }}
                              >
                                {el.order_status !== 'refunded' && (
                                  <img src="/assets/icons/action.svg" alt="" />
                                )}
                                {el.order_status === 'refunded' && (
                                  <img
                                    disabled
                                    src="/assets/icons/action.svg"
                                    alt=""
                                  />
                                )}
                              </span>
                              {el.order_status !== 'refunded' && (
                                <div
                                  className="dropdown-menu drop-left"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <button
                                    type="button"
                                    onClick={() => handleViewClick(el)}
                                    className="rss dropdown-item btn border-0"
                                  >
                                    View
                                  </button>
                                  {el.order_status === 'abandoned' && (
                                    <button
                                      className="rss btn border-0 dropdown-item"
                                      type="button"
                                      onClick={() =>
                                        handleSendReminderClick(el?._id)
                                      }
                                    >
                                      Send Reminder
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!orderData.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={categoryPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>
      <GlobalModal
        open={modalOpen}
        onClose={() => fetchData()}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#FFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span
              onClick={handleClose}
              style={{
                cursor: 'pointer',
              }}
            >
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>
          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="name">Status</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={filterSearch.status}
                    name="status"
                    required
                    onChange={(e) =>
                      handleFilterStatus('status', e.target.value)
                    }
                  >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'completed'}>Completed</MenuItem>
                    <MenuItem value={'pending'}>Pending</MenuItem>
                    <MenuItem value={'refunded'}>Refunded</MenuItem>
                  </Select>
                </div>
                <div className="mb-3">
                  <label htmlFor="name">Order Confirmation</label>
                  <Select
                    labelId="name"
                    id="name"
                    fullWidth
                    size="small"
                    name="order_confirmation"
                    value={filterSearch.order_confirmation}
                    required
                    onChange={(e) =>
                      handleFilterStatus('order_confirmation', e.target.value)
                    }
                  >
                    {/* <MenuItem value={'select'}>Select</MenuItem> */}
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'completed'}>Confirmed</MenuItem>
                    <MenuItem value={'pending'}>Unconfirmed</MenuItem>
                  </Select>
                </div>
                <div className="mb-3">
                  <label htmlFor="name">Payment Method</label>
                  <Select
                    labelId="name"
                    id="name"
                    fullWidth
                    size="small"
                    name="payment_method"
                    value={filterSearch.payment_method}
                    required
                    onChange={(e) =>
                      handleFilterStatus('payment_method', e.target.value)
                    }
                  >
                    {/* <MenuItem value={'select'}>Select</MenuItem> */}
                    <MenuItem value={''}>All</MenuItem>
                    <MenuItem value={'cash'}>Cash</MenuItem>
                    <MenuItem value={'transfer'}>Transfer</MenuItem>
                    <MenuItem value={'wallet'}>Wallet </MenuItem>
                    <MenuItem value={'card'}>Card </MenuItem>
                  </Select>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="date">Date </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex align-items-center"
                    style={{
                      height: '40px',
                    }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{
                        width: '30px',
                      }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
                <label htmlFor="price">Total Cost </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="price"
                        placeholder="Min"
                        size="small"
                        name="min_price"
                        value={filterSearch.min_price}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterStatus('min_price', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex align-items-center"
                    style={{
                      height: '40px',
                    }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{
                        width: '30px',
                      }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        placeholder="Max "
                        size="small"
                        name="max_price"
                        value={filterSearch.max_price}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterStatus('max_price', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        name="min_order_unit"
                        value={filterSearch.min_order_unit}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterStatus('min_order_unit', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        placeholder="Max "
                        size="small"
                        name="max_order_unit"
                        value={filterSearch.max_order_unit}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterStatus('max_order_unit', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={() => fetchData()}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
                {isPending && (
                  <CircularProgress
                    style={{
                      color: 'white',
                      display: 'inline-block',
                    }}
                    size="0.8rem"
                    className="ml-1"
                  />
                )}
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
      <GlobalModal
        open={modalOpenConfirm}
        onClose={handleCloseConfirm}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="500px !important"
        overflow="auto"
        bgcolor="#F5F5F5"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        backgroundColor="#FFFFFF"
        boxShadow="24"
        p="4px"
      >
        <div
          className={`${styles.modal_content}`}
          style={{ backgroundColor: '#FFFFFF' }}
        >
          <form action="" className="mt-0 p-3">
            <div className="text-center">
              <img src="/assets/carticon.svg" alt="" />
            </div>
            <div className="conten text-center mt-3 pt-3">
              <h4>Confirm Customer Order</h4>
              <p>Input Customer after for confirmation of valid order</p>
            </div>
            <div className="my-4 inputs_n_label d-none">
              <label htmlFor="trx">Transaction Id</label>
              <TextField
                disabled
                required
                value={trx_id}
                onChange={handleTrxChange}
                id="trx"
                size="small"
                fullWidth
              />
            </div>
            <div className="my-4 inputs_n_label">
              <label htmlFor="trx">Transaction Id</label>
              <TextField
                disabled
                required
                value={transactionId}
                onChange={handleTrxChange}
                id="trx"
                size="small"
                fullWidth
              />
            </div>
            <div className="mb-4 text-center">
              <button
                type="button"
                onClick={handleCloseConfirm}
                className={`${styles.cancel} btn`}
              >
                Cancel
              </button>
              &nbsp; &nbsp;
              <button
                type="button"
                onClick={() => closeModals()}
                className={`${styles.confirm_order} btn `}
              >
                Confirm Order
                {confirmOrder && (
                  <CircularProgress
                    style={{
                      color: 'white',
                    }}
                    size="1rem"
                  />
                )}
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
      <GlobalModal
        open={modalOpenConfirm1}
        onClose={handleCloseConfirm1}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="500px !important"
        overflow="auto"
        bgcolor="#F5F5F5"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        backgroundColor="#FFFFFF"
        boxShadow="24"
        p="4px"
      >
        <div
          className={`${styles.modal_content}`}
          style={{ backgroundColor: '#FFFFFF' }}
        >
          <form action="" className="mt-0 p-3">
            <div className="text-center">
              <img src="/assets/carticon.svg" alt="" />
            </div>
            <div className="conten text-center mt-3 pt-3">
              <h4 className="mb-3">Order Confirmed </h4>
              {/* <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing  elit. Eius aliquam
                laudantium explicabo pariatur iste dolorem animi vitae error totam. At
                sapiente aliquam accusamus facere veritatis.

              </p> */}
            </div>

            <div className="mb-4 mt-4 text-center">
              <button
                type="button"
                onClick={handleCloseConfirm1}
                className={`${styles.confirm_order} btn btn-block w-100`}
              >
                Back to Order
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
    </>
  );
}
