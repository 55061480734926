/* eslint-disable react-hooks/exhaustive-deps */

// ----------------------------------------------------------------------
import styles from './BrandOwner.module.css';
import TextField from '@mui/material/TextField';
import { Card, Avatar } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
// sections
// mock
import LiveLocation from 'src/pages/LiveLocation';
import { useLayoutEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import GlobalModal from 'src/Modals/GlobalModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import toast from 'react-hot-toast';
import bus from 'src/bus';
import { BallTriangle } from 'react-loader-spinner';
import axiosInstance from 'src/axiosInstance';
import { useEffect, useState } from 'react';
import { shortenText } from 'src/utils';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Pagination } from 'src/components';

// ----------------------------------------------------------------------
import CircularProgress from '@mui/material/CircularProgress';
import { downloadFile2 } from 'src/utils';
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
export default function DashboardAppPage() {
  const [value, setValue] = useState('1');

  const [modalOpen, setModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minOrder, setMinOrder] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxOrder, setMaxOrder] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [exporting, setExporting] = useState(false);

  // pagination
  const [shoppersData, setdata] = useState({});
  const [isPending, setIsPending] = useState(false);
  const [filterPending, setFilterPending] = useState(false);
  const [shoppersPagination, setShoppersPagination] = useState({ total: 0 });
  const notifyError = (err) => toast.error(err);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loadData, setLoadData] = useState(false);
  const [searchvalue, setsearch] = useState('');

  const handleClose = () => {
    setModalOpen(false);
  };

  // const handleSearchChange = (e, newValue) => {
  //   setsearch(e.target.value);
  // };

  const handleMinOrderChange = (value) => {
    setMinOrder(value);
  };

  const handleMinPriceChange = (value) => {
    setMinPrice(value);
  };

  const handleMaxOrderChange = (value) => {
    setMaxOrder(value);
  };

  const handleMaxPriceChange = (value) => {
    setMaxPrice(value);
  };

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvp');

      // Your code here.
    },
    []
  );

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const processLocation = (e) => {
    // console.log(e);
  };

  useEffect(() => {
    fetchData();
  }, [loadData, searchvalue]);

  bus.on('reload', () => {
    fetchData();
  });
  const fetchData = async (page = 1, pageSize = 10, export_type = null) => {
    let filter_payload = {};
    if (searchvalue !== '') {
      filter_payload.search = searchvalue;
    }
    if (minPrice !== '') {
      filter_payload.min_price = minPrice;
    }
    if (minOrder !== '') {
      filter_payload.min_order = minOrder;
    }
    if (maxPrice !== '') {
      filter_payload.max_price = maxPrice;
    }
    if (maxOrder !== '') {
      filter_payload.max_order = maxOrder;
    }
    if (startDate !== '') {
      filter_payload.start_date = startDate;
    }
    if (endDate !== '') {
      filter_payload.end_date = endDate;
    }
    if (export_type) {
      setExporting(true);

      let queryParams = new URLSearchParams({
        page,
        pageSize,
        ...filter_payload,
        export_type,
      }).toString();

      try {
        await downloadFile2(
          'All Shoppers ',
          `/shop/shopper/get?${queryParams}`,
          export_type
        );
      } finally {
        // alert()
        setExporting(false);
      }
      return;
    }
    setIsPending(true);
    axiosInstance
      .get('shop/shopper/get', {
        params: {
          page,
          pageSize,
          export_type,
          ...filter_payload,
        },
      })
      .then((res) => {
        setdata(res.data.data.data);
        setShoppersPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          // notifyError("Unable to fetch data, try again later");
        }
      })
      .finally(() => setIsPending(false));
  };

  const shoppersFilter = () => {
    setFilterPending(true);
    fetchData();
    setLoadData(true);
    handleClose();
  };

  const searchFilter = (e) => {
    setsearch(e.target.value);
  };

  return (
    <>
      <div className="wrapper mt-2 px-2">
        <div className="header d-block d-md-none mb-4">
          <div
            className={`${styles.product_actions} d-md-flex justify-content-between`}
          >
            <div className=" ">
              <h4 className={`mb-0 pb-0`}>Shoppers</h4>
              <p style={{ fontSize: '14px' }} className={`mb-0 mt-2 pb-0`}>
                Monitor shoppers transactions, feedback and reviews
              </p>
            </div>
          </div>
        </div>
        <Box
          style={{
            padding: '0',
          }}
          className="aa"
          sx={{
            width: '100%',
            typography: 'body1',
          }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs">
                <Tab label="All Customers" value="1" />
                <Tab label="Customers Review" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" style={{ paddingLeft: '0' }}>
              <div className={`${styles.filters}`}>
                <div className="row">
                  <div className={`${styles.hold_input} col-6 col-md-3`}>
                    <img
                      src="/assets/icons/search.svg"
                      className="ml-3"
                      alt=""
                    />
                    <input
                      type="text"
                      value={searchvalue}
                      onChange={searchFilter}
                      placeholder="Search"
                      style={{
                        textIndent: '25px',
                        width: '100%',
                      }}
                      className=" form-control"
                    />
                  </div>
                  <div className="col-6 col-md-9   text-right">
                    <button
                      onClick={() => setModalOpen(true)}
                      className="btn  border"
                      style={{
                        backgroundColor: '#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px',
                      }}
                    >
                      <img
                        className=""
                        style={{
                          display: 'inline',
                          width: '28%',
                        }}
                        src="/assets/icons/filterlines.svg"
                        alt=""
                      />
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                        Filters
                      </small>
                    </button>
                  </div>
                </div>
              </div>
              <Card
                style={{ marginTop: '20px', borderRadius: '10px' }}
                className="p-0"
              >
                <div className="p-4 border-bottom">
                  <div className="d-md-flex justify-content-between">
                    <div className="pb-3 pb-md-0">
                      <h5 className="table_desc_header">Shoppers</h5>
                      <small style={{ fontWeight: '200', fontSize: '12px' }}>
                        Customers who purchased from your stores recently
                      </small>
                    </div>
                    <div className="pt-md-2">
                      <div className="dropleft ">
                        <button
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className={`${styles.export_btn} btn m-0`}
                        >
                          <span
                            style={{
                              paddingTop: '8px',
                            }}
                          >
                            <img src="/assets/icons/download.svg" alt="" />{' '}
                            &nbsp;
                            {!exporting && <span>Export</span>}
                            {exporting && (
                              <CircularProgress
                                style={{
                                  color: 'white',
                                  display: 'inline-block',
                                }}
                                size="1rem"
                              />
                            )}
                          </span>
                          &nbsp; &nbsp;
                          <span
                            style={{
                              borderLeft: '1px solid white',
                              display: 'inline-block',
                              height: 'inherit',
                              paddingTop: '8px',
                              paddingLeft: '8px',
                              textAlign: 'center',
                            }}
                          >
                            <img src="/assets/icons/arrowDown.svg" alt="" />
                          </span>
                        </button>

                        <div
                          className="dropdown-menu drop-left"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <button
                            onClick={() =>
                              fetchData(currentPage, 200000, 'csv')
                            }
                            type="button"
                            className="rss dropdown-item btn border-0"
                          >
                            CSV
                          </button>
                          <button
                            onClick={() =>
                              fetchData(currentPage, 200000, 'excel')
                            }
                            type="button"
                            className="rss dropdown-item btn border-0"
                          >
                            Excel
                          </button>
                          <button
                            onClick={() =>
                              fetchData(currentPage, 200000, 'pdf')
                            }
                            type="button"
                            className="rss dropdown-item btn border-0"
                          >
                            PDF
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Scrollbar>
                  <div className={`table-responsive-sm  pt-3 pb-3 pl-0 pr-0`}>
                    <table
                      className={`${styles.table} table-hover table-striped`}
                    >
                      <thead className={`${styles.thead}`}>
                        <tr>
                          <th
                            style={{
                              width: '10%',
                              color: '#4b5563',
                            }}
                            className="text-muted"
                          >
                            S/N
                          </th>
                          <th
                            style={{
                              width: '30%',
                            }}
                            className="text-muted"
                          >
                            Customer Name
                            <img
                              className="pl-2"
                              src="/assets/icons/down.svg"
                              alt=""
                            />
                          </th>
                          <th
                            style={{
                              width: '30%',
                            }}
                            className="text-muted"
                          >
                            Orders
                          </th>
                          <th
                            style={{
                              width: '10%',
                            }}
                            className="text-muted"
                          >
                            Price
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {shoppersData.length > 0 &&
                          !isPending &&
                          shoppersData.map((el, i) => (
                            <tr key={i} className={`${styles.tr} `}>
                              <td>
                                <span className="ml-2">
                                  {(currentPage - 1) * itemsPerPage + i + 1}
                                </span>
                              </td>
                              <td>
                                <Avatar
                                  className={`${styles.avatrr} mr-3 float-md-left`}
                                  alt=""
                                  src={el.image}
                                />
                                <div className=" ">
                                  <p
                                    className="mb-0"
                                    style={{ fontSize: '14px' }}
                                  >
                                    {el.full_name}
                                  </p>
                                  <span style={{ fontSize: '10px' }}>
                                    {el.email}
                                  </span>
                                </div>
                              </td>
                              {/* <td>
                                <BootstrapTooltip title={
                                  <div>
                                    <span>
                                      {el?.location}
                                    </span>
                                  </div>
                              }>
                                <p>
                                  {el.location ? shortenText(el.location) : "---"}
                                </p>
                                </BootstrapTooltip>
                              </td> */}
                              <td>
                                {/* <BootstrapTooltip title={
                                  <div>
                                    Nestle Milo, Pure life table water, <br /> Maggi, Nestcafe
                                  </div>
                                }> */}
                                <span>{el.orders}</span>

                                {/* </BootstrapTooltip> */}
                              </td>
                              <td>
                                ₦{' '}
                                {el.price
                                  ? Number(el.price).toLocaleString()
                                  : 0}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="d-flex justify-content-center">
                    {isPending && (
                      <BallTriangle
                        height={50}
                        width={55}
                        radius={5}
                        color="#af3585"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                      />
                    )}
                  </div>
                  {!shoppersData.length && !isPending && (
                    <div className="alert alert-[#af3585] text-center">
                      <h4>No record found</h4>
                    </div>
                  )}
                </Scrollbar>
                <div>
                  <Pagination
                    currentPage={currentPage}
                    dataPagination={shoppersPagination}
                    itemsPerPage={itemsPerPage}
                    setCurrentPage={setCurrentPage}
                    fetchData={fetchData}
                  />
                </div>
              </Card>
            </TabPanel>
            <TabPanel value="2" style={{ paddingLeft: '0' }}>
              <div
                className={`${styles.hold_input} ${styles.hold_input1} col-md-3 col-6   pl-0`}
              >
                <img src="/assets/icons/search.svg" alt="" />
                <input
                  type="text"
                  placeholder="Search"
                  style={{ textIndent: '25px', width: '100%' }}
                  className=" form-control"
                />
              </div>
              <div className="card mt-4">
                <div className="card-body">
                  <div id="accordion">
                    <div className="alert alert-[#af3585] text-center">
                      <h4>Feature Coming Soon...</h4>
                    </div>
                    {/* <div className={`card mb-2`}>
                      <div className={`card-body ${styles.accordion_header}`}>
                        <div style={{ cursor: 'pointer' }} data-toggle="collapse" href="#collapseOne">
                          <div className={`d-flex justify-content-between`}>
                            <div className=" " style={{ width: '50%' }}>
                              <div className={`d-flex ${styles.review_contents}`}>
                                <div style={{



                                }}>
                                  <img style={{
                                    width: '51px',
                                  }} src="/assets/miloContainer.svg" alt="" />

                                </div>
                                &nbsp;
                                <div style={{



                                }}>
                                  <p style={{
                                    cursor: 'pointer',
                                    display: 'inline-block',
                                    marginBottom: '5px'
                                  }}>
                                    <strong>
                                      Nestle Milo

                                    </strong>
                                  </p>
                                  &nbsp;
                                  &nbsp;
                                  <small>
                                    <img src="/assets/starsgroup.svg" alt="" />
                                  </small>
                                  <p style={{
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '150%',
                                    color: '#6B7280'


                                  }}>
                                    25 Reviews
                                  </p>

                                </div>

                              </div>
                            </div>
                            <div  >
                              <img src="/assets/icons/accordionDown.svg" alt="" />

                            </div>

                          </div>


                        </div>

                      </div>
                      <div id="collapseOne" className="collapse " data-parent="#accordion">
                        <div className="card-body border-top">
                          <div className={`${styles.review_content_header}`}>
                            <img className={`${styles.avatar}`} src="/assets/image1.svg" alt="" />
                            <div className={`${styles.contact_info}`}>
                              <p style={{
                                marginBottom: '0',
                                fontSize: '14px',


                              }}>Ariene McCoy</p>
                              <p style={{
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '150%',
                                color: ' #6B7280',
                                marginBottom: '0'

                              }}>
                                mccoy@yahoo.com
                              </p>

                              <small>
                                <img src="/assets/starsgroup.svg" alt="" />
                              </small>
                              <p className="mb-0  " style={{
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '150%',
                                color: ' #6B7280',
                                marginTop: '0',
                                textAlign: 'right'

                              }}>
                                25-01-2021

                              </p>
                              <div>
                                <p className={`my-3 ${styles.review_content_body}`}>
                                  Amet minim mollit non deserunt ullamco
                                  est sit aliqua dolor do amet sint. Velit officia
                                  consequat duis enim velit mollit. velit moll Exercitation
                                  veniam consequat sunt nostrud amet.

                                </p>


                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`card mb-2`}>
                      <div className={`card-body ${styles.accordion_header}`}>
                        <div style={{ cursor: 'pointer' }} data-toggle="collapse" href="#collapseTwo">
                          <div className={`d-flex justify-content-between`}>
                            <div className=" " style={{ width: '50%' }}>
                              <div className={`d-flex ${styles.review_contents}`}>
                                <div style={{



                                }}>
                                  <img style={{
                                    width: '51px',
                                  }} src="/assets/pureWater.svg" alt="" />

                                </div>
                                &nbsp;
                                <div style={{



                                }}>
                                  <p style={{
                                    cursor: 'pointer',
                                    display: 'inline-block',
                                    marginBottom: '5px'
                                  }}>
                                    <strong>
                                      Pure  Life Water

                                    </strong>
                                  </p>
                                  &nbsp;
                                  &nbsp;
                                  <small>
                                    <img src="/assets/starsgroup.svg" alt="" />
                                  </small>
                                  <p style={{
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '150%',
                                    color: '#6B7280'


                                  }}>
                                    25 Reviews
                                  </p>

                                </div>

                              </div>
                            </div>
                            <div  >
                              <img src="/assets/icons/accordionDown.svg" alt="" />

                            </div>

                          </div>


                        </div>

                      </div>
                      <div id="collapseTwo" className="collapse " data-parent="#accordion">
                        <div className="card-body border-top">
                          <div className={`${styles.review_content_header}`}>
                            <img className={`${styles.avatar}`} src="/assets/image1.svg" alt="" />
                            <div className={`${styles.contact_info}`}>
                              <p style={{
                                marginBottom: '0',
                                fontSize: '14px',


                              }}>Ariene McCoy</p>
                              <p style={{
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '150%',
                                color: ' #6B7280',
                                marginBottom: '0'

                              }}>
                                mccoy@yahoo.com
                              </p>

                              <small>
                                <img src="/assets/starsgroup.svg" alt="" />
                              </small>
                              <p className="mb-0  " style={{
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '150%',
                                color: ' #6B7280',
                                marginTop: '0',
                                textAlign: 'right'

                              }}>
                                25-01-2021

                              </p>
                              <div>
                                <p className={`my-3 ${styles.review_content_body}`}>
                                  Amet minim mollit non deserunt ullamco
                                  est sit aliqua dolor do amet sint. Velit officia
                                  consequat duis enim velit mollit. velit moll Exercitation
                                  veniam consequat sunt nostrud amet.

                                </p>


                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#FFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                {/* <div className="mb-3">
                  <LiveLocation label='Location' passData={processLocation} />



                </div>
                <label htmlFor="name">Date </label>

                <div className="mb d-flex">
                  <div className="" style={{ width: '48%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                      />


                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '14px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '48%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                      />


                    </div>

                  </div>

                </div> */}

                <label htmlFor="price">Price </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="price"
                        placeholder="Min"
                        size="small"
                        value={minPrice}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleMinPriceChange(inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        placeholder="Max "
                        size="small"
                        value={maxPrice}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleMaxPriceChange(inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        value={minOrder}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleMinOrderChange(inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        placeholder="Max "
                        size="small"
                        value={maxOrder}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleMaxOrderChange(inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={shoppersFilter}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
    </>
  );
}
