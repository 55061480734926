import styles from './Outlet.module.css';

import { Card } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import GlobalModal from 'src/Modals/GlobalModal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { forwardRef, useEffect } from 'react';
import { useAuth } from 'src/hooks/useAuth';
import { Pagination } from 'src/components';

// sections
// mock
import { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Select from '@mui/material/Select';
import bus from 'src/bus';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import axios from 'axios';
import moment from 'moment';
import { BallTriangle } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';

// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const TABLE_HEAD = [
  { id: 'customerName', label: 'Customer Name', alignRight: false },
  // { id: 'category', label: 'Category', alignRight: false },
  { id: 'outletManager', label: 'Outlet Manager', alignRight: false },
  { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
  { id: 'outletLocation', label: 'Outlet Location', alignRight: false },
];

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 580,
  overflow: 'auto',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};
export default function OrderPage() {
  const [open, setOpen] = useState(false);
  let [shouldFetch, setShouldFetch] = useState(0);
  const [outletData, setdata] = useState([]);
  const [outletPagination, setOutletPagination] = useState({ total: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { data } = useAuth();

  const [text, setText] = useState({
    title: 'Add Outlet',
    sub: 'Add information about this outlet',
  });
  const [isDeleting, setIsDeleting] = useState(false);
  const [search, setSearch] = useState({
    search: '',
    category: '',
    location: '',
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [endpoint, changeEndPoint] = useState('/outlet/add-outlet');
  const [outlets, setOutLets] = useState({});
  const [searchvalue, setSearchvalue] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [brandPending, setBrandPending] = useState(false);
  const [brandsData, setBrandsData] = useState([]);
  const [dataPagination, setDataPagination] = useState('');
  const notify = (msg = null) =>
    toast.success(msg === null ? 'Store saved .' : msg);
  const notifyError = (err) => toast.error(err);
  const [filterSearch, setFilterSearch] = useState({
    type: '',
    status: '',
    min_price: 0,
    max_price: 0,
    duration: '',
  });
  const [formValues, setFormValues] = useState({
    outlet_name: '',
    outlet_email: '',
    outlet_manager: '',
    phone_number: '',
    outlet_location: '',
    outlet_category: 1,
    outlet_country: 10,
    outlet_state: 10,
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((formValues) => ({
      ...formValues,
      [name]: value,
    }));
  };
  const handleOpen = (data = null) => {
    if (data !== null) {
      setText({
        title: 'Edit Outlet',
        sub: 'Edit information about this outlet',
      });
      changeEndPoint(`/outlet/update-outlet/${data._id}`);
      if (!data.hasOwnProperty('outlet_category')) {
        data.outlet_category = 1;
        data.outlet_country = 10;
        data.outlet_state = 10;
      }
      setFormValues(data);
      setOpen(true);
    } else {
      changeEndPoint('/outlet/add-outlet');
      setText({
        title: 'Add Outlet',
        sub: 'Add information about this outlet',
      });
      setOpen(true);
    }
  };
  const [showDiv, setShowDiv] = useState(data.merchant_type === 'brand');

  const handleCategoryChange = (key, value) => {
    setFilterSearch((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const [category, setCategory] = useState(1);
  const [status, setStatus] = useState('select');
  const handleClose = () => setOpen(false);
  const handleCloseFilterModal = () => setModalOpen(false);

  const handleClose2 = () => {
    setModalOpen(false);
  };

  const [openDialog, setDiologOpen] = useState(false);

  const handleClickOpen = (data) => {
    setOutLets(data);
    changeEndPoint('/outlet/delete-outlet/' + data._id);
    setDiologOpen(true);
  };

  const handleDialogClose = () => {
    setDiologOpen(false);
  };

  useEffect(() => {
    bus.on('open-outlet', () => {
      changeEndPoint('/outlet/add-outlet');
      setOpen(true);
    });

    return () => {
      bus.off('open-outlet', () => {
        changeEndPoint('/outlet/add-outlet');
      });
    };
  }, []);

  useEffect(() => {
    // fetchData()
  }, [shouldFetch, search.search]);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Do something with the form values
    setIsPending(true);
    axiosInstance[formValues.hasOwnProperty('_id') ? 'put' : 'post'](
      endpoint,
      formValues
    )
      .then((res) => {
        if (res.status === 201) {
          notify(res.data.message);
          fetchData(currentPage, itemsPerPage);
          setFormValues({
            outlet_name: '',
            outlet_email: '',
            outlet_manager: '',
            phone_number: '',
            outlet_location: '',
            outlet_category: 1,
            outlet_country: 10,
            outlet_state: 10,
          });
          handleClose();
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to save outlet, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const handleDelete = () => {
    setIsDeleting(true);
    axiosInstance
      .delete(endpoint)
      .then((res) => {
        if (res.status < 300) {
          notify(res.data.message);
          fetchData(currentPage, itemsPerPage);

          handleClose();
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to save outlet, try again later');
        }
      })
      .finally(() => {
        setIsDeleting(false);
        handleDialogClose();
      });
  };

  const processFilter = (e) => {
    const { name, value } = e.target;
    setSearch(() => ({
      ...search,
      [name]: value,
    }));
  };

  const searchFilter = (e) => {
    setSearchvalue(e.target.value);
  };

  const fetchData = (page = 1, perpage = 10) => {
    let filter_payload = {};
    if (filterSearch.min_price) {
      filter_payload.min_price = filterSearch.min_price;
    }
    if (filterSearch.max_price) {
      filter_payload.max_price = filterSearch.max_price;
    }
    if (searchvalue != '') {
      filter_payload.search = searchvalue;
    }
    if (filterSearch.duration != '') {
      filter_payload.duration = filterSearch.duration;
    }
    if (filterSearch.type != '' && filterSearch.type != 'select') {
      filter_payload.type = filterSearch.type;
    }
    if (filterSearch.status != '' && filterSearch.status != 'select') {
      filter_payload.status = filterSearch.status;
    }
    setBrandPending(true);
    handleClose(false);
    setModalOpen(false);
    axiosInstance
      .get('/admin/subscriptions/all?', {
        params: {
          page,
          perpage,
          ...filter_payload,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        if (res.status < 300) {
          setBrandsData(res.data.data.data);
        }
        setDataPagination(res.data.data.pagination);
        setFilterSearch({
          status: '',
          type: '',
          duration: '',
          max_price: '',
          min_price: '',
        });
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch subscriptions, try again later');
        }
      })
      .finally(() => setBrandPending(false));
  };

  useEffect(() => {
    fetchData();
  }, [searchvalue]);

  return (
    <>
      <div className="wrapper pt-2 px-2">
        <div className="header d-block d-md-none mb-4">
          <div className="d-md-flex justify-content-between">
            <div className="mb-3">
              <h4 className={`mb-1 mb-md-0 pb-0`}>Audit</h4>

              <p style={{ fontSize: '14px' }} className={`mb-0 pb-0`}>
                Admin Activities
              </p>
            </div>
          </div>
        </div>

        <div className={`${styles.filters} mt-4`}>
          <div className="d-flex justify-content-between px-0 ">
            <div className={`${styles.hold_input} col-md-4 col-12 px-0  `}>
              <img
                src="/assets/icons/search.svg"
                style={{
                  position: 'absolute',
                  top: '26%',
                  left: '25px',
                }}
                alt=""
              />
              <input
                type="text"
                value={searchvalue}
                onChange={searchFilter}
                placeholder="...Search"
                name="search"
                style={{
                  textIndent: '25px',
                  width: '100%',
                }}
                className=" form-control"
              />
            </div>
            <div className="col-7 text-right">
              <div className="col-md-12 col-6  text-right">
                <button
                  className="btn  border"
                  onClick={() => setModalOpen(true)}
                  style={{
                    backgroundColor: '#fff',
                    border: '1px solid #D0D5DD',
                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    borderRadius: '4px',
                  }}
                >
                  <img
                    className=""
                    style={{ display: 'inline', width: '17px' }}
                    src="/assets/icons/filterlines.svg"
                    alt=""
                  />
                  &nbsp;
                  <small className="d-none d-md-inline-block">Filters</small>
                </button>
              </div>
            </div>
          </div>
        </div>

        <Scrollbar>
          <div className={`card p-0  m-0 mt-5 ${styles.card_table}`}>
            <div className="head_wrapper" style={{ fontSize: '14px' }}>
              <div className={`row px-4 py-3 ${styles.show_control}`}>
                {/* <div className='pt-1 pl-1 '> */}
                {/* <div className="row d-md-flex justify-content-between" > */}
                <div className="col-md-5 pb-3 pb-md-0">
                  <h5 className="table_desc_header">Subscriptions</h5>
                  <small style={{ fontWeight: '200', fontSize: '14px' }}>
                    Brand Subscriptions
                  </small>
                </div>
                <div className="col-md-7 pt-md-2 text-right">
                  <div className="dropleft ">
                    <button
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className={`${styles.export_btn} btn m-0`}
                    >
                      <span
                        style={{
                          paddingTop: '8px',
                        }}
                      >
                        <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                        Export
                      </span>
                      &nbsp; &nbsp;
                      <span
                        style={{
                          borderLeft: '1px solid white',
                          display: 'inline-block',
                          height: 'inherit',
                          paddingTop: '8px',
                          paddingLeft: '8px',
                          textAlign: 'center',
                        }}
                      >
                        <img src="/assets/icons/arrowDown.svg" alt="" />
                      </span>
                    </button>

                    <div
                      className="dropdown-menu drop-left"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <button
                        type="button"
                        className="rss dropdown-item btn border-0"
                      >
                        CSV
                      </button>
                      <button
                        type="button"
                        className="rss dropdown-item btn border-0"
                      >
                        Excel{' '}
                      </button>
                      <button
                        type="button"
                        className="rss dropdown-item btn border-0"
                      >
                        PDF{' '}
                      </button>
                    </div>
                  </div>
                </div>

                {/* </div> */}
                {/* </div> */}
              </div>
            </div>
            <div
              className={`table-responsive ${styles.table_section} border-bottom`}
            >
              <table
                className="table px-0 table-striped table-hover"
                style={{
                  fontSize: '13px',
                  width: '110%',
                }}
              >
                <thead>
                  <tr>
                    <th scope="col">Brand name</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Date Created</th>
                    <th scope="col">Subscription Type</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody className="text-dark">
                  {!brandPending &&
                    brandsData.length >= 1 &&
                    brandsData.map((el, i) => (
                      <tr key={i}>
                        {/* <td>1</td> */}
                        <th>
                          <div className=" ">
                            <img
                              className="float-md-left  mr-2"
                              src={'/assets/outlet1.svg'}
                              alt=""
                              style={{}}
                            />
                            <div className=" ">
                              <p className="mb-0" style={{ fontSize: '14px' }}>
                                <strong>{el?.name}</strong>
                                <br />
                                <small>{el?.ID}</small>
                              </p>
                            </div>
                          </div>
                        </th>
                        <td>{el?.phone_number}</td>
                        <td>{el?.duration}</td>
                        <td>₦ {Number(el?.amount).toLocaleString()}</td>
                        <td>{moment(el?.expiry_date).format('DD/MM/YYYY')}</td>
                        <td>{el?.subscription_type}</td>
                        <td>
                          <span className="">
                            {el?.status === 'completed' ? (
                              <span className="verified_badge">Completed</span>
                            ) : el?.status === 'ongoing' ? (
                              <span className="pending_badge">Ongoing</span>
                            ) : (
                              <span className="not_verified_badge">
                                {el.status}
                              </span>
                            )}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {brandPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!brandsData.length && !brandPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
            <div>
              <Pagination
                currentPage={currentPage}
                dataPagination={dataPagination}
                itemsPerPage={itemsPerPage}
                setCurrentPage={setCurrentPage}
                fetchData={fetchData}
              />
            </div>
          </div>

          {/* {
                (!outletData.length && !isPending)  &&

                <div className="alert alert-[#af3585] text-center">
                  <h4>No record found</h4>
                </div>

              } */}
        </Scrollbar>

        {/* Modals */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={open}>
            <Box sx={style} className={`mobile_modal_size`}>
              <div className={`${styles.modal_content}`}>
                <div className={`${styles.modal_header} mb-0`}>
                  <div>
                    <h5
                      style={{
                        fontSize: '19px',
                        fontWeight: '700',
                      }}
                    >
                      {text.title}
                    </h5>
                    <p
                      style={{
                        fontSize: '11px',
                        color: '#71717A',
                      }}
                    >
                      {text.sub}
                    </p>
                  </div>
                  <div>
                    <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                      <img src="/assets/icons/x.svg" alt="" />
                    </span>
                  </div>
                </div>

                <form onSubmit={handleSubmit} className="mt-0">
                  <div className="mb-3">
                    <label htmlFor="name">Outlet Name</label>
                    <TextField
                      required
                      type="text"
                      value={formValues.outlet_name}
                      name="outlet_name"
                      onChange={handleChange}
                      id="name"
                      placeholder="Oulet 1"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name">Select Category</label>
                    <Select
                      labelId="s"
                      id="s"
                      fullWidth
                      size="small"
                      value={formValues.outlet_category}
                      name="outlet_category"
                      required
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>Kiosk</MenuItem>
                    </Select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="mname">Outlet Manager Name</label>
                    <TextField
                      required
                      value={formValues.outlet_manager}
                      name="outlet_manager"
                      onChange={handleChange}
                      type="text"
                      id="mname"
                      placeholder="Irina Shark"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email">Email</label>
                    <TextField
                      required
                      type="email"
                      value={formValues.outlet_email}
                      name="outlet_email"
                      onChange={handleChange}
                      id="email"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone">Phone Number</label>
                    <TextField
                      required
                      type="phone"
                      value={formValues.phone_number}
                      name="phone_number"
                      onChange={handleChange}
                      id="phone"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="businessDesc">Adddress </label>
                    <TextField
                      required
                      id="businessDesc"
                      placeholder="Outlet address"
                      size="small"
                      value={formValues.outlet_location}
                      name="outlet_location"
                      onChange={handleChange}
                      multiline
                      rows="2"
                      cols="2"
                      fullWidth
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-1">
                      <label htmlFor="s">Country*</label>

                      <Select
                        labelId="s"
                        id="s"
                        fullWidth
                        size="small"
                        required
                        value={formValues.outlet_country}
                        name="outlet_country"
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Nigeria</MenuItem>
                        <MenuItem value={20}>South Africa</MenuItem>
                      </Select>
                    </div>
                    <div className="col-md-6 mb-1">
                      <label htmlFor="state">State*</label>

                      <Select
                        id="state"
                        fullWidth
                        size="small"
                        required
                        value={formValues.outlet_state}
                        name="outlet_state"
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Lagos</MenuItem>
                        <MenuItem value={20}>Abuja</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div className="mb-1 mt-4">
                    {!isPending && (
                      <button
                        type="submit"
                        className={`${styles.modal_btn} btn btn-block`}
                      >
                        Save
                      </button>
                    )}
                    {isPending && (
                      <button
                        disabled
                        className={`btn btn-block ${styles.modal_btn}`}
                      >
                        Submitting &nbsp;
                        {isPending && (
                          <CircularProgress
                            style={{ color: 'white' }}
                            size="1rem"
                          />
                        )}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </Box>
          </Fade>
        </Modal>
        {/* <GlobalModal
        open={modalOpen}
        onClose={handleClose2}
        position='absolute'
        top= '50%'
        left= '50%'
        transform= 'translate(-50%, -50%)'
        width= '740px !important'
        overflow='auto'
        bgcolor='#F5F5F5'
        border= '1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow= '24'
        p='25px'
      >
        <div  className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
          <h5 style={{
            fontSize: '19px',
            fontWeight: '700',

          }}>Filter</h5>
            <span onClick={handleClose2} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>


          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="loc">Location</label>
                  <TextField
                    type='text'
                    id="loc"
                    placeholder="Enter Location"
                    name="location"
                    value={search.location}
                    onChange={processFilter}


                    size="small"
                    fullWidth

                  />


                </div>

              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="cat">Category</label>
                  <Select
                      labelId="s"
                      id="cat"
                      fullWidth
                      size="small"
                      value={search.category}
                      name="category"

                      onChange={processFilter}
                    >

                      <MenuItem value={1}>Select Category</MenuItem>
                  </Select>


              </div>
            </div>
            <div className="mb-4">
            <button type="button" onClick={handleClose2} className={`${styles.modal_btn} btn btn-block`}>
              Proceed

            </button>


            </div>

          </form>


        </div>


      </GlobalModal> */}

        <GlobalModal
          open={modalOpen}
          onClose={handleCloseFilterModal}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="740px !important"
          overflow="auto"
          bgcolor="#FFFFFF"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          p="25px"
        >
          <div className={`${styles.modal_content}`}>
            <div className={`${styles.modal_header} mb-0`}>
              <h5
                style={{
                  fontSize: '19px',
                  fontWeight: '700',
                }}
              >
                Filter
              </h5>
              <span
                onClick={handleCloseFilterModal}
                style={{ cursor: 'pointer' }}
              >
                <img src="/assets/icons/x.svg" alt="" />
              </span>
            </div>

            <form action="" className="mt-0">
              <div className="row">
                <div className="col-md-6 mb-1">
                  <label htmlFor="price">Duration </label>
                  <div className="mb-3">
                    <input
                      value={filterSearch.duration}
                      name="duration"
                      className="form-control"
                      required
                      id="price"
                      width={'100%'}
                      placeholder="Enter Duration"
                      type="text"
                      onChange={(e) =>
                        handleCategoryChange('duration', e.target.value)
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name">Status </label>
                    <Select
                      labelId="s"
                      id="s"
                      fullWidth
                      size="small"
                      value={filterSearch.status}
                      name="status"
                      required
                      onChange={(e) =>
                        handleCategoryChange('transaction_type', e.target.value)
                      }
                    >
                      <MenuItem value="select">Select</MenuItem>
                      <MenuItem value="ongoing">Ongoing</MenuItem>
                      <MenuItem value="completed">Completed</MenuItem>
                      {/* <MenuItem value='Supermarket'>Supermarket</MenuItem>
                    <MenuItem value='E-commerce store'>E-commerce store</MenuItem> */}
                    </Select>
                  </div>
                </div>
                <div className="col-md-6 mb-1">
                  <label htmlFor="price">Amount </label>
                  <div className=" d-flex">
                    <div className="" style={{ width: '100%' }}>
                      <div className="mb-3">
                        <TextField
                          required
                          type="number"
                          id="price"
                          placeholder="Min"
                          size="small"
                          value={filterSearch.min_price}
                          name="min_price"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              inputValue === '' ||
                              parseFloat(inputValue) >= 0
                            ) {
                              handleCategoryChange('min_price', inputValue);
                            }
                          }}
                          inputProps={{
                            inputMode: 'decimal',
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === '-' ||
                              e.key === 'e' ||
                              e.key === '.'
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="px-1 d-flex   align-items-center "
                      style={{ height: '40px' }}
                    >
                      <img
                        src="/assets/icons/line.svg"
                        style={{ width: '30px' }}
                        alt=""
                      />
                    </div>
                    <div className="" style={{ width: '100%' }}>
                      <div className="mb-3">
                        <TextField
                          required
                          type="number"
                          placeholder="Max "
                          size="small"
                          value={filterSearch.max_price}
                          name="max_price"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              inputValue === '' ||
                              parseFloat(inputValue) >= 0
                            ) {
                              handleCategoryChange('max_price', inputValue);
                            }
                          }}
                          inputProps={{
                            inputMode: 'decimal',
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === '-' ||
                              e.key === 'e' ||
                              e.key === '.'
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="name">Subscription Type</label>
                    <Select
                      labelId="d"
                      id="d"
                      fullWidth
                      size="small"
                      value={filterSearch.type}
                      name="type"
                      required
                      onChange={(e) =>
                        handleCategoryChange('type', e.target.value)
                      }
                    >
                      <MenuItem value="select">All</MenuItem>
                      <MenuItem value="Incremental Insight">
                        Incremental Insight
                      </MenuItem>
                      <MenuItem value="Ads Subscription">
                        Ads Subscription
                      </MenuItem>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <button
                  type="button"
                  onClick={() => fetchData()}
                  className={`${styles.modal_btn} btn btn-block`}
                >
                  Proceed
                </button>
              </div>
            </form>
          </div>
        </GlobalModal>

        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleDialogClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="card border-0">
            <div className="card-body border-0 ">
              <div className={`${styles.customized_row}`}>
                <div className="text-center">
                  <img
                    style={{ width: '40px' }}
                    src="/assets/icons/caution.svg"
                    alt=""
                  />
                </div>
                <div>
                  <h5>Delete {outlets.outlet_name} outlet</h5>
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#6B7280',
                    }}
                  >
                    Are you sure you want to delete this outlet? All your data
                    will be permanently removed from our servers forever. This
                    action cannot be undone.
                  </p>
                </div>
              </div>

              <DialogActions>
                <Button
                  className={`${styles.cancel_delete}`}
                  onClick={handleDialogClose}
                >
                  Cancel
                </Button>
                {!isDeleting && (
                  <Button
                    className={`${styles.delete_product}`}
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                )}

                {isDeleting && (
                  <button disabled className={`btn ${styles.delete_product}`}>
                    {isDeleting && (
                      <CircularProgress
                        style={{ color: 'white' }}
                        size="1rem"
                      />
                    )}
                  </button>
                )}
              </DialogActions>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}
