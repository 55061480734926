import styles from '../../Sales.module.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 90,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "6px 0 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const WithdrawalLimitsCard = ({
  title = 'For every 3 referrals',
  amount = 0,
  frequency = '',
  boldCount = '',
  smallCount = '',
  lg = 'col-lg-10',
  options = ['edit', 'delete'],
  onEdit = () => {},
  onDelete = () => {}
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    handleClose();
    if (option === 'edit') {
      onEdit();
    } else if (option === 'delete') {
      onDelete();
    }
  };

  return (
    <div className={`${lg} col-sm-12 m-2 mt-4 p-0`}>
      <div className={styles.card}>
        <div className="card-body">
          <div style={{fontSize: '14px', fontWeight: 'lighter'}}>
            <small 
              className="d-inline mb-1"
              style={{fontWeight: 'lighter'}}
            >
              {title}
            </small>
          </div>
          <div className="d-flex justify-content-betweeen">
            <h5 className="d-inline m-0" style={{fontSize: '18px', fontWeight: '700'}}>₦{Number(amount).toLocaleString()}/{frequency.charAt(0).toUpperCase() + frequency.slice(1)}</h5>
            <div style={{ minWidth: 'fit-content', lineHeight: '0', background: '8C2D9129' }} class="ml-auto">
              <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="none"
                className="p-0 m-0 pr-2"
                style={{ minWidth: 'fit-content', lineHeight: '0', }}
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
              ></Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{ 'aria-labelledby': 'demo-customized-button' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <div className="pl-3">
                  {options.map((option) => (
                    <div key={option} className="pfy-1" onClick={() => handleOptionClick(option)}>
                      <label style={{fontSize: '12px', cursor: 'pointer'}}>
                        {option.charAt(0).toUpperCase() + option.slice(1)}
                      </label>
                    </div>
                  ))}
                </div>
              </StyledMenu>
            </div>
          </div>
          <div className={styles.numbers}>
            <small style={{fontSize: '13px', fontWeight: '600'}}>
                ₦{Number(boldCount).toLocaleString()} {" - "} ₦{Number(smallCount).toLocaleString()}
              </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalLimitsCard;
