import GoogleMapReact from 'google-map-react';
import { useRef, useState, useEffect } from 'react';
import { useApiKeys } from 'src/hooks/useApiKeys';
import TextField from '@mui/material/TextField';
import styles from '../Outlet.module.css';
import GlobalModal from 'src/Modals/GlobalModal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';
import axiosInstance from 'src/axiosInstance';
import { BallTriangle } from 'react-loader-spinner';
import LiveLocation from 'src/pages/LiveLocation';
import bus from 'src/bus';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export default function Map({ locations }) {
  const defaultLocation = locations.length > 0 ? locations[0] : null;
  const [center, setCenter] = useState(defaultLocation);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const mapRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isPending, setIsPending] = useState('');
  const [filterSearch, setFilterSearch] = useState({
    location: '',
    category: 'select',
    status: 'select',
    dateStart: '',
    dateEnd: '',
  });
  const notifyError = (err) => toast.error(err);
  const [data, setData] = useState([]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilterSearch((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const APiKeys = useApiKeys();
  const mapStyles = [
    {
      featureType: 'all',
      elementType: 'all',
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: '0',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        {
          hue: 'gray',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ];
  const handleMarkerClick = (location) => {
    setCenter({ lat: location.lat, lng: location.lng });
  };

  const handleProcess = (data) => {
    setShow(false);
    handleMarkerClick(data);
    const map = mapRef.current;
    if (map) {
      mapRef.current = {
        ...map,
        center: {
          lat: data.lat,
          lng: data.lng,
        },
      };
    }
  };

  const handleClose = () => {
    bus.emit('filter_set', filterSearch);
    setModalOpen(false);
  };
  const fetchData = async () => {
    if (search === '' && !show) return;
    setIsPending(true);

    try {
      const res = await axiosInstance.get(
        `/admin/stores?perpage=1000&search=` + search
      );

      setData(res.data.data.data);
      setShow(true);

      setIsPending(false);
    } catch (err) {
      setIsPending(false);
      notifyError(err.response.data.message);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  const processLocation = (e) => {
    const value = {
      target: {
        name: 'location',
        value: e.formatted_address,
      },
    };
    handleFilterChange(value);
  };

  useEffect(() => {
    const obValue = {
      target: {
        name: 'dateFrom',
        value: startDate,
      },
    };
    const obValue1 = {
      target: {
        name: 'dateTo',
        value: endDate,
      },
    };

    handleFilterChange(obValue);
    handleFilterChange(obValue1);
  }, [startDate, endDate]);

  return (
    <div
      className="border border-radius p-2 mt-3"
      style={{ height: '700px', width: '100%', position: 'relative' }}
    >
      <div className={`${styles.myMapRow} d-flex w-100 px-3`}>
        <div className="pr-2 w-100">
          <div className="map_filter">
            <div>
              <TextField
                id="businessPhone"
                placeholder="Search Store"
                size="small"
                value={search}
                type="text"
                onChange={(e) => setSearch(e.target.value)}
                fullWidth
              />
            </div>
            {data.length > 0 && !isPending && (
              <div className="info text-muted">
                {data.map((el, i) => (
                  <div className="p-2 card border-0" key={i}>
                    <div
                      className="card  mt-3"
                      style={{ borderRadius: '14px' }}
                    >
                      <div className="card-body">
                        <p>{el.store_name}</p>
                        <p className="text-center">{el.store_address}</p>
                        <div>
                          <img
                            src={el.business_image}
                            alt=""
                            style={{ width: '100%' }}
                          />
                        </div>
                        <button
                          onClick={() => {
                            handleProcess({
                              lat: Number(el.latitude),
                              lng: Number(el.longitude),
                              name: el.store_name,
                            });
                          }}
                          className="btn-block btn btn_primary btn-lg"
                        >
                          View Store
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="py-3 d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
          </div>
        </div>
        <div className="pl-2 w-100">
          <div className="text-right pt-2">
            <button
              className="btn border"
              onClick={() => setModalOpen(true)}
              style={{
                backgroundColor: '#fff',
                border: '1px solid #D0D5DD',
                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                borderRadius: '4px',
              }}
            >
              <img
                className=""
                style={{ display: 'inline', width: '25px' }}
                src="/assets/icons/filterlines.svg"
                alt=""
              />
              &nbsp;
              <small className="d-none d-md-inline-block">Filters</small>
            </button>
          </div>
        </div>
      </div>

      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px"
        maxWidth="95% !important"
        overflow="auto"
        bgcolor="#FFFFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form action="" className="mt-0 w-100">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <LiveLocation label="Location" passData={processLocation} />
                </div>

                <div className="mb-3">
                  <label htmlFor="name">Status</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    value={filterSearch.status}
                    onChange={handleFilterChange}
                    name="status"
                    size="small"
                    required
                    defaultValue="select"
                  >
                    <MenuItem value="select">Select Status</MenuItem>
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="verified">Verified</MenuItem>
                    <MenuItem value="not-verified">Not Verified</MenuItem>
                  </Select>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="name">Category</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={filterSearch.category}
                    onChange={handleFilterChange}
                    name="category"
                    required
                    defaultValue="select"
                  >
                    <MenuItem value="select">Select Category</MenuItem>
                    <MenuItem value="Kiosk">Kiosk</MenuItem>
                    <MenuItem value="Neighborhood store">
                      Neighborhood store
                    </MenuItem>
                    <MenuItem value="Supermarket">Supermarket</MenuItem>
                    <MenuItem value="E-commerce store">
                      E-commerce store
                    </MenuItem>
                  </Select>
                </div>

                <label htmlFor="price">Date </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="name">Product</label> <div></div>
                  {productLists.map((el, i) => (
                    <div className="categories mr-2 mb-3" key={i}>
                      <input onClick={(e) => handleProductChange(e, el.name)} type="checkbox" value={el.name} name="" className="myCheckbox" />
                      &nbsp;
                      {el.name}


                    </div>

                  ))}


                </div>
              </div> */}
            </div>
            <div className="mt-3">
              <button
                onClick={handleClose}
                type="button"
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed{' '}
                {isPending && (
                  <CircularProgress style={{ color: '#fff' }} size="1rem" />
                )}
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>

      <GoogleMapReact
        ref={mapRef}
        bootstrapURLKeys={{ key: APiKeys.GOOGLE_MAPS.KEY }}
        defaultCenter={
          defaultLocation
            ? { lat: defaultLocation.lat, lng: defaultLocation.lng }
            : null
        }
        center={center}
        defaultZoom={13}
        options={{
          styles: mapStyles,
          backgroundColor: 'black',
        }}
      >
        {locations.map((location, index) => (
          <LocationMarker
            key={index}
            lat={location.lat}
            lng={location.lng}
            name={location.name}
            address={location.store_address}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
}

// Define a custom component for the marker
function LocationMarker({ name, address }) {
  return (
    <div
      style={{
        position: 'relative',
        color: 'red',
        fontWeight: 'bold',
        fontSize: '14px',
      }}
    >
      <img
        src="/assets/icons/mapIndicator.svg"
        alt=""
        data-tooltip-id="my-tooltip"
        data-tooltip-content={address}
      />
      <div>{name}</div>
      <Tooltip id="my-tooltip" place="top" />
    </div>
  );
}
