

// ----------------------------------------------------------------------
import styles from "../BrandOwner.module.css"
import { useState } from "react"
import * as React from 'react';
import bus from 'src/bus';

// sections
// mock
import GlobalModal from "src/Modals/GlobalModal";

import { useEffect } from "react";


export default function AudienceModal({ closeModal }) {
  
  const [modalOpenC, setModalOpenC] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;

    if (event.target.checked) {
      // Check if the value already exists in the array
      if (!selectedValues.includes(value)) {
        // Add the value to the array
        setSelectedValues([...selectedValues, value]);
      }
    } else {
      // Remove the value from the array
      setSelectedValues(selectedValues.filter((val) => val !== value));
    }
  };

  const handleCloseC = () => {
    setModalOpenC(false);
  };
  

  
  useEffect(() => {
    bus.on('open-audience', (val) => {
      setModalOpenC(true)
    });

    return () => {

    };
  }, []);
  
  
  
  const proceed = () => {
    setModalOpenC(false);
    bus.emit('selected-audience', selectedValues);


  }
  
 
  






  return (
    <>
    
      <GlobalModal
        open={modalOpenC}
        onClose={handleCloseC}
        position='absolute'
        top='50%'
        left='50%'
        transform='translate(-50%, -50%)'
        width='740px !important'
        overflow='auto'
        bgcolor='#F5F5F5'
        border='1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow='24'
        background='#FFFFFF'
        p='25px'
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5 style={{
              fontSize: '19px',
              fontWeight: '700',

            }}>Select Audience</h5>
            <span onClick={handleCloseC} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>


          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="loc">Select Customers</label>
                  <div className="products mt-2">
                  {/*  { name: 'New Shopers' }, {name:'New Merchant'} */}
                  {[{ name: 'Merchants ' }, { name: 'Shoppers' }].map((el, i) => (
                      <div className="categories mr-2 mb-3" key={i}>
                        <input 
                          onChange={handleCheckboxChange} 
                          type="checkbox" 
                          value={el.name} 
                          name="" 
                          className="myCheckbox" 
                        />
                        &nbsp;
                        {el.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button 
                type="button" 
                onClick={proceed} 
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
    </>
  )
}
