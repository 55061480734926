

// ----------------------------------------------------------------------
import styles from "../BrandOwner.module.css"
import { useState } from "react"
import TextField from '@mui/material/TextField';
import * as React from 'react';
import bus from 'src/bus';
import LiveLocation from "src/pages/LiveLocation";
// sections
// mock
import GlobalModal from "src/Modals/GlobalModal";
import Menu from '@mui/material/Menu';
import CircularProgress from '@mui/material/CircularProgress';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect } from "react";
import axiosInstance from "src/axiosInstance"
import toast from 'react-hot-toast';
import LiveSelect from 'react-select';


const style1 = {
    position: 'absolute',
    width: '500px',
    height: '235px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#F5F5F5',
    border: '1px solid #F5F5F5',
    borderRadius: '5px',
    boxShadow: 24,
    background: '#FFFFFF',
    p: 3,
};
const style3 = {
    position: 'absolute',
    width: '650px',
    height: 'auto',
    overflow: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#F5F5F5',
    border: '1px solid #F5F5F5',
    borderRadius: '5px',
    boxShadow: 24,
    background: '#FFFFFF',
    maxHeight: '650px',
    p: 4,
};


// ptvp=PAGE TO VIEW (SALES)

export default function VoucherModal({ closeModal }) {
    const [openGoToCart, setopenGoToCart] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startDate1, setStartDate1] = useState(null);
    const [endDate1, setEndDate1] = useState(null);
    const [allProducts, setAllProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    const [selectedProductNames, setSelectedProductNames] = useState([]);
    const [open1, setOpen1] = useState(false);
    const notifyError = (err) => toast.error(err);
    const notifyToast = (err) => toast.success(err);
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];
    const [selectedOption, setSelectedOption] = useState(null);

    const [voucher, setVoucher] = useState({
        input_code: "",
        price: "",
        start: "",
        end: "",
        discount_occurence: "",
    })
    const [audience, setAudience] = useState({
        location: "",
        product: "",
        customer_type: "",
        start: "",
        end: "",
        price_min: "",
        price_max: "",
        order_amount_min: "",
        order_amount_max: "",
        order_unit_min: "",
        order_unit_max: "",


    })
    const [selectedLocationName, setSelectedLocationName] = useState('');
    const [modalOpenC, setModalOpenC] = useState(false);

    const handleGoToCartClose = () => {
        setopenGoToCart(false);
        handleClose1()
    }
    // const handleProductChange = (e) => {
    //     setAudience((prev) =>{
    //         return {
    //             ...prev,
    //             product: e.value
    //         }
    //     })
    // }

    const handleProductChange = (selectedOption) => {
        setSelectedProduct(selectedOption);
        setSelectedProductIds((prevIds) => [...prevIds, selectedOption.value]);
        setSelectedProductNames((prevNames) => [...prevNames, selectedOption.label]);
    };

    const handleCloseC = () => {
        setModalOpenC(false);
    };
    const handleClose1 = () => {
        setOpen1(false);
        setVoucher({
            input_code: "",
            price: "",
            start: "",
            end: "",
            discount_occurence: "",
        })
        setAudience({
            location: "",
            product: "",
            customer_type: "",
            start: "",
            end: "",
            price_min: "",
            price_max: "",
            order_amount_min: "",
            order_amount_max: "",
            order_unit_min: "",
            order_unit_max: "",
        })
        setStartDate(null)
        setStartDate1(null)
        setEndDate(null)
        setEndDate1(null)
        setSelectedProductIds([])
        setSelectedProductNames([])
        setSelectedLocationName('')

    };
    const fetchProducts = () => {
        axiosInstance.get(`/admin/product`)
            .then(res => {
                setAllProducts(res.data.data)


            }).catch(err => {

                try {
                    notifyError(err.response.data.message);

                } catch (error) {
                    notifyError("Unable to save, try again later");

                }

            }).finally(() => setIsPending(false))
    }
    const voucherSubmit = (e) => {
        e.preventDefault()
        setIsPending(true);
        let payload = {
            input_code: voucher.input_code,
            price: voucher.price,
            date: {
                start: voucher.start,
                end: voucher.end,

            },
            discount_occurence: voucher.discount_occurence,
            selected_audience: {
                location: selectedLocationName,
                customer_type: audience.customer_type,
                product: selectedProductIds,
                // product: audience.product,
                date: {
                    start: audience.start,
                    end: audience.end,

                },
                price: {
                    min: audience.price_min,
                    max: audience.price_max,
                },
                order_amount: {
                    min: audience.order_amount_min,
                    max: audience.order_amount_max,
                },
                order_unit: {
                    min: audience.order_unit_min,
                    max: audience.order_unit_max,
                },


            }
        }


        axiosInstance.post(`/admin/voucher/create`, payload)
            .then(res => {
                if (res.status < 300) {
                    notifyToast('Voucher successfully added')
                    bus.emit('reload');
                    setVoucher({
                        input_code: "",
                        price: "",
                        start: "",
                        end: "",
                        discount_occurence: "",
                    })
                    setAudience({
                        location: "",
                        product: "",
                        customer_type: "",
                        start: "",
                        end: "",
                        price_min: "",
                        price_max: "",
                        order_amount_min: "",
                        order_amount_max: "",
                        order_unit_min: "",
                        order_unit_max: "",
                    })
                    setStartDate(null)
                    setStartDate1(null)
                    setEndDate(null)
                    setEndDate1(null)
                    setOpen1(false);
                    setSelectedProductIds([])
                    setSelectedProductNames([])
                    setSelectedLocationName()
                    setopenGoToCart(true)
                } else {
                    notifyToast(res.data.message)
                }

            }).catch(err => {

                try {
                    notifyError(err.response.data.message);

                } catch (error) {
                    notifyError("Unable to save, try again later");

                }

            }).finally(() => setIsPending(false))
    }




    useEffect(() => {
        fetchProducts()
        bus.on('open-voucher', () => {
            setOpen1(true)

        });

        return () => {

        };
    }, []);


    useEffect(() => {
        setVoucher((prev) => {
            return {
                ...prev,
                start: startDate,
                end: endDate,
            }
        })

    }, [startDate, endDate])
    useEffect(() => {
        setAudience((prev) => {
            return {
                ...prev,
                start: startDate1,
                end: endDate1,
            }
        })

    }, [startDate1, endDate1])

    const handleVoucher = (e) => {
        setVoucher((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }
    const handleVoucher1 = (e) => {

        setAudience((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value


            }
        })
    }

    const processLocation = (locationData) => {
        setSelectedLocationName(locationData.formatted_address);
    };

    // const processLocation = (e) => {
    //     setSelectedLocation(e.value)
    //     setAudience((prev) => {
    //         return {
    //             ...prev,
    //             'location': e.value
    //         }
    //     })
    // }







    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open1}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableEnforceFocus
            >
                <Fade in={open1}>
                    <Box className={`mobile_modal_size`} sx={style3}>
                        <div className={`${styles.modal_content}`}>
                            <div className={`${styles.modal_header}`}>
                                <h3>Voucher  </h3>
                                <span onClick={handleClose1} style={{ cursor: 'pointer' }}>
                                    <img src="/assets/icons/x.svg" alt="" />

                                </span>

                            </div>
                            <p>
                                Create Discounts for Customers
                            </p>


                            <form onSubmit={voucherSubmit}>

                                <div>
                                    <label className={``} htmlFor="pdb">Create Code    </label>
                                    <div className="row">
                                        <div className="col-6 mb-3">
                                            <input type="text" value={voucher.input_code} name="input_code" onChange={handleVoucher} className="form-control" placeholder="Input Code" />

                                        </div>
                                        <div className="col-6 mb-3 pl-0">
                                            <div className="d-flex align-items-center" style={{ height: '100%' }}>
                                                <small>{voucher.input_code}</small>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className={``} htmlFor="pdb">Discount Price    </label>
                                        <TextField
                                            id="pdb"
                                            type="text"
                                            placeholder="Input Amount"
                                            value={voucher.price} name="price" onChange={handleVoucher}
                                            size="small"
                                            fullWidth

                                        />
                                    </div>
                                    <div className="mb-1">
                                        <div className="row ">
                                            <div className="col-4">
                                                <label className={` pt-2`} htmlFor="discountPrice">
                                                    Duration of Discount
                                                </label>

                                            </div>
                                            <div className="col-8  ">
                                                <div className="mb-3 d-flex">
                                                    <div className="" style={{ width: '45%' }}>
                                                        <div className="mb-3">
                                                            <DatePicker
                                                                showIcon
                                                                selected={startDate}
                                                                onChange={(date) => setStartDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                className="form-control"
                                                                placeholderText="Start"
                                                            />


                                                        </div>

                                                    </div>
                                                    <div className="px-1 d-flex   align-items-center " style={{ height: '40px', width: '10%' }}>
                                                        <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                                                    </div>
                                                    <div className="" style={{ width: '45%' }}>
                                                        <div className="mb-3">
                                                            <DatePicker
                                                                showIcon
                                                                selected={endDate}
                                                                onChange={(date) => setEndDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                className="form-control"
                                                                placeholderText="End"
                                                            />

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>



                                        </div>

                                    </div>
                                    <div className="mb-3">
                                        <label className={`label`} htmlFor="do">Discount Occurrence*    </label>
                                        <TextField
                                            id="do"
                                            type="text"
                                            placeholder="Input discount occurance"
                                            value={voucher.discount_occurence} name="discount_occurence" onChange={handleVoucher}
                                            size="small"
                                            fullWidth

                                        />
                                    </div>


                                    <div className={`mb-3 ${styles.push_notification_icon}`}>
                                        <label className={`${styles.modal_product_title}`} htmlFor="push">Push Notification </label>
                                        <TextField
                                            disabled
                                            id="push"
                                            type="text"
                                            placeholder=" Select Target Audience"
                                            size="small"
                                            fullWidth


                                        />
                                        <img onClick={() => setModalOpenC(true)} className="cursor_pointer" src="/assets/icons/ShapeRight.svg" alt="" />
                                    </div>


                                    <div className="mb-3">
                                        {isPending && <button disabled className={`btn btn-block modal_btn`}>
                                            Submitting &nbsp;
                                            {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />

                                            }
                                        </button>
                                        }
                                        {!isPending &&
                                            <button type="submit" className={`modal_btn btn btn-block`}>
                                                Save

                                            </button>


                                        }
                                    </div>

                                </div>


                            </form>


                        </div>

                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openGoToCart}
                onClose={handleGoToCartClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableEnforceFocus
            >
                <Fade in={openGoToCart}>
                    <Box className={`mobile_modal_size ${styles.modal_bg}`} sx={style1}>
                        <div className={`text-center`}>
                            <img src="/assets/icons/Illustration.svg" alt="" />
                            <h5 className="mt-3">Promo/Discount created</h5>
                            <p className="text-center" style={{ color: '#6B7280', fontSize: '14px' }}>
                                {/* You will receive an in-app notification and mail once your <br /> request has been approved */}
                                This Promo/Discount has been successfully created

                            </p>
                            <div className={`text-center  pt-2`}>
                                <button className={`${styles.go_to} d-block w-100 `} onClick={handleGoToCartClose}>

                                    Confirm
                                </button>

                            </div>

                        </div>



                    </Box>
                </Fade>
            </Modal>
            {/* Original Modal --  */}


            <GlobalModal
                open={modalOpenC}
                onClose={handleCloseC}
                position='absolute'
                top='50%'
                left='50%'
                transform='translate(-50%, -50%)'
                width='740px !important'
                overflow='auto'
                bgcolor='#F5F5F5'
                border='1px solid #F5F5F5'
                borderRadius='5px'
                boxShadow='24'
                background='#FFFFFF'
                p='25px'
            >
                <div className={`${styles.modal_content}`}>
                    <div className={`${styles.modal_header} mb-0`}>
                        <h5 style={{
                            fontSize: '19px',
                            fontWeight: '700',

                        }}>Select Audience</h5>
                        <span onClick={handleCloseC} style={{ cursor: 'pointer' }}>
                            <img src="/assets/icons/x.svg" alt="" />

                        </span>

                    </div>


                    <form action="" className="mt-0 form_label" >
                        <div className="row">
                            <div className="col-md-6 mb-1">
                                <div className="mb-3">
                                    <LiveLocation 
                                        label='Location' 
                                        passData={processLocation}
                                    />
                                    {
                                        selectedLocationName !== '' &&
                                        <div className="products mt-2">
                                            <div className={`${styles.product_selected} mb-1`}>
                                                <div className="d-flex justify-content-between">
                                                <span>{selectedLocationName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="mb-2">
                                    <div>
                                        <label htmlFor="loc">Product</label>
                                        <LiveSelect
                                            defaultValue={selectedOption}
                                            onChange={handleProductChange}
                                            options={allProducts.map((option) => ({
                                                value: option._id,
                                                label: option.product_name,
                                            }))}
                                        />
                                        <div className="products mt-2">
                                            {selectedProductNames.length > 0 && selectedProductNames.map((el, i) => (
                                            <div className={`${styles.product_selected} mb-1`}>
                                                <div className="d-flex justify-content-between">
                                                <span key={i}>{el}</span>
                                                {/* &nbsp;
                                            &nbsp;
                                            <span style={{ cursor: 'pointer' }} onClick={() => removeProductFromList(i)}>
                                                <img style={{ width: '5px' }} src="/assets/icons/x.svg" alt="" />
                                            </span> */}
                                                </div>
                                            </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label htmlFor="loc">Select Customers</label> <br />
                                        <div className="categories mr-2 mt-2 mb-3">
                                            <input
                                                type="radio"
                                                value="users"
                                                name="customer_type"
                                                onChange={handleVoucher1}
                                                checked={audience.customer_type === "users"}
                                                className="myCheckbox"
                                            />
                                            &nbsp;
                                            Users
                                            </div>
                                            <div className="categories mr-2 mt-2 mb-3">
                                            <input
                                                type="radio"
                                                value="brand_owners"
                                                name="customer_type"
                                                onChange={handleVoucher1}
                                                checked={audience.customer_type === "brand_owners"}
                                                className="myCheckbox"
                                            />
                                            &nbsp;
                                            Brand Owner
                                            </div>
                                            <div className="categories mr-2 mt-2 mb-3">
                                            <input
                                                type="radio"
                                                value="shop_owners"
                                                name="customer_type"
                                                onChange={handleVoucher1}
                                                checked={audience.customer_type === "shop_owners"}
                                                className="myCheckbox"
                                            />
                                            &nbsp;
                                            Merchant
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <div className="col-md-6 mb-1">


                                <div className="mb-3">
                                    <label htmlFor="customers">Date</label>
                                    <div className="mb-3 d-flex">
                                        <div className="" style={{ width: '45%' }}>
                                            <div className="mb-3">
                                                <DatePicker
                                                    showIcon
                                                    selected={startDate1}
                                                    onChange={(date) => setStartDate1(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control"
                                                    placeholderText="Start"
                                                />


                                            </div>

                                        </div>
                                        <div className="px-1 d-flex   align-items-center " style={{ height: '40px', width: '10%' }}>
                                            <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                                        </div>
                                        <div className="" style={{ width: '45%' }}>
                                            <div className="mb-3">
                                                <DatePicker
                                                    showIcon
                                                    selected={endDate1}
                                                    onChange={(date) => setEndDate1(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control"
                                                    placeholderText="End"
                                                />

                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div className="mb-3">
                                    <label htmlFor="ounit">Price </label>
                                    <div className=" d-flex">
                                        <div className="" style={{ width: '100%' }}>
                                            <div className="mb-3">
                                                <TextField
                                                    required
                                                    type='number'
                                                    id="ounit"
                                                    placeholder="Min"
                                                    size="small"
                                                    value={audience.price_min} name="price_min" onChange={handleVoucher1}

                                                />


                                            </div>

                                        </div>
                                        <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                                            <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                                        </div>
                                        <div className="" style={{ width: '100%' }}>
                                            <div className="mb-3">
                                                <TextField
                                                    required
                                                    type='number'
                                                    placeholder="Max "
                                                    size="small"
                                                    value={audience.price_max} name="price_max" onChange={handleVoucher1}

                                                />
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className="mb-3">
                                    <label htmlFor="ounit">Order Amount </label>
                                    <div className=" d-flex">
                                        <div className="" style={{ width: '100%' }}>
                                            <div className="mb-3">
                                                <TextField
                                                    required
                                                    type='number'
                                                    id="ounit"
                                                    placeholder="Min"
                                                    size="small"
                                                    value={audience.order_amount_min} name="order_amount_min" onChange={handleVoucher1}


                                                />


                                            </div>

                                        </div>
                                        <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                                            <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                                        </div>
                                        <div className="" style={{ width: '100%' }}>
                                            <div className="mb-3">
                                                <TextField
                                                    required
                                                    type='number'
                                                    placeholder="Max "
                                                    size="small"
                                                    value={audience.order_amount_max} name="order_amount_max" onChange={handleVoucher1}

                                                />
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className="mb-3">
                                    <label htmlFor="ounit">Order Unit </label>
                                    <div className=" d-flex">
                                        <div className="" style={{ width: '100%' }}>
                                            <div className="mb-3">
                                                <TextField
                                                    required
                                                    type='number'
                                                    id="ounit"
                                                    placeholder="Min"
                                                    size="small"
                                                    value={audience.order_unit_min} name="order_unit_min" onChange={handleVoucher1}


                                                />


                                            </div>

                                        </div>
                                        <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                                            <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                                        </div>
                                        <div className="" style={{ width: '100%' }}>
                                            <div className="mb-3">
                                                <TextField
                                                    required
                                                    type='number'
                                                    placeholder="Max "
                                                    size="small"
                                                    value={audience.order_unit_max} name="order_unit_max" onChange={handleVoucher1}

                                                />
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <button type="button" onClick={handleCloseC} className={`${styles.modal_btn} btn btn-block`}>
                                Proceed

                            </button>


                        </div>

                    </form>


                </div>


            </GlobalModal>

        </>
    )
}
