import { useState, useEffect } from "react";
import styles from "./Fileinput.module.css";
import bus from "src/bus";

const FileInput = ({uploaded, maxHeight, maxWidth}) => {
  const [preview, setPreview] = useState('/assets/dropBox.svg') 
  const [overSizedImg, setOverSizedImg] = useState(false)

  const handleFile = (e) => {
    if (e.target.files[0]) {
      setOverSizedImg(false)
      setPreview(URL.createObjectURL(e.target.files[0]))
      uploaded(e.target.files[0])
    }
  }

  useEffect(() => {
    bus.on('overSizedImg', (val) => {
      if (val) {
        setOverSizedImg(true)
      }
    })
  }, [])

  return (
    <div className={styles.file_input_container}>
      <label>
        <div className={styles.input_inner}>
          <figure>
            {
              overSizedImg && (
                <img style={{height:'100px'}} src={'/assets/dropBox.svg'} alt="" />
              )
            }
            {
              !overSizedImg && (
                <img style={{height:'100px'}} src={preview} alt="" />
              )
            }
          </figure>

          <input type="file" onChange={handleFile} />
          <div>
            
            <div className={` ${styles.btn_styles}`}>Select Files</div>
          </div>
        </div>


        <div className={`mt-4 ${styles.box_buttom_content}`}>
          <p className="mb-2">Recommended image size to ({maxWidth} x {maxHeight})px</p>
          <p className="mb-2">Maximum upload files size: 20MB</p>
          <p>Allowed image type (png, jpg, jpeg)</p>
        </div>
      </label>
    </div>
  );
};

export default FileInput;
