import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import bus from 'src/bus';

import { Box, Drawer, ListItemText, Avatar } from '@mui/material';
import styles from './nav.module.css';
// mock
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../pages/logo/appLogo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import Backdrop from '@mui/material/Backdrop';
// import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import navConfig from './config';
import { StyledNavItem } from '../../../components/nav-section/styles';
import { NavLink as RouterLink } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';

// ----------------------------------------------------------------------

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  maxWidth: '95%',
  overflow: 'auto',
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 2,
};

const NAV_WIDTH = 280;

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const { data, dispatch } = useAuth();
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const notify = (msg) => toast.success(msg);
  const notifyError = (err) => toast.error(err);

  let navigate = useNavigate();

  const isDesktop = useResponsive('up', 'lg');

  const goToPage = () => {
    navigate('/app/profile');
  };

  const LogoutUser = () => {
    if (data.merchant_type) {
      localStorage.removeItem('auth');
      dispatch({ type: 'LOGIN', payload: {} });
      setTimeout(() => {
        navigate('/login');
      }, 1000);
    } else {
      axiosInstance.get('/admin/auth/logout').then((res) => {
        if (res.data.success) {
          notify(res.data.message);
          setTimeout(() => {
            navigate('/auth');
          }, 1000);
        } else {
          notifyError(res.data.message);
        }
      });
    }
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
      setOpenLogoutDialog(false);
    }
  }, [pathname]);

  useEffect(() => {}, [openNav]);

  useEffect(() => {
    bus.on('open-logout-modal', () => {
      setOpenLogoutDialog(true);
    });
  }, []);

  const renderContent = (
    <Scrollbar
      style={{
        position: 'relative',
        background: '#FFFFFF',
        borderRight: '1px solid #E5E7EB',
        overflowY: 'scroll',
      }}
      className="sidebarM"
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>
      {data.user_type !== 'admin' && (
        <Box
          style={{
            marginTop: '20px',
          }}
          sx={{ px: 2.5, display: 'inline-flex' }}
        >
          {data?.user_type !== 'admin' && data?.is_verified === false && (
            <button
              className="btn  "
              style={{
                backgroundColor: '#F3F4F6',
                borderRadius: '16px',
                padding: '3px 10px',
              }}
            >
              <img src="/assets/icons/circle.svg" alt="" />
              &nbsp; Test mode
            </button>
          )}
          {data?.user_type !== 'admin' && data?.is_verified === true && (
            <button
              className=" btn "
              style={{
                borderRadius: '16px',
                padding: '3px 10px',
                backgroundColor: '#FFD3FA',
                color: '#af3585',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div className={styles.modeCircle}>{/* Circle dot */}</div>
              &nbsp; Live mode
            </button>
          )}
        </Box>
      )}
      <p></p>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box className={styles.sub_sec} sx={{ px: 1, pb: 3, mt: 8 }}>
        {data?.user_type !== 'admin' && (
          <div className="border-bottom ">
            {data?.user_type === 'admin' && (
              <StyledNavItem
                component={RouterLink}
                to="/app/settings"
                className="px-3 "
                sx={{
                  '&.active': {
                    color: 'text.primary',
                    bgcolor: 'action.selected',
                    fontWeight: 'fontWeightBold',
                  },
                }}
                style={{ position: 'absolute', bottom: '0px', width: '100%' }}
              >
                {/* <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon> */}
                <p className="mb-0 ">
                  <img src="/assets/icons/settings.svg" alt="icon" />
                </p>
                <ListItemText
                  style={{ paddingLeft: '15px' }}
                  disableTypography
                  primary="Settings"
                />
              </StyledNavItem>
            )}

            {data?.merchant_type && (
              <StyledNavItem
                component={RouterLink}
                to="/app/settings"
                className="px-3 "
                sx={{
                  '&.active': {
                    color: 'text.primary',
                    bgcolor: 'action.selected',
                    fontWeight: 'fontWeightBold',
                  },
                }}
              >
                {/* <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon> */}
                <p className="mb-0 ">
                  <img src="/assets/icons/settings.svg" alt="icon" />
                </p>
                <ListItemText
                  style={{ paddingLeft: '15px' }}
                  disableTypography
                  primary="Settings"
                />
              </StyledNavItem>
            )}
          </div>
        )}
        {data?.user_type === 'admin' && (
          <div className="border-bottom ">
            <StyledNavItem
              className="px-3 "
              onClick={() => setOpenLogoutDialog(true)}
              sx={{
                '&.active': {
                  color: 'text.primary',
                  bgcolor: 'action.selected',
                  fontWeight: 'fontWeightBold',
                },
              }}
            >
              {/* <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon> */}
              <p className="mb-0 ">
                <img src="/assets/icons/log-out.svg" alt="" />
              </p>
              <ListItemText
                style={{ paddingLeft: '15px', color: '#ff0000d1' }}
                disableTypography
                primary="Log Out"
              />
            </StyledNavItem>
          </div>
        )}
        <div className="mt-2">
          <StyledNavItem
            // component={RouterLink}
            // to='/app/profile'
            className="px-3 "
          >
            <Avatar
              className="float-left"
              style={{
                width: '36px',
                borderRadius: '50%',
              }}
              src={data.business_logo ? data.business_logo : data.store_logo}
              alt=""
            />
            <div className="ml-2 pt-3">
              <h6 className="mb-0" style={{ fontSize: '16px' }}>
                {data?.business_name}
                {data?.store_name}
                {data?.user_type === 'admin' && data?.is_superadmin && (
                  <span>Super Admin</span>
                )}
                {data?.user_type === 'admin' && !data?.is_superadmin && (
                  <span>Admin</span>
                )}
              </h6>
              {data.user_type === 'admin' && (
                <h6 className="mb-0" style={{ fontSize: '16px' }}>
                  {data?.first_name}
                </h6>
              )}
              <p
                style={{ fontSize: '12px', color: '#4b5563' }}
                className="mb-0"
              >
                {data.merchant_type === 'brand' && (
                  <span>Brand ID: {data.brandID}</span>
                )}
                {data.merchant_type === 'shop' && (
                  <span>Shop ID: {data.shopID}</span>
                )}
              </p>
              <p className="text-[#53138D] mt-1" style={{ fontSize: '10px' }}>
                <span
                  style={{ cursor: 'pointer', color: '#FFA6F5' }}
                  type="button"
                  onClick={() => {
                    goToPage();
                  }}
                >
                  View profile
                </span>
              </p>
            </div>
          </StyledNavItem>
        </div>
      </Box>
    </Scrollbar>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{
          flexShrink: { lg: 0 },
          width: { lg: NAV_WIDTH },
        }}
      >
        {isDesktop && (
          <Drawer
            open
            variant="permanent"
            PaperProps={{
              sx: {
                width: NAV_WIDTH,
                bgcolor: 'background.default',
                borderRightStyle: 'dashed',
              },
            }}
          >
            {renderContent}
          </Drawer>
        )}
        {!isDesktop && (
          <Drawer
            open={openNav}
            onClose={() => {
              onCloseNav();
              setOpenLogoutDialog(false); // Close the logout modal
            }}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: { width: NAV_WIDTH },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openLogoutDialog}
        onClose={() => setOpenLogoutDialog(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
        className="modal_bg"
        style={{ maxWidth: '100%', background: 'transparent' }}
      >
        <Fade
          in={openLogoutDialog}
          className="modal_bg"
          style={{ maxWidth: '90%' }}
        >
          <Box
            className={`mobile_modal_size modal_bg`}
            sx={style1}
            style={{ maxWidth: '90%' }}
          >
            <div className={`text-center add_to_cart_content py-4`}>
              <img
                src="/assets/icons/log-out.svg"
                alt=""
                width={50}
                className="d-block mx-auto my-2"
              />
              <p
                className="text-center font-weight-normal my-3"
                style={{ textAlign: 'center !important', fontSize: '1.2em' }}
              >
                Do you want to Logout?
              </p>
              <div className="d-flex px-2 mt-4">
                <button
                  onClick={() => setOpenLogoutDialog(false)}
                  className={`modal_btn d-block w-100 hover btn border py-2 bg-light text-dark mr-2`}
                  style={{
                    boxShadow: '0 5px 5px #bbb',
                  }}
                >
                  Cancel
                </button>

                <button
                  // onClick={() => setOpenLogoutDialog(false)}
                  className={`modal_btn d-block w-100 hover btn border py-2 bg-light text-danger ml-2`}
                  style={{
                    boxShadow: '0 5px 5px #bbb',
                  }}
                  onClick={LogoutUser}
                >
                  Logout
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
