// ----------------------------------------------------------------------
import styles from './BrandOwner.module.css';
import { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { BallTriangle } from 'react-loader-spinner';
import bus from 'src/bus';
import FilterModal from './filterModal';
import CircularProgress from '@mui/material/CircularProgress';
import Filter1 from 'src/pages/Filter/Filter1';
import axiosInstance from 'src/axiosInstance';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { Card } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
// sections
// mock
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useApiKeys } from 'src/hooks/useApiKeys';
import toast from 'react-hot-toast';
import LiveSelect from 'react-select';
import { downloadFile2 } from 'src/utils';
import { Pagination } from 'src/components';

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#FFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 2,
};
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  overflow: 'auto',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};
export default function BrandownerWallet() {
  const [open, setOpen] = useState(false);
  const [openReport, setReportOpen] = useState(false);
  const [walletStatus, setWalletStatus] = useState(false);
  const [loadWalletSummary, setLoadWalletSummary] = useState(false);
  const handleReportClose = () => setReportOpen(false);
  const [banks, setBanks] = useState([]);
  const [topUpPending, setTopUpPending] = useState(false);
  const [transPending, setTransPending] = useState(false);
  const [categoriesData, setdata] = useState([]);
  const [categoryPagination, setCategoryPagination] = useState({ total: 0 });
  const [dataFilter, setDataFilter] = useState('today');
  const notifyError = (err) => toast.error(err);
  const [open1, setOpen1] = useState(false);
  const handleClose1 = () => setOpen1(false);
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpen21 = () => setOpen1(true);
  const [otp, setOtp] = useState(false);
  const handleOpen2 = () => setOpen(true);
  const [values, setValues] = useState(['', '', '', '']);
  const inputs = useRef([]);
  const [modalTitle, setmodalTitle] = useState('');
  const [modal_desc, setmodal_desc] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState(false);
  let [bank_info, setBankInfo] = useState({ account_no: '', account_name: '' });
  const [selectedOption] = useState(bank_info.bank_code);
  const [exporting, setExporting] = useState(false);

  const [walletSummary, setWalletSummary] = useState({
    summaries: {
      total_income: {
        bold: 0,
        small: 0,
        percentageDiff: 0,
      },
      total_deposit: {
        bold: 0,
        small: 0,
        percentageDiff: 0,
      },
      total_expenses: {
        bold: 0,
        small: 0,
        percentageDiff: 0,
      },
    },
    discount_info: {},
    wallet_balance: 0,
  });

  const [filterSearch, setFilterSearch] = useState({
    transaction_id: '',
    min_amount: '',
    max_amount: '',
    dateTo: '',
    dateFrom: '',
    status: '',
    transaction_type: '',
  });
  const apiKeys = useApiKeys();
  const [nameEnquiryLoading, setNameEnquiryLoading] = useState(false);
  const [amount, setAmountValue] = useState('');
  const [topUpAmount, setTopUpAmount] = useState(0);

  const handleTopUpChange = (value) => {
    // e.preventDefault()
    setTopUpAmount(value);
  };

  const handleAmountChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 10) {
      setAmountValue(inputValue);
    }
  };

  let ar = [];

  const handleBankChange = (e, nameParam = null) => {
    const name = nameParam === null ? e.target.name : nameParam;
    const value = e.hasOwnProperty('label') ? e.value : e.target.value;
    setBankInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  useEffect(() => {
    nameEnquiry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bank_info.account_no]);

  useEffect(() => {
    const data = bank_info;
    data.account_no = '';
    setBankInfo(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bank_info.bank_code]);

  const fetchBanks = async () => {
    const banksApi = await fetch('https://api.paystack.co/bank');
    const banks = await banksApi.json();
    if (banks.status) {
      setBanks(banks.data);
    }
  };
  const nameEnquiry = async () => {
    if (bank_info.account_no.length < 10 || bank_info.account_no.length > 11)
      return;
    setNameEnquiryLoading(true);
    const accountNameApi = await fetch(
      `https://api.paystack.co/bank/resolve?account_number=${bank_info.account_no}&bank_code=${bank_info.bank_code}`,
      {
        headers: {
          Authorization: `Bearer ${apiKeys.PASTACK.SECRET_KEY}`,
        },
      }
    );
    const accountName = await accountNameApi.json();
    setNameEnquiryLoading(false);

    if (accountName.status) {
      const data = bank_info;
      data.account_name = accountName.data.account_name;
      setBankInfo(data);
    } else {
      const data = bank_info;
      data.account_name = '';
      setBankInfo(data);
      notifyError(accountName.message);
    }
  };

  const submitOtpWallet = (e) => {
    e.preventDefault();
    setOtp(false);
    setmodalTitle('Withdraw complete');
    setmodal_desc(
      'Your withdrawal has been processed. Check your mail for a notice of withdrawal'
    );
    setOpenDialog(true);
  };

  const submitFundWallet = (e) => {
    e.preventDefault();
    setOtp(true);
    setOpen1(false);
  };

  const moveFocus = (event) => {
    setTimeout(() => {
      const input = event.target;
      const nextInput = inputs.current[inputs.current.indexOf(input) + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }, 0);
  };

  const topUp = (e) => {
    e.preventDefault();
    setTopUpPending(true);
    axiosInstance
      .post('/brand/wallet/top-up', {
        amount: topUpAmount,
        payment_gateway: 'paystack',
      })
      .then((res) => {
        window.open(res.data.data.url, '_self');
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError(
            'Unable to complete transaction process, try again later'
          );
        }
      })
      .finally(() => setTopUpPending(false));
  };
  const submitReport = (e) => {
    e.preventDefault();
    setOpenDialog(true);
    setmodalTitle('Report  Sent');
    setReportOpen(false);
  };
  const handleChange = (event) => {
    const input = event.target;
    const newValues = [...values];
    newValues[inputs.current.indexOf(input)] = input.value;
    ar.push(newValues);
    setValues(newValues);
    moveFocus(event);
  };

  const fetchWalletSummary = async () => {
    setLoadWalletSummary(true);
    let periodValue = typeof dataFilter === 'object' ? 'All time' : dataFilter;

    axiosInstance
      .get('/brand/wallet/summary?period=' + periodValue)
      .then((res) => {
        setWalletStatus(true);
        if (res.status === 200) {
          setWalletSummary(res.data.data);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch store, try again later');
        }
      })
      .finally(() => setLoadWalletSummary(false));
  };

  const fetchData = async (
    page = 1,
    pageSize = 10,
    dateFilter = dataFilter,
    file
  ) => {
    let filter_payload = {};
    if (filterSearch.dateFrom && filterSearch.dateFrom !== '') {
      filter_payload.dateFrom = filterSearch.dateFrom;
    }
    if (filterSearch.status !== 'select') {
      filter_payload.status = filterSearch.status;
    }
    if (filterSearch.transaction_type !== '') {
      filter_payload.transaction_type = filterSearch.transaction_type;
    }
    if (filterSearch.transaction_id !== '') {
      filter_payload.transaction_id = filterSearch.transaction_id;
    }
    if (filterSearch.dateTo && filterSearch.dateTo !== '') {
      filter_payload.dateTo = filterSearch.dateTo;
    }
    if (filterSearch.min_amount !== '') {
      filter_payload.min_amount = filterSearch.min_amount;
    }
    if (filterSearch.max_amount !== '') {
      filter_payload.max_amount = filterSearch.max_amount;
    }
    if (file) {
      setExporting(true);
      let queryParams = new URLSearchParams({
        page,
        pageSize,
        ...filter_payload,
        dataFilter,
        export_type: file,
      }).toString();

      try {
        await downloadFile2(
          'All Wallets',
          `/brand/wallet?${queryParams}`,
          file
        );
      } finally {
        // alert()
        setExporting(false);
      }
      return;
    }
    setTransPending(true);
    axiosInstance
      .get('/brand/wallet', {
        params: {
          page,
          pageSize,
          dateFilter,
          ...filter_payload,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setdata(res.data.data.data);
        setCategoryPagination(res.data.data.pagination);
        filterSearch.transaction_id = '';
        filterSearch.min_amount = '';
        filterSearch.max_amount = '';
        filterSearch.dateTo = '';
        filterSearch.dateFrom = '';
        filterSearch.status = '';
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch wallet details, please try again later');
        }
      })
      .finally(() => setTransPending(false));
  };

  useEffect(() => {
    bus.on('open-wallet-topup', () => {
      handleOpen2();
    });

    bus.on('open-wallet-withdraw', () => {
      handleOpen21();
    });

    bus.on('search-filter', (el) => {
      setFilterSearch(el);
      setStatusFilter(!statusFilter);
    });

    bus.on('dataFilter', (el) => {
      // console.log(el);
      setDataFilter(el);
    });

    fetchWalletSummary();
    fetchData();
    // topUpWallet()

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, dataFilter]);

  const parseString = (v) => {
    return v.replace(/_/g, ' ');
  };

  return (
    <>
      <div className="wrapper px-2 mt-4">
        <div className="header d-block d-md-none mb-4">
          <div
            className={`${styles.wallet_actions} d-md-flex justify-content-between`}
          >
            <div className=" ">
              <h4 className={`mb-0 pb-0`}>Wallet</h4>
              <p style={{ fontSize: '14px' }} className={`mb-0 mt-2 pb-0`}>
                Manage all your cash transactions
              </p>
            </div>
            <div className={`${styles.wallet_button} mt-3 mt-md-0`}>
              <button
                type="button"
                onClick={handleOpen2}
                className={`btn ${styles.top_up_button}`}
              >
                <span>Top Up</span>
              </button>
              &nbsp; &nbsp;
              <button
                onClick={handleOpen21}
                className={`btn ${styles.transfer}`}
              >
                <span>Withdraw</span>
              </button>
            </div>
          </div>
        </div>
        <div className={`${styles.analytics}`}>
          <Filter1 width="61%" />

          <div className={`${styles.customized_row}`}>
            <div>
              {/* {loadWalletSummary &&
                      <CircularProgress style={{ color: '#af3585', alignItems: "center" }} size="2rem" />
                    } */}
              <>
                <div className={`row`}>
                  <div className={`col-md-6 pr-md-2 mb-2 pr-0`}>
                    <div className={`${styles.card} mb-2`}>
                      <div className={`card-body`}>
                        <div className="d-flex justify-content-between">
                          <small>Total Income </small>
                        </div>
                        {!loadWalletSummary && (
                          <div className={`${styles.numbers} mt-3`}>
                            <div>
                              <span>
                                ₦{' '}
                                {walletSummary?.summaries?.total_income?.bold
                                  ? Number(
                                      walletSummary?.summaries?.total_income
                                        ?.bold
                                    ).toLocaleString()
                                  : 0}
                              </span>
                              &nbsp;
                              <small>
                                from ₦
                                {walletSummary?.summaries?.total_income?.small
                                  ? Number(
                                      walletSummary?.summaries?.total_income
                                        ?.small
                                    ).toLocaleString()
                                  : 0}
                              </small>
                            </div>

                            <div>
                              {Number(
                                walletSummary?.summaries?.total_income?.bold
                              ).toLocaleString() >=
                              Number(
                                walletSummary?.summaries?.total_income?.small
                              ).toLocaleString() ? (
                                <div className={styles.percent}>
                                  <img src="/assets/icons/up.svg" alt="" />
                                  <small className="pl-1">
                                    {walletSummary?.summaries?.total_income
                                      ? Number(
                                          walletSummary?.summaries?.total_income
                                            ?.percentageDiff
                                        ).toLocaleString()
                                      : 0}
                                    %
                                  </small>
                                </div>
                              ) : (
                                <div className={`${styles.percent2}`}>
                                  <img src="/assets/icons/downr.svg" alt="" />
                                  <small className="pl-1">
                                    {walletSummary?.summaries
                                      ? Math.abs(
                                          walletSummary?.summaries?.total_income
                                            ?.percentageDiff
                                        )
                                      : 0}
                                    %
                                  </small>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-6 pl-md-1 mb-2 pr-0`}>
                    <div className={`${styles.card} mb-2`}>
                      <div className={`card-body`}>
                        <div className="d-flex justify-content-between">
                          <small>Total Deposit</small>
                        </div>
                        {!loadWalletSummary && (
                          <div className={`${styles.numbers} mt-3`}>
                            <div>
                              <span>
                                ₦{' '}
                                {walletSummary?.summaries?.total_deposit?.bold
                                  ? Number(
                                      walletSummary?.summaries?.total_deposit
                                        ?.bold
                                    ).toLocaleString()
                                  : 0}
                              </span>
                              &nbsp;
                              <small>
                                from ₦
                                {walletSummary?.summaries?.total_deposit?.small
                                  ? Number(
                                      walletSummary?.summaries?.total_deposit
                                        ?.small
                                    ).toLocaleString()
                                  : 0}
                              </small>
                            </div>

                            <div>
                              {Number(
                                walletSummary?.summaries?.total_deposit?.bold
                              ).toLocaleString() >=
                              Number(
                                walletSummary?.summaries?.total_deposit?.small
                              ).toLocaleString() ? (
                                <div className={styles.percent}>
                                  <img src="/assets/icons/up.svg" alt="" />
                                  <small className="pl-1">
                                    {walletSummary?.summaries?.total_deposit
                                      ? Number(
                                          walletSummary?.summaries
                                            ?.total_deposit?.percentageDiff
                                        ).toLocaleString()
                                      : 0}
                                    %
                                  </small>
                                </div>
                              ) : (
                                <div className={`${styles.percent2}`}>
                                  <img src="/assets/icons/downr.svg" alt="" />
                                  <small className="pl-1">
                                    {walletSummary?.summaries
                                      ? Math.abs(
                                          walletSummary?.summaries
                                            ?.total_deposit?.percentageDiff
                                        )
                                      : 0}
                                    %
                                  </small>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`row `}>
                  <div className={`col-md-6 pr-md-2 mb-2 pr-0`}>
                    <div className={`${styles.card} mb-2`}>
                      <div className={`card-body`}>
                        <div className="d-flex justify-content-between">
                          <small>Total Expenses </small>
                        </div>
                        {!loadWalletSummary && (
                          <div className={`${styles.numbers}`}>
                            <div>
                              <span>
                                ₦{' '}
                                {walletSummary?.summaries?.total_expenses?.bold
                                  ? Number(
                                      walletSummary?.summaries?.total_expenses
                                        ?.bold
                                    ).toLocaleString()
                                  : 0}
                              </span>
                              &nbsp;
                              <small>
                                from ₦
                                {walletSummary?.summaries?.total_expenses?.small
                                  ? Number(
                                      walletSummary?.summaries?.total_expenses
                                        ?.small
                                    ).toLocaleString()
                                  : 0}
                              </small>
                            </div>

                            <div>
                              {Number(
                                walletSummary?.summaries?.total_expenses?.bold
                              ).toLocaleString() >=
                              Number(
                                walletSummary?.summaries?.total_expenses?.small
                              ).toLocaleString() ? (
                                <div className={styles.percent}>
                                  <img src="/assets/icons/up.svg" alt="" />
                                  <small className="pl-1">
                                    {walletSummary?.summaries?.total_expenses
                                      ? Number(
                                          walletSummary?.summaries
                                            ?.total_expenses?.percentageDiff
                                        ).toLocaleString()
                                      : 0}
                                    %
                                  </small>
                                </div>
                              ) : (
                                <div className={`${styles.percent2}`}>
                                  <img src="/assets/icons/downr.svg" alt="" />
                                  <small className="pl-1">
                                    {walletSummary?.summaries
                                      ? Math.abs(
                                          walletSummary?.summaries
                                            ?.total_expenses?.percentageDiff
                                        )
                                      : 0}
                                    %
                                  </small>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-6 pl-md-1 mb-2 pr-0`}>
                    <div className={`row`}>
                      <div className={`col-md-6 pr-md-2 mb-2`}>
                        <div className={`${styles.card} mb-2`}>
                          <div className={`card-body`}>
                            <div className="d-flex justify-content-between">
                              <small style={{ whiteSpace: 'nowrap' }}>
                                Discount Balance{' '}
                              </small>
                            </div>
                            {!loadWalletSummary && (
                              <div className={`${styles.numbers}`}>
                                <div>
                                  <div>
                                    <span>
                                      ₦
                                      {Number(
                                        walletSummary?.discount_info
                                          ?.discount_balance
                                      ).toLocaleString()}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={`col-md-6 pl-md-1 mb-2`}>
                        <div className={`${styles.card} mb-2`}>
                          <div className={`card-body`}>
                            <div className="d-flex justify-content-between">
                              <small style={{ whiteSpace: 'nowrap' }}>
                                Locked Balance{' '}
                              </small>
                            </div>
                            {!loadWalletSummary && (
                              <div className={`${styles.numbers}`}>
                                <div>
                                  <div>
                                    <span>
                                      ₦
                                      {Number(
                                        walletSummary?.discount_info
                                          ?.locked_balance
                                      ).toLocaleString()}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
            <div className="">
              <div className={`${styles.card2} card `} style={{ padding: '' }}>
                <div className={`${styles.wallet}`}>
                  <img
                    className={`${styles.img}`}
                    src="/assets/crc.jpg"
                    alt=""
                    style={{ borderRadius: '50px' }}
                  />
                  <div
                    className={`${styles.content}`}
                    style={{
                      background: '#8c2d915d',
                      height: '290px',
                      borderRadius: '50px',
                    }}
                  >
                    <div className={`${styles.content_header}`}>
                      <div>
                        ₦‎{' '}
                        {Number(walletSummary?.wallet_balance).toLocaleString()}
                      </div>
                      <div>
                        <img src="/assets/icons/Vector.svg" alt="" />
                      </div>
                    </div>
                    <p className={`${styles.sub_text}`}>Your Balance</p>
                    <div className={`${styles.content_body}`}>
                      <p className={`${styles.sub_text}`}>Account Details</p>
                      <div className={`${styles.bank_info}`}>
                        <div>
                          <p className={`${styles.name}`}>
                            {walletSummary?.account_details?.bank_account_name}
                          </p>
                          <p className={`${styles.account_number}`}>
                            {
                              walletSummary?.account_details
                                ?.bank_account_number
                            }
                          </p>
                        </div>
                        <span
                          style={{
                            fontSize: '9px',
                            fontWeight: '700',
                            color: '#FFFFFF',
                          }}
                        >
                          {walletSummary?.account_details?.bank_name}
                        </span>
                        {/* <img className="mt-2" src="/assets/Logomark.svg" alt="" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: '10px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-4 d-md-flex justify-content-between">
            <div>
              <h5 className="table_desc_header">All Transactions</h5>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <small style={{ fontWeight: '200', fontSize: '12px' }}>
                  Your transaction history
                </small>
              </div>
            </div>
            <div className="d-md-flex">
              <div className="dropleft mb-1 ">
                <button
                  className="btn  border"
                  onClick={() => bus.emit('open-filter-wallet')}
                  style={{
                    backgroundColor: '#fff',
                    border: '1px solid #D0D5DD',
                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    borderRadius: '4px',
                  }}
                >
                  <img
                    className=""
                    style={{ display: 'inline', width: '17px' }}
                    src="/assets/icons/filterlines.svg"
                    alt=""
                  />
                  &nbsp;
                  <small className="d-none d-md-inline-block">Filters</small>
                </button>
              </div>
              <span className="d-none d-md-block">&nbsp; &nbsp;</span>
              <div className="dropleft ">
                <button
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className={`${styles.export_btn} btn m-0`}
                >
                  <span
                    style={{
                      paddingTop: '8px',
                    }}
                  >
                    <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                    {!exporting && <span>Export</span>}
                    {exporting && (
                      <CircularProgress
                        style={{
                          color: 'white',
                          display: 'inline-block',
                        }}
                        size="1rem"
                      />
                    )}
                  </span>
                  &nbsp; &nbsp;
                  <span
                    style={{
                      borderLeft: '1px solid white',
                      display: 'inline-block',
                      height: 'inherit',
                      paddingTop: '8px',
                      paddingLeft: '8px',
                      textAlign: 'center',
                    }}
                  >
                    <img src="/assets/icons/arrowDown.svg" alt="" />
                  </span>
                </button>

                <div
                  className="dropdown-menu drop-left"
                  aria-labelledby="dropdownMenuButton"
                >
                  <button
                    onClick={() =>
                      fetchData(currentPage, 200000, dataFilter, 'csv')
                    }
                    type="button"
                    className="rss dropdown-item btn border-0"
                  >
                    CSV
                  </button>
                  <button
                    onClick={() =>
                      fetchData(currentPage, 200000, dataFilter, 'excel')
                    }
                    type="button"
                    className="rss dropdown-item btn border-0"
                  >
                    Excel
                  </button>
                  <button
                    onClick={() =>
                      fetchData(currentPage, 200000, dataFilter, 'pdf')
                    }
                    type="button"
                    className="rss dropdown-item btn border-0"
                  >
                    PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            {categoriesData.length > 0 && !transPending && (
              <div className={`table-responsive-sm `}>
                <table
                  className={`${styles.table} table-striped `}
                  style={{ minWidth: '85em' }}
                >
                  <thead>
                    <tr className={`${styles.tr} ${styles.thead_tr} pb-3`}>
                      <td style={{ width: '20%' }}>Transaction Id</td>
                      <td>Type</td>
                      <td style={{ width: '15%' }}>Action</td>
                      <td>Date</td>
                      <td>Amount</td>
                      <td>Status</td>
                    </tr>
                  </thead>

                  <tbody className="">
                    {categoriesData.map((el, i) => (
                      <tr key={i} className={`${styles.tr}`}>
                        <td>
                          <b>{el.transaction_id}</b>
                        </td>
                        <td>
                          <span>
                            {el.transaction_type
                              .split('_')
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(' ')}
                          </span>
                        </td>
                        <td>
                          <span style={{ textTransform: 'capitalize' }}>
                            {parseString(el.transaction_action)}
                          </span>
                        </td>
                        <td>{moment(el.createdAt).format('DD/MM/YYYY')}</td>
                        <td>
                          ₦
                          {el.amount.$numberDecimal > 0
                            ? Number(el.amount.$numberDecimal).toLocaleString()
                            : 0}
                        </td>
                        <td>
                          {el.transaction_status === 'completed' ? (
                            <span className="success">
                              {el.transaction_status}
                            </span>
                          ) : el.transaction_status === 'pending' ? (
                            <span className="alert">
                              {el.transaction_status}
                            </span>
                          ) : (
                            <span className="danger">
                              {el.transaction_status}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div className="d-flex justify-content-center">
              {transPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!categoriesData.length && !transPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={categoryPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open1}>
          <Box sx={style} className={`mobile_modal_size`}>
            <div className={`modal_content`}>
              <div className={`modal_header mb-0`}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                    marginBottom: '0',
                  }}
                >
                  Withdraw
                </h5>
                <span onClick={handleClose1} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: '14px',
                }}
              >
                Withdraw from your wallet
              </p>
              <form onSubmit={submitFundWallet} className="mt-0">
                <div className="mb-0 relative_position">
                  <label htmlFor="amount">Amount</label>
                  <span>
                    <img src="/assets/icons/naira.svg" alt="" />
                  </span>
                  <TextField
                    required
                    type="number"
                    id="amount"
                    maxLength={10}
                    value={amount}
                    // onChange={handleAmountChange}
                    size="small"
                    fullWidth
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue === '' || parseFloat(inputValue) > 0) {
                        handleAmountChange(inputValue);
                      }
                    }}
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                    onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e' || e.key === '.') {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                {/* <small style={{fontSize:'12px'}} className="text-danger">
                  * Daily withdrawal limit of 300,000
                </small> */}
                <div className="mt-3 mb-4">
                  <label htmlFor="state">Select Bank</label>
                  <LiveSelect
                    defaultValue={selectedOption}
                    name="bank_code"
                    onChange={(e) => handleBankChange(e, 'bank_code')}
                    options={banks.map((option) => ({
                      value: option.code,
                      label: option.name,
                    }))}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="cnumber">Enter Account Number </label>{' '}
                  {nameEnquiryLoading && (
                    <CircularProgress
                      style={{ color: '#af3585' }}
                      size="0.8rem"
                    />
                  )}
                  <TextField
                    required
                    id="cnumber"
                    type="number"
                    size="small"
                    fullWidth
                    value={bank_info.account_no}
                    name="account_no"
                    disabled={!bank_info.bank_code}
                    onChange={handleBankChange}
                  />
                  {!nameEnquiryLoading && (
                    <small style={{ fontSize: '12px', marginTop: '5px' }}>
                      {bank_info.account_name}
                    </small>
                  )}
                </div>
                <div className="mb-4">
                  <button type="submit" className={`modal_btn btn btn-block`}>
                    Proceed
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open}>
          <Box sx={style} className={`mobile_modal_size`}>
            <div className={`modal_content`}>
              <div className={`modal_header mb-0`}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}
                >
                  Top up
                </h5>
                <span
                  onClick={() => setOpen(false)}
                  style={{ cursor: 'pointer' }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: '14px',
                }}
              >
                Fund your wallet
              </p>

              <form onSubmit={topUp} className="mt-0">
                <div className="mb-4 relative_position">
                  <label htmlFor="amount">Amount</label>
                  <span>
                    <img src="/assets/icons/naira.svg" alt="" />
                  </span>
                  <TextField
                    required
                    type="number"
                    value={topUpAmount}
                    name="amount"
                    id="amount"
                    size="small"
                    fullWidth
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue === '' || parseFloat(inputValue) > 0) {
                        handleTopUpChange(inputValue);
                      }
                    }}
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                    onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e' || e.key === '.') {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                {/* <div className="mb-4">
                  <label htmlFor="name">Card Holder Name</label>
                  <TextField
                    required
                    value={topUpData.card_holder_name}
                    name="card_holder_name"
                    onChange={handleTopUpChange}
                    id="name"
                    placeholder="James benson"
                    size="small"
                    fullWidth

                  />


                </div>
                <div className="mb-4 relative_position card_hold">
                  <span>
                    <img src="/assets/icons/cardnum.svg" style={{ width: '80%' }} alt="" />
                  </span>
                  <label htmlFor="cnumber">Card Number </label>
                  <TextField
                    required
                    value={topUpData.card_number}
                    name="card_number"
                    onChange={handleTopUpChange}
                    id="cnumber"
                    type="number"
                    placeholder="Card Number"
                    size="small"
                    fullWidth

                  />


                </div>
                <div className="mb-4 row">
                  <div className="col-6 mb-1">
                    <div className='relative_position1'>
                      <label htmlFor="exp">Expiry Date </label>
                      <img src="/assets/icons/calendar.svg" alt="" />


                      <DatePicker
                        showIcon
                        selected={expDate}
                        onChange={(date) => setExpDate(date)}
                        dateFormat="MM/yyyy"

                        className="form-control"
                        placeholderText="Start"
                        required
                      />

                    </div>

                  </div>
                  <div className="col-6 mb-1">
                    <div className='relative_position1'>
                      <label htmlFor="cvv">Cvv </label>
                      <img src="/assets/icons/helpicon.svg" alt="" />
                      <TextField
                        required
                        value={topUpData.cvv}
                        name="cvv"
                        onChange={handleTopUpChange}
                        id="cvv"
                        size="small"
                        fullWidth

                      />

                    </div>

                  </div>



                </div> */}
                <div className="mb-4">
                  {topUpPending && (
                    <button disabled className={`btn btn-block modal_btn`}>
                      Submitting &nbsp;
                      {topUpPending && (
                        <CircularProgress
                          style={{ color: 'white' }}
                          size="1rem"
                        />
                      )}
                    </button>
                  )}
                  {!topUpPending && (
                    <button type="submit" className={`modal_btn btn btn-block`}>
                      Proceed
                    </button>
                  )}
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={otp}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={otp}>
          <Box sx={style2} className={`mobile_modal_size`}>
            <div className={`modal_content`}>
              <div className={`modal_header`}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}
                >
                  Confirmation
                </h5>
                <span
                  onClick={() => setOtp(false)}
                  style={{ cursor: 'pointer' }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: '14px',
                }}
              >
                Enter OTP sent to your mail to confirm <br /> transaction
              </p>

              <form onSubmit={submitOtpWallet}>
                <div className="container  pt-5 px-5 pb-5 text-center mb-4">
                  <div
                    className={`d-flex  justify-content-center input_container`}
                  >
                    {/*  */}
                    <div className=" p-0 ml-2">
                      <input
                        type="number"
                        maxLength={1}
                        className="form-control"
                        onChange={handleChange}
                        ref={(el) => (inputs.current[0] = el)}
                      />
                    </div>
                    <div className=" p-0 ml-2">
                      <input
                        type="number"
                        maxLength={1}
                        className="form-control"
                        onChange={handleChange}
                        ref={(el) => (inputs.current[1] = el)}
                      />
                    </div>
                    <div className=" p-0 ml-2">
                      <input
                        type="number"
                        maxLength={1}
                        className="form-control"
                        onChange={handleChange}
                        ref={(el) => (inputs.current[2] = el)}
                      />
                    </div>
                    <div className=" p-0 ml-2">
                      <input
                        type="number"
                        maxLength={1}
                        className="form-control"
                        onChange={handleChange}
                        ref={(el) => (inputs.current[3] = el)}
                      />
                    </div>
                  </div>
                  <small
                    className="text-[#af3585]"
                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                  >
                    Resend Otp
                  </small>
                </div>

                <div className="mb-4">
                  <button type="submit" className={`modal_btn btn btn-block`}>
                    Proceed
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openDialog}>
          <Box className={`mobile_modal_size modal_bg`} sx={style1}>
            <div className={`text-center add_to_cart_content`}>
              <img src="/assets/icons/circleCheck.svg" alt="" />
              <h5 className="mt-3 mb-2" style={{ fontWeight: 'bold' }}>
                {modalTitle}
              </h5>
              <p
                className="text-center"
                style={{ textAlign: 'center !important' }}
              >
                {modal_desc}
              </p>
              <button
                onClick={() => setOpenDialog(false)}
                className={`modal_btn hover btn btn-block`}
              >
                Done
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openReport}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openReport}>
          <Box sx={style} className={`mobile_modal_size`}>
            <div className={`modal_content`}>
              <div className={`modal_header`}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}
                >
                  Help Center
                </h5>
                <span onClick={handleReportClose} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: '14px',
                }}
              >
                Report any issue related to this transaction
              </p>

              <form onSubmit={submitReport}>
                <div className="mb-4">
                  <label htmlFor="subject">Subject</label>
                  <TextField
                    required
                    type="text"
                    id="subject"
                    size="small"
                    fullWidth
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="complaint">Your complaint</label>
                  <TextField
                    required
                    type="text"
                    id="complaint"
                    size="small"
                    multiline
                    rows="5"
                    cols="5"
                    fullWidth
                  />
                </div>

                <div className="mb-4 text-right">
                  <button type="submit" className={`modal_btn btn `}>
                    Send
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
      <FilterModal />
    </>
  );
}
