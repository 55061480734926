

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
import styles from './Auth.module.css'
import Logo from "../logo/appLogo"
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useContext, useRef, useState } from "react"
import { BaseUrlContext } from 'src/context/BaseUrlContext';
import { CountdownTimer } from "../../components";

export default function LoginPage() {
  let navigate = useNavigate();
  const formRef = useRef();


  const [isPending, setIsPending] = useState(false);
  const baseUrl = useContext(BaseUrlContext);
  const [signUpError, setSignUpError] = useState(null);
  const [n, setN] = useState([]);

  const notify = () => toast.success('Email verified successfully .');
  const notify2 = () => toast.success('Verification token has been sent to your mail');
  const notifyError = () => toast.error("That didn't work please try again later .");
  const [timerFinished, setTimerFinished] = useState(false);


  const [values, setValues] = useState(['', '', '', '']);
  const inputs = useRef([]);
  let ar = [];

  const handleTimerFinish = () => {
    setTimerFinished(true);
  };

  const moveFocus = (event) => {
    setTimeout(() => {
      const input = event.target;
      const nextInput = inputs.current[inputs.current.indexOf(input) + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }, 0);
  }
  const handleChange = (event) => {
    const input = event.target;
    const newValues = [...values];
    newValues[inputs.current.indexOf(input)] = input.value;
    ar.push(newValues)
    setN(newValues)
    setValues(newValues);
    moveFocus(event);
    // if(n.length ===4) {
    //   alert()
    //   const form = formRef.current;

    //   // Create a new `submit` event
    //   const submitEvent = new Event('submit');

    //   // Dispatch the event
    //   form.dispatchEvent(submitEvent);

    // }
  }

  const resendToken = async () => {
    try {
      setIsPending(true)
      // Make the API request
      let payload = {
        email: localStorage.getItem('e'),
      }
      setN([])
      setValues(['', '', '', '']);
      let inputsVal = document.querySelectorAll('.form-control');
      inputsVal.forEach(item => {
        item.value = ''
      })
      const res = await axios({
        method: "POST",
        url: `${baseUrl}/merchant/resend`,
        data: payload,
        headers: {
          'Authorization': `x-auth-token ${localStorage.getItem('token')}`,
        },
      });


      notify2()
      setTimerFinished(false);

    } catch (err) {
      notifyError()
      setSignUpError(err.response.data.message)
    } finally {
      setIsPending(false)


    }
  };



  function HandleFormSubmit(e) {

    e.preventDefault();
    let payload = {
      email: localStorage.getItem('e'),
      otp: n.join(''),
    };

    const fetchData = async () => {
      try {
        setIsPending(true)
        // Make the API request
        const res = await axios({
          method: "POST",
          url: `${baseUrl}/merchant/verify`,
          data: payload,
          headers: {
            'Authorization': `x-auth-token ${localStorage.getItem('token')}`,
          },
        });


        notify()

        setTimeout(() => {
          naviagteToLogin()
        }, 2000);
      } catch (err) {
        notifyError()
        setSignUpError(err.response.data.message)
      } finally {
        setIsPending(false)
      }
    };
    fetchData(true);

  }
  const naviagteToLogin = () => {
    navigate('/login');

  }

  return (
    <>

      <div className={`row ${styles.sav}`}>

        <div className="col-md-6 d-none d-md-block">
          <div className={styles.bg} style={{ backgroundImage: "linear-gradient(to right,  #52138d9f, #8c2d9176), url('/assets/happyMan.jpg')" }}>
            <img className={styles.star} src='/assets/Stars.svg' alt='stars' />
            <div className={styles.content}>
              <h5 className={`${styles.header_text}`}>
                Track and monitor <br /> your customers on <br /> a single dashboard
              </h5>
              <p>
                Make informed sales decisions by monitoring consumer <br /> behaviours, brand acceptance and market performance.
              </p>
              <div className={styles.icon_n_text}>
                <img className='icons' src='/assets/Avatars.svg' alt='avatars' />
                <span style={{ color: '#EAECF0' }}>
                  Join 40,000+ users
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 ">
          <Logo />
          <div className={`${styles.sign_up}  mx-auto`}>
            <h4 className=' ' style={{
              fontSize: '36px',
              fontWeight: '600',
              color: '#111827',
              marginTop: '100px',
              marginBottom: '50px',


            }}>Email Verification</h4>
            {(signUpError) && <Alert style={{
              border: '1px solid red'
            }} severity="error">{signUpError}</Alert>}


            <form ref={formRef} onSubmit={HandleFormSubmit} className={styles.form}>

              <div className='my-4 inputs_n_label'>
                <p style={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '150%',
                  color: '#374151',
                }}>
                  Email OTP code sent to your email
                </p>
                <div className={`d-flex ${styles.input_container}`}>


                  {/*  */}
                  <div className="col-3 p-0 m-0">
                    <input type="number" maxLength={1} className="form-control" onChange={handleChange} ref={(el) => inputs.current[0] = el} />
                  </div>
                  <div className="col-3 p-0 m-0">
                    <input type="number" maxLength={1} className="form-control" onChange={handleChange} ref={(el) => inputs.current[1] = el} />
                  </div>
                  <div className="col-3 p-0 m-0">
                    <input type="number" maxLength={1} className="form-control" onChange={handleChange} ref={(el) => inputs.current[2] = el} />
                  </div>
                  <div className="col-3 p-0 m-0">
                    <input type="number" maxLength={1} className="form-control" onChange={handleChange} ref={(el) => inputs.current[3] = el} />
                  </div>
                </div>
              </div>
              <div className="container text-[#53138D]" style={{ display: 'flex', justifyContent: 'end'}}>
                {!timerFinished && <CountdownTimer onTimerFinish={handleTimerFinish} />}
                {timerFinished && (
                  <div className="text-center float-right">
                    <small class="text-dark mr-1" style={{ fontSize: '13px'}}>I didn't receive a code:</small>
                    <span style={{ cursor: 'pointer', fontSize: '14px', textDecoration: 'underline' }} className='text-[#53138D] mr-2' onClick={resendToken}>
                      Resend OTP
                    </span>
                  </div>
                )}
              </div>
              <div className="text-censter text-muted mb-3 mt-4" style={{
                fontSize: '12px'

              }}>
                <strong>Note:</strong> Check your email promotions or spam if you don’t <br /> find OTP in your inbox
              </div>
              {/* <div className='text-center'>
                <span style={{
                  cursor: 'pointer'
                }} className='text-primary' onClick={resendToken}>
                  Resend OTP
                </span>
              </div> */}

              <p className="text-right text-muted">
                <span onClick={naviagteToLogin} className="cursor_pointer">
                  Back to Login
                </span>
              </p>
              <div className={styles.buttons}>
                {!isPending &&
                  <button className={`btn btn-block ${styles.btn_custom_color}`}>
                    <span className={`${styles.span}`}>
                      Proceed

                    </span>
                    {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                  </button>}
                {isPending && <button disabled className={`btn btn-block ${styles.btn_custom_color}`}>
                  Processing &nbsp;
                  {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                </button>}


              </div>

            </form>
          </div>
        </div>
      </div>
    </>
  );
}
