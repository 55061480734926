import styles from "./Outlet.module.css"
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// import AllStores from "./AllStores";
import AllStores from "./Superadmin/AllStores";
import Map from "./Superadmin/Maps";
import Box from '@mui/material/Box';
import { useEffect, useState } from "react";
import axiosInstance from "src/axiosInstance"
import toast from "react-hot-toast";
import bus from "src/bus";

export default function BrandOwnerStors() {

  const [value, setValue] = useState('1');
  const [isPending, setIsPending] = useState(false);
  const [filterSearch, setFilterSearch] = useState({
    location: '',
    category: '',
    status: '',
    dateStart: null,
    dateEnd: null,
  });
  const [searchvalue] = useState('');
  const notifyError = (err) => toast.error(err);
  const [locations, setLocation] = useState([
    { lat: 7.945211, lng: 4.788842, name: 'Shop rite', store_address: '' },
    { lat: 6.5037588, lng: 3.6014641, name: 'Delight Store', store_address: '' },
    { lat: 6.8299846, lng: 3.9164585, name: 'R N B ', store_address: '' },
  ]);


  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchData = (page = 1, pageSize = 1000) => {
    let filter_payload = {};
    if (filterSearch.status && filterSearch.status !== 'select' && filterSearch.status !=='all') {
      filter_payload.status = filterSearch.status
    }
    if (filterSearch.search !== '') {
      filter_payload.search = searchvalue
    }
    if (filterSearch.dateFrom !== '') {
      filter_payload.dateStart = filterSearch.dateFrom
    }
    if (filterSearch.dateTo !== '') {
      filter_payload.dateEnd = filterSearch.dateTo
    }
    if (filterSearch.category && filterSearch.category  !== 'select') {
      filter_payload.category = filterSearch.category
    }
    if (filterSearch.location !== '') {
      filter_payload.location = filterSearch.location
    }
    setIsPending(true);
    axiosInstance.get('/admin/stores', {
      params: {
        page,
        pageSize,
        ...filter_payload
      }
    })
      .then(res => {
        const data = res.data.data.data.filter(el => el.longitude && el.latitude).map(el => {
          return { lat: Number(el.latitude), lng: Number(el.longitude), name: el.store_name, store_address: el.store_address }
        })
        setLocation(data);
        filterSearch.location = ''
        filterSearch.category = ''
        filterSearch.status = ''
        filterSearch.dateStart = ''
        filterSearch.dateEnd = ''
      }).catch(err => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError("Unable to fetch store, try again later");
        }
      }).finally(() => setIsPending(false))
  }

  useEffect(() => {
    bus.on('filter_set', (data) => {
      setFilterSearch(data)
    })
    fetchData()
    bus.on('update-store', (data) => {
      const prevLocations = [...locations]
      prevLocations.push({
        lat: Number(data.latitude),
        lng: Number(data.longitude),
        name: data.store_name
      })

      setLocation(prevLocations)

    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchData()

    
  },[filterSearch])

  return (
    <>

      <div className="wrapper mt-1 px-2">
        <div className="header d-block d-md-none mb-4">
          <div className="d-md-flex justify-content-between">
            <div className="mb-3">
              <h4 className={`mb-1 mb-md-0 pb-0`}>Stores</h4>

              <p style={{ fontSize: '14px' }} className={`mb-0 pb-0`}>
                Manage and monitor your Stores here.


              </p>

            </div>
            <div className="">

              <button className={`btn ${styles.import}`}>
                Import CSV File
              </button>
            </div>

          </div>

        </div>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs ">
              <Tab label="All Stores" value="1" />
              <Tab label="Map View" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" style={{ padding: '0' }}>
            <AllStores key={1} />
          </TabPanel>
          <TabPanel value="2" style={{ padding: '0' }}>
            <Map locations={locations} key={2} />
          </TabPanel>
        </TabContext>


      </div>
    </>
  )
}
