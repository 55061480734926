import { useEffect, useState } from 'react';
import styles from './Outlet.module.css';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import { BallTriangle } from 'react-loader-spinner';

// sections
// mock

export default function Media() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const notifyError = (err) => toast.error(err);
  const [search, setSearch] = useState('');

  const fetchData = () => {
    setIsLoading(true);
    axiosInstance
      .get('/admin/marketing/products-image?search=' + search)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        if (err) {
          notifyError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [search]);

  return (
    <>
      <div className=" px-2">
        <div className={`${styles.filters} `}>
          <div className="row">
            <div className={`${styles.hold_input} col-6   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search"
                style={{ textIndent: '25px', width: '60%' }}
                className=" form-control"
              />
            </div>
          </div>
        </div>
        {/* <div className="card mt-3">
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h5 className="mb-1"><strong>Media</strong></h5>
                                <p className="pb-0 text-muted" style={{ fontSize: '14px', }}>
                                    View all media uploads
                                </p>

                            </div>
                            <div className="pr-0">
                                <button className="btn btn_primary">
                                    Add Image
                                </button>

                            </div>

                        </div>

                    </div>
                </div> */}
        <div>
          <div className="cards mt-5">
            {/* <p>25-10-2022</p> */}
            <div className="row">
              {!isLoading &&
                data.length > 0 &&
                data?.map((el, i) => (
                  <div className="col-md-3 mb-5" key={i}>
                    <div className="card">
                      <div className="card-body text-center">
                        <img
                          style={{
                            height: '100px',
                          }}
                          src={el.product_image}
                          alt=""
                        />

                        <p>{el.product_name}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="d-flex justify-content-center">
              {isLoading && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!data.length && !isLoading && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
