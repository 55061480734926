import './appLogo.css';
export default function Logo(params) {
  return (
    <div className="pt-2 app_logo_container">
      <img
        className="logo"
        style={{ display: 'inline', width: '100px' }}
        src="/assets/icons/xpress.png"
        alt="logo"
      />
    </div>
  );
}
