// ----------------------------------------------------------------------
import styles from '../BrandOwner.module.css';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import DiscountModal from './DiscountModal';
import bus from 'src/bus';
import axiosInstance from 'src/axiosInstance';
import CircularProgress from '@mui/material/CircularProgress';
import LiveSelect from 'react-select';
import { InputFile } from '../../../components';
// import ImagePreview from "src/components/file-input/ImagePreveiw";

// sections
// mock
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { createRef } from 'react';
// ----------------------------------------------------------------------
import { useCategoriesApi } from 'src/context/CategoriesApi';
import { useTagApi } from 'src/context/ProductTagApi';
import toast from 'react-hot-toast';
import { useAuth } from 'src/hooks/useAuth';
import { ImagePreviewModal } from 'src/components';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 230,
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 1,
};

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 190,
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 1,
};

const style3 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  // height: 190,
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 1,
};

const style1 = {
  position: 'absolute',
  height: '700px',
  overflow: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  backgroundColor: '#FFFFFF',
  p: 3,
};

// ptvp=PAGE TO VIEW (PRODUCT)

export default function ProductsModal({ closeModal, refresh }) {
  const { data } = useAuth();
  let textInput = createRef();
  const categoriesApi = useCategoriesApi();
  const tagApi = useTagApi();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [originalFile, setOriginalFile] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [showDiscount, setShowDiscount] = useState(false);
  const [discountValue, setDiscountValue] = useState({});
  const [openGoToCart, setopenGoToCart] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [enableProductEdit, setEnableProductEdit] = useState(false);
  const [action_type, setActionType] = useState('Add');
  const [editData, setEditData] = useState({});
  const [createdProduct, setCreatedProduct] = useState({});
  const [tagValue] = useState([]);
  const [product_spec, setProductSpec] = useState('');
  const [spec_value, setSpecValue] = useState('');
  const [allBrands, setAllBrands] = useState([]);
  const isAdmin = data.user_type && data.user_type === 'admin';
  const [newProducts, setNewProducts] = useState({
    notifications: [],
    product_name: '',
    product_image: null,
    brand_name: null,
    po_amount: '',
    price_per_case: '',
    // price_per_product: '',
    // number_of_cases: '',
    // products_in_case: '',
    description: '',
    cases_type: 'Cartons',
    product_category: '',
    tag_ids: [],
    po_document: previewData,
  });
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const handleGoToCartClose = () => setopenGoToCart(false);
  const [open1, setOpen1] = useState(false);

  const handleClose1 = () => {
    setNewProducts({
      notifications: [],
      product_name: '',
      product_image: null,
      price_per_case: '',
      price_per_product: '',
      number_of_cases: '',
      products_in_case: '',
      description: '',
      cases_type: 'Cartons',
      product_category: '',
      tag_ids: [],
    });
    setEnableProductEdit(false);
    setPreviewData(false);
    setActionType('Add');
    setUrl('');
    setOpen1(false);
  };
  const [category, setCategory] = useState(1);
  const [url, setUrl] = useState('');
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open2 = Boolean(anchorEl1);
  const notifyError = (err) => toast.error(err);

  const handleProductChange = (key, value, label) => {
    setAnchorEl1(null);
    if (key === 'brand_id') setSelectedOption({ label, value });
    setNewProducts((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleTagChange = (data) => {
    setNewProducts((prev) => {
      let tag_datas = [...prev.tag_ids];

      // Check if the value already exists in the array
      const valueExists = tag_datas.includes(data.value);

      // If the checkbox is checked and the value is not already in the array, add it
      // If the checkbox is unchecked and the value is in the array, remove it
      if (data.checked) {
        if (!valueExists) {
          tag_datas.push(data.value);
        }
      } else {
        if (valueExists) {
          const index = tag_datas.indexOf(data.value);
          tag_datas.splice(index, 1);
        }
      }

      return {
        ...prev,
        tag_ids: tag_datas,
      };
    });
  };

  // const handleTagChange = (target) => {
  //     const value = target.value;
  //     const checked = target.checked;

  //     // Update the selected IDs array based on the checkbox change
  //     if (checked) {
  //       setSelectedIds((prevIds) => [...prevIds, value]);
  //       console.log(selectedIds);
  //     } else {
  //       setSelectedIds((prevIds) => prevIds.filter((id) => id !== value));
  //       console.log();
  //     }
  //   };
  useEffect(() => {
    console.log(newProducts?.tag_ids);
  }, [newProducts?.tag_ids]);

  const handleProductSubmit = (e) => {
    e.preventDefault();
    setIsPending(true);
    const formData = new FormData();
    formData.append('product_spec', product_spec);

    // formData.append("product_image", newProducts.product_name);

    Object.keys(newProducts).forEach((key) =>
      formData.append(key, newProducts[key])
    );

    if (action_type === 'Add' || action_type === 'Edit') {
      formData.delete('tag_ids');

      for (let i = 0; i < newProducts.tag_ids?.length; i++) {
        formData.append('tag_id', newProducts.tag_ids[i]);
      }
    }

    setIsPending(true);
    let checkAuth = JSON.parse(localStorage.getItem('auth'));
    let endpoint = '';

    if (checkAuth.user_type !== 'admin') {
      formData.append('brand_name', checkAuth.business_name);
    }

    if (checkAuth.user_type !== 'admin') {
      formData.delete('price_per_case');
      formData.delete('price_per_product');
      formData.delete('number_of_cases');
      formData.delete('products_in_case');
      formData.delete('notifications');
    }

    if (action_type === 'Add') {
      if (checkAuth.user_type === 'admin') {
        endpoint = '/admin/product/create';
      } else {
        endpoint = `/brand/product/add-product/${newProducts.product_category}`;
      }
    }
    if (action_type === 'Edit') {
      if (checkAuth.user_type === 'admin') {
        endpoint = `/admin/product/${newProducts._id}`;
      } else {
        endpoint = `/brand/product/update-product/${newProducts._id}`;
      }
    }

    axiosInstance[action_type === 'Add' ? 'post' : 'put'](endpoint, formData)
      .then((res) => {
        setOpen1(false);
        setopenGoToCart(!openGoToCart);
        bus.emit('reload_product');
        setCreatedProduct(res.data.data);
        refresh();
        setEnableProductEdit(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          // notifyError("Unable to save, try again later");
        }
      })
      .finally(() => setIsPending(false));
  };

  const fetchBrands = () => {
    axiosInstance
      .get(`/admin/product/brands`)
      .then((res) => {
        setAllBrands(res.data.data);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to save, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const handleFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      // Create a FileReader object to read the image file
      const reader = new FileReader();

      // Set up a callback function to be executed when the FileReader has finished reading the file
      reader.onload = (event) => {
        const image = new Image();

        // Set up a callback function to be executed when the image has loaded
        image.onload = () => {
          const { width, height } = image;

          // Specify the maximum allowed dimensions
          const maxWidth = 76;
          const maxHeight = 100;

          // Check if the image dimensions exceed the maximum allowed dimensions
          //   if (width > maxWidth || height > maxHeight) {
          //     // Throw an error if the image dimensions are too large
          //     return notifyError( `Image dimensions must be ${maxWidth}px by ${maxHeight}px.`);

          //   } else {
          // If the image dimensions are within the allowed limits, proceed with handling the file
          setUrl(URL.createObjectURL(file));
          handleProductChange('product_image', file);
          //   }
        };

        // Set the image source to the data URL obtained from the FileReader
        image.src = event.target.result;
      };

      // Read the image file as a data URL
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    textInput.current.click();
  };

  const handleClickList1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const discountPage = (val) => {
    setShowDiscount(true);
    setDiscountValue(val);
    localStorage.setItem('setProduct', JSON.stringify(val));
    handleGoToCartClose();
    handleClose1();
    // bus.emit('open-discount', val)
  };

  useEffect(() => {
    bus.on('open-product', (val = {}, edit = null) => {
      if (val) {
        setEditData(val);
      }
      if (val.hasOwnProperty('edit') && Object.keys(val?.edit)) {
        delete val.edit.tag_id;
        setSelectedOption({
          value: val.edit.brand__id,
          label: val.edit.brand_name,
        });
        setNewProducts((prev) => {
          return {
            ...val?.edit,
            product_category: val?._id,
            price_per_case: val?.edit?.price_per_case,
            price_per_product:
              val?.edit?.price_per_product?.$numberDecimal ||
              val?.edit?.price_per_product ||
              0,
            more_info: val?.edit?.more_info,
          };
        });
        setUrl(val?.edit?.product_image);
        setActionType('Edit');
      }
      setOpen1(true);
    });
  }, [tagValue]);

  const handleFileUpload = (file) => {
    if (file) {
      setOriginalFile(file);
      setNewProducts((prev) => {
        return {
          ...prev,
          po_document: file,
        };
      });
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewData(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    handleProductChange('product_category', editData._id);
    setTimeout(() => {
      let inputs = document.querySelectorAll('.categoriesList');
      let mCheckbox = document.querySelectorAll('.mCheckbox');
      inputs.forEach((el, i) => {
        if (el.value === editData._id) el.setAttribute('checked', true);
      });
      if (action_type === 'Edit') {
        mCheckbox.forEach((el, i) => {
          if (newProducts.tag_ids === el.value)
            el.setAttribute('checked', true);
        });
      }
    }, 500);
  }, [editData]);
  useEffect(() => {
    if (isAdmin) fetchBrands();
  }, []);

  const handleEnableProductEdit = (val) => {
    setEnableProductEdit(!enableProductEdit);
    setUrl(val);
  };

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const handlePreviewImg = () => {
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  return (
    <>
      {/* Modals */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open}>
          <Box className={`mobile_modal_size`} sx={style}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3>Choose Category</h3>
                <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <form action="">
                <div className="mb-3">
                  <label htmlFor="name">Category Name</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={category}
                    required
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>Breveges</MenuItem>
                    <MenuItem value={2}>Table water</MenuItem>
                    <MenuItem value={3}>Snacks</MenuItem>
                  </Select>
                </div>
                <div className="mb-4">
                  <button
                    type="button"
                    onClick={handleClose}
                    className={`${styles.modal_btn} btn btn-block`}
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* Withdraw */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open1}>
          <Box className={`mobile_modal_size`} sx={style1}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3 style={{ fontSize: '25px' }}>
                  {action_type === 'Add' ? 'Add' : ' Edit'} Product{' '}
                </h3>
                <span onClick={handleClose1} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p>
                {action_type === 'Add' ? 'Add' : ' Edit'} product to this
                category
              </p>
              <form onSubmit={handleProductSubmit}>
                <div className="d-flex">
                  <p className={` label`}>Product Image* </p>
                  {action_type !== 'Add' && (
                    <p
                      className="ml-auto mt-3 text-primary"
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() =>
                        handleEnableProductEdit(newProducts?.product_image)
                      }
                    >
                      {!enableProductEdit
                        ? 'Re-upload Image'
                        : 'reverse changes'}
                    </p>
                  )}
                </div>
                <input
                  type="file"
                  hidden
                  ref={textInput}
                  name=""
                  onChange={handleFile}
                />
                <div
                  className={`${styles.retailer_modal_product_img}`}
                  onClick={url ? handlePreviewImg : null}
                  style={{
                    backgroundImage: `url('${url}')`,
                    cursor: 'pointer',
                  }}
                >
                  {(action_type !== 'Add'
                    ? !url || enableProductEdit
                    : !url) && (
                    <>
                      <div
                        style={{ cursor: 'pointer', display: 'inline-block' }}
                        onClick={() => handleButtonClick()}
                      >
                        <img
                          className={`${styles.retailer_product_image}`}
                          src="/assets/image.svg"
                          alt=""
                        />
                        {action_type !== 'Add' && (
                          <p
                            className="mb-0 text-center text-white bg-primary p-1"
                            style={{ fontSize: '10px' }}
                          >
                            Click to upload
                          </p>
                        )}
                        {action_type === 'Add' && (
                          <p
                            className="mb-0 text-center text-info p-1"
                            style={{ fontSize: '10px' }}
                          >
                            Click to upload
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="mb-3">
                  <label className={`label`} htmlFor="pname">
                    Product Name*
                  </label>
                  <TextField
                    required
                    type="text"
                    id="pname"
                    value={newProducts.product_name}
                    onChange={(e) =>
                      handleProductChange('product_name', e.target.value)
                    }
                    placeholder=""
                    size="small"
                    fullWidth
                  />
                </div>
                <div className="mb-3">
                  <label className={`label`} htmlFor="category">
                    Product Category*
                  </label>
                  <div>
                    {(data.merchant_type === 'brand'
                      ? data.product_categories
                      : data.merchant_type === 'shop'
                      ? data.products_sold
                      : categoriesApi
                    ).map((el, i) => (
                      <div className="categories" key={i}>
                        <input
                          className="categoriesList"
                          onChange={(e) =>
                            handleProductChange(
                              'product_category',
                              e.target.value
                            )
                          }
                          value={el._id}
                          type="radio"
                          name="same"
                          id="myCheckbox"
                        />
                        &nbsp;
                        {el?.category_name}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label className={`label`} htmlFor="spec">
                        Product Specification( g, ml, kg )
                      </label>
                      <TextField
                        type="text"
                        id="spec"
                        value={product_spec}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === '' || parseFloat(inputValue) > 0) {
                            setProductSpec(inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e') {
                            e.preventDefault();
                          }
                        }}
                        placeholder="0"
                        size="small"
                        fullWidth
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label className={`label`} htmlFor="desc">
                    Description *{' '}
                  </label>
                  <TextField
                    id="desc"
                    multiline
                    rows="4"
                    cols="3"
                    placeholder=" Best Product"
                    value={newProducts.description}
                    onChange={(e) =>
                      handleProductChange('description', e.target.value)
                    }
                    size="small"
                    fullWidth
                  />
                </div>
                {data.user_type === 'admin' && tagApi?.length > 0 && (
                  <div>
                    <label htmlFor="loc">Product Tag</label>
                    <div>
                      {tagApi?.map((el, i) => (
                        <div className="tag_id mr-2 mb-3" key={i}>
                          <input
                            type="checkbox"
                            value={el?._id}
                            checked={newProducts.tag_ids.includes(el?._id)}
                            onChange={(e) => handleTagChange(e.target)}
                            name="tag_ids"
                            className="mCheckbox"
                          />
                          &nbsp;
                          {el?.name}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {action_type === 'Add' && isAdmin === true && (
                  <div className="mb-4">
                    <label className={`label`} htmlFor="desc">
                      Brand *
                    </label>
                    <LiveSelect
                      defaultValue={selectedOption}
                      onChange={(e) => handleProductChange('brand_id', e.value)}
                      options={allBrands
                        ?.filter((option) => option.business_name)
                        .map((option) => ({
                          value: option._id,
                          label: option.business_name,
                        }))}
                    />
                  </div>
                )}
                {action_type === 'Edit' && isAdmin === true && (
                  <div className="mb-4">
                    <label className={`label`} htmlFor="desc">
                      Brand *
                    </label>
                    <LiveSelect
                      disabled
                      defaultValue={selectedOption}
                      onChange={(e) =>
                        handleProductChange('brand_id', e.value, e.label)
                      }
                      options={allBrands
                        ?.filter((option) => option.business_name)
                        .map((option) => ({
                          value: option._id,
                          label: option.business_name,
                        }))}
                    />
                  </div>
                )}
                {action_type === 'Add' && (
                  <div className="mb-4">
                    <label className={`label`} htmlFor="desc">
                      Upload PO *
                    </label>
                    {!previewData && (
                      <div>
                        <InputFile
                          accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                          uploaded={handleFileUpload}
                          show={true}
                        />
                      </div>
                    )}
                    {previewData && (
                      <div>
                        <embed src={previewData} width="500" height="200" />
                        <div
                          className="ml-auto mt-2 px-3"
                          style={{
                            bottom: '15px',
                            left: '0',
                            right: '0',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <button
                            onClick={() => setPreviewData(null)}
                            className="btn btn-light px-2 py-1 mx-2 rounded border-0"
                            style={{
                              backgroundColor: '#333 !important',
                              fontSize: '14px',
                            }}
                          >
                            <img
                              style={{ width: '15px' }}
                              src="/assets/icons/trash-2.png"
                              alt=""
                            />{' '}
                            Remove
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {action_type === 'Add' && (
                  <div className="mb-4">
                    <label className={`label`} htmlFor="pname">
                      Enter PO Amount *
                    </label>
                    <TextField
                      required
                      type="number"
                      id="poamount"
                      value={newProducts.po_amount}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue === '' || parseFloat(inputValue) > 0) {
                          handleProductChange('po_amount', inputValue);
                        }
                      }}
                      inputProps={{
                        inputMode: 'decimal',
                      }}
                      onKeyDown={(e) => {
                        if (e.key === '-' || e.key === 'e' || e.key === '.') {
                          e.preventDefault();
                        }
                      }}
                      placeholder="0"
                      size="small"
                      fullWidth
                    />
                  </div>
                )}
                <div className="mb-3">
                  {isPending && (
                    <button
                      disabled
                      className={`btn btn-block ${styles.modal_btn}`}
                    >
                      Submitting &nbsp;
                      {isPending && (
                        <CircularProgress
                          style={{ color: 'white' }}
                          size="1rem"
                        />
                      )}
                    </button>
                  )}
                  {!isPending && (
                    <button
                      type="submit"
                      className={`${styles.modal_btn} btn btn-block`}
                    >
                      Save
                    </button>
                  )}
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openGoToCart}
        onClose={handleGoToCartClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openGoToCart}>
          <Box
            className={`mobile_modal_size pt-md-3 ${styles.modal_bg}`}
            sx={style3}
          >
            <div className={`text-center ${styles.add_to_cart_content}`}>
              <img src="/assets/icons/Illustration.svg" alt="" />
              <h5 className="mt-3 mb-2">Product Added</h5>
              {action_type === 'Add' && (
                <span className="text-center" style={{ fontSize: '15px' }}>
                  An Email has being sent to you containing the P.O Approval
                  code
                </span>
              )}
              <div>
                {action_type === 'Add' && (
                  <button
                    className={`${styles.go_to} d-block mt-3 w-100 `}
                    style={{ fontSize: '14px' }}
                    onClick={() => {
                      discountPage(createdProduct);
                    }}
                  >
                    Proceed to Create Discount
                  </button>
                )}
                {action_type === 'Edit' && (
                  <button
                    onClick={() => setopenGoToCart(false)}
                    className={`modal_btn hover btn btn-block`}
                  >
                    Go to Product
                  </button>
                )}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ImagePreviewModal
        isOpen={isPreviewOpen}
        onClose={handleClosePreview}
        imageUrl={url}
        imageType={'Product Image'}
      />
      <DiscountModal openModal={showDiscount} discountValue={discountValue} />
    </>
  );
}
