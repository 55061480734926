import  { useEffect, useRef, useState } from 'react';
import axiosInstance from "src/axiosInstance"
import bus from 'src/bus';
import toast from 'react-hot-toast';

function AddToCart() {
  
  const notifyError = (err) => toast.error(err);
  const hasBeenHandled = useRef(false); // keep track of whether the event has already been handled or not



  const addToCart = (id, quantity) => {
    axiosInstance.post('/shop/cart/add-to-cart/' + id, {
      quantity: quantity
    })
      .then(res => {
        bus.emit('added-to-cart')

      }).catch(err => {
        notifyError(err.response.data.message)
      }).finally(() => {
        // hasBeenHandled.current = false; // mark the event as handled

      })
  }

  useEffect(() => {
    bus.on('add-p-to-cart' , (data) => {
  console.log(4);

      if (!hasBeenHandled.current) { // check if the event has already been handled
        hasBeenHandled.current = true; // mark the event as handled
        addToCart(data.id, data.quantity)
      }

    })

    
  }, [])




}

export default AddToCart;
