import styles from "./Order.module.css"
import { useEffect, useState } from "react"
import {
    Card,
} from '@mui/material';
import Scrollbar from '../../components/scrollbar';
import Modal from '@mui/material/Modal';
// sections
// mock
import { useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import bus from 'src/bus';
import axiosInstance from "src/axiosInstance"
// import { Login } from "@mui/icons-material";

const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    overflow: 'auto',
    bgcolor: '#F5F5F5',
    border: '1px solid #F5F5F5',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
  };

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'details', label: 'Product Details', alignRight: false },
    { id: 'quantity', label: 'Quantity', alignRight: false },
    { id: 'unitPrice', label: 'Unit price', alignRight: false },
    { id: 'totalPrice', label: 'Total price', alignRight: false },

];

// ----------------------------------------------------------------------

export default function OrderPage({ onChange }) {

    const navigate = useNavigate();
    const [otp, setOtp] = useState(false);
    const [modal_desc, setmodal_desc] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [singleOrder, setSingleOrder] = useState({});
    const [newValue, setNewValue] = useState(JSON.parse(localStorage.getItem('brand_order_details')) || {})
    const [salesOrder, setSalesOrder] = useState({
        shipping_fee: 0,
        subtotal: 0,
        product_items: []
    })
    const [isPending, setIsPending] = useState(false)
    
    
    

    function handleClick(event) {
        event.preventDefault();
        navigate('/app/dashboard')
    }
    const [modalTitle, setmodalTitle] = useState('');
    const submitOtpWallet = (e) => {
        e.preventDefault()
        setOtp(false);
        setmodalTitle('Refund complete')
        setmodal_desc('Your refund has been processed')
        setOpenDialog(true)
    }

    bus.on('getOrders', (val) => {
        // console.log(val);
        setSingleOrder(val)
    })
    console.log(onChange);

    const orderDetails = () => {
        setIsPending(true)
        axiosInstance.get('/brand/order/'+newValue._id)
            .then((res) => {
                setSalesOrder(res.data.data[0])
            }).finally(() => {
                setIsPending(false)
            })
    }

    useEffect(() => {
        console.log(newValue);
        orderDetails()
    }, [newValue])


    return (
        <>
            <div className={`pt-2 px-2 pt-4`}>
                <button onClick={handleClick} className="btn pl-0 mt-5">
                    <img src="/assets/icons/Steplink.svg" alt="" /> &nbsp;
                    Dashboard
                </button>
                {/* <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
        </Breadcrumbs> */}
                <div className={`${styles.header} mt-4`}>
                    <img src={singleOrder?.store_info?.store_logo} height={'70px'} width={'70px'} className="float-md-left mr-3" alt="" />

                    <div >
                        <h1 style={{ display: 'inline-block' }}>
                            {singleOrder?.store_info?.store_name}
                        </h1>
                        {
                            <small>
                                ({singleOrder?.store_info?.store_category})
                            </small>
                        }
                        
                        <p className="mb-1">
                            <small>
                                <img className="d-inline-block mr-1" src="/assets/icons/mail2.svg" alt="" />
                                {salesOrder?.shop_email}
                            </small>
                            &nbsp;&nbsp;&nbsp;
                            <small>
                                <img className="d-inline-block mr-1" src="/assets/icons/phone.svg" alt="" />

                                {singleOrder?.phone_number ? singleOrder?.phone_number : '---'}
                            </small>
                        </p>
                        <p>
                            <small className="ml-3">
                                <img className="d-inline-block mr-1 ml-5 pl-4" src="/assets/icons/map.svg" alt="" />

                                {singleOrder?.location?.address}
                            </small>

                        </p>

                    </div>

                </div>

                <Card style={{ marginTop: '50px', borderRadius: '10px', overflowX: 'hidden' }} className=' ss p-0' >

                    <Scrollbar style={{ overflowX: 'hidden' }}>
                        <div className="row">
                            <div className="col-md-8  mb-1">
                                <div className='pl-4 pr-3 pt-3 pb-0 mb-0'>
                                    <h3 className="font-weight-bold">
                                        Orders
                                    </h3>
                                    <small style={{ fontWeight: '200', fontSize: '12px' }}>
                                        Order Number: {salesOrder?.order_number}
                                    </small>
                                    <small style={{ display: 'inline-block', float: 'right' }}>
                                    {singleOrder?.product_unit} {singleOrder?.product_unit > 1 ? ' items' : ' item'}
                                    </small>

                                </div>
                                <div className={`table-responsive-sm mt-0 p-3`} style={{
                                    paddingTop: '0 !important',
                                    minHeight: '85em'
                                }}>
                                    <table className={`${styles.table} mt-0`}>
                                        <thead className={`${styles.thead}`}>
                                            <tr>
                                                {TABLE_HEAD.map((el, i) => {

                                                    return (
                                                        <th className="text-muted">{el.label}
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(isPending && salesOrder?.product_items?.length > 0) && salesOrder?.product_items?.map((el,i) => {
                                                <tr className={`${styles.tr}`}>
                                                    <td>
                                                        <div className=" ">
                                                            <div className=" ">
                                                                <img
                                                                    className="flsoat-left"
                                                                    src={el?.product_image ? el.product_image : '/assets/purelifetablewater.svg'}
                                                                    alt=""
                                                                    style={{
                                                                    }}
                                                                />
                                                                &nbsp;
                                                                &nbsp;
                                                                <div style={{
                                                                    display: 'inline-block'
                                                                }}>
                                                                    <p className="mb-0" style={{ fontSize: '14px' }}>
                                                                        <span>
                                                                            {el?.product_name}
                                                                        </span>
                                                                    </p>
                                                                    <span style={{ fontSize: '10px' }}>
                                                                        50kg
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {Number(el?.quantity).toLocaleString()}
                                                    </td>
                                                    <td>
                                                        ₦ {Number(el?.unit_price).toLocaleString()}
                                                    </td>
                                                    <td>
                                                        ₦ {Number(el?.total_price).toLocaleString()}
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4" style={{ background: '#E5E7EB', }}>
                                <div className=" pt-4 px-2 border-bottom" style={{ background: 'inherit !important' }}>
                                    <h4 className="font-weight-bold">Summary</h4>
                                </div>
                                <div className="border-bottom-white mt-4 px-2">
                                    <div className="d-flex justify-content-between">
                                        <p style={{
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                            lineHeight: ' 150%',
                                            color: '#1F2937'
                                        }}>
                                            Total Order
                                        </p>
                                        <p style={{
                                            fontWeight: '400',
                                            fontSize: '13px',
                                            lineHeight: ' 150%',
                                            color: '##374151'
                                        }}>
                                            ₦ {Number(salesOrder?.total_cost).toLocaleString()}
                                        </p>

                                    </div>
                                    <div className="">
                                        {singleOrder.status === 'completed' &&
                                            <button onClick={submitOtpWallet} 
                                                className={`modal_btn btn btn-block`}
                                                style={{
                                                    marginTop: '7cm',
                                                }}>
                                                Refund
                                            </button>
                                        }
                                    </div>
                                </div>
                                <div className={`${styles.sub_total} py-4 px-2`} style={{
                                    position: 'absolute',
                                    width: '89%',
                                    bottom: 0,
                                    borderTop: '2px solid #FFFFFF '
                                }}>
                                    <div className="d-flex justify-content-between">
                                        <p style={{
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                            lineHeight: ' 150%',
                                            color: '#1F2937'
                                        }}>
                                            Total
                                        </p>
                                        <p className="font-weight-bold" style={{
                                            fontSize: '20px',
                                            lineHeight: ' 150%',
                                            color: '##374151'
                                        }}>
                                            ₦ {Number(salesOrder?.total_cost).toLocaleString()}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Scrollbar>

                    {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
                </Card>


            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
                disableEnforceFocus
            >
                <Fade in={openDialog}>
                <Box className={`mobile_modal_size modal_bg`} sx={style1}>
                    <div className={`text-center add_to_cart_content`}>
                    <img src="/assets/icons/circleCheck.svg" alt="" />
                    <h5 className="mt-3 mb-2" style={{ fontWeight: 'bold' }}>{modalTitle}</h5>
                    <p className='text-center' style={{ textAlign: 'center !important' }}>
                        {modal_desc}

                    </p>
                    <button onClick={() => setOpenDialog(false)} className={`modal_btn hover btn btn-block`}>
                        Go to Orders
                    </button>
                    </div>
                </Box>
                </Fade>
            </Modal>
        </>
    );
}
