import React, { useState, useEffect } from "react";
import styles from "./InputFile.module.css";
import bus from "src/bus";

const InputFile = ({ uploaded, show, disabled, accept }) => {
    const [preveiw, setPreview] = useState('/assets/dropBox.svg');
    const [overSizedImg, setOverSizedImg] = useState(false);
    const [isDraggingOver, setIsDraggingOver] = useState(false);

    const handleFile = (file) => {
        if (file) {
            setPreview(URL.createObjectURL(file));
            uploaded(file);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        if (!disabled) {
            setIsDraggingOver(true);
        }
    };

    const handleDragLeave = () => {
        setIsDraggingOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDraggingOver(false);
        if (e.dataTransfer.files.length > 0) {
            const droppedFile = e.dataTransfer.files[0];
            handleFile(droppedFile);
        }
    };

    useEffect(() => {
        bus.on('overSizedImg', (val) => {
            if (val) {
                setOverSizedImg(true);
            }
        });
    }, []);

    return (
        <div
            className={`${styles.file_input_container} ${isDraggingOver ? styles.drag_over : ""}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <label>
                <div className={styles.input_inner}>
                    <figure>
                        <img style={{ height: '70px' }} src={preveiw} alt="" />
                    </figure>

                    {!disabled && (
                        <input accept={accept} type="file" onChange={(e) => handleFile(e.target.files[0])} />
                    )}
                    {show && (
                        <div>
                            <span className="mb-3">
                                {isDraggingOver ? "Drop Files to Upload" : "Drag and Drop Files Here"}
                            </span>
                            <br />
                            <small>or</small>
                            <div className={`${styles.btn_styles}`}>Select Files</div>
                        </div>
                    )}
                </div>
            </label>
        </div>
    );
};

export default InputFile;
