import { useEffect, useState } from 'react';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import  { Toaster } from 'react-hot-toast';
import LoadingBackground from './LoadingBackground';

// ----------------------------------------------------------------------

export default function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate the loading process
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);


  return (
    <ThemeProvider>
      {isLoading ? (
        <LoadingBackground />
      ):(
        <>
          <Toaster />

        <ScrollToTop />
        <StyledChart />
        <Router />
        </>
      )}
    </ThemeProvider>
  );
}
