import Admin from "./admin";
import Media from "./media";
import Website from "./website";
import AdRequest from "./adRequest";
import Notification from "./notification";
import Mobile from "./mobile/mobile";

// sections
// mock
import { useEffect, useState } from "react";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import axiosInstance from "src/axiosInstance"
import toast from 'react-hot-toast';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function MarketingIndex() {
  const [value, setValue] = useState("1");
  const [count, setCount] = useState("1");
  const notifyError = (err) => toast.error(err);


  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetch = () => {
    axiosInstance.get(`/admin/marketing/ads-request/all?count=true`)
      .then(res => {
        setCount(res.data.data)

      }).catch(err => {

        if (err) {
          notifyError(err.response.data.message);
    
        }
      })
  }

  useEffect(() => {
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="wrapper pt-2 px-2">
        <div className="header d-block d-md-none mb-4">
          <div className="d-md-flex justify-content-between">
            <div className="mb-3">
              <h4 className={`mb-1 mb-md-0 pb-0`}>Marketing</h4>

              <p style={{ fontSize: "14px" }} className={`mb-0 pb-0`}>
                Manage your post and adverts
              </p>
            </div>
          </div>
        </div>
        <TabContext value={value}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs ">
            <Tab label="Mobile" value="1" />
            <Tab label="Website  " value="2" />
            <Tab label="Admin" value="3" />
            <Tab
              label={
                <small>
                  <span className="font-weight-bold">Ads Requests</span>{" "}
                  <span className="success">{count}</span>
                </small>
              }
              value="4"
            />
            <Tab label="Media  " value="5" />
            <Tab label="Push Notification  " value="6" />
          </TabList>

          <TabPanel value="1" style={{ paddingLeft: "0" }}>
            <Mobile key={1} />
          </TabPanel>

          <TabPanel value="2" style={{ paddingLeft: "0" }}>
            <Website key={2} />
          </TabPanel>

          <TabPanel value="3" style={{ paddingLeft: "0" }}>
            <Admin key={3} />
          </TabPanel>

          <TabPanel value="4" style={{ paddingLeft: "0" }}>
            <AdRequest reload={fetch} key={4} />
          </TabPanel>

          <TabPanel value="5" style={{ paddingLeft: "0" }}>
            <Media key={5} />
          </TabPanel>

          <TabPanel value="6" style={{ paddingLeft: "0" }}>
            <Notification key={6} />
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
}
