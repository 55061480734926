
export const useCategories = () => {
    return [
        {name: 'Baby Products'},
        {name: 'Household Care'},
        {name: 'Health, Beauty & Personal Hygiene'},
        {name: 'Beverages'},
        {name: 'Food'},
        {name: 'Alcoholic Bevrages'},
        {name: 'Pet Foods'},
        {name: 'Tobacco'},
    ]


}