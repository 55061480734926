/* eslint-disable react-hooks/exhaustive-deps */
import styles from './Outlet.module.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { forwardRef } from 'react';
import Slide from '@mui/material/Slide';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import { BallTriangle } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#FFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 2,
};

export default function Notification() {
  const [open, setOpen] = useState(false);
  const [fetchPageSize, setPageSize] = useState(12);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openDialog, setDiologOpen] = useState(false);
  const [openGoToCart, setopenGoToCart] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currDate, setCurrData] = useState({});
  const [search, setSearch] = useState('');
  const notify = (messg) => toast.success(messg);
  const notifyError = (err) => toast.error(err);
  const [info, seInfo] = useState({
    targeted_audience: '',
    action: '',
    frequency: '',
    medium: [],
    startDate: startDate,
    endDate: endDate,
    time_value: fetchPageSize,
    custom_frequency: '',
    time_frequency: 'am',
    day_frequency: 'daily',
  });

  const mediums = ['email', 'sms', 'in-app notification'];

  const formatMediumLabel = (medium) => {
    if (medium === 'sms') {
      return medium.toUpperCase();
    } else if (medium === 'in-app notification') {
      return 'In-App Notification';
    } else {
      return medium.charAt(0).toUpperCase() + medium.slice(1);
    }
  };

  const handleDialogClose = () => {
    setDiologOpen(false);
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    console.log(event.target);
    seInfo((prevState) => {
      if (
        name === 'sms' ||
        name === 'email' ||
        name === 'in-app notification'
      ) {
        console.log(name);
        if (checked) {
          return { ...prevState, medium: [...prevState.medium, value] };
        } else {
          return {
            ...prevState,
            medium: prevState.medium.filter((item) => item !== value),
          };
        }
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  };

  const updatePageSize = (el) => {
    setPageSize((prevPageSize) => {
      if (el === 'up' && prevPageSize < 12) {
        return prevPageSize + 1;
      } else if (el === 'down' && prevPageSize > 1) {
        return prevPageSize - 1;
      } else {
        return prevPageSize;
      }
    });
  };

  const save = (e) => {
    e.preventDefault();

    const setNotificationDetails = {
      ...info,
      custom_frequency: `${fetchPageSize}${info.time_frequency}${' '}${
        info.day_frequency
      }`,
      time_value: fetchPageSize,
      startDate,
      endDate,
    };

    setIsProcessing(true);
    const endpoint = setNotificationDetails.hasOwnProperty('_id')
      ? '/admin/marketing/push-notification/update'
      : '/admin/marketing/push-notification/create';
    const method = setNotificationDetails.hasOwnProperty('_id')
      ? 'put'
      : 'post';
    axiosInstance[method](endpoint, setNotificationDetails)
      .then((res) => {
        setOpen(false);
        notify(res.data.message);
        seInfo({
          targeted_audience: 'select',
          action: 'select',
          frequency: '',
          medium: [],
          time_value: 0,
          time_frequency: 'am',
          day_frequency: 'daily',
          startDate: null,
          endDate: null,
        });
        setStartDate(null);
        setEndDate(null);
        setPageSize(12);
        setReload(!reload);
      })
      .catch((err) => {
        if (err) {
          notifyError(err.response.data.message);
        }
      })
      .finally(() => setIsProcessing(false));
  };

  const handleDeleteProcess = () => {
    setIsProcessing(true);

    axiosInstance
      .delete(`/admin/marketing/push-notification/delete/${currDate._id}`)
      .then((res) => {
        setDiologOpen(false);
        notify(res.data.message);
        setCurrData({});
        setReload(!reload);
        setopenGoToCart(true);
      })
      .catch((err) => {
        if (err) {
          notifyError(err.response.data.message);
        }
      })
      .finally(() => setIsProcessing(false));
  };

  const fetchData = () => {
    setIsLoading(true);
    axiosInstance
      .get(`/admin/marketing/push-notification?search=${search}`)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        if (err) {
          notifyError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [reload, search]);

  return (
    <>
      <div className=" px-2">
        <div className={`${styles.filters} `}>
          <div className="row">
            <div className={`${styles.hold_input} col-6   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search"
                style={{ textIndent: '25px', width: '60%' }}
                className=" form-control"
              />
            </div>
            <div className="col-6 text-right">
              <button className="btn btn_primary" onClick={() => setOpen(true)}>
                +{' '}
                <span style={{ color: '#ffffff', fontSize: '14px' }}>
                  Create New Template
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className={`mt-3 ${styles.notification}`}>
          <div className="row">
            {!isLoading &&
              data.length > 0 &&
              data?.map((el, i) => (
                <div className="col-md-6 mb-1" key={i}>
                  <div className={`card ${styles.card_control}`}>
                    <div className="card-body ">
                      <div className="d-flex justify-content-between mb-4">
                        <div>
                          <span>Targeted Audience:</span>
                          &nbsp; &nbsp;
                          <span className="text-[#af3585]">
                            {el.targeted_audience}
                          </span>
                        </div>
                        <div>
                          <div className="dropleft ">
                            <span
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              className={`${styles.action_button} px-3 py-2`}
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <img src="/assets/icons/action.svg" alt="" />
                            </span>

                            <div
                              className="dropdown-menu drop-left"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <button
                                type="button"
                                onClick={() => {
                                  setOpen(true);
                                  seInfo(el);
                                }}
                                className="rss dropdown-item btn border-0"
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  setDiologOpen(true);
                                  setCurrData(el);
                                }}
                                className="rss dropdown-item btn border-0"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-4">
                        <span>Action:</span>
                        &nbsp; &nbsp;
                        <span>{el.action}</span>
                      </div>
                      <div className="mb-4">
                        <span>Message:</span>
                        &nbsp; &nbsp;
                        <p>{el.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="d-flex justify-content-center">
            {isLoading && (
              <BallTriangle
                height={50}
                width={55}
                radius={5}
                color="#af3585"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            )}
          </div>
          {!data.length && !isLoading && (
            <div className="alert alert-[#af3585] text-center">
              <h4>No record found</h4>
            </div>
          )}
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open}>
          <Box
            sx={style}
            className={`mobile_modal_size`}
            style={{ maxHeight: '95vh', overflowY: 'auto' }}
          >
            <div
              className={`modal_content`}
              style={{
                maxHeight: '100%',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
              }}
            >
              <div className={`modal_header mb-0`}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}
                >
                  Ad Notification template
                </h5>
                <span
                  onClick={() => setOpen(false)}
                  style={{ cursor: 'pointer' }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: '14px',
                }}
              >
                Automate your sending of notifications
              </p>

              <form onSubmit={save} className="mt-0">
                <div className="mb-4 inputs_n_label">
                  <label htmlFor="target">Targeted User*</label>
                  <Select
                    labelId="s"
                    id="target"
                    fullWidth
                    size="small"
                    value={info.targeted_audience ?? ''}
                    name="targeted_audience"
                    required
                    onChange={handleChange}
                  >
                    {/* <MenuItem value={'select'}>Select</MenuItem> */}
                    <MenuItem value={'All Users'}>All Users</MenuItem>
                    <MenuItem value={'New Users'}>New Users</MenuItem>
                    <MenuItem value={'Brands'}>Brands</MenuItem>
                    <MenuItem value={'Storeowners'}>Merchants</MenuItem>
                    {/* <MenuItem value={'Storeowners'}>Shoppers</MenuItem> */}
                  </Select>
                </div>
                <div className="mb-4 inputs_n_label">
                  <label htmlFor="action">Medium</label>
                  <div className="d-flex">
                    {mediums.map((medium, index) => (
                      <div className="categories" key={index}>
                        <input
                          type="checkbox"
                          id={`checkbox-${medium}`}
                          name={medium}
                          value={medium ?? []}
                          checked={info?.medium?.includes(medium) ?? []}
                          onChange={handleChange}
                        />
                        &nbsp;&nbsp; {formatMediumLabel(medium)}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mb-4 inputs_n_label">
                  <label htmlFor="action">Actions*</label>
                  <Select
                    labelId="s"
                    id="action"
                    fullWidth
                    size="small"
                    value={info.action ?? ''}
                    name="action"
                    required
                    onChange={handleChange}
                    style={{ fontSize: '12px' }}
                  >
                    {/* <MenuItem value={'select'}>Select</MenuItem> */}
                    <MenuItem value={'New Shopper'}>New Shopper </MenuItem>
                    <MenuItem value={'New Brand Owner'}>
                      New Brand Owner
                    </MenuItem>
                    <MenuItem value={'Order Completed'}>
                      Order Completed
                    </MenuItem>
                    <MenuItem value={'Pending Cart'}>Pending Cart</MenuItem>
                    <MenuItem value={'Product Approval'}>
                      Product Approval
                    </MenuItem>
                    <MenuItem value={'Product Rejected'}>
                      Product Rejected
                    </MenuItem>
                    <MenuItem value={'Referral'}>Referral</MenuItem>
                    <MenuItem value={'Voucher'}>Voucher</MenuItem>
                  </Select>
                </div>
                <div className="mb-4 inputs_n_label">
                  <label htmlFor="frequency">Frequency*</label>
                  <Select
                    labelId="s"
                    id="frequency"
                    fullWidth
                    size="small"
                    value={info.frequency ?? ''}
                    name="frequency"
                    required
                    onChange={handleChange}
                  >
                    <MenuItem value={'recurring'}>Recurring </MenuItem>
                    <MenuItem value={'custom'}>Custom</MenuItem>
                  </Select>
                </div>
                {info?.frequency === 'custom' && (
                  <div className="mb-4 inputs_n_label">
                    <label htmlFor="frequency">Set Interval</label>
                    <div className="row">
                      <div className="col-md-6 mb-1">
                        {/* <label htmlFor="price">Date </label> */}
                        <div className=" d-flex">
                          <div className="" style={{ width: '100%' }}>
                            <label
                              htmlFor="ounit"
                              style={{
                                fontWeight: 'normal',
                                fontSize: 'small',
                              }}
                            >
                              Start Date{' '}
                            </label>
                            <div className="mb-3">
                              <DatePicker
                                showIcon
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="dd/MM/yyyy"
                                className="form-control pl-1"
                                // placeholderText="Start"
                                required
                                minDate={new Date()}
                              />
                            </div>
                          </div>
                          <div
                            className="px-1 d-flex   align-items-center "
                            style={{ height: '100px' }}
                          >
                            <img
                              src="/assets/icons/line.svg"
                              style={{ width: '30px' }}
                              alt=""
                            />
                          </div>
                          <div className="" style={{ width: '100%' }}>
                            <label
                              htmlFor="ounit"
                              style={{
                                fontWeight: 'normal',
                                fontSize: 'small',
                              }}
                            >
                              End Date{' '}
                            </label>
                            <div className="mb-3">
                              <DatePicker
                                showIcon
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                dateFormat="dd/MM/yyyy"
                                className="form-control pl-1"
                                required
                                minDate={new Date()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-1">
                        <label
                          htmlFor="ounit"
                          style={{ fontWeight: 'normal', fontSize: 'small' }}
                        >
                          Time{' '}
                        </label>
                        <div className=" d-flex">
                          <div className="mr-1" style={{ width: '50%' }}>
                            <div className="mb-3">
                              <div
                                className={`${styles.number_cont} d-flex py-1 px-3`}
                              >
                                <span className="d-inline-block">
                                  {fetchPageSize}
                                </span>
                                <span
                                  className={`d-inline-block ${styles.svg_div}`}
                                >
                                  <span onClick={() => updatePageSize('up')}>
                                    <svg
                                      className="d-block mx-auto mt-1 mb-1"
                                      width="8"
                                      height="8"
                                      viewBox="0 0 7 7"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M3.58334 6.09039V0.743164M3.58334 0.743164L0.909729 3.41677M3.58334 0.743164L6.25695 3.41677"
                                        stroke="black"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </span>
                                  <span onClick={() => updatePageSize('down')}>
                                    <svg
                                      className="d-block mx-auto mb-1 mt-1"
                                      width="8"
                                      height="8"
                                      viewBox="0 0 7 7"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M3.58334 0.743164V6.09039M3.58334 6.09039L6.25695 3.41677M3.58334 6.09039L0.909729 3.41677"
                                        stroke="black"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="mr-1" style={{ width: '50%' }}>
                            <div className="mb-3">
                              <Select
                                labelId="s"
                                id="time_frequency"
                                fullWidth
                                size="small"
                                value={info.time_frequency ?? ''}
                                name="time_frequency"
                                required
                                onChange={handleChange}
                                style={{ fontSize: '10px', minHeight: '40px' }}
                              >
                                <MenuItem
                                  value={'am'}
                                  style={{ fontSize: '10px' }}
                                >
                                  AM{' '}
                                </MenuItem>
                                <MenuItem
                                  value={'pm'}
                                  style={{ fontSize: '10px' }}
                                >
                                  PM
                                </MenuItem>
                              </Select>
                            </div>
                          </div>
                          <div className="mr-1" style={{ width: '100%' }}>
                            <div className="mb-3">
                              <Select
                                labelId="s"
                                id="day_frequency"
                                fullWidth
                                size="small"
                                value={info.day_frequency ?? ''}
                                name="day_frequency"
                                required
                                onChange={handleChange}
                              >
                                <MenuItem value={'daily'}>Daily </MenuItem>
                                <MenuItem value={'weekly'}>Weekly</MenuItem>
                                <MenuItem value={'monthly'}>Monthly</MenuItem>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mb-4 inputs_n_label">
                  <label htmlFor="message">Message</label>
                  <TextField
                    id="message"
                    value={info.message ?? ''}
                    name="message"
                    required
                    onChange={handleChange}
                    placeholder="Message"
                    size="small"
                    multiline
                    rows="5"
                    cols="5"
                    fullWidth
                  />
                </div>
                <div className="mb-4">
                  <button
                    type="submit"
                    disabled={isProcessing}
                    className={`modal_btn btn btn-block`}
                  >
                    Save
                    {isProcessing && (
                      <CircularProgress
                        style={{ color: 'white' }}
                        size="0.8rem"
                      />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="text-center">
                <img
                  style={{ width: '40px' }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <h5 className="mt-2">
                  Delete {currDate.targeted_audience} template
                </h5>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6B7280',
                  }}
                >
                  Are you sure you want to delete this notification ?
                </p>
              </div>
            </div>

            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={handleDialogClose}
              >
                Cancel
              </Button>

              <Button
                className={`${styles.delete_product}`}
                onClick={handleDeleteProcess}
              >
                Delete
                {isProcessing && (
                  <CircularProgress style={{ color: '#af3585' }} size="1rem" />
                )}
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openGoToCart}
        onClose={() => setopenGoToCart(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openGoToCart}>
          <Box
            className={`mobile_modal_size pt-md-3 ${styles.modal_bg}`}
            sx={style}
          >
            <div className={`text-center ${styles.add_to_cart_content}`}>
              <img src="/assets/icons/Illustration.svg" alt="" />
              <h5 className="mt-3">Notification Deleted </h5>
              <div>
                <button
                  onClick={() => setopenGoToCart(false)}
                  className={`modal_btn hover btn btn-block`}
                >
                  Done
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
