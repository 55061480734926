// ----------------------------------------------------------------------
import styles from '../BrandOwner.module.css';
import { NavLink as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, forwardRef } from 'react';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import ProductsModal from '../Modals/SuperAdminProductsModal.js';
import bus from 'src/bus';
import axiosInstance from 'src/axiosInstance';
import { BallTriangle } from 'react-loader-spinner';

import { Card, Avatar } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { createRef } from 'react';
import { useLayoutEffect } from 'react';
import SingleView from './SingleView';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// ----------------------------------------------------------------------
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useCategories } from 'src/hooks/useCategories';
import { useCategoriesApi } from 'src/context/CategoriesApi';
import toast from 'react-hot-toast';
import { Pagination } from 'src/components';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 190,
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 1,
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const style1 = {
  position: 'absolute',
  height: '700px',
  overflow: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  backgroundColor: '#FFFFFF',
  p: 3,
};
function changeView(d) {
  localStorage.setItem('ptvp', d);
}
// ptvp=PAGE TO VIEW (PRODUCT)

let app_view = localStorage.getItem('ptvp') ?? 0;
export default function ProductsPageBrandowwner() {
  let textInput = createRef();
  const categoriesApi = useCategoriesApi();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [openGoToCart, setopenGoToCart] = useState(false);
  const categories = useCategories();
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();
  const [searchvalue, setSearchValue] = useState('');
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [modalTitle, setmodalTitle] = useState('');

  const [newProducts, setNewProducts] = useState({
    notifications: [],
    cases_type: 'Cartons',
  });
  const handleGoToCartClose = () => setopenGoToCart(false);

  const searchFilter = (e) => {
    setSearchValue(e.target.value);
  };

  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [category, setCategory] = useState(1);
  const [url, setUrl] = useState('');
  const [appView, setAppview] = useState(app_view);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open2 = Boolean(anchorEl1);
  const [categoriesData, setdata] = useState([]);
  const [categoryPagination, setCategoryPagination] = useState({ total: 0 });
  const notify = (msg) => toast.success(msg);
  const notifyError = (err) => toast.error(err);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvp');

      // Your code here.
    },
    []
  );
  const handleCloseList = (key, value) => {
    setAnchorEl1(null);
    setNewProducts((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleProductSubmit = (e) => {
    e.preventDefault();
    setOpen1(false);
    setopenGoToCart(true);
  };

  const handleFile = (e) => {
    if (e.target.files[0]) {
      setUrl(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleButtonClick = () => {
    textInput.current.click();
  };

  const handleClickList1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const discountPage = () => {
    handleGoToCartClose();
    setShowDiscount(true);
    bus.emit('open-discount', true);
  };

  const handleViewClick = (val) => {
    localStorage.setItem('brand_email', JSON.stringify(val?.brand_email));
    bus.emit('open-product-await-approval', val?._id);
    // navigate('/app/products/' + d)
    // handle view click action
  };

  const reloadPage = (val) => {
    setAppview(val);
  };

  useEffect(() => {
    fetchData();
  }, [searchvalue, openPopUp]);

  bus.on('reload', () => {
    fetchData();
  });
  const fetchData = (page = 1, pageSize = 10, search = searchvalue) => {
    setIsPending(true);
    axiosInstance
      .get('/admin/product/awaiting-approval', {
        params: {
          page,
          search,
          pageSize,
        },
      })
      .then((res) => {
        setdata(res.data.data.data);
        setCategoryPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError(
            'Unable to fetch awaiting approvals, please try again later'
          );
        }
      })
      .finally(() => setIsPending(false));
  };

  const handleActions = (val) => {
    setOpenDialogDelete(!openDialogDelete);
    setSelectedCategory(val);
  };

  const handleDeleteCategories = (e) => {
    setIsDelete(true);
    // setTimeout(() => {
    //   setOpenDialogDelete(false);
    // }, [1000]);
    // setmodalTitle("Category deleted");
    axiosInstance
      .delete('/admin/product/' + selectedCategory._id)
      .then((res) => {
        if (res.status < 300) {
          notify('Request Successfully Deleted');
          setIsDelete(false);
          setOpenDialogDelete(false);
          setOpenPopUp(true);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to delete product, try again later');
        }
      })
      .finally(() => setIsDelete(false));
  };

  return Number(appView) === 0 ? (
    <>
      <div className="mt-3 px-2">
        <div className={`${styles.filters}  mss`}>
          <div className="row">
            <div className={`${styles.hold_input1} col-md-3   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={searchvalue}
                onChange={searchFilter}
                placeholder="Search"
                style={{
                  textIndent: '25px',
                  width: '100%',
                }}
                className=" form-control"
              />
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: '10px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-4 border-bottom">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">Request List</h5>
                <small style={{ fontSize: '16px' }} className="text-muted">
                  View and approve requests.
                </small>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`table-responsive-sm  pt-3 pb-3 pl-0 pr-0`}>
              <table className={`${styles.table} table-hover table-striped`}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    <th
                      style={{
                        width: '40%',
                      }}
                      className="text-muted"
                    >
                      Brand Name
                      <img
                        className="pl-2"
                        src="/assets/icons/down.svg"
                        alt=""
                      />
                    </th>
                    <th
                      style={{
                        width: '30%',
                      }}
                      className="text-muted "
                    >
                      Phone Number
                    </th>
                    <th
                      style={{
                        width: '30%',
                      }}
                      className="text-muted "
                    >
                      Products Added
                    </th>
                    <th
                      style={{
                        width: '30%',
                      }}
                      className="text-muted"
                    >
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {!isPending &&
                    categoriesData.length > 0 &&
                    categoriesData.map((el, i) => (
                      <tr key={el._id} className={`${styles.tr} `}>
                        <td>
                          <div className=" ">
                            <Avatar
                              className="float-md-left mr-2"
                              src={el?.brand_image}
                              alt=""
                              style={{}}
                            />
                            <div className=" ">
                              <p className="mb-0" style={{ fontSize: '14px' }}>
                                <strong>{el?.brand_name}</strong>
                                <br />
                                <small>{el?.brand_email}</small>
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p>
                            <span>{el?.brand_phone_number}</span>
                          </p>
                        </td>
                        <td>
                          <p>{el?.product_name}</p>
                        </td>
                        <td>
                          <button
                            onClick={() => handleViewClick(el)}
                            type="button"
                            className={`btn ${styles.view_action}`}
                          >
                            <img
                              src="/assets/icons/eyewhite.svg"
                              className="pr-1"
                              alt=""
                            />
                            View
                          </button>
                          <button
                            type="button"
                            onClick={() => handleActions(el)}
                            className="btn btn-danger ml-3 border-0"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!categoriesData.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={categoryPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>

      {/* Modals */}

      <Dialog
        open={openDialogDelete}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenDialogDelete(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="text-center">
                <img
                  style={{ width: '40px' }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <h4>Delete Request</h4>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6B7280',
                  }}
                >
                  Are you sure you want to delete this request ? All your data
                  will be permanently removed from our servers forever. This
                  action cannot be undone.
                </p>
              </div>
            </div>

            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={() => setOpenDialogDelete(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleDeleteCategories()}
                type="button"
                className={`${styles.delete_product}`}
              >
                Delete{'  '}
                {isDelete && (
                  <CircularProgress
                    className="ml-1"
                    style={{ color: 'red' }}
                    size="1rem"
                  />
                )}
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open}>
          <Box className={`mobile_modal_size`} sx={style}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3>Choose Category</h3>
                <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>

              <form action="">
                <div className="mb-3">
                  <label htmlFor="name">Category Name</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={category}
                    required
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>Breveges</MenuItem>
                    <MenuItem value={2}>Table water</MenuItem>
                    <MenuItem value={3}>Snacks</MenuItem>
                  </Select>
                </div>
                <div className="mb-4">
                  <button
                    type="button"
                    onClick={handleClose}
                    className={`${styles.modal_btn} btn btn-block`}
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      <ProductsModal key={3} closeModal={() => setShowDiscount(false)} />
    </>
  ) : (
    <SingleView key={1} reload={reloadPage} />
  );
}
