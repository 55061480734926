import propTypes from "prop-types";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import styles from "./Breadcrumb.module.css";

const Breadcrumb = (props) => {
  const { title, amount, handleAdd, handleReduce, disableReduce } = props;
  return (
    <div className={styles.Breadcrumb}>
      <div>
        <h4 className={styles.title}>{title}</h4>
      </div>

      <div className={styles.btnBox}>
        <button className={styles.crumbBtn} onClick={handleAdd}>
          <AiOutlinePlus />
        </button>
        <span>{amount}</span>
        <button
          className={styles.crumbBtn}
          onClick={handleReduce}
          disabled={disableReduce}
        >
          <AiOutlineMinus />
        </button>
      </div>
    </div>
  );
};

Breadcrumb.propTypes = {
  title: propTypes.string,
  amount: propTypes.number,
  handleAdd: propTypes.func,
  handleReduce: propTypes.func,
  disableReduce: propTypes.bool,
};
export default Breadcrumb;
