import axios from 'axios';
export const shortenText = (value) =>
  value ? value.substring(0, 16) + '...' : '';

export const downloadFile = async (file, extension) => {
  try {
    const outputFilename = `${Date.now()}.${extension}`;
    const link = document.createElement('a');
    link.href = `${file}`;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    throw Error(error);
  }
};

export const downloadFile2 = async (name, endpoint, extension) => {
  try {
    const data = JSON.parse(localStorage.getItem('auth'));

    axios({
      url: endpoint, //your url
      method: 'GET',
      responseType: 'blob',
      headers: {
        'x-auth-token': data.token,
      },
      baseURL: 'https://admin.flexdeals.com.ng/api/v1',
      // baseURL: 'https://admin.flexdeals.com.ng/api/v1'
      // baseURL: 'https://retailxpress.herokuapp.com/api/v1'
    }).then((response) => {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute(
        'download',
        `${name}.${extension === 'excel' ? 'xlsx' : extension}`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  } catch (error) {
    throw Error(error);
  }
};

export const downloadFile3 = async (name, endpoint, extension) => {
  try {
    const data = JSON.parse(localStorage.getItem('auth'));

    axios({
      url: endpoint, //your url
      method: 'POST',
      responseType: 'blob',
      headers: {
        'x-auth-token': data.token,
      },
      // baseURL: 'https://stageback.flexdeals.com.ng/api/v1',
      baseURL: 'https://admin.flexdeals.com.ng/api/v1',
      // baseURL: 'https://retailxpress.herokuapp.com/api/v1'
    }).then((response) => {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute(
        'download',
        `${name}.${extension === 'excel' ? 'xlsx' : extension}`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  } catch (error) {
    throw Error(error);
  }
};
