import { AiOutlinePicture } from "react-icons/ai";
import styles from "./Imageuploader.module.css";
import { useState } from "react";

const ImageUploader = ({ imageResult }) => {
  const [preview, setPreview] = useState('')
  const handleFile = (e) => {
    if (e.target.files[0]) {
      setPreview(URL.createObjectURL(e.target.files[0]))
      imageResult(e.target.files[0])
    }
  }
  return (
    <div className={styles.image_upload}>
      <label>
        {
          preview === '' &&
          // <AiOutlinePicture className={styles.pic_icon} style={{ cursor: 'pointer' }} />
          <div style={{ cursor: 'pointer', display: 'inline-block' }} >
            <img
                className={`${styles.retailer_product_image}`}
                src="/assets/image.svg"
                alt=""
            />
            <p className="mb-0 text-center text-info" style={{ fontSize: '10px' }} >Click to upload</p>
          </div>
        }
        {
          preview !== '' &&
          <div 
            style={{ 
              cursor: 'pointer', 
              display: 'inline-block',
              padding: '0px'
            }} 
          >
            <img 
                className={`${styles.retailer_product_image}`}
                src={preview}
                style={{
                  width: '100px'
                }}
                alt=""
            />
          </div>
          // <img style={{ width: '100%' }} src={preview} alt='' />
        }
        <input onChange={handleFile} type="file" />
      </label>
    </div>
  );
};

export default ImageUploader;
