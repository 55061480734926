import { useEffect, useState } from "react";
import GlobalModal from "src/Modals/GlobalModal";
import styles from "../BrandOwner.module.css"
import TextField from '@mui/material/TextField';
import LiveLocation from "src/pages/LiveLocation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import bus from 'src/bus';

export default function Filter() {
    const [modalOpen, setModalOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [location, setLocation] = useState('');
    const [filterSearch, setFilterSearch] = useState({
        min_balance: 0,
        max_balance: 0,
        min_store_order: 0,
        max_store_order: 0,
        dateStart: startDate,
        dateEnd: endDate,
        location: location,
        });

        const handleFilterStatus = (e) => {
            setFilterSearch(prev => {
              return {
                ...prev,
                [e.target.name]: e.target.value
              }
            })
          }

    const handleClose = () => {
        setModalOpen(false);
        bus.emit('filterSearch', filterSearch)
    };

    const handleClose1 = () => {
        setModalOpen(false);
    };

    const processLocation = (e) => {
        // console.log(e);
        setLocation(e.value)
    }
   

    useEffect(() => {
        bus.on('open-filter', (val) => {
            setModalOpen(true)
          
            
        });
    
        return () => {
    
        };
      }, []);



    return (
        <GlobalModal
            open={modalOpen}
            onClose={handleClose1}
            position='absolute'
            top='50%'
            left='50%'
            transform='translate(-50%, -50%)'
            width='740px !important'
            overflow='auto'
            bgcolor='#FFFF'
            border='1px solid #F5F5F5'
            borderRadius='5px'
            boxShadow='24'
            p='25px'
        >
            <div className={`${styles.modal_content}`}>
                <div className={`${styles.modal_header} mb-0`}>
                    <h5 style={{
                        fontSize: '19px',
                        fontWeight: '700',

                    }}>Filter</h5>
                    <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                        <img src="/assets/icons/x.svg" alt="" />

                    </span>

                </div>


                <form action="" className="mt-0">
                    <div className="row">
                        <div className="col-md-6 mb-1">

                            <div className="mb-3">
                                <LiveLocation label='Location' passData={processLocation} />


                            </div>
                            <label htmlFor="name">Date </label>

                            <div className=" d-flex">
                                <div className="" style={{ width: '100%' }}>
                                    <div className="mb-3">
                                        <DatePicker
                                            showIcon
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                            placeholderText="Start"
                                            required
                                        />

                                    </div>

                                </div>
                                <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                                </div>
                                <div className="" style={{ width: '100%' }}>
                                    <div className="mb-3">
                                        <DatePicker
                                            showIcon
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                            placeholderText="Start"
                                            required
                                        />


                                    </div>

                                </div>

                            </div>

                        </div>
                        <div className="col-md-6 mb-1">
                            <label htmlFor="price">Balance </label>

                            <div className=" d-flex">
                                <div className="" style={{ width: '100%' }}>
                                    <div className="mb-3">
                                        <TextField
                                            required
                                            type='number'
                                            id="price"
                                            placeholder="Min"
                                            size="small"
                                            value={filterSearch.min_balance}
                                            onChange={handleFilterStatus}
                                            name="min_balance"

                                        />


                                    </div>

                                </div>
                                <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                                </div>
                                <div className="" style={{ width: '100%' }}>
                                    <div className="mb-3">
                                        <TextField
                                            required
                                            type='number'
                                            placeholder="Max "
                                            size="small"
                                            value={filterSearch.max_balance}
                                            onChange={handleFilterStatus}
                                            name="max_balance"

                                        />


                                    </div>

                                </div>

                            </div>
                            <label htmlFor="ounit">Store Order </label>
                            <div className=" d-flex">
                                <div className="" style={{ width: '100%' }}>
                                    <div className="mb-3">
                                        <TextField
                                            required
                                            type='number'
                                            id="ounit"
                                            placeholder="Min"
                                            size="small"
                                            value={filterSearch.min_store_order}
                                            onChange={handleFilterStatus}
                                            name="min_store_order"

                                        />


                                    </div>

                                </div>
                                <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                                </div>
                                <div className="" style={{ width: '100%' }}>
                                    <div className="mb-3">
                                        <TextField
                                            required
                                            type='number'
                                            placeholder="Max "
                                            size="small"
                                            value={filterSearch.max_store_order}
                                            onChange={handleFilterStatus}
                                            name="max_store_order"

                                        />


                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="mb-4">
                        <button type="button" onClick={handleClose} className={`${styles.modal_btn} btn btn-block`}>
                            Proceed

                        </button>


                    </div>

                </form>


            </div>


        </GlobalModal>
    )
}