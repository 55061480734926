/* eslint-disable react-hooks/exhaustive-deps */


// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
import styles from './Page.module.css'
import Logo from "../logo/appLogo"
import { createRef, useEffect, useState } from "react"
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAuth } from 'src/hooks/useAuth';
import axiosInstance from "src/axiosInstance"
import CircularProgress from '@mui/material/CircularProgress';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { useApiKeys } from 'src/hooks/useApiKeys';
import CategoriesModal from './CategoriesModal';
import bus from 'src/bus';
import LiveSelect from 'react-select';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function BrandPage() {
  const [country, setCountry] = useState('select');
  const [state, setState] = useState('select');
  const [business_name, setBname] = useState('');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedOption] = useState(null);
  const [selectedOption1] = useState(null);
  const [selectedOption2] = useState(null);
  const [business_address, setAddr] = useState('');
  const navigate = useNavigate();
  const { dispatch, data } = useAuth()
  const [categories, setCategories] = useState([])


  const [business_phone_number, setPhone] = useState('');
  const [business_email, setEmail] = useState(data.email);
  const [terminal_id, setTerminalId] = useState('');
  const [linkedIn_url, setLinkedIn] = useState('');
  const [business_website, setBusinessWebsite] = useState('');
  const [business_description, setBusinessDesc] = useState('');
  const [business_logo, setUrl] = useState(null);
  const [business_logo_preview, setUrlP] = useState('/assets/icons/profileicon.svg');
  const notify = () => toast.success('Onboarding successful .');
  const notifyError = (err) => toast.error(err);
  const [isPending, setIsPending] = useState(false);
  const [product, setProduct] = useState(1);
  const [productsAdAdded, setProductsAdded] = useState([]);
  const [banks, setBanks] = useState([]);
  const [nameEnquiryLoading, setNameEnquiryLoading] = useState(false);
  let [location, setLocation] = useState({ latitude: '', longitude: '' });
  let [bank_info, setBankInfo] = useState({ account_no: '', account_name: '', bank_code: '', bank_name: '' });
  const apiKeys = useApiKeys();
  const [loadingState, setLoadingState] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationDisabled, setLocationDisabled] = useState(false);
  const [selectedLocationName, setSelectedLocationName] = useState(null);
  const [currentLocationCheck, setCurrentLocationCheck] = useState(false);
  // const [statesOption, setStatesOption] = useState([
  //   {
  //    "Abia": "Adamawa"
  //   },
  //   {
  //    "Abia": "Akwa Ibom"
  //   },
  //   {
  //    "Abia": "Anambra"
  //   },
  //   {
  //    "Abia": "Bauchi"
  //   },
  //   {
  //    "Abia": "Bayelsa"
  //   },
  //   {
  //    "Abia": "Benue"
  //   },
  //   {
  //    "Abia": "Borno"
  //   },
  //   {
  //    "Abia": "Cross River"
  //   },
  //   {
  //    "Abia": "Delta"
  //   },
  //   {
  //    "Abia": "Ebonyi"
  //   },
  //   {
  //    "Abia": "Edo"
  //   },
  //   {
  //    "Abia": "Ekiti"
  //   },
  //   {
  //    "Abia": "Enugu"
  //   },
  //   {
  //    "Abia": "Gombe"
  //   },
  //   {
  //    "Abia": "Imo"
  //   },
  //   {
  //    "Abia": "Jigawa"
  //   },
  //   {
  //    "Abia": "Kaduna State"
  //   },
  //   {
  //    "Abia": "Kano"
  //   },
  //   {
  //    "Abia": "Katsina"
  //   },
  //   {
  //    "Abia": "Kebbi"
  //   },
  //   {
  //    "Abia": "Kogi"
  //   },
  //   {
  //    "Abia": "Kwara"
  //   },
  //   {
  //    "Abia": "Lagos"
  //   },
  //   {
  //    "Abia": "Nasarawa"
  //   },
  //   {
  //    "Abia": "Niger"
  //   },
  //   {
  //    "Abia": "Ogun"
  //   },
  //   {
  //    "Abia": "Ondo"
  //   },
  //   {
  //    "Abia": "Osun"
  //   },
  //   {
  //    "Abia": "Oyo"
  //   },
  //   {
  //    "Abia": "Plateau"
  //   },
  //   {
  //    "Abia": "Rivers"
  //   },
  //   {
  //    "Abia": "Sokoto"
  //   },
  //   {
  //    "Abia": "Taraba"
  //   },
  //   {
  //    "Abia": "Yobe"
  //   },
  //   {
  //    "Abia": "Zamfara"
  //   }
  //  ])

  const handleSelect = async value => {
    const results = await geocodeByAddress(value);
    const { lat, lng } = await getLatLng(results[0]);
    setLocation({
      latitude: lat,
      longitude: lng

    })
    setAddr(value);
  }






  let textInput = createRef();

  // check if the browser supports geolocation
  // if (navigator.geolocation) {
  // get user's current position
  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(function (position) {


  //     // get latitude and longitude
  //     const latitude = position.coords.latitude;
  //     const longitude = position.coords.longitude;
  //     setLocation({
  //       latitude,
  //       longitude

  //     })
  //   });

  // }, [location])
  useEffect(() => {
    fetchBanks()
  }, [])
  useEffect(() => {

    axiosInstance.get('/product-category/all', {
      headers:{
        'x-auth-token': ` ${JSON.parse(localStorage.getItem('auth'))?.token}`
      }
    })
    .then(res => {
        setCategories(res.data.data)
    })
        
  }, []);
  useEffect(() => {
    nameEnquiry()
  }, [bank_info.account_no])
  
  useEffect(() => {
    const data = bank_info;
    data.account_no = ''
    setBankInfo(data)
  }, [bank_info.bank_code])
  // useEffect(() => {
  //   if(bank_info.account_no.length >= 10) {
  //     nameEnquiry();
  //   }
  // },[bank_info.account_no])
  

  const handleNameChange = (event) => {
    setBname(event.target.value);
  };
  
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleChange = (event) => {
    setCountry(event.value);
  };
  const handleChange2 = (event) => {
    setState(event.value);
  };

  const handleBusinessDescription = (event) => {
    setBusinessDesc(event.target.value)
  }
  const handleTerminalId = (event) => {
    setTerminalId(event.target.value)
  }
  const handleWebsiteChange = (event) => {
    setBusinessWebsite(event.target.value)
  }
  const handleLinkedChange = (event) => {
    setLinkedIn(event.target.value)
  }

  const fetchBanks = async () => {
    const banksApi = await fetch('https://api.paystack.co/bank')
    const banks = await banksApi.json();
    if (banks.status) {
      setBanks(banks.data)
    }
  }
  const nameEnquiry = async () => {
    if (bank_info.account_no.length < 10 || bank_info.account_no.length > 11) return;
    setNameEnquiryLoading(true)
    const accountNameApi = await fetch(`https://api.paystack.co/bank/resolve?account_number=${bank_info.account_no}&bank_code=${bank_info.bank_code}`, {
      headers: {
        'Authorization': `Bearer ${apiKeys.PASTACK.SECRET_KEY}`
      }
    })
    
    const accountName = await accountNameApi.json();
    setNameEnquiryLoading(false)

    if (accountName.status) {
      const data = bank_info;
      data.account_name = accountName.data.account_name
      setBankInfo(data)

    } else {
      const data = bank_info;
      data.account_name = ''
      setBankInfo(data)
      notifyError(accountName.message);

    }
  }

  const handleButtonClick = () => {

    textInput.current.click()
  }

  const handleFile = (e) => {
    if (e.target.files[0]) {
      setUrlP(URL.createObjectURL(e.target.files[0]))
      setUrl(e.target.files[0])

    }


  }

  const passProducts = (value) => {
    setProductsAdded(value)
  };
  const removeFromList = (index) => {
    let data = [...productsAdAdded]
    data = data.filter((_, i) => i !== index);
    if (data['length'] === 0) setProduct(1)
    setProductsAdded(data)




  }

  function isValidUrl(url) {
    // Regular expression to match a valid URL
    const urlPattern = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

    return urlPattern.test(url);
  }
  const fetchCountries = async () => {
    const countriesApi = await fetch('https://restcountries.com/v3.1/all')
    const countriesJson = await countriesApi.json();
    const countriesData = countriesJson?.map(country => ({
      code: country.cca2,
      name: country.name.common
    }));
    setCountries(countriesData.sort((a, b) => a.name.localeCompare(b.name)))
  }

  const fetchStates = async () => {
    if (country === '') return;
    setStates([]);
    setState('select');
    setLoadingState(true);
  
    try {
      const statesFetch = await fetch(`https://countriesnow.space/api/v0.1/countries/states`, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: country 
        }),
      });
      const statesJson = await statesFetch.json();
      const statesData = statesJson.data.states;
  
      setStates(() => {
        return statesData.filter((el) => el.name).sort((a, b) => a.name.localeCompare(b.name));
      });
      setLoadingState(false);
    } catch (error) {
      console.error('Error fetching states:', error);
      setLoadingState(false);
    }
  };

  // const fetchStates = async () => {
  //   if (country === '') return;
  //   setStates([])
  //   setState('select')

  //   setLoadingState(true);
  //   const statesFetch = await fetch(`https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/states.json`, {
  //   });
  //   const statesJson = await statesFetch.json();
  //   const statesData = statesJson
  //   setStates(() => {
  //     return statesData.filter((el, i) => el.country_name === country).sort((a, b) => a.name.localeCompare(b.name))
  //   })
  //   setLoadingState(false)

  // }

  useEffect(() => {
    fetchCountries()
    fetchStates()

  }, [country])

  async function appendToFormData(formData, key, value) {
    let success = false;
    let attempts = 0;
    const maxAttempts = 3; // Maximum number of attempts

    while (!success && attempts < maxAttempts) {
        try {
            formData.append(key, value);
            success = true;
        } catch (error) {
            console.error(`Appending ${key} failed. Retrying...`);
            attempts++;
            await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
        }
    }

    if (!success) {
        console.error(`Appending ${key} failed after ${maxAttempts} attempts.`);
    }
}

useEffect(() => {
  bus.on('selected-categories-onboarding', data => {
    console.log(data)
  })
}, [])


  async function HandleFormSubmit(e) {
    e.preventDefault();

    if(!productsAdAdded.length) return notifyError('Select at least a product category to continue onboarding process');

    let payload = {
      business_name, business_email,
      country, business_address,
      business_phone_number, state,
      business_description,
      linkedIn_url, business_website,
      latitude: location.latitude,
      longitude: location.longitude,

    }

    // let catId = categories
    //   ?.filter(category => productsAdAdded.includes(category.category_name))
    //   ?.map(category => category._id);

    if (!location.latitude) {
      return notifyError("Please select a valid address")

    }
    if (business_website !== '') {
      if (!isValidUrl(business_website)) {
        return notifyError("That isn't a valid website url")
      }
    }
    if (linkedIn_url !== '') {
      if (!isValidUrl(linkedIn_url)) {
        return notifyError("That isn't a valid LinkedIn url")
      }
    }
    const formData = new FormData();
    formData.append("business_logo", business_logo);
    Object.keys(payload).forEach(key => formData.append(key, payload[key]));


  //   catId.forEach(value => {
  //     formData.append("products_sold", value);
  // });

    setIsPending(true);

    axiosInstance.put('/merchant/business-info', formData)
      .then(res => {
        if (res.status === 201) {
          notify();
          data.onboarded = true;
          localStorage.setItem('auth', JSON.stringify(data));
          dispatch({ type: 'LOGIN', payload: data })

          navigate('/kyc_mou');

        }
      }).catch(err => {

        if (err) {
          notifyError(err.response.data.message);

        }
      }).finally(() => setIsPending(false))

  }
  function logOutUser() {
    localStorage.removeItem('auth');
    dispatch({ type: 'LOGIN', payload: {} })

    setTimeout(() => {
      navigate('/login')
    }, 1000);
  }

  const handleBankChange = (e) => {
    const value = e.value
    const name = e.label
    setBankInfo((prev) => {
      return {
        ...prev,
        bank_name: name,
        bank_code: value,
      }
    })

  }
  const handleBankAccountChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setBankInfo((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })

  }

  // const handleLocationSelect = () => {
  //   if (navigator.geolocation) {
  //     setIsLoading(true); 
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setIsLoading(false); 
  //         const { latitude, longitude } = position.coords;
  //         console.log(latitude, longitude)
  //         setLocation({ latitude: latitude, longitude: longitude });

  //         fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDJSZC3d1twPS0a9nO0Ecjucu3nBgraf2M`)
  //           .then(response => response.json())
  //           .then(data => {
  //             if (data.results && data.results.length > 0) {
  //               const address = data.results[0].formatted_address;
  //               setAddr(address)
  //             } else {
  //               console.error('No address found for the given coordinates.');
  //             }
  //           })
  //           .catch(error => {
  //             console.error('Error fetching address:', error);
  //           });
  //       },
  //       (error) => {
  //         setIsLoading(false); 
  //         console.error("Error getting location:", error);
  //       }
  //     );
  //   } else {
  //     console.error("Geolocation is not supported by this browser.");
  //   }
  // };

  // useEffect(() => {
  //   handleLocationSelect();
  // }, []);

  // useEffect(() => {
  //   setIsLoading(true); 
  //   const watchId = navigator.geolocation.watchPosition(
  //     (position) => {
  //       setIsLoading(false); 
  //       const { latitude, longitude } = position.coords;
  //       console.log(latitude, longitude);
  //       setSelectedLocation({ latitude, longitude });

  //       fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDJSZC3d1twPS0a9nO0Ecjucu3nBgraf2M`)
  //         .then(response => response.json())
  //         .then(data => {
  //           if (data.results && data.results.length > 0) {
  //             const address = data.results[0].formatted_address;
  //             setSelectedLocationName(address);
  //           } else {
  //             console.error('No address found for the given coordinates.');
  //           }
  //         })
  //         .catch(error => {
  //           console.error('Error fetching address:', error);
  //         });
  //     },
  //     (error) => {
  //       console.error("Error getting location:", error);
  //       if (error.code === error.PERMISSION_DENIED) {
  //         setLocationDisabled(true)
  //       }
  //     },
  //     { enableHighAccuracy: true }
  //   );

  //   return () => {
  //     navigator.geolocation.clearWatch(watchId);
  //   };
  // }, []);

  const setAddress = (e) => {
    setAddr(e)
  }

  const [addressResults, setAddressResults] = useState([]);

  const handleUseLiveLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          // console.log(latitude, longitude, '000')
          if (latitude !== null && longitude !== null) {
            setSelectedLocation({ latitude, longitude });
            // fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDJSZC3d1twPS0a9nO0Ecjucu3nBgraf2M`)
            // fetch(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=03c48dae07364cabb7f121d8c1519492&no_annotations=1&language=en`)
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${latitude},+${longitude}&key=AIzaSyCvH_nDKUVAcmjtKEbv-_YDY9rVbGqg4VQ`)
              .then(response => response.json())
              .then(data => {
                if (data.results && data.results.length > 0) {
                  const uniqueAddresses = [];
                  data.results.forEach(result => {
                    if (!uniqueAddresses.includes(result.formatted_address)) {
                      uniqueAddresses.push(result.formatted_address);
                    }
                  });
                  setAddressResults(uniqueAddresses);
                } else {
                  console.error('No address found for the given coordinates.');
                  setSelectedLocationName("Unknown");
                }
              })
              .catch(error => {
                console.error('Error fetching address:', error);
                setSelectedLocationName("Unknown");
              });
          } else {
            console.error('Latitude or longitude is null.');
            setSelectedLocationName("Unknown");
          }
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const handleAddressChange = (e) => {
    setAddr(e.target.value)
  }

  const handlePasteLocation = () => {
    if(locationDisabled) {
      alert("Please enable browser location to proceed.")
    };
    
    setLocation(selectedLocation)
    // setAddr(selectedLocationName)
    setCurrentLocationCheck(!currentLocationCheck)

    if (addressResults.length > 0) {
      setAddr(addressResults[0]);
    }
  }

  useEffect(() => {
    handleUseLiveLocation()
  }, []);

  return (
    <>

      <div className={`row ${styles.sav}`}>
        <div className="col-md-6 d-none d-md-block">
          <div className={styles.bg}  >
            <div className={styles.content}>
              <h5>
                Welcome, Let's get to know <br /> more about your business
              </h5>

              <ul className={`${styles.features} list-unstyled`}>
                <li>
                  <img src='/assets/check-circle.svg' alt='ic' />
                  <small>
                    Your business becomes public to wholesalers and retailers
                  </small>
                </li>
                <li>
                  <img src='/assets/check-circle.svg' alt='ic' />

                  <small>
                    Businesses with completed profiles are 3x more likely to be <br /> seen by customers

                  </small>
                </li>
                <li>
                  <img src='/assets/check-circle.svg' alt='ic' />

                  <small>
                    Robust dashboard including real time data for making sales <br /> decisions

                  </small>
                </li>
                <li>
                  <img src='/assets/check-circle.svg' alt='ic' />

                  <small>
                    Unlimited access to all the features on your dashboard

                  </small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6 ">
          <Logo />
          <div className={`${styles.sign_up} container`}>

            <div className={`${styles.headerSection} text-center`}>
              <div className='m-0 text-center'>
                <img src='/assets/icons/Featuredicon.svg' alt='' />

              </div>
              <h3>
                Business information
              </h3>
              <p>
                Please enter the relevant details.
              </p>

            </div>
            <form onSubmit={HandleFormSubmit} className={styles.form}>
              <div className='mb-0'>
                <div className='d-flex justify-content-between'>
                  <p className='mb-1'>
                    Upload profile picture
                  </p>

                  <span className='text-[#53138D]' style={{ cursor: 'pointer' }} onClick={() => bus.emit("open-logout-modal")}>
                    Back
                  </span>

                </div>
                <input ref={textInput} onChange={handleFile} type="file" hidden name="" id="" />

                <div className={styles.main_img_container}>
                  <div className={styles.img_container}>
                    <div className={styles.avatar_container}>
                      <img style={{ width: '100%' }} src={business_logo_preview} alt='' />
                    </div>
                  </div>
                  <span style={{ cursor: 'pointer' }} onClick={() => handleButtonClick()}>
                    <img className={styles.camera} src="/assets/icons/camera.svg" alt="" />
                  </span>
                </div>
                <div>
                </div>
              </div>
              <div className='my-4 inputs_n_label'>
                <label htmlFor='businessName'>Business Name*</label>
                <TextField
                  required
                  id="businessName"
                  placeholder="Enter business name"
                  size="small"
                  value={business_name}
                  onChange={handleNameChange}
                  fullWidth

                />
              </div>
              <PlacesAutocomplete
                value={business_address}
                onChange={(e) => { setAddress(e); setCurrentLocationCheck(false); }}
                onSelect={handleSelect}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <div className='my-4 inputs_n_label'>
                      <label htmlFor='loc'>Business Location*</label>
                      {currentLocationCheck && (
                        <div className={`${styles.syl} d-flex mt-0`}>
                          <FormControlLabel className='m-0' value={business_address} checked control={<Radio />} />
                          <Select
                            id="state"
                            fullWidth
                            size="small"
                            value={business_address}
                            required
                            onChange={handleAddressChange}
                          >
                            {addressResults.map((address, index) => (
                              <MenuItem key={index} value={address}>{ address }</MenuItem>
                            ))}
                          </Select>
                        </div>
                      )}
                      {
                        !currentLocationCheck && (
                          <TextField
                            id="loc"
                            placeholder='Input your location'
                            size="small"
                            fullWidth
                            {...getInputProps({
                              placeholder: 'Search Places ...',
                              className: 'location-search-input',
                            })}
    
                          />
                        )
                      }
                    </div>

                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions?.map((suggestion, i) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            key={i}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6 mb-1">
                        <label htmlFor='state'>Latitude*</label>&nbsp;
                        <TextField
                          required
                          id="lat"
                          value={location?.latitude}
                          size="small"
                          fullWidth
                          disabled

                        />
                      </div>
                      <div className="col-md-6 mb-1">
                        <label htmlFor='state'>Longitude*</label>&nbsp;
                        <TextField
                          required
                          id="lng"
                          value={location?.longitude}
                          size="small"
                          fullWidth
                          disabled

                        />
                      </div>
                    </div>
                    {
                        !currentLocationCheck &&
                        <p className='mt-2' style={{fontSize: 'small', fontStyle: 'italic'}}>
                          OR
                        </p>
                      }
                      <>
                        <div className={`${styles.syl}`} style={{ position: 'relative', width: '210px' }}>
                          {
                            !currentLocationCheck &&
                            <p 
                              className='mt-2' 
                              onClick={handlePasteLocation} 
                              style={{ cursor: 'pointer', fontSize: '15px' }}>
                              <FmdGoodIcon /> Use My Current Location
                              {isLoading && <CircularProgress className='ml-1 mt-1' style={{ color: '#53138D' }} size="0.8rem" />}
                            </p>
                          }
                          {
                            currentLocationCheck &&
                            <p 
                              className='mt-2' 
                              onClick={handlePasteLocation}
                              style={{ cursor: 'pointer', fontSize: '15px' }}>
                              <FmdGoodIcon /> Manual Location (Edit)
                            </p>
                          }
                        </div>
                        
                        {/* {isLoading && <p>Loading Map <CircularProgress style={{ color: '#53138D' }} size="1rem" /></p>} */}
                      </>
                  </div>
                )}
              </PlacesAutocomplete>
              {/* <div className='my-4 inputs_n_label'>
                <label htmlFor='businessAddr'>Business Address*</label>
                <TextField
                  required
                  id="businessAddr"
                  placeholder='Line 1'
                  size="small"
                  fullWidth
                  value={business_address}
                  onChange={handleAddressChange}

                />
              </div> */}
              <div className='my-4 inputs_n_label'>
                <div className="row">
                  <div className="col-md-6 mb-1">
                    <label htmlFor='s'>Country*</label>
                    <LiveSelect
                        defaultValue={selectedOption}
                        onChange={handleChange}
                        options={countries?.map((option) => ({
                            value: option.name,
                            label: option.name,
                        }))}
                    />

                    {/* <Select
                      labelId="s"
                      id="s"
                      fullWidth
                      size="small"
                      value={country}
                      required
                      onChange={handleChange}
                    >
                      <MenuItem value='select'>Select Country</MenuItem>
                      {countries.map((el, i) => {
                        return (
                          <MenuItem key={i} value={el.name}>{el.name}</MenuItem>

                        )
                      })}
                    </Select> */}


                  </div>
                  <div className="col-md-6 mb-1">
                    <label htmlFor='state'>State*</label> &nbsp;
                    {loadingState &&
                      <CircularProgress style={{ color: '#53138D' }} size="1rem" />

                    }
                    <LiveSelect
                        defaultValue={selectedOption1}
                        onChange={handleChange2}
                        options={states?.map((option) => ({
                            value: option.name,
                            label: option.name,
                        }))}
                    />
                    {/* <Select
                      labelId="s"
                      id="s"
                      fullWidth
                      size="small"
                      value={state}
                      required
                      onChange={handleChange2}
                    >
                      <MenuItem value='select'>Select State</MenuItem>
                      {states.map((el, i) => {
                        return (
                          <MenuItem key={i} value={el.name}>{el.name}</MenuItem>

                        )
                      })}
                    </Select> */}

                  </div>
                </div>


              </div>
              <div className='my-4 inputs_n_label'>
                <label htmlFor='businessPhone'>Business Phone Number*</label>
                <TextField
                  required
                  id="businessPhone"
                  size="small"
                  value={business_phone_number}
                  onChange={handlePhoneChange}
                  fullWidth

                />
              </div>
              <div className='my-4 inputs_n_label'>
                <label htmlFor='businessEmail'>Business Email*</label>
                <TextField
                  required
                  value={business_email}
                  onChange={handleEmailChange}
                  id="businessEmail"
                  size="small"
                  fullWidth

                />
              </div>
              
              <div className='my-4 inputs_n_label'>
                <label htmlFor='businessDesc'>Business Description*</label>
                <TextField
                  required
                  id="businessDesc"
                  value={business_description}
                  onChange={handleBusinessDescription}
                  placeholder='Your business description'
                  size="small"
                  multiline
                  rows='5'
                  cols='5'
                  fullWidth

                />
              </div>
              <div className={`mb-3 inputs_n_label ${styles.push_notification_icon}`} >
                <label htmlFor="changeProduct">Product category*</label>
                <TextField
                  required
                  id="businessDesc"
                  placeholder='Select items'
                  size="small"
                  disabled
                  fullWidth

                />
                <img onClick={() => bus.emit('open-categories-onboarding', productsAdAdded)} 
                  className="cursor_pointer" 
                  src="/assets/icons/ShapeRight.svg" 
                  alt="" 
                />
              </div>
              <div className="products mt-2">
                {productsAdAdded?.map((el, i) => {
                  return (
                    <div key={i} className={`product_selected`}>
                      <div className=" d-flex justify-content-between">
                        <span >{el}</span>
                        &nbsp;
                        &nbsp;
                        <span style={{ cursor: 'pointer' }} onClick={() => removeFromList(i)}>
                          <img style={{ width: '5px' }} src="/assets/icons/x.svg" alt="" />

                        </span>

                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='my-4 inputs_n_label'>
                <label htmlFor='businessWebsite'>Business website</label>
                <TextField
                  value={business_website}
                  onChange={handleWebsiteChange}
                  id="businessWebsite"
                  placeholder='Enter your company URL'
                  size="small"
                  fullWidth
                />
              </div>
              <div className='my-4 inputs_n_label'>
                <label htmlFor='businessLink'>LinkedIn URL</label>
                <TextField
                  value={linkedIn_url}
                  onChange={handleLinkedChange}
                  id="businessLink"
                  placeholder='Url'
                  size="small"
                  fullWidth

                />
              </div>



              <div className={styles.buttons}>
                {!isPending &&
                  <button className={`btn btn-block ${styles.btn_custom_color}`}>
                    <span>
                      Submit
                    </span>

                  </button>


                }
                {isPending && <button disabled className={`btn btn-block ${styles.btn_custom_color}`}>
                  Submitting &nbsp;
                  {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                </button>
                }

              </div>

            </form>


          </div>
        </div>
      </div>
      <CategoriesModal productsData={passProducts} />

    </>
  );
}
