// ----------------------------------------------------------------------
import styles from '../styles.module.css';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Card, Avatar } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
import ReferralRewardCard from './referralRewardCard';
// sections
// mock
import { useLayoutEffect } from 'react';
import GlobalModal from 'src/Modals/GlobalModal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { createRef } from 'react';
import { Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import moment from 'moment';
import { BallTriangle } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateFormat from 'src/hooks/useDateFormat';
import LiveLocation from 'src/pages/LiveLocation';
import { downloadFile3 } from 'src/utils';
import { Pagination } from 'src/components';

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3,
};

export default function Referrals() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [referralsData, setReferralsData] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [dataPagination, setDataPagination] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const notify = (msg) => toast.success(msg);
  const notifyError = (err) => toast.error(err);
  const [filter, setFilter] = useState({
    search: '',
  });
  const handleFilterChange = (key, value) => {
    setFilter((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleCloseFilter = () => {
    setFilterModalOpen(false);
  };

  const processLocation = (e) => {
    handleFilterChange('location', e.formatted_address);
    handleFilterChange('latitude', e.lat);
    handleFilterChange('longitude', e.lng);
  };

  const handleDownloadFile = async (extension) => {
    setExportLoading(true);
    try {
      const result = `/admin/customers/get-storeowners?page=${dataPagination.currentPage}&perpage=${dataPagination.total}&export_type=${extension}`;
      await downloadFile3('Customer - Stores', result, extension);
    } catch (err) {
      notifyError(err.message);
    } finally {
      setExportLoading(false);
    }
  };

  const fetchData = () => {};

  const handleEdit = () => {
    console.log('Edit action triggered');
  };

  const handleDelete = () => {
    console.log('Delete action triggered');
  };

  const getDotColor = (status) => {
    return status === 'active' ? 'rgb(76, 175, 80)' : 'rgb(244, 67, 54)';
  };

  return (
    <>
      <div className="px-2">
        <div className="d-flex">
          <div className="ml-auto">
            <button
              className="btn btn_primaryh"
              onClick={() => setFilterModalOpen(true)}
              style={{
                border: '1px solid #af3585',
                color: '#af3585',
                background: '#8C2D9129',
                borderRadius: '7px',
                minHeight: '40px',
              }}
            >
              + <small>Create Referral Reward</small>
            </button>
          </div>
        </div>
        <div className="row">
          <ReferralRewardCard
            title="For every 3 referrals"
            amount={1000000}
            status="active"
            date="Expires on 24/04/24"
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
          <ReferralRewardCard
            title="For every 3 referrals"
            amount={1206}
            status="inactive"
            date="Expires on 24/04/24"
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </div>
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-6   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={filter.search}
                onChange={(e) => handleFilterChange('search', e.target.value)}
                placeholder="Search"
                style={{ textIndent: '25px', width: '60%' }}
                className=" form-control"
              />
            </div>
            <div className="col-6 text-right">
              <button
                onClick={() => setFilterModalOpen(true)}
                className="btn  border"
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px',
                }}
              >
                <img
                  className=""
                  style={{ display: 'inline', width: '17px' }}
                  src="/assets/icons/filterlines.svg"
                  alt=""
                />
                &nbsp;
                <small className="d-none d-md-inline-block">Filters</small>
              </button>
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: '50px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-4 border-bottom">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">Referrals</h5>
                <small style={{ fontWeight: '200', fontSize: '14px' }}>
                  View your list of referrals
                </small>
              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`${styles.export_btn} btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: '8px',
                      }}
                    >
                      <img src="/assets/icons/downloadblue.svg" alt="" /> &nbsp;
                      Export
                    </span>
                    &nbsp; &nbsp;
                    <span
                      style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <img src="/assets/icons/chevron-downblue.svg" alt="" />
                    </span>
                  </button>

                  <div
                    className="dropdown-menu drop-left"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('csv')}
                    >
                      CSV
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('excel')}
                    >
                      Excel
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('pdf')}
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`pt-3 pb-3 pl-0 pr-0`}>
              <table
                className={`${styles.table} table-hover table-striped `}
                styfle={{ minWidth: '100em' }}
              >
                <thead className={`${styles.thead}`}>
                  <tr>
                    <th className="text-muted">S/N</th>
                    <th className="text-muted" style={{ width: '16rem' }}>
                      Referee
                      <img
                        className="pl-2"
                        src="/assets/icons/down.svg"
                        alt=""
                      />
                    </th>
                    <th className="text-muted">Phone Number</th>
                    <th className="text-muted">Number of invite</th>
                    <th className="text-muted">Invite Code</th>
                  </tr>
                </thead>
                <tbody>
                  {!isPending &&
                    referralsData.length >= 1 &&
                    referralsData.map((el, i) => (
                      <tr className={`${styles.tr} `}>
                        <td>
                          {(currentPage - 1) * itemsPerPage + i + 1}
                          {/* {(currentPage - 1) * itemsPerPage + i + 1} */}
                        </td>
                        <td>
                          <p>{el?.referee ? el.referee : '---'}</p>
                        </td>
                        <td>
                          <p>{el?.phone_number ? el.phone_number : '---'}</p>
                        </td>
                        <td>
                          <p>
                            {el?.number_of_invite ? el.number_of_invite : '---'}
                          </p>
                        </td>
                        <td>{el?.invite_code ? el.invite_code : 0}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!referralsData.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={dataPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>

      {/* Fiter Modal */}
      {/* <GlobalModal
                open={filterModalOpen}
                onClose={handleCloseFilter}
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                width="740px !important"
                overflow="auto"
                bgcolor="#FFFFFF"
                border="1px solid #F5F5F5"
                borderRadius="5px"
                boxShadow="24"
                p="25px"
            >
                <div className={`${styles.modal_content}`}>
                <div className={`${styles.modal_header} mb-0`}>
                    <h5
                    style={{
                        fontSize: "19px",
                        fontWeight: "700",
                    }}
                    >
                    Filter
                    </h5>
                    <span onClick={handleCloseFilter} style={{ cursor: "pointer" }}>
                    <img src="/assets/icons/x.svg" alt="" />
                    </span>
                </div>

                <form action="" className="mt-0">
                    <div className="row">
                    <div className="col-md-6 mb-1">
                        <div className="mb-3">
                        <label htmlFor="name">Verification</label>
                        <Select
                            labelId="s"
                            id="s"
                            fullWidth
                            size="small"
                            value={filter.verified}
                            onChange={(e) => handleFilterChange('verified', e.target.value)}
                        >
                            <MenuItem value="select">Select Customer Status</MenuItem>
                            <MenuItem value={true}>Verified</MenuItem>
                            <MenuItem value={false}>Unverified</MenuItem>
                        </Select>
                        </div>

                        <div className="">
                        <div className="mb-3">
                            <LiveLocation label='Location' passData={processLocation} />
                        </div>
                        </div>

                        <label htmlFor="ounit">Brands </label>
                        <div className=" d-flex">
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <TextField
                                required
                                type="number"
                                value={filter.min_brands}
                                id="ounit"
                                placeholder=""
                                size="small"
                                onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleFilterChange('min_brands', inputValue);
                                }
                                }}
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        <div
                            className="px-1 d-flex   align-items-center "
                            style={{ height: "40px" }}
                        >
                            <img
                            src="/assets/icons/line.svg"
                            style={{ width: "30px" }}
                            alt=""
                            />
                        </div>
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <TextField
                                required
                                type="number"
                                value={filter.max_brands}
                                placeholder=""
                                size="small"
                                onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleFilterChange('max_brands', inputValue);
                                }
                                }}
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-1">
                        <label htmlFor="price">Date </label>

                        <div className=" d-flex">
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <DatePicker
                                showIcon
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                                placeholderText="Start"
                                required
                                maxDate={new Date()}
                            />
                            </div>
                        </div>
                        <div
                            className="px-1 d-flex   align-items-center "
                            style={{ height: "40px" }}
                        >
                            <img
                            src="/assets/icons/line.svg"
                            style={{ width: "30px" }}
                            alt=""
                            />
                        </div>
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <DatePicker
                                showIcon
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                                placeholderText="End"
                                required
                                maxDate={new Date()}
                            />
                            </div>
                        </div>
                        </div>

                        <label htmlFor="ounit">Wallet Balance </label>
                        <div className=" d-flex">
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <TextField
                                required
                                type="number"
                                value={filter.min_balance}
                                id="ounit"
                                placeholder="Min"
                                size="small"
                                onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleFilterChange('min_balance', inputValue);
                                }
                                }}
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        <div
                            className="px-1 d-flex   align-items-center "
                            style={{ height: "40px" }}
                        >
                            <img
                            src="/assets/icons/line.svg"
                            style={{ width: "30px" }}
                            alt=""
                            />
                        </div>
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <TextField
                                required
                                value={filter.max_balance}
                                type="number"
                                placeholder="Max "
                                size="small"
                                onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleFilterChange('max_balance', inputValue);
                                }
                                }}
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        </div>

                        <label htmlFor="ounit">Order Unit </label>
                        <div className=" d-flex">
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <TextField
                                required
                                value={filter.min_order_unit}
                                type="number"
                                id="ounit"
                                placeholder="Min"
                                size="small"
                                onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleFilterChange('min_order_unit', inputValue);
                                }
                                }}
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        <div
                            className="px-1 d-flex   align-items-center "
                            style={{ height: "40px" }}
                        >
                            <img
                            src="/assets/icons/line.svg"
                            style={{ width: "30px" }}
                            alt=""
                            />
                        </div>
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <TextField
                                required
                                type="number"
                                value={filter.max_order_unit}
                                placeholder="Max "
                                size="small"
                                onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleFilterChange('max_order_unit', inputValue);
                                }
                                }}
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        </div>

                        <label htmlFor="ounit">Product Categories </label>
                        <div className=" d-flex">
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <TextField
                                required
                                value={filter.min_categories}
                                type="number"
                                id="ounit"
                                placeholder=""
                                size="small"
                                onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleFilterChange('min_categories', inputValue);
                                }
                                }}
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        <div
                            className="px-1 d-flex   align-items-center "
                            style={{ height: "40px" }}
                        >
                            <img
                            src="/assets/icons/line.svg"
                            style={{ width: "30px" }}
                            alt=""
                            />
                        </div>
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <TextField
                                required
                                type="number"
                                value={filter.max_categories}
                                placeholder=""
                                size="small"
                                onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleFilterChange('max_categories', inputValue);
                                }
                                }}
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="mb-4">
                    <button
                        type="button"
                        onClick={() => {
                        handleCloseFilter();
                        fetchData()
                        }}
                        className={`${styles.modal_btn} btn btn-block`}
                    >
                        Proceed{" "}
                        {isPending && (
                        <CircularProgress style={{ color: "#fff" }} size="1rem" />
                        )}
                    </button>
                    </div>
                </form>
                </div>
            </GlobalModal> */}
    </>
  );
}
