import { useState } from "react";
import GlobalModal from "src/Modals/GlobalModal";
import bus from 'src/bus';
import { useEffect } from "react";
import axiosInstance from "src/axiosInstance"
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';
import { useAuth } from 'src/hooks/useAuth';

export default function CategoriesModal({ productsData }) {
    const { data } = useAuth();
    const [categories, setCategories] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const [product, setProduct] = useState(1);
    const [productsAdded, setProductsAdded] = useState([]);
    const [passedData, setPassedData] = useState([]);
    const [uploadCategories, setUploadCategories] = useState(false)
    const notify = (msg) => toast.success(msg);
    const notifyError = (err) => toast.error(err);

    useEffect(() => {

        axiosInstance.get('/product-category/all?perpage=1000', {
            headers:{
              'x-auth-token': ` ${JSON.parse(localStorage.getItem('auth'))?.token}`
            }
      
          })
            .then(res => {
                setCategories(res.data.data)
            })
            
      }, []);



    const handleClose = () => {
        setModalOpen(false);
    };

    const handleProductChange = (e, value) => {
        if (value === 'selectAll') {
          // If "Select All" checkbox is clicked, toggle all other checkboxes
          const allChecked = e.target.checked;
          const updatedProductsAdded = allChecked ? categories.map(category => category.category_name) : [];
          setProductsAdded(updatedProductsAdded);
          setPassedData(updatedProductsAdded)
        } else {
          // If an individual category checkbox is clicked, toggle that category
          const isChecked = e.target.checked;
          if (isChecked) {
            setProductsAdded(prevProductsAdded => [...prevProductsAdded, value]);
            setPassedData(prevProductsAdded => [...prevProductsAdded, value]);
          } else {
            setProductsAdded(prevProductsAdded => prevProductsAdded.filter(el => el !== value));
            setPassedData(prevProductsAdded => prevProductsAdded.filter(el => el !== value));
          }
        }
    };

    useEffect(() => {
        bus.on('open-categories-onboarding', (val) => {
            setProductsAdded(val)
            setModalOpen(true)
            setPassedData(val)
            setTimeout(() => {
                let input = document.querySelectorAll(".myCheckbox")
                input.forEach((el, i) => {
                    if(val.some(name => name === el.value)) {
                        el.setAttribute('checked', true)
                    }
                })

            }, 1000);
        });

        return () => {

        };
    }, []);

    const handleUploadAllCats = (e) => {
        const checked = e.target.checked;
        const updatedCategories = categories.map(category => ({
          ...category,
          isChecked: checked 
        }));
        setCategories(updatedCategories);
    };


    const handleBrandCategoriesUpload = () => {
        productsData(passedData)
        handleClose();
    }

  const handleCategoriesUpload = () => {
    setUploadCategories(true)
    let catId = categories
      ?.filter(category => productsAdded.includes(category?.category_name))
      ?.map(category => category?._id);

    const productsSoldArray = [];

    for (let i = 0; i < catId.length; i++) {
      productsSoldArray.push(catId[i]);
    }

    const payload = {
        products_sold: productsSoldArray
    }

    const endpoint = data.merchant_type === 'brand' ? axiosInstance.put('/merchant/upload-business-categories', payload) : axiosInstance.post('/merchant/upload-store-categories', payload)

    endpoint
    .then((res) => {
        notify(res.data.message);
        const processData = [...new Set([...productsAdded,])]
        productsData(processData)
        handleClose();
    }).catch(err => {
      notifyError(err.response.data.message)
    }).finally(() => setUploadCategories(false))
  }

    return (
        <GlobalModal
            open={modalOpen}
            onClose={handleClose}
            position='absolute'
            top='50%'
            left='50%'
            transform='translate(-50%, -50%)'
            width='740px !important'
            height='auto !important'
            maxHeight='50vh !important'
            overflow='auto'
            bgcolor='#fff'
            border='1px solid #F5F5F5'
            borderRadius='5px'
            boxShadow='24'
            p='25px'
        >
            <div className={`modal_content`}>
                <div className={`modal_header mb-0`}>
                    <p className='mb-0 pl-2 text-muted'>Select all kind of product this business sells</p>

                    <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                        <img src="/assets/icons/x.svg" alt="" />

                    </span>

                </div>

                <div>
                <div className="d-flex mr-2 mb-3 mt-3">
                    <input 
                        type="checkbox" 
                        onChange={(e) => handleProductChange(e, 'selectAll')} 
                        className="myCheckbox" 
                    />
                    &nbsp;
                    <small className="mt-1"  style={{fontSize: '12px'}}>
                        Select All
                    </small>
                </div>

                <div>
                    {categories.map((category, index) => (
                        <div className="categories mr-2 mb-3" key={index}>
                        <input 
                            type="checkbox" 
                            value={category.category_name} 
                            checked={productsAdded.includes(category.category_name)} 
                            onChange={(e) => handleProductChange(e, category.category_name)} 
                            className="myCheckbox" 
                        />
                        &nbsp;
                        {category.category_name}
                        </div>
                    ))}
                </div>

                </div>
                <div className="mt-5">
                    <button 
                        onClick={handleCategoriesUpload} 
                        className="btn text-white btn-[#53138D] bg-[#8c2d915d] w-100 d-block" 
                        data-dismiss="modal" 
                        aria-label="Close"
                        style={{ background: '#53138D',  }}
                    >
                        Confirm
                        { uploadCategories && <CircularProgress style={{color:'white'}}  size="1rem"   />}
                    </button>
                </div>

            </div>


        </GlobalModal>
    )
}
