import BrandOwner from "./brandowner"
import Superadmin from "./superadmin"
import Page404 from "../Page404";
import { useAuth } from "src/hooks/useAuth";
export default function OrderPage() {

  const {data} = useAuth();
    
  

  return data.merchant_type === "brand"  ?  (
      <BrandOwner />  
  ) : (
    data?.user_type === "admin" ? <Superadmin /> : <Page404 />)
}
