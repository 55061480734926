import Brand from "./Brand";
import Retailer from "./Retailer";
import { useAuth } from 'src/hooks/useAuth';

export default function OnboardIndex() {
    const {data} = useAuth();
    
    
    return data.merchant_type === "brand"  ?  (
        <Brand />  
    ) : (
      <Retailer />
    )
}