

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
import styles from './Auth.module.css'
import Logo from "../logo/appLogo"
import { NavLink as RouterLink } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import toast from 'react-hot-toast';
import axios from 'axios';
import { BaseUrlContext } from 'src/context/BaseUrlContext';

import { useState } from "react"
import { useContext } from "react"

export default function LoginPage() {
  const [signUpError, setSignUpError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const notifyError = () => toast.error("That didn't work please try again later .");
  const notify = (res) => toast.success(res);
  const baseUrl = useContext(BaseUrlContext);
  const [showText, setShowText] = useState(false);
  const [showText1, setShowText1] = useState(false);
  const [showReset, setShowReset] = useState(false)
  const [otpToken, setOtpToken] = useState('')

  // const [passRule, setPassRule] = useState("<p className='mb-1'>The password must be at least 8 characters long.</p>, ")


  const [details, setDetails] = useState({
    new_password: '',
    token: '',
    confirm_password: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target

    setDetails(details => ({
      ...details,
      [name]: value
    }))

  }

  const changeType = () => {
    setShowText(!showText)
  }
  const changeType1 = () => {
    setShowText1(!showText1)
  }

  const verifyOtp = async (payload) => {
    payload.email = localStorage.getItem('e');
    delete payload.confirm_password
    delete payload.new_password
    delete payload.token
    try {
      setIsPending(true)
      setSignUpError(null)
      // Make the API request
      const res = await axios({
        method: "POST",
        url: `${baseUrl}/merchant/verify-otp`,
        data: payload,
      });

      notify(res.data.message);
      setOtpToken(res.data.data.token)
      setShowReset(true)
    } catch (err) {
      try {
        setSignUpError(err.response.data.message?.message ? err.response.data.message.message : err.response.data.message);

      } catch (error) {
        setSignUpError("Unable to change password, try again later");

        notifyError()
      }
    } finally {
      setIsPending(false)


    }
  }
  const resetPassword = async (payload) => {
    if (payload.new_password.length < 8) {
      setSignUpError('Password must be at least 8 characters long');
      return;
    }
    if (payload.confirm_password !== payload.new_password) {
      setSignUpError('Password Confirmation doesn\'t match ');;
      return;
    }
    payload.token = otpToken
    delete payload.otp
    delete payload.email
    console.log(payload);
    try {
      setIsPending(true)
      setSignUpError(null)
      // Make the API request
      const res = await axios({
        method: "POST",
        url: `${baseUrl}/merchant/reset`,
        data: payload,
      });

      notify(res.data.message);


      setTimeout(() => {
        window.location.href = '/login'

      }, 2000);
    } catch (err) {
      try {
        setSignUpError(err.response.data.message?.message ? err.response.data.message.message : err.response.data.message);

      } catch (error) {
        setSignUpError("Unable to change password, try again later");

        notifyError()
      }
    } finally {
      setIsPending(false)


    }
  }
  function HandleFormSubmit(e) {
    e.preventDefault();
    let payload = details

    if (!showReset) verifyOtp(payload)
    else resetPassword(payload)

  }


  return (
    <>

      <div className={`row ${styles.sav}`}>

        <div className="col-md-6 d-none d-md-block">
          <div className={styles.bg} style={{ backgroundImage: " linear-gradient(to right,  #52138d9f, #8c2d9176), url('/assets/happyMan.jpg')" }}>
            <img className={styles.star} src='/assets/Stars.svg' alt='stars' />
            <div className={styles.content}>
              <h5 className={`${styles.header_text}`}>
                Track and monitor <br /> your customers on <br /> a single dashboard
              </h5>
              <p>
                Make informed sales decisions by monitoring consumer <br /> behaviours, brand acceptance and market performance.
              </p>
              <div className={styles.icon_n_text}>
                <img className='icons' src='/assets/Avatars.svg' alt='avatars' />
                <span style={{ color: '#EAECF0' }}>
                  Join 40,000+ users
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 ">
          <Logo />
          <div className={`${styles.sign_up} container`}>


            <form onSubmit={HandleFormSubmit} className={styles.form}>
              <h4 className='mb-4' style={{
                fontSize: '34px',
                fontWeight: '600',
                color: '#111827',


              }}>Reset Password</h4>
              {(signUpError) && <Alert severity="error">{signUpError}</Alert>}
              {
                !showReset &&
                <div className='my-4 inputs_n_label'>
                  <label htmlFor='token'>O T P</label>
                  <TextField
                    type='text'
                    id="token"
                    placeholder="Enter token sent to your mail"
                    name="otp"
                    size="small"
                    value={details.otp}
                    onChange={handleChange}
                    fullWidth

                  />
                </div>

              }
              {
                showReset
                &&
                <div>
                  <div className='my-4 inputs_n_label'>
                    <label htmlFor='password'>Enter new password*</label>
                    <TextField
                      required
                      type={showText ? 'text' : 'password'}
                      id="password"
                      placeholder="Create a password"
                      name="new_password"
                      size="small"
                      value={details.new_password}
                      onChange={handleChange}
                      fullWidth

                    />
                    <div className="icon" onClick={changeType}>
                      {
                        !showText &&
                        <img src="/assets/icons/eye.svg" alt="" />

                      }
                      {
                        showText &&
                        <img src="/assets/icons/eye-off.svg" alt="" />


                      }

                    </div>
                    <small className='text-muted'>Must be at least 8 characters</small>
                  </div>
                  <div className='my-4 inputs_n_label'>
                    <label htmlFor='password'>Confirm  password*</label>
                    <TextField
                      required
                      type={showText1 ? 'text' : 'password'}
                      id="password"
                      name="confirm_password"
                      placeholder="Confirm  password"
                      size="small"
                      value={details.confirm_password}
                      onChange={handleChange}
                      fullWidth

                    />
                    <div className="icon mt-2" onClick={changeType1}>
                      {
                        !showText1 &&
                        <img src="/assets/icons/eye.svg" alt="" />

                      }
                      {
                        showText1 &&
                        <img src="/assets/icons/eye-off.svg" alt="" />


                      }

                    </div>
                  </div>

                </div>

              }


              <div className={styles.buttons}>
                {!isPending &&
                  <button className={`btn btn-block ${styles.btn_custom_color}`}>
                    <span className={`${styles.span}`}>
                      Proceed

                    </span>
                    {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                  </button>}
                {isPending && <button disabled className={`btn btn-block ${styles.btn_custom_color}`}>
                  Sending link &nbsp;
                  {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                </button>}
                <p className='text-center'>
                  <RouterLink to='/login'>Back to Login </RouterLink>
                </p>

              </div>

            </form>
          </div>
        </div>
      </div>
    </>
  );
}
