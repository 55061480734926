import React, { useEffect, useState } from 'react';
import moment from 'moment';
import bus from 'src/bus'

function useDiscountTimer({ start, end }) {
    const [timeLeft, setTimeLeft] = useState(null);
    const [expired, setExpired] = useState(null);

    useEffect(() => {
      const interval = setInterval(() => {
        const diff = moment.duration(moment(end).diff(moment()));
    
        if (diff.asSeconds() <= 0) {
          clearInterval(interval);
          setTimeLeft(null);
          setExpired(true);
          bus.emit('expired', true)
        } else {
          setTimeLeft(diff);
          setExpired(false);
          bus.emit('expired', false)
        }
      }, 1000);
    
      return () => clearInterval(interval);
    }, [end]);
    
    return (
      <div>
        <p className="text-center mb-0 text-danger">
          {timeLeft
            ? `${Math.floor(timeLeft.asDays())
                .toString()
                .padStart(2, '0')}:${timeLeft
                .hours()
                .toString()
                .padStart(2, '0')}:${timeLeft
                .minutes()
                .toString()
                .padStart(2, '0')}:${timeLeft
                .seconds()
                .toString()}`
            : "Expired"}
        </p>
      </div>
    );
}

export default useDiscountTimer;
