// ----------------------------------------------------------------------
import styles from '../BrandOwner.module.css';
import { Card, CardHeader } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
import DiscountModal from '../Modals/DiscountModal';
import axiosInstance from 'src/axiosInstance';
import { BallTriangle } from 'react-loader-spinner';
import * as React from 'react';
import bus from 'src/bus';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import DatePicker from 'react-datepicker';
import { useState } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import LiveLocation from 'src/pages/LiveLocation';
import DiscountTimer from 'src/hooks/useDiscountCountDown';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

// sections
// mock

import GlobalModal from 'src/Modals/GlobalModal';
import Menu from '@mui/material/Menu';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';
import { useLayoutEffect } from 'react';
import { useAuth } from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
// import moment from 'moment';
import { shortenText } from 'src/utils';
// ----------------------------------------------------------------------
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const style1 = {
  position: 'absolute',
  width: '600px',
  height: '650px',
  overflow: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  background: '#FFFFFF',
  p: 3,
};

const style3 = {
  position: 'absolute',
  width: '650px',
  height: 'auto',
  overflow: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  background: '#FFFFFF',
  maxHeight: '650px',
  p: 3,
};

// ptvp=PAGE TO VIEW (SALES)
export default function PromoAndDiscounts(closeModal) {
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [openGoToCart, setopenGoToCart] = React.useState(false);
  const navigate = useNavigate();
  const { data } = useAuth();
  const [dataList, setdata] = React.useState([]);
  const [isPending, setIsPending] = React.useState(false);
  const notifyError = (err) => toast.error(err);
  const notifyToast = (msg) => toast.success(msg);
  const [anchorEl5, setAnchorEl5] = React.useState(null);
  const open6 = Boolean(anchorEl5);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const open5 = Boolean(anchorEl4);
  const [allPAdded, setAllPAdded] = useState([]);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [allP, setAllP] = useState([1, 2, 3]);
  const [summary, setSummary] = useState(false);
  const [openDialog, setDiologOpen] = useState(false);
  const [productData, setProductData] = useState('');
  const [deleteProduct, setDeleteProduct] = useState(false);
  const [refetchProduct, setRefetchProduct] = useState(false);
  const [discountP, setDiscountP] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [allP1, setAllP1] = useState([1, 2, 3]);
  const [isFocused1, setIsFocused1] = useState(false);
  const open4 = Boolean(anchorEl3);
  const open3 = Boolean(anchorEl2);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchvalue, setsearch] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterStatus, setFilterStatus] = useState('');

  const addNew1 = (index) => {
    // Make sure index is a valid number
    if (typeof index !== 'number') {
      return;
    }

    // Check if index is a valid index for allP array
    if (index < 0 || index >= allP1.length) {
      return;
    }

    // Get the item at index from allP
    const itemToAdd = allP1[index];

    // Add itemToAdd to allPAdded and remove it from allP
    setAllP1(allP1.filter((item, i) => i !== index));

    // Create a copy of the discountData state
    const updatedDiscountData = [...discountData];

    // Add itemToAdd to the products array of the third object in updatedDiscountData
    updatedDiscountData[2].products.push(itemToAdd);

    // Update the discountData state with the updatedDiscountData array
    setDiscountData(updatedDiscountData);
  };

  const handleClickList2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClickList3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const discountProcess = (e) => {
    e.preventDefault();
    if (discount.discount_type === 'emt') {
      return notifyError('Please select discount type ');
    }

    // delete style3.height
    setSummary(true);
    // setOpen1(false);
  };

  const searchFilter = (e) => {
    setsearch(e.target.value);
  };

  const searchFilterType = (e) => {
    setFilterType(e.target.value);
  };

  const searchFilterStatus = (e) => {
    setFilterStatus(e.target.value);
  };

  const handleCloseDiscount = () => {
    setDiscountModal(false);
  };

  const handleClose1 = () => {
    delete style3.height;
    setOpen1(false);
    setDiscountModal(false);
    setDiscountP(false);
    setAllPAdded([]);
    setAllP([1, 2, 3]);
    setIsFocused1(false);
    closeModal(false);
  };
  const [discount, setDiscount] = useState({
    discount_type: 'emt',
    proposed_market_price: '',
    proposed_discount_budget: '',
    max_sales_quantity: '',
    discount_start_date: '',
    discount_end_date: '',
    discount_product_quantity: '',
    bonus_product_id: '',
  });
  const handleClickList4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };
  const handleClickList5 = (event) => {
    setAnchorEl5(event.currentTarget);
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleCloseList = (index, key, value) => {
    setAnchorEl(null);
    setAnchorEl1(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
    setAnchorEl4(null);
    setAnchorEl5(null);
    setDiscountData((prev) =>
      prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            discount_duration: {
              ...item.discount_duration,
              [key]: value,
            },
          };
        }
        return item;
      })
    );
  };

  const [category, setCategory] = useState('select');
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const [discountData, setDiscountData] = useState([
    {
      name: 'Amount Off More Than 1',
      number_of_product: 0,
      discount_percentage: 0,
      discount_duration: {
        time: 'Hours',
        count: '01',
      },
    },
    {
      name: 'Amount Off Product',
      discount_percentage: 0,
      discount_duration: {
        time: 'Hours',
        count: '01',
      },
    },
    {
      name: 'Buy X Get Y',
      products: [],
      no_of_product: '',
      discount_duration: {
        time: 'Hours',
        count: '01',
      },
    },
  ]);
  const handleGoToCartClose = () => setopenGoToCart(false);
  const [modalOpen1, setModalOpen1] = React.useState(false);
  const [search, setSearch] = React.useState({
    search: '',
    type: '',
    location: '',
    status: '',
  });
  const processModalClose = () => {
    setRefetchProduct(!refetchProduct);
  };

  // const processFilter = (e) => {
  //   const { name, value } = e.target;
  //   setSearch(() => ({
  //     ...search,
  //     [name]: value
  //   }));
  // }

  const handleOpenModal = (val) => {
    bus.emit('open-discount-modal', val);
    localStorage.setItem('discount-modal', JSON.stringify(val));
  };

  const handleDiscountChange = (key, value) => {
    setDiscount((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const [modalOpenC, setModalOpenC] = useState(false);

  useLayoutEffect(
    () => () => {
      // Your code here.
    },
    []
  );

  React.useEffect(() => {
    bus.on('reload', () => {
      fetchData();
    });
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [searchvalue, refetchProduct]);

  const fetchData = () => {
    const filter_payload = {};
    if (searchvalue) {
      filter_payload.searchvalue = searchvalue;
    }
    if (filterType !== '' && filterType !== 'all') {
      filter_payload.discount_type = filterType;
    }
    if (filterStatus !== '' && filterStatus !== 'all') {
      filter_payload.status = filterStatus;
    }
    setIsPending(true);
    axiosInstance
      .get('/brand/discount/get-discounts', {
        params: {
          ...filter_payload,
        },
      })
      .then((res) => {
        setSearch({});
        setModalOpen1(false);
        setdata(res.data.data.data);
        console.log(res.data.data.data);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch discounts, please try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const handleCloseC = () => {
    setModalOpenC(false);
  };

  const processLocation = (e) => {
    // console.log(e);
  };

  const [open1, setOpen1] = useState(false);
  const [discountModal, setDiscountModal] = useState(false);
  const [flashSales] = useState([
    { name: 'Flash Sales' },
    { name: 'Official Store Deals' },
    { name: 'Clearance Sales' },
    { name: 'Limited Stock  Deals' },
    { name: 'Season Sales' },
    { name: 'Hot Sales' },
  ]);

  const openDiscount = () => {
    bus.emit('open-discount', false);
  };

  const handleDialogClose = () => {
    setDiologOpen(false);
  };

  const handleClickOpen = (val) => {
    setDiologOpen(true);
    setProductData(val);
  };

  const deleteSingleProduct = (e) => {
    setDeleteProduct(true);
    setTimeout(() => {
      handleDialogClose(false);
    }, [1000]);
    // setmodalTitle('Product deleted')
    axiosInstance
      .delete('/brand/product/delete-product/' + productData)
      .then((res) => {
        if (res.status < 300) {
          // setopenPopUp(true)
          notifyToast(res.data.message);
          setRefetchProduct(!refetchProduct);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to delete product, try again later');
        }
      })
      .finally(() => setDeleteProduct(false));
  };

  return (
    <>
      <div className={`mt-3 px-2`}>
        <div className={`${styles.filters} `}>
          <div className="row">
            <div className={`${styles.hold_input1} col-md-3 col-8 mb-2  `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={searchvalue}
                onChange={searchFilter}
                placeholder="Search"
                style={{
                  textIndent: '25px',
                  width: '100%',
                }}
                className="form-control"
              />
            </div>
            <div className="col-md-9 col-4 text-right">
              <button
                className="btn  border"
                onClick={() => setModalOpen1(true)}
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px',
                }}
              >
                <img
                  className=""
                  style={{
                    display: 'inline',
                    width: '28%',
                  }}
                  src="/assets/icons/filterlines.svg"
                  alt=""
                />
                <span className="d-none d-md-inline-block">&nbsp;</span>
                <small className="d-none d-md-inline-block">Filters</small>
              </button>
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: '10px', borderRadius: '10px' }}
          className="p-0"
        >
          <CardHeader
            className="p-2 p-md-4"
            title={
              <div className="d-md-flex  justify-content-between">
                <div>
                  <h4 style={{ fontSize: '19px', fontWeight: '600' }}>
                    Promo And Discounts
                  </h4>
                  <p
                    style={{
                      fontSize: '14px',
                      fontWeight: 'lighter',
                    }}
                    className="text-muted"
                  >
                    Create and push promo discounts to your Stores and shoppers
                    here.
                  </p>
                </div>
                <div className="d-flex">
                  &nbsp; &nbsp;
                  <div>
                    {data.is_verified && (
                      <button
                        id="dropdownMenuButton"
                        className={`btn m-0`}
                        style={{
                          verticalAlign: 'middle',
                          background: '#af3585',
                          color: '#FFFFFF',
                          borderRadius: '8px',
                        }}
                        onClick={() => bus.emit('open-discount')}
                      >
                        Create Discount
                      </button>
                    )}
                    {!data.is_verified && (
                      <button
                        id="dropdownMenuButton"
                        disabled
                        className={`btn m-0`}
                        style={{
                          verticalAlign: 'middle',
                          background: '#af3585',
                          color: ' #FFFFFF',
                          borderRadius: '8px',
                        }}
                        // onClick={() => setOpen1(true)}
                      >
                        Create Discount
                      </button>
                    )}
                  </div>
                </div>
              </div>
            }
          />
          <Scrollbar>
            {data.is_verified && dataList?.length > 0 && !isPending && (
              <div className={`py-3 px-md-4 ${styles.cover_products}`}>
                <div className={`${styles.customized_row}`}>
                  {dataList?.map((el, i) => {
                    //  const originalStartDate = el?.discount_start_date;
                    //  const originalEndDate = el?.discount_end_date;
                    //  const delayDays = moment(el?.updatedAt);

                    // Calculate adjusted start and end dates
                    //  const promoStartDate = moment(originalStartDate, 'DD-MM-YYYY').add(delayDays, 'days').format('DD-MM-YYYY');
                    //  const promoEndDate = moment(originalEndDate, 'DD-MM-YYYY').add(delayDays, 'days').format('DD-MM-YYYY');
                    return (
                      <div className="mb-2" key={i}>
                        <div
                          className="card "
                          style={{
                            position: 'relative !important',
                            overflow: 'hidden',
                          }}
                        >
                          <div className="card-body">
                            <div
                              className={`${styles.customized_row1}`}
                              style={{ minHeight: '220px' }}
                            >
                              <div
                                className="bg-light"
                                style={{
                                  overflow: 'hidden',
                                  maxHeight: '190px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                  }}
                                  src={el?.product_image}
                                  alt=""
                                />
                              </div>
                              <div className={`${styles.product_txt} h-100`}>
                                <h6>{el?.product_name}</h6>
                                <div>
                                  <div className="mt-4 mb-0 d-flex justify-content-between">
                                    <h6 style={{ fontWeight: 'lighter' }}>
                                      Type:
                                    </h6>
                                    <h6>
                                      {el?.discount_type ===
                                      'amount_off_product'
                                        ? 'Amount Off Product'
                                        : ' Buy X Get Y'}
                                      {/* { el?.discount_id ? el?.discount_id?.discount_type?.split("_").map((word) =>
                                      word.charAt(0).toUpperCase() + word.slice(1)).join(" ") : '---'}
                                    */}
                                    </h6>
                                  </div>
                                  <div className="mt-2 mb-0 d-flex justify-content-between">
                                    <h6 style={{ fontWeight: 'lighter' }}>
                                      Duration:
                                    </h6>
                                    <h6
                                      className="text-danger mb-0"
                                      style={{ fontWeight: 'lighter' }}
                                    >
                                      {/* <DiscountTimer start={el?.discount_start_date} end={el?.discount_end_date} /> */}
                                      {el?.is_approved &&
                                      el?.discount_start_date &&
                                      el?.discount_end_date !== null ? (
                                        el?.discount_expired ? (
                                          <p className="text-center mb-0">
                                            Expired
                                          </p>
                                        ) : (
                                          // console.log("Not expired:", el?.discount_end_date, new Date()),
                                          <DiscountTimer
                                            start={el?.updatedAt}
                                            end={el?.discount_end_date}
                                          />
                                        )
                                      ) : (
                                        <p className="text-center mb-0">
                                          00:00:00:00
                                        </p>
                                      )}
                                    </h6>
                                  </div>
                                  {el?.msq_expired === true && (
                                    <div className="mt-2 mb-0 d-flex justify-content-between">
                                      <h6 style={{ fontWeight: 'lighter' }}>
                                        Msq:
                                      </h6>
                                      <h6
                                        className="text-success mb-0"
                                        style={{ fontWeight: 'lighter' }}
                                      >
                                        Sold Out
                                      </h6>
                                    </div>
                                  )}
                                  <div className="mt-2 mb-0 d-flex justify-content-between">
                                    <h6 style={{ fontWeight: 'lighter' }}>
                                      Category:
                                    </h6>
                                    <div className="">
                                      <span
                                        style={{
                                          background: '#EFF6FF',
                                          padding: '5px',
                                          color: ' #8C2D91',
                                          border: '1px solid #E5E7EB',
                                          borderRadius: '5px',
                                        }}
                                        className="d-inline-block mx-1 "
                                      >
                                        {el?.product_category_name}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="mt-2 mb-0 d-flex justify-content-between">
                                    <h6 style={{ fontWeight: 'lighter' }}>
                                      Description:
                                    </h6>
                                    <h6>
                                      <BootstrapTooltip
                                        title={
                                          <div>
                                            <span>{el?.description}</span>
                                          </div>
                                        }
                                      >
                                        <h6
                                          className="text-success"
                                          style={{ fontWeight: 'lighter' }}
                                        >
                                          {/* 15% Off Product */}
                                          {el?.description
                                            ? shortenText(el?.description)
                                            : ''}
                                        </h6>
                                      </BootstrapTooltip>
                                    </h6>
                                  </div>
                                  <div className="mt-2 mb-0 d-flex justify-content-between">
                                    <h6 style={{ fontWeight: 'lighter' }}>
                                      Status:
                                    </h6>
                                    <h6
                                      className="text-[#af3585] bg-[#8c2d915d]"
                                      style={{ fontWeight: 'lighter' }}
                                    >
                                      {/* 15% Off Product */}
                                      {el?.is_approved && <span>Approved</span>}
                                      {!el?.is_approved && (
                                        <span>Awaiting Approval</span>
                                      )}
                                    </h6>
                                  </div>
                                  {/* <div className="row pt-2">
                                    <div className="col-6 text-center border-right">
                                      <span onClick={() => handleOpenModal(el)} style={{ cursor: 'pointer' }}>
                                        <img src="/assets/icons/edit.svg" alt="edit" />
                                      </span>
                                    </div>
                                    <div className="col-6 text-right">
                                      <span style={{ cursor: 'pointer' }} onClick={() => handleClickOpen(el?._id)}>
                                        <img src="/assets/icons/trash-2.svg" alt="delete" />
                                      </span>
                                    </div>

                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      // <div className="mb-2" key={el._id}>
                      //   <div className="card " style={{ position: 'relative !important', overflow: 'hidden' }}>
                      //     <div className="card-body">
                      //       <div className={`${styles.customized_row1}`}>
                      //         <div className=" "
                      //           style={{
                      //             overflow: "hidden",
                      //             maxHeight: "190px",
                      //             display: 'flex',
                      //             justifyContent: 'center',
                      //             alignItems: 'center'
                      //           }}>
                      //           <img style={{
                      //             width: "100%",
                      //             height: "100%",
                      //             objectFit: "contain",
                      //           }}
                      //           src={el?.product_image} alt="" />

                      //         </div>
                      //         <div className={`${styles.product_txt}`}>
                      //           <h6>{el?.product_name}</h6>
                      //           <div >
                      //             <div className="mt-2 mb-0 d-flex justify-content-between">
                      //               <h6 style={{ fontWeight: 'lighter' }}>
                      //                 Type:
                      //               </h6>
                      //               <h6>
                      //                 {el?.discount_type === 'amount_off_product' ? 'Amount Off Product' : ' Buy X Get Y'}
                      //               </h6>
                      //             </div>
                      //             <div className="mt-2 mb-0 d-flex justify-content-between">
                      //               <h6 style={{ fontWeight: 'lighter' }}>
                      //                 Duration:
                      //               </h6>
                      //               <h6 className="text-danger" style={{ fontWeight: 'lighter' }}>
                      //               {el?.is_approved ?
                      //                 <DiscountTimer start={el?.discount_start_date} end={el?.discount_end_date} />
                      //                 : "00 : 00 : 00 : 00"
                      //               }
                      //               </h6>
                      //             </div>
                      //             {/* <div className="mt-2 mb-0 d-flex justify-content-between">
                      //             <h6 style={{ fontWeight: 'lighter' }}>
                      //               Category:

                      //             </h6>
                      //             <div className="">
                      //               <span style={{
                      //                 background: '#EFF6FF',
                      //                 padding: '5px',
                      //                 color: ' #8C2D91',
                      //                 border: '1px solid #E5E7EB',
                      //                 borderRadius: '5px'

                      //               }} className="d-inline-block mx-1 ">
                      //                 Shoppers
                      //               </span>
                      //               <span style={{
                      //                 background: '#EFF6FF',
                      //                 padding: '5px',
                      //                 color: ' #8C2D91',
                      //                 border: '1px solid #E5E7EB',
                      //                 borderRadius: '5px'

                      //               }} className="d-inline-block mx-1 ">
                      //                 S.Owners

                      //               </span>
                      //             </div>
                      //           </div> */}
                      //             <div className="mt-3 mb-0 d-flex justify-content-between">
                      //               <h6 style={{ fontWeight: 'lighter' }}>
                      //                 Description:

                      //               </h6>
                      //               <h6 className="text-danger" style={{ fontWeight: 'lighter' }}>
                      //                 {el?.description}
                      //               </h6>
                      //             </div>
                      //             <div className="mt-3 mb-0 d-flex justify-content-between">
                      //               <h6 style={{ fontWeight: 'lighter' }}>
                      //                 Status:

                      //               </h6>
                      //               <h6 className="text-danger" style={{ fontWeight: 'lighter' }}>
                      //                 {el?.status}
                      //               </h6>
                      //             </div>
                      //           </div>
                      //         </div>
                      //       </div>
                      //     </div>
                      //   </div>

                      // </div>
                    );
                  })}
                </div>
              </div>
            )}
            {!data.is_verified && (
              <div className="alert alert-[#af3585] text-center">
                <p>
                  This account is yet to be verified by the admin. Kindly send
                  an email to support@flexdeals.com.ng if it is over 24hours of
                  registeration. Thanks 🤝
                </p>
              </div>
            )}
            <div className="pb-4 d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {data.is_verified && !dataList.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
        </Card>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={discountModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={discountModal}>
          <Box className={`mobile_modal_size`} sx={style3}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3>Create Discount </h3>
                <span
                  onClick={handleCloseDiscount}
                  style={{ cursor: 'pointer' }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <span
                style={{ fontSize: '14px', paddingBottom: '0px !important' }}
              >
                Add promo to product
              </span>

              <form onSubmit={discountProcess} className="mt-2">
                {!discountP && (
                  <div className="mb-3">
                    <label
                      className={`${styles.modal_product_title} pl-0`}
                      htmlFor="pname"
                    >
                      Product Search
                    </label>
                    <div
                      className={`${styles.hold_input} col-md-12 pl-0 mb-2  `}
                    >
                      <input
                        onFocus={() => setIsFocused1(true)}
                        type="text"
                        placeholder=""
                        style={{ textIndent: '25px', width: '103%' }}
                        className=" form-control"
                      />
                    </div>
                  </div>
                )}

                <div
                  className="card"
                  onClick={() => setOpen1(true)}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="card-body p-2">
                    <div
                      className={`${styles.customized_row1} mt-2`}
                      style={{ display: 'flex' }}
                    >
                      <div
                        style={{
                          overflow: 'hidden',
                          maxHeight: '70px',
                          width: '15%',
                          padding: '0px',
                        }}
                      >
                        <img
                          style={{
                            height: '70px',
                          }}
                          src="/assets/images/products/Milo2.png"
                          alt="Milo"
                        />
                      </div>

                      <div className={`${styles.product_txt} pt-3`}>
                        <h6>Nestle Milo</h6>
                        <p>25 Reviews</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="mb-3 mt-3">
                    {isPending && (
                      <button disabled className={`btn btn-block modal_btn`}>
                        Submitting &nbsp;
                        {isPending && (
                          <CircularProgress
                            style={{ color: 'white' }}
                            size="1rem"
                          />
                        )}
                      </button>
                    )}
                    {!isPending && (
                      <button
                        type="submit"
                        className={`modal_btn btn btn-block`}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open1}>
          <Box className={`mobile_modal_size`} sx={style3}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3>Create Discount </h3>
                <span onClick={handleClose1} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p style={{ fontSize: '14px' }}>Add promo to product</p>

              <form onSubmit={discountProcess}>
                {!discountP && (
                  <div className="mb-3">
                    <label
                      className={`${styles.modal_product_title} pl-0`}
                      htmlFor="pname"
                    >
                      Product
                    </label>
                    <div className="card">
                      <div className="card-body p-2">
                        <div
                          className={`${styles.customized_row1} mt-2`}
                          style={{ display: 'flex' }}
                        >
                          <div
                            style={{
                              overflow: 'hidden',
                              maxHeight: '70px',
                              width: '15%',
                              padding: '0px',
                            }}
                          >
                            <img
                              style={{
                                height: '70px',
                              }}
                              src="/assets/images/products/Milo2.png"
                              alt="Milo"
                            />
                          </div>

                          <div className={`${styles.product_txt} pt-3`}>
                            <h6>Nestle Milo</h6>
                            <p>25 Reviews</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  <div className="mb-3">
                    <label
                      className={`${styles.modal_product_title}`}
                      htmlFor="price"
                    >
                      Proposed Market Price*
                    </label>
                    <TextField
                      required
                      id="price"
                      name="proposed_market_price"
                      value={discount.proposed_market_price}
                      type="number"
                      placeholder=" ₦"
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue === '' || parseFloat(inputValue) > 0) {
                          handleDiscountChange(
                            'proposed_market_price',
                            inputValue
                          );
                        }
                      }}
                      inputProps={{
                        inputMode: 'decimal',
                      }}
                      onKeyDown={(e) => {
                        if (e.key === '-' || e.key === 'e' || e.key === '.') {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      className={`${styles.modal_product_title}`}
                      htmlFor="discount"
                    >
                      Discount Type{' '}
                    </label>
                    <Select
                      labelId="s"
                      id="s"
                      fullWidth
                      size="small"
                      className="mb-3"
                      name="discount_type"
                      value={discount.discount_type}
                      required
                      onChange={(e) =>
                        handleDiscountChange('discount_type', e.target.value)
                      }
                    >
                      <MenuItem value={'emt'}>Select Type</MenuItem>
                      <MenuItem value={'amount_off_product'}>
                        Amount Off Product
                      </MenuItem>
                      <MenuItem value={'buy_x_get_y'}>Buy X Get Y</MenuItem>
                    </Select>
                    {discount.discount_type === 'amount_off_product' && (
                      <div className="card">
                        <div className="card-header bg-white">
                          Amount Off Product &nbsp;
                          <BootstrapTooltip title={<div></div>}>
                            <img src="/assets/icons/hint.svg" alt="" />
                          </BootstrapTooltip>
                          A discount on a particular product
                        </div>
                        <div className="card-body">
                          <div className="mb-1">
                            <div className="d-md-flex justify-content-between">
                              <label
                                className={` pt-2`}
                                htmlFor="discountPrice"
                              >
                                Duration of Discount
                              </label>
                              <div className={`${styles.duration_discount}`}>
                                <div className="border-right">
                                  <span className={`${styles.times}`}>
                                    {discountData[1]?.discount_duration?.count}
                                  </span>
                                </div>
                                <div
                                  className="border-right"
                                  style={{ cursor: 'pointer' }}
                                  onClick={handleClickList2}
                                >
                                  <img
                                    src="/assets/icons/downicon.svg"
                                    alt=""
                                  />
                                </div>
                                <div className="border-right">
                                  <span className={`${styles.times}`}>
                                    {discountData[1]?.discount_duration?.time}
                                  </span>
                                </div>
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={handleClickList3}
                                >
                                  <img
                                    src="/assets/icons/downicon.svg"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorEl2}
                                open={open3}
                                MenuListProps={{
                                  'aria-labelledby': 'basic-button',
                                }}
                              >
                                {['01', '02', '03', '04'].map((count) => (
                                  <MenuItem
                                    key={count}
                                    onClick={() =>
                                      handleCloseList(1, 'count', count)
                                    }
                                  >
                                    {count}
                                    {discountData[1]?.discount_duration
                                      .count === count && (
                                      <img
                                        className={`${styles.check}`}
                                        src="/assets/icons/check.svg"
                                        alt=""
                                      />
                                    )}
                                  </MenuItem>
                                ))}
                              </Menu>
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorEl3}
                                open={open4}
                                MenuListProps={{
                                  'aria-labelledby': 'basic-button',
                                }}
                              >
                                {['Minutes', 'Hours', 'Days', 'Months'].map(
                                  (count) => (
                                    <MenuItem
                                      key={count}
                                      onClick={() =>
                                        handleCloseList(1, 'time', count)
                                      }
                                    >
                                      {count}
                                      {discountData[1]?.discount_duration
                                        .time === count && (
                                        <img
                                          className={`${styles.check}`}
                                          src="/assets/icons/check.svg"
                                          alt=""
                                        />
                                      )}
                                    </MenuItem>
                                  )
                                )}
                              </Menu>
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              <div className="mb-3 d-flex">
                                <div className="" style={{ width: '45%' }}>
                                  <div className="mb-3">
                                    <DatePicker
                                      showIcon
                                      selected={startDate}
                                      onChange={(date) => setStartDate(date)}
                                      dateFormat="dd/MM/yyyy"
                                      className="form-control"
                                      placeholderText="Start"
                                      minDate={new Date()}
                                      required
                                    />
                                  </div>
                                </div>
                                <div
                                  className="px-1 d-flex   align-items-center "
                                  style={{ height: '40px' }}
                                >
                                  <img
                                    src="/assets/icons/line.svg"
                                    style={{ width: '30px' }}
                                    alt=""
                                  />
                                </div>
                                <div className="" style={{ width: '45%' }}>
                                  <div className="mb-3">
                                    <DatePicker
                                      showIcon
                                      selected={endDate}
                                      onChange={(date) => setEndDate(date)}
                                      dateFormat="dd/MM/yyyy"
                                      className="form-control"
                                      placeholderText="End"
                                      minDate={new Date()}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label
                              className={`${styles.modal_product_title}`}
                              htmlFor="dprice"
                            >
                              Proposed Discount Budget*{' '}
                            </label>
                            <TextField
                              id="dprice"
                              type="number"
                              name="proposed_discount_budget"
                              value={discount.proposed_discount_budget}
                              placeholder=" Input amount"
                              size="small"
                              fullWidth
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (
                                  inputValue === '' ||
                                  parseFloat(inputValue) > 0
                                ) {
                                  handleDiscountChange(
                                    'proposed_discount_budget',
                                    inputValue
                                  );
                                }
                              }}
                              inputProps={{
                                inputMode: 'decimal',
                              }}
                              onKeyDown={(e) => {
                                if (
                                  e.key === '-' ||
                                  e.key === 'e' ||
                                  e.key === '.'
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              className={`${styles.modal_product_title}`}
                              htmlFor="mq"
                            >
                              Maximum Sales Quantity*{' '}
                            </label>
                            <TextField
                              id="mq"
                              type="number"
                              name="max_sales_quantity"
                              value={discount.max_sales_quantity}
                              size="small"
                              fullWidth
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (
                                  inputValue === '' ||
                                  parseFloat(inputValue) > 0
                                ) {
                                  handleDiscountChange(
                                    'max_sales_quantity',
                                    inputValue
                                  );
                                }
                              }}
                              inputProps={{
                                inputMode: 'decimal',
                              }}
                              onKeyDown={(e) => {
                                if (
                                  e.key === '-' ||
                                  e.key === 'e' ||
                                  e.key === '.'
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <small
                              className="small text-danger"
                              style={{ fontWeight: 'lighter ' }}
                            >
                              This is the total quantity of product the discount
                              covers
                            </small>
                          </div>
                          <div className="mb-3 d-flex justify-content-between">
                            <div>
                              <p className="mb-0 text-muted">
                                Discount percentages
                              </p>
                              <small className="text-danger small">
                                This percentage is subject to change based on
                                the market research undertaken
                              </small>
                            </div>
                            <span className="text-muted">10%</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {discount.discount_type === 'buy_x_get_y' && (
                      <div className="card">
                        <div className="card-header bg-white">
                          A promo just like buy one get one free Buy X Get Y
                          &nbsp;
                          <BootstrapTooltip title={<div></div>}>
                            <img src="/assets/icons/hint.svg" alt="" />
                          </BootstrapTooltip>
                        </div>
                        <div className="card-body shadow-none px-3">
                          <div className="mb-3">
                            <label className={``} htmlFor="np">
                              Number of Product{' '}
                            </label>
                            <TextField
                              id="np"
                              type="number"
                              name="discount_product_quantity"
                              value={discount.discount_product_quantity}
                              size="small"
                              fullWidth
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (
                                  inputValue === '' ||
                                  parseFloat(inputValue) > 0
                                ) {
                                  handleDiscountChange(
                                    'discount_product_quantity',
                                    inputValue
                                  );
                                }
                              }}
                              inputProps={{
                                inputMode: 'decimal',
                              }}
                              onKeyDown={(e) => {
                                if (
                                  e.key === '-' ||
                                  e.key === 'e' ||
                                  e.key === '.'
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                          <div className={`${styles.bonus_product}`}>
                            Add Bonus Product
                            <div className={`${styles.products} px-0 mt-1`}>
                              <TextField
                                id="discountPrice"
                                type="number"
                                placeholder="Search For Products"
                                size="small"
                                onFocus={() => setIsFocused(true)}
                                fullWidth
                              />
                              <img src="/assets/icons/search.svg" alt="" />
                            </div>
                            {isFocused ? (
                              <div
                                className={`${styles.product_list} card px-0 `}
                              >
                                <div className="card-body">
                                  {allP1.map((el, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className={`d-flex justify-content-between`}
                                      >
                                        <div
                                          className=" "
                                          style={{ width: '50%' }}
                                        >
                                          <div
                                            className={`d-flex ${styles.review_contents}`}
                                          >
                                            <div>
                                              <img
                                                style={{
                                                  width: '51px',
                                                }}
                                                src="/assets/miloContainer.svg"
                                                alt=""
                                              />
                                            </div>
                                            &nbsp;
                                            <div style={{}}>
                                              <p
                                                style={{
                                                  cursor: 'pointer',
                                                  display: 'inline-block',
                                                  marginBottom: '5px',
                                                }}
                                              >
                                                <strong>Nestle Milo</strong>
                                                <small
                                                  style={{ paddingLeft: '4px' }}
                                                >
                                                  <img
                                                    src="/assets/starsgroup.svg"
                                                    alt=""
                                                  />
                                                </small>
                                              </p>
                                              <p
                                                style={{
                                                  fontStyle: 'normal',
                                                  fontWeight: '400',
                                                  fontSize: '14px',
                                                  lineHeight: '150%',
                                                  color: '#6B7280',
                                                }}
                                              >
                                                25 Reviews
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="d-flsex align-items-center">
                                          <button
                                            type="button"
                                            className="btn"
                                            onClick={() => addNew1(i)}
                                          >
                                            <img
                                              src="/assets/icons/plus-square1.svg"
                                              alt=""
                                            />
                                            &nbsp; Add Product
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : null}
                            Bonus Product
                            <div className="card mb-3">
                              <div className="card-body py-1">
                                <div
                                  className={`d-flex justify-content-between`}
                                >
                                  <div className=" " style={{ width: '50%' }}>
                                    <div
                                      className={`d-flex ${styles.review_contents}`}
                                    >
                                      <div>
                                        <img
                                          style={{
                                            width: '51px',
                                          }}
                                          src={discountP.product_image}
                                          alt=""
                                        />
                                      </div>
                                      &nbsp;
                                      <div style={{}}>
                                        <p
                                          style={{
                                            cursor: 'pointer',
                                            display: 'inline-block',
                                            marginBottom: '5px',
                                          }}
                                        >
                                          <strong>
                                            {discountP.product_name}
                                          </strong>
                                        </p>
                                        {/* <div>
                                {discountP.description}
                              </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-md-flex justify-content-between mt-2">
                            <label className={` pt-2`} htmlFor="discountPrice">
                              Duration of Discount
                            </label>
                            <div className={`${styles.duration_discount} mt-2`}>
                              <div className="border-right">
                                <span className={`${styles.times}`}>
                                  {discountData[2]?.discount_duration?.count}
                                </span>
                              </div>
                              <div
                                className="border-right"
                                style={{ cursor: 'pointer' }}
                                onClick={handleClickList4}
                              >
                                <img src="/assets/icons/downicon.svg" alt="" />
                              </div>
                              <div className="border-right">
                                <span className={`${styles.times}`}>
                                  {discountData[2]?.discount_duration?.time}
                                </span>
                              </div>
                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={handleClickList5}
                              >
                                <img src="/assets/icons/downicon.svg" alt="" />
                              </div>
                            </div>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl4}
                              open={open5}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                            >
                              {['01', '02', '03', '04'].map((count) => (
                                <MenuItem
                                  key={count}
                                  onClick={() =>
                                    handleCloseList(2, 'count', count)
                                  }
                                >
                                  {count}
                                  {discountData[2]?.discount_duration.count ===
                                    count && (
                                    <img
                                      className={`${styles.check}`}
                                      src="/assets/icons/check.svg"
                                      alt=""
                                    />
                                  )}
                                </MenuItem>
                              ))}
                            </Menu>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl5}
                              open={open6}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                            >
                              {['Minutes', 'Hours', 'Days', 'Months'].map(
                                (count) => (
                                  <MenuItem
                                    key={count}
                                    onClick={() =>
                                      handleCloseList(2, 'time', count)
                                    }
                                  >
                                    {count}
                                    {discountData[2]?.discount_duration.time ===
                                      count && (
                                      <img
                                        className={`${styles.check}`}
                                        src="/assets/icons/check.svg"
                                        alt=""
                                      />
                                    )}
                                  </MenuItem>
                                )
                              )}
                            </Menu>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <div className="mb-3 d-flex mt-2">
                              <div className="" style={{ width: '45%' }}>
                                <div className="mb-3">
                                  <DatePicker
                                    showIcon
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control"
                                    placeholderText="Start"
                                    required
                                    minDate={new Date()}
                                  />
                                </div>
                              </div>
                              <div
                                className="px-1 d-flex   align-items-center "
                                style={{ height: '40px' }}
                              >
                                <img
                                  src="/assets/icons/line.svg"
                                  style={{ width: '30px' }}
                                  alt=""
                                />
                              </div>
                              <div className="" style={{ width: '45%' }}>
                                <div className="mb-3">
                                  <DatePicker
                                    showIcon
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control"
                                    placeholderText="End"
                                    required
                                    minDate={new Date()}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className={``} htmlFor="pdb">
                              Proposed Discount Budget*{' '}
                            </label>
                            <TextField
                              id="pdb"
                              type="text"
                              placeholder="Input Amount"
                              name="proposed_discount_budget"
                              value={discount.proposed_discount_budget}
                              onChange={(e) =>
                                handleDiscountChange(
                                  'proposed_discount_budget',
                                  e.target.value
                                )
                              }
                              size="small"
                              fullWidth
                            />
                          </div>
                          <div className="mb-3 d-flex justify-content-between">
                            <div>
                              <p className="mb-0 text-muted">
                                Discount Occurrence
                              </p>
                              <small className="text-danger small">
                                This percentage is subject to change based on
                                the market research undertaken
                              </small>
                            </div>
                            <span className="text-muted">10%</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={`mb-3 ${styles.push_notification_icon}`}>
                    <label
                      className={`${styles.modal_product_title}`}
                      htmlFor="push"
                    >
                      Push Notification{' '}
                    </label>
                    <TextField
                      disabled
                      id="push"
                      type="text"
                      placeholder=" Select Target Audience"
                      size="small"
                      fullWidth
                    />
                    <img
                      onClick={() => setModalOpenC(true)}
                      className="cursor_pointer"
                      src="/assets/icons/ShapeRight.svg"
                      alt=""
                    />
                  </div>

                  <div>
                    {/* <label className={`${styles.modal_product_title}`} htmlFor="loc">Product Tag*</label>
                  <div>
                    {flashSales.map((el, i) => (
                      <div className="categories mr-2 mb-3" key={i}>
                        <input type="checkbox" value={el.name} name="" className="myCheckbox" />
                        &nbsp;
                        {el.name}


                      </div>

                    ))}

                  </div> */}
                  </div>

                  <div className="mb-3">
                    {isPending && (
                      <button disabled className={`btn btn-block modal_btn`}>
                        Submitting &nbsp;
                        {isPending && (
                          <CircularProgress
                            style={{ color: 'white' }}
                            size="1rem"
                          />
                        )}
                      </button>
                    )}
                    {!isPending && (
                      <button
                        type="submit"
                        className={`modal_btn btn btn-block`}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="text-center">
                <img
                  style={{ width: '40px' }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <h4>Delete Discount</h4>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6B7280',
                  }}
                >
                  Are you sure you want to delete this product? All your data
                  will be permanently removed from our servers forever. This
                  action cannot be undone.
                </p>
              </div>
            </div>

            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={handleDialogClose}
              >
                Cancel
              </Button>
              <Button
                className={`${styles.delete_product}`}
                onClick={deleteSingleProduct}
              >
                Delete
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>

      <GlobalModal
        open={modalOpenC}
        onClose={handleCloseC}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#F5F5F5"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        background="#FFFFFF"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Select Audience
            </h5>
            <span onClick={handleCloseC} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form action="" className="mt-0 form_label">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <LiveLocation label="Location" passData={processLocation} />
                </div>
                <div>
                  <div>
                    <label htmlFor="loc">Product Tags*</label>
                    <div>
                      {flashSales.map((el, i) => (
                        <div className="categories mr-2 mb-3" key={i}>
                          <input
                            type="checkbox"
                            value={el.name}
                            name=""
                            className="myCheckbox"
                          />
                          &nbsp;
                          {el.name}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="name">Select Customer</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={category}
                    name="category"
                    required
                    onChange={handleCategoryChange}
                  >
                    <MenuItem value="select">Select Customer</MenuItem>
                    <MenuItem value="Verified">Merchant</MenuItem>
                    <MenuItem value="Unverified">Shopper</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={handleCloseC}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
      {/*DIALOG*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openGoToCart}
        onClose={handleGoToCartClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openGoToCart}>
          <Box
            className={`mobile_modal_size ${styles.modal_bg}`}
            sx={style1}
            style={{
              height: '300px',
            }}
          >
            <div className={`text-center ${styles.add_to_cart_content}`}>
              <img src="/assets/icons/Illustration.svg" alt="" />
              <h5 className="mt-3">Product Added to Cart</h5>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius
                aliquam laudantium explicabo pariatur iste dolorem animi vitae
                error totam. At sapiente aliquam accusamus facere veritatis.
              </p>
              <div className={`${styles.buttons}  pt-2`}>
                <button
                  className={`${styles.cancel} `}
                  onClick={handleGoToCartClose}
                >
                  Cancel
                </button>
                &nbsp;
                <button
                  className={`${styles.go_to} `}
                  onClick={() => navigate('/app/carts/')}
                >
                  Go to cart
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <GlobalModal
        open={modalOpen1}
        onClose={() => setModalOpen1(false)}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#F5F5F5"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span
              onClick={() => setModalOpen1(false)}
              style={{ cursor: 'pointer' }}
            >
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>
          <form action="" className="mt-0 form_label">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="loc">Type of Discount</label>
                  <Select
                    labelId="s"
                    id="cat"
                    fullWidth
                    size="small"
                    value={filterType}
                    name="filterType"
                    onChange={searchFilterType}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="amount_off_product">
                      Amount Off Product
                    </MenuItem>
                    <MenuItem value="buy_x_get_y">Buy X Get Y</MenuItem>
                  </Select>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="cat">Status</label>
                <Select
                  labelId="s"
                  id="cat"
                  fullWidth
                  size="small"
                  value={filterStatus}
                  name="filterStatus"
                  onChange={searchFilterStatus}
                >
                  <MenuItem value="all">Select Status</MenuItem>
                  <MenuItem value="true">Approved</MenuItem>
                  <MenuItem value="false">Awaiting Approval</MenuItem>
                </Select>
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={() => fetchData()}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
                {isPending && (
                  <CircularProgress
                    style={{
                      color: 'white',
                    }}
                    size="1rem"
                  />
                )}
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
      <DiscountModal closeModal={processModalClose} />
    </>
  );
}
