import styles from "./Outlet.module.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AllStores from "./AllStores";
import Map from "./Maps";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import axiosInstance from "src/axiosInstance";
import toast from "react-hot-toast";
import bus from "src/bus";

export default function BrandOwnerStors() {
  const [value, setValue] = useState("1");
  const [isPending, setIsPending] = useState(false);
  const notifyError = (err) => toast.error(err);
  const [locations, setLocation] = useState([
    { lat: 7.945211, lng: 4.788842, name: "Shop rite", store_address: '' },
    { lat: 6.5037588, lng: 3.6014641, name: "Delight Store", store_address: '' },
    { lat: 6.8299846, lng: 3.9164585, name: "R N B ", store_address: '' },
  ])

  useEffect(() => {
    fetchData()
    bus.on('update-store', (data) => {
      const prevLocations = [...locations]
      prevLocations.push({
        lat: Number(data.latitude),
        lng: Number(data.longitude),
        name: data.store_name
      })

      setLocation(prevLocations)

    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async (
  ) => {
    setIsPending(true);
    console.log(locations);

    try {
      const res = await axiosInstance.get(`brand/store/get-stores?perpage=1000`);
      const data = res.data.data.data.map(el =>  {
        return {lat: Number(el.latitude), lng: Number(el.longitude), name: el.store_name, store_address: el.store_address}
      })
      setLocation(data);
      setIsPending(false);
    } catch (err) {
      setIsPending(false);
      notifyError(err.response.data.message);
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="wrapper mt-1 px-2">
        <div className="header d-block d-md-none mb-4">
          <div className="d-md-flex justify-content-between">
            <div className="mb-3">
              <h4 className={`mb-1 mb-md-0 pb-0`}>Stores</h4>

              <p style={{ fontSize: "14px" }} className={`mb-0 pb-0`}>
                An overview of all stores
              </p>
            </div>
            <div className="">
              <button className={`btn ${styles.import}`}>
                Import CSV File
              </button>
            </div>
          </div>
        </div>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs ">
              <Tab label="All Stores" value="1" />
              <Tab label="Map View" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" style={{ padding: "0" }}>
            <AllStores key={1} />
          </TabPanel>
          <TabPanel value="2" style={{ padding: "0" }}>
            <Map locations={locations} key={2} />
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
}
