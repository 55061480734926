// ----------------------------------------------------------------------
import styles from '../BrandOwner.module.css';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Card, Avatar } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import { useLayoutEffect } from 'react';
import GlobalModal from 'src/Modals/GlobalModal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import { BallTriangle } from 'react-loader-spinner';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateFormat from 'src/hooks/useDateFormat';
import LiveLocation from 'src/pages/LiveLocation';
import { downloadFile3 } from 'src/utils';
import { Pagination } from 'src/components';
import moment from 'moment';
// ----------------------------------------------------------------------

// ptvp=PAGE TO VIEW (PRODUCT)

export default function DeletedTab() {
  const handleViewClick = (d) => {
    // navigate('/app/purchases_details')
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [isPending, setIsPending] = useState('');
  const [userDeletedData, setUserDeletedData] = useState([]);
  const [dataPagination, setDataPagination] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filter, setFilter] = useState({
    dateFrom: '',
    dateTo: '',
    min_balance: '',
    max_balance: '',
    min_order_unit: '',
    max_order_unit: '',
    customer_type: 'select',
    location: '',
    search: '',
  });

  const notifyError = (err) => toast.error(err);

  const handleClose = () => {
    setModalOpen(false);
  };

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvp');

      // Your code here.
    },
    []
  );

  useEffect(() => {
    const obValue = {
      target: {
        name: 'dateFrom',
        value: startDate,
      },
    };
    const obValue1 = {
      target: {
        name: 'dateTo',
        value: endDate,
      },
    };

    handleFilterChange('dateFrom', startDate);
    handleFilterChange('dateTo', endDate);
  }, [startDate, endDate]);

  const processLocation = (e) => {
    handleFilterChange('location', e.formatted_address);
  };

  const handleDownloadFile = async (extension) => {
    try {
      const result = `/admin/customers/get-deleted?page=${dataPagination.currentPage}&perpage=${dataPagination.total}&export_type=${extension}`;
      await downloadFile3('Deleted Account', result, extension);
    } catch (err) {
      notifyError(`Unable to export ${extension} file`);
    }
  };

  const fetchData = (page = 1, perpage = 10) => {
    const filterObject = { ...filter, perpage, page };
    if (filterObject.dateFrom) {
      const dValue = DateFormat(filterObject.dateFrom);
      filterObject.dateFrom = dValue;
    } else {
      filterObject.dateFrom = '';
    }
    if (filterObject.dateTo) {
      const dValue = DateFormat(filterObject.dateTo);
      filterObject.dateTo = dValue;
    } else {
      filterObject.dateTo = '';
    }
    let queryParams = new URLSearchParams({ ...filterObject }).toString();
    setIsPending(true);
    handleClose(false);
    axiosInstance
      .post(`/admin/customers/get-deleted?${queryParams}`)
      .then((res) => {
        // console.log(res.data.data);
        if (res.status < 300) {
          setUserDeletedData(res.data.data.data);
        }
        setDataPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError(
            'Unable to fetch deleted records, please try again later'
          );
        }
      })
      .finally(() => setIsPending(false));
  };
  const handleFilterChange = (key, value) => {
    setFilter((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.search]);

  return (
    <>
      <div className=" px-2">
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-6   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={filter.search}
                onChange={(e) => handleFilterChange('search', e.target.value)}
                placeholder="Search"
                style={{
                  textIndent: '25px',
                  width: '60%',
                }}
                className=" form-control"
              />
            </div>
            <div className="col-6  text-right">
              <button
                onClick={() => setModalOpen(true)}
                className="btn  border"
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px',
                }}
              >
                <img
                  className=""
                  style={{ display: 'inline', width: '17px' }}
                  src="/assets/icons/filterlines.svg"
                  alt=""
                />
                &nbsp;
                <small className="d-none d-md-inline-block">Filters</small>
              </button>
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: '50px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-4 border-bottom">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">Deleted Account</h5>
                <small style={{ fontWeight: '200', fontSize: '14px' }}>
                  Details of deleted accounts
                </small>
              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`${styles.export_btn} btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: '8px',
                      }}
                    >
                      <img src="/assets/icons/downloadblue.svg" alt="" /> &nbsp;
                      Export
                    </span>
                    &nbsp; &nbsp;
                    <span
                      style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <img src="/assets/icons/chevron-downblue.svg" alt="" />
                    </span>
                  </button>

                  <div
                    className="dropdown-menu drop-left"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('csv')}
                    >
                      CSV
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('excel')}
                    >
                      Excel
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('pdf')}
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`pt-3 pb-3 pl-0 pr-0`}>
              <table
                className={`${styles.table} table-hover table-striped`}
                style={{ minWidth: '70em' }}
              >
                <thead className={`${styles.thead}`}>
                  <tr>
                    <th
                      style={{
                        width: '10%',
                      }}
                      className="text-muted"
                    >
                      S/N
                    </th>
                    <th
                      style={{
                        width: '30%',
                      }}
                      className="text-muted"
                    >
                      Customer Name
                      <img
                        className="pl-2"
                        src="/assets/icons/down.svg"
                        alt=""
                      />
                    </th>
                    <th
                      style={{
                        width: '30%',
                      }}
                      className="text-muted"
                    >
                      User Type
                      <img
                        className="pl-2"
                        src="/assets/icons/down.svg"
                        alt=""
                      />
                    </th>
                    <th
                      style={{
                        width: '30%',
                      }}
                      className="text-muted"
                    >
                      Customer Location
                    </th>
                    <th
                      style={{
                        width: '30%',
                      }}
                      className="text-muted"
                    >
                      Orders
                    </th>
                    <th
                      style={{
                        width: '10%',
                      }}
                      className="text-muted"
                    >
                      Price
                    </th>
                    <th
                      style={{
                        width: '10%',
                      }}
                      className="text-muted"
                    >
                      Deleted At
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {!isPending &&
                    userDeletedData.length >= 1 &&
                    userDeletedData.map((el, i) => (
                      <tr key={i} className={`${styles.tr} `}>
                        <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                        <td>
                          <div className="d-flex justify-content-start align-items-center">
                            <Avatar
                              className={`${styles.avatrr} mr-3 float-md-left`}
                              alt=""
                              src={'/assets/image1.svg'}
                            />
                            <div className=" ">
                              <p
                                className="mb-0"
                                style={{ fontSize: '14px', whiteSpace: 'wrap' }}
                              >
                                {el?.name ? el?.name : '---'}
                              </p>
                              <span style={{ fontSize: '10px' }}>
                                {el?.email ? el?.email : '---'}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p>{el?.user_type}</p>
                        </td>
                        <td>
                          <p>{el?.address || '---'}</p>
                        </td>
                        <td>
                          <span>
                            {el?.orders
                              ? Number(el?.orders).toLocaleString()
                              : 0}
                          </span>
                        </td>
                        <td>
                          ₦{' '}
                          {el.wallet_balance?.$numberDecimal
                            ? Number(
                                el.wallet_balance?.$numberDecimal
                              ).toLocaleString()
                            : 0}
                        </td>
                        <td>
                          <span>
                            {el?.updatedAt
                              ? moment(el?.updatedAt).format('DD-MM-YYYY')
                              : '---'}
                            <br />
                            <small>
                              {el?.updatedAt
                                ? moment(el?.updatedAt).format('hh:mm:ss A')
                                : '---'}
                            </small>
                          </span>
                        </td>
                        {/* <td>
                        <div className="dropleft ">
                          <span
                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"

                            className={`${styles.action_button} px-3 py-2`}  style={{
                              cursor: 'pointer',
                            }}>
                            <img src="/assets/icons/action.svg" alt="" />
                          </span>
                          <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                            <button type="button" onClick={() => handleViewClick(el?._id)} className="rss dropdown-item btn border-0">Clear</button>

                          </div>
                        </div>
                      </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!userDeletedData.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={dataPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#ffff"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>
          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="name">Select Customer</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    required
                    value={filter.customer_type}
                    name="customer_type"
                    onChange={(e) =>
                      handleFilterChange('customer_type', e.target.value)
                    }
                  >
                    <MenuItem value="">Select Customer Status</MenuItem>
                    <MenuItem value="brand">Brand Owner</MenuItem>
                    <MenuItem value="shop">Merchant</MenuItem>
                    <MenuItem value="shopper">Shopper</MenuItem>
                  </Select>
                </div>
                <div className="mb-3">
                  <LiveLocation label="Location" passData={processLocation} />
                </div>
                <label htmlFor="price">Date </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Wallet Balance </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.min_balance}
                        id="price"
                        placeholder="Min"
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('min_balance', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.max_balance}
                        placeholder="Max "
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('max_balance', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.min_order_unit}
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('min_order_unit', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        value={filter.max_order_unit}
                        type="number"
                        placeholder="Max "
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('max_order_unit', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={() => {
                  handleClose();
                  fetchData();
                }}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
    </>
  );
}
