import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#e8dfe7',
  400: '#d5c4d4',
  500: '#8C2D91',
  600: '#4B5563', // --text-primary
  700: '#53138D',
  800: '#af3585',
  900: '#53138D',
};

const PRIMARY = {
  lighter: '#fcd1f3',
  light: '#53138D',
  main: '#AF3585', // color change theme
  dark: '#53138D',
  darker: '#53138D',
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: '#ffd6fa',
  light: '#8C2D91',
  main: '#53138D',
  dark: '#53138D',
  darker: '#53138D',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#fdd0ff',
  light: '#53138D',
  main: '#53138D',
  dark: '#53138D',
  darker: '#53138D',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: '#ffd3fa', // opac-rimary-color
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
