import React from "react";
import { Page, Text, Image, View, Document, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
// import MyImage from "./xpress.svg";

// import LebronStretch from "../photos/lebron_transparent.png";
// import {Font} from '@react-pdf/renderer';
// import MyCustomFont from '../fonts/Anton-Regular.ttf';

// Font.register({
//   family: 'AntonFamily',
//   src: MyCustomFont
// })

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        // paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: "center",
        // fontFamily: "AntonFamily",
    },
    text: {
        margin: 12,
        fontSize: 12,
        fontWeight: 'light'
        // fontFamily: "AntonFamily",

    },
    image: {
        width: 200, // Adjust the width as needed
        height: 40, 
        marginLeft:10,
    },
    header: {
    fontSize: 20,
    fontWeight: 'bolder',
    marginBottom: 20,
    textAlign: "center",
    // fontFamily: "AntonFamily",
},
    sub_header: {
    fontSize: 14,
    paddingTop: 20,
    fontWeight: 'bolder',
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    borderTop: '1px solid #ccc'
    // fontFamily: "AntonFamily",
},
    pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    // fontFamily: "AntonFamily",
},
});

const SponsoredAdReceipt = ({ data }) => {

    return (
        <Document title="Receipt">
            <Page style={{ ...styles.body }}>
                {/* <Image style={styles.image} src="https://images.pexels.com/photos/3888151/pexels-photo-3888151.jpeg?auto=compress&cs=tinysrgb&w=1600" /> */}
                <Image style={styles.image} src="https://retail-express.sfo3.cdn.digitaloceanspaces.com/defaults/Xpress_logo.png" />


                <Text style={styles.header} fixed>
                    Receipt
                </Text>

                <Text style={styles.sub_header} fixed>
                    Sponsored Ads
                </Text>

                <View style={{ flexDirection: 'row', justifyContent: 'space-around' }} >
                    <View style={{ flexDirection: 'column' }} >
                        <Text style={styles.text}>  Duration:</Text>
                    </View>

                    <View style={{ flexDirection: 'column' }} >
                        <Text style={styles.text}>{data?.pricing_id.duration}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-around' }} >

                    <View style={{ flexDirection: 'column' }} >
                        <Text style={styles.text}>Date Placed:</Text>
                    </View>
                    <View style={{ flexDirection: 'column' }} >
                        <Text style={styles.text}>{moment(data?.createdAt).format('DD/MM/YYYY')}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-around' }} >

                    <View style={{ flexDirection: 'column' }} >
                        <Text style={styles.text}>Total Fees:</Text>
                    </View>
                    <View style={{ flexDirection: 'column' }} >
                        <Text style={styles.text}>{data?.pricing_id.subscription_fee}</Text>
                    </View>
                </View>
                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) =>
                        `${pageNumber} / ${totalPages}`
                    }
                />
            </Page>

        </Document>
    );
};

export default SponsoredAdReceipt;