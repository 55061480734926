import React, { useEffect } from 'react';

const GoogleMapHeatmap = ({ data }) => {
  useEffect(() => {
    // AIzaSyDD9PxamXYgjCwIAceKLPwmHA53XNLGAtQ // former api key
    const loadGoogleMapsScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCvH_nDKUVAcmjtKEbv-_YDY9rVbGqg4VQ&loading=async&libraries=visualization&callback=initMap`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        if (window.initMap) {
          window.initMap();
        } else {
          console.error('initMap function not found.');
        }
      };

      document.head.appendChild(script);

      return () => {
        // Cleanup script when component is unmounted
        document.head.removeChild(script);
      };
    };

    if (!window.google || !window.google.maps) {
      loadGoogleMapsScript();
    } else {
      // If Google Maps is already loaded, call initMap directly
      initMap();
    }
  }, [data]);

  function initMap() {
    const mapDataIsEmpty = data.length === 0;

    const map = new window.google.maps.Map(document.getElementById('heatmap'), {
      zoom: 12,
      center: {
        lat: mapDataIsEmpty ? 6.543147599999999 : parseFloat(data[0].latitude[0]),
        lng: mapDataIsEmpty ? 3.3931796 : parseFloat(data[0].longitude[0]),
      },
    });

    console.log('heatmap:', map);

    const heatmapData = data.map((item) => ({
      location: new window.google.maps.LatLng(
        parseFloat(item.latitude[0]),
        parseFloat(item.longitude[0])
      ),
      weight: item.totalOrder,
    }));

    const heatmap = new window.google.maps.visualization.HeatmapLayer({
      data: heatmapData,
      radius: 30,
    });

    heatmap.setMap(map);
  };

  return <div id="heatmap" style={{ height: '100vh' }}></div>;
};

export default GoogleMapHeatmap;
