import ComponentRepeater from "../componentRepeater";

const mobile = () => {
 
  return (
    <>
      <ComponentRepeater  name = "mobile" heading="Advert/Promos/Offers" />
    </>
  )
};

export default mobile;
