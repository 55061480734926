import React from 'react';
import StarIcon from './StarIcon';

const StarRating = ({ rating = 0 }) => {
  const roundedRating = Math.round(rating * 10) / 10;

  const totalStars = 5;
  const fullStars = Math.floor(roundedRating);
  const decimalPart = roundedRating - fullStars;

  let hasHalfStar = false;
  if (decimalPart >= 0.25 && decimalPart <= 0.75) {
    hasHalfStar = true;
  } else if (decimalPart > 0.75) {
    hasHalfStar = true;
  } else if (decimalPart > 0 && decimalPart < 0.25) {
    hasHalfStar = true;
  }

  const renderFullStars = () => {
    const stars = [];
    for (let i = 0; i < fullStars; i++) {
      stars.push(<StarIcon key={i} className="star full-star" color="#FFA500" />);
    }
    return stars;
  };

  const renderHalfStar = () => {
    return hasHalfStar ? <StarIcon className="star half-star" color="#FFA500" isHalf /> : null;
  };

  const renderEmptyStars = () => {
    const emptyStars = totalStars - fullStars - (hasHalfStar ? 1 : 0);
    const stars = [];
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<StarIcon key={i} className="star empty-star" color="#ccc" />);
    }
    return stars;
  };

  return (
    <div className="star-rating">
      {renderFullStars()}
      {renderHalfStar()}
      {renderEmptyStars()}
    </div>
  );
};

export default StarRating;


// import React from 'react';
// import StarIcon from './StarIcon';

// const StarRating = ({ rating = 0 }) => {
//   // Round the rating to one decimal place
//   const roundedRating = Math.round(rating * 10) / 10;

//   const totalStars = 5;
//   const fullStars = Math.floor(roundedRating);
//   const decimalPart = roundedRating - fullStars;

//   let hasHalfStar = false;
//   if (decimalPart >= 0.25 && decimalPart <= 0.75) {
//     hasHalfStar = true;
//   } else if (decimalPart > 0.75) {
//     hasHalfStar = false;
//     fullStars++; // Round up to the next full star
//   } else if (decimalPart > 0 && decimalPart < 0.25) {
//     hasHalfStar = true;
//   }

//   const renderFullStars = () => {
//     const stars = [];
//     for (let i = 0; i < fullStars; i++) {
//       stars.push(<StarIcon key={i} className="star full-star" color="#FFA500" />);
//     }
//     return stars;
//   };

//   const renderHalfStar = () => {
//     return hasHalfStar ? <StarIcon className="star half-star" color="#FFA500" isHalf /> : null;
//   };

//   const renderEmptyStars = () => {
//     const emptyStars = totalStars - fullStars - (hasHalfStar ? 1 : 0);
//     const stars = [];
//     for (let i = 0; i < emptyStars; i++) {
//       stars.push(<StarIcon key={i} className="star empty-star" color="#ccc" />);
//     }
//     return stars;
//   };

//   return (
//     <div className="star-rating">
//       {renderFullStars()}
//       {hasHalfStar && !Number.isInteger(rating) && renderHalfStar()}
//       {renderEmptyStars()}
//     </div>
//   );
// };

// export default StarRating;


