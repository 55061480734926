/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------------
import styles from '../BrandOwner.module.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ProductsModal from '../Modals/ProductsModal.js';
import SuperAdminProductsModal from '../Modals/SuperAdminProductsModal.js';
import bus from 'src/bus';
import axiosInstance from 'src/axiosInstance';
import { BallTriangle } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Card, Avatar } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import { createRef } from 'react';
import { forwardRef, useLayoutEffect } from 'react';
import SingleView from './SingleView';
import { downloadFile2 } from 'src/utils';
import { Pagination } from 'src/components';
import { ImagePreviewModal } from 'src/components';
// ----------------------------------------------------------------------

import { useCategories } from 'src/hooks/useCategories';
import toast from 'react-hot-toast';
import { ImageUploader } from '../../../components';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const categoryStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  // height: 260,
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3,
};
const style1d = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  overflow: 'scroll',
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

let app_view = localStorage.getItem('ptvp') ?? 0;
export default function ProductsPageBrandowwner() {
  let textInput = createRef();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [openGoToCart, setopenGoToCart] = useState(false);
  const categories = useCategories();
  const [isPending, setIsPending] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const navigate = useNavigate();

  const [newProducts, setNewProducts] = useState({
    notifications: [],
    cases_type: 'Cartons',
  });
  const handleGoToCartClose = () => setopenGoToCart(false);

  const [open1, setOpen1] = useState(false);
  const [category, setCategory] = useState('');
  const [categoryImage, setCategoryImage] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});
  const [url, setUrl] = useState('');
  const [appView, setAppview] = useState(app_view);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open2 = Boolean(anchorEl1);
  const [categoriesData, setCategoriesdata] = useState([]);
  const [categoryPagination, setCategoryPagination] = useState({ total: 0 });
  const notifyError = (err) => toast.error(err);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchvalue, setSearchvalue] = useState('');
  const [modalTitle, setmodalTitle] = useState('');
  const [modal_desc, setmodal_desc] = useState('');
  const [addCategory, setAddCategory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);

  const searchFilter = (e) => {
    setSearchvalue(e.target.value);
  };

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvp');

      // Your code here.
    },
    []
  );

  const handleChange = (event) => {
    setCategory(event.target.value);
  };
  const handleCategoryImage = (val) => {
    setCategoryImage(val);
  };

  const handleViewClick = (val) => {
    localStorage.setItem('selected_category_name', JSON.stringify(val));
    navigate('/app/products/' + val._id);
    // handle view click action
  };

  const handleActions = (val) => {
    setOpenDialogDelete(!openDialogDelete);
    setSelectedCategory(val);
  };

  const handleDeleteCategories = (e) => {
    setIsDelete(true);
    setTimeout(() => {
      setOpenDialogDelete(false);
    }, [1000]);
    setmodalTitle('Category deleted');
    axiosInstance
      .delete('/admin/product/category/' + selectedCategory._id)
      .then((res) => {
        if (res.status < 300) {
          setOpenPopUp(true);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to delete product, try again later');
        }
      })
      .finally(() => setIsDelete(false));
  };

  const reloadPage = (val) => {
    setAppview(val);
  };

  const refreshPage = () => {
    setOpenPopUp(!openPopUp);
    fetchData();
  };

  const refreshData = () => {
    // setOpenPopUp(!openPopUp)
    fetchData();
  };

  const handleAddCategory = (e) => {
    e.preventDefault();
    let filter_payload = { category_image: categoryImage };
    if (category !== '') {
      filter_payload.category_name = category;
    }
    const formData = new FormData();

    Object.keys(filter_payload).forEach((key) =>
      formData.append(key, filter_payload[key])
    );

    setLoading(true);
    axiosInstance
      .post('/admin/product/create-category', formData)
      .then((res) => {
        if (res.status < 300) {
          handleClose();
          setAddCategory(true);
          bus.emit('fetchProductCategory');
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to process data, try again later');
        }
      })
      .finally(() => setLoading(false));
  };

  const handleDownloadFile = async (extension) => {
    try {
      const result = `/admin/product/category-count?page=${categoryPagination.currentPage}&perpage=${categoryPagination.total}&export_type=${extension}`;
      await downloadFile2('Product Categories', result, extension);
    } catch (err) {
      notifyError(`Unble to export ${extension} file`);
    }
  };

  const fetchData = (page = 1, perpage = 10, file = null) => {
    let filter_payload = {};
    if (searchvalue !== '') {
      filter_payload.search = searchvalue;
    }
    setIsPending(true);
    axiosInstance
      .get('/admin/product/category-count', {
        params: {
          page,
          perpage,
          file,
          ...filter_payload,
        },
      })
      .then((res) => {
        setCategoriesdata(res.data.data.data);
        setCategoryPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch categories, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchvalue, addCategory]);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isUrl, setIsUrl] = useState(false);

  const handlePreviewImg = (url) => {
    setIsPreviewOpen(true);
    setIsUrl(url);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  return Number(appView) === 0 ? (
    <>
      <div className="mt-3 px-2">
        <div className={`${styles.filters}  mss`}>
          <div className="row">
            <div className={`${styles.hold_input2} col-md-3   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={searchvalue}
                onChange={searchFilter}
                placeholder="Search"
                style={{ textIndent: '25px', width: '100%' }}
                className=" form-control"
              />
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: '10px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-4 border-bottom">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">Product Categories</h5>
                <small style={{ fontWeight: '200', fontSize: '14px' }}>
                  View and manage products and categories
                </small>
              </div>
              <div className="pt-md-2 d-md-flex">
                <div className={` `}>
                  {/* <button onClick={() => bus.emit('open-category')} type="button" className={`${styles.export_btn1} btn m-0`} */}
                  <button
                    onClick={() => setOpen(true)}
                    type="button"
                    className={`${styles.export_btn} btn m-0 d-flex justify-content-between gap-6 align-items-center border border-[#af3585] rounded`}
                    style={{
                      border: 'none',
                    }}
                  >
                    <div className="px-4">+</div>
                    <div>Create Category</div>
                  </button>
                </div>
                &nbsp; &nbsp;
                <div className="dropleft">
                  <button
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`${styles.export_btn} btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: '8px',
                      }}
                    >
                      <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                      Export
                    </span>
                    &nbsp; &nbsp;
                    <span
                      style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <img src="/assets/icons/arrowDown.svg" alt="" />
                    </span>
                  </button>
                  <div
                    className="dropdown-menu drop-left mb-1"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('csv')}
                    >
                      CSV
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('excel')}
                    >
                      Excel
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('pdf')}
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`table-responsive-sm  pt-3 pb-3 pl-0 pr-0`}>
              <table className={`${styles.table} table-hover table-striped`}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    <th
                      style={{
                        width: '40%',
                      }}
                      className="text-muted"
                    >
                      Category Name
                      <img
                        className="pl-2"
                        src="/assets/icons/down.svg"
                        alt=""
                      />
                    </th>
                    <th
                      style={{
                        width: '30%',
                      }}
                      className="text-muted "
                    >
                      No of Product
                    </th>
                    <th
                      style={{
                        width: '30%',
                      }}
                      className="text-muted"
                    >
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {!isPending &&
                    categoriesData.length > 0 &&
                    categoriesData?.map((el, i) => (
                      <tr key={i} className={`${styles.tr} `}>
                        <td>
                          <p className="mb-0" style={{ fontSize: '14px' }}>
                            {el?.category_name}
                            <Avatar
                              className={`${styles.avatrr} mr-3 float-md-left`}
                              onclick={
                                el?.category_image
                                  ? () => handlePreviewImg(el?.category_image)
                                  : null
                              }
                              alt=""
                              src={
                                el?.category_image
                                  ? el?.category_image
                                  : '/assets/Avatars.svg'
                              }
                              style={{
                                cursor: 'pointer',
                              }}
                            />
                          </p>
                        </td>
                        <td>
                          <span>
                            {el?.total_product
                              ? Number(el?.total_product).toLocaleString()
                              : 0}
                          </span>
                        </td>
                        <td className="d-flex mt-3">
                          <button
                            onClick={() => handleViewClick(el)}
                            className={`btn ${styles.view_action}`}
                          >
                            <img
                              src="/assets/icons/eyewhite.svg"
                              className="pr-1"
                              alt=""
                            />
                            View
                          </button>
                          &nbsp; &nbsp;
                          <button
                            onClick={() => bus.emit('open-product', el)}
                            className={`btn ${styles.add_product}`}
                          >
                            <img
                              src="/assets/icons/plusgray.svg"
                              className="pr-1"
                              alt=""
                            />
                            <span className={`${styles.hide}`}>Add</span>{' '}
                            Product
                          </button>
                          <div className="dropleft ml-5">
                            <span
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              className={`${styles.action_button} px-3 py-2`}
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <img src="/assets/icons/action.svg" alt="" />
                            </span>
                            <div
                              className="dropdown-menu drop-left"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <button
                                type="button"
                                onClick={() => handleActions(el)}
                                className="rss dropdown-item text-danger btn border-0"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!categoriesData?.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={categoryPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>

      {/* Modals */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open}>
          <Box className={`mobile_modal_size`} sx={categoryStyle}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3>Create Category</h3>
                <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <div
                style={{
                  fontSize: '14px',
                  marginBottom: '0px',
                }}
              >
                Add New Category
              </div>

              <form onSubmit={handleAddCategory}>
                <div className="mb-4 w-50">
                  <label> Category Image *</label>
                  <ImageUploader imageResult={handleCategoryImage} />
                </div>

                <div className="mb-3">
                  <label htmlFor="name">Category Name</label>
                  <div className="row">
                    <div className="col-12">
                      <input
                        className="form-control"
                        type="text"
                        required
                        value={category}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <button
                    className={`${styles.modal_btn} btn btn-block`}
                    disabled={loading}
                  >
                    Add{' '}
                    {loading && (
                      <CircularProgress
                        style={{ color: 'white' }}
                        size="0.8rem"
                      />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Dialog
        open={openDialogDelete}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenDialogDelete(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="text-center">
                <img
                  style={{ width: '40px' }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <h4>Delete Category</h4>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6B7280',
                  }}
                >
                  Are you sure you want to delete this category? All your data
                  will be permanently removed from our servers forever. This
                  action cannot be undone.
                </p>
              </div>
            </div>

            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={() => setOpenDialogDelete(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleDeleteCategories()}
                type="button"
                className={`${styles.delete_product}`}
              >
                Delete{'  '}
                {isDelete && (
                  <CircularProgress
                    className="ml-1"
                    style={{ color: 'red' }}
                    size="1rem"
                  />
                )}
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openPopUp}
        onClose={() => setOpenPopUp(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openPopUp}>
          <Box className={`mobile_modal_size modal_bg`} sx={style1d}>
            <div className={`text-center add_to_cart_content`}>
              <img src="/assets/icons/circleCheck.svg" alt="" />
              <h5 className="mt-3 mb-2" style={{ fontWeight: 'bold' }}>
                {modalTitle}
              </h5>
              <p
                className="text-center mb-3"
                style={{ textAlign: 'center !important' }}
              >
                {modal_desc}
              </p>
              <button
                onClick={() => refreshPage()}
                className={`modal_btn hover btn btn-block`}
              >
                Go Back
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* <ImagePreviewModal isOpen={isPreviewOpen} onClose={handleClosePreview} imageUrl={isUrl} /> */}
      <ProductsModal
        key={3}
        closeModal={() => setShowDiscount(false)}
        refresh={refreshData}
      />
      <SuperAdminProductsModal key={4} />
    </>
  ) : (
    <SingleView key={1} reload={reloadPage} />
  );
}
