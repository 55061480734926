

// ----------------------------------------------------------------------
import styles from "./BrandOwner.module.css"
import { useEffect, useState } from "react"
// sections
// mock
import { useLayoutEffect } from "react";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Products from "./RetailerComponent/Products/Index";
import Store from "./RetailerComponent/Store/Index";
import StoreSingleView from "./RetailerComponent/Store/SingleView";
import SingleView from "./RetailerComponent/Products/SingleView";
import RetailerSingleView from "./RetailerComponent/SingleView";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
// ----------------------------------------------------------------------
// ptvp=PAGE TO VIEW (PRODUCT)

export default function ProductRetailerPage() {
  const { data } = useAuth();

  const [value, setValue] = useState('1');
  const { categoryId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // const tabIndex = localStorage.getItem('tab');
    // if (tabIndex) {
    //   setValue(tabIndex)
    // }
  }, [])




  useLayoutEffect(() => () => {
    localStorage.removeItem('ptvp');

    // Your code here.
  }, [])

  const handleTabChange = (event, newValue) => {
    navigate('/app/products')
    setValue(newValue);
  };

  return (
    <>
      <div className="wrapper mt-2 px-2">
        <div className="header d-block d-md-none mb-4">
          <div className={`${styles.product_actions} d-md-flex justify-content-between`}>
            <div className=" ">
              <h4 className={`mb-0 pb-0`}>Products</h4>
              <p style={{ fontSize: '14px' }} className={`mb-0 mt-2 pb-0`}>
                Review and manage product categories
              </p>
            </div>
          </div>

        </div>
        <TabContext value={value}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs ">
            <Tab label="Products" value="1" />
            <Tab label="My Store " value="2" />
            {
              data.merchant_type !== 'shop' &&
              <Tab label="Promos and Discounts " value="3" />
            }
          </TabList>
          <TabPanel value="1" style={{ paddingLeft: '0' }}>
            {categoryId === undefined &&
              <Products key={3} />
            }
            {categoryId !== undefined &&
              <SingleView categoryId={categoryId} key={3} />
            }


          </TabPanel>
          <TabPanel value="2" style={{ paddingLeft: '0' }}>
            {categoryId === undefined &&
              <Store key={4} />
            }
            {categoryId !== undefined &&
              <StoreSingleView categoryId={categoryId} key={4} />
            }


          </TabPanel>
          <TabPanel value="3" style={{ paddingLeft: '0' }}>
            <RetailerSingleView key={6} />



          </TabPanel>
        </TabContext>

      </div>

    </>
  )
}
