import styles from '../../SalesPage/Sales.module.css';
import { useAuth } from 'src/hooks/useAuth';
import { Card } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from 'src/axiosInstance';
import moment from 'moment';
import { BallTriangle } from 'react-loader-spinner';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import bus from 'src/bus';
import CircularProgress from '@mui/material/CircularProgress';
import { Pagination } from 'src/components';

// ----------------------------------------------------------------------

let TABLE_HEAD = [
  { id: 'details', label: 'Product Details', alignRight: false },
  { id: 'quantity', label: 'Quantity', alignRight: false },
  { id: 'unitPrice', label: 'Unit price', alignRight: false },
  { id: 'totalPrice', label: 'Total price', alignRight: false },
];

// ----------------------------------------------------------------------

export default function OrderPage({ onChange }) {
  const navigate = useNavigate();
  const { data } = useAuth();
  const [isPending, setIsPending] = useState(false);
  const [purchaseData, setdata] = useState({ items: [] });
  const [purchasePagination, setPurchasePagination] = useState({ total: 0 });
  const notifyError = (err) => toast.error(err);
  const notify = (msg) => toast.success(msg);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filter, setFilter] = useState(false);
  const [refundPending, setRefundPending] = useState(false);

  if (data.user_type == 'admin') {
    TABLE_HEAD = [
      { id: 'details', label: 'Product Details', alignRight: false },
      { id: 'brand', label: 'Brand', alignRight: false },
      { id: 'quantity', label: 'Quantity', alignRight: false },
      { id: 'unitPrice', label: 'Unit price', alignRight: false },
      { id: 'totalPrice', label: 'Total price', alignRight: false },
    ];
  }
  const { purchase_id } = useParams();

  function handleClick(event) {
    event.preventDefault();
    navigate('/app/purchases');
  }

  useEffect(() => {
    fetchData();
  }, [filter]);

  bus.on('reload', () => {
    fetchData();
  });

  useEffect(() => {
    console.log(purchaseData);
  }, [purchaseData]);

  const fetchData = () => {
    setIsPending(true);
    axiosInstance
      .get('/admin/purchases/get-shopowners-purchases/' + purchase_id)
      .then((res) => {
        setdata(res.data.data);
        setPurchasePagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch purchases, please try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const handleRefundTransaction = () => {
    setRefundPending(true);
    axiosInstance
      .post('/admin/transactions/refund/' + purchaseData?._id, {
        transaction_id: purchaseData?._id,
      })
      .then((res) => {
        notify(res.data.message);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to refund transactiom, try again later');
        }
      })
      .finally(() => setRefundPending(false));
  };

  return (
    <>
      <div className={` px-2 pt-5`}>
        <button
          onClick={handleClick}
          className="btn mt-4"
          style={{ zIndex: '6000 !important' }}
        >
          <img src="/assets/icons/Steplink.svg" alt="" /> &nbsp;
          {/* Sales */}
        </button>
        <div className={`${styles.header}   mt-4`}>
          <img src="/assets/shoprite.svg" className="float-md-left" alt="" />

          <div>
            <span style={{ display: 'inline-block !important' }}>
              <div className="row">
                <div className="col-4">
                  <h1 style={{ display: 'inline !important' }}>
                    {purchaseData?.shop_id?.store_name}
                  </h1>
                </div>
                <div className="col-4 pt-4">
                  {data.user_type != 'admin' && (
                    <div>
                      <small className="">(Mom & Pop)</small>
                    </div>
                  )}
                </div>
              </div>
              {/* <h1 className="bg-danger" style={{ display: 'inline !important' }}>
                                Store 1
                            </h1> */}
            </span>

            <p className="mb-1 text-muted">
              <small>
                <img
                  className="d-inline-block mr-1"
                  src="/assets/icons/mail2.svg"
                  alt=""
                />
                {purchaseData?.shop_id?.store_email}
              </small>
              &nbsp;&nbsp;&nbsp;
              <small>
                <img
                  className="d-inline-block mr-1"
                  src="/assets/icons/phone.svg"
                  alt=""
                />

                {purchaseData?.shop_id?.store_phone_number}
              </small>
            </p>
            <p className="pl-2">
              <small className="text-muted">
                <img
                  className="d-inline-block mr-1 text-muted"
                  src="/assets/icons/map.svg"
                  alt=""
                />

                {purchaseData?.delivery_information?.address}
              </small>
            </p>
          </div>
        </div>

        <Card
          style={{ marginTop: '50px', borderRadius: '10px' }}
          className="p-0"
        >
          <Scrollbar style={{ overflowX: 'hidden' }}>
            <div className="row">
              <div className="col-md-8  mb-2">
                {data.user_type != 'admin' && (
                  <div className="pl-3 pr-3 pt-3 pb-0 mb-0">
                    <h3>Sales</h3>
                    <small style={{ fontWeight: '200', fontSize: '12px' }}>
                      Sale Number: 1xr538
                    </small>
                    <small style={{ display: 'inline-block', float: 'right' }}>
                      4 items
                    </small>
                  </div>
                )}
                {data.user_type == 'admin' && (
                  <div className="pl-3 pr-3 pt-3 pb-0 mb-0">
                    <h3>Orders</h3>
                    <span
                      className="text-muted"
                      style={{ fontWeight: '200', fontSize: '16px' }}
                    >
                      Order Number: {purchaseData?.order_number}
                    </span>
                    <small style={{ display: 'inline-block', float: 'right' }}>
                      {purchaseData?.items?.length} items
                    </small>
                  </div>
                )}

                <div
                  className={`table-responsive-sm mt-0 p-3`}
                  style={{
                    paddingTop: '0 !important',
                  }}
                >
                  <table className={`${styles.table} mt-0`}>
                    <thead className={`${styles.thead}`}>
                      <tr>
                        {TABLE_HEAD.map((el, i) => {
                          return (
                            <th style={{ fontSize: '16px !important' }}>
                              {el?.label}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseData.items.length > 0 &&
                        purchaseData.items.map((el, i) => {
                          return (
                            <tr key={el._id} className={`${styles.tr}`}>
                              <td>
                                <div className=" ">
                                  <div className=" ">
                                    <img
                                      className="float-left"
                                      src={
                                        el?.product_id?.product_image
                                          ? el.product_id.product_image
                                          : '/assets/purelifetablewater.svg'
                                      }
                                      alt=""
                                      style={{
                                        height: '100px',
                                        width: '100px',
                                      }}
                                    />
                                    &nbsp; &nbsp;
                                    <div
                                      style={{
                                        display: 'inline-block',
                                      }}
                                    >
                                      <p
                                        className="mb-0"
                                        style={{ fontSize: '14px' }}
                                      >
                                        <span>
                                          {el?.product_id?.product_name}
                                        </span>
                                      </p>
                                      <span style={{ fontSize: '10px' }}>
                                        50kg
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              {data.user_type == 'admin' && (
                                <td>
                                  {el?.product_id?.brand_id?.business_name}
                                </td>
                              )}
                              <td>{el?.quantity}</td>
                              <td>₦ {Number(el?.price).toLocaleString()}</td>
                              <td>
                                ₦ {Number(el?.total_amount).toLocaleString()}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-center">
                    {isPending && (
                      <BallTriangle
                        height={50}
                        width={55}
                        radius={5}
                        color="#af3585"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`${styles.container_checkout} col-md-4 ${styles.height} `}
                style={{ background: '#E5E7EB' }}
              >
                <div
                  className=" pt-4  px-2 border-bottom"
                  style={{ background: 'inherit !important' }}
                >
                  <h4 className="font-weight-bold">Summary</h4>
                </div>
                <div className="border-bottom-white mt-4 px-2">
                  <div className="d-flex justify-content-between">
                    <p className={`${styles.summarytitles}`}>Total Order</p>
                    <p className={`${styles.summaryValues}`}>
                      ₦{' '}
                      {purchaseData?.subtotal
                        ? Number(purchaseData?.subtotal).toLocaleString()
                        : 0}
                    </p>
                  </div>
                  {/* {
                                        data.user_type == 'admin' && <div>
                                        <p className={`${styles.summarytitles} ${styles.inline_block}`}>
                                            Promo Code
                                        </p>
                                        <p className={`${styles.summaryValues} ${styles.inline_block} float-right`}
                                        style={{
                                            backgroundColor: "white",
                                            padding:'5px'
                                        }}>
                                                KYL231
                                        </p>

                                        <table width={'100%'}>
                                            <tr>
                                                <td width={'50%'}>
                                                    <p className={`${styles.summarytitles}`}>
                                                        Promo Discount
                                                    </p>
                                                </td>
                                                <td width={'50%'}>
                                                <p className={`${styles.summaryValues} ${styles.inline_block} float-right`}
                                                style={{
                                                    backgroundColor: "white",
                                                    padding:'5px',
                                                    width:'65px',
                                                    textAlign:'center'
                                                }}>
                                                        0%
                                                </p>
                                                </td>
                                            </tr>
                                        </table>

                                        <div className="d-flex justify-content-center">
                                            {isPending &&
                                            <BallTriangle
                                                height={50}
                                                width={55}
                                                radius={5}
                                                color="#af3585"
                                                ariaLabel="ball-triangle-loading"
                                                wrapperClass={{}}
                                                wrapperStyle=""
                                                visible={true}
                                            />
                                            }

                                        </div>
                                        </div>
                                    } */}
                </div>
                {data.user_type == 'admin' &&
                purchaseData?.status === 'completed' ? (
                  <button
                    onClick={() => handleRefundTransaction(purchaseData?._id)}
                    style={{ marginTop: '50px' }}
                    className="btn btn-[#af3585] text-white form-control"
                  >
                    Refund{' '}
                    {refundPending && (
                      <CircularProgress style={{ color: '#fff' }} size="1rem" />
                    )}
                  </button>
                ) : (
                  <button
                    disabled
                    style={{ marginTop: '50px' }}
                    className="btn btn-[#af3585] text-white form-control"
                  >
                    Refund
                  </button>
                )}

                <div className={`${styles.sub_total} px-2`} style={{}}>
                  <div
                    className=" border-top"
                    style={{
                      marginTop: '150px',
                    }}
                  >
                    {/* <div className="d-flex justify-content-between">
                                            <p style={{
                                                fontWeight: '500',
                                                fontSize: '14px',
                                                lineHeight: ' 150%',
                                                color: '#1F2937',
                                                marginBottom: '0px',
                                            }}>
                                                Shipping fee:
                                            </p>
                                            <p style={{
                                                fontWeight: '400',
                                                fontSize: '15px',
                                                lineHeight: ' 150%',
                                                color: '##374151',
                                                marginBottom: '0px',
                                            }}>
                                                ₦ {purchaseData?.shipping_fee ? Number(purchaseData?.shipping_fee).toLocaleString() : 0}
                                            </p>

                                        </div> */}
                    <p
                      className="my-2"
                      style={{
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '150%',
                        color: '#4B5563',
                      }}
                    >
                      {purchaseData?.delivery_information?.address}
                    </p>
                  </div>
                  <div
                    className="pt-4 "
                    style={{ borderTop: '2px solid #FFFFFF' }}
                  >
                    <div className="d-flex justify-content-between">
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          lineHeight: ' 150%',
                          color: '#1F2937',
                        }}
                      >
                        Total
                      </p>
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: '20px',
                          lineHeight: ' 150%',
                          color: '##374151',
                        }}
                      >
                        ₦{' '}
                        {purchaseData?.subtotal
                          ? Number(purchaseData?.subtotal).toLocaleString()
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!Object.keys(purchaseData).length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
        </Card>
      </div>
    </>
  );
}
