// ----------------------------------------------------------------------
import styles from '../BrandOwner.module.css';
import { useState, createRef } from 'react';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import bus from 'src/bus';
import LiveLocation from 'src/pages/LiveLocation';
// sections
// mock
import GlobalModal from 'src/Modals/GlobalModal';
import CircularProgress from '@mui/material/CircularProgress';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useLayoutEffect } from 'react';
import { useAuth } from 'src/hooks/useAuth';
import { useEffect } from 'react';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import { BallTriangle } from 'react-loader-spinner';
import { useTagApi } from 'src/context/ProductTagApi';
import { number } from 'prop-types';
import { parseISO, sub } from 'date-fns';
import { InputFile } from '../../../components';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3,
};
const style1 = {
  position: 'absolute',
  width: '500px',
  overflow: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  background: '#FFFFFF',
  p: 3,
};
const style3 = {
  position: 'absolute',
  width: '700px',
  height: 'auto',
  overflow: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  background: '#FFFFFF',
  maxHeight: '95vh',
  p: 3,
};

// ptvp=PAGE TO VIEW (SALES)
export default function DiscountModal({
  openModal,
  closeModal,
  discountValue,
}) {
  let textInput = createRef();
  const [openGoToCart, setopenGoToCart] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isProdPending, setIsProdPending] = useState(false);
  const tagApi = useTagApi();
  const { data } = useAuth();
  const [startDate, setStartDate] = useState(null);
  const [startDate2, setStartDate2] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endDate2, setEndDate2] = useState(null);
  const [open1, setOpen1] = useState(false);
  const [summary, setSummary] = useState(false);
  const [requestSummary, setRequestSummary] = useState(false);
  const handleGoToCartClose = () => setopenGoToCart(false);
  const handleRequestSummary = () => {
    setRequestSummary(false);
    setIsPending(false);
  };
  const [isFocused1, setIsFocused1] = useState(false);
  const [discountTags, setDiscountTags] = useState([]);
  const [locationProcess, setProcessLocation] = useState('');
  const [customers, setCustomer] = useState('shoppers');
  const [customersAdded, setCustomerssAdded] = useState([]);
  const [productAdded, setProductAdded] = useState([]);
  const [audience, setSelectedAudience] = useState({});
  const [allP, setAllP] = useState([]);
  const [allPAdded, setAllPAdded] = useState([]);
  const authData = JSON.parse(localStorage.getItem('auth'));
  const [selectedProductIds, setSelectedProductIdss] = useState('');
  const [locationLat, setLocationLat] = useState('');
  const [locationLong, setLocationLong] = useState('');
  const [url, setUrl] = useState('');
  const [selectedProductId, setSelectedProductIds] = useState([]);
  // const [selectedLocationId, setSelectedLocationIds] = useState([])
  const [pushSelectedLocation, setPushSelectedLocationIds] = useState([]);
  // const [selectedCustomersId, setSelectedCustomersIds] = useState([])
  const [selectedProductName, setSelectedProductName] = useState([]);
  const [selectedLocationName, setSelectedLocationName] = useState([]);
  const [searchvalue, setSearchValue] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountPercentage2, setDiscountPercentage2] = useState(0);
  const [discountCheck, setDiscountCheck] = useState(false);
  const [discountP, setDiscountP] = useState(false);
  const [showAddNewPO, setShowAddNewPO] = useState(false);
  const notifyError = (err) => toast.error(err);
  const notifyToast = (msg) => toast.success(msg);
  const [checkAudience, setCheckAudience] = useState(false);
  const [discountDetails, setDiscountDetails] = useState({});
  const [isPOApprovalCode, setIsPOApprovalCode] = useState(false);
  const [handleNewPOModal, setHandleNewPOModal] = useState(false);
  const [enableProductEdit, setEnableProductEdit] = useState(false);
  const [isPOPending, setIsPOPending] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [originalFile, setOriginalFile] = useState(null);
  const [POCode, setPOCode] = useState('');
  const [POUploads, setPOUploads] = useState({});
  const [editModalDetails, setEditModalDetails] = useState(
    JSON.parse(localStorage.getItem('discount-modal'))
  );
  // const [createdProduct, setCreatedProduct] = useState({
  //   tag_id: []
  // })
  const [product, setProduct] = useState({});
  const [discount, setDiscount] = useState({
    discount_type: '',
    proposed_market_price: number,
    proposed_discount_budget: number,
    max_sales_quantity: number,
    discount_start_date: null,
    audience_start_date: null,
    discount_end_date: null,
    audience_end_date: null,
    price_per_carton: number,
    discount_product_quantity: number,
    discount_occurrence: number,
    product_image: '',
    product_name: '',
    product_spec: '',
    product_id: '',
    discount_id: '',
    bonus_product_id: '',
    location: '',
    productSelect: '',
    customers: '',
    po_approval_code: '',
    po_amount: '',
    po_document: '',
    min_price: number,
    max_price: number,
    min_order: number,
    max_order: number,
    min_unit: number,
    max_unit: number,
  });
  // Amount off
  const amountOffProduct = () => {
    setDiscountPercentage(0);
    setDiscountCheck(false);
    const discount_budget = Number(discount?.proposed_discount_budget);
    const proposed_market_price = Number(discount?.proposed_market_price);
    const price_per_carton = Number(product?.price_per_case?.$numberDecimal);
    const max_sales_quantity = Number(discount?.max_sales_quantity);

    if (customers === 'shoppers') {
      if (
        discount_budget < 1 ||
        proposed_market_price < 1 ||
        max_sales_quantity < 1
      )
        return;
      const calcPercent =
        (discount_budget / max_sales_quantity / proposed_market_price) * 100;

      const check = (calcPercent / 100) * proposed_market_price;
      if (check > proposed_market_price) {
        return setDiscountCheck(true);
      }
      setDiscountPercentage(Math.floor(calcPercent));
    }
    if (customers === 'shop_owners') {
      if (discount_budget < 1 || price_per_carton < 1 || max_sales_quantity < 1)
        return;
      const calcPercent =
        (discount_budget / max_sales_quantity / price_per_carton) * 100;

      const check = (calcPercent / 100) * price_per_carton;
      if (check > price_per_carton) {
        return setDiscountCheck(true);
      }
      console.log(calcPercent);
      setDiscountPercentage(Math.floor(calcPercent));
    }
  };

  // useEffect(() =>{
  //   console.log(discountDetails)
  // }, [discountDetails])

  useEffect(() => {
    amountOffProduct();
    calculateDiscountOccurrence();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    discount?.proposed_discount_budget,
    discount?.proposed_market_price,
    product?.price_per_case?.$numberDecimal,
    discount?.max_sales_quantity,
  ]);

  // Buy X get Y
  const calculateDiscountOccurrence = () => {
    setDiscountPercentage2(0);
    const discount_budget = discount?.proposed_discount_budget;
    const proposed_market_price = discount?.proposed_market_price;
    const price_per_carton = product?.price_per_case?.$numberDecimal;
    console.log(customers);

    if (customers === 'shoppers') {
      if (discount_budget < 1 || proposed_market_price < 1) return;
      const discount_occurrence = discount_budget / proposed_market_price;
      if (discount_occurrence < 1) return 0;
      if (Math.sign(discount_occurrence) === -1) return 0;
      console.log(discount_occurrence);

      setDiscountPercentage2(Math.floor(discount_occurrence));
    }
    if (customers === 'shop_owners') {
      if (discount_budget < 1 || price_per_carton < 1) return;
      const discount_occurrence = discount_budget / price_per_carton;
      if (discount_occurrence < 1) return 0;
      if (Math.sign(discount_occurrence) === -1) return 0;
      setDiscountPercentage2(Math.floor(discount_occurrence));
    }
  };

  // const [discountData, setDiscountData] = useState([
  //   {
  //     name: 'Amount Off More Than 1',
  //     number_of_product: 0,
  //     discount_percentage: 0,
  //     discount_duration: {
  //       time: 'Hours',
  //       count: '01'
  //     },
  //   },
  //   {
  //     name: 'Amount Off Product',
  //     discount_percentage: 0,
  //     discount_duration: {
  //       time: 'Hours',
  //       count: '01'
  //     },
  //   },
  //   {
  //     name: 'Buy X Get Y',
  //     products: [],
  //     no_of_product: '',
  //     discount_duration: {
  //       time: 'Hours',
  //       count: '01'
  //     },
  //   }
  // ])
  const [modalOpenC, setModalOpenC] = useState(false);
  const searchFilter = (e) => {
    setSearchValue(e.target.value);
  };

  const handleCloseC = () => {
    setModalOpenC(false);
    setCustomer('select');
    setPushSelectedLocationIds([]);
    setSelectedLocationName([]);
  };
  const handleClose1 = () => {
    // setopenGoToCart(true)
    localStorage.removeItem('discount-modal');
    setCheckAudience(false);
    delete style3.height;
    setOpen1(false);
    setModalOpenC(false);
    setCustomer('select');
    setPushSelectedLocationIds([]);
    setSelectedLocationName([]);
    setDiscountP(false);
    setAllPAdded([]);
    setAllP([]);
    setIsFocused1(false);
    setSearchValue('');
    bus.emit('refetch');
    setIsPOApprovalCode(false);
    setPreviewData(null);
    setDiscount({
      discount_type: '',
      proposed_market_price: 0,
      price_per_carton: 0,
      proposed_discount_budget: 0,
      max_sales_quantity: 0,
      startDate: null,
      endDate: null,
      startDate2: null,
      endDate2: null,
      discount_start_date: null,
      discount_end_date: null,
      discount_product_quantity: 0,
      discount_occurrence: 0,
      product_name: '',
      product_spec: '',
      product_id: '',
      product_image: '',
      bonus_product_id: '',
      location: '',
      po_approval_code: '',
      po_amount: '',
      po_document: '',
      productSelect: '',
      customers: '',
      min_price: 0,
      max_price: 0,
      min_order: 0,
      max_order: 0,
      min_unit: 0,
      max_unit: 0,
    });
  };

  const handleCustomerChange = (event) => {
    setCustomer(event.target.value);
    let data = customersAdded;
    let val = event.target.value;
    if (val != 1) {
      if (data.includes(val) == false) {
        data.push(val);
        setCustomerssAdded(data);
      }
    }
  };
  // const removeCustomerFromList = (index) => {
  //   let data = [...customersAdded]
  //   data = data.filter((_, i) => i !== index);
  //   if (data['length'] === 0) setCustomer(1)
  //   setCustomerssAdded(data)
  // }

  useEffect(() => {
    if (
      authData?.user_type === 'admin' ||
      authData?.merchant_type === 'brand'
    ) {
      fetchProducts();
    }
  }, [searchvalue]);

  const addedProductForDiscount = (el) => {
    setProduct(el);
    setDiscountP(true);
    setShowAddNewPO(true);
    style3.height = '650px';
  };

  const discountSubmit = (e) => {
    setIsPending(true);
    setModalOpenC(false);

    const startDate = new Date(discount.discount_start_date);
    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, '0');
    const day = String(startDate.getDate()).padStart(2, '0');
    const formattedStartDate = `${year}-${month}-${day}T00:00:00.000+00:00`;

    const endDate = new Date(discount.discount_end_date);
    const endyear = endDate.getFullYear();
    const endmonth = String(endDate.getMonth() + 1).padStart(2, '0');
    const endday = String(endDate.getDate()).padStart(2, '0');
    const formattedEndDate = `${endyear}-${endmonth}-${endday}T00:00:00.000+00:00`;

    let payload = {
      product_id: product?._id || discount?.product_id,
      product_name: discount?.product_name || product?.product_name,
      product_spec: discount?.product_spec || product?.product_spec,
      discount_type: discount?.discount_type,
      price: discount?.proposed_market_price,
      proposed_discount_budget: discount?.proposed_discount_budget,
      proposed_market_price: discount?.proposed_market_price,
      discount_start_date: formattedStartDate,
      discount_end_date: formattedEndDate,
      po_approval_code: discount?.po_approval_code,
      max_sales_quantity: discount?.max_sales_quantity,
    };

    const formData = new FormData();

    const brand_discount_audience = {
      customer_type: customers,
      location: {
        names: selectedLocationName,
        coordinates: pushSelectedLocation,
      },
    };

    if (discount?.discount_type === 'buy_x_get_y') {
      formData.append('bonus_product_id', product?._id || discount?.product_id);
      formData.append('discount_occurrence', discountPercentage2);
      formData.append(
        'no_of_products_bought',
        discount?.discount_product_quantity
      );
      payload.bonus_product_id = product?._id || discount?.product_id;
      payload.discount_occurrence = discountPercentage2;
      payload.no_of_products_bought = discount?.discount_product_quantity;
    }

    if (discount?.discount_type !== 'buy_x_get_y') {
      payload.discount_occurrence = discountPercentage;
      formData.append('discount_occurrence', discountPercentage);
    }

    if (discountTags?.length) {
      payload.tag_id = discountTags;
      formData.append('tag_id', discountTags);
    }

    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
    formData.append(
      'product_image',
      product?.product_image || discount?.product_image
    );
    payload.product_image = product?.product_image || discount?.product_image;
    if (authData?.user_type) {
      payload.selected_audience = audience;
      formData.append('selected_audience', JSON.stringify(audience));
    }

    if (!authData?.user_type) {
      payload.brand_discount_audience = brand_discount_audience;
      formData.append(
        'brand_discount_audience',
        JSON.stringify(brand_discount_audience)
      );
    }

    var endpoint =
      authData?.user_type === 'admin' && discount?.discount_id === ''
        ? '/admin/product/create-discount'
        : authData?.user_type === 'admin' && discount?.discount_id !== ''
        ? `/admin/product/update-discount/${discount?.discount_id}`
        : `/brand/discount/create/${product?._id}`;
    axiosInstance[
      authData?.user_type === 'admin' && discount?.discount_id !== ''
        ? 'put'
        : 'post'
    ](endpoint, product.hasOwnProperty('edit') ? formData : payload)
      .then((res) => {
        setModalOpenC(false);
        if (res.status < 300) {
          setDiscountDetails(res.data.data);
          notifyToast('Discount successfully added');
          setDiscountP(false);
          setOpen1(!open1);
          setRequestSummary(!requestSummary);
          setSelectedAudience({});
          setCustomer('select');
          setPushSelectedLocationIds([]);
          setSelectedLocationName([]);
          bus.emit('reload');
          setopenGoToCart(false);
          setProduct({});
          setCheckAudience(false);
          setAllP([]);
          localStorage.removeItem('discount-modal');
          setEnableProductEdit(false);
          setDiscount({
            discount_type: '',
            proposed_market_price: 0,
            proposed_discount_budget: 0,
            max_sales_quantity: 0,
            startDate: null,
            endDate: null,
            startDate2: null,
            endDate2: null,
            discount_start_date: null,
            discount_end_date: null,
            discount_product_quantity: 0,
            discount_occurrence: 0,
            bonus_product_id: '',
            location: '',
            productSelect: 'select',
            customers: 'select',
            po_approval_code: '',
            po_amount: '',
            po_document: '',
            min_price: 0,
            max_price: 0,
            min_order: 0,
            max_order: 0,
            min_unit: 0,
            max_unit: 0,
          });
          setIsPOApprovalCode(false);
          setPreviewData(null);

          setOpen1(!open1);
          setOpen1(false);
          setOpen1(false);
          setOpen1(false);
          setOpen1(false);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to save, try again later');
        }
        // setDiscountP(product);
        // setSummary(false);
      })
      .finally(() => setIsPending(false));
  };

  const handleProceed = () => {
    bus.off('open-discount', () => {
      setModalOpenC(false);
      setEnableProductEdit(false);
    });

    if (data.user_type !== 'admin' && !customersAdded.length)
      return notifyError('Customer is required ');

    setModalOpenC(false);
    setEnableProductEdit(false);
    setOpen1(true);
    // console.log(discountTags)
    // setCheckAudience(true)
    setSelectedAudience({
      customer_type: customers,
      location: {
        // names: selectedLocationName,
        coordinates: pushSelectedLocation,
      },
      latitude: locationLat,
      longitude: locationLong,
    });
  };

  const [fetchAllProduct, setFetchAllProduct] = useState([]);
  fetchAllProduct.sort((a, b) => a.product_name?.localeCompare(b.product_name));

  const fetchCategories = () => {
    axiosInstance
      .get('/admin/product')
      .then((res) => {
        if (res.status < 300) {
          setFetchAllProduct(res.data.data);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.message);
        } catch {
          notifyError('Something went wrong');
        }
      });
  };

  const handleProductSelect = (selectedProduct) => {
    const isSelected = selectedProductId.includes(
      selectedProduct._id.toString()
    );
    const isSelected2 = selectedProductName.includes(
      selectedProduct.product_name.toString()
    );

    const selectedCategory = selectedProduct.product_name;
    const selectedCategoryId = selectedProduct._id;
    if (isSelected) {
      notifyError('Product already exist');
    } else {
      // selectedProductIds.push(selectedProduct._id.toString());
      setSelectedProductIdss(selectedCategoryId);
      setSelectedProductIds((prevSelectedCategories) => [
        ...prevSelectedCategories,
        selectedCategoryId,
      ]);
    }

    if (!isSelected2) {
      // selectedProductNames.push(selectedProduct.product_name.toString());
      setSelectedProductName((prevSelectedCategories) => [
        ...prevSelectedCategories,
        selectedCategory,
      ]);
    }
  };

  useEffect(() => {
    if (authData.user_type) {
      fetchCategories();
    }
  }, []);

  // const discountProcess = (e) => {
  //   e.preventDefault();
  //   setIsGoing(true)
  //   if (discount.discount_type === 'emt') {
  //     return notifyError("Please select discount type ");
  //   }
  //   axiosInstance.put('/admin/product/' + discount?.edit?.discount_id?.product_id, {
  //     tag_id: discount.edit.tag_id,
  //     product_category: discount.edit.product_category._id
  //   })
  //     .then(res => {
  //       // console.log(res);
  //       setOpen1(false);
  //       setSummary(true);
  //     }).catch(err => {

  //       try {
  //         notifyError(err.response.data.message);

  //       } catch (error) {
  //         notifyError("Unable to save, try again later");

  //       }
  //       // setOpen1(true);
  //       // setDiscountP(product);
  //       // setSummary(false);

  //     }).finally(() => setIsGoing(false))
  //   // delete style3.height
  //   // setOpen1(false);

  // }

  const handleTagChange = (data) => {
    let tag_datas = [...discountTags];

    if (data.checked) {
      // If the value is not already in the array, add it
      if (!tag_datas.includes(data.value)) {
        tag_datas.push(data.value);
      }
    } else {
      // Remove the value from the array if it exists
      const index = tag_datas.indexOf(data.value);
      if (index !== -1) {
        tag_datas.splice(index, 1);
      }
    }
    setDiscountTags(tag_datas);
  };

  const fetchProducts = (search = searchvalue) => {
    // if (!search) return;
    setIsProdPending(true);
    setAllP([]);
    var endpoint = '';
    if (authData.user_type === 'admin') {
      endpoint = '/admin/product';
    }
    if (authData.merchant_type === 'brand') {
      endpoint = '/brand/product/get-all';
    }
    axiosInstance
      .get(endpoint, {
        params: {
          search,
        },
      })
      .then((res) => {
        if (res.status < 300) {
          setAllP(res.data.data);
        }
      })
      .finally(() => setIsProdPending(false));
  };

  useEffect(() => {
    setDiscount((prev) => {
      return {
        ...prev,
        discount_start_date: startDate,
        audience_start_date: startDate2,
        discount_end_date: endDate,
        audience_end_date: endDate2,
      };
    });
  }, [startDate, endDate]);

  useEffect(() => {
    if (openModal) {
      const val = discountValue;
      setDiscountP(true);
      setShowAddNewPO(false);
      setProduct(val);
      setModalOpenC(true);
      setEnableProductEdit(false);
      if (val.hasOwnProperty('edit')) {
        const start = parseISO(val?.edit?.discount_id?.discount_start_date);
        const end = parseISO(val?.edit?.discount_id?.discount_end_date);

        // Subtract one day from the parsed start and end dates
        // const modifiedStartDate = start ? sub(start, { days: 1 }) : null;
        // const modifiedEndDate = end ? sub(end, { days: 1 }) : null;

        setStartDate(start);
        setEndDate(end);
        setSelectedLocationName((prevSelectedLocations) => [
          ...prevSelectedLocations,
          val?.edit?.discount_id?.selected_audience?.location?.names[0],
        ]);
        setCustomer(val?.edit?.discount_id?.selected_audience?.customer_type);
        setUrl(val?.edit?.product_image);
        setDiscount({
          discount_type: val?.edit?.discount_id?.discount_type,
          proposed_market_price:
            val?.edit?.discount_id?.proposed_market_price?.$numberDecimal,
          price_per_carton:
            val?.edit?.discount_id?.price_per_carton?.$numberDecimal,
          proposed_discount_budget:
            val?.edit?.discount_id?.proposed_discount_budget?.$numberDecimal,
          max_sales_quantity: val?.edit?.discount_id?.max_sales_quantity,
          startDate: startDate,
          endDate: endDate,
          startDate2: null,
          endDate2: null,
          discount_start_date: startDate,
          discount_end_date: endDate,
          discount_product_quantity:
            val?.edit?.discount_id?.discount_product_quantity,
          discount_occurrence: val?.edit?.discount_id?.discount_occurrence,
          bonus_product_id: val?.edit?.discount_id?.bonus_product_id,
          product_image: val?.edit?.product_image,
          product_name: val?.edit?.product_name,
          product_spec: val?.edit?.product_spec,
          product_id: val?.edit?._id,
          discount_id: val?.edit?.discount_id._id,
          location: '',
          po_approval_code: '',
          po_amount: '',
          po_document: '',
          productSelect: '',
          customers: customers,
          min_price: 0,
          max_price: 0,
          min_order: 0,
          max_order: 0,
          min_unit: 0,
          max_unit: 0,
        });
      }
    }
    // setModalOpenC(!modalOpenC)
    bus.on('open-discount', (val) => {
      setModalOpenC(true);
      if (val) {
        setDiscountP(true);
        setShowAddNewPO(false);
        setProduct(val);
        if (!val.hasOwnProperty('edit')) {
          setDiscount(val);
        }
        if (val.hasOwnProperty('edit')) {
          console.log(val.edit);
          const start = parseISO(val?.edit?.discount_id?.discount_start_date);
          const end = parseISO(val?.edit?.discount_id?.discount_end_date);

          // Subtract one day from the parsed start and end dates
          // const modifiedStartDate = start ? sub(start, { days: 1 }) : null;
          // const modifiedEndDate = end ? sub(end, { days: 1 }) : null;

          setStartDate(start);
          setEndDate(end);
          setSelectedLocationName((prevSelectedLocations) => [
            ...prevSelectedLocations,
            val?.edit?.discount_id?.selected_audience?.location?.names[0],
          ]);
          setCustomer(val?.edit?.discount_id?.selected_audience?.customer_type);
          setDiscount({
            discount_type: val?.edit?.discount_id?.discount_type,
            proposed_market_price:
              val?.edit?.discount_id?.proposed_market_price?.$numberDecimal,
            price_per_carton:
              val?.edit?.discount_id?.price_per_carton?.$numberDecimal,
            proposed_discount_budget:
              val?.edit?.discount_id?.proposed_discount_budget?.$numberDecimal,
            max_sales_quantity: val?.edit?.discount_id?.max_sales_quantity,
            startDate: startDate,
            endDate: endDate,
            startDate2: null,
            endDate2: null,
            discount_start_date: startDate,
            discount_end_date: endDate,
            discount_product_quantity:
              val?.edit?.discount_id?.discount_product_quantity,
            discount_occurrence: val?.edit?.discount_id?.discount_occurrence,
            bonus_product_id: val?.edit?.discount_id?.bonus_product_id,
            product_image: val?.edit?.product_image,
            product_name: val?.edit?.product_name,
            product_spec: val?.edit?.product_spec,
            product_id: val?.edit?._id,
            discount_id: val?.edit?.discount_id._id,
            location: '',
            po_approval_code: '',
            po_amount: '',
            po_document: '',
            productSelect: '',
            customers: customers,
            min_price: 0,
            max_price: 0,
            min_order: 0,
            max_order: 0,
            min_unit: 0,
            max_unit: 0,
          });
        }
      }
    });

    bus.on('open-discount-modal', (val) => {
      setOpen1(true);
      setDiscount(val);
      if (val) {
        setDiscountP(true);
        setShowAddNewPO(false);
      }
    });
  }, [openModal]);

  const handleCreateDiscount = (e) => {
    e.preventDefault();
    setModalOpenC(false);
    const checkDiscountPercentage =
      discount?.discount_type === 'buy_x_get_y'
        ? discountPercentage2
        : discountPercentage;
    // if (discountPercentage2 > 0) {
    if (checkDiscountPercentage > 0) {
      setRequestSummary(true);
      delete style3.height;
    } else {
      notifyError(
        'Discount should not be less than Proposed Market Price. Discount Percentage has to be greater than 0'
      );
    }
  };

  const handleDiscountChange = (key, value) => {
    setDiscount((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });

    let data = productAdded;
    let val = value;
    if (val != 1) {
      if (data.includes(val) == false) {
        data.push(val);
        setProductAdded(data);
      }
    }
  };

  const processLocation = (e) => {
    bus.emit('location');
    setProcessLocation(e.value);

    const selectedLocation = e.formatted_address;
    const selectedLocationId1 = e.lng;
    const selectedLocationId2 = e.lat;

    setLocationLong(selectedLocationId1);
    setLocationLat(selectedLocationId2);
    // selectedLocationNames.push(e.formatted_address.toString());
    setSelectedLocationName((prevSelectedLocations) => [
      ...prevSelectedLocations,
      selectedLocation,
    ]);

    setPushSelectedLocationIds((prevSelectedLocations) => [
      ...prevSelectedLocations,
      [selectedLocationId1, selectedLocationId2],
    ]);
  };

  useEffect(() => {
    console.log(pushSelectedLocation);
  }, [pushSelectedLocation]);

  const handleNewPO = () => {
    setHandleNewPOModal(!handleNewPOModal);
    setOpen1(!open1);
  };

  const handlePOApprovalCode = () => {
    setIsPOPending(true);

    const payload = {
      po_amount: POUploads?.po_amount,
      brand_id: product?.edit?.brand_id,
      product_id: product?.edit?._id,
    };

    const byteCharacters = atob(previewData.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' }); // Adjust type as needed

    // Create a File object from the Blob
    const file = new File([blob], 'image.jpg', { type: 'image/jpeg' }); // Adjust filename and type as needed

    const formData = new FormData();
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
    formData.append('po_document', file);
    axiosInstance
      .post('/admin/product/upload-po', formData)
      .then((res) => {
        if (res.status < 300) {
          handleNewPO();
          notifyToast('Check mail for P.O Approval Code');
          setIsPOApprovalCode(true);
          setIsPOPending(false);
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
      })
      .finally(() => setIsPOPending(false));
  };

  // const handleFile = (e) => {
  //   if (e.target.files[0]) {
  //     setUrl(URL.createObjectURL(e.target.files[0]))
  //     setUrlP(e.target.files[0])
  //   }
  // }

  const handleFileUpload = (file) => {
    if (file) {
      setOriginalFile(file);
      setPOUploads((prev) => {
        return {
          ...prev,
          po_document: URL.createObjectURL(file),
        };
      });
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewData(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handlePOUploads = (key, value) => {
    console.log(key, value);
    setPOUploads((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  useLayoutEffect(
    () => () => {
      // Your code here.
    },
    []
  );

  const handleEnableProductEdit = (val) => {
    setEnableProductEdit(!enableProductEdit);
    setUrl(val);
  };

  const handleFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      // Create a FileReader object to read the image file
      const reader = new FileReader();

      // Set up a callback function to be executed when the FileReader has finished reading the file
      reader.onload = (event) => {
        const image = new Image();

        // Set up a callback function to be executed when the image has loaded
        image.onload = () => {
          setUrl(URL.createObjectURL(file));
          handleDiscountChange('product_image', file);
        };

        // Set the image source to the data URL obtained from the FileReader
        image.src = event.target.result;
      };

      // Read the image file as a data URL
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    textInput.current.click();
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        onClose={handleClose1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open1}>
          <Box className={`mobile_modal_size`} sx={style3}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3>Create Discount </h3>
                <span onClick={handleClose1} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>

              <div className={`${styles.modal_header}`}>
                <p>Add Promo to Products</p>
              </div>
              <form onSubmit={handleCreateDiscount}>
                {!discountP && (
                  <div className="mb-3">
                    <label
                      className={`${styles.modal_product_title} pl-0`}
                      htmlFor="pname"
                    >
                      Search Product
                    </label>
                    <div
                      className={`${styles.hold_input} col-md-12 pl-0 mb-2  `}
                    >
                      <input
                        onFocus={() => setIsFocused1(true)}
                        value={searchvalue}
                        onChange={searchFilter}
                        type="text"
                        placeholder="Search"
                        style={{
                          textIndent: '25px',
                          width: '100%',
                        }}
                        className="form-control"
                      />
                    </div>
                    <div className={`${styles.product_list} card px-0 `}>
                      <div className="card-body">
                        {!isProdPending &&
                          allP.length > 0 &&
                          allP
                            .filter((el) => !el.is_deleted)
                            .slice(0, 10)
                            .map((el, i) => {
                              return (
                                <div
                                  key={i}
                                  className={`d-flex justify-content-between`}
                                >
                                  <div
                                    className="mt-2"
                                    style={{ width: '50%' }}
                                  >
                                    <div
                                      className={`d-flex ${styles.review_contents}`}
                                    >
                                      <div>
                                        <img
                                          style={{
                                            width: '51px',
                                          }}
                                          src={el?.product_image}
                                          alt=""
                                        />
                                      </div>
                                      &nbsp;
                                      <div style={{}}>
                                        <p
                                          style={{
                                            cursor: 'pointer',
                                            display: 'inline-block',
                                            marginBottom: '5px',
                                          }}
                                        >
                                          <strong>{el?.product_name}</strong>
                                        </p>
                                        <p
                                          style={{
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '150%',
                                            color: '#6B7280',
                                          }}
                                        >
                                          {el?.product_spec}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flsex align-items-center">
                                    {!el?.is_approved && (
                                      <button
                                        type="button"
                                        onClick={() =>
                                          addedProductForDiscount(el)
                                        }
                                        className="btn"
                                      >
                                        <img
                                          src="/assets/icons/plus-square1.svg"
                                          alt=""
                                        />
                                        &nbsp; Create Discount
                                      </button>
                                    )}
                                    {el?.is_approved && (
                                      <button
                                        type="button"
                                        className="btn"
                                        disabled
                                      >
                                        <img
                                          src="/assets/icons/plus-square1.svg"
                                          alt=""
                                        />
                                        &nbsp; Ongoing Discount
                                      </button>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        <div className="d-flex justify-content-center">
                          {isProdPending && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                        {isFocused1 && !allP.length && !isProdPending && (
                          <div className=" text-center">
                            <h5>No record found</h5>
                          </div>
                        )}
                        {!isFocused1 && !allP.length && !isProdPending && (
                          <div className=" text-center">
                            <h5>
                              What product are you creating a discount for ?
                            </h5>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* {discountP && showAddNewPO && ( */}
                <>
                  {/* <div className="d-flex">
                    <button
                      className={`btn text-white btn-[#af3585] bg-[#8c2d915d] d-block ml-auto`}
                      type="button"
                      onClick={handleNewPO}
                      style={{ background: '#af3585' }}
                    >
                      <img
                        src="/assets/icons/plus.svg"
                        className="pr-1"
                        alt=""
                      />
                      Add New P.O
                    </button>
                  </div> */}
                </>
                {/* )} */}

                {discountP && !product.hasOwnProperty('edit') && (
                  <div>
                    <p>Product</p>
                    <div className="card mb-3">
                      <div className="card-body py-1">
                        <div className={`d-flex justify-content-between`}>
                          <div className=" " style={{ width: '50%' }}>
                            <div className={`d-flex`}>
                              {!product.hasOwnProperty('edit') && (
                                <div className="d-flex">
                                  <div>
                                    <img
                                      style={{
                                        width: '51px',
                                      }}
                                      src={product?.product_image}
                                      alt=""
                                    />
                                  </div>
                                  &nbsp;
                                  <div style={{}}>
                                    <p
                                      style={{
                                        cursor: 'pointer',
                                        display: 'inline-block',
                                        marginBottom: '5px',
                                      }}
                                    >
                                      <strong>
                                        {product?.product_name ||
                                          product?.product_name}
                                      </strong>
                                    </p>
                                    <p>{product?.product_spec}</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(customers === 'shoppers' ||
                      discount?.discount_type === 'shoppers') && (
                      <div className="mb-3">
                        <label
                          className={`${styles.modal_product_title}`}
                          htmlFor="price"
                        >
                          Proposed Market Price*
                        </label>
                        <TextField
                          required
                          id="price"
                          name="proposed_market_price"
                          value={discount?.proposed_market_price}
                          type="number"
                          placeholder=" ₦"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              inputValue === '' ||
                              parseFloat(inputValue) >= 0
                            ) {
                              handleDiscountChange(
                                'proposed_market_price',
                                inputValue
                              );
                            }
                          }}
                          inputProps={{
                            inputMode: 'decimal',
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === '-' ||
                              e.key === 'e' ||
                              e.key === '.'
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    )}
                    <div className="mb-3">
                      <label
                        className={`${styles.modal_product_title}`}
                        htmlFor="discount"
                      >
                        Discount Type
                      </label>
                      <Select
                        labelId="s"
                        id="s"
                        fullWidth
                        size="small"
                        className="mb-3"
                        name="discount_type"
                        value={discount?.discount_type}
                        required
                        onChange={(e) =>
                          handleDiscountChange('discount_type', e.target.value)
                        }
                      >
                        <MenuItem value={''}>Select Type</MenuItem>
                        <MenuItem value={'amount_off_product'}>
                          Amount Off Product
                        </MenuItem>
                        <MenuItem value={'buy_x_get_y'}>Buy X Get Y</MenuItem>
                      </Select>
                      {discount?.discount_type === 'amount_off_product' && (
                        <div className="card">
                          <div className="card-header bg-white">
                            A discount on a particular product
                          </div>
                          <div className="card-body">
                            <div className="mb-1">
                              <div className="d-md-flex justify-content-between">
                                <label
                                  className={` pt-2`}
                                  htmlFor="discountPrice"
                                >
                                  Duration of Discount Price
                                </label>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <div className="mb-3 d-flex">
                                  <div className="" style={{ width: '45%' }}>
                                    <div className="mb-3">
                                      <DatePicker
                                        showIcon
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control"
                                        placeholderText="Start"
                                        minDate={new Date()}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="px-1 d-flex   align-items-center "
                                    style={{ height: '40px' }}
                                  >
                                    <img
                                      src="/assets/icons/line.svg"
                                      style={{ width: '30px' }}
                                      alt=""
                                    />
                                  </div>
                                  <div className="" style={{ width: '45%' }}>
                                    <div className="mb-3">
                                      <DatePicker
                                        showIcon
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control"
                                        placeholderText="End"
                                        minDate={new Date()}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mb-3">
                              <label
                                className={`${styles.modal_product_title}`}
                                htmlFor="dprice"
                              >
                                Proposed Discount Budget*{' '}
                              </label>
                              <TextField
                                id="dprice"
                                type="number"
                                name="proposed_discount_budget"
                                value={discount?.proposed_discount_budget}
                                placeholder=" Input amount"
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    inputValue === '' ||
                                    parseFloat(inputValue) >= 0
                                  ) {
                                    handleDiscountChange(
                                      'proposed_discount_budget',
                                      inputValue
                                    );
                                  }
                                }}
                                inputProps={{
                                  inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === '-' ||
                                    e.key === 'e' ||
                                    e.key === '.'
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                className={`${styles.modal_product_title}`}
                                htmlFor="mq"
                              >
                                Maximum Sales Quantity*{' '}
                              </label>
                              <TextField
                                id="mq"
                                type="number"
                                placeholder=""
                                name="max_sales_quantity"
                                value={discount?.max_sales_quantity}
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    inputValue === '' ||
                                    parseFloat(inputValue) >= 0
                                  ) {
                                    handleDiscountChange(
                                      'max_sales_quantity',
                                      inputValue
                                    );
                                  }
                                }}
                                inputProps={{
                                  inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === '-' ||
                                    e.key === 'e' ||
                                    e.key === '.'
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {discountCheck && (
                                <div className="alert alert-danger mt-2">
                                  <small
                                    className="small"
                                    style={{ fontWeight: 'lighter ' }}
                                  >
                                    Note: Discount should not be greater than
                                    market price
                                  </small>
                                </div>
                              )}
                            </div>
                            <div className="mb-3 d-flex justify-content-between">
                              <div>
                                <p
                                  className="mb-0 text-[#af3585] font-weight-bold"
                                  style={{ fontSize: '14px' }}
                                >
                                  Discount percentages
                                </p>
                                <small className="text-danger small">
                                  This percentage is subject to change based on
                                  the market research undertaken
                                </small>
                              </div>
                              <span className="text-[#af3585]">
                                {discountPercentage ? discountPercentage : 0}%
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {discount?.discount_type === 'buy_x_get_y' && (
                        <div className="card">
                          <div className="card-header bg-white">
                            A promo just like buy one get one free
                          </div>
                          <div className="card-body shadow-none px-3">
                            <div className="mb-3">
                              <label className={``} htmlFor="np">
                                Number of Product
                              </label>
                              <TextField
                                id="np"
                                type="number"
                                placeholder="0"
                                name="discount_product_quantity"
                                value={discount?.discount_product_quantity}
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    inputValue === '' ||
                                    parseFloat(inputValue) >= 0
                                  ) {
                                    handleDiscountChange(
                                      'discount_product_quantity',
                                      inputValue
                                    );
                                  }
                                }}
                                inputProps={{
                                  inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === '-' ||
                                    e.key === 'e' ||
                                    e.key === '.'
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                            <div className={`${styles.bonus_product}`}>
                              Bonus Product
                              <div className="card mb-3">
                                <div className="card-body py-1">
                                  <div
                                    className={`d-flex justify-content-between`}
                                  >
                                    <div className=" " style={{ width: '50%' }}>
                                      <div
                                        className={`d-flex ${styles.review_contents}`}
                                      >
                                        <div>
                                          <img
                                            style={{
                                              width: '51px',
                                            }}
                                            src={product?.product_image}
                                            alt=""
                                          />
                                        </div>
                                        &nbsp;
                                        <div style={{}}>
                                          <p
                                            style={{
                                              cursor: 'pointer',
                                              display: 'inline-block',
                                              marginBottom: '5px',
                                            }}
                                          >
                                            <strong>
                                              {product?.product_name}
                                            </strong>
                                          </p>
                                          <p>{product?.product_spec}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-md-flex justify-content-between mt-2">
                              <label
                                className={` pt-2`}
                                htmlFor="discountPrice"
                              >
                                Duration of Discount Price
                              </label>
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              <div className="mb-3 d-flex">
                                <div className="" style={{ width: '45%' }}>
                                  <div className="mb-3">
                                    <DatePicker
                                      showIcon
                                      selected={startDate}
                                      onChange={(date) => setStartDate(date)}
                                      dateFormat="dd/MM/yyyy"
                                      className="form-control"
                                      placeholderText="Start"
                                      required
                                      minDate={new Date()}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="px-1 d-flex align-items-center "
                                  style={{ height: '40px' }}
                                >
                                  <img
                                    src="/assets/icons/line.svg"
                                    style={{ width: '30px' }}
                                    alt=""
                                  />
                                </div>
                                <div className="" style={{ width: '45%' }}>
                                  <div className="mb-3">
                                    <DatePicker
                                      showIcon
                                      selected={endDate}
                                      onChange={(date) => setEndDate(date)}
                                      dateFormat="dd/MM/yyyy"
                                      className="form-control"
                                      placeholderText="End"
                                      required
                                      minDate={new Date()}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mb-3">
                              <label className={``} htmlFor="pdb">
                                Proposed Discount Budget*
                              </label>
                              <TextField
                                id="pdb"
                                type="number"
                                placeholder="Input Amount"
                                name="proposed_discount_budget"
                                value={discount?.proposed_discount_budget}
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    inputValue === '' ||
                                    parseFloat(inputValue) >= 0
                                  ) {
                                    handleDiscountChange(
                                      'proposed_discount_budget',
                                      inputValue
                                    );
                                  }
                                }}
                                inputProps={{
                                  inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === '-' ||
                                    e.key === 'e' ||
                                    e.key === '.'
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                            <div className="mb-3 d-flex justify-content-between">
                              <div>
                                <p
                                  className="mb-0 text-[#af3585] font-weight-bold"
                                  style={{ fontSize: '14px' }}
                                >
                                  Discount Occurrence
                                </p>
                                <small className="text-danger small">
                                  This percentage is subject to change based on
                                  the market research undertaken
                                </small>
                              </div>
                              <span className="text-[#af3585]">
                                {discountPercentage2 ? discountPercentage2 : 0}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {showAddNewPO && isPOApprovalCode && (
                      <div className="mb-3">
                        <label
                          className={`${styles.modal_product_title}`}
                          htmlFor="price"
                        >
                          P.O Approval Code *
                        </label>
                        <TextField
                          required
                          id="price"
                          type="text"
                          name="po_approval_code"
                          value={discount?.po_approval_code}
                          size="small"
                          fullWidth
                          onChange={(e) =>
                            handleDiscountChange(
                              'po_approval_code',
                              e.target.value
                            )
                          }
                        />
                      </div>
                    )}
                    {!showAddNewPO && (
                      <div className="mb-3">
                        <label
                          className={`${styles.modal_product_title}`}
                          htmlFor="price"
                        >
                          P.O Approval Code *
                        </label>
                        <TextField
                          required
                          id="price"
                          type="text"
                          name="po_approval_code"
                          value={discount?.po_approval_code}
                          size="small"
                          fullWidth
                          onChange={(e) =>
                            handleDiscountChange(
                              'po_approval_code',
                              e.target.value
                            )
                          }
                        />
                      </div>
                    )}
                    <div className="mb-3">
                      <button
                        type="submit"
                        className={`modal_btn btn btn-block`}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
                {discountP && product.hasOwnProperty('edit') && (
                  <div>
                    <div className="d-flex">
                      <p>Product</p>
                      <p
                        className="ml-auto mt-3 text-primary"
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          handleEnableProductEdit(discount?.product_image)
                        }
                      >
                        {!enableProductEdit
                          ? 'Click to edit product'
                          : 'reverse changes'}
                      </p>
                    </div>
                    {!enableProductEdit && (
                      <div className="card mb-3">
                        <div className="card-body py-1">
                          <div className={`d-flex justify-content-between`}>
                            <div className=" " style={{ width: '50%' }}>
                              <div className={`d-flex`}>
                                <div className="d-flex">
                                  <div>
                                    <img
                                      style={{
                                        width: '51px',
                                      }}
                                      src={product?.edit?.product_image}
                                      alt=""
                                    />
                                  </div>
                                  &nbsp;
                                  <div style={{}}>
                                    <p
                                      style={{
                                        cursor: 'pointer',
                                        display: 'inline-block',
                                        marginBottom: '5px',
                                      }}
                                    >
                                      <strong>{discount?.product_name}</strong>
                                    </p>
                                    <p>{discount?.product_spec}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {enableProductEdit && (
                      <>
                        <input
                          type="file"
                          hidden
                          ref={textInput}
                          name=""
                          onChange={handleFile}
                        />
                        <div
                          className={`${styles.retailer_modal_product_img}`}
                          style={{
                            backgroundImage: `url('${url}')`,
                            backgroundSize: 'cover',
                          }}
                        >
                          {
                            // !url && (
                            <div
                              style={{
                                cursor: 'pointer',
                                display: 'inline-block',
                              }}
                              onClick={() => handleButtonClick()}
                            >
                              <img
                                className={`${styles.retailer_product_image}`}
                                src="/assets/image.svg"
                                alt=""
                              />
                              <p
                                className="mb-0 text-center text-white bg-primary p-1"
                                style={{ fontSize: '10px' }}
                              >
                                Click to upload
                              </p>
                            </div>
                            // )
                          }
                        </div>
                        <div className="mb-3">
                          <label className={`label`} htmlFor="pname">
                            Product Name*
                          </label>
                          <TextField
                            required
                            type="text"
                            id="pname"
                            value={discount?.product_name}
                            onChange={(e) =>
                              handleDiscountChange(
                                'product_name',
                                e.target.value
                              )
                            }
                            placeholder=""
                            size="small"
                            fullWidth
                          />
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="mb-3">
                              <label className={`label`} htmlFor="spec">
                                Product Specification( g, ml, kg )
                              </label>
                              <TextField
                                type="text"
                                id="spec"
                                value={discount?.product_spec}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    inputValue === '' ||
                                    parseFloat(inputValue) > 0
                                  ) {
                                    handleDiscountChange(
                                      'product_spec',
                                      inputValue
                                    );
                                  }
                                }}
                                inputProps={{
                                  inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="0"
                                size="small"
                                fullWidth
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {(customers === 'shoppers' ||
                      discount?.discount_type === 'shoppers') && (
                      <div className="mb-3">
                        <label
                          className={`${styles.modal_product_title}`}
                          htmlFor="price"
                        >
                          Proposed Market Price*
                        </label>
                        <TextField
                          required
                          disabled
                          id="price"
                          name="proposed_market_price"
                          value={discount?.proposed_market_price}
                          type="number"
                          placeholder=" ₦"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              inputValue === '' ||
                              parseFloat(inputValue) > 0
                            ) {
                              handleDiscountChange(
                                'proposed_market_price',
                                inputValue
                              );
                            }
                          }}
                          inputProps={{
                            inputMode: 'decimal',
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === '-' ||
                              e.key === 'e' ||
                              e.key === '.'
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    )}
                    <div className="mb-3">
                      <label
                        className={`${styles.modal_product_title}`}
                        htmlFor="discount"
                      >
                        Discount Type
                      </label>
                      <Select
                        labelId="s"
                        id="s"
                        disabled
                        fullWidth
                        size="small"
                        className="mb-3"
                        name="discount_type"
                        value={discount?.discount_type}
                        required
                        onChange={(e) =>
                          handleDiscountChange('discount_type', e.target.value)
                        }
                      >
                        <MenuItem value={''}>Select Type</MenuItem>
                        <MenuItem value={'amount_off_product'}>
                          Amount Off Product
                        </MenuItem>
                        <MenuItem value={'buy_x_get_y'}>Buy X Get Y</MenuItem>
                      </Select>
                      {discount?.discount_type === 'amount_off_product' && (
                        <div className="card">
                          <div className="card-header bg-white">
                            A discount on a particular product
                          </div>
                          <div className="card-body">
                            <div className="mb-1">
                              <div className="d-md-flex justify-content-between">
                                <label
                                  className={` pt-2`}
                                  htmlFor="discountPrice"
                                >
                                  Duration of Discount Price
                                </label>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <div className="mb-3 d-flex">
                                  <div className="" style={{ width: '45%' }}>
                                    <div className="mb-3">
                                      <DatePicker
                                        showIcon
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control"
                                        placeholderText="Start"
                                        minDate={new Date()}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="px-1 d-flex   align-items-center "
                                    style={{ height: '40px' }}
                                  >
                                    <img
                                      src="/assets/icons/line.svg"
                                      style={{ width: '30px' }}
                                      alt=""
                                    />
                                  </div>
                                  <div className="" style={{ width: '45%' }}>
                                    <div className="mb-3">
                                      <DatePicker
                                        showIcon
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control"
                                        placeholderText="End"
                                        minDate={new Date()}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mb-3">
                              <label
                                className={`${styles.modal_product_title}`}
                                htmlFor="dprice"
                              >
                                Proposed Discount Budget*{' '}
                              </label>
                              <TextField
                                id="dprice"
                                type="number"
                                name="proposed_discount_budget"
                                value={discount?.proposed_discount_budget}
                                placeholder=" Input amount"
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    inputValue === '' ||
                                    parseFloat(inputValue) >= 0
                                  ) {
                                    handleDiscountChange(
                                      'proposed_discount_budget',
                                      inputValue
                                    );
                                  }
                                }}
                                inputProps={{
                                  inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === '-' ||
                                    e.key === 'e' ||
                                    e.key === '.'
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                className={`${styles.modal_product_title}`}
                                htmlFor="mq"
                              >
                                Maximum Sales Quantity*{' '}
                              </label>
                              <TextField
                                id="mq"
                                type="number"
                                placeholder=""
                                name="max_sales_quantity"
                                value={discount?.max_sales_quantity}
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    inputValue === '' ||
                                    parseFloat(inputValue) >= 0
                                  ) {
                                    handleDiscountChange(
                                      'max_sales_quantity',
                                      inputValue
                                    );
                                  }
                                }}
                                inputProps={{
                                  inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === '-' ||
                                    e.key === 'e' ||
                                    e.key === '.'
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {discountCheck && (
                                <div className="alert alert-danger mt-2">
                                  <small
                                    className="small"
                                    style={{ fontWeight: 'lighter ' }}
                                  >
                                    Note: Discount should not be greater than
                                    market price
                                  </small>
                                </div>
                              )}
                            </div>
                            <div className="mb-3 d-flex justify-content-between">
                              <div>
                                <p
                                  className="mb-0 text-[#af3585] font-weight-bold"
                                  style={{ fontSize: '14px' }}
                                >
                                  Discount percentages
                                </p>
                                <small className="text-danger small">
                                  This percentage is subject to change based on
                                  the market research undertaken
                                </small>
                              </div>
                              <span className="text-[#af3585]">
                                {discountPercentage ? discountPercentage : 0}%
                              </span>
                            </div>
                            {isPOApprovalCode && (
                              <div className="mb-3">
                                <label
                                  className={`${styles.modal_product_title}`}
                                  htmlFor="po_code"
                                >
                                  Enter P.O Code*
                                </label>
                                <TextField
                                  id="po_approval_code"
                                  type="text"
                                  name="po_approval_code"
                                  value={discount?.po_approval_code}
                                  size="small"
                                  fullWidth
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    handleDiscountChange(
                                      'po_approval_code',
                                      inputValue
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {discount?.discount_type === 'buy_x_get_y' && (
                        <div className="card">
                          <div className="card-header bg-white">
                            A promo just like buy one get one free
                          </div>
                          <div className="card-body shadow-none px-3">
                            <div className="mb-3">
                              <label className={``} htmlFor="np">
                                Number of Product
                              </label>
                              <TextField
                                id="np"
                                disabled
                                type="number"
                                placeholder="0"
                                name="discount_product_quantity"
                                value={discount?.discount_product_quantity}
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    inputValue === '' ||
                                    parseFloat(inputValue) >= 0
                                  ) {
                                    handleDiscountChange(
                                      'discount_product_quantity',
                                      inputValue
                                    );
                                  }
                                }}
                                inputProps={{
                                  inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === '-' ||
                                    e.key === 'e' ||
                                    e.key === '.'
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                            <div className={`${styles.bonus_product}`}>
                              Bonus Product
                              <div className="card mb-3">
                                <div className="card-body py-1">
                                  <div
                                    className={`d-flex justify-content-between`}
                                  >
                                    <div className=" " style={{ width: '50%' }}>
                                      <div
                                        className={`d-flex ${styles.review_contents}`}
                                      >
                                        <div>
                                          <img
                                            style={{
                                              width: '51px',
                                            }}
                                            src={discount?.product_image}
                                            alt=""
                                          />
                                        </div>
                                        &nbsp;
                                        <div style={{}}>
                                          <p
                                            style={{
                                              cursor: 'pointer',
                                              display: 'inline-block',
                                              marginBottom: '5px',
                                            }}
                                          >
                                            <strong>
                                              {discount?.product_name}
                                            </strong>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-md-flex justify-content-between mt-2">
                              <label
                                className={` pt-2`}
                                htmlFor="discountPrice"
                              >
                                Duration of Discount Price
                              </label>
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              <div className="mb-3 d-flex">
                                <div className="" style={{ width: '45%' }}>
                                  <div className="mb-3">
                                    <DatePicker
                                      showIcon
                                      selected={startDate}
                                      onChange={(date) => setStartDate(date)}
                                      dateFormat="dd/MM/yyyy"
                                      className="form-control"
                                      placeholderText="Start"
                                      required
                                      minDate={new Date()}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="px-1 d-flex align-items-center "
                                  style={{ height: '40px' }}
                                >
                                  <img
                                    src="/assets/icons/line.svg"
                                    style={{ width: '30px' }}
                                    alt=""
                                  />
                                </div>
                                <div className="" style={{ width: '45%' }}>
                                  <div className="mb-3">
                                    <DatePicker
                                      showIcon
                                      selected={endDate}
                                      onChange={(date) => setEndDate(date)}
                                      dateFormat="dd/MM/yyyy"
                                      className="form-control"
                                      placeholderText="End"
                                      required
                                      minDate={new Date()}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mb-3">
                              <label className={``} htmlFor="pdb">
                                Proposed Discount Budget*
                              </label>
                              <TextField
                                id="pdb"
                                type="number"
                                disabled
                                placeholder="Input Amount"
                                name="proposed_discount_budget"
                                value={discount?.proposed_discount_budget}
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    inputValue === '' ||
                                    parseFloat(inputValue) >= 0
                                  ) {
                                    handleDiscountChange(
                                      'proposed_discount_budget',
                                      inputValue
                                    );
                                  }
                                }}
                                inputProps={{
                                  inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === '-' ||
                                    e.key === 'e' ||
                                    e.key === '.'
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                            <div className="mb-3 d-flex justify-content-between">
                              <div>
                                <p
                                  className="mb-0 text-[#af3585] font-weight-bold"
                                  style={{ fontSize: '14px' }}
                                >
                                  Discount Occurrence
                                </p>
                                <small className="text-danger small">
                                  This percentage is subject to change based on
                                  the market research undertaken
                                </small>
                              </div>
                              <span className="text-[#af3585]">
                                {discountPercentage2 ? discountPercentage2 : 0}
                              </span>
                            </div>
                            {isPOApprovalCode && (
                              <div className="mb-3">
                                <label
                                  className={``}
                                  htmlFor="po_approval_code"
                                >
                                  Enter P.O Code*
                                </label>
                                <TextField
                                  id="po_approval_code"
                                  type="text"
                                  name="po_approval_code"
                                  value={discount?.po_approval_code}
                                  size="small"
                                  fullWidth
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    // if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleDiscountChange(
                                      'po_approval_code',
                                      inputValue
                                    );
                                    // }
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <button
                        type="submit"
                        className={`modal_btn btn btn-block`}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openGoToCart}
        onClose={handleGoToCartClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openGoToCart}>
          <Box
            className={`mobile_modal_size ${styles.modal_bg}`}
            sx={style1}
            style={{ height: '300px' }}
          >
            <div className={`text-center`}>
              <img src="/assets/icons/Illustration.svg" alt="" />
              <h5 className="mt-3 font-weight-bold">Request Sent</h5>
              <p
                className="text-center"
                style={{ color: '#6B7280', fontSize: '14px' }}
              >
                You will receive an in-app notification and mail once your{' '}
                <br /> request has been approved
              </p>
              <div className={`text-center  pt-2`}>
                <button
                  className={`${styles.go_to} d-block w-100 `}
                  onClick={handleGoToCartClose}
                >
                  Go to Product
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <GlobalModal
        open={modalOpenC}
        onClose={handleCloseC}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#F5F5F5"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        background="#FFFFFF"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Select Audience
            </h5>
            <span onClick={handleCloseC} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>
          <form action="" className="mt-0 form_label">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <LiveLocation label="Location" passData={processLocation} />
                  {selectedLocationName?.length > 0 &&
                    selectedLocationName?.map((el, i) =>
                      el?.length ? (
                        <div className="products mt-2">
                          <div
                            className={`${styles.product_selected} mb-1`}
                            key={i}
                          >
                            <div className="d-flex justify-content-between">
                              <span>{el}</span>
                              {/* Remove product button */}
                            </div>
                          </div>
                        </div>
                      ) : null
                    )}
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <div>
                  <div className="mb-3">
                    <label htmlFor="customers">Select Customers</label>
                    <Select
                      labelId="customers"
                      id="customers"
                      fullWidth
                      size="small"
                      value={customers}
                      required
                      onChange={handleCustomerChange}
                    >
                      <MenuItem value={'shoppers'}>Shoppers</MenuItem>
                      {/* <MenuItem value={'shop_owners'}>Merchants</MenuItem> */}
                    </Select>
                    {customers !== '' && (
                      <div className="products mt-2">
                        <div className={`${styles.product_selected} mb-1`}>
                          <div className=" d-flex justify-content-between">
                            <span>{customers}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <button
                type="button"
                onClick={handleProceed}
                disabled={
                  !selectedLocationName?.length &&
                  (!customers || customers === 'select')
                }
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>

      <GlobalModal
        open={handleNewPOModal}
        onClose={handleNewPO}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#F5F5F5"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        background="#FFFFFF"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              className="mb-5"
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Add New P.O
            </h5>
            <span onClick={handleNewPO} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>
          <form action="" className="mt-0 form_label">
            <div className="mb-4">
              <label className={`label`} htmlFor="desc">
                Upload PO *
              </label>
              {!previewData && (
                <div>
                  <InputFile
                    accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                    uploaded={handleFileUpload}
                    show={true}
                  />
                </div>
              )}
              {previewData && (
                <div>
                  <embed src={previewData} width="500" height="200" />
                  <div
                    className="ml-auto mt-2 px-3"
                    style={{
                      bottom: '15px',
                      left: '0',
                      right: '0',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      onClick={() => setPreviewData(null)}
                      className="btn btn-light px-2 py-1 mx-2 rounded border-0"
                      style={{
                        backgroundColor: '#333 !important',
                        fontSize: '14px',
                      }}
                    >
                      <img
                        style={{
                          width: '15px',
                        }}
                        src="/assets/icons/trash-2.png"
                        alt=""
                      />
                      Remove
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className={`label`} htmlFor="poamount">
                Enter PO Amount *
              </label>
              <TextField
                required
                type="number"
                id="poamount"
                value={setPOUploads?.po_amount}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue === '' || parseFloat(inputValue) > 0) {
                    handlePOUploads('po_amount', inputValue);
                  }
                }}
                inputProps={{
                  inputMode: 'decimal',
                }}
                onKeyDown={(e) => {
                  if (e.key === '-' || e.key === 'e' || e.key === '.') {
                    e.preventDefault();
                  }
                }}
                placeholder="0"
                size="small"
                fullWidth
              />
            </div>

            <div className="mt-5">
              <button
                type="button"
                onClick={handlePOApprovalCode}
                disabled={!previewData && !POUploads.po_amount}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Get P.O Approval Code
                {isPOPending && (
                  <CircularProgress style={{ color: 'white' }} size="1rem" />
                )}
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={summary}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={summary}>
          <Box
            className={`mobile_modal_size pt-md-3 ${styles.modal_bg}`}
            sx={style}
          >
            <div className={`text-center ${styles.add_to_cart_content}`}>
              <img src="/assets/icons/Illustration.svg" alt="" />
              <h5 style={{ fontWeight: 'bold' }} className="mt-3 mb-3">
                Product Approved
              </h5>
              <p>This Product has been successfully Added</p>
              <div>
                <button
                  className={`${styles.go_to} d-block w-100 `}
                  style={{ fontSize: '14px' }}
                  onClick={() => setSummary(false)}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={requestSummary}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={requestSummary}>
          <Box className={`mobile_modal_size`} sx={style3}>
            <div className={`${styles.modal_content}`}>
              <div className={`text-center`}>
                <h3>Request Summary</h3>
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <p style={{ fontSize: '14px' }} className="text-muted">
                    Discount Type
                  </p>
                  <p style={{ fontSize: '14px' }}>
                    {discount?.discount_type === 'amount_off_product'
                      ? 'Amount Off Product'
                      : discount?.discount_type === 'buy_x_get_y'
                      ? 'Buy X Get Y'
                      : ''}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p style={{ fontSize: '14px' }} className="text-muted">
                    Proposed Budget
                  </p>
                  <p style={{ fontSize: '14px' }}>
                    {Number(
                      discount?.proposed_discount_budget
                    ).toLocaleString()}
                  </p>
                </div>
                {/* <div className="d-flex justify-content-between">
                  <p style={{ fontSize: '14px' }} className="text-muted">
                    RX Service Charge
                  </p>
                  <p style={{ fontSize: '14px' }}>
                    20%
                  </p>
                </div> */}
              </div>
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className={`${styles.cancel_delete} w-50 btn`}
                  onClick={handleRequestSummary}
                >
                  Cancel
                </button>
                &nbsp; &nbsp;
                {isPending && (
                  <button disabled className={`btn btn-block modal_btn`}>
                    Submitting &nbsp;
                    <CircularProgress style={{ color: 'white' }} size="1rem" />
                  </button>
                )}
                {!isPending && (
                  <button
                    type="button"
                    onClick={discountSubmit}
                    className={`${styles.confirm_order}  w-50 btn `}
                  >
                    Confirm
                  </button>
                )}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
