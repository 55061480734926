

// ----------------------------------------------------------------------
import styles from "./BrandOwner.module.css"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {
  Card,
  CardContent,
} from '@mui/material';
import ReactApexChart from 'react-apexcharts';

// sections
// mock
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import bus from 'src/bus';
import GlobalModal from "src/Modals/GlobalModal";

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor:'#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius:'5px',
  boxShadow: 24,
  p: 2,
};
export default function MorePage() {
  const [msModalOpen, setMsModalOpen] = useState(false);
  const [sbpModalOpen, setSbpModalOpen] = useState(false);

  const handleCloseMsModal = () => {
    setMsModalOpen(false);
  };
  const handleCloseSbpModal = () => {
    setSbpModalOpen(false);
  };

  // For tabs
  const [selectedTab, setSelectedTab] = useState(1);
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };


  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose1 = () => {
    setModalOpen(false);
  };


  const handleClose = () => setOpen(false);

  const handleChange = (e) => setSearch(e.target.value)
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    legend:{
      position:'top',
      horizontalAlign: 'center', 
    },
    
    colors : ['#53138D', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 5,
        borderRadiusApplication:'end'
      },
    },
    xaxis: {
      categories: ['Nigeria', 'Ghana', 'South Africa', 'Egypt', 'Benin', 'Liberia', 'Togo', 'Sierrelone', 'Senegal'],
    },
    
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val 
        }
      }
    },
    dataLabels:{
      enabled: false,
    }
  })
  const [chartSeries, setChartSeries] = useState(
    [{
      name: 'Order',
      data:  [900, 550, 800, 810, 810, 600, 400, 400]
    }, 
    {
      name: 'Depletion',
      data: [800, 700, 600, 500, 800, 700, 650, 600]
    }, 
    {
      name: 'In-stock',
      data: [650, 700, 750, 890, 800, 400, 350, 480]
    }]
  );
  const [chartOptions1, setChartOptions1] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    legend:{
      position:'top',
      horizontalAlign: 'right', 
    },
    colors : ['#53138D', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        borderRadius: 5,
        borderRadiusApplication:'end'
      },
    },
    dataLabels:{
      enabled: false,
    },
    xaxis: {
      categories: ['Milo', 'Pure Life Water', 'Nescafe', 'Maggi', 'Kitkat', 'Golden Morn', 'Nido', 'Semovita'],
    },
    
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val 
        }
      }
    }
  })
  const [chartSeries1, setChartSeries1] = useState(
    [{
      name: 'Depletion',
      data:[900, 550, 800, 810, 810, 600, 400, 400]
    }]
  )
  const [chartSeries2, setChartSeries2] = useState(
    [35,3,8,3,10,15,5,21
    ]
  )
  const [chartOptions2, setChartOptions2] = useState({
    chart: {
      type: 'pie',
      toolbar: {
        show: false,
      },
    },
    dataLabels:{
      enabled: false,
    },
    colors : ['#85E13A', '#D444F1', '#16B364', '#06AED4', '#2E90FA', '#875BF7', '#F63D68', '#EAAA08'],
    labels: ['Milo', 'Pure Life Water', 'Nescafe', 'Maggi', 'Kitkat', 'Golden Morn', 'Nido', 'Semovita'],

    plotOptions: {
      pie: {
        labels: {
          formatter: function(val, opts) {
            var value =chartSeries2[opts.index];
            return val + ' - ' + value;
          }
        }
      },
    },
    
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "%" 
        }
      }
    }
  })
  
 
    const [FGBChartOptions, setFGBChartOptions] = useState({
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      legend:{
        position:'top',
        horizontalAlign: 'right', 
      },
      colors : ['#53138D', '#000000', '#23aa2a', '#ff0000'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          borderRadius: 5,
          borderRadiusApplication:'end'
        },
      },
      dataLabels:{
        enabled: false,
      },
      xaxis: {
        categories: ['Milo', 'Pure Life Water', 'Nescafe', 'Maggi', 'Kitkat', 'Golden Morn', 'Nido', 'Semovita'],
      },
      
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return " " + val 
          }
        }
      }
    })
  
    const [FGBChartSeries, setFGBChartSeries] = useState( 
      [
        {
        name: 'Nestle',
        data:[900, 550, 800, 810, 810, 600, 400, 400]
      },
      {
        name: 'Dangote',
        data: [450, 650, 700, 850, 540, 950, 650, 900]
      },
      {
        name: 'Calbury',
        data: [350, 450, 500, 550, 540, 650, 750, 900]
      },
      {
        name: 'Cway',
        data: [40, 50, 55, 60, 59, 70, 80, 89]
      }
    ]
    )
    // ===============================Fastest Growing Product (fgb) Chart=========================================
  
    // ===============================Fastest Growing Product (fgb) Chart=========================================
  
    const [FGPChartOptions, setFGPChartOptions] = useState({
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      legend:{
        position:'top',
        horizontalAlign: 'right', 
      },
      colors : ['#53138D', '#000000', '#23aa2a', '#ff0000'], 
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          borderRadius: 5,
          borderRadiusApplication:'end'
        },
      },
      dataLabels:{
        enabled: false,
      },
      xaxis: {
        categories: ['Milo', 'Pure Life Water', 'Nescafe', 'Maggi', 'Kitkat', 'Golden Morn', 'Nido', 'Semovita'],
      },
      
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return " " + val 
          }
        }
      }
    })
  
    const [FGPChartSeries, setFGPChartSeries] = useState( 
      [
        {
        name: 'Nestle',
        data:[900, 550, 800, 810, 810, 600, 400, 400]
      },
      {
        name: 'Dangote',
        data: [450, 650, 700, 850, 540, 950, 650, 900]
      },
      {
        name: 'Calbury',
        data: [350, 450, 500, 550, 540, 650, 750, 900]
      },
      {
        name: 'Cway',
        data: [400, 500, 550, 600, 590, 700, 800, 890]
      }
    ]
    )
    // ===============================Fastest Growing Product (fgb) Chart=========================================
  
    // ================================== Shoppers Buying Power =========================================
    const [chartOptionsPie, setChartOptionsPie] = useState({
      chart: {
        type: 'pie',
        toolbar: {
          show: false,
        },
      },
      dataLabels:{
        enabled: false,
      },
      colors : ['#85E13A', '#D444F1', '#16B364', '#06AED4', '#2E90FA', '#875BF7', '#F63D68', '#EAAA08'],
      labels: ['Nigeria', 'Ghana', 'Egypt', 'Benin', 'Liberia', 'Togo', 'Sierralone', 'Senegal'],
  
      plotOptions: {
        pie: {
          labels: {
            formatter: function(val, opts) {
              var value =chartSeries2[opts.index];
              return val + ' - ' + value;
            }
          }
        },
      },
      
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "%" 
          }
        }
      }
    })
    const [chartSeriesPie, setChartSeriesPie] = useState(
      [35,3,8,3,10,15,5,21]
    )
    const [chartOptionLine, setChartOptionLine] = useState({
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      legend:{
        position:'top',
        horizontalAlign: 'right', 
      },
      colors : ['#53138D'], 
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          borderRadius: 5,
          borderRadiusApplication:'end'
        },
      },
      xaxis: {
        categories: ['Nigeria', 'Ghana', 'South Africa', 'Egypt', 'Benin', 'Liberia', 'Togo', 'Sierrelone', 'Senegal'],
      },
      
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val 
          }
        }
      },
      dataLabels:{
        enabled: false,
      }
    })
    const [lineChartSeries, setLineChartSeries] = useState(
      [
        {
          name: 'Series 1',
          data: [30, 40, 45, 50, 49, 60, 70]
        },
       
      ]
    )
    // ================================== Shoppers Buying Power =========================================

    const [brand, setBrand] = useState('1');

    const handleBrandChange = (e) => {
      setBrand(e.target.value)

    }

  useEffect(() => {
    bus.on('open-product', () => {
      setOpen(true)
    });
    
    return () => {
      bus.off('open-product', () => {

      });
    };
  }, []);

  
 
  
  
  return (
    <>
     <div className={`wrapper pt-2 px-2`}>
        <Card style={{marginTop:'50px',marginBottom:'20px',borderRadius:'10px'}} className='p-0'>
          <CardContent>
            <div className={`${styles.insight_header} pl-md-5`}>
              <h5>
              Store Orders, Depletion and in-stock Stats
              </h5>
              <div className="d-md-flex justify-content-between">
                <p>
                  Compare your competitor's orders against their depletion <br/>
                  rates and what's left in stock in their Stores.
                </p>
                <div className="d-flex">
                  <div className="dropleft ">
                    <button className="btn  border" onClick={() => setModalOpen(true)} 
                      style={{
                        backgroundColor:'#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow:  '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px'
                      }}>
                      <img className="" style={{display:'inline',width:'28%'}} src="/assets/icons/filterlines.svg" alt="" /> 
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                      Filters
                      </small>
                    </button>
                    
                  </div>
                  &nbsp;
                  &nbsp;
                  <div className="dropleft ">
                    <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className={`${styles.export_btn} btn m-0`}>
                      <span style={{
                      paddingTop: '8px',


                      }}> 
                      <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                      Export 
                      </span>
                      &nbsp;
                      &nbsp;
                      <span style={{
                        borderLeft:'1px solid white',
                        display:'inline-block',
                        height:'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign:'center',

                      }}>
                        <img src="/assets/icons/arrowDown.svg" alt="" /> 

                      </span>


                    </button>
                    
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      <button type="button"  className="rss dropdown-item btn border-0">CSV</button>
                      <button type="button"    className="rss dropdown-item btn border-0">Excel </button>
                      <button type="button"    className="rss dropdown-item btn border-0">PDF </button>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row mt-md-0 mt-4">
                <div className="col-md-7 mb-2">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="card  p-0"  >
                        <div className="card-body d-flex justify-content-around  py-1">
                          <label htmlFor="ov">
                            <input className="mt-2 mr-2" type="radio" name="insight" id="ov" /> Overall
                          </label>
                          <label htmlFor="year">
                            <input className="mt-2 mr-2" type="radio" name="insight" id="year" /> Yearly
                          </label>
                          <label htmlFor="month">
                            <input className="mt-2 mr-2" type="radio" name="insight" id="month" /> Montly
                          </label>
                        </div>
                      </div>
                      

                    </div>
                    <div className="col-md-4">
                        <div className="m-0">
                            <div className="mt-2">
                              <div className="p-0">
                                <span>
                                  <span className={`${styles.arrowSpan}`}>
                                    <img width={'20px'}  src="/assets/icons/left_arrow.svg" alt="arrow" className={`${styles.newStyle} mr-2`}/>
                                  </span>
                                  <span className={`${styles.year_styles}`}>2023</span>
                                  <span className={`${styles.arrowSpan}`}>
                                    <img width={'20px'} src="/assets/icons/right_arrow.svg" alt="arrow"className={`${styles.newStyle} ml-2`} />
                                  </span>
                                </span>
                              </div>
                            </div>
                        </div>
                    </div>   
                  </div>
                  
                </div>
                {/* <div className="col-md-6  text-md-right">
                  <span>
                    <img src="/assets/icons/ordercolor.svg" alt="" />
                    &nbsp;
                    Order
                  </span>
                  &nbsp;
                  <span>
                    <img src="/assets/icons/depletionColor.svg" alt="" />
                    &nbsp;
                    Depletion
                  </span>
                  &nbsp;
                  <span>
                    <img src="/assets/icons/instockColor.svg" alt="" />
                    &nbsp;
                    In-stock
                  </span>
                  

                </div> */}
              </div>
              <div className="row ">
                <div className="col-7"></div>
                <div className="col-5">
                  <Select
                    id="state"
                    width="50%"
                    size="small"
                    value={brand}
                    required
                    onChange={handleBrandChange}
                    className="float-right"
                  >

                    <MenuItem value={1}>Brands</MenuItem>
                    <MenuItem value={2}>Nestle</MenuItem>
                    <MenuItem value={3}>Cway</MenuItem>
                    <MenuItem value={4}>Chi</MenuItem>
                  </Select>
                </div>
              </div>
            </div>

            <ReactApexChart type="bar" series={chartSeries} options={chartOptions} height={500} />

          </CardContent>
          
        </Card>
        {/* Product Depletion */}
        <Card style={{ marginBottom:'20px',borderRadius:'10px'}} className='p-0'>
          <CardContent>
            <div className={`${styles.insight_header} pl-md-5`}>
              <h5>
                Product Depletion

              </h5>
              <div className="d-md-flex justify-content-between">
                <p style={{fontSize:'14px'}}>
                  View how your competitors products are depleted in their outlets
                </p>
                <div className="d-flex">
                  <div className="dropleft ">
                    <button className="btn  border" onClick={()=>{setModalOpen(true)}}
                      style={{
                        backgroundColor:'#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow:  '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px'
                      }}>
                      <img className="" style={{display:'inline',width:'28%'}} src="/assets/icons/filterlines.svg" alt="" /> 
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                      Filters
                      </small>
                    </button>
                    
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      {/* <button type="button" className="rss dropdown-item btn border-0">All</button>
                      <button type="button"  className="rss dropdown-item btn border-0">Abandoned </button>
                      <button type="button"  className="rss dropdown-item btn border-0">Completed</button> */}
                    </div>
                  </div>
                  &nbsp;
                  &nbsp;
                  <div className="dropleft ">
                    <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className={`${styles.export_btn} btn m-0`}>
                        <span style={{
                        paddingTop: '8px',


                        }}>
                        <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                        Export
                        </span>
                        &nbsp;
                        &nbsp;
                        <span style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',

                        }}>
                        <img src="/assets/icons/arrowDown.svg" alt="" />

                        </span>


                    </button>

                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                        <button type="button" className="rss dropdown-item btn border-0">CSV</button>
                        <button type="button" className="rss dropdown-item btn border-0">Excel </button>
                        <button type="button" className="rss dropdown-item btn border-0">PDF </button>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row mt-md-0 mt-4">
                <div className="col-md-7 mb-2 mt-4">
                  <div className="row mt-2">
                    <div className="col-md-8">
                      <div className="card  p-0"  >
                        <div className="card-body d-flex justify-content-around  py-1">
                          <label htmlFor="ov2">
                            <input type="radio" className="mt-2 mr-2" name="insight_shoppers" id="ov2" /> Overall
                          </label>
                          <label htmlFor="year2">
                            <input type="radio" className="mt-2 mr-2" name="insight_shoppers" id="year2" /> Yearly
                          </label>
                          <label htmlFor="month2">
                            <input type="radio" className="mt-2 mr-2" name="insight_shoppers" id="month2" /> Montly
                          </label>
                        </div>
                      </div>

                    </div>  
                    <div className="col-md-4">
                        <div className="m-0">
                            <div className="mt-2">
                              <div className="p-0">
                                <span>
                                  <span className={`${styles.arrowSpan}`}>
                                    <img width={'20px'}  src="/assets/icons/left_arrow.svg" alt="arrow" className={`${styles.newStyle} mr-2`}/>
                                  </span>
                                  <span className={`${styles.year_styles}`}>2023</span>
                                  <span className={`${styles.arrowSpan}`}>
                                    <img width={'20px'} src="/assets/icons/right_arrow.svg" alt="arrow"className={`${styles.newStyle} ml-2`} />
                                  </span>
                                </span>
                              </div>
                            </div>
                        </div>
                    </div>                   
                  </div>
                </div>
               
              </div>
              <div className="row mt-3 ">
                <div className="col-7"></div>
                <div className="col-5">
                  <Select
                    id="state"
                    width="50%"
                    size="small"
                    value={brand}
                    required
                    onChange={handleBrandChange}
                    className="float-right"
                  >

                    <MenuItem value={1}>Brands</MenuItem>
                    <MenuItem value={2}>Nestle</MenuItem>
                    <MenuItem value={3}>Cway</MenuItem>
                    <MenuItem value={4}>Chi</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <div>
                <div>
                  <ReactApexChart type="bar" series={chartSeries1} options={chartOptions1} height={500} />
                </div>
            </div>


          </CardContent>
          
        </Card>
        {/* Fastest growing brands */}
        <Card style={{ marginBottom:'20px',borderRadius:'10px'}} className='p-0'>
          <CardContent>
            <div className={`${styles.insight_header} pl-md-5`}>
              <h5>
                Fastest Growing Brands
              </h5>
              <div className="d-md-flex justify-content-between">
                <p>
                  View the growth rates of top brands
                </p>
                <div className="d-flex">
                  <div className="dropleft ">
                    {/* <button className="btn  border" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" 
                      style={{
                        backgroundColor:'#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow:  '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px'
                      }}>
                      <img className="" style={{display:'inline',width:'28%'}} src="/assets/icons/filterlines.svg" alt="" /> 
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                      Filters
                      </small>
                    </button> */}
                    
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      {/* <button type="button" className="rss dropdown-item btn border-0">All</button>
                      <button type="button"  className="rss dropdown-item btn border-0">Abandoned </button>
                      <button type="button"  className="rss dropdown-item btn border-0">Completed</button> */}
                    </div>
                  </div>
                  &nbsp;
                  &nbsp;
                  <div className="dropleft ">
                    <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className={`${styles.export_btn} btn m-0`}>
                      <span style={{
                      paddingTop: '8px',


                      }}> 
                      <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                      Export 
                      </span>
                      &nbsp;
                      &nbsp;
                      <span style={{
                        borderLeft:'1px solid white',
                        display:'inline-block',
                        height:'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign:'center',

                      }}>
                        <img src="/assets/icons/arrowDown.svg" alt="" /> 

                      </span>


                    </button>
                    
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      <button type="button"  className="rss dropdown-item btn border-0">CSV</button>
                      <button type="button"    className="rss dropdown-item btn border-0">Excel </button>
                      <button type="button"    className="rss dropdown-item btn border-0">PDF </button>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row mt-md-0 mt-4">
                <div className="col-md-7 mb-2">
                  <div className="row mb-3">
                    <div className="col-md-8">
                      <div className="card  p-0"  >
                        <div className="card-body d-flex justify-content-around  py-1">
                          <label htmlFor="f_g_b_overall">
                            <input className="mt-2 mr-2" type="radio" name="fastest_growing_brands" id="f_g_b_overall" /> Overall
                          </label>
                          <label htmlFor="f_g_b_year">
                            <input className="mt-2 mr-2" type="radio" name="fastest_growing_brands" id="f_g_b_year" /> Yearly
                          </label>
                          <label htmlFor="f_g_b_montly">
                            <input className="mt-2 mr-2" type="radio" name="fastest_growing_brands" id="f_g_b_montly" /> Montly
                          </label>
                        </div>

                      </div>

                    </div>
                    <div className="col-md-4">
                        <div className="m-0">
                            <div className="mt-2">
                              <div className="p-0">
                                <span>
                                  <span className={`${styles.arrowSpan}`}>
                                    <img width={'20px'}  src="/assets/icons/left_arrow.svg" alt="arrow" className={`${styles.newStyle} mr-2`}/>
                                  </span>
                                  <span className={`${styles.year_styles}`}>2023</span>
                                  <span className={`${styles.arrowSpan}`}>
                                    <img width={'20px'} src="/assets/icons/right_arrow.svg" alt="arrow"className={`${styles.newStyle} ml-2`} />
                                  </span>
                                </span>
                              </div>
                            </div>
                        </div>
                    </div>   
                  </div>
                </div>
                
              </div>
            </div>

            <ReactApexChart type="line" series={FGBChartSeries} options={FGBChartOptions} height={500} />

          </CardContent>
          
        </Card>
        {/* Fastest growing products */}
        <Card style={{ marginBottom:'20px',borderRadius:'10px'}} className='p-0'>
          <CardContent>
            <div className={`${styles.insight_header} pl-md-5`}>
              <h5>
                Fastest Growing Products
              </h5>
              <div className="d-md-flex justify-content-between">
                <p style={{fontSize:'14px'}}>
                  View growth rate of highly demanded product
                </p>
                <div className="d-flex">
                  <div className="dropleft ">
                    {/* <button className="btn  border" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" 
                      style={{
                        backgroundColor:'#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow:  '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px'
                      }}>
                      <img className="" style={{display:'inline',width:'28%'}} src="/assets/icons/filterlines.svg" alt="" /> 
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                      Filters
                      </small>
                    </button> */}
                    
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      {/* <button type="button" className="rss dropdown-item btn border-0">All</button>
                      <button type="button"  className="rss dropdown-item btn border-0">Abandoned </button>
                      <button type="button"  className="rss dropdown-item btn border-0">Completed</button> */}
                    </div>
                  </div>
                  &nbsp;
                  &nbsp;
                  <div className="dropleft ">
                    <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className={`${styles.export_btn} btn m-0`}>
                      <span style={{
                      paddingTop: '8px',


                      }}> 
                      <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                      Export 
                      </span>
                      &nbsp;
                      &nbsp;
                      <span style={{
                        borderLeft:'1px solid white',
                        display:'inline-block',
                        height:'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign:'center',

                      }}>
                        <img src="/assets/icons/arrowDown.svg" alt="" /> 

                      </span>


                    </button>
                    
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      <button type="button"  className="rss dropdown-item btn border-0">CSV</button>
                      <button type="button"    className="rss dropdown-item btn border-0">Excel </button>
                      <button type="button"    className="rss dropdown-item btn border-0">PDF </button>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row mt-md-0 mt-4">
                <div className="col-md-7 mb-2">
                  <div className="row mb-3">
                    <div className="col-md-8">
                      <div className="card  p-0"  >
                        <div className="card-body d-flex justify-content-around  py-1">
                          <label htmlFor="f_g_p_overall">
                            <input className="mt-2 mr-2" type="radio" name="fastest_growing_brands" id="f_g_p_overall" /> Overall
                          </label>
                          <label htmlFor="f_g_p_year">
                            <input className="mt-2 mr-2" type="radio" name="fastest_growing_brands" id="f_g_p_year" /> Yearly
                          </label>
                          <label htmlFor="f_g_p_montly">
                            <input className="mt-2 mr-2" type="radio" name="fastest_growing_brands" id="f_g_p_montly" /> Montly
                          </label>
                        </div>

                      </div>

                    </div>
                    <div className="col-md-4">
                        <div className="m-0">
                            <div className="mt-2">
                              <div className="p-0">
                                <span>
                                  <span className={`${styles.arrowSpan}`}>
                                    <img width={'20px'}  src="/assets/icons/left_arrow.svg" alt="arrow" className={`${styles.newStyle} mr-2`}/>
                                  </span>
                                  <span className={`${styles.year_styles}`}>2023</span>
                                  <span className={`${styles.arrowSpan}`}>
                                    <img width={'20px'} src="/assets/icons/right_arrow.svg" alt="arrow"className={`${styles.newStyle} ml-2`} />
                                  </span>
                                </span>
                              </div>
                            </div>
                        </div>
                    </div>   
                  </div>
                </div>
                
              </div>
            </div>

            <ReactApexChart type="line" series={FGPChartSeries} options={FGPChartOptions} height={500} />

          </CardContent>
          
        </Card>

        <Card style={{ marginBottom:'20px',borderRadius:'10px'}} className='p-0'>
          <CardContent>
            <div className={`${styles.insight_header} pl-md-5`}>
              <h5>
                Market Share

              </h5>
              <div className="d-md-flex justify-content-between">
                <p>
                  A view of your competitors market shre compare <br /> to yours.
                </p>
                <div className="d-flex">
                  <div className="dropleft ">
                    {/* <button className="btn  border" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" 
                      style={{
                        backgroundColor:'#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow:  '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px'
                      }}>
                      <img className="" style={{display:'inline',width:'28%'}} src="/assets/icons/filterlines.svg" alt="" /> 
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                      Filters
                      </small>
                    </button> */}
                    
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      {/* <button type="button" className="rss dropdown-item btn border-0">All</button>
                      <button type="button"  className="rss dropdown-item btn border-0">Abandoned </button>
                      <button type="button"  className="rss dropdown-item btn border-0">Completed</button> */}
                    </div>
                  </div>
                  &nbsp;
                  &nbsp;
                  <div className="dropleft ">
                    <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className={`${styles.export_btn} btn m-0`}>
                      <span style={{
                      paddingTop: '8px',


                      }}> 
                      <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                      Export 
                      </span>
                      &nbsp;
                      &nbsp;
                      <span style={{
                        borderLeft:'1px solid white',
                        display:'inline-block',
                        height:'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign:'center',

                      }}>
                        <img src="/assets/icons/arrowDown.svg" alt="" /> 

                      </span>


                    </button>
                    
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      <button type="button"  className="rss dropdown-item btn border-0">CSV</button>
                      <button type="button"    className="rss dropdown-item btn border-0">Excel </button>
                      <button type="button"    className="rss dropdown-item btn border-0">PDF </button>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row mt-md-0 mt-4">
                <div className="col-md-7 mb-2">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="card  p-0"  >
                        <div className="card-body d-flex justify-content-around  py-1">
                          <label htmlFor="ov1">
                            <input className="mt-2 mr-2" type="radio" name="insight_depletion" id="ov1" /> Overall
                          </label>
                          <label htmlFor="year1">
                            <input className="mt-2 mr-2" type="radio" name="insight_depletion" id="year1" /> Yearly
                          </label>
                          <label htmlFor="month1">
                            <input className="mt-2 mr-2" type="radio" name="insight_depletion" id="month1" /> Montly
                          </label>
                        </div>

                      </div>

                    </div>
                    <div className="col-md-4">
                        <div className="m-0">
                            <div className="mt-2">
                              <div className="p-0">
                                <span>
                                  <span className={`${styles.arrowSpan}`}>
                                    <img width={'20px'}  src="/assets/icons/left_arrow.svg" alt="arrow" className={`${styles.newStyle} mr-2`}/>
                                  </span>
                                  <span className={`${styles.year_styles}`}>2023</span>
                                  <span className={`${styles.arrowSpan}`}>
                                    <img width={'20px'} src="/assets/icons/right_arrow.svg" alt="arrow"className={`${styles.newStyle} ml-2`} />
                                  </span>
                                </span>
                              </div>
                            </div>
                        </div>
                    </div>   
                  </div>
                </div>
                
              </div>
              <div className="row ">
                <div className="col-7"></div>
                <div className="col-5">
                  <Select
                    id="state"
                    width="50%"
                    size="small"
                    value={brand}
                    required
                    onChange={handleBrandChange}
                    className="float-right"
                  >

                    <MenuItem value={1}>Brands</MenuItem>
                    <MenuItem value={2}>Nestle</MenuItem>
                    <MenuItem value={3}>Cway</MenuItem>
                    <MenuItem value={4}>Chi</MenuItem>
                  </Select>
                </div>
              </div>
            </div>

            <ReactApexChart type="pie" series={chartSeries2} options={chartOptions2} width={'80%'} height={350} />

          </CardContent>
          
        </Card>
        
        {/* <Card style={{ marginBottom:'20px',borderRadius:'10px'}} className='p-0'>
          <CardContent>
            <div className={`${styles.insight_header} pl-md-5`}>
              <h5>
                Shoppers buying power

              </h5>
              <div className="d-md-flex justify-content-between">
                <p>
                Track how your orders compares to your visitors <br /> average.
                </p>
                <div className="d-flex">
                  <div className="dropleft ">
                    <button className="btn  border" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" 
                      style={{
                        backgroundColor:'#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow:  '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px'
                      }}>
                      <img className="" style={{display:'inline',width:'28%'}} src="/assets/icons/filterlines.svg" alt="" /> 
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                      Filters
                      </small>
                    </button>
                   
                  </div>
                  &nbsp;
                  &nbsp;
                  <div className="dropleft ">
                    <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className={`${styles.export_btn} btn m-0`}>
                      <span style={{
                      paddingTop: '8px',


                      }}> 
                      <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                      Export 
                      </span>
                      &nbsp;
                      &nbsp;
                      <span style={{
                        borderLeft:'1px solid white',
                        display:'inline-block',
                        height:'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign:'center',

                      }}>
                        <img src="/assets/icons/arrowDown.svg" alt="" /> 

                      </span>


                    </button>
                    
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      <button type="button"  className="rss dropdown-item btn border-0">CSV</button>
                      <button type="button"    className="rss dropdown-item btn border-0">Excel </button>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row mt-md-0 mt-4">
                <div className="col-md-6 mb-2">
                  <div className="row">
                    <div className="col-md-9">
                      <div className="card  p-0"  >
                        <div className="card-body d-flex justify-content-around  py-1">
                          <label htmlFor="ov2">
                            <input type="radio" name="insight_shoppers" id="ov2" /> Overall
                          </label>
                          <label htmlFor="year2">
                            <input type="radio" name="insight_shoppers" id="year2" /> Yearly
                          </label>
                          <label htmlFor="month2">
                            <input type="radio" name="insight_shoppers" id="month2" /> Montly
                          </label>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
                
              </div>
            </div>

            <ReactApexChart type="bar" series={chartSeries3} options={chartOptions3} height={500} />

          </CardContent>
          
        </Card> */}

        <Card style={{ marginBottom:'20px',borderRadius:'10px'}} className='p-0'>
          <CardContent>
            <div className={`${styles.insight_header} pl-md-5`}>
              <h5>
                Shopper's purchasing power
              </h5>
              <div className="d-md-flex justify-content-between">
                <p style={{fontSize:'14px'}}>
                  An overview of the average amount your shoppers buy
                </p>
                <div className="d-flex">
                  <div className="dropleft ">
                    <button className="btn  border" onClick={()=>{setModalOpen(true)}}
                      style={{
                        backgroundColor:'#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow:  '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px'
                      }}>
                      <img className="" style={{display:'inline',width:'28%'}} src="/assets/icons/filterlines.svg" alt="" /> 
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                      Filters
                      </small>
                    </button>
                    
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      {/* <button type="button" className="rss dropdown-item btn border-0">All</button>
                      <button type="button"  className="rss dropdown-item btn border-0">Abandoned </button>
                      <button type="button"  className="rss dropdown-item btn border-0">Completed</button> */}
                    </div>
                  </div>
                  &nbsp;
                  &nbsp;
                  <div className="dropleft ">
                    <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className={`${styles.export_btn} btn m-0`}>
                        <span style={{
                        paddingTop: '8px',


                        }}>
                        <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                        Export
                        </span>
                        &nbsp;
                        &nbsp;
                        <span style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',

                        }}>
                        <img src="/assets/icons/arrowDown.svg" alt="" />

                        </span>


                    </button>

                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                        <button type="button" className="rss dropdown-item btn border-0">CSV</button>
                        <button type="button" className="rss dropdown-item btn border-0">Excel </button>
                        <button type="button" className="rss dropdown-item btn border-0">PDF </button>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row mt-md-0 mt-4">
                <div className="col-md-7 mb-2 mt-4">
                  <div className="row mt-2">
                    <div className="col-md-8">
                      <div className="card  p-0"  >
                        <div className="card-body d-flex justify-content-around  py-1">
                          <label htmlFor="ov2">
                            <input type="radio" className="mt-2 mr-2" name="insight_shoppers" id="ov2" /> Overall
                          </label>
                          <label htmlFor="year2">
                            <input type="radio" className="mt-2 mr-2" name="insight_shoppers" id="year2" /> Yearly
                          </label>
                          <label htmlFor="month2">
                            <input type="radio" className="mt-2 mr-2" name="insight_shoppers" id="month2" /> Montly
                          </label>
                        </div>
                      </div>

                    </div>  
                    <div className="col-md-4">
                        <div className="m-0">
                            <div className="mt-2">
                              <div className="p-0">
                                <span>
                                  <span className={`${styles.arrowSpan}`}>
                                    <img width={'20px'}  src="/assets/icons/left_arrow.svg" alt="arrow" className={`${styles.newStyle} mr-2`}/>
                                  </span>
                                  <span className={`${styles.year_styles}`}>2023</span>
                                  <span className={`${styles.arrowSpan}`}>
                                    <img width={'20px'} src="/assets/icons/right_arrow.svg" alt="arrow"className={`${styles.newStyle} ml-2`} />
                                  </span>
                                </span>
                              </div>
                            </div>
                        </div>
                    </div>                   
                  </div>
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-4 pt-2">
                  <div className="card  p-0 mt-4"  >
                    <div className="card-body d-flex justify-content-around  py-1">
                      <label htmlFor="tab1">
                        <input type="radio" 
                          id="tab1"
                          name="tabs"
                          value={1}
                          checked={selectedTab === 1}
                          onChange={() => handleTabChange(1)}
                          className="mt-2 mr-2"
                        /> 
                          Bar Chart
                      </label>
                      <label htmlFor="tab2">
                        <input type="radio"
                          id="tab2"
                          name="tabs"
                          value={2}
                          checked={selectedTab === 2}
                          onChange={() => handleTabChange(2)}
                          className="mt-2 mr-2"
                        /> 
                        Line Chart
                      </label>
                      <label htmlFor="tab3">
                        <input type="radio" 
                          id="tab3"
                          name="tabs"
                          value={3}
                          checked={selectedTab === 3}
                          onChange={() => handleTabChange(3)}
                          className="mt-2 mr-2"
                        /> 
                        Pie Chart
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 ">
                <div className="col-7"></div>
                <div className="col-5">
                  <Select
                    id="state"
                    width="50%"
                    size="small"
                    value={brand}
                    required
                    onChange={handleBrandChange}
                    className="float-right"
                  >

                    <MenuItem value={1}>Brands</MenuItem>
                    <MenuItem value={2}>Nestle</MenuItem>
                    <MenuItem value={3}>Cway</MenuItem>
                    <MenuItem value={4}>Chi</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <div>
              {selectedTab === 1 && (
                <div>
                  <ReactApexChart type="bar" series={lineChartSeries} options={chartOptionLine} height={500} />
                </div>
              )}
              {selectedTab === 2 && (
                <div>
                  <ReactApexChart type="line" series={lineChartSeries} options={chartOptionLine} height={500} />
                </div>
              )}
              {selectedTab === 3 && (
                <div>
                  <ReactApexChart type="pie" series={chartSeriesPie} options={chartOptionsPie} height={350} width={'80%'}/>

                </div>
              )}
            </div>

            {/* <ReactApexChart type="bar" series={chartSeries3} options={chartOptions3} height={500} /> */}

          </CardContent>
          
        </Card>

     </div>
     <GlobalModal
        open={open}
        onClose={handleClose}
        position='absolute'
        top= '50%'
        left= '50%'
        transform= 'translate(-50%, -50%)'
        width= '580px !important'
        overflow='auto'
        bgcolor='#F5F5F5'
        border= '1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow= '24'
        p='25px'
      >
        <div  className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
          <h5 style={{
            fontSize: '19px',
            fontWeight: '700',

          }}>New Incremental Insight</h5>
            <span onClick={handleClose} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>
          

          <form action="" className="mt-4">
            <div className="row">
              <div className="col-md-12 mb-1">
                <div className={`mb-3 ${styles.control_relative}`}>
                  <label htmlFor="loc" className="text-muted">Search for product</label>
                  <TextField
                    type='text'
                    id="loc"
                    placeholder="Search"
                    name="search"
                    value={search}
                    onChange={handleChange}


                    size="small"
                    fullWidth

                  />
                  <img src="/assets/icons/search.svg" alt="" />

                    

                </div>
                <div  className={`product_selected`}>
                  <div className=" d-flex justify-content-between">
                  <span >Dangote sugar (5kg)</span>
                  &nbsp;
                  &nbsp;
                  <span style={{cursor:'pointer'}}>
                    <img style={{width:'5px'}} src="/assets/icons/x.svg" alt="" />

                  </span>

                  </div>
                </div>
                
              </div>
              
            </div>
            <div className="mt-4">
            <button type="button" onClick={handleClose} className={`${styles.modal_btn} btn btn-block`}>
              Proceed

            </button>
            
            
            </div>

          </form>


        </div>
       
       
      </GlobalModal>
      {/* <GlobalModal
        open={modalOpen}
        onClose={handleClose1}
        position='absolute'
        top= '50%'
        left= '50%'
        transform= 'translate(-50%, -50%)'
        width= '740px !important'
        height= 'auto !important'
        maxHeight= '50vh !important'
        overflow='auto'
        bgcolor='#fff'
        border= '1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow= '24'
        p='25px'
      >
        <div  className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5 style={{
              fontSize: '19px',
              fontWeight: '700',

            }}>Filter</h5>
            <span onClick={handleClose1} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>
          <div className={`${styles.modal_content} row`}>
            <div className="col-md-6">
                <div className="allprod_wrapper">
                    <div className="modal-header" style={{border: 'none'}}>
                        <h5 className="modal-title" id="exampleModalLabel">Products</h5>
                    </div>
                    <div className={`${styles.modal_body}`}>
                        <div className="row my-0 py-0">
                            <div className="col-7 py-0 my-0">
                                <label htmlFor="allprod">
                                    <input type="checkbox" name="allprod" id="allprod" />
                                        &nbsp;&nbsp;All Products
                                </label>
                            </div>
                            <div className="col-5 py-0 my-0">
                                <span className={`${styles.span}`} data-toggle="collapse" data-target="#collapseExample01" aria-expanded="false" aria-controls="collapseExample">
                                    <label>
                                        <input type="checkbox" name="" />
                                        <span>
                                            <i className="bi bi-chevron-down"></i>
                                        </span>
                                    </label>
                                </span>
                            </div>
                        </div>

                        <div className="collapse" id="collapseExample01">
                          <div className="card card-body pl-5 pb-0" style={{border: 'none'}}>
                            <p><input type="text" className="form-control" placeholder="Search..." name="" id="" /></p>
                            <p>
                                <label htmlFor="milo1">
                                    <input type="checkbox" name="milo1" id="milo1" />
                                    &nbsp;&nbsp;<span>Nestle Milo</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="water1">
                                    <input type="checkbox" name="water1" id="water1" />
                                    &nbsp;&nbsp;<span>Pure life table water</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="nescafe1">
                                    <input type="checkbox" name="nescafe1" id="nescafe1" />
                                    &nbsp;&nbsp;<span>Nescafe</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="kitkat1">
                                    <input type="checkbox" name="kitkat1" id="kitkat1" />
                                    &nbsp;&nbsp;<span>Kitkat</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="maggi1">
                                    <input type="checkbox" name="maggi1" id="maggi1" />
                                    &nbsp;&nbsp;<span>Maggi</span>
                                </label>
                            </p>
                          </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.bordered_sect} col-md-6`} style={{
              borderLeft: '2px solid #ddd'
            }}>
                <div className="allprod_wrapper">
                    <div className="modal-header" style={{border: 'none'}}>
                        <h5 className="modal-title" id="exampleModalLabel">Location/Store</h5>
                    </div>
                    <div className={`${styles.modal_body}`}>
                        <p><input type="text" className="form-control" placeholder="Search..." name="" id="" /></p>
                        <div className="row my-0 py-0">
                            <div className="col-5 py-0 my-0">
                                <label htmlFor="country">
                                    <input type="checkbox" name="country" id="country" />
                                    &nbsp;&nbsp;Nigeria
                                </label>
                            </div>
                            <div className="col-5 py-0 my-0">
                                <span className={`${styles.span}`} data-toggle="collapse" data-target="#collapseExample02" aria-expanded="false" aria-controls="collapseExample">
                                    <label>
                                        <input type="checkbox" name="" />
                                        <span>
                                            <i className="bi bi-chevron-down"></i>
                                        </span>
                                    </label>
                                </span>
                            </div>
                        </div>

                        <div className="collapse pl-1" id="collapseExample02">
                            <div className="card card-body pl-5 pb-0" style={{border: 'none'}}>
                                <p><input type="text" className="form-control" placeholder="Search..." name="" id="" /></p>
                                <div className="row mt-3">
                                    <div className="col-5 py-0 my-0">
                                        <label htmlFor="state">
                                            <input type="checkbox" name="state" id="state" />
                                            &nbsp;&nbsp;Lagos
                                        </label>
                                    </div>
                                    <div className="col-5 py-0 my-0">
                                        <span className={`${styles.span}`} data-toggle="collapse" data-target="#collapseExample03" aria-expanded="false" aria-controls="collapseExample">
                                            <label>
                                                <input type="checkbox" name="" />
                                                <span>
                                                    <i className="bi bi-chevron-down"></i>
                                                </span>
                                            </label>
                                        </span>
                                    </div>
                                </div>

                                <div className="collapse pl-1" id="collapseExample03">
                                  <div className="card card-body pl-5 pb-0" style={{border: 'none'}}>
                                      <p><input type="text" className="form-control" placeholder="Search..." name="" id="" /></p>
                                      <p>
                                          <label htmlFor="milo2">
                                              <input type="checkbox" name="milo2" id="milo2" />
                                              &nbsp;&nbsp;<span>Mama tee superstore</span>
                                          </label>
                                      </p>
                                      <p>
                                          <label htmlFor="water2">
                                              <input type="checkbox" name="water2" id="water2" />
                                              &nbsp;&nbsp;<span>Shoprite</span>
                                          </label>
                                      </p>
                                      <p>
                                          <label htmlFor="nescafe2">
                                              <input type="checkbox" name="nescafe2" id="nescafe2" />
                                              &nbsp;&nbsp;<span>Justrite</span>
                                          </label>
                                      </p>
                                      <p>
                                          <label htmlFor="kitkat2">
                                              <input type="checkbox" name="kitkat2" id="kitkat2" />
                                              &nbsp;&nbsp;<span>Mallmart</span>
                                          </label>
                                      </p>
                                      <p>
                                          <label htmlFor="maggi2">
                                              <input type="checkbox" name="maggi2" id="maggi2" />
                                              &nbsp;&nbsp;<span>Jendol</span>
                                          </label>
                                      </p>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div className="mt-5">
              <button className="btn btn-[#53138D] bg-[#8c2d915d] w-100 d-block" data-dismiss="modal" aria-label="Close">Proceed</button>
          </div>

        </div>
       
       
      </GlobalModal> */}
     <GlobalModal
        open={modalOpen}
        onClose={handleClose1}
        position='absolute'
        top= '50%'
        left= '50%'
        transform= 'translate(-50%, -50%)'
        width= '740px !important'
        height= 'auto !important'
        maxHeight= '50vh !important'
        overflow='auto'
        bgcolor='#fff'
        border= '1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow= '24'
        p='25px'
      >
        <div  className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5 style={{
              fontSize: '22px',
              fontWeight: '900',

            }}>Filter</h5>
            <span onClick={handleClose1} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>
          <div className={`${styles.modal_content} row `}>
            <div className="col-md-6">
                <div className="allprod_wrapper">
                    <div style={{border: 'none'}}>
                        <h5 className="modal-title" id="exampleModalLabel"
                          style={{fontWeight: '550',}}
                        >Products</h5>
                    </div>
                    <div className={`${styles.modal_body}`}>
                        <div className="row my-0 py-0">
                            <div className="col-7 py-0 my-0">
                                <label htmlFor="allprod">
                                    <input type="checkbox" name="allprod" id="allprod" />
                                        &nbsp;&nbsp;All Products
                                </label>
                            </div>
                            <div className="col-5 py-0 my-0">
                                <span className={`${styles.span}`} data-toggle="collapse" data-target="#collapseExample04" aria-expanded="false" aria-controls="collapseExample">
                                    <label>
                                        <input type="checkbox" name="" />
                                        <span>
                                            <i className="bi bi-chevron-down"></i>
                                        </span>
                                    </label>
                                </span>
                            </div>
                        </div>

                        <div className="collapse" id="collapseExample04">
                          <div className="card card-body pl-5 pb-0" style={{border: 'none'}}>
                            <p className={`${styles.hold_input}`}>
                                <img src="/assets/icons/search.svg" alt="" />
                                <input type="text" placeholder="Search" style={{ textIndent: '25px', width: '100%' }} className="form-control" />
                            </p>
                            <p>
                                <label htmlFor="milo1">
                                    <input type="checkbox" name="milo1" id="milo1" />
                                    &nbsp;&nbsp;<span>Nestle Milo</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="water1">
                                    <input type="checkbox" name="water1" id="water1" />
                                    &nbsp;&nbsp;<span>Pure life table water</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="nescafe1">
                                    <input type="checkbox" name="nescafe1" id="nescafe1" />
                                    &nbsp;&nbsp;<span>Nescafe</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="kitkat1">
                                    <input type="checkbox" name="kitkat1" id="kitkat1" />
                                    &nbsp;&nbsp;<span>Kitkat</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="maggi1">
                                    <input type="checkbox" name="maggi1" id="maggi1" />
                                    &nbsp;&nbsp;<span>Maggi</span>
                                </label>
                            </p>
                          </div>
                        </div>
                    </div>
                </div>
                <div className="allprod_wrapper mt-3">
                    <div className={`${styles.modal_body}`}>
                        <div className="row my-0 py-0">
                            <div className="col-7 py-0 my-0">
                                <label htmlFor="allprod">
                                    <input type="checkbox" name="allprod" id="allprod" />
                                        &nbsp;&nbsp;All Category
                                </label>
                            </div>
                            <div className="col-5 py-0 my-0">
                                <span className={`${styles.span}`} data-toggle="collapse" data-target="#collapseExample01" aria-expanded="false" aria-controls="collapseExample">
                                    <label>
                                        <input type="checkbox" name="" />
                                        <span>
                                            <i className="bi bi-chevron-down"></i>
                                        </span>
                                    </label>
                                </span>
                            </div>
                        </div>

                        <div className="collapse" id="collapseExample01">
                          <div className="card card-body pl-5 pb-0" style={{border: 'none'}}>
                            <p className={`${styles.hold_input}`}>
                              <img src="/assets/icons/search.svg" alt="" />
                              <input type="text" placeholder="Search" style={{ textIndent: '25px', width: '100%' }} className=" form-control" />
                            </p>
                            <p>
                                <label htmlFor="milo1">
                                    <input type="checkbox" name="milo1" id="milo1" />
                                    &nbsp;&nbsp;<span>Beverages</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="water1">
                                    <input type="checkbox" name="water1" id="water1" />
                                    &nbsp;&nbsp;<span>Snacks</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="nescafe1">
                                    <input type="checkbox" name="nescafe1" id="nescafe1" />
                                    &nbsp;&nbsp;<span>Milk</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="kitkat1">
                                    <input type="checkbox" name="kitkat1" id="kitkat1" />
                                    &nbsp;&nbsp;<span>Table Water</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="maggi1">
                                    <input type="checkbox" name="maggi1" id="maggi1" />
                                    &nbsp;&nbsp;<span>Groceries</span>
                                </label>
                            </p>
                          </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.bordered_sect} col-md-6 right_wrapper`} style={{
              borderLeft: '2px solid #ddd'
            }}>
                <div className="allprod_wrappe">
                    <div className="" style={{border: 'none'}}>
                        <h5 className="modal-title" id="exampleModalLabel" style={{fontWeight: '550',}}>Location/Store</h5>
                    </div>
                    <div className={`${styles.modal_body}`}>
                      <p className={`${styles.hold_input}`}>
                          <img src="/assets/icons/search.svg" alt="" />
                          <input type="text" placeholder="Search" style={{ textIndent: '35px', width: '100%' }} className=" form-control" />
                      </p>
                        <div className="row my-0 py-0">
                            <div className="col-5 py-0 my-0">
                                <label htmlFor="country">
                                    <input type="checkbox" name="country" id="country" />
                                    &nbsp;&nbsp;Nigeria
                                </label>
                            </div>
                            <div className="col-5 py-0 my-0">
                                <span className={`${styles.span}`} data-toggle="collapse" data-target="#collapseExample02" aria-expanded="false" aria-controls="collapseExample">
                                    <label>
                                        <input type="checkbox" name="" />
                                        <span>
                                            <i className="bi bi-chevron-down"></i>
                                        </span>
                                    </label>
                                </span>
                            </div>
                        </div>

                        <div className="collapse pl-1" id="collapseExample02">
                            <div className="card card-body pl-5 pb-0" style={{border: 'none'}}>
                            <p className={`${styles.hold_input}`}>
                                <img src="/assets/icons/search.svg" alt="" />
                                <input type="text" placeholder="Search" style={{ textIndent: '25px', width: '100%' }} className=" form-control" />
                            </p>
                                <div className="row mt-3">
                                    <div className="col-5 py-0 my-0">
                                        <label htmlFor="state">
                                            <input type="checkbox" name="state" id="state" />
                                            &nbsp;&nbsp;Lagos
                                        </label>
                                    </div>
                                    <div className="col-5 py-0 my-0">
                                        <span className={`${styles.span}`} data-toggle="collapse" data-target="#collapseExample03" aria-expanded="false" aria-controls="collapseExample">
                                            <label>
                                                <input type="checkbox" name="" />
                                                <span>
                                                    <i className="bi bi-chevron-down"></i>
                                                </span>
                                            </label>
                                        </span>
                                    </div>
                                </div>

                                <div className="collapse pl-1" id="collapseExample03">
                                  <div className="card card-body pl-5 pb-0" style={{border: 'none'}}>
                                      <p><input type="text" className="form-control" placeholder="Search..." name="" id="" /></p>
                                      <p>
                                          <label htmlFor="milo2">
                                              <input type="checkbox" name="milo2" id="milo2" />
                                              &nbsp;&nbsp;<span>Mama tee superstore</span>
                                          </label>
                                      </p>
                                      <p>
                                          <label htmlFor="water2">
                                              <input type="checkbox" name="water2" id="water2" />
                                              &nbsp;&nbsp;<span>Shoprite</span>
                                          </label>
                                      </p>
                                      <p>
                                          <label htmlFor="nescafe2">
                                              <input type="checkbox" name="nescafe2" id="nescafe2" />
                                              &nbsp;&nbsp;<span>Justrite</span>
                                          </label>
                                      </p>
                                      <p>
                                          <label htmlFor="kitkat2">
                                              <input type="checkbox" name="kitkat2" id="kitkat2" />
                                              &nbsp;&nbsp;<span>Mallmart</span>
                                          </label>
                                      </p>
                                      <p>
                                          <label htmlFor="maggi2">
                                              <input type="checkbox" name="maggi2" id="maggi2" />
                                              &nbsp;&nbsp;<span>Jendol</span>
                                          </label>
                                      </p>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div className="mt-5">
              <button className="btn btn-[#53138D] bg-[#8c2d915d] w-100 d-block" data-dismiss="modal" aria-label="Close">Proceed</button>
          </div>

        </div>
       
       
      </GlobalModal>

      <GlobalModal
        open={msModalOpen}
        onClose={handleCloseMsModal}
        position='absolute'
        top= '50%'
        left= '50%'
        transform= 'translate(-50%, -50%)'
        width= '740px !important'
        height= 'auto !important'
        maxHeight= '50vh !important'
        overflow='auto'
        bgcolor='#fff'
        border= '1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow= '24'
        p='25px'
      >
        <div  className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5 style={{
              fontSize: '19px',
              fontWeight: '700',

            }}>Filter</h5>
            <span onClick={handleCloseMsModal} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>
          <div className={`${styles.modal_content} row`}>
            <div className="col-md-6">
                <div className="allprod_wrapper">
                    <div className="modal-headr" style={{border: 'none'}}>
                        <h5 className="modal-title" id="exampleModalLabel">Products</h5>
                    </div>
                    <div className={`${styles.modal_body}`}>
                        <div className="row my-0 py-0">
                            <div className="col-7 py-0 my-0">
                                <label htmlFor="allprod">
                                    <input type="checkbox" name="allprod" id="allprod" />
                                        &nbsp;&nbsp;All Products
                                </label>
                            </div>
                            <div className="col-5 py-0 my-0">
                                <span className={`${styles.span}`} data-toggle="collapse" data-target="#collapseExample04" aria-expanded="false" aria-controls="collapseExample">
                                    <label>
                                        <input type="checkbox" name="" />
                                        <span>
                                            <i className="bi bi-chevron-down"></i>
                                        </span>
                                    </label>
                                </span>
                            </div>
                        </div>

                        <div className="collapse" id="collapseExample04">
                          <div className="card card-body pl-5 pb-0" style={{border: 'none'}}>
                            <p><input type="text" className="form-control" placeholder="Search..." name="" id="" /></p>
                            <p>
                                <label htmlFor="milo1">
                                    <input type="checkbox" name="milo1" id="milo1" />
                                    &nbsp;&nbsp;<span>Nestle Milo</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="water1">
                                    <input type="checkbox" name="water1" id="water1" />
                                    &nbsp;&nbsp;<span>Pure life table water</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="nescafe1">
                                    <input type="checkbox" name="nescafe1" id="nescafe1" />
                                    &nbsp;&nbsp;<span>Nescafe</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="kitkat1">
                                    <input type="checkbox" name="kitkat1" id="kitkat1" />
                                    &nbsp;&nbsp;<span>Kitkat</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="maggi1">
                                    <input type="checkbox" name="maggi1" id="maggi1" />
                                    &nbsp;&nbsp;<span>Maggi</span>
                                </label>
                            </p>
                          </div>
                        </div>
                    </div>
                </div>
                <div className="allprod_wrapper">
                    <div className={`${styles.modal_body}`}>
                        <div className="row my-0 py-0">
                            <div className="col-7 py-0 my-0">
                                <label htmlFor="allprod">
                                    <input type="checkbox" name="allprod" id="allprod" />
                                        &nbsp;&nbsp;All Category
                                </label>
                            </div>
                            <div className="col-5 py-0 my-0">
                                <span className={`${styles.span}`} data-toggle="collapse" data-target="#collapseExample01" aria-expanded="false" aria-controls="collapseExample">
                                    <label>
                                        <input type="checkbox" name="" />
                                        <span>
                                            <i className="bi bi-chevron-down"></i>
                                        </span>
                                    </label>
                                </span>
                            </div>
                        </div>

                        <div className="collapse" id="collapseExample01">
                          <div className="card card-body pl-5 pb-0" style={{border: 'none'}}>
                            <p><input type="text" className="form-control" placeholder="Search..." name="" id="" /></p>
                            <p>
                                <label htmlFor="milo1">
                                    <input type="checkbox" name="milo1" id="milo1" />
                                    &nbsp;&nbsp;<span>Beverages</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="water1">
                                    <input type="checkbox" name="water1" id="water1" />
                                    &nbsp;&nbsp;<span>Snacks</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="nescafe1">
                                    <input type="checkbox" name="nescafe1" id="nescafe1" />
                                    &nbsp;&nbsp;<span>Milk</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="kitkat1">
                                    <input type="checkbox" name="kitkat1" id="kitkat1" />
                                    &nbsp;&nbsp;<span>Table Water</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="maggi1">
                                    <input type="checkbox" name="maggi1" id="maggi1" />
                                    &nbsp;&nbsp;<span>Groceries</span>
                                </label>
                            </p>
                          </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.bordered_sect} col-md-6`} style={{
              borderLeft: '2px solid #ddd'
            }}>
                <div className="allprod_wrapper">
                    <div className="modal-header" style={{border: 'none'}}>
                        <h5 className="modal-title" id="exampleModalLabel">Location/Store</h5>
                    </div>
                    <div className={`${styles.modal_body}`}>
                        <p><input type="text" className="form-control" placeholder="Search..." name="" id="" /></p>
                        <div className="row my-0 py-0">
                            <div className="col-5 py-0 my-0">
                                <label htmlFor="country">
                                    <input type="checkbox" name="country" id="country" />
                                    &nbsp;&nbsp;Nigeria
                                </label>
                            </div>
                            <div className="col-5 py-0 my-0">
                                <span className={`${styles.span}`} data-toggle="collapse" data-target="#collapseExample02" aria-expanded="false" aria-controls="collapseExample">
                                    <label>
                                        <input type="checkbox" name="" />
                                        <span>
                                            <i className="bi bi-chevron-down"></i>
                                        </span>
                                    </label>
                                </span>
                            </div>
                        </div>

                        <div className="collapse pl-1" id="collapseExample02">
                            <div className="card card-body pl-5 pb-0" style={{border: 'none'}}>
                                <p><input type="text" className="form-control" placeholder="Search..." name="" id="" /></p>
                                <div className="row mt-3">
                                    <div className="col-5 py-0 my-0">
                                        <label htmlFor="state">
                                            <input type="checkbox" name="state" id="state" />
                                            &nbsp;&nbsp;Lagos
                                        </label>
                                    </div>
                                    <div className="col-5 py-0 my-0">
                                        <span className={`${styles.span}`} data-toggle="collapse" data-target="#collapseExample03" aria-expanded="false" aria-controls="collapseExample">
                                            <label>
                                                <input type="checkbox" name="" />
                                                <span>
                                                    <i className="bi bi-chevron-down"></i>
                                                </span>
                                            </label>
                                        </span>
                                    </div>
                                </div>

                                <div className="collapse pl-1" id="collapseExample03">
                                  <div className="card card-body pl-5 pb-0" style={{border: 'none'}}>
                                      <p><input type="text" className="form-control" placeholder="Search..." name="" id="" /></p>
                                      <p>
                                          <label htmlFor="milo2">
                                              <input type="checkbox" name="milo2" id="milo2" />
                                              &nbsp;&nbsp;<span>Mama tee superstore</span>
                                          </label>
                                      </p>
                                      <p>
                                          <label htmlFor="water2">
                                              <input type="checkbox" name="water2" id="water2" />
                                              &nbsp;&nbsp;<span>Shoprite</span>
                                          </label>
                                      </p>
                                      <p>
                                          <label htmlFor="nescafe2">
                                              <input type="checkbox" name="nescafe2" id="nescafe2" />
                                              &nbsp;&nbsp;<span>Justrite</span>
                                          </label>
                                      </p>
                                      <p>
                                          <label htmlFor="kitkat2">
                                              <input type="checkbox" name="kitkat2" id="kitkat2" />
                                              &nbsp;&nbsp;<span>Mallmart</span>
                                          </label>
                                      </p>
                                      <p>
                                          <label htmlFor="maggi2">
                                              <input type="checkbox" name="maggi2" id="maggi2" />
                                              &nbsp;&nbsp;<span>Jendol</span>
                                          </label>
                                      </p>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div className="mt-5">
              <button className="btn btn-[#53138D] bg-[#8c2d915d] w-100 d-block" data-dismiss="modal" aria-label="Close">Proceed</button>
          </div>

        </div>
       
       
      </GlobalModal>

      <GlobalModal
        open={sbpModalOpen}
        onClose={handleCloseSbpModal}
        position='absolute'
        top= '50%'
        left= '50%'
        transform= 'translate(-50%, -50%)'
        width= '740px !important'
        height= 'auto !important'
        maxHeight= '50vh !important'
        overflow='auto'
        bgcolor='#fff'
        border= '1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow= '24'
        p='25px'
      >
        <div  className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5 style={{
              fontSize: '19px',
              fontWeight: '700',

            }}>Filter</h5>
            <span onClick={handleCloseSbpModal} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>
          <div className={`${styles.modal_content} row`}>
            <div className="col-md-6">
                <div className="allprod_wrapper">
                    <div className="modal-header" style={{border: 'none'}}>
                        <h5 className="modal-title" id="exampleModalLabel">All Products</h5>
                    </div>
                    <div className={`${styles.modal_body}`}>
                        <div className="row my-0 py-0">
                            <div className="col-7 py-0 my-0">
                                <label htmlFor="allprod">
                                    <input type="checkbox" name="allprod" id="allprod" />
                                        &nbsp;&nbsp;All Products
                                </label>
                            </div>
                            <div className="col-5 py-0 my-0">
                                <span className={`${styles.span}`} data-toggle="collapse" data-target="#collapseExample04" aria-expanded="false" aria-controls="collapseExample">
                                    <label>
                                        <input type="checkbox" name="" />
                                        <span>
                                            <i className="bi bi-chevron-down"></i>
                                        </span>
                                    </label>
                                </span>
                            </div>
                        </div>

                        <div className="collapse" id="collapseExample04">
                          <div className="card card-body pl-5 pb-0" style={{border: 'none'}}>
                            <p><input type="text" className="form-control" placeholder="Search..." name="" id="" /></p>
                            <p>
                                <label htmlFor="milo1">
                                    <input type="checkbox" name="milo1" id="milo1" />
                                    &nbsp;&nbsp;<span>Nestle Milo</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="water1">
                                    <input type="checkbox" name="water1" id="water1" />
                                    &nbsp;&nbsp;<span>Pure life table water</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="nescafe1">
                                    <input type="checkbox" name="nescafe1" id="nescafe1" />
                                    &nbsp;&nbsp;<span>Nescafe</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="kitkat1">
                                    <input type="checkbox" name="kitkat1" id="kitkat1" />
                                    &nbsp;&nbsp;<span>Kitkat</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="maggi1">
                                    <input type="checkbox" name="maggi1" id="maggi1" />
                                    &nbsp;&nbsp;<span>Maggi</span>
                                </label>
                            </p>
                          </div>
                        </div>
                    </div>
                </div>
                <div className="allprod_wrapper">
                    <div className={`${styles.modal_body}`}>
                        <div className="row my-0 py-0">
                            <div className="col-7 py-0 my-0">
                                <label htmlFor="allprod">
                                    <input type="checkbox" name="allprod" id="allprod" />
                                        &nbsp;&nbsp;All Category
                                </label>
                            </div>
                            <div className="col-5 py-0 my-0">
                                <span className={`${styles.span}`} data-toggle="collapse" data-target="#collapseExample01" aria-expanded="false" aria-controls="collapseExample">
                                    <label>
                                        <input type="checkbox" name="" />
                                        <span>
                                            <i className="bi bi-chevron-down"></i>
                                        </span>
                                    </label>
                                </span>
                            </div>
                        </div>

                        <div className="collapse" id="collapseExample01">
                          <div className="card card-body pl-5 pb-0" style={{border: 'none'}}>
                            <p><input type="text" className="form-control" placeholder="Search..." name="" id="" /></p>
                            <p>
                                <label htmlFor="milo1">
                                    <input type="checkbox" name="milo1" id="milo1" />
                                    &nbsp;&nbsp;<span>Beverages</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="water1">
                                    <input type="checkbox" name="water1" id="water1" />
                                    &nbsp;&nbsp;<span>Snacks</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="nescafe1">
                                    <input type="checkbox" name="nescafe1" id="nescafe1" />
                                    &nbsp;&nbsp;<span>Milk</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="kitkat1">
                                    <input type="checkbox" name="kitkat1" id="kitkat1" />
                                    &nbsp;&nbsp;<span>Table Water</span>
                                </label>
                            </p>
                            <p>
                                <label htmlFor="maggi1">
                                    <input type="checkbox" name="maggi1" id="maggi1" />
                                    &nbsp;&nbsp;<span>Groceries</span>
                                </label>
                            </p>
                          </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.bordered_sect} col-md-6`} style={{
              borderLeft: '2px solid #ddd'
            }}>
                <div className="allprod_wrapper">
                    <div className="modal-header" style={{border: 'none'}}>
                        <h5 className="modal-title" id="exampleModalLabel">Location/Store</h5>
                    </div>
                    <div className={`${styles.modal_body}`}>
                        <p><input type="text" className="form-control" placeholder="Search..." name="" id="" /></p>
                        <div className="row my-0 py-0">
                            <div className="col-5 py-0 my-0">
                                <label htmlFor="country">
                                    <input type="checkbox" name="country" id="country" />
                                    &nbsp;&nbsp;Nigeria
                                </label>
                            </div>
                            <div className="col-5 py-0 my-0">
                                <span className={`${styles.span}`} data-toggle="collapse" data-target="#collapseExample02" aria-expanded="false" aria-controls="collapseExample">
                                    <label>
                                        <input type="checkbox" name="" />
                                        <span>
                                            <i className="bi bi-chevron-down"></i>
                                        </span>
                                    </label>
                                </span>
                            </div>
                        </div>

                        <div className="collapse pl-1" id="collapseExample02">
                            <div className="card card-body pl-5 pb-0" style={{border: 'none'}}>
                                <p><input type="text" className="form-control" placeholder="Search..." name="" id="" /></p>
                                <div className="row mt-3">
                                    <div className="col-5 py-0 my-0">
                                        <label htmlFor="state">
                                            <input type="checkbox" name="state" id="state" />
                                            &nbsp;&nbsp;Lagos
                                        </label>
                                    </div>
                                    <div className="col-5 py-0 my-0">
                                        <span className={`${styles.span}`} data-toggle="collapse" data-target="#collapseExample03" aria-expanded="false" aria-controls="collapseExample">
                                            <label>
                                                <input type="checkbox" name="" />
                                                <span>
                                                    <i className="bi bi-chevron-down"></i>
                                                </span>
                                            </label>
                                        </span>
                                    </div>
                                </div>

                                <div className="collapse pl-1" id="collapseExample03">
                                  <div className="card card-body pl-5 pb-0" style={{border: 'none'}}>
                                      <p><input type="text" className="form-control" placeholder="Search..." name="" id="" /></p>
                                      <p>
                                          <label htmlFor="milo2">
                                              <input type="checkbox" name="milo2" id="milo2" />
                                              &nbsp;&nbsp;<span>Mama tee superstore</span>
                                          </label>
                                      </p>
                                      <p>
                                          <label htmlFor="water2">
                                              <input type="checkbox" name="water2" id="water2" />
                                              &nbsp;&nbsp;<span>Shoprite</span>
                                          </label>
                                      </p>
                                      <p>
                                          <label htmlFor="nescafe2">
                                              <input type="checkbox" name="nescafe2" id="nescafe2" />
                                              &nbsp;&nbsp;<span>Justrite</span>
                                          </label>
                                      </p>
                                      <p>
                                          <label htmlFor="kitkat2">
                                              <input type="checkbox" name="kitkat2" id="kitkat2" />
                                              &nbsp;&nbsp;<span>Mallmart</span>
                                          </label>
                                      </p>
                                      <p>
                                          <label htmlFor="maggi2">
                                              <input type="checkbox" name="maggi2" id="maggi2" />
                                              &nbsp;&nbsp;<span>Jendol</span>
                                          </label>
                                      </p>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div className="mt-5">
              <button className="btn btn-[#53138D] bg-[#8c2d915d] w-100 d-block" data-dismiss="modal" aria-label="Close">Proceed</button>
          </div>

        </div>
       
       
      </GlobalModal>
    </>
  );
}
