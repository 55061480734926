import { useState } from 'react';
import { useDropDowns } from 'src/hooks/useDropDowns';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import bus from 'src/bus';
let data = {
  'All time': 'all',
  Today: 'today',
  'Last Week': 'pastweek',
  'Past 30 Days': '30days',
  'Past 90 Days': '60days',
  'Past 6 Months': '6months',
  'Past 1 Year': '1year',
};

//  all || pastweek || 30days || 60days || 90days || 6months
export default function Filter2({ width, show }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const dropdownFilters = useDropDowns();

  const open = Boolean(anchorEl);
  const [selectedFilter, setValue] = useState(dropdownFilters[0]?.name);
  const handleClickList = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleFilterList = () => {
    bus.emit('filterList');
  };
  const handleSelectFilter = (val, el, i) => {
    switch (i) {
      case 0:
        setAnchorEl(null);
        setValue(el);
        // if (onChange) {
        //   onChange(data[val]);
        // }
        bus.emit('dataFilter', val);

        break;

      default:
        break;
    }
  };

  return (
    <div
      className="text-rifght"
      style={{ width: width, background: '#EFF6FF', color: '#D97706' }}
    >
      <div className="d-flex w-100">
        {show && (
          <div className=" text-left d-flex">
            <span className="ml-4" style={{ fontSize: '12px' }}>
              Filter Product
            </span>
            &nbsp;
            <span onClick={handleFilterList} className="text-white">
              <img
                style={{ cursor: 'pointer' }}
                src="/assets/icons/darkTrigger.svg"
                alt=""
              />
            </span>
            {/* <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                  "aria-labelledby": "basic-button",
                  }}
              >
                  {dropdownFilters.map((item, i) => (
                  <MenuItem
                      key={i}
                      onClick={() => handleSelectFilter(item.key, item.name, 0)}
                  >
                      {item.name}
                  </MenuItem>
                  ))}
              </Menu> */}
          </div>
        )}
        <div className="text-right ml-auto d-flex">
          <span className="" style={{ fontSize: '12px' }}>
            {selectedFilter === 'Today'
              ? 'Performance for'
              : selectedFilter === 'All time'
              ? 'Performance in'
              : 'Performance in the'}
          </span>
          &nbsp;
          <span style={{ fontSize: '12px' }}>{selectedFilter}</span>
          &nbsp;
          <span onClick={handleClickList} className="text-white">
            <img
              style={{ cursor: 'pointer' }}
              src="/assets/icons/darkTrigger.svg"
              alt=""
            />
          </span>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {dropdownFilters.map((item, i) => (
              <MenuItem
                key={i}
                onClick={() => handleSelectFilter(item.key, item.name, 0)}
              >
                {item.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
      {/* <div className="text-right w-100">
        </div> */}
    </div>
  );
}
