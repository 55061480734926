import { useAuth } from "src/hooks/useAuth";
import Brand from "./BrandOwner";
import Retailer from "./Retailer";

export default function Wallet() {
  const {data} = useAuth();

    
    return data.merchant_type === "brand" ?  (
        <Brand />  
    ) : (
      <Retailer />
    )
}