

// ----------------------------------------------------------------------
import styles from "../BrandOwner.module.css"
import { useEffect, useState } from "react"
import TextField from '@mui/material/TextField';
import DiscountModal from "./DiscountModal";
import bus from 'src/bus';
import axiosInstance from "src/axiosInstance"
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import GlobalModal from "src/Modals/GlobalModal";
import LiveLocation from "src/pages/LiveLocation";
import { toDate, utcToZonedTime } from 'date-fns-tz';
import { parseISO } from 'date-fns';
import { InputFile } from "../../../components";


// sections
// mock
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { createRef } from "react";
// ----------------------------------------------------------------------
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useCategoriesApi } from "src/context/CategoriesApi";
import toast from 'react-hot-toast';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const style0 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#FFFFFF',
    border: '1px solid #F5F5F5',
    borderRadius: '5px',
    boxShadow: 24,
    p: 3,
};
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 190,
    bgcolor: '#FFFFFF',
    border: '1px solid #F5F5F5',
    borderRadius: '5px',
    boxShadow: 24,
    p: 1,
};
const styleOpenGoToCart = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 210,
    bgcolor: '#FFFFFF',
    border: '1px solid #F5F5F5',
    borderRadius: '5px',
    boxShadow: 24,
    p: 1,
};
const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));
const style1 = {
    position: 'absolute',
    height: '700px',
    overflow: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: '#FFFFFF',
    border: '1px solid #F5F5F5',
    borderRadius: '5px',
    boxShadow: 24,
    backgroundColor: '#FFFFFF',
    p: 3,
};

// ptvp=PAGE TO VIEW (PRODUCT)

let app_view = localStorage.getItem('ptvp') ?? 0;
export default function ProductsModal() {
    let textInput = createRef();
    const categoriesApi = useCategoriesApi();
    const [open, setOpen] = useState(false);
    const [notify, setNotify] = useState(false);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false);
    const [showDiscount, setShowDiscount] = useState(false);
    const [openGoToCart, setopenGoToCart] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [action_type, setActionType] = useState('Add')
    const [editData, setEditData] = useState('');
    const [editDataValue, setEditDataValue] = useState({});
    const [brand_email, setBrandEmail] = useState(JSON.parse(localStorage.getItem("brand_email")));
    const [createdProduct, setCreatedProduct] = useState({});
    const [value, setValue] = useState('1');
    const [modalOpen, setModalOpen] = useState(false);
    const [viewProductModalOpen, setViewProductModalOpen] = useState(false);
    const handleClose4 = () => setModalOpen(false);
    const handleCLoseViewProductModal = () => setViewProductModalOpen(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [originalFile, setOriginalFile] = useState(null);
    const [previewData, setPreviewData] = useState(null);
    const [audience, setSelectedAudience] = useState({});
    const [selectedProductIds, setSelectedProductIdss] = useState('')
    const [selectedProductId, setSelectedProductIds] = useState([])
    const [selectedProductName, setSelectedProductName] = useState([])
    const [locationProcess, setProcessLocation] = useState(false);
    const [locationValue, setLocationValue] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState(0)
    const [discountPercentage2, setDiscountPercentage2] = useState(0)
    const [discountCheck, setDiscountCheck] = useState(false)

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    const [status, setStatus] = useState('select');

    const [newProducts, setNewProducts] = useState({
        notifications: [],
        product_name: '',
        product_image: null,
        price_per_case: '',
        price_per_product: '',
        number_of_cases: '',
        products_in_case: '',
        description: '',
        cases_type: 'Cartons',
        category_id: '',
        product_category: ''

    });
    const handleGoToCartClose = () => setopenGoToCart(false);
    const [open1, setOpen1] = useState(false);
    const handleClose1 = () => {
        setNewProducts({
            notifications: [],
            product_name: '',
            product_image: null,
            price_per_case: '',
            price_per_product: '',
            number_of_cases: '',
            products_in_case: '',
            description: '',
            cases_type: 'Cartons',
            category_id: '',
            product_category: ''

        })
        setActionType('Add')
        setUrl('')
        setOpen1(false)
    };
    const [category, setCategory] = useState(1);
    const [url, setUrl] = useState('');
    const [anchorEl1, setAnchorEl1] = useState(null);
    const notifyError = (err) => toast.error(err);
    const notifyToast = (err) => toast.success(err);

    // Amount off 
    const amountOffProduct = () => {
        setDiscountPercentage(0)
        setDiscountCheck(false)
        const discount_budget = Number(editDataValue?.discount_id?.proposed_discount_budget?.$numberDecimal)
        const proposed_market_price = Number(editDataValue?.discount_id?.proposed_market_price?.$numberDecimal)
        const max_sales_quantity = Number(editDataValue?.discount_id?.max_sales_quantity)

        if (discount_budget < 1 || proposed_market_price < 1 || max_sales_quantity < 1) return;
        const calcPercent = ((discount_budget / max_sales_quantity) /
            proposed_market_price) * 100

        const check = (calcPercent / 100) * proposed_market_price;
        if (check > proposed_market_price) {
            return setDiscountCheck(true)
        }

        setDiscountPercentage(Math.ceil(calcPercent))
    }

    useEffect(() => {
        amountOffProduct()
        calculateDiscountOccurrence()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        editDataValue?.discount_id?.proposed_discount_budget?.$numberDecimal,
        editDataValue?.discount_id?.proposed_market_price?.$numberDecimal,
        editDataValue?.discount_id?.max_sales_quantity?.$numberDecimal
    ])

    // Buy X get Y
    const calculateDiscountOccurrence = () => {
        setDiscountPercentage2(0)
        const discount_budget = Number(editDataValue?.discount_id?.proposed_discount_budget?.$numberDecimal)
        const proposed_market_price = Number(editDataValue?.discount_id?.proposed_market_price?.$numberDecimal)

        if (discount_budget < 1 || proposed_market_price < 1) return;
        const discount_occurrence = discount_budget / proposed_market_price;
        if (discount_occurrence < 1) return 0;
        if (Math.sign(discount_occurrence) === -1) return 0;

        setDiscountPercentage2(Math.ceil(discount_occurrence))
    };

    const handleProductChange = (key, value) => {
        setAnchorEl1(null);
        setNewProducts((prev) => {
            return {
                ...prev,
                [key]: value,
            };
        }
        );
    };
    useEffect(() => {
        console.log(categoriesApi);
    }, [categoriesApi])

    const processLocation = (e) => {
        console.log(e);
        const value = {
            target: {
                name: 'location',
                value: e.formatted_address
            }
        }
        setLocationValue(e.formatted_address)
    }

    const handleProductSubmit = (e) => {
        e.preventDefault();
        if(!editDataValue?.discount_id?.selected_audience?.location?.names?.length) {
            editDataValue.discount_id.selected_audience.location?.names.push(locationValue)
        }
        
        setIsPending(true);
        const formData = new FormData();
        formData.append("product_image", editDataValue.product_name);

        Object.keys(editDataValue).forEach(key => formData.append(key, editDataValue[key]));
        setIsPending(true);
        let endpoint = action_type === 'Approve' ? `/admin/product/approve/${editData}` : `/brand/product/update-product/${editDataValue._id}`
        axiosInstance[action_type === 'Approve' ? 'put' : 'post'](endpoint, formData)
            .then(res => {
                setOpen1(false);
                setopenGoToCart(true)
                bus.emit('reload');
                setCreatedProduct(res.data.data)
                notifyToast('Product ' + (action_type === 'Approve' ? ' Approved ' : ' Edited ') + 'successfully')

            }).catch(err => {

                try {
                    notifyError(err.response.data.message);

                } catch (error) {
                    notifyError("Unable to save, try again later");

                }

            }).finally(() => setIsPending(false))

    }

    const handleFile = (e) => {
        if (e.target.files[0]) {
            setUrl(URL.createObjectURL(e.target.files[0]))
            handleProductChange('product_image', e.target.files[0])

        }


    }
    const handleButtonClick = () => {

        textInput.current.click()
    }

    const handleChange = (event) => {
        setCategory(event.target.value);
    };

    const discountPage = () => {
        handleGoToCartClose()
        setShowDiscount(true)
        handleClose1()
        bus.emit('open-discount', createdProduct);
    }

    const handleDiscountChange = (e) => {
        // console.log(e);
        setEditDataValue((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const authData = JSON.parse(localStorage.getItem('auth'));

    const handleFileUpload = (file) => {
        if (file) {
            setOriginalFile(file);
            setNewProducts((prev) =>{
                return {
                    ...prev,
                    uploaded_po: file
                }
            })
    
          const reader = new FileReader();
          reader.onload = () => {
            setPreviewData(reader.result);
          };
    
          reader.readAsDataURL(file);
        }
    };

    const discountSubmit = (e) => {
        // console.log(editDataValue);
        e.preventDefault()
        setIsPending(true);
        var endpoint = authData.user_type === 'admin' ? '/admin/product/create-discount' : `/brand/discount/create/${editDataValue?.discount_id.product_id}`
        axiosInstance.post(endpoint, {
            product_id: editDataValue?.discount_id.product_id,
            discount_type: editDataValue?.discount_id?.discount_type,
            proposed_market_price: editDataValue?.discount_id?.proposed_market_price?.$numberDecimal,
            discount_price: editDataValue?.discount_id?.proposed_discount_budget,
            discount_start_date: editDataValue?.discount_id?.discount_start_date,
            discount_end_date: editDataValue?.discount_id?.discount_end_date,
            discount_occurrence: editDataValue?.discount_id?.discount_occurrence,
            discount_product_quantity: editDataValue?.discount_id?.discount_product_quantity,
            max_sales_quantity: editDataValue?.discount_id?.max_sales_quantity,
            selected_audience: audience,
            tag_id: [],
        })
            .then(res => {
                notifyToast('Discount successfully added')
                bus.emit('reload');
                // setProduct(1)
                setEditDataValue({
                    discount_type: 'emt',
                    proposed_market_price: 0,
                    proposed_discount_budget: 0,
                    max_sales_quantity: 0,
                    discount_start_date: null,
                    discount_end_date: null,
                    discount_product_quantity: 0,
                    discount_occurrence: 0,
                    bonus_product_id: '',
                    location: '',
                    po_amount:'',
                    productSelect: '',
                    customers: '',
                    min_price: 0,
                    max_price: 0,
                    min_order: 0,
                    max_order: 0,
                    min_unit: 0,
                    max_unit: 0,
                })

                setOpen1(false);
                // setDiscountP(false);
                // setSummary(false);
                // setopenGoToCart(true)

            }).catch(err => {

                try {
                    notifyError(err.response.data.message);

                } catch (error) {
                    notifyError("Unable to save, try again later");

                }

            }).finally(() => setIsPending(false))
    }

    const handleProceed = () => {
        setModalOpen(false);
        setSelectedAudience({
            // product_id: product?.edit?.discount_id?.product_id,
            location: locationProcess,
            product: selectedProductIds,
            // customer_type: discount.customers,
            customer_type: editDataValue?.customers,
            date: {
                start: editDataValue?.discount_id?.discount_start_date,
                end: editDataValue?.discount_id?.discount_end_date,
            },
            price: {
                min: editDataValue?.discount_id?.proposed_discount_budget?.$numberDecimal,
                max: editDataValue?.discount_id?.proposed_market_price?.$numberDecimal,
            },
            order_amount: {
                min: editDataValue?.discount_id?.min_order,
                max: editDataValue?.discount_id?.max_order,
            },
            order_unit: {
                min: editDataValue?.discount_id?.min_order,
                max: editDataValue?.discount_id?.max_order
            },
        })
    }

    const handleProductSelect = (selectedProduct) => {
        const isSelected = selectedProductId.includes(selectedProduct._id.toString());
        const isSelected2 = selectedProductName.includes(selectedProduct?.category_name.toString());

        const selectedCategory = selectedProduct?.category_name;
        const selectedCategoryId = selectedProduct._id;
        if (isSelected) {
            notifyError('Product already exist')
        }
        else {
            // selectedProductIds.push(selectedProduct._id.toString());
            setSelectedProductIdss(selectedCategoryId)
            setSelectedProductIds((prevSelectedCategories) => [
                ...prevSelectedCategories,
                selectedCategoryId,
            ]);
        }

        if (!isSelected2) {
            // selectedProductNames.push(selectedProduct.category_name.toString());
            setSelectedProductName((prevSelectedCategories) => [
                ...prevSelectedCategories,
                selectedCategory,
            ]);
        }
        // console.log(selectedProductId);
        // console.log(selectedProductName);
        // Any other logic related to handling the selection can be added here
    };

    const [fetchAllProduct, setFetchAllProduct] = useState([])
    fetchAllProduct.sort((a, b) => a.product_name.localeCompare(b.product_name));


    const fetchProduct = () => {
        axiosInstance.get('/admin/product')
            .then(res => {
                if (res.status < 300) {
                    setFetchAllProduct(res.data.data)
                }
            }).catch(err => {
                try {
                    notifyError(err.response.message)
                } catch {
                    notifyError('Something went wrong')
                }
            })
    }

    useEffect(() => {
        fetchProduct()
    }, [])


    const fetchData = () => {
        axiosInstance.get('/admin/product/' + editData)
            .then(res => {
                // console.log(res.data.data);
                if (res.status < 300) {
                    setEditDataValue(res.data.data)
                    if (editDataValue?.discount_id?.discount_start_date) {
                        const parsedStartDate = toDate(editDataValue.discount_id.discount_start_date);
                        const startDateWithTimezone = utcToZonedTime(parsedStartDate, 'Africa/Lagos');
                        setStartDate(startDateWithTimezone);
                    }
                    if (editDataValue?.discount_id?.discount_end_date) {
                        const parsedStartDate = toDate(editDataValue.discount_id.discount_end_date);
                        const endDateWithTimezone = utcToZonedTime(parsedStartDate, 'Africa/Lagos');
                        setEndDate(endDateWithTimezone);
                    }
                }
            })
        // .catch(err => {
        //     try{
        //         notifyError(err.response.message)
        //     }
        //     catch{
        //         notifyError('Something went wrong, please try again later.')
        //     }
        // })
    }

    useEffect(() => {
        fetchData()
    }, [editData])


    useEffect(() => {
        bus.on('open-product-await-approval', (val) => {
            setEditData(val)

            // console.log(brand_email);
            setActionType('Approve')

            // fetchData()
            if (val.hasOwnProperty('edit') && Object.keys(val.edit).length > 0) {
                setNewProducts(prev => {
                    return {
                        ...val.edit,
                        price_per_case: val.edit.price_per_case?.$numberDecimal,
                        price_per_product: val.edit.price_per_product?.$numberDecimal,
                    }
                })
                setUrl(val.edit.product_image)
                setActionType('Edit')

            }
            setOpen1(true)
        });

        bus.on('open-category', () => {
            setOpen(true)
        })

        return () => {

        };
    }, []);

    useEffect(() => {
        handleProductChange('category_id', editDataValue?.product_category?._id)
        // console.log(editData);
        setTimeout(() => {
            let inputs = document.querySelectorAll('.categoriesList');
            inputs.forEach((el, i) => {
                if (el.value === editData) el.setAttribute("checked", true)
            })
        }, 500);

    }, [editData])


    return (
        <>

            {/* Modals */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableEnforceFocus
            >
                <Fade in={open}>
                    <Box className={`mobile_modal_size`} sx={style0}>
                        <div className={`${styles.modal_content}`}>
                            <div className={`${styles.modal_header}`}>
                                <h3>Choose  Category</h3>
                                <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                                    <img src="/assets/icons/x.svg" alt="" />

                                </span>

                            </div>


                            <form action="">
                                <div className="mb-3">
                                    <label htmlFor="name">Category Name</label>
                                    <Select
                                        labelId="s"
                                        id="s"
                                        fullWidth
                                        size="small"
                                        value={category}
                                        required
                                        onChange={handleChange}
                                    >

                                        <MenuItem value={1}>Breveges</MenuItem>
                                        <MenuItem value={2}>Table water</MenuItem>
                                        <MenuItem value={3}>Snacks</MenuItem>
                                    </Select>


                                </div>
                                <div className="mb-4">
                                    <button type="button" onClick={handleClose} className={`${styles.modal_btn} btn btn-block`}>
                                        Add

                                    </button>


                                </div>

                            </form>


                        </div>

                    </Box>
                </Fade>
            </Modal>
            {/* Withdraw */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open1}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableEnforceFocus
            >
                <Fade in={open1}>
                    <Box className={`mobile_modal_size`} sx={style1}>
                        <div className={`${styles.modal_content}`}>
                            <div className={`${styles.modal_header}`}>
                                <h3>Verify Product </h3>
                                <span
                                    onClick={handleClose1}
                                    style={{ cursor: 'pointer' }}>
                                    <img
                                        src="/assets/icons/x.svg"
                                        alt=""
                                    />
                                </span>
                            </div>
                            <p>
                                Verify product of Brands
                            </p>
                            <TabContext value={value}>
                                <TabList onChange={handleTabChange} aria-label="lab API tabs ">
                                    <Tab label="Products Details" value="1" />
                                    <Tab label="Discounts Details " value="2" />
                                </TabList>
                                <TabPanel className="mt-0 pt-0" value="1" style={{ paddingLeft: '0' }}>
                                    <form onSubmit={handleProductSubmit}>
                                        <p className={` label`}>Product Image* </p>
                                        <input type="file" hidden ref={textInput} name="" onChange={handleFile} />
                                        <div className={`${styles.retailer_modal_product_img}`} style={{
                                            backgroundImage: `url('${editDataValue?.product_image}')`,
                                        }}>
                                            <div style={{ cursor: 'pointer', display: 'inline-block' }} onClick={() => handleButtonClick()}>
                                                <img
                                                    className={`${styles.retailer_product_image}`}
                                                    src="/assets/image.svg"
                                                    alt=""

                                                />
                                                <p className="mb-0 text-center text-info" style={{ fontSize: '10px' }} >Click to upload</p>

                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className={`label`} htmlFor="pname">Product Name*</label>
                                            <TextField
                                                type='text'
                                                disabled
                                                id="pname"
                                                value={editDataValue?.product_name}
                                                onChange={(e) => handleProductChange('product_name', e.target.value)}
                                                placeholder=""
                                                size="small"
                                                fullWidth

                                            />


                                        </div>
                                        <div className="mb-3">
                                            <label className={`label`} htmlFor="pname">Price*</label>
                                            <TextField
                                                type='number'
                                                disabled
                                                id="price"
                                                value={editDataValue?.discount_id?.proposed_market_price?.$numberDecimal}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue === '' || parseFloat(inputValue) > 0) {
                                                        handleProductChange('product_price', inputValue);
                                                    }
                                                }}
                                                inputProps={{
                                                    inputMode: 'decimal',
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                placeholder="₦0"
                                                size="small"
                                                fullWidth

                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className={`label`} htmlFor="category">Product Category*</label>
                                            <div>
                                                <div className="categories">
                                                    <input className="categoriesList"
                                                        onChange={(e) => handleProductChange('category_id', e.target.value)}
                                                        checked={editDataValue?.product_category?._id}
                                                        type="radio"
                                                        name="same"
                                                        id="myCheckbox"
                                                    />
                                                    &nbsp;
                                                    {editDataValue?.product_category?.category_name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className={`label`} htmlFor="spec">% Discount</label>
                                            <TextField
                                                type='number'
                                                disabled
                                                id="discount"
                                                value={
                                                    editDataValue?.discount_id?.discount_type === 'amount_off_product' ?
                                                        discountPercentage :
                                                        discountPercentage2
                                                }
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue === '' || parseFloat(inputValue) > 0) {
                                                        handleProductChange('product_discount', inputValue);
                                                    }
                                                }}
                                                inputProps={{
                                                    inputMode: 'decimal',
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                size="small"
                                                fullWidth

                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className={`label`} htmlFor="category">Push Notification</label>
                                            <div>
                                                {
                                                    editDataValue?.discount_id?.selected_audience?.customer_type === 'shoppers' &&
                                                    <div className="d-flex">
                                                        <div className="categories">
                                                            <input
                                                                type="checkbox"
                                                                readOnly
                                                                id="myCheckbox"
                                                                name='name'
                                                                value='shoppers'
                                                                checked={editDataValue?.discount_id?.selected_audience?.customer_type}
                                                                onChange={(e) => handleProductChange('customer_type', e.target.value)}
                                                            />
                                                            &nbsp;
                                                            &nbsp;
                                                            {
                                                                "Shoppers"
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className={`label`} htmlFor="desc">Description </label>
                                            <TextField
                                                id="desc"
                                                disabled
                                                multiline
                                                rows='4'
                                                cols='3'
                                                placeholder=" Best Product"
                                                value={editDataValue?.description}
                                                onChange={(e) => handleProductChange('description', e.target.value)}
                                                size="small"
                                                fullWidth

                                            />
                                        </div>
                                        <div className="mb-4">
                                            {
                                                !editDataValue?.po_docs_id?.po && (
                                                    <div>
                                                        <InputFile disabled show={false} />
                                                        <div className="ml-auto px-3" style={{ bottom: '0px', left: '0', right: '0', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                            <button disabled className="btn btn-light px-2 py-1 mx-2 rounded border-0" style={{ backgroundColor: '#333 !important', fontSize: '14px' }}>
                                                                No PO uploaded
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                editDataValue?.po_docs_id?.po &&
                                                <div>
                                                    <embed src={editDataValue?.po_docs_id?.po} width="500" height="150" />
                                                    <div className="ml-auto px-3" style={{ bottom: '0px', left: '0', right: '0', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                    <a href={editDataValue?.po_docs_id?.po} download target="_blank">
                                                        <button type="button" className="btn btn-light phx-2 py-1 mx-2 rounded border-0" style={{ backgroundColor: '#333 !important', fontSize: '14px' }}>
                                                            <img style={{ width: '15px' }} src="/assets/icons/downloadblue.svg" alt="" /> Download
                                                        </button>
                                                    </a>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="mb-4">
                                            <label className={`label`} htmlFor="pname">PO Amount *</label>
                                            <TextField
                                                required
                                                disabled
                                                type='number'
                                                id="poamount"
                                                value={editDataValue?.po_docs_id?.amount}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue === '' || parseFloat(inputValue) > 0) {
                                                        handleProductChange('po_amount', inputValue);
                                                    }
                                                }}
                                                inputProps={{
                                                    inputMode: 'decimal',
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                size="small"
                                                fullWidth

                                            />
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <button type="button" className={`${styles.modal_btn_outline} btn btn-block`}>
                                                        <a href={`mailto:${brand_email}`}>Send Email</a>
                                                    </button>
                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        !isPending
                                                        &&
                                                        <button type="submit" className={`${styles.modal_btn} btn btn-block`}>
                                                            Verify
                                                        </button>
                                                    }
                                                    {isPending
                                                        &&
                                                        <button disabled className={`btn btn-block ${styles.modal_btn}`}>
                                                            Submitting &nbsp;
                                                            {
                                                                isPending &&
                                                                <CircularProgress style={{ color: 'white' }} size="1rem" />
                                                            }
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </TabPanel>
                                <TabPanel className="mt-0 pt-0" value="2" style={{ paddingLeft: '0' }}>
                                    <div className="card mt-2 mb-2">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div>
                                                        <img
                                                            className={`${styles.retailer_product_image}`}
                                                            src={editDataValue?.product_image}
                                                            alt=""
                                                            style={{ width: '100%' }}
                                                        />
                                                        {/* <p className="mb-0 text-center text-info" style={{ fontSize: '10px' }} >Click to upload</p> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <strong>
                                                        {editDataValue?.product_name}
                                                    </strong> <br />
                                                    <small className="text-muted">
                                                        {
                                                            editDataValue?.discount_id?.discount_type === 'amount_off_product' ?
                                                                discountPercentage :
                                                                discountPercentage2
                                                        }%
                                                        {/* 25 Reviews */}
                                                    </small>
                                                </div>
                                                <div className={`${styles.view_product} col-md-4 mt-2`}
                                                    onClick={() => setViewProductModalOpen(true)}
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    View Product
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={handleProductSubmit}>
                                        <div className="mb-3">
                                            <label className={`label`}
                                                htmlFor="pname">
                                                Proposed Price*
                                            </label>
                                            <TextField
                                                required
                                                disabled
                                                type='text'
                                                id="proposedPrice"
                                                value={
                                                    editDataValue?.discount_id ?
                                                        Number(editDataValue?.discount_id?.proposed_market_price?.$numberDecimal)
                                                        :
                                                        0
                                                }
                                                onChange={(e) => handleProductChange('proposed_price', e.target.value)}
                                                size="small"
                                                fullWidth
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className={`label`} htmlFor="pname">Proposed Discount Budget*</label>
                                            <TextField
                                                required
                                                disabled
                                                type='number'
                                                id="pdbudget"
                                                value={
                                                    editDataValue?.discount_id ?
                                                        Number(editDataValue?.discount_id?.proposed_discount_budget?.$numberDecimal)
                                                        :
                                                        0
                                                }
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue === '' || parseFloat(inputValue) > 0) {
                                                        handleProductChange('pro_discount_budget', inputValue);
                                                    }
                                                }}
                                                inputProps={{
                                                    inputMode: 'decimal',
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                placeholder="₦ 1,000,000"
                                                size="small"
                                                fullWidth
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-10">
                                                <div className="mb-3">
                                                    {/* <label className={`label`} htmlFor="spec">Discount Type</label> */}
                                                    <p className="text-muted">
                                                        {
                                                            editDataValue?.discount_id ?
                                                                editDataValue?.discount_id?.discount_type.split("_").map((word) =>
                                                                    word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
                                                                :
                                                                'No Discount'
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="row">
                                                <div className="col-4 text-muted mt-2" style={{
                                                    fontSize: '14px'
                                                }}>
                                                    Duration of Discount
                                                </div>
                                                <div className="col-8">
                                                    <div className="mb-1">
                                                        {/* <label htmlFor="date">Date </label> */}
                                                        <div className=" d-flex">
                                                            <div className="" style={{ width: '100%' }}>
                                                                <div className="mb-3">
                                                                    <DatePicker
                                                                        showIcon
                                                                        disabled
                                                                        selected={editDataValue?.discount_id?.discount_start_date ? parseISO(editDataValue.discount_id.discount_start_date) : null}
                                                                        onChange={(date) => setStartDate(date)}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        className="form-control"
                                                                        placeholderText="Start"
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="px-1 d-flex align-items-center"
                                                                style={{ height: '40px' }}>
                                                                <img src="/assets/icons/line.svg"
                                                                    style={{ width: '30px' }}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className=""
                                                                style={{ width: '100%' }}>
                                                                <div className="mb-3">
                                                                    <DatePicker
                                                                        showIcon
                                                                        disabled
                                                                        selected={editDataValue?.discount_id?.discount_end_date ? parseISO(editDataValue.discount_id.discount_end_date) : null}
                                                                        onChange={(date) => setEndDate(date)}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        className="form-control"
                                                                        placeholderText="End"
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className={`label`} htmlFor="pname">Maximum Sales Quantity*</label>
                                            <TextField
                                                required
                                                disabled
                                                type='number'
                                                id="mmprice"
                                                value={
                                                    editDataValue?.discount_id ?
                                                        Number(editDataValue?.discount_id?.max_sales_quantity)
                                                        :
                                                        0
                                                }
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue === '' || parseFloat(inputValue) > 0) {
                                                        handleProductChange('max_sales_quantity', inputValue);
                                                    }
                                                }}
                                                inputProps={{
                                                    inputMode: 'decimal',
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                size="small"
                                                fullWidth
                                            />
                                            <small className="small text-danger"
                                                style={{ fontWeight: 'lighter ' }}>
                                                Max sales quantity must not be less than 150
                                            </small>
                                        </div>
                                        <div className="mb-3 d-flex justify-content-between">
                                            <div>
                                                <p className="mb-0 text-[#53138D] font-weight-bold" style={{ fontSize: '14px' }}>
                                                    Discount percentages
                                                </p>
                                                <small className="text-danger small">
                                                    This percentage is subject to change based
                                                    on the market research undertaken
                                                </small>
                                            </div>
                                            <span className="text-[#53138D]">
                                                {
                                                    editDataValue?.discount_id?.discount_type === 'amount_off_product' ?
                                                        discountPercentage :
                                                        discountPercentage2
                                                }%
                                            </span>
                                        </div>
                                        <div className={`mb-3 ${styles.push_notification_icon}`}>
                                            <label className={`${styles.modal_product_title}`}
                                                htmlFor="push">
                                                Push Notification
                                            </label>
                                            <div className="row">
                                                <div className="col-md-6 mb-1">
                                                    <div className="mb-3">
                                                        <LiveLocation label='Location' 
                                                            passData={processLocation} 
                                                        />
                                                        {/* <div className="products mt-2">
                                                            {editDataValue?.discount_id?.brand_discount_audience?.location?.names?.length > 0 &&
                                                                editDataValue?.discount_id?.brand_discount_audience?.location?.names?.map((el, i) => (
                                                                    <div className={`${styles.product_selected} mb-1`}>
                                                                        <div className="d-flex justify-content-between">
                                                                            <span>{el}</span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-1">
                                                    <div>
                                                        <div>
                                                            <label htmlFor="loc">Customers</label>
                                                            <TextField
                                                                disabled
                                                                id="push"
                                                                type="text"
                                                                value={editDataValue?.discount_id?.selected_audience?.customer_type}
                                                                size="small"
                                                                fullWidth
                                                            />
                                                            {/* <div className="categories mr-2 mt-2 mb-3">
                                                                {editDataValue?.discount_id?.selected_audience?.customer_type}
                                                            </div> */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <button type="button" className={`${styles.modal_btn_outline} btn btn-block`}>
                                                        <a href={`mailto:${brand_email}`}>Send Email</a>
                                                    </button>
                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        !isPending
                                                        &&
                                                        <button type="submit" className={`${styles.modal_btn} btn btn-block`}>
                                                            Verify
                                                        </button>
                                                    }
                                                    {isPending
                                                        &&
                                                        <button disabled className={`btn btn-block ${styles.modal_btn}`}>
                                                            Submitting &nbsp;
                                                            {
                                                                isPending &&
                                                                <CircularProgress style={{ color: 'white' }} size="1rem" />
                                                            }
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </TabPanel>
                            </TabContext>
                        </div>
                    </Box>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openGoToCart}
                onClose={handleGoToCartClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableEnforceFocus
            >
                <Fade in={openGoToCart}>
                    <Box className={`mobile_modal_size pt-md-3 ${styles.modal_bg}`}
                        sx={styleOpenGoToCart}
                    >
                        <div
                            className={`text-center ${styles.add_to_cart_content}`}>
                            <img
                                src="/assets/icons/Illustration.svg"
                                alt=""
                            />
                            <h5 className="mt-3 font-weight-bold">
                                Product Approved
                            </h5>
                            <p style={{ textAlign: 'center !important' }}>
                                This Product has been successfully Added
                            </p>
                            <div className="pb-2">
                                {action_type === 'Add' &&
                                    <button className={`${styles.go_to} d-block w-100 `}
                                        style={{ fontSize: '14px' }}
                                        onClick={() => discountPage()}
                                    >
                                        Confirm
                                    </button>
                                }
                                {
                                    action_type === 'Approve' &&
                                    <button className={`${styles.go_to} d-block w-100 `}
                                        style={{ fontSize: '14px' }}
                                        onClick={handleGoToCartClose}
                                    >
                                        Confirm
                                    </button>
                                }
                                {
                                    action_type === 'Edit' &&
                                    <button onClick={() => setopenGoToCart(false)}
                                        className={`modal_btn hover btn btn-block`}
                                    >
                                        Go to Product
                                    </button>
                                }
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>

            <GlobalModal
                open={modalOpen}
                onClose={handleClose4}
                position='absolute'
                top='50%'
                left='50%'
                transform='translate(-50%, -50%)'
                width='740px !important'
                overflow='auto'
                bgcolor='#F5F5F5'
                border='1px solid #F5F5F5'
                borderRadius='5px'
                boxShadow='24'
                background='#FFFFFF'
                p='25px'
            >
                <div className={`${styles.modal_content}`}>
                    <div className={`${styles.modal_header} mb-0`}>
                        <h5 style={{
                            fontSize: '19px',
                            fontWeight: '700',

                        }}>Select Audience</h5>
                        <span onClick={handleClose4}
                            style={{ cursor: 'pointer' }}
                        >
                            <img src="/assets/icons/x.svg"
                                alt=""
                            />
                        </span>
                    </div>
                    <form action="" className="mt-0 form_label" >
                        <div className="row">
                            <div className="col-md-6 mb-1">
                                <div className="mb-3">
                                    <label htmlFor="loc">Location</label>
                                    <TextField
                                        id="loc"
                                        disabled
                                        size="small"
                                        fullWidth
                                    />
                                    {/* <LiveLocation disabled label='Location' 
                                        passData={processLocation} 
                                    /> */}
                                    <div className="products mt-2">
                                        {editDataValue?.discount_id?.brand_discount_audience?.location?.names?.length > 0 &&
                                            editDataValue?.discount_id?.brand_discount_audience?.location?.names?.map((el, i) => (
                                                <div className={`${styles.product_selected} mb-1`}>
                                                    <div className="d-flex justify-content-between">
                                                        <span>{el}</span>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-1">
                                <div>
                                    <div>
                                        <label htmlFor="loc">Customers</label>
                                        <Select
                                            disabled
                                            labelId="s"
                                            id="cat"
                                            fullWidth
                                            size="small"
                                            value={editDataValue?.customers}
                                            onChange={handleDiscountChange}
                                            name="customers"
                                        >
                                            <MenuItem value={'select'}>Select Customers</MenuItem>
                                            <MenuItem value={'allshoppers'}>All Shoppers</MenuItem>
                                            {/* <MenuItem value={'Coca Cola'}>Coca Cola</MenuItem> */}
                                        </Select>
                                        <div className="categories mr-2 mt-2 mb-3">
                                            {editDataValue?.discount_id?.brand_discount_audience?.customer_type}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </GlobalModal>

            {/* View Product Modal */}
            {/* <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={viewProductModalOpen} 
                onClose={handleCLoseViewProductModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableEnforceFocus    
            >
                <Fade in={viewProductModalOpen}>
                    <Box className={`mobile_modal_size pt-md-3 ${styles.modal_bg}`} sx={style}>
                        <div className={`${styles.modal_content}`}>
                            <div className={`${styles.modal_header}`}>
                                <h3>{action_type === 'Add' ? 'Add' : ' Edit'} Edit Product </h3>
                                <span onClick={handleCLoseViewProductModal} style={{ cursor: 'pointer' }}>
                                    <img src="/assets/icons/x.svg" alt="" />
                                </span>
                            </div>
                            <form onSubmit={handleProductSubmit}>
                                <p className={` label`}>Product Image* </p>
                                <input type="file" hidden ref={textInput} name="" onChange={handleFile} />
                                <div className={`${styles.retailer_modal_product_img}`} style={{
                                    backgroundImage: `url('${url}')`,


                                }}>
                                    <div style={{ cursor: 'pointer', display: 'inline-block' }} onClick={() => handleButtonClick()}>
                                        <img
                                            className={`${styles.retailer_product_image}`}
                                            src="/assets/image.svg"
                                            alt=""

                                        />
                                        <p className="mb-0 text-center text-info" style={{ fontSize: '10px' }} >Click to upload</p>

                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className={`label`} htmlFor="pname">Product Name*</label>
                                    <TextField
                                        required
                                        type='text'
                                        id="pname"
                                        value={newProducts.product_name}
                                        onChange={(e) => handleProductChange('product_name', e.target.value)}
                                        placeholder=""
                                        size="small"
                                        fullWidth

                                    />


                                </div>
                                <div className="mb-3">
                                    <label className={`label`} htmlFor="pname">Price*</label>
                                    <TextField
                                        required
                                        type='number'
                                        id="price"
                                        value={newProducts.product_price}
                                        onChange={(e) => handleProductChange('product_price', e.target.value)}
                                        placeholder="₦ 1200"
                                        size="small"
                                        fullWidth

                                    />


                                </div>
                                <div className="mb-3">
                                    <label className={`label`} htmlFor="category">Product Category*</label>
                                    <div>
                                        {categoriesApi.map((el, i) => (
                                            <div className="categories" key={i}>
                                                <input className="categoriesList" onChange={(e) => handleProductChange('category_id', e.target.value)} value={el._id} type="radio" name="same" id="myCheckbox" />
                                                &nbsp;
                                                {el.category_name}


                                            </div>

                                        ))}
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className={`label`} htmlFor="spec">Minimum Order Quantity*</label>
                                    <TextField
                                        type='number'
                                        id="category"
                                        value={newProducts.order_quantity}
                                        onChange={(e) => handleProductChange('order_quantity', e.target.value)}
                                        placeholder="20"
                                        size="small"
                                        fullWidth

                                    />


                                </div>
                                <div className="mb-3">
                                    <label className={`label`} htmlFor="spec">% Discount</label>
                                    <TextField
                                        type='number'
                                        id="discount"
                                        value={newProducts.discount}
                                        onChange={(e) => handleProductChange('product_discount', e.target.value)}
                                        placeholder="10"
                                        size="small"
                                        fullWidth

                                    />


                                </div>
                                <label className={`label`} htmlFor="price">Stock * </label>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mb-3" style={{ position: 'relative' }}>
                                            <TextField
                                                required
                                                id="stock"
                                                type="number"
                                                size="small"
                                                value={newProducts.stock}
                                                onChange={(e) => handleProductChange('stock', e.target.value)}
                                                fullWidth

                                            />

                                        </div>

                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className={`label`} htmlFor="desc">Description </label>
                                    <TextField
                                        id="desc"
                                        multiline
                                        rows='4'
                                        cols='3'
                                        placeholder=" Best Product"
                                        value={newProducts.description}
                                        onChange={(e) => handleProductChange('description', e.target.value)}
                                        size="small"
                                        fullWidth

                                    />


                                </div>


                                <div className="mb-3">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <button type="submit" className={`${styles.modal_btn_outline} btn btn-block`}>
                                                Send Email
                                            </button>
                                        </div>
                                        <div className="col-md-6">
                                            <button onClick={handleCLoseViewProductModal} type="submit" className={`${styles.modal_btn} btn btn-block`}>
                                                Close
                                            </button>
                                            
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </Box>
                </Fade>
            </Modal> */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={viewProductModalOpen}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableEnforceFocus
            >
                <Fade in={viewProductModalOpen}>
                    <Box className={`mobile_modal_size`} sx={style1}>
                        <div className={`${styles.modal_content}`}>
                            <div className={`${styles.modal_header}`}>
                                <h3> Edit Product </h3>
                                <span onClick={handleCLoseViewProductModal} style={{ cursor: 'pointer' }}>
                                    <img src="/assets/icons/x.svg" alt="" />
                                </span>
                            </div>
                            <p>
                                Edit product in this category
                            </p>
                            <form onSubmit={handleProductSubmit}>
                                <p className={` label`}>Product Image* </p>
                                <input type="file" hidden ref={textInput} name="" onChange={handleFile} />
                                <div className={`${styles.retailer_modal_product_img}`} style={{
                                    backgroundImage: `url('${editDataValue?.product_image}')`,


                                }}>
                                    <div style={{ cursor: 'pointer', display: 'inline-block' }} onClick={() => handleButtonClick()}>
                                        {/* <img
                                            className={`${styles.retailer_product_image}`}
                                            src={editDataValue?.product_image}
                                            alt=""

                                        /> */}
                                        <p className="mb-0 text-center text-info" style={{ fontSize: '10px' }} >Click to upload</p>

                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className={`label`} htmlFor="pname">Product Name*</label>
                                    <TextField
                                        required
                                        type='text'
                                        id="pname"
                                        value={editDataValue?.product_name}
                                        onChange={(e) => handleProductChange('product_name', e.target.value)}
                                        placeholder=""
                                        size="small"
                                        fullWidth
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className={`label`} htmlFor="pname">Price*</label>
                                    <TextField
                                        required
                                        type='number'
                                        id="price"
                                        value={editDataValue?.price_per_product?.$numberDecimal}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            if (inputValue === '' || parseFloat(inputValue) > 0) {
                                                handleProductChange('product_price', inputValue);
                                            }
                                        }}
                                        inputProps={{
                                            inputMode: 'decimal',
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                e.preventDefault();
                                            }
                                        }}
                                        size="small"
                                        fullWidth

                                    />


                                </div>
                                <div className="mb-3">
                                    <label className={`label`} htmlFor="category">Product Category*</label>
                                    <div>
                                        <div className="categories">
                                            <input className="categoriesList"
                                                onChange={(e) => handleProductChange('category_id', e.target.value)}
                                                checked={editDataValue?.product_category?._id}
                                                type="radio"
                                                name="same"
                                                id="myCheckbox"
                                            />
                                            &nbsp;
                                            {editDataValue?.product_category?.category_name}
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className={`label`} htmlFor="spec">Minimum Order Quantity*</label>
                                    <TextField
                                        type='number'
                                        id="category"
                                        value={editDataValue?.minimum_order_quantity}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            if (inputValue === '' || parseFloat(inputValue) > 0) {
                                                handleProductChange('order_quantity', inputValue);
                                            }
                                        }}
                                        inputProps={{
                                            inputMode: 'decimal',
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                e.preventDefault();
                                            }
                                        }}
                                        placeholder="20"
                                        size="small"
                                        fullWidth

                                    />


                                </div>
                                <div className="mb-3">
                                    <label className={`label`} htmlFor="spec">% Discount</label>
                                    <TextField
                                        type='number'
                                        id="discount"
                                        value={
                                            editDataValue?.discount_id?.discount_type === 'amount_off_product' ?
                                                discountPercentage :
                                                discountPercentage2
                                        }
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            if (inputValue === '' || parseFloat(inputValue) > 0) {
                                                handleProductChange('product_discount', inputValue);
                                            }
                                        }}
                                        inputProps={{
                                            inputMode: 'decimal',
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                e.preventDefault();
                                            }
                                        }}
                                        placeholder="0%"
                                        size="small"
                                        fullWidth

                                    />


                                </div>
                                <label className={`label`} htmlFor="price">Stock * </label>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mb-3" style={{ position: 'relative' }}>
                                            <TextField
                                                required
                                                id="stock"
                                                type="number"
                                                size="small"
                                                value={editDataValue?.number_of_cases}
                                                fullWidth
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                                        handleProductChange('stock', inputValue);
                                                    }
                                                  }}
                                                  inputProps={{
                                                      inputMode: 'decimal',
                                                  }}
                                                  onKeyDown={(e) => {
                                                      if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                          e.preventDefault();
                                                      }
                                                  }}
                                            />

                                        </div>

                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className={`label`} htmlFor="desc">Description </label>
                                    <TextField
                                        id="desc"
                                        multiline
                                        rows='4'
                                        cols='3'
                                        placeholder=""
                                        value={editDataValue?.description}
                                        onChange={(e) => handleProductChange('description', e.target.value)}
                                        size="small"
                                        fullWidth

                                    />


                                </div>


                                <div className="mb-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button onClick={handleCLoseViewProductModal} type="button" className={`${styles.modal_btn} btn btn-block form-control`}>
                                                Close
                                            </button>

                                        </div>
                                    </div>
                                </div>

                            </form>

                        </div>

                    </Box>
                </Fade>
            </Modal>

            <DiscountModal closeModal={() => setShowDiscount(false)} />
        </>
    )
}
