import ComponentRepeater from "./componentRepeater";

const Website = () => {
  

  return (
    <>
      <ComponentRepeater  name = "hero" heading="Hero Section" />
      <div className="mt-3">
        <ComponentRepeater  name = "dealsOfTheDay" heading="Deals Of The Day" />
      </div>
    </>

  );
};

export default Website;
