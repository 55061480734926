import styles from './Order.module.css';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Card, Avatar } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
import Modal from '@mui/material/Modal';
// sections
// mock
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import bus from 'src/bus';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import { BallTriangle } from 'react-loader-spinner';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { forwardRef } from 'react';
import Slide from '@mui/material/Slide';

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  overflow: 'auto',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'details', label: 'Product Details', alignRight: false },
  { id: 'quantity', label: 'Qty', alignRight: false },
  { id: 'unitPrice', label: 'Unit price', alignRight: false },
  { id: 'discount', label: 'Discount', alignRight: false },
  { id: 'customer to pay', label: 'customer to pay', alignRight: false },
  { id: 'totalAmount', label: 'Total amount', alignRight: false },
];

// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderPage({ onChange, categoryId }) {
  const navigate = useNavigate();

  const [modal_desc] = useState('');

  const [newValue] = useState(
    JSON.parse(localStorage.getItem('order_details_info')) || {}
  );
  const [orderData, setOrderData] = useState({});
  const [isPending, setIsPending] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const [refundIds, setRefundIds] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [handleOpenDialog, setHandleOpenDialog] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  // const [orderInfo, setOrderInfo] = useState(JSON.parse(localStorage.getItem('order_info')))

  const notifyError = (err) => toast.error(err);

  function handleClick(event) {
    event.preventDefault();
    localStorage.removeItem('order_details_info');
    navigate('/app/orders');
  }
  const [modalTitle] = useState('');

  const fetchData = () => {
    setIsPending(true);
    axiosInstance
      .get('/shop/order/get-customer-order/' + newValue.order_id)
      .then((res) => {
        if (res.data.data.length) setOrderData(res.data.data[0]);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch orders, please try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const refund = () => {
    setLoadingState(true);
    axiosInstance
      .put('/shop/order/customer-order/refund/' + newValue.order_id, {
        shop_product_ids: refundIds,
      })
      .then((res) => {
        if (res.data.success) {
          setHandleOpenDialog(false);
          setOpenDialog(true);
          navigate('/app/orders');
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to refund order, try again later');
        }
      })
      .finally(() => setLoadingState(false));
  };

  useEffect(() => {
    setTotalAmount(
      orderData?.items
        ?.filter((el, i) => refundIds.includes(el?.shop_product_id))
        .map((el) => Number(el.total_amount))
        .reduce((ac, val) => ac + val, 0) || 0
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refundIds]);

  const handleCheck = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    const refundIdsArray = [...refundIds];

    if (checked) {
      if (!refundIdsArray.includes(value)) refundIdsArray.push(value);
    } else {
      const index = refundIdsArray.indexOf(value);
      refundIdsArray.splice(index, 1);
    }

    setRefundIds(refundIdsArray);
  };

  const getInitials = (val) => {
    const splitName = val?.full_name?.split(' ');
    const initials = splitName
      ?.map((name) => name.charAt(0))
      .join('')
      .toUpperCase();
    return initials;
  };

  return (
    <>
      <div className={`pt-2 px-2`}>
        <button onClick={handleClick} className="btn d-flex pl-0">
          <div
            className="p-1"
            style={{
              border: '1px solid lightgrey',
              borderRadius: '3px',
            }}
          >
            <img src="/assets/icons/Chevronleft.svg" alt="" />
          </div>
          &nbsp;
          <span className="mt-1">Orders</span>
        </button>
        {/* <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
        </Breadcrumbs> */}
        <div className={`${styles.header} mt-5`}>
          <div className="row">
            <div className="col-md-6">
              {!newValue?.image && (
                <div
                  className="float-md-left mr-2 initials"
                  style={{
                    borderRadius: '0px',
                    width: '70px',
                    height: '70px',
                    backgroundColor: '#E5E7EB',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {getInitials(newValue)}
                </div>
              )}
              {newValue?.image && (
                <img
                  src={newValue?.image}
                  height={'70px'}
                  width={'70px'}
                  className="float-md-left mr-3"
                  alt=""
                />
              )}
              <div>
                <h1 style={{ display: 'inline-block' }}>
                  {newValue?.full_name}
                </h1>
                <p className="mb-1">
                  <small>
                    <img
                      className="d-inline-block mr-1"
                      src="/assets/icons/mail2.svg"
                      alt=""
                    />
                    {newValue?.email}
                  </small>
                  &nbsp;&nbsp;&nbsp;
                  <small>
                    <img
                      className="d-inline-block mr-1"
                      src="/assets/icons/phone.svg"
                      alt=""
                    />
                    {orderData ? orderData?.phone_number : '---'}
                  </small>
                </p>
              </div>
            </div>
            {/* <div className="col-md-6 text-right" >
                            {
                                !showCheck &&
                                <button onClick={() => setShowCheck(true)} className={`modal_btn btn `} >
                                    Refund
                                </button>

                            }
                            {
                                showCheck &&
                                <button onClick={() => {
                                    setShowCheck(false); setRefundIds([])
                                }} className={`modal_btn btn `} >
                                    Cancel
                                </button>

                            }
                        </div> */}
          </div>
        </div>

        {/* Single Order view */}
        <Card
          style={{
            marginTop: '50px',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
          className=" ss p-0"
        >
          {/* <Scrollbar style={{ overflowX: 'hidden' }}> */}
          <div className="row" style={{ height: '60vh', overflow: 'hidden' }}>
            <div
              className="col-md-8 mb-1 h-100"
              style={{ overflowX: 'hidden' }}
            >
              <div>
                <div
                  className="pl-4 pr-3 py-3 pb-0 mb-0"
                  style={{
                    position: 'sticky',
                    top: '0',
                    zIndex: '99',
                    background: 'white',
                  }}
                >
                  <h3 className="font-weight-bold">Orders</h3>
                  <small style={{ fontWeight: '200', fontSize: '12px' }}>
                    Order Number: {orderData?.order_number}
                  </small>
                  <small style={{ display: 'inline-block', float: 'right' }}>
                    {orderData?.items?.length
                      ? Number(orderData?.items?.length).toLocaleString()
                      : 0}
                    {Number(orderData?.items?.length).toLocaleString() > 1
                      ? ' items'
                      : ' item'}
                  </small>
                </div>
                <Scrollbar style={{ overflowX: 'hidden' }}>
                  <div
                    className={`table-responsive-sm mt-0 p-3`}
                    style={{
                      paddingTop: '0 !important',
                    }}
                  >
                    <table className={`${styles.table} mt-0`}>
                      <thead className={`${styles.thead}`}>
                        <tr>
                          {TABLE_HEAD.map((el, i) => {
                            return <th className="text-muted">{el.label}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {!isPending &&
                          orderData?.items?.length > 0 &&
                          orderData?.items.map((el, i) => (
                            <tr key={i} className={`${styles.tr}`}>
                              <td>
                                <div className=" ">
                                  <div className=" ">
                                    {showCheck && (
                                      <div className="d-inline-block">
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={refundIds.includes(
                                                el?.shop_product_id
                                              )}
                                              onChange={handleCheck}
                                              value={el?.shop_product_id}
                                            />
                                          }
                                        />
                                      </div>
                                    )}
                                    <img
                                      className="flsoat-left"
                                      src={el?.product_info?.image}
                                      alt=""
                                      style={{
                                        width: '15%',
                                      }}
                                    />
                                    &nbsp; &nbsp;
                                    <div
                                      style={{
                                        display: 'inline-block',
                                      }}
                                    >
                                      <p
                                        className="mb-0"
                                        style={{ fontSize: '14px' }}
                                      >
                                        <span>{el?.product_info?.name}</span>
                                      </p>
                                      <span style={{ fontSize: '10px' }}>
                                        {el?.product_info?.product_spec}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>{Number(el?.quantity).toLocaleString()}</td>
                              <td>
                                ₦{' '}
                                {Number(
                                  el?.product_info?.original_price
                                    ?.$numberDecimal || 0
                                ).toLocaleString()}
                              </td>

                              <td>
                                ₦{' '}
                                {Number(
                                  el?.product_info?.discount || 0
                                ).toLocaleString()}
                              </td>
                              <td>₦ {Number(el?.price).toLocaleString()}</td>
                              <td>
                                ₦ {Number(el?.total_amount).toLocaleString()}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-center">
                    {isPending && (
                      <BallTriangle
                        height={50}
                        width={55}
                        radius={5}
                        color="#af3585"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                      />
                    )}
                  </div>
                  {!orderData?.items?.length && !isPending && (
                    <div className="alert alert-[#af3585] text-center">
                      <h4>No record found</h4>
                    </div>
                  )}
                </Scrollbar>
              </div>
            </div>
            <div
              className="col-md-4 h-100"
              style={{ background: '#E5E7EB', overflow: 'hidden' }}
            >
              <div
                className=" pt-4  px-2 border-bottom"
                style={{ background: 'inherit !important' }}
              >
                <h4 className="font-weight-bold">Summary</h4>
              </div>
              <div className="border-bottom-white mt-4 px-2">
                <div className="d-flex justify-content-between">
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      lineHeight: ' 150%',
                      color: '#1F2937',
                    }}
                  >
                    Total Order
                  </p>
                  <p
                    style={{
                      fontWeight: '400',
                      fontSize: '13px',
                      lineHeight: ' 150%',
                      color: '##374151',
                    }}
                  >
                    ₦{' '}
                    {orderData?.total_order
                      ? Number(
                          orderData?.total_order?.$numberDecimal
                        ).toLocaleString()
                      : 0}
                  </p>
                </div>

                <div className="d-flex justify-content-between">
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      lineHeight: ' 150%',
                      color: '#1F2937',
                    }}
                  >
                    Total Discount
                  </p>
                  <p
                    style={{
                      fontWeight: '400',
                      fontSize: '13px',
                      lineHeight: ' 150%',
                      color: '##374151',
                    }}
                  >
                    ₦{' '}
                    {orderData?.discount
                      ? Number(orderData?.discount).toLocaleString()
                      : 0}
                  </p>
                </div>

                <div className="d-flex justify-content-between">
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      lineHeight: ' 150%',
                      color: '#1F2937',
                    }}
                  >
                    Customer Total
                  </p>
                  <p
                    style={{
                      fontWeight: '400',
                      fontSize: '13px',
                      lineHeight: ' 150%',
                      color: '##374151',
                    }}
                  >
                    ₦{' '}
                    {orderData?.customer_to_pay
                      ? Number(orderData?.customer_to_pay).toLocaleString()
                      : 0}
                  </p>
                </div>
                {/* <div className="">
                                    {orderData?.status === "pending" ?
                                        <button onClick={submitOtpWallet} className={`modal_btn btn btn-block`}
                                            style={{
                                                marginTop: '7cm',
                                            }}>
                                            Refund
                                        </button>
                                        :
                                        <button disabled className={`modal_btn btn btn-block`}
                                            style={{
                                                marginTop: '7cm',
                                            }}>
                                            Refund
                                        </button>
                                    }
                                </div> */}
              </div>
              <div
                className={`${styles.sub_total} py-4 px-2`}
                style={{
                  position: 'absolute',
                  width: '89%',
                  bottom: 0,
                  borderTop: '2px solid #FFFFFF ',
                }}
              >
                {showCheck && (
                  <div>
                    <button
                      disabled={!refundIds.length}
                      onClick={() => {
                        setHandleOpenDialog(true);
                      }}
                      className={`modal_btn btn btn-block`}
                    >
                      Proceed
                    </button>
                  </div>
                )}
                <div className="d-flex mt-3 justify-content-between">
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      lineHeight: ' 150%',
                      color: '#1F2937',
                    }}
                  >
                    Total Price
                  </p>
                  <p
                    className="font-weight-bold"
                    style={{
                      fontSize: '20px',
                      lineHeight: ' 150%',
                      color: '##374151',
                    }}
                  >
                    ₦{' '}
                    {orderData?.customer_to_pay
                      ? Number(orderData?.customer_to_pay).toLocaleString()
                      : 0}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* </Scrollbar> */}

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openDialog}>
          <Box className={`mobile_modal_size modal_bg`} sx={style1}>
            <div className={`text-center add_to_cart_content`}>
              <img src="/assets/icons/circleCheck.svg" alt="" />
              <h5 className="mt-3 mb-2" style={{ fontWeight: 'bold' }}>
                {modalTitle}
              </h5>
              <p
                className="text-center"
                style={{ textAlign: 'center !important' }}
              >
                {modal_desc}
              </p>
              <button
                onClick={() => setOpenDialog(false)}
                className={`modal_btn hover btn btn-block`}
              >
                Go to Orders
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '100%' } }}
        open={handleOpenDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setHandleOpenDialog(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="">
                <img
                  style={{ width: '40px' }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <p style={{ fontWeight: 'bold', fontSize: '19px' }}>
                  Total amount to be refunded{' '}
                  {Number(totalAmount).toLocaleString()}
                </p>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6B7280',
                  }}
                >
                  Continue with this operation ?
                </p>
              </div>
            </div>

            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={() => [
                  setHandleOpenDialog(false),
                  navigate('/app/orders'),
                ]}
              >
                Cancel
              </Button>
              <Button className={`${styles.delete_product}`} onClick={refund}>
                Yes{' '}
                {loadingState && (
                  <CircularProgress style={{ color: '#af3585' }} size="1rem" />
                )}
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
    </>
  );
}
