import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import DashboardHeader from './header/index';
import PagesHeader from './header/pagesHeader';
import SmallScreenHeader from './header/smallScreenHeader';
import Nav from './nav';
import { useParams, useNavigate } from "react-router-dom";
import bus from 'src/bus';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { id } = useParams()
  const [open, setOpen] = useState(false);
  const [pathName, setPathName] = useState(window.location.pathname);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const innerWidth = window.innerWidth

  const locationVal = window.location.pathname

  useEffect(() => {

    setPathName(locationVal);

  }, [locationVal]);

  useEffect(() => {

    if (innerWidth <= 743) {
      setIsSmallScreen(true)
    } else {
      setIsSmallScreen(false)
    }

  }, [innerWidth]);

  const handleNavOpen = () => {
    setOpen(true)
  }

  useEffect(() => {
    bus.on('open-navbar', () => {
      if(!open) setOpen(true);
    })
  }, [])


  return (
    <StyledRoot>
      
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      

      {(pathName === "/app/dashboard" && !isSmallScreen) &&
        <DashboardHeader onOpenNav={handleNavOpen} />
      }
      {(!isSmallScreen && pathName !== "/app/dashboard"
        && pathName !== "/app/incremental_insight"
        && pathName !== "/app/purchase_details_s/"
        && pathName !== "/app/purchase_details_so"
        && pathName !== "/app/cart" && pathName !== "/app/sales_details"
        && pathName !== "/app/sales_details_shop"
        && pathName !== "/app/brand_sales_details"
        && pathName !== "/app/order_details_brand"
        && pathName !== "/app/order_details_shop"
        && pathName !== "/app/order_details_my_orders"
        && pathName !== `/app/brand_insight/${id}`
        && pathName !== `/app/shop_insight/${id}`
        && pathName !== "/app/purchases_details") &&
        <PagesHeader pathName={pathName} onOpenNav={handleNavOpen} />

      }
      {isSmallScreen &&
        <SmallScreenHeader onOpenNav={handleNavOpen} />

      }


      <Main className='px-1 px-md-3 remove_padding'>

        <Outlet />
      </Main>
    </StyledRoot>
  );
}
