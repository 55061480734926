import React from 'react';

const StarIcon = ({ color, isHalf }) => (
  <svg
    viewBox="0 0 24 24"
    fill={isHalf ? 'url(#halfFill)' : color}
    stroke={color}
    strokeWidth="0"
    height="1em"
    width="1em"
  >
    {isHalf ? (
      <defs>
        <linearGradient id="halfFill">
          <stop offset="50%" stopColor={color} />
          <stop offset="50%" stopColor="#ccc" />
        </linearGradient>
      </defs>
    ) : null}
    <path d="M12 2l2.4 7.4H22l-6 4.6 2.4 7.4-6-4.6-6 4.6 2.4-7.4-6-4.6h7.6z" />
  </svg>
);

export default StarIcon;
