import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import NotificationsPopover from './NotificationsPopover';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import './sty.css'
import { useAuth } from 'src/hooks/useAuth';
import { Navigate, NavLink } from 'react-router-dom';
// ----------------------------------------------------------------------
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import bus from 'src/bus';

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 50;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const cartIconStyle = {
  position: 'relative',
  display: 'inline-block',
}

const badgeStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  background: 'red',
  color: 'white',
  fontSize: '10px',
  padding: '1px 4px',
  borderRadius: '50%',
}



// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
    const {dispatch, data} = useAuth();
    const [searchValue, setSearchValue] = useState('')
    const navigate = useNavigate();
    const subText= data.merchant_type === "brand" ? 
    ' An overview of your business performance' : 
    (data.merchant_type === "shop"?'  A brief update on your business transactions': "An overview of all that's happening on FlexDeals")
  
    const searchFilter = (e) => {
      setSearchValue(e.target.value)
      bus.emit('dashboardSearch',e.target.value)
    }

    const LogoutUser=()=>{
      localStorage.removeItem('auth');
      dispatch({type:'LOGIN', payload: {}})

      setTimeout(() => {
        navigate(data.merchant_type ? '/login' :'/auth')
      }, 1000);
    }

    const openNav = () => {
      bus.emit('open-navbar')
    }

  return (
    <StyledRoot  className='pt-md-5 style_cs'>
      <StyledToolbar  className='px-0 style_c'>
        <IconButton
          onClick={openNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <div   className={` header d-none d-md-block`}>
          <h4 className={`mb-1 pb-0`}>Dashboard</h4>
          <p style={{fontSize: '14px'}} className={`mb-0 pb-0`}>
            {subText}
          </p>
        </div>
        
        <Box sx={{ flexGrow: 1 }} />

        {/* {data.user_type != 'admin' && (
          <div className='hold_input4 mr-2' style={{width:'35%'}}>
              <img src="/assets/icons/search.svg" alt="" />
              <input 
                type="text"
                value={searchValue}
                onChange={searchFilter} 
                placeholder="Search.." 
                style={{
                  textIndent:'25px',
                  width:'100%'
              }} 
                className=" form-control" 
              />
          </div>
        )} */}
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* {data.merchant_type === "shop" && 
            <NavLink className="text-decoration-none" to='/app/cart'>
              <div style={ cartIconStyle }>
                <img 
                  style={{ width: '75%' }} 
                  src="/assets/icons/shopping-cart.svg" 
                  alt="" 
                />
                {
                  emitCount > 0 &&
                  <b 
                    style={badgeStyle} 
                    className='text-center'
                  >
                    { Number(emitCount).toLocaleString() }
                  </b>
                }
              </div>
            </NavLink>
          
          } */}
          <div className="dropleft">
            <PowerSettingsNewIcon 
              id="dropdownMenuButton" 
              data-toggle="dropdown" 
              aria-haspopup="true" 
              aria-expanded="false"  
              style={{
                color:'gray', 
                marginRight: '10px',
                cursor:'pointer',
                width:'90%'
              }} 
            />
                
            <div 
              className="dropdown-menu drop-left" 
              aria-labelledby="dropdownMenuButton"
            >
              <button 
                type="button" 
                onClick={() => bus.emit("open-logout-modal")} 
                className="rss dropdown-item btn border-0"
              >
                Logout
              </button>
            
            
            </div>
          </div>
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
