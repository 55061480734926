import styles from './Order.module.css';
import { Card } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
import bus from 'src/bus';
import axiosInstance from 'src/axiosInstance';
import { BallTriangle } from 'react-loader-spinner';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

// sections
// mock
import { useNavigate, useParams } from 'react-router-dom';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'details', label: 'Product Details', alignRight: false },
  { id: 'quantity', label: 'Quantity', alignRight: false },
  { id: 'unitPrice', label: 'Unit price (₦)', alignRight: false },
  { id: 'totalPrice', label: 'Total price (₦)', alignRight: false },
];

// ----------------------------------------------------------------------

export default function OrderPage({ onChange }) {
  const navigate = useNavigate();
  const [shoppersData, setdata] = useState({ items: [] });
  const [isPending, setIsPending] = useState(false);
  const notifyError = (err) => toast.error(err);
  const [filter, setFilter] = useState(false);
  useEffect(() => {
    fetchData();
  }, [filter]);

  bus.on('reload', () => {
    fetchData();
  });

  useEffect(() => {}, [shoppersData]);

  const { order_id } = useParams();
  // alert(order_id)

  //Getting Table Data
  const fetchData = () => {
    setIsPending(true);
    axiosInstance
      .get('/shop/order/get-order/' + order_id)
      .then((res) => {
        setdata(res.data.data);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch orders, please try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  function handleClick() {
    // event.preventDefault();
    navigate(-1);
  }
  function HideHeaderOnMount() {
    const header = document.querySelector('header');
    if (header) {
      header.style.display = 'none';
    }
  }
  useEffect(() => {
    HideHeaderOnMount();
  }, []);

  return (
    <>
      <div className={`pt-2 px-2`}>
        <div className="d-flex">
          <button onClick={handleClick} className="pl-0 btn mt-2">
            <div
              className="p-1"
              style={{
                border: '1px solid lightgrey',
                borderRadius: '3px',
              }}
            >
              <img src="/assets/icons/Chevronleft.svg" alt="" />
            </div>
            &nbsp;
          </button>
          <span className="pl-0 mt-3">Orders</span>
        </div>
        {shoppersData?.status === 'pending' && (
          <p
            className="text-right text-[#af3585] font-weight-bold"
            style={{
              fontSize: '18px',
            }}
          >
            Transaction in progress...
          </p>
        )}
        {/* <Breadcrumbs separator="›" aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs> */}
        <Card
          style={{
            marginTop: '50px',
            borderRadius: '10px',
            overflowX: 'hidden',
          }}
          className=" ss p-0"
        >
          <Scrollbar style={{ overflowX: 'hidden' }}>
            <div className="row" style={{ minHeight: '60vh' }}>
              <div className="col-md-8  mb-1">
                <div className="pl-4 pr-3 pt-3 pb-0 mb-0">
                  <h3 className="font-weight-bold">Orders</h3>
                  <small style={{ fontWeight: '200', fontSize: '12px' }}>
                    Order Number: {shoppersData.order_number}
                  </small>
                  <small style={{ display: 'inline-block', float: 'right' }}>
                    {shoppersData.items.length.toLocaleString()}
                    {shoppersData.items.length <= 1 ? ' item' : ' items'}
                  </small>
                </div>
                {shoppersData.items.length > 0 && !isPending && (
                  <div
                    className={`table-responsive-sm mt-0 p-3`}
                    style={{
                      paddingTop: '0 !important',
                    }}
                  >
                    <table className={`${styles.table} mt-0`}>
                      <thead className={`${styles.thead}`}>
                        <tr>
                          {TABLE_HEAD.map((el, i) => {
                            return <th className="text-muted">{el.label}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {shoppersData.items.length > 0 &&
                          !isPending &&
                          shoppersData.items.map((el, i) => (
                            <tr key={i} className={`${styles.tr}`}>
                              <td>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: '5px',
                                  }}
                                >
                                  <div className="p-2 ">
                                    <img
                                      className="float-left"
                                      src={
                                        el?.product_id
                                          ? el?.product_id?.product_image
                                          : '/assets/purelifetablewater.svg'
                                      }
                                      alt=""
                                      style={{
                                        width: '100px',
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: 'inline-block',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                      }}
                                    >
                                      <p
                                        className="mb-0"
                                        style={{
                                          fontSize: '14px',
                                        }}
                                      >
                                        <span>
                                          {el?.product_id?.product_name}
                                        </span>
                                      </p>
                                      {el?.product_spec && (
                                        <span style={{ fontSize: '10px' }}>
                                          {el?.product_spec}kg
                                        </span>
                                      )}
                                      <p
                                        className="mt-3 mb-2"
                                        style={{
                                          color: 'royalblue',
                                        }}
                                      >
                                        <span>
                                          Contact Vendor:
                                          <a
                                            className="ml-1"
                                            href={`tel:${el?.brand_id?.business_phone_number}`}
                                          >
                                            {
                                              el?.brand_id
                                                ?.business_phone_number
                                            }
                                          </a>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>{el.quantity}</td>
                              <td>₦{Number(el.price).toLocaleString()}</td>
                              <td>
                                ₦ {Number(el.total_amount).toLocaleString()}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="d-flex justify-content-center">
                  {isPending && (
                    <BallTriangle
                      height={50}
                      width={55}
                      radius={5}
                      color="#af3585"
                      ariaLabel="ball-triangle-loading"
                      wrapperClass={{}}
                      wrapperStyle=""
                      visible={true}
                    />
                  )}
                </div>
                {!shoppersData.items.length && !isPending && (
                  <div className="alert alert-[#af3585] text-center">
                    <h4>No record found</h4>
                  </div>
                )}
              </div>
              <div
                className={`${styles.summary} col-md-4`}
                style={{
                  background: '#E5E7EB',
                }}
              >
                <div
                  className=" pt-4  px-2 border-bottom"
                  style={{
                    background: 'inherit !important',
                  }}
                >
                  <h4 className="font-weight-bold">Summary</h4>
                </div>
                <div className="border-bottom-white mt-4 px-2">
                  <div className="d-flex justify-content-between">
                    <p
                      style={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        lineHeight: ' 150%',
                        color: '#1F2937',
                      }}
                    >
                      Total Order
                    </p>
                    <p
                      style={{
                        fontWeight: '400',
                        fontSize: '13px',
                        lineHeight: ' 150%',
                        color: '#374151',
                      }}
                    >
                      ₦{' '}
                      {!isPending &&
                        Number(shoppersData.subtotal).toLocaleString()}
                    </p>
                  </div>
                  {/*
                                        <div className="">
                                            <button className={`modal_btn btn btn-block`}
                                                style={{
                                                    marginTop: '7cm',

                                                }}>
                                                Refund

                                            </button>

                                        </div>
                                    */}
                </div>
                <div
                  className={`${styles.sub_total} py-4 px-2`}
                  style={{
                    position: 'absolute',
                    width: '89%',
                    bottom: 0,
                    borderTop: '2px solid #FFFFFF ',
                  }}
                >
                  {!isPending && (
                    <div className="d-flex justify-content-between">
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          lineHeight: ' 150%',
                          color: '#1F2937',
                        }}
                      >
                        Total
                      </p>
                      <p
                        className="font-weight-bold"
                        style={{
                          fontSize: '20px',
                          lineHeight: ' 150%',
                          color: '##374151',
                        }}
                      >
                        ₦{' '}
                        {!isPending &&
                          Number(shoppersData.subtotal).toLocaleString()}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Scrollbar>
          {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={USERLIST.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
        </Card>
      </div>
    </>
  );
}
