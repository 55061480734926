import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import BlogPage from "./pages/BlogPage";
// import UserPage from './pages/UserPage';
import OrderPage from "./pages/Orders/index";
import Purchase from "./pages/Purchases/index";
import SalesPage from "./pages/SalesPage/index";
import SalesDetails from "./pages/SalesPage/SalesDetails";
import SalesDetailsShop from "./pages/SalesPage/SalesDetailsShop";
import ShopSalesDetails from "./pages/SalesPage/BrandOwnerComponent/ShopOwnersView";
import SalesDetailsBrand from "./pages/SalesPage/SalesDetailsBrand";
import PurchaseDetailsShopOwner from "./pages/Purchases/SuperAdminComponent/ShopOwnerPurchaseDetail";
import PurchaseDetailsShopper from "./pages/Purchases/SuperAdminComponent/ShopperPurchaseDetail";
import PurchaseDetails from "./pages/Purchases/Details";
import OrderDetailsBrandOwner from "./pages/Orders/OrderDetailsBrandOwner";
import OrderDetailsShopOwner from "./pages/Orders/OrderDetailsShopOwner";
import OrdersDetailss from "./pages/Orders/BrandOwnerOrderDetails";
import OrderDetails2 from "./pages/Orders/OrderDetails2";
import OutletPage from "./pages/OutletPage/index";
import LoginPage from "./pages/Auth/LoginPage";
import SuperAdmin from "./pages/Auth/SuperAdmin";
import ForgotPasswordPage from "./pages/Auth/ForgotPasswordPage";
import ResetPassword from "./pages/Auth/ResetPassword";
import VerifyEmailPage from "./pages/Auth/VerifyEmailPage";
import RegisterPage from "./pages/Auth/RegisterPage";
import InviteAdmin from "./pages/Administration/inviteAdmin";
import Page404 from "./pages/Page404";
import DashboardAppPage from "./pages/Dashboard/Index";
import WalletPage from "./pages/Wallet/Index";
import ProductPage from "./pages/Product/Index";
import CommentPage from "./pages/Customers/components/UserComments";
import ShoppersPage from "./pages/Shop/Index";
import CustomersPage from "./pages/Customers/index";
import AuditPage from "./pages/Audit/index";
import Marketing from "./pages/Marketing/index";
import Administration from "./pages/Administration/index";
import Promotions from "./pages/Promotions/index";
import Transactions from "./pages/Transactions/index";
import Subscription from "./pages/Subscription/index";
import Pricing from "./pages/Pricing/index";
import DataInsight from "./pages/DataInsight/Index";
import DataInsightBrand from "./pages/DataInsight/Superadmin/BrandOwner";
import DataInsightShop from "./pages/DataInsight/Superadmin/Retailer";
import IncrementalInsight from "./pages/IncrementalInsight/Index";
import IncrementalInsightMore from "./pages/IncrementalInsight/More";
import Profile from "./pages/Profile/Index";
import SettingsApp from "./pages/Settings/Index";
import Onboarding from "./pages/Onboarding/Index";
import CartsPage from "./pages/Cart/Index";
import KYC from "./pages/Onboarding/KYC";
import TC from "./pages/Onboarding/TC";
import DeleteAccountSteps from "./pages/Onboarding/DeleteAccountSteps";
import { useAuth } from "./hooks/useAuth";
import withAuth from "./auth.hoc.js";
// import PrivacyPolicy from "./policy/PrivacyPolicy";
import TermsAndCondition from "./policy/TermsAndCondition";
import StoreInvite from "./pages/Administration/inviteStore";
import BrandInvite from "./pages/Administration/inviteBrand";

// ----------------------------------------------------------------------

export default function Router() {
  const { data } = useAuth();
  let isLoggedIn = Object.keys(data).length;
  //  Object.keys(data).length;
  const openRoutes = ["/login", "forgot_password", "verify_email", "register", "complete_profile/:token", "shop-invite/:shop_id/:token", "signup-invite/:token"];

  const routes = [
    {
      path: "/",
      element: <Navigate to="/app/dashboard" />,
    },
    // {
    //   path: "/privacy-policy",
    //   element: <PrivacyPolicy />,
    // },
    {
      path: "/terms-and-conditions",
      element: <TermsAndCondition />,
    },
    {
      path: "/app",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/app/dashboard" />, index: true },
        {
          path: "dashboard",
          element: isLoggedIn ? (
            withAuth(DashboardAppPage, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "carts/:order_id",
          element: isLoggedIn ? (
            withAuth(CartsPage, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "cart/",
          element: isLoggedIn ? (
            withAuth(CartsPage, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "orders",
          element: isLoggedIn ? (
            withAuth(OrderPage, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "purchases",
          element: isLoggedIn ? (
            withAuth(Purchase, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "sales",
          element: isLoggedIn ? (
            withAuth(SalesPage, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "purchase_details_so/:purchase_id",
          element: isLoggedIn ? (
            withAuth(PurchaseDetailsShopOwner, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "purchase_details_s/:purchase_id",
          element: isLoggedIn ? (
            withAuth(PurchaseDetailsShopper, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "sales_details/",
          element: isLoggedIn ? (
            withAuth(SalesDetails, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "sales_details_shop",
          element: isLoggedIn ? (
            withAuth(SalesDetailsShop, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "brand_sales_details",
          element: isLoggedIn ? (
            withAuth(SalesDetailsBrand, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "shop_sales_details/",
          element: isLoggedIn ? (
            withAuth(ShopSalesDetails, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "purchases_details",
          element: isLoggedIn ? (
            withAuth(PurchaseDetails, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "order_details_brand",
          element: isLoggedIn ? (
            withAuth(OrderDetailsBrandOwner, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "order_details_shop",
          element: isLoggedIn ? (
            withAuth(OrderDetailsShopOwner, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "order_detailss/:order_id",
          element: isLoggedIn ? (
            withAuth(OrdersDetailss, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "order_details_my_orders/:order_id",
          element: isLoggedIn ? (
            withAuth(OrderDetails2, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "stores",
          element: isLoggedIn ? (
            withAuth(OutletPage, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "customers/:pageId?",
          element: isLoggedIn ? (
            withAuth(CustomersPage, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "audit",
          element: isLoggedIn ? (
            withAuth(AuditPage, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "marketing",
          element: isLoggedIn ? (
            withAuth(Marketing, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "administration",
          element: isLoggedIn ? (
            withAuth(Administration, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "promotions",
          element: isLoggedIn ? (
            withAuth(Promotions, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "wallets",
          element: isLoggedIn ? (
            withAuth(WalletPage, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "products/:categoryId?",
          element: isLoggedIn ? (
            withAuth(ProductPage, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "userComments/",
          element: isLoggedIn ? (
            withAuth(CommentPage, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "transactions/",
          element: isLoggedIn ? (
            withAuth(Transactions, openRoutes)
          ) : (
            <Navigate to="/auth" />
          ),
        },
        {
          path: "subscription/",
          element: isLoggedIn ? (
            withAuth(Subscription, openRoutes)
          ) : (
            <Navigate to="/auth" />
          ),
        },
        {
          path: "Pricing/",
          element: isLoggedIn ? (
            withAuth(Pricing, openRoutes)
          ) : (
            <Navigate to="/auth" />
          ),
        },
        // {
        //   path: 'products/:productId?',
        //   element: <SingleViewPage />,
        // },
        {
          path: "shoppers",
          element: isLoggedIn ? (
            withAuth(ShoppersPage, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "data_insight",
          element: isLoggedIn ? (
            withAuth(DataInsight, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "brand_insight/:id",
          element: isLoggedIn ? (
            withAuth(DataInsightBrand, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "shop_insight/:id?",
          element: isLoggedIn ? (
            withAuth(DataInsightShop, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "incremental_insight",
          element: isLoggedIn ? (
            withAuth(IncrementalInsight, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "incremental_insight/more",
          element: isLoggedIn ? (
            withAuth(IncrementalInsightMore, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "settings",
          element: isLoggedIn ? (
            withAuth(SettingsApp, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "profile",
          element: isLoggedIn ? (
            withAuth(Profile, openRoutes)
          ) : (
            <Navigate to="/login" />
          ),
        },
        { path: "blog", element: <BlogPage /> },
      ],
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "auth",
      element: <SuperAdmin />,
    },
    {
      path: "forgot_password",
      element: !isLoggedIn ? (
        <ForgotPasswordPage />
      ) : (
        <Navigate to="/app/dashboard" />
      ),
    },
    {
      path: "reset",
      element: !isLoggedIn ? (
        <ResetPassword />
      ) : (
        <Navigate to="/app/dashboard" />
      ),
    },
    {
      path: "verify_email",
      element: !isLoggedIn ? (
        <VerifyEmailPage />
      ) : (
        <Navigate to="/app/dashboard" />
      ),
    },
    {
      path: "register",
      element: !isLoggedIn ? (
        <RegisterPage />
      ) : (
        <Navigate to="/app/dashboard" />
      ),
    },
    {
      path: "complete_profile/:token",
      element: !isLoggedIn ? (
        <InviteAdmin />
      ) : (
        <Navigate to="/app/dashboard" />
      ),
    },
    {
      path: "shop-invite/:shop_id/:token",
      element: !isLoggedIn ? (
        <StoreInvite />
      ) : (
        <Navigate to="/app/dashboard" />
      ),
    },
    {
      path: "signup-invite/:token",
      element: !isLoggedIn ? (
        <BrandInvite />
      ) : (
        <Navigate to="/app/dashboard" />
      ),
    },
    {
      path: "onboarding",
      element: isLoggedIn ? <Onboarding /> : <Navigate to="/login" />,
    },
    {
      path: "kyc_mou",
      element: <KYC />,
    },
    {
      path: "privacy-policy",
      element: <TC />,
    },
    {
      path: "delete-account-steps",
      element: <DeleteAccountSteps />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/app/dashboard" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    // {
    //   path: '*',
    //   element: <Navigate to="/404" replace />,
    // },
  ];
  return useRoutes(routes);
}
