import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  email: faker.internet.email() ,
  customerName: faker.company.companyName() ,
  category: index  < 3 ? ' Mom & Pop' : 'Kioks',
  productUnit:  2,
  customerLocation: faker.address.state(),
  price: faker.finance.amount(5, 10, 2, '$'),
  created_at: '2025/02/24',
  status: sample(['active', 'banned']),
  role: sample([
    'Leader',
    'Hr Manager',
    'UI Designer',
    'UX Designer',
    'UI/UX Designer',
    'Project Manager',
    'Backend Developer',
    'Full Stack Designer',
    'Front End Developer',
    'Full Stack Developer',
  ]),
}));

export default users;
