import styles from './index.module.css';
import Pdf from 'react-to-pdf';

import { Card } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
import Iconify from '../../components/iconify';
import { useNavigate } from 'react-router-dom';
import bus from 'src/bus';
import axiosInstance from 'src/axiosInstance';
import { createRef, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BallTriangle } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import LiveLocation from 'src/pages/LiveLocation';
import LiveSelect from 'react-select';
import PDFFile from '../../components/PDFS/receipt';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useAuth } from 'src/hooks/useAuth';
import Header from 'src/layouts/dashboard/header/index';

// sections
// mock

// ----------------------------------------------------------------------
const style1 = {
  position: 'absolute',
  // height: '500px',
  overflow: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3,
};

const TABLE_HEAD = [
  { id: 'details', label: 'Product Details', alignRight: false },
  { id: 'quantity', label: 'Quantity', alignRight: false },
  { id: 'unitPrice', label: 'Unit price', alignRight: false },
  { id: 'totalPrice', label: 'Total price', alignRight: false },
];

// ----------------------------------------------------------------------

export default function OrderPage({ onChange }) {
  const navigate = useNavigate();
  const ref = createRef();
  const { data } = useAuth();

  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [showAddress, setShowAddress] = useState(false);
  const [dataItem, setdata] = useState({
    items: [],
    total: 0,
    shipping_fee: 0,
  });
  const [isPending, setIsPending] = useState(false);
  const [checkoutLoading, setcheckoutLoading] = useState(false);
  const [reload, setreload] = useState(false);
  const notifyError = (err) => toast.error(err);
  const notify = (msg) => toast.success(msg);
  const [openGoToCart, setopenGoToCart] = useState(false);
  const [warning, setwarning] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedOption] = useState(null);
  const [selectedOption1] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [address, setAddress] = useState(data?.store_address);
  const options = {
    orientation: 'potrait',
    unit: 'in',
    format: [10, 10],
  };

  const handleGoToCartClose = () => {
    setopenGoToCart(false);
    setreload(!reload);
  };
  const handleWarningCartClose = () => {
    setwarning(false);
  };

  function handleClick(event) {
    event.preventDefault();
    // let
    navigate('/app/dashboard');
  }

  const handleAddress = (val) => {
    setShowAddress(val);
  };

  // const [data, setdata] = useState({items:[]});

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    console.log(dataItem);
  }, [dataItem]);

  bus.on('reload', () => {
    fetchData();
  });

  const fetchData = () => {
    setIsPending(true);
    axiosInstance
      .get('/shop/cart/get-cart')
      .then((res) => {
        setdata(res.data.data);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch cart, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const checkout = () => {
    setcheckoutLoading(true);

    axiosInstance
      .post('/shop/order/checkout-wallet', {
        delivery_information: {
          address,
          state,
          country,
        },
      })
      .then((res) => {
        setopenGoToCart(true);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Something went wrong');
        }
        setwarning(true);
      })
      .finally(() => setcheckoutLoading(false));
  };
  const processCartClose = () => {
    setcheckoutLoading(true);
    axiosInstance
      .post('/shop/cart/clear-cart')
      .then((res) => {
        setOpenDialog(false);
        setreload(!reload);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Something went wrong');
        }
        setwarning(true);
      })
      .finally(() => setcheckoutLoading(false));
  };

  const handleInviteSubmit = (d) => {
    setopenGoToCart(false);
    setOpenDialog(true);
  };
  const processLocation = (e) => {
    setAddress(e.formatted_address);
  };
  const fetchCountries = async () => {
    const countriesApi = await fetch('https://restcountries.com/v3.1/all');
    const countriesJson = await countriesApi.json();
    const countriesData = countriesJson.map((country) => ({
      code: country.cca2,
      name: country.name.common,
    }));
    setCountries(countriesData.sort((a, b) => a.name.localeCompare(b.name)));
  };
  const fetchStates = async () => {
    if (country === '') return;
    setStates([]);
    setState('select');

    setLoadingState(true);
    const statesFetch = await fetch(
      `https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/states.json`,
      {}
    );
    const statesJson = await statesFetch.json();
    const statesData = statesJson;
    setStates(() => {
      return statesData
        .filter((el, i) => el.country_name === country)
        .sort((a, b) => a.name.localeCompare(b.name));
    });
    setLoadingState(false);
  };
  useEffect(() => {
    fetchCountries();
    fetchStates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);
  const cartUpdate = (data) => {
    if (Number(data.qt) < 2 && Number(data.quantity) < 1) {
      return notifyError('Cart item should be greater than 0');
    }

    setIsPending(true);
    axiosInstance
      .post(`/shop/cart/add-to-cart/${data.id}`, data)
      .then((res) => {
        setreload(!reload);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to process your action, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };
  const removeItem = (id) => {
    setIsPending(true);
    axiosInstance
      .post(`/shop/cart/remove-item/${id}`)
      .then((res) => {
        setreload(!reload);
        notify('Item removed successfully');
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to process your action, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };
  const handleChange = (event) => {
    setCountry(event.value);
  };
  const handleChange2 = (event) => {
    setState(event.value);
  };

  const handleGoShopping = () => {
    navigate('/app/dashboard');
  };

  //Getting Table Data
  // const confirmOrder = () => {
  //     setIsPending(true);
  //     axiosInstance.put('/shop/order/mark-complete/'+order_id)
  //     .then(res => {
  //         setdata(res.data.data.data)
  //         setShoppersPagination(res.data.data.pagination)

  //     }).catch(err => {
  //         try {
  //             notifyError(err.response.data.message);
  //         } catch (error) {
  //             notifyError("Unable to complete order, try again later");
  //         }

  //     }).finally(() => setIsPending(false))
  // }

  return (
    <>
      <div className={` px-2`}>
        <div>
          {/* <img src="/assets/shoprite.svg" height={'50px'} className="float-md-left" alt="" /> */}

          <div>
            {/* <h1 style={{ display: 'inline-block', color:'#F9FAFB' }}>
                            Shoprite
                        </h1> */}
            <small>{/* (Supermarket) */}</small>
            <div className="row">
              <div className="col-8">
                <button onClick={handleClick} className="btn pl-0">
                  <img src="/assets/icons/Steplink.svg" alt="" /> &nbsp; Back
                </button>
              </div>
              {/* <div className="col-4 text-center p-1">
                                <button onClick={() => confirmOrder()} className="btn btn-primary">
                                    Complete Order
                                </button>
                            </div> */}
            </div>
          </div>
        </div>
        <Card
          style={{
            marginTop: '60px',
            borderRadius: '10px',
            overflowX: 'hidden',
          }}
          className=" ss p-0 mb-5"
        >
          {/* <button onClick={handleClick} className="btn pl-0">
                    <img src="/assets/icons/Steplink.svg" alt="" /> &nbsp;
                    Back
                </button> */}
          <Scrollbar style={{ overflowX: 'hidden' }}>
            <div className="row p-0">
              <div
                className="col-md-9  mb-1"
                style={{ height: '90vh', position: 'relative' }}
              >
                <div className="pl-3 pr-3 pt-3 pb-0 mb-0">
                  <h3 className="font-weight-bold">Carts</h3>
                </div>
                <div
                  className={`table-responsive-sm mt-0 p-3`}
                  style={{
                    paddingTop: '0 !important',
                    height: '50vh',
                    overflowY: 'auto',
                  }}
                >
                  <table className={`${styles.table}  mt-0`}>
                    <thead className={`${styles.thead}`}>
                      <tr>
                        {TABLE_HEAD.map((el, i) => {
                          return (
                            <th key={el.id} className="text-muted">
                              {el.label}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {dataItem.items.map((el, i) => {
                        return (
                          <tr key={el._id} className={`${styles.tr} mb-2`}>
                            <td>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                }}
                              >
                                <div className="py-2 px-3">
                                  <img
                                    className=""
                                    src={el?.product_id?.product_image}
                                    alt=""
                                    style={{
                                      width: '70px',
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: 'inline-block',
                                  }}
                                >
                                  <p
                                    className="mb-0"
                                    style={{ fontSize: '14px' }}
                                  >
                                    <span>{el?.product_id?.product_name}</span>
                                  </p>
                                  {el?.product_id?.product_spec && (
                                    <span style={{ fontSize: '10px' }}>
                                      {el?.product_id?.product_spec}kg
                                    </span>
                                  )}

                                  <p
                                    className="my-0 mt-2"
                                    style={{
                                      color: 'royalblue',
                                    }}
                                  >
                                    <span>
                                      Contact Vendor:
                                      <a
                                        className="ml-1"
                                        href={`tel:${el?.product_id?.brand_id?.business_phone_number}`}
                                      >
                                        {
                                          el?.product_id?.brand_id
                                            ?.business_phone_number
                                        }
                                      </a>
                                    </span>
                                  </p>
                                  <div className="pt-2">
                                    <button
                                      className="btn p-0 m-0"
                                      style={{ verticalAlign: 'middle' }}
                                      onClick={() =>
                                        removeItem(el.product_id._id)
                                      }
                                    >
                                      <Iconify
                                        sx={{ color: 'error.main' }}
                                        icon={'eva:trash-2-outline'}
                                        style={{ verticalAlign: 'top' }}
                                      />
                                      <span
                                        className="pl-1 text-danger"
                                        style={{
                                          verticalAlign: 'middle',
                                          fontSize: '12px',
                                        }}
                                      >
                                        Delete
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                className="d-flex"
                                style={{
                                  verticalAlign: 'middle',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                }}
                              >
                                <span
                                  onClick={() =>
                                    cartUpdate({
                                      id: el.product_id._id,
                                      quantity: -1,
                                      qt: el.quantity,
                                    })
                                  }
                                  className={`${styles.quantity_btn} btn mx-2`}
                                  style={{ fontSize: '35px' }}
                                >
                                  -
                                </span>
                                <span className="mx-2">{el.quantity}</span>
                                <span
                                  onClick={() =>
                                    cartUpdate({
                                      id: el.product_id._id,
                                      quantity: 1,
                                      qt: el.quantity,
                                    })
                                  }
                                  className={`${styles.quantity_btn} btn mx-2`}
                                  style={{ fontSize: '20px' }}
                                >
                                  +
                                </span>
                              </div>
                            </td>
                            <td>₦ {Number(el.price).toLocaleString()}</td>
                            <td>
                              ₦ {Number(el.total_amount).toLocaleString()}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {!dataItem.items.length && !isPending && (
                    <div class={`${styles.nothing_wrapper}`}>
                      <div class={`${styles.nothing_detail_img}`}>
                        <img src="/assets/app_logo.png" alt="" />
                      </div>
                      <h2>Nothing here for now</h2>
                      <p>The cart would show your selected product.</p>
                      <span>
                        <button
                          className="btn btn-[#af3585] bg-[#8c2d915d] text-white"
                          onClick={() => handleGoShopping()}
                        >
                          Continue Shopping
                        </button>
                      </span>
                    </div>
                  )}
                  <div className="d-flex justify-content-center">
                    {isPending && (
                      <BallTriangle
                        height={50}
                        width={55}
                        radius={5}
                        color="#af3585"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3 pb-3" style={{ background: '#af3585' }}>
                <div
                  className=" pt-4 px-2 border-bottom"
                  style={{ background: 'inherit !important' }}
                >
                  <h5 className="font-weight-bold">Summary</h5>
                </div>
                <div className="border-bottom-white mt-4 px-2">
                  <div className="d-flex   border-bottom justify-content-between">
                    <p
                      style={{
                        fontWeight: 'bold',
                        fontSize: '13px',
                        lineHeight: ' 150%',
                        color: '#1F2937',
                      }}
                    >
                      Total Order
                    </p>
                    <p
                      style={{
                        fontWeight: 'bold',
                        fontSize: '13px',
                        lineHeight: ' 150%',
                        color: '##374151',
                      }}
                    >
                      ₦{' '}
                      {dataItem.total
                        ? Number(dataItem.total).toLocaleString()
                        : 0}
                    </p>
                  </div>
                </div>
                <div className={`${styles.sub_total} px-2 border-top`}>
                  {showAddress && (
                    <div
                      className={`pb-3 ${styles.d_address}`}
                      style={{
                        marginBottom: '80px',
                        borderBottom: ' 2px solid #FFFFFF',
                      }}
                    >
                      <h5>Delivery Address</h5>
                      <form action="">
                        <div className="mb-3">
                          <LiveLocation
                            label="Location"
                            passData={processLocation}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="s">Country*</label>
                          <LiveSelect
                            defaultValue={selectedOption}
                            onChange={handleChange}
                            options={countries.map((option) => ({
                              value: option.name,
                              label: option.name,
                            }))}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="state">State*</label> &nbsp;
                          {loadingState && (
                            <CircularProgress
                              style={{ color: '#af3585' }}
                              size="1rem"
                            />
                          )}
                          <LiveSelect
                            defaultValue={selectedOption1}
                            onChange={handleChange2}
                            options={states.map((option) => ({
                              value: option.name,
                              label: option.name,
                            }))}
                          />
                        </div>
                        <button
                          type="button"
                          style={{
                            fontSize: '13px',
                            fontWeight: 'bold',
                          }}
                          onClick={() => {
                            handleAddress(false);
                          }}
                          className="btn btn-[#af3585] bg-[#8c2d915d] text-white btn-hover btn-block animate__animated animate__bounceInUp"
                        >
                          Add
                        </button>
                      </form>
                    </div>
                  )}
                  {!showAddress && (
                    <div
                      className=" pb-3"
                      style={{
                        marginTop: '250px',
                        borderBottom: ' 2px solid #FFFFFF',
                      }}
                    >
                      <div className=" border-top">
                        {/* <div className="d-flex justify-content-between">
                                                    <p style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '13px',
                                                        lineHeight: ' 150%',
                                                        color: '#1F2937',
                                                        marginBottom: '0px',
                                                    }}>
                                                        Shipping fee:
                                                    </p>
                                                    <p style={{
                                                        fontWeight: '400',
                                                        fontSize: '15px',
                                                        lineHeight: ' 150%',
                                                        color: '##374151',
                                                        marginBottom: '0px',
                                                    }}>
                                                        ₦ {Number(data.shipping_fee).toLocaleString()}
                                                    </p>

                                                </div> */}
                        <p
                          className="mt-2 mb-1"
                          style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '150%',
                            color: '#4B5563',
                          }}
                        >
                          {address}
                        </p>
                        <small
                          className="text-[#af3585]"
                          style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: '12px',
                            lineHeight: '150%',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleAddress(true)}
                        >
                          Add / Change address
                        </small>
                      </div>
                    </div>
                  )}

                  <div className="mt-3 ">
                    <div className="d-flex justify-content-between">
                      <p
                        style={{
                          fontWeight: '500',
                          fontSize: '13px',
                          lineHeight: ' 150%',
                          color: '#1F2937',
                        }}
                      >
                        Total
                      </p>
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: '17px',
                          lineHeight: ' 150%',
                          color: '##374151',
                        }}
                      >
                        ₦{' '}
                        {(
                          dataItem.total && Number(dataItem.total)
                        ).toLocaleString()}
                      </p>
                    </div>

                    {!showAddress && (
                      <button
                        type="button"
                        onClick={checkout}
                        style={{
                          fontSize: '17px',
                          fontWeight: 'bold',
                        }}
                        className="btn btn-[#af3585] bg-[#8c2d915d] text-white btn-hover btn-block"
                      >
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <span>Pay Now</span>
                          <span>
                            {checkoutLoading && (
                              <CircularProgress
                                style={{ color: '#ffff' }}
                                size="1rem"
                              />
                            )}
                          </span>
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Scrollbar>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openGoToCart}
        onClose={handleGoToCartClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openGoToCart}>
          <Box className={`mobile_modal_size ${styles.modal_bg}`} sx={style1}>
            <div className={`text-center ${styles.add_to_cart_content}`}>
              <img src="/assets/icons/Illustration.svg" alt="" />
              <h5 className="mt-3 font-weight-bold">Payment successful </h5>
              <div className={`${styles.buttons}  pt-2`}>
                <button
                  className={`${styles.go_to} d-block w-100`}
                  onClick={() => handleInviteSubmit(1)}
                >
                  View Receipt
                </button>
              </div>
              {/* <div className={`${styles.buttons}  pt-2`}>
                                <button className={`${styles.cancel} `} onClick={handleGoToCartClose}>
                                    Cancel
                                </button>
                                &nbsp;
                                <button className={`${styles.go_to} `} onClick={() => navigate('/app/orders')}>

                                    Go to orders
                                </button>

                            </div> */}
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openDialog}>
          <Box className={`mobile_modal_size ${styles.modal_bg}`} sx={style1}>
            <div className={`${styles.add_to_cart_content}`}>
              <h5 className="mt-3 font-weight-bold">
                Receipt
                <PDFDownloadLink
                  document={<PDFFile data={dataItem} />}
                  filename="FORM"
                >
                  {({ loading }) =>
                    loading ? (
                      <button className="btn m-0 p-0 float-right">
                        Loading documents
                      </button>
                    ) : (
                      <button className="btn m-0 p-0 float-right">
                        <img src="/assets/icons/downloadblue.svg" alt="" />
                      </button>
                    )
                  }
                </PDFDownloadLink>
                {/* <Pdf targetRef={ref} filename="reciept.pdf" options={options} x={2}>
                                    {({ toPdf }) => <button className="btn m-0 p-0 float-right"><img src="/assets/icons/downloadblue.svg" alt="" /></button>}
                                </Pdf> */}
              </h5>
              <div ref={ref}>
                {/* <img className='logo d-inline-block mt-2' style={{ display: 'inline' }} src='/assets/icons/xpress.png' alt='logo' />

                                <h4 className="text-center border-bottom mt-5"> Order reciept</h4> */}
                <div className="mt-5">
                  {dataItem.items.map((el, i) => (
                    <div
                      key={i}
                      className="d-flex mb-4"
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <span className="d-block">
                          {el?.product_id?.product_name}
                        </span>
                        <span className="d-block">50cl</span>
                      </div>
                      <div>
                        <span>
                          ₦ {Number(el.total_amount).toLocaleString()} (
                          {Number(el.quantity).toLocaleString()})
                        </span>
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  className="d-flex mb-2"
                  style={{ justifyContent: 'flex-end', alignItems: 'center' }}
                >
                  <div
                    className="mt-3"
                    style={{ width: '17em', maxWidth: '100%' }}
                  >
                    <div
                      className="d-flex mb-4"
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <span className="d-block font-weight-bold">
                          Total Order:
                        </span>
                      </div>
                      <div>
                        <span>₦ {Number(dataItem.total).toLocaleString()}</span>
                      </div>
                    </div>
                    {/* <div className="d-flex mb-4" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <span className="d-block font-weight-bold">Shipping fee:</span>
                                        </div>
                                        <div>
                                            <span>₦ {data.shipping_fee}</span>
                                        </div>
                                    </div> */}
                    <div
                      className="d-flex mb-4"
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <span className="d-block font-weight-bold">Total:</span>
                      </div>
                      <div>
                        <span>
                          ₦{' '}
                          {(
                            dataItem.total &&
                            Number(dataItem.total) +
                              Number(dataItem.shipping_fee)
                          ).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div> */}
              <div className={`${styles.buttons}  pt-2`}>
                <button
                  className={`${styles.go_to} d-block w-100`}
                  onClick={processCartClose}
                >
                  Close
                  <span>
                    {checkoutLoading && (
                      <CircularProgress
                        style={{ color: '#ffff' }}
                        size="1rem"
                      />
                    )}
                  </span>
                </button>
              </div>
              {/* <div className={`${styles.buttons}  pt-2`}>
                                <button className={`${styles.cancel} `} onClick={handleGoToCartClose}>
                                    Cancel
                                </button>
                                &nbsp;
                                <button className={`${styles.go_to} `} onClick={() => navigate('/app/orders')}>

                                    Go to orders
                                </button>

                            </div> */}
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={warning}
        onClose={handleWarningCartClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={warning}>
          <Box className={`mobile_modal_size ${styles.modal_bg}`} sx={style1}>
            <div className={`text-center ${styles.add_to_cart_content}`}>
              <img src="/assets/icons/caution.svg" alt="" />

              <h5 className="mt-3 font-weight-bold">Insufficient fund </h5>
              <div className={`${styles.buttons}  pt-2`}>
                <button
                  className={`${styles.go_to} `}
                  onClick={() => navigate('/app/wallets')}
                >
                  Top up wallet
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Header emitCount={dataItem.items.length} />
    </>
  );
}
