

// ----------------------------------------------------------------------
import styles from "../BrandOwner.module.css"
import TextField from '@mui/material/TextField';
import bus from 'src/bus';
import {
  Card,
} from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
import * as React from 'react';
import AdDuration from "../Modals/AdDuration";
import AudienceModal from "../Modals/AudienceModal";

// sections
// mock



export default function SponsoredAd() {

  return (
    <>
      <Card className="mt-2">
        <div className={` ${styles.ads_wrapper_} mb-5`} id="updated">

          <div className="">
            <div className=" px-3"> 
              <h6 className="font-weight-bold">Spnsored Ads</h6>
              <p style={{ fontSize: '12px' }} className="text-muted">
                Create and push ads to your Stores and shoppers here.
              </p>

            </div>
            <div className={`${styles.sub_header}  px-3 row`}>


              <div className={`${styles.p_info} col-8 px-0`}>
                <h6 className="m-0 my-1 font-weight-bold">Ad Posts</h6>
                <p style={{ fontSize: '12px' }} className="py-2">Share an advert to FlexDeals users</p>
              </div>
              <div className={`${styles.edit_btn} col-4 px-0 text-right `}>
                <button className={`${styles.btn} btn btn-[#53138D] `}>Send</button>
              </div>
            </div>

            <div className={`${styles.row} row`}>
              <div className="col-md-3 pt-3" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column' }}>
                <p className="m-0 font-weight-bold">Ad Post</p>
                <p style={{ fontSize: '12px' }}>Please upload an image of the Ad</p>
              </div>
              <div className="col-md-5 p-0">
                <div className={`${styles.row} row`} style={{ borderTop: 'none', padding: '0', margin: '0' }}>
                  <div className="col-md-12 mx-0 px-0">
                    <div className={`${styles.cstm_file} custom-file`}>
                      <input type="file" className="custom-file-input" id="customFile" />
                      <div className={`${styles.cstm_file_label} custom-file-label`} for="customFile">
                        <p className="p-0 m-0 mt-3 mb-2"><i className="bi bi-cloud-arrow-up"></i></p>
                        <p><span>Click to upload</span> or drag and drop</p>
                        <p>PNG, JPG or PDF</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.row} row`}>
              <div className="col-md-3">
                <p style={{ padding: '0', margin: '0', lineHeight: '1' }}>Ad description</p>
                <small className="text-muted p-0" style={{ fontSize: '10px', lineHeight: '0px' }}>Write a short introduction</small>
              </div>
              <div className="col-md-5 py-md-0 py-3 px-0">
                <div className="form-group">
                  <textarea rows="4" className="form-control" id="bizName" placeholder="Write a brief description about your business..."></textarea>
                  <small className="text-muted p-0" style={{ fontSize: '10px', lineHeight: '0px' }}><span>275</span> characters left</small>
                </div>
              </div>
            </div>

            <div className={`${styles.row} row`}>
              <div className="col-md-3">
                <p style={{ padding: '0', margin: '0', lineHeight: '1' }}>Ad Duration</p>
              </div>
              <div className="col-md-5 py-md-0 py-3 px-0">
                <div className="form-group relative_position1">
                  <TextField
                    required
                    type='number'
                    id="price"
                    placeholder="Select Durtion"
                    size="small"
                    disabled
                    className="w-100"

                  />
                  <img onClick={() => bus.emit('open-sponsored-add')} className="img cursor_pointer" src="/assets/icons/ShapeRight.svg" alt="" />
                </div>
              </div>
            </div>

            <div className={`${styles.sub_header} ${styles.row} row pt-3 mb-5`}>
              <div className="col-sm-12 pl-0 relative_position1">
                <h6 className="mb-1 text-muted" style={{ fontSize: '14px' }}>Push Notification</h6>
                <TextField
                  required
                  type='number'
                  id="price"
                  placeholder="Select Target Audience"
                  size="small"
                  disabled
                  className="w-100"


                />
                <img className=" cursor_pointer" onClick={() => bus.emit('open-audience')} src="/assets/icons/ShapeRight.svg" alt="" />

              </div>
            </div>
            <Card style={{ marginTop: '50px', borderRadius: '10px' }} className='p-0'>
              <div className='p-4 d-md-flex justify-content-between'>
                <div>
                  <h5 className="table_desc_header">
                    Recent Ads
                  </h5>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}>
                    <small style={{ fontWeight: '200', fontSize: '12px' }}>
                      Your Recent Ads history
                    </small>


                  </div>

                </div>
              </div>
              <Scrollbar>
                <div className={`table-responsive-sm `}  >
                  <table className={`${styles.table} table-striped `}>
                    <thead>
                      <tr className={`${styles.tr} ${styles.thead_tr} pb-3`}>
                        <td>Description</td>
                        <td>Date Placed</td>
                        <td>Price</td>
                      </tr>
                    </thead>

                    <tbody className="">
                      <tr className={`${styles.tr} `}>
                        <td>
                          <p>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit....
                          </p>
                        </td>
                        <td>
                          25/02/22
                        </td>
                        <td>
                          ₦270,630
                        </td>
                        <td>
                          <span className="text-[#53138D] cursor_pointer">
                            Download

                          </span>
                        </td>
                      </tr>
                      <tr className={`${styles.tr} `}>
                        <td>
                          <p>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit....
                          </p>
                        </td>
                        <td>
                          25/02/22
                        </td>
                        <td>
                          ₦270,630
                        </td>
                        <td>
                          <span className="text-[#53138D] text-white cursor_pointer">
                            Download

                          </span>
                        </td>
                      </tr>
                      <tr className={`${styles.tr} `}>
                        <td>
                          <p>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit....
                          </p>
                        </td>
                        <td>
                          25/02/22
                        </td>
                        <td>
                          ₦270,630
                        </td>
                        <td>
                          <span className="text-[#53138D] cursor_pointer">
                            Download

                          </span>
                        </td>
                      </tr>
                    </tbody>

                  </table>

                </div>


              </Scrollbar>
              <div className="card mt-2 border-0 p-0 m-0">
                <div className="card-body pt-0 mt-0">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-item-center pt-2">
                      Page 1 of <b className="pl-1"> 5</b>
                    </div>
                    <div className={`${styles.pagination_button_container}`}>
                      <button className="btn">Previous</button>
                      &nbsp;
                      <button className="btn">Next</button>
                    </div>

                  </div>

                </div>
              </div>

            </Card>
          </div>
        </div>

      </Card>
      <AdDuration />
      <AudienceModal />


    </>
  )

}