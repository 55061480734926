import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

function TimeAgo({ time }) {
  const [timeElapsed, setTimeElapsed] = useState('');

  useEffect(() => {
    const calculateTimeElapsed = () => {
      const timeDate = new Date(time);
      const currentDate = new Date();

      const seconds = Math.floor((currentDate - timeDate) / 1000);
      let interval = Math.floor(seconds / 31536000);

      if (interval) {
        setTimeElapsed(`${interval} ${interval === 1 ? 'year' : 'years'} ago`);
        return;
      }
      interval = Math.floor(seconds / 2592000);
      if (interval) {
        setTimeElapsed(`${interval} ${interval === 1 ? 'month' : 'months'} ago`);
        return;
      }
      interval = Math.floor(seconds / 86400);
      if (interval) {
        setTimeElapsed(`${interval} ${interval === 1 ? 'day' : 'days'} ago`);
        return;
      }
      interval = Math.floor(seconds / 3600);
      if (interval) {
        setTimeElapsed(`${interval} ${interval === 1 ? 'hour' : 'hours'} ago`);
        return;
      }
      interval = Math.floor(seconds / 60);
      if (interval >= 30) { // If more than 30 minutes ago
        setTimeElapsed(`${interval} ${interval === 1 ? 'minute' : 'minutes'} ago`);
        return;
      }
      // If less than 30 minutes ago, display normal time
      setTimeElapsed(`${moment(time).format('hh:mm A')}`);
    };

    calculateTimeElapsed();

    // Update time elapsed every 60 seconds
    const intervalId = setInterval(calculateTimeElapsed, 60000);

    return () => clearInterval(intervalId);
  }, [time]);

  return <span>{timeElapsed}</span>;
}
export default TimeAgo;
