import { useState } from "react"
import { useNavigate } from "react-router-dom";

// components
import Referrals from './components/referral';
import Vouchers from './components/voucher';
// components
// materialui
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// materialui


export default function PromotionTabs() {
    const [value, setValue] = useState('1');
    const navigate = useNavigate();

    const handleTabChange = (event, newValue) => {
        navigate('/app/promotions')
        setValue(newValue);
      };

    return (
        <div className="wrapper">
            <Box style={{padding:'0'}} className='aa' sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs ">
                        <Tab label="Vouchers" value="1" />
                        <Tab label="Referrals" value="2" />
                    </TabList>
                    </Box>
                    <TabPanel value="1" style={{paddingLeft:'0'}}>
                    <Vouchers  />
                    </TabPanel>
                    <TabPanel value="2" style={{paddingLeft:'0'}}>
                    <Referrals  />
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    )
}