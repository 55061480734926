

// ----------------------------------------------------------------------
import styles from "./BrandOwner.module.css"
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useEffect, useState } from "react";
import Products from "./SuperAdminComponent/Products";
import SingleView from "./SuperAdminComponent/SingleView";
import SingleView2 from "./SuperAdminComponent/SingleView2";
import PromoAndDiscount from "./SuperAdminComponent/PromoAndDiscount";
import PromoAndDiscountSingleView from "./SuperAdminComponent/PromoAndDiscountSingleView";
import SponsoredAd from "./SuperAdminComponent/SponsoredAd";
import AwaitingApproval from "./SuperAdminComponent/AwaitingApproval";
import ProductTags from "./SuperAdminComponent/ProductTags";
import axiosInstance from "src/axiosInstance"
import toast from 'react-hot-toast';

import { useAuth } from "src/hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";

export default function ProductBrandOwnerPage() {

  const [value, setValue] = useState('1');
  const { data } = useAuth();
  const navigate = useNavigate();
  const [count, setCount] = useState([]);
  const { categoryId } = useParams();
  const notifyError = (err) => toast.error(err);
  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (event, newValue) => {
    navigate('/app/products/')
    setValue(newValue);
  };

  const hasPermission = (permission) => {
    const authData = JSON.parse(localStorage.getItem('auth')) || [];
    return authData?.direct_permissions?.some((el => el.permission_alias === permission))
  };

  const fetch = () => {

    axiosInstance.get(`/admin/product/awaiting-approval`)
      .then(res => {
        setCount(res.data.data.data)

      }).catch(err => {

        if (err) {
          notifyError(err.response.data.message);
    
        }
      })
  }

  useEffect(() => {
    if(hasPermission('view-awaiting-approval')) {
      fetch()
    }
    // hasPermission()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabClick = (tabValue) => {
    setActiveTab(tabValue);
    localStorage.setItem('tab', activeTab)
  };



  return (
    <>
      <div className="wrapper mt-4 mt-md-0 px-2">
        <div className="header d-block d-md-none mb-4">
          <div className={`${styles.product_actions} d-md-flex justify-content-between`}>
            <div className=" ">
              <h4 className={`mb-0 pb-0`}>Products</h4>
              <p style={{ fontSize: '14px' }} className={`mb-0 mt-2 pb-0`}>
                Review and manage product categories
              </p>

            </div>
            {/* <div className={`${styles.product_button} mt-3 mt-md-0`}>
            <button type="button"  className={`btn ${styles.top_up_button}`}>
              <span>
              <img  src="/assets/icons/plus.svg" className="pr-1" alt="" />

              Add Category
              </span>
            </button>
            

          </div> */}


          </div>


        </div>
        <TabContext value={value}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs ">
            <Tab label="Products" value="1" onClick={() => handleTabClick("1")} />
            <Tab label="Promos and Discounts " value="2" onClick={() => handleTabClick("2")} />
            {hasPermission('view-awaiting-approval') && (
              <Tab
                label={
                  <span>
                    <span className="font-weight-bold">Awaiting Approval</span>{" "}
                    {activeTab !== "3" && (
                      <span className="success">{Number(count.length).toLocaleString()}</span>
                    )}
                  </span>
                }
                value="3"
                onClick={() => handleTabClick("3")}
              />
            )}
            <Tab label="Product Tags" value="4" onClick={() => handleTabClick("4")} />
            {data.user_type !== "admin" &&
              <Tab label="Sponsored Ads" value="3" />
            }
          </TabList>
          <TabPanel className="mt-0 pt-0" value="1" style={{ paddingLeft: '0' }}>
            {categoryId === undefined &&
              <Products key={3} />
            }
            {categoryId !== undefined &&
              <SingleView categoryId={categoryId} key={3} />
            }
          </TabPanel>
          <TabPanel className="mt-0 pt-0" value="2" style={{ paddingLeft: '0' }}>
            {categoryId === undefined &&
              <PromoAndDiscount key={4} />
            }

            {categoryId !== undefined &&
              <PromoAndDiscountSingleView categoryId={categoryId} key={3} />
            }

          </TabPanel>
          <TabPanel className="mt-0 pt-0" value="3" style={{ paddingLeft: '0' }}>
            {categoryId === undefined &&
              <AwaitingApproval key={4} />
            }

            {categoryId !== undefined &&
              <SingleView categoryId={categoryId} key={3} />
            }

          </TabPanel>
          <TabPanel className="mt-0 pt-0" value="4" style={{ paddingLeft: '0' }}>
            {categoryId === undefined &&
              <ProductTags key={4} />
            }

            {categoryId !== undefined &&
              <SingleView2 categoryId={categoryId} key={3} />
            }

          </TabPanel>
          {data.user_type !== "admin" &&
            <TabPanel className="mt-0 pt-0" value="3" style={{ paddingLeft: '0' }}>
              <SponsoredAd key={6} />
            </TabPanel>

          }
        </TabContext>


      </div>

    </>
  );
}
