import GoogleMapReact from "google-map-react";
import { useEffect, useRef, useState } from "react";
import { useApiKeys } from "src/hooks/useApiKeys";
import TextField from "@mui/material/TextField";
import styles from "./Outlet.module.css";
import axiosInstance from "src/axiosInstance";
import toast from "react-hot-toast";
import { BallTriangle } from "react-loader-spinner";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
// import 'node_modules/react-tooltip/dist/react-tooltip.[mode].js'

export default function Map({ locations }) {
  const defaultLocation = locations.length > 0 ? locations[0] : null;
  const [center, setCenter] = useState(defaultLocation);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const mapRef = useRef(null);
  const [isPending, setIsPending] = useState(false);
  const notifyError = (err) => toast.error(err);



  const APiKeys = useApiKeys();
  const mapStyles = [
    {
      featureType: "all",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: "0",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          hue: "gray",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];
  const handleMarkerClick = (location) => {
    setCenter({ lat: location.lat, lng: location.lng });
  };

  const handleProcess = (data) => {
    setShow(false);
    handleMarkerClick(data);
    const map = mapRef.current;
    if (map) {
      mapRef.current = {
        ...map,
        center: {
          lat: data.lat,
          lng: data.lng
        }
      };
    }
  };

  const fetchData = async (
  ) => {
    if (search === "" && !show) return;
    setIsPending(true);

    try {
      const res = await axiosInstance.get(`brand/store/get-stores?perpage=1000&search=` + search);

      setData(res.data.data.data);
      setShow(true)

      setIsPending(false);
    } catch (err) {
      setIsPending(false);
      notifyError(err.response.data.message);
    }
  };

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <div
      className="border border-radius p-2 mt-3"
      style={{ height: "700px", width: "100%", position: "relative", zIndex:'10000' }}
    >
      <div className={`${styles.myMapRow} d-flex w-100 px-3`}  style={{
        height:'300px',
        overflow:'scroll'
      }}>
        <div className="map_filter">
          <div>
            <TextField
              id="businessPhone"
              placeholder="Search Store"
              size="small"
              value={search}
              type="text"
              onChange={(e) => setSearch(e.target.value)}
              fullWidth

            />
          </div>
          {data.length > 0 && !isPending && (
            <div className="info text-muted">
              {
                data.map((el, i) => (
                  <div className="p-2 card border-0" key={i}>
                    <div className="card  mt-3" style={{ borderRadius: "14px" }}>
                      <div className="card-body">
                        <p>{el.store_name}</p>
                        <p className="text-center">{el.store_address}</p>
                        <div>
                          <img src={el.business_image} alt="" style={{width: '100%'}} />
                        </div>
                        <button
                          onClick={() => {
                            handleProcess({
                              lat: Number(el.latitude),
                              lng: Number(el.longitude),
                              name: el.store_name
                            })
                          }}
                          className="btn-block btn btn_primary btn-lg"
                        >
                          View Store
                        </button>
                      </div>
                    </div>
                  </div>

                ))
              }
            </div>
          )}
          <div className="py-3 d-flex justify-content-center">
            {isPending && (
              <BallTriangle
                height={50}
                width={55}
                radius={5}
                color="#53138D"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            )}

          </div>

        </div>
      </div>
      <GoogleMapReact
        ref={mapRef}
        bootstrapURLKeys={{ key: APiKeys.GOOGLE_MAPS.KEY }}
        defaultCenter={
          defaultLocation
            ? { lat: defaultLocation.lat, lng: defaultLocation.lng }
            : null
        }
        center={center}
        defaultZoom={12}
        options={{
          styles: mapStyles,
          backgroundColor: "black",
        }}
      >
        {locations.map((location, index) => (
          <LocationMarker
            key={index}
            lat={location.lat}
            lng={location.lng}
            name={location.name}
            address={location.store_address}
          />
        ))}
      </GoogleMapReact>
      
    </div>
  );
}

function Marker({ name }) {
  return <div>{name}</div>
}
// Define a custom component for the marker
function LocationMarker({ name, address }) {
  return (
    <div
      style={{
        position: "relative",
        color: "red",
        fontWeight: "bold",
        fontSize: "14px",
      }}
    >
      <img src="/assets/icons/mapIndicator.svg" alt="" data-tooltip-id="my-tooltip" data-tooltip-content={address}/>
      <div>{name}</div>
      <Tooltip id="my-tooltip" place="top" />
    </div>
  )
}
