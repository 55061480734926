import styles from '../Sales.module.css';

import { Card } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import SalesDetails from '../SalesDetails';
import { useEffect, useState, forwardRef } from 'react';
import { useLayoutEffect } from 'react';
import GlobalModal from 'src/Modals/GlobalModal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useAuth } from 'src/hooks/useAuth';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import { useDropDowns } from 'src/hooks/useDropDowns';
import { useCategories } from 'src/hooks/useCategories';
import { useProducts } from 'src/hooks/useProducts';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import moment from 'moment';
import { BallTriangle } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';
import { Pagination } from 'src/components';
// ----------------------------------------------------------------------
import Filter1 from 'src/pages/Filter/Filter1';

const TABLE_HEAD = [
  { id: 'duration', label: 'Duration', alignRight: false },
  { id: 'dateCreated', label: 'Date Created', alignRight: false },
  { id: 'subFee', label: 'Subscription Fee (#)', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function changeView(d) {
  localStorage.setItem('ptvs', d);
}
const style2 = {
  position: 'absolute',
  height: '200px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: '#FFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  // boxShadow: 24,
  p: 3,
};
// ptvs=PAGE TO VIEW (SALES)

let app_view = localStorage.getItem('ptvs') ?? 0;
export default function ShopOwners() {
  const { data } = useAuth();
  const dropdownFilters = useDropDowns();
  const categories = useCategories();
  const productLists = useProducts();

  const [appView, setAppview] = useState(app_view);
  const [product, setProduct] = useState(1);
  const [productsAdAdded, setProductsAdded] = useState([]);
  const [category, setCategory] = useState(1);
  const [showDiv, setShowDiv] = useState(data.merchant_type === 'brand');
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [adsPostLoading, setAdsPostLoading] = useState(false);
  const [editAdsPostLoading, setEditAdsPostLoading] = useState(false);
  const [postAd, setPostAd] = useState(false);
  const [editAd, setEditAd] = useState(false);
  // const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl1);
  const [selectedFilter, setValue] = useState(dropdownFilters[0]?.name);
  const [selectedFilter1, setValue1] = useState(dropdownFilters[0]?.name);
  const [viewAdData, setViewAdData] = useState({
    subscription_fee: 0,
    duration: '',
    createdAt: '',
  });
  const [handleViewAdData, setHandleViewAdData] = useState('');
  const [searchvalue, setSearchvalue] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [adsSubs, setAdsSubs] = useState([]);
  const [dataPagination, setDataPagination] = useState('');
  const [filterSearch, setFilterSearch] = useState({
    duration: '',
    subscription_fee: '',
  });
  const [addPost, setAddPost] = useState({
    duration: '',
    subscription_fee: '',
    type: 'Months',
  });
  const notify = (msg = null) =>
    toast.success(msg === null ? 'Store saved .' : msg);
  const notifyError = (err) => toast.error(err);

  // const [action_type, setActionType] = useState('Add')
  // const [url, setUrl] = useState('');
  // const [open1, setOpen1] = useState(false);
  const [newProducts, setNewProducts] = useState({
    notifications: [],
    product_name: '',
    product_image: null,
    price_per_case: '',
    price_per_product: '',
    number_of_cases: '',
    products_in_case: '',
    description: '',
    cases_type: 'Months',
    category_id: '',
  });

  //   const handleClose1 = () => {
  //     setNewProducts({
  //         notifications: [],
  //         product_name: '',
  //         product_image: null,
  //         price_per_case: '',
  //         price_per_product: '',
  //         number_of_cases: '',
  //         products_in_case: '',
  //         description: '',
  //         cases_type: 'Cartons',
  //         category_id: '',

  //     })
  //     setActionType('Add')
  //     setUrl('')
  //     setOpen1(false)
  // };

  const [modalOpen, setModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [openDialog, setDiologOpen] = useState(false);
  const [deleteValue, setDeleteValue] = useState({});
  const [delFromStore, setDelFromStore] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDialogClose = () => {
    // setSuccessOpen(false);
    setDiologOpen(false);
  };
  const handleClose = () => {
    setModalOpen(false);
    setViewModalOpen(false);
  };

  const handleOpenDialog = (val) => {
    setDiologOpen(true);
    setDeleteValue(val);
  };

  const handleAddPostChange = (key, value) => {
    setAddPost((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleAdsPost = (e) => {
    e.preventDefault();
    setAdsPostLoading(true);
    axiosInstance
      .post('/admin/pricing/ads_subscription/create', {
        name: addPost.name,
        type: addPost.duration + ' ' + addPost.type,
        duration: addPost.duration + ' ' + addPost.type,
        subscription_fee: addPost.subscription_fee,
      })
      .then((res) => {
        setModalOpen(false);
        if (res.status < 300) {
          // setSuccessOpen(true);
          notify(res.data.message);
          setPostAd(!postAd);
          setAddPost({
            duration: '',
            subscription_fee: '',
            type: 'Months',
          });
        } else {
          notifyError(res.data.message);
        }
      })
      .finally(() => setAdsPostLoading(false));
  };

  const handleEdit = () => {
    setEditAdsPostLoading(true);
    setViewModalOpen(false);
    axiosInstance
      .put('/admin/pricing/ads_subscription/update', {
        duration: viewAdData.duration,
        subscription_fee: viewAdData.subscription_fee,
        id: viewAdData._id,
      })
      .then((res) => {
        if (res.status < 300) {
          setSuccessOpen(true);
          setEditAd(true);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Something went wrong , please try again later');
        }
      })
      .finally(() => setEditAdsPostLoading(false));
  };

  const handleDeleteSubscription = () => {
    setIsDeleting(true);
    setDiologOpen(false);
    axiosInstance
      .delete('/admin/pricing/ads_subscription/delete/' + deleteValue._id)
      .then((res) => {
        if (res.status < 300) {
          setDelFromStore(!delFromStore);
          notify(res.data.message);
          // fetchData(currentPage, itemsPerPage)
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to delete store, try again later');
        }
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const handleViewClose = () => {
    setViewModalOpen(false);
  };
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handleProductChange = (event) => {
    setProduct(event.target.value);
    let data = productsAdAdded;
    let val = event.target.value;
    if (val != 1) {
      if (data.includes(val) == false) {
        data.push(val);
        setProductsAdded(data);
      }
    }
  };

  const handleDurationChange = (key, value) => {
    console.log(key, value);
    setAnchorEl1(null);
    setAddPost((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const removeFromList = (index) => {
    let data = [...productsAdAdded];
    data = data.filter((_, i) => i !== index);
    if (data['length'] === 0) setProduct(1);
    setProductsAdded(data);
  };

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvs');
    },
    []
  );

  const handleViewClick = (d) => {
    navigate('/app/sales_details');
    // handle view click action
  };

  const handleviewAdDataChange = (e) => {
    const { name, value } = e.target;
    setViewAdData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleViewAdClick = (val) => {
    setViewModalOpen(true);
    setViewAdData(val);
  };

  const handleChange = (/* args passed from the child component */) => {
    handleViewClick(0);
  };

  const handleClickList = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClickList1 = (e) => {
    setAnchorEl1(e.currentTarget);
  };
  const handleSelectFilter = (val, i) => {
    switch (i) {
      case 0:
        setAnchorEl(null);
        setValue(val);

        break;
      case 1:
        setAnchorEl1(null);
        setValue1(val);
        break;

      default:
        break;
    }
  };

  const fetchData = (page = 1, perpage = 10) => {
    let filter_payload = {};
    if (filterSearch.transaction_min_price) {
      filter_payload.transaction_min_price = filterSearch.transaction_min_price;
    }
    if (filterSearch.transaction_max_price) {
      filter_payload.transaction_max_price = filterSearch.transaction_max_price;
    }
    if (searchvalue != '') {
      filter_payload.search = searchvalue;
    }
    if (filterSearch.dateStart != '') {
      filter_payload.dateStart = filterSearch.dateStart;
    }
    if (filterSearch.dateEnd != '') {
      filter_payload.dateEnd = filterSearch.dateEnd;
    }
    if (filterSearch.transaction_type != '') {
      filter_payload.transaction_type = filterSearch.transaction_type;
    }
    if (
      filterSearch.transaction_status != '' &&
      filterSearch.transaction_status != 'all'
    ) {
      filter_payload.transaction_status = filterSearch.transaction_status;
    }
    setIsPending(true);
    // handleClose(false)
    axiosInstance
      .get('/admin/pricing/ads_subscription?', {
        params: {
          page,
          perpage,
          ...filter_payload,
        },
      })
      .then((res) => {
        setAdsSubs(res.data.data.data);
        setDataPagination(res.data.data.pagination);
        setFilterSearch({
          transaction_status: '',
          transaction_type: '',
          dateEnd: '',
          dateStart: '',
          transaction_max_price: '',
          transaction_min_price: '',
        });
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch pricing, please try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  useEffect(() => {
    fetchData();
  }, [searchvalue, editAd, postAd, delFromStore]);

  return Number(appView) === 0 ? (
    <>
      <div className="pt-4">
        <div className={`${styles.analytics}  `}></div>
        <div className={`mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-md-3 col-6   `}>
              {/* <img src="/assets/icons/search.svg" alt="" />
              <input type="text" placeholder="Search" style={{ textIndent: '25px', width: '100%' }} className=" form-control" /> */}
            </div>
            <div className="col-md-9 col-6  text-right">
              <button
                className="btn btn-[#8c2d915d] text-white"
                onClick={() => setModalOpen(true)}
                style={{
                  background: '#af3585',
                }}
              >
                Create Ad fee
              </button>
            </div>
          </div>
        </div>

        <Card
          style={{ marginTop: '50px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-3 ">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">Ads Subscription</h5>
                <small style={{ fontWeight: '200', fontSize: '12px' }}>
                  Manage and create service change
                </small>
              </div>
              {/* <div className="pt-md-2">
                <div className="dropleft ">
                  <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className={`${styles.export_btn} btn m-0`}>
                    <span style={{
                      paddingTop: '8px',


                    }}>
                      <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                      Export
                    </span>
                    &nbsp;
                    &nbsp;
                    <span style={{
                      borderLeft: '1px solid white',
                      display: 'inline-block',
                      height: 'inherit',
                      paddingTop: '8px',
                      paddingLeft: '8px',
                      textAlign: 'center',

                    }}>
                      <img src="/assets/icons/arrowDown.svg" alt="" />

                    </span>


                  </button>

                  <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                    <button type="button" className="rss dropdown-item btn border-0">CSV</button>
                    <button type="button" className="rss dropdown-item btn border-0">Excel </button>
                    <button type="button" className="rss dropdown-item btn border-0">PDF </button>
                  </div>
                </div>


              </div> */}
            </div>
          </div>
          <Scrollbar>
            <div className={`pt-3 pb-3 pl-0 pr-0`}>
              <table className={`${styles.table} table-hover table-striped `}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      let sIndex = i < 1 ? 1 : false;
                      return (
                        <th key={i} className="text-muted">
                          {el.label}{' '}
                          {sIndex && (
                            <span style={{ cursor: 'pointer' }}>
                              <img
                                className="pl-2"
                                src="/assets/icons/down.svg"
                                alt=""
                              />
                            </span>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {!isPending &&
                    adsSubs.map((el, i) => (
                      <tr key={i} className={`${styles.tr}`}>
                        <td>
                          {el.duration}{' '}
                          {el.type
                            .split('_')
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(' ')}
                        </td>
                        <td>
                          <p>
                            <span>
                              {moment(el.createdAt).format('DD/MM/YYYY')}
                            </span>{' '}
                            <br />
                            <small>
                              {moment(el.createdAt).format('HH:mm')} PM
                            </small>
                          </p>
                        </td>

                        <td>{Number(el.subscription_fee).toLocaleString()}</td>

                        <td>
                          <div className="dropleft ">
                            <span
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              className={`${styles.action_button} px-3 py-2`}
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <img src="/assets/icons/action.svg" alt="" />
                            </span>

                            <div
                              className="dropdown-menu drop-left"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <button
                                type="button"
                                onClick={() => handleViewAdClick(el)}
                                className="rss dropdown-item btn border-0"
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                onClick={() => handleOpenDialog(el)}
                                className="rss dropdown-item btn border-0"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!adsSubs.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={dataPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="640px !important"
        overflow="auto"
        bgcolor="#FFFFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Ad Post
            </h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form onSubmit={handleAdsPost} className="mt-0">
            <div className="row">
              <div className="col-12">
                <div className="mb-3" style={{ position: 'relative' }}>
                  <span
                    style={{
                      fontWeight: 100,
                    }}
                  >
                    Post Duration
                  </span>

                  <TextField
                    required
                    id="price"
                    type="number"
                    size="small"
                    name="duration"
                    value={addPost.duration}
                    placeholder="Enter Duration"
                    fullWidth
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue === '' || parseFloat(inputValue) > 0) {
                        handleAddPostChange('duration', inputValue);
                      }
                    }}
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                    onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e' || e.key === '.') {
                        e.preventDefault();
                      }
                    }}
                  />
                  <div className="absolute_input">
                    <small>
                      {/* Months */}
                      {addPost.type}
                    </small>
                    &nbsp;
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={handleClickList1}
                    >
                      <img src="/assets/icons/downicon.svg" alt="" />
                    </span>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl1}
                      open={open2}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {['Months', 'Years', 'Days'].map((count) => (
                        <MenuItem
                          onClick={() => handleDurationChange('type', count)}
                        >
                          {count}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <label
                  htmlFor="rxfee"
                  style={{
                    fontWeight: 100,
                  }}
                >
                  FlexDeals Fee (#)
                </label>
                <input
                  type="number"
                  value={addPost.subscription_fee}
                  name="subscription_fee"
                  onChange={(e) =>
                    handleAddPostChange('subscription_fee', e.target.value)
                  }
                  placeholder="Enter Amount"
                  className="form-control"
                />
              </div>
            </div>
            <div className="mb-4">
              <button
                type="submit"
                className={`${styles.modal_btn} btn btn-block`}
              >
                Create{' '}
                {adsPostLoading && (
                  <CircularProgress style={{ color: '#fff' }} size="1rem" />
                )}
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>

      {/* Modals */}
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="text-center">
                <img
                  style={{ width: '40px' }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <h5>Delete</h5>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6B7280',
                  }}
                >
                  Are you sure you want to delete this outlet? All your data
                  will be permanently removed from our servers forever. This
                  action cannot be undone.
                </p>
              </div>
            </div>

            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={() => handleDialogClose()}
              >
                Cancel
              </Button>
              {!isDeleting && (
                <Button
                  className={`${styles.delete_product}`}
                  onClick={handleDeleteSubscription}
                >
                  Delete
                </Button>
              )}

              {isDeleting && (
                <button disabled className={`btn ${styles.delete_product}`}>
                  {isDeleting && (
                    <CircularProgress style={{ color: 'white' }} size="1rem" />
                  )}
                </button>
              )}
            </DialogActions>
          </div>
        </div>
      </Dialog>

      <GlobalModal
        open={viewModalOpen}
        onClose={handleViewClose}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="600px !important"
        overflow="auto"
        bgcolor="#FFFFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-4`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Ad Post
            </h5>
            <span onClick={handleViewClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form action="" className="mt-0">
            <h3>{viewAdData.duration}</h3>
            <div className="row">
              <div className="col-md-6 text-muted">
                <p className="mt-2 pt-1">FlexDeals Fee (#)</p>
              </div>
              <div className="col-md-6">
                <input
                  type="number"
                  value={viewAdData.subscription_fee}
                  onChange={handleviewAdDataChange}
                  name="subscription_fee"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 mb-3 text-muted">
                <span className="mt-1 pt-1">
                  {moment(viewAdData.createdAt).format('HH/MM/YYYY')}
                </span>
                <small style={{ fontSize: '12px', marginLeft: '10px' }}>
                  {moment(viewAdData.createdAt).format('HH:mm')} PM
                </small>
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={handleEdit}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Done{' '}
                {editAdsPostLoading && (
                  <CircularProgress style={{ color: '#fff' }} size="1rem" />
                )}
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={successOpen}
        onClose={() => handleDialogClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={successOpen}>
          <Box className={`mobile_modal_size modal_bg`} sx={style2}>
            <div className={`text-center add_to_cart_content`}>
              <img src="/assets/icons/circleCheck.svg" alt="" />
              <h3 className="mb-2 mt-2">Successful</h3>
              <button
                onClick={() => handleDialogClose()}
                className={`modal_btn hover btn btn-block`}
              >
                Done
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  ) : (
    <SalesDetails key={1} onChange={handleChange} />
  );
}
