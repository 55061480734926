

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
import styles from './Auth.module.css'
import Logo from "../logo/appLogo"
import { NavLink as RouterLink } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import toast from 'react-hot-toast';
import axios from 'axios';

import { useContext, useState } from "react"
import { BaseUrlContext } from 'src/context/BaseUrlContext';

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const notify = (res) => toast.success(res);
  const notifyError = () => toast.error("That didn't work please try again later .");
  const [signUpError, setSignUpError] = useState(null);
  const [isPending,setIsPending] = useState(false);
  const baseUrl = useContext(BaseUrlContext);





  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  
  function HandleFormSubmit(e) {
    e.preventDefault();
    let payload = {
      email

    }
    const fetchData = async () => {
      try {
        setIsPending(true)
        setSignUpError(null)
        // Make the API request
        const res = await axios({
          method:"POST",
          url: `${baseUrl}/merchant/recover`,
          data: payload,
          headers: {
            'Authorization': `x-auth-token ${localStorage.getItem('token')}`,
          },
        });
        localStorage.setItem('e', payload.email)
        
        notify(res.data.message);
        
        
        setTimeout(() => {
          window.location.href='/reset'

        }, 2000);
      } catch (err) {
        try {
          setSignUpError(err.response.data.message);
          
        } catch (error) {
          setSignUpError("Unable to reset password, try again later");
          
          notifyError()
        }
      } finally {
        setIsPending(false)

  
      }
    };
    fetchData(true);
  }
  
  return (
    <>
    
    <div className={`row ${styles.sav}`}>

      <div className="col-md-6 d-none d-md-block">
        <div className={styles.bg} style={{backgroundImage:"linear-gradient(to right,  #52138d9f, #8c2d9176), url('/assets/happyMan.jpg"}}>
          <img className={styles.star} src='/assets/Stars.svg' alt='stars' />
          <div  className={styles.content}>
            <h5 className={`${styles.header_text}`}>
             Track and monitor <br /> your customers on <br /> a single dashboard
            </h5>
            <p>
             Make informed sales decisions by monitoring consumer <br /> behaviours, brand acceptance and market performance.
            </p>
            <div className={styles.icon_n_text}>
              <img className='icons'   src='/assets/Avatars.svg' alt='avatars' />
              <span style={{color: '#EAECF0'}}>
                Join 40,000+ users
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 ">
         <Logo />
        <div className={`${styles.sign_up} container pt-md-4`}>
            
            
          <form onSubmit={HandleFormSubmit} className={styles.form}>
              <h4 className='mb-4'  style={{
                  fontSize: '34px',
                  fontWeight: '600',
                  color: '#111827',


              }}>Forgot Password ?</h4>
              {( signUpError) &&       <Alert severity="error">{ signUpError}</Alert>}

              
              <div className='my-4 inputs_n_label'>
                <label htmlFor='email'>Email</label>
                <TextField
                    required
                    type='text'
                    id="email"
                    placeholder="Enter your email"
                    size="small"
                    value={email}
                    onChange={handleEmailChange}
                    fullWidth

                  />
              </div>
              
             
              <div className={styles.buttons}>
                {!isPending && 
                  <button  className={`btn btn-block ${styles.btn_custom_color}`}>
                    <span  className={`${styles.span}`}>
                    Send link 

                    </span>
                    {isPending && <CircularProgress style={{color:'white'}}  size="1rem"   />}
                  </button>}
                  {isPending &&   <button disabled  className={`btn btn-block ${styles.btn_custom_color}`}>
                    Sending link &nbsp;
                    {isPending && <CircularProgress style={{color:'white'}}  size="1rem"   />}
                  </button>}
                <p className='text-center'>
                  <RouterLink to='/login'>Back to Login </RouterLink>
                </p>

              </div>

          </form>
        </div>
      </div>
    </div>
  </>
  );
}
