export const policy = `<p>
This privacy notice for FLEXDEALS ("Company," "we," "us," or "our"),
describes how <br />
and why we might collect, store, use, and/or share ("process") your
information when you <br />
use our services ("Services"), such as when you:
</p>
<ol>
<h6>
  1. Download and use our mobile application, or any other application of ours
  that links to this privacy notice.
</h6>
<h6>
  2. Reading this privacy notice will help you understand your privacy rights  <br />
  and choices. If you do not agree with our policies and practices, please do  <br />
  not use our Services. If you still have any questions or concerns, please <br />
  contact us at support@flexdeals.com.ng
</h6>
</ol>
<p>
Reading this privacy notice will help you understand your privacy rights
<br />
and choices. If you do not agree with our policies and practices, please
<br />
do not use our Services. If you still have any questions or concerns,
<br />
please contact us at support@flexdeals.com.ng
</p>
<h3>SUMMARY OF KEY POINTS</h3>

<p>
This summary provides key points from our privacy notice, but you can find
<br />
out more details about any of these topics by using our table of contents
<br />
below to find the section you are looking for.
</p>
<ol>
<li>
  <p>
    What personal information do we process? When you visit, use, or
    <br />
    navigate our Services, we may process personal information depending
    <br />
    on how you interact with FLEXDEALS APP and the Services, the
    <br />
    choices you make, and the products and features you use.
  </p>
</li>

<li>
  <p>
    Do we process any sensitive personal information? We do not process
    <br />
    sensitive personal information.
  </p>
</li>

<li>
  <p>
    Do we receive any information from third parties? We may receive
    <br />
    information from public databases, marketing partners, social media
    <br />
    platforms, and other outside sources.
  </p>
</li>

<li>
  <p>
    How do we process your information? We process your information to
    <br />
    provide, improve, and administer our Services, communicate with you,
    <br />
    for security and fraud prevention, and to comply with law. We may also
    <br />
    process your information for other purposes with your consent. We
    <br />
    process your information only when we have a valid legal reason to do
    <br />
    so.
  </p>
</li>

<li>
  <p>
    In what situations and with which parties do we share personal <br />
    information? We may share information in specific situations and with
    <br />
    specific third parties.
  </p>
</li>

<li>
  <p>
    What are your rights? Depending on where you are located <br />
    geographically, the applicable privacy law may mean you have certain
    <br />
    rights regarding your personal information. Want to learn more about
    <br />
    what FLEXDEALS does with any information we collect? Review the
    <br />
    privacy notice in full.
  </p>
</li>
</ol>
<h3>TABLE OF CONTENTS</h3>
<ol>
<li>
  <h5>WHAT INFORMATION DO WE COLLECT?</h5>
</li>
<li>
  <h5>HOW DO WE PROCESS YOUR INFORMATION?</h5>
</li>
<li>
  <h5>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h5>
</li>
<li>
  <h5>HOW LONG DO WE KEEP YOUR INFORMATION?</h5>
</li>
<li>
  <h5>WHAT ARE YOUR PRIVACY RIGHTS?</h5>
</li>
<li>
  <h5>DO WE MAKE UPDATES TO THIS NOTICE?</h5>
</li>
<li>
  <h5>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h5>
</li>
</ol>
<ol>
<li>
  <h4>WHAT INFORMATION DO WE COLLECT?</h4>
  <ol style="color: black">
    <li>
      <p>
        Personal information you disclose to us. We collect personal
        <br />
        information that you voluntarily provide to us when you register
        <br />
        our application, express an interest in obtaining information
        <br />
        about us or our products and Services when you participate in
        <br />
        activities on the Services, or otherwise when you contact us.
      </p>
    </li>

    <li>
      <p>
        Sensitive Information. We do not process sensitive information.
        <br />
        All personal information that you provide to us must be true,
        <br />
        complete, and accurate, and you must notify us of any changes to
        <br />
        such personal information.
      </p>
    </li>

    <li>
      <p>
        Information automatically collected. Some information, such as
        <br />
        your Internet Protocol (IP) address and/or browser and device
        <br />
        characteristics is collected automatically when you visit our
        <br />
        Services.
      </p>
    </li>
  </ol>
  <p>
    We automatically collect certain information when you visit, use, or
    <br />
    navigate our Services. This information does not reveal your specific
    <br />
    identity (like your name or contact information) but may include
    <br />
    device and usage information, such as your IP address, browser and
    <br />
    device characteristics, operating system, language preferences, <br />
    referring URLs, device name, country, location, information about how
    <br />
    and when you use our Services, and other technical information. This
    <br />
    information is primarily needed to maintain the security and operation
    <br />
    of our Services, and for our internal analytics and reporting <br />
    purposes. Like many businesses, we also collect information through
    <br />
    cookies and similar technologies.
  </p>
</li>
<li>
  <h4>HOW DO WE PROCESS YOUR INFORMATION?</h4>
  <p>
    We process your information to provide, improve, and administer our
    <br />
    Services, communicate with you, for security and fraud prevention, and
    <br />
    to comply with law. We may also process your information for other
    <br />
    purposes with your consent.
  </p>
</li>
<li>
  <h4>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>
  <ol style="color: black">
    <li>
      <p>
        We may share information in specific situations described in this
        <br />
        section and/or with the following third parties. We may need to
        <br />
        share your personal information in the following situation
      </p>
    </li>
    <li>
      <p>
        Business Transfers. We may share or transfer your information in
        <br />
        connection with or during negotiations of, any merger, sale of
        <br />
        company assets, financing, or acquisition of all or a portion of
        <br />
        our business to another company.
      </p>
    </li>

    <li>
      <p>
        Affiliates. We may share your information with our affiliates, in<br />
        which case we will require those affiliates to honour this privacy<br />
        notice. Affiliates include our parent company and any<br />
        subsidiaries, joint venture partners, or other companies that we<br />
        control or that are under common control with us. Business<br />
        Partners. We may share your information with our business partners<br />
        to offer you certain products, services, or promotions.<br />
      </p>
    </li>
  </ol>
</li>

<li>
  <h4>HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
  <ol style="color: black">
    <li>
      <p>
        We keep your information for as long as necessary to fulfill the
        <br />
        purposes outlined in this privacy notice unless otherwise required
        <br />
        by law.
      </p>
    </li>
    <li>
      <p>
        We will only keep your personal information for as long as it is
        <br />
        necessary for the purposes set out in this privacy notice unless a
        <br />
        longer retention period is required or permitted by law (such as
        <br />
        tax, accounting, or other legal requirements).
      </p>
    </li>

    <li>
      <p>
        When we have no ongoing legitimate business need to process your<br />
        personal information, we will either delete or anonymize such<br />
        information, or, if this is not possible (for example, because<br />
        your personal information has been stored in backup archives),<br />
        then we will securely store your personal information and isolate<br />
        it from any further processing until deletion is possible.
      </p>
    </li>
  </ol>
</li>

<li>
  <h4>WHAT ARE YOUR PRIVACY RIGHTS?</h4>
  <ol style="color: black">
    <li>
      <p>You may review, change, or terminate your account at any time.</p>
    </li>
    <li>
      <p>
        In accordance to Reg 3.1(8) of the NDPR guarantee the right of a
        <br />
        data subject to request the data controller, without undue delay,
        <br />
        to rectify any inaccurate personal data concerning him or her. The
        <br />
        data subject may also request the inclusion of additional personal
        <br />
        data to provide complete information by providing a supplementary
        <br />
        statement. However, please note that this will not affect the
        <br />
        lawfulness of the processing before its withdrawal nor when <br />
        applicable law allows, will it affect the processing of your
        <br />
        personal information conducted in reliance on lawful processing
        <br />
        grounds other than consent.
      </p>
    </li>

    <li>
      <p>
        Account Information. If you would at any time like to review or
        <br />
        change the information in your account or terminate your account,
        <br />
        you can. Upon your request to terminate your account, we will
        <br />
        deactivate or delete your account and information from our active
        <br />
        databases. However, we may retain some information in our files to
        <br />
        prevent fraud, troubleshoot problems, assist with any <br />
        investigations, enforce our legal terms, and/or comply with <br />
        applicable legal requirements.
      </p>
    </li>
  </ol>
</li>

<li>
  <h4>DO WE MAKE UPDATES TO THIS NOTICE?</h4>
  <p>
    Yes, we will update this notice as necessary to stay compliant with
    <br />
    relevant laws. We may update this privacy notice from time to time.
    <br />
    The updated version will be indicated by an updated "Revised" date and
    <br />
    the updated version will be effective as soon as it is accessible. If
    <br />
    we make material changes to this privacy notice, we may notify you
    <br />
    either by prominently posting a notice of such changes or by directly
    <br />
    sending you a notification. We encourage you to review this privacy
    <br />
    notice frequently to be informed of how we are protecting your
    information.
  </p>
</li>

<li>
  <h4>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>
  <p>
    If you have questions or comments about this notice, you may email us
    <br />
    at support@flexdeals.com.ng
  </p>
</li>
</ol>
`;

export const terms = `<p>
This privacy notice for FLEXDEALS ("Company," "we," "us," or "our"),
describes how <br />
and why we might collect, store, use, and/or share ("process") your
information when you <br />
use our services ("Services"), such as when you:
</p>
<ol>
<h6>
  1. Download and use our mobile application, or any other application of ours
  that links to this privacy notice.
</h6>
<h6>
  2. Reading this privacy notice will help you understand your privacy rights  <br />
  and choices. If you do not agree with our policies and practices, please do  <br />
  not use our Services. If you still have any questions or concerns, please <br />
  contact us at support@flexdeals.com.ng
</h6>
</ol>
<p>
Reading this privacy notice will help you understand your privacy rights
<br />
and choices. If you do not agree with our policies and practices, please
<br />
do not use our Services. If you still have any questions or concerns,
<br />
please contact us at support@flexdeals.com.ng
</p>
<h3>SUMMARY OF KEY POINTS</h3>

<p>
This summary provides key points from our privacy notice, but you can find
<br />
out more details about any of these topics by using our table of contents
<br />
below to find the section you are looking for.
</p>
<ol>
<li>
  <p>
    What personal information do we process? When you visit, use, or
    <br />
    navigate our Services, we may process personal information depending
    <br />
    on how you interact with FLEXDEALS APP and the Services, the
    <br />
    choices you make, and the products and features you use.
  </p>
</li>

<li>
  <p>
    Do we process any sensitive personal information? We do not process
    <br />
    sensitive personal information.
  </p>
</li>

<li>
  <p>
    Do we receive any information from third parties? We may receive
    <br />
    information from public databases, marketing partners, social media
    <br />
    platforms, and other outside sources.
  </p>
</li>

<li>
  <p>
    How do we process your information? We process your information to
    <br />
    provide, improve, and administer our Services, communicate with you,
    <br />
    for security and fraud prevention, and to comply with law. We may also
    <br />
    process your information for other purposes with your consent. We
    <br />
    process your information only when we have a valid legal reason to do
    <br />
    so.
  </p>
</li>

<li>
  <p>
    In what situations and with which parties do we share personal <br />
    information? We may share information in specific situations and with
    <br />
    specific third parties.
  </p>
</li>

<li>
  <p>
    What are your rights? Depending on where you are located <br />
    geographically, the applicable privacy law may mean you have certain
    <br />
    rights regarding your personal information. Want to learn more about
    <br />
    what FLEXDEALS does with any information we collect? Review the
    <br />
    privacy notice in full.
  </p>
</li>
</ol>
<h3>TABLE OF CONTENTS</h3>
<ol>
<li>
  <h5>WHAT INFORMATION DO WE COLLECT?</h5>
</li>
<li>
  <h5>HOW DO WE PROCESS YOUR INFORMATION?</h5>
</li>
<li>
  <h5>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h5>
</li>
<li>
  <h5>HOW LONG DO WE KEEP YOUR INFORMATION?</h5>
</li>
<li>
  <h5>WHAT ARE YOUR PRIVACY RIGHTS?</h5>
</li>
<li>
  <h5>DO WE MAKE UPDATES TO THIS NOTICE?</h5>
</li>
<li>
  <h5>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h5>
</li>
</ol>
<ol>
<li>
  <h4>WHAT INFORMATION DO WE COLLECT?</h4>
  <ol style="color: black">
    <li>
      <p>
        Personal information you disclose to us. We collect personal
        <br />
        information that you voluntarily provide to us when you register
        <br />
        our application, express an interest in obtaining information
        <br />
        about us or our products and Services when you participate in
        <br />
        activities on the Services, or otherwise when you contact us.
      </p>
    </li>

    <li>
      <p>
        Sensitive Information. We do not process sensitive information.
        <br />
        All personal information that you provide to us must be true,
        <br />
        complete, and accurate, and you must notify us of any changes to
        <br />
        such personal information.
      </p>
    </li>

    <li>
      <p>
        Information automatically collected. Some information, such as
        <br />
        your Internet Protocol (IP) address and/or browser and device
        <br />
        characteristics is collected automatically when you visit our
        <br />
        Services.
      </p>
    </li>
  </ol>
  <p>
    We automatically collect certain information when you visit, use, or
    <br />
    navigate our Services. This information does not reveal your specific
    <br />
    identity (like your name or contact information) but may include
    <br />
    device and usage information, such as your IP address, browser and
    <br />
    device characteristics, operating system, language preferences, <br />
    referring URLs, device name, country, location, information about how
    <br />
    and when you use our Services, and other technical information. This
    <br />
    information is primarily needed to maintain the security and operation
    <br />
    of our Services, and for our internal analytics and reporting <br />
    purposes. Like many businesses, we also collect information through
    <br />
    cookies and similar technologies.
  </p>
</li>
<li>
  <h4>HOW DO WE PROCESS YOUR INFORMATION?</h4>
  <p>
    We process your information to provide, improve, and administer our
    <br />
    Services, communicate with you, for security and fraud prevention, and
    <br />
    to comply with law. We may also process your information for other
    <br />
    purposes with your consent.
  </p>
</li>
<li>
  <h4>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>
  <ol style="color: black">
    <li>
      <p>
        We may share information in specific situations described in this
        <br />
        section and/or with the following third parties. We may need to
        <br />
        share your personal information in the following situation
      </p>
    </li>
    <li>
      <p>
        Business Transfers. We may share or transfer your information in
        <br />
        connection with or during negotiations of, any merger, sale of
        <br />
        company assets, financing, or acquisition of all or a portion of
        <br />
        our business to another company.
      </p>
    </li>

    <li>
      <p>
        Affiliates. We may share your information with our affiliates, in<br />
        which case we will require those affiliates to honour this privacy<br />
        notice. Affiliates include our parent company and any<br />
        subsidiaries, joint venture partners, or other companies that we<br />
        control or that are under common control with us. Business<br />
        Partners. We may share your information with our business partners<br />
        to offer you certain products, services, or promotions.<br />
      </p>
    </li>
  </ol>
</li>

<li>
  <h4>HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
  <ol style="color: black">
    <li>
      <p>
        We keep your information for as long as necessary to fulfill the
        <br />
        purposes outlined in this privacy notice unless otherwise required
        <br />
        by law.
      </p>
    </li>
    <li>
      <p>
        We will only keep your personal information for as long as it is
        <br />
        necessary for the purposes set out in this privacy notice unless a
        <br />
        longer retention period is required or permitted by law (such as
        <br />
        tax, accounting, or other legal requirements).
      </p>
    </li>

    <li>
      <p>
        When we have no ongoing legitimate business need to process your<br />
        personal information, we will either delete or anonymize such<br />
        information, or, if this is not possible (for example, because<br />
        your personal information has been stored in backup archives),<br />
        then we will securely store your personal information and isolate<br />
        it from any further processing until deletion is possible.
      </p>
    </li>
  </ol>
</li>

<li>
  <h4>WHAT ARE YOUR PRIVACY RIGHTS?</h4>
  <ol style="color: black">
    <li>
      <p>You may review, change, or terminate your account at any time.</p>
    </li>
    <li>
      <p>
        In accordance to Reg 3.1(8) of the NDPR guarantee the right of a
        <br />
        data subject to request the data controller, without undue delay,
        <br />
        to rectify any inaccurate personal data concerning him or her. The
        <br />
        data subject may also request the inclusion of additional personal
        <br />
        data to provide complete information by providing a supplementary
        <br />
        statement. However, please note that this will not affect the
        <br />
        lawfulness of the processing before its withdrawal nor when <br />
        applicable law allows, will it affect the processing of your
        <br />
        personal information conducted in reliance on lawful processing
        <br />
        grounds other than consent.
      </p>
    </li>

    <li>
      <p>
        Account Information. If you would at any time like to review or
        <br />
        change the information in your account or terminate your account,
        <br />
        you can. Upon your request to terminate your account, we will
        <br />
        deactivate or delete your account and information from our active
        <br />
        databases. However, we may retain some information in our files to
        <br />
        prevent fraud, troubleshoot problems, assist with any <br />
        investigations, enforce our legal terms, and/or comply with <br />
        applicable legal requirements.
      </p>
    </li>
  </ol>
</li>

<li>
  <h4>DO WE MAKE UPDATES TO THIS NOTICE?</h4>
  <p>
    Yes, we will update this notice as necessary to stay compliant with
    <br />
    relevant laws. We may update this privacy notice from time to time.
    <br />
    The updated version will be indicated by an updated "Revised" date and
    <br />
    the updated version will be effective as soon as it is accessible. If
    <br />
    we make material changes to this privacy notice, we may notify you
    <br />
    either by prominently posting a notice of such changes or by directly
    <br />
    sending you a notification. We encourage you to review this privacy
    <br />
    notice frequently to be informed of how we are protecting your
    information.
  </p>
</li>

<li>
  <h4>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>
  <p>
    If you have questions or comments about this notice, you may email us
    <br />
    at support@flexdeals.com.ng
  </p>
</li>
</ol>
`;
