import axios from 'axios';
import toast from 'react-hot-toast';

const authData = JSON.parse(localStorage.getItem('auth'));
const instance = axios.create({
  // http://localhost:1445/api/v1
  // https://retailxpress.herokuapp.com/api/v1
  // http://143.198.155.229/api/v1
  // https://admin.retailxpress.africa/api/v1
  // https://stageback.flexdeals.com.ng/api/v1
  // https://admin.flexdeals.com.ng/api/v1
  // staggingBaseURL: 'https://stageback.flexdeals.com.ng/api/v1',
  baseURL: 'https://admin.flexdeals.com.ng/api/v1',
});
const notifyError = (err) => toast.error(err);

instance.interceptors.request.use(
  (config) => {
    const data = JSON.parse(localStorage.getItem('auth'));
    if (data && data.token) {
      config.headers['x-auth-token'] = data.token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.statusText === 'Unauthorized'
    ) {
      notifyError('SESSION TIMEOUT !');
      let href = authData && authData.merchant_type ? '/login' : '/auth';
      localStorage.clear();
      window.location.href = href;

      return;
    }
    return Promise.reject(error);
  }
);

export default instance;
