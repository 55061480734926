import { useNavigate } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
import Brand from "./BrandOwner";
import Retailer from "./Retailer";
import SuperAdmin from "./SuperAdmin";
// localStorage.setItem('merchant_type',1) then reload
export default function DashboardIndex() {
  const {dispatch, data} = useAuth();
  const navigate = useNavigate();
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = mm + '/' + dd + '/' + yyyy;
  let loginDate = localStorage.getItem('dl') ?localStorage.getItem('dl') : null ;
  
  var one = new Date(loginDate);
  var two = new Date(today);
  var diffInTime = two.getTime() - one.getTime();
  var diffInDays = diffInTime / (1000 * 3600 * 24);
  
  if(diffInDays >=1) {
    logOutUser()

  }

  function logOutUser() {
    localStorage.removeItem('auth');
    dispatch({type:'LOGIN', payload: {}})




    
    setTimeout(() => {
      // navigate('/login')


      
    }, 1000);
  }

  

    
    return data.merchant_type === "brand"  ?  (
        <Brand />  
    ) : (
      data?.user_type === "admin" ? <SuperAdmin /> :
      <Retailer />
    )
}