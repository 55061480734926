
export const useDropDowns = () => {
    return [
        {name: 'All time', key: 'alltime'},
        {name: 'Today', key: 'today'},
        {name: 'Past Week', key: 'pastweek'},
        {name: 'Past 30 Days', key: '30days'},
        {name: 'Past 90 Days', key: '90days'},
        {name: 'Past 6 Months', key: '6months'},
        {name: 'Past 1 Year', key: '1year'},
    ]
}