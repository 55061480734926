// ----------------------------------------------------------------------
import styles from '../BrandOwner.module.css';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Card, Avatar } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import { useLayoutEffect } from 'react';
import { createRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import LiveLocation from 'src/pages/LiveLocation';

import GlobalModal from 'src/Modals/GlobalModal';
import { Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import moment from 'moment';
import { BallTriangle } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { VerificationBadge, UnverifiedBadge } from '../../../components';
import DateFormat from 'src/hooks/useDateFormat';
import { downloadFile3 } from 'src/utils';
import { Pagination } from 'src/components';
// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 200,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3,
};

const style01 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 500,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3,
};

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 700,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3,
};

// ptvp=PAGE TO VIEW (PRODUCT)

export default function BrandOwnerJS() {
  // eslint-disable-next-line no-unused-vars
  const [url, setUrl] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [isPending, setIsPending] = useState('');
  const [searchPO, setSearchPO] = useState('');
  const [searchCategories, setSearchCategories] = useState('');
  const [searchStores, setSearchStores] = useState('');
  const [brandOwnersData, setBrandOwnersData] = useState([]);
  const [dataPagination, setDataPagination] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [viewPending, setViewPending] = useState(false);
  const [currentData, setcurrentData] = useState({});
  const [openVerify, setOpenVerify] = useState(false);
  const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
  const handleOpenVerify = () => setOpenVerify(false);

  const [viewData, setViewData] = useState({
    shops: [],
    products: [],
    productcategories: [],
    stores: [],
    address: {},
    wallet_balance: {},
    totalRevenue: 0,
    revenueData: {
      bold: 0,
      small: 0,
      percentagDiff: 0,
    },
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [openDialog, setDiologOpen] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [reload, setReload] = useState(false);
  const notify = (msg = null) =>
    toast.success(msg === null ? 'Store saved .' : msg);
  const notifyError = (err) => toast.error(err);
  // eslint-disable-next-line no-unused-vars
  const [newProducts, setNewProducts] = useState({
    notifications: [],
    product_name: '',
    product_image: null,
    price_per_case: '',
    price_per_product: '',
    number_of_cases: '',
    products_in_case: '',
    description: '',
    cases_type: 'Cartons',
    category_id: '',
  });
  const [filter, setFilter] = useState({
    verified: 'select',
    dateFrom: '',
    dateTo: '',
    min_categories: '',
    max_categories: '',
    min_balance: '',
    max_balance: '',
    min_order_unit: '',
    max_order_unit: '',
    min_brands: '',
    max_brands: '',
    min_stores: '',
    max_stores: '',
    location: '',
    search: '',
  });
  const [viewBrands, setViewBrands] = useState(false);
  const [viewPOs, setViewPOs] = useState(false);
  const [recordData, setRecordData] = useState(false);
  const [filteredData, setFilteredData] = useState(viewData?.pos || []);
  const [filteredStoreData, setFilteredStoreData] = useState(
    viewData?.stores || []
  );
  const [filteredBrandsData, setFilteredBrandsData] = useState(
    viewBrands?.productcategories || []
  );

  const handleCloseViewBrands = () => {
    setViewBrands(false);
  };
  const handleCloseViewPOs = () => {
    setViewPOs(false);
  };
  const handleCloseViewStores = () => {
    setViewStores(false);
  };
  const handleSearchPO = (e) => {
    const query = e.target.value;
    setSearchPO(query);

    const filteredResults = (viewData?.pos || []).filter((el) =>
      el.product_name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  const handleSearchStores = (e) => {
    const query = e.target.value;
    setSearchStores(query);
    const filteredStoreResults = (viewData?.stores || []).filter((el) =>
      el.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredStoreData(filteredStoreResults);
  };

  const handleSearchCategories = (e) => {
    const query = e.target.value;
    setSearchCategories(query);
    const filteredCatResults = (viewBrands?.productcategories || []).filter(
      (el) => el.category_name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredBrandsData(filteredCatResults);
  };

  const [viewModalOpen, setViewModalOpen] = useState(false);

  let textInput = createRef();
  const handleButtonClick = () => {
    textInput.current.click();
  };
  const [viewStores, setViewStores] = useState(false);
  const handleProductChange = (key, value) => {
    setAnchorEl1(null);
    setNewProducts((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const handleFile = (e) => {
    if (e.target.files[0]) {
      setUrl(URL.createObjectURL(e.target.files[0]));
      handleProductChange('product_image', e.target.files[0]);
    }
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  const handleCloseViewModal = () => {
    setViewModalOpen(false);
  };

  const handleFilterChange = (key, value) => {
    setFilter((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const processLocation = (e) => {
    handleFilterChange('location', e.formatted_address);
  };

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvp');

      // Your code here.
    },
    []
  );

  const processAction = (val) => {
    setcurrentData(val);
    setDiologOpen(true);
  };

  const handleDeleteMarchant = (marchantItem) => {
    console.log(marchantItem, ' marchant detail -- ');
  };

  const handleDialogClose = () => {
    setDiologOpen(false);
  };

  const viewRecord = (el) => {
    setViewModalOpen(true);
    setRecordData(el);
    setViewData({
      shops: [],
      productcategories: [],
      products: [],
      stores: [],
      address: {},
      wallet_balance: {},
      revenueData: {
        bold: 0,
        small: 0,
        percentagDiff: 0,
      },
    });

    setViewPending(true);
    axiosInstance
      .post('/admin/customers/view-brand', {
        id: el._id,
        search: searchPO,
      })
      .then((res) => {
        if (res.status < 300) {
          // console.log(res.data.data);
          setViewData(res.data.data);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Something went wrong, please try again later');
        }
      })
      .finally(() => setViewPending(false));
  };

  const blockUser = () => {
    setLoadingState(true);
    axiosInstance
      .post('/admin/customers/block-customer', {
        id: currentData._id,
        type: 'brands',
      })
      .then((res) => {
        // console.log(res.data.data);
        if (res.status < 300) {
          notify(
            'You have succesfully blocked ' +
              currentData.business_name +
              '. Access Blacklisted tab to view blocked users'
          );
          handleDialogClose();
          setReload(!reload);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Something went wrong , please try again later');
        }
      })
      .finally(() => setLoadingState(false));
  };

  const handleVerifyDialog = () => {
    setOpenVerify(true);
  };

  const handleDownloadFile = async (extension) => {
    try {
      const result = `/admin/customers/get-brands?page=${dataPagination.currentPage}&perpage=${dataPagination.total}&export_type=${extension}`;
      await downloadFile3('Customers - Brands', result, extension);
    } catch (err) {
      notifyError(`Unble to export ${extension} file`);
    }
  };

  const fetchData = (page = 1, perpage = 10) => {
    const filterObject = { ...filter, perpage, page };
    if (filterObject.verified === 'select') filterObject.verified = '';
    if (filterObject.dateFrom) {
      const dValue = DateFormat(filterObject.dateFrom);
      filterObject.dateFrom = dValue;
    } else {
      filterObject.dateFrom = '';
    }
    if (filterObject.dateTo) {
      const dValue = DateFormat(filterObject.dateTo);
      filterObject.dateTo = dValue;
    } else {
      filterObject.dateTo = '';
    }
    let queryParams = new URLSearchParams({ ...filterObject }).toString();
    setIsPending(true);
    handleClose(false);
    axiosInstance
      .post(`/admin/customers/get-brands?${queryParams}`)
      .then((res) => {
        // console.log(res.data.data);
        if (res.status < 300) {
          setBrandOwnersData(res.data.data.data);
        }
        setDataPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch data, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const verifyUser = () => {
    setLoadingState(true);
    handleClose(false);
    axiosInstance
      .post(`/admin/customers/verify`, {
        id: currentData._id,
        type: 'brands',
      })
      .then((res) => {
        if (res.status < 300) {
          setOpenVerifyDialog(false);
          notify(res.data.message);
          handleVerifyDialog();
          setReload(!reload);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Something went wrong , please try again later');
        }
      })
      .finally(() => setLoadingState(false));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.search, reload]);

  useEffect(() => {
    handleFilterChange('dateFrom', startDate);
    handleFilterChange('dateTo', endDate);
  }, [startDate, endDate]);

  return (
    <>
      <div className="px-2">
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-6   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={filter.search}
                onChange={(e) => handleFilterChange('search', e.target.value)}
                placeholder="Search"
                style={{ textIndent: '25px', width: '60%' }}
                className=" form-control"
              />
            </div>
            <div className="col-6  text-right">
              <button
                onClick={() => setModalOpen(true)}
                className="btn  border"
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px',
                }}
              >
                <img
                  className=""
                  style={{ display: 'inline', width: '17px' }}
                  src="/assets/icons/filterlines.svg"
                  alt=""
                />
                &nbsp;
                <small className="d-none d-md-inline-block">Filters</small>
              </button>
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: '50px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-4 border-bottom">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">Brand Lists</h5>
                <small style={{ fontWeight: '200', fontSize: '12px' }}>
                  Details of registered brands on FlexDeals
                </small>
              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`${styles.export_btn} btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: '8px',
                      }}
                    >
                      <img src="/assets/icons/downloadblue.svg" alt="" /> &nbsp;
                      Export
                    </span>
                    &nbsp; &nbsp;
                    <span
                      style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <img src="/assets/icons/chevron-downblue.svg" alt="" />
                    </span>
                  </button>

                  <div
                    className="dropdown-menu drop-left"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('csv')}
                    >
                      CSV
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('excel')}
                    >
                      Excel
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('pdf')}
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`pt-3 pb-3 pl-0 pr-0`}>
              <table
                className={`${styles.table} table-hover table-striped `}
                style={{ minWidth: '100em' }}
              >
                <thead className={`${styles.thead}`}>
                  <tr>
                    <th className="text-muted">S/N</th>
                    <th className="text-muted" style={{ width: '16rem' }}>
                      Brand Name
                      <img
                        className="pl-2"
                        src="/assets/icons/down.svg"
                        alt=""
                      />
                    </th>
                    <th className="text-muted">Email</th>
                    <th className="text-muted">Phone Numbers</th>
                    <th className="text-muted">Categories</th>
                    <th className="text-muted">Stores Onboarded</th>
                    <th className="text-muted">Status</th>
                    <th className="text-muted">P.O Amount</th>
                    <th className="text-muted">P.O Balance</th>
                    <th className="text-muted">Date Registered</th>
                    <th className="text-muted">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {!isPending &&
                    brandOwnersData.length >= 1 &&
                    brandOwnersData.map((el, i) => (
                      <tr key={i} className={`${styles.tr} `}>
                        <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                        <td>
                          <div className="d-flex justify-content-start align-items-center">
                            <Avatar
                              className={`${styles.avatrr} mr-3 float-md-left`}
                              alt=""
                              src={el?.business_logo}
                            />
                            <div className=" ">
                              <p
                                className="mb-0"
                                style={{ fontSize: '14px', whiteSpace: 'wrap' }}
                              >
                                {el?.business_name ? el?.business_name : '---'}
                              </p>
                              <span>{el?.brandID ? el?.brandID : '---'}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="ml-2">
                            {el?.business_email ? el?.business_email : '---'}
                          </span>
                        </td>
                        <td>
                          {el?.business_phone_number
                            ? el?.business_phone_number
                            : '---'}
                        </td>
                        <td>{el?.categories ? Number(el?.categories) : 0}</td>
                        <td>
                          {el?.stores_onboarded
                            ? Number(el?.stores_onboarded)
                            : 0}
                        </td>
                        <td>
                          <span className="">
                            {el?.is_verified === true ? (
                              <span className="verified_badge">
                                <img
                                  src="/assets/icons/check-circlel.svg"
                                  alt=""
                                />
                                Verified
                              </span>
                            ) : el?.is_verified === false ? (
                              <span className="not_verified_badge">
                                Not Verified
                              </span>
                            ) : (
                              <span className="pending_verified_badge">
                                {el?.is_verified}
                              </span>
                            )}
                          </span>
                        </td>
                        <td>
                          ₦
                          {el?.po_budget?.$numberDecimal
                            ? Number(
                                el?.po_budget?.$numberDecimal
                              ).toLocaleString()
                            : 0}
                        </td>
                        <td>
                          ₦
                          {el?.po_balance?.$numberDecimal
                            ? Number(
                                el?.po_balance?.$numberDecimal
                              ).toLocaleString()
                            : 0}
                        </td>
                        <td>
                          <span>
                            {moment(el.date_registered).format('DD/MM/YYYY')}
                          </span>{' '}
                          <br />
                          <small>
                            {moment(el.date_registered).format('HH:mm')} PM
                          </small>
                        </td>
                        <td>
                          <div className="dropleft ">
                            <span
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              className={`${styles.action_button} px-3 py-2`}
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <img src="/assets/icons/action.svg" alt="" />
                            </span>

                            <div
                              className="dropdown-menu drop-left"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <button
                                type="button"
                                onClick={() => viewRecord(el)}
                                className="rss dropdown-item btn border-0"
                              >
                                View
                              </button>
                              {el?.is_verified === false && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    setcurrentData(el);
                                    setOpenVerifyDialog(true);
                                  }}
                                  className="rss dropdown-item btn border-0"
                                >
                                  Verify
                                </button>
                              )}
                              <button
                                type="button"
                                onClick={() => processAction(el)}
                                className="rss dropdown-item btn border-0"
                              >
                                Deactivate
                              </button>

                              {/* <button
                                type="button"
                                onClick={() => handleDeleteMarchant(el)}
                                className="rss dropdown-item btn border-0"
                              >
                                Delete
                              </button> */}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!brandOwnersData.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={dataPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#ffff"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="loc">Verification</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={filter.verified}
                    name="verified"
                    onChange={(e) =>
                      handleFilterChange('verified', e.target.value)
                    }
                  >
                    <MenuItem value="select">Select Customer Status</MenuItem>
                    <MenuItem value={true}>Verified</MenuItem>
                    <MenuItem value={false}>Unverified</MenuItem>
                  </Select>
                </div>
                <div className="mb-3">
                  <LiveLocation label="Location" passData={processLocation} />
                </div>
                <label htmlFor="name">Date </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        // showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 mt-1 d-sflex   align-items-center "
                    style={{ heigsht: '40px' }}
                  >
                    {/* sss */}
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        // showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="ounit">Brands Selection</label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.min_brands}
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('min_brands', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.max_brands}
                        placeholder="Max "
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('max_brands', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <label htmlFor="price">PO Balance </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.min_balance}
                        id="price"
                        placeholder="Min"
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('min_balance', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.max_balance}
                        placeholder="Max "
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('max_balance', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.min_order_unit}
                        id="ounit"
                        placeholder=""
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('min_order_unit', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.max_order_unit}
                        placeholder=""
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('max_order_unit', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <label htmlFor="ounit">Product Category </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.min_categories}
                        id="ounit"
                        placeholder=""
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('min_categories', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        value={filter.max_categories}
                        type="number"
                        placeholder=""
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('max_categories', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={() => {
                  handleClose();
                  fetchData();
                }}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed{' '}
                {isPending && (
                  <CircularProgress style={{ color: '#fff' }} size="1rem" />
                )}
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openVerify}
        onClose={handleOpenVerify}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openVerify}>
          <Box
            className={`mobile_modal_size pt-md-3 ${styles.modal_bg}`}
            sx={style}
          >
            <div className={`text-center ${styles.add_to_cart_content}`}>
              <img src="/assets/icons/Illustration.svg" alt="" />
              <h5 className="mt-3">Brand Verified</h5>
              <div>
                <button
                  className={`${styles.go_to} d-block w-100 `}
                  style={{ fontSize: '14px' }}
                  onClick={() => setOpenVerify(false)}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '100%' } }}
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="">
                <img
                  style={{ width: '40px' }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <h4>Block {currentData.business_name}</h4>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6B7280',
                  }}
                >
                  Are you sure you want to perform this operation
                </p>
              </div>
            </div>

            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={handleDialogClose}
              >
                Cancel
              </Button>
              <Button
                className={`${styles.delete_product}`}
                onClick={blockUser}
              >
                Block{' '}
                {loadingState && (
                  <CircularProgress style={{ color: '#af3585' }} size="1rem" />
                )}
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '100%' } }}
        open={openVerifyDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenVerifyDialog(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="">
                <img
                  style={{ width: '40px' }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <h4>Verify {currentData.business_name}</h4>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6B7280',
                  }}
                >
                  Are you sure you want to perform this operation
                </p>
              </div>
            </div>

            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={() => setOpenVerifyDialog(false)}
              >
                Cancel
              </Button>
              <Button
                className={`${styles.delete_product}`}
                onClick={verifyUser}
              >
                Verify{' '}
                {loadingState && (
                  <CircularProgress style={{ color: '#af3585' }} size="1rem" />
                )}
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>

      <GlobalModal
        open={viewModalOpen}
        onClose={handleCloseViewModal}
        position="fixed"
        top="0"
        left="50%"
        right="0"
        bottom="0"
        transform="translate(-50%, 0%)"
        width="840px !important"
        overflow="auto"
        bgcolor="#FFFF"
        border="1px solid #F5F5F5"
        m="auto"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <div
          className={`${styles.modal_content} mx-auto`}
          style={{ height: '90vh', overflowY: 'auto' }}
        >
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Brand Info
            </h5>

            <span onClick={handleCloseViewModal} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>
          <span className="text-muted mb-4">Details of Brand</span>
          <form action="" className="mt-0">
            {viewPending && (
              <BallTriangle
                height={50}
                width={55}
                radius={5}
                color="#af3585"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            )}
            <p className={` label mt-3`}>Brand Logo </p>
            <div className="row">
              <div className="col-md-6">
                <div
                  className={`${styles.retailer_modal_product_img}`}
                  style={{
                    width: '30%',
                    height: '100px',
                  }}
                >
                  <div
                    style={{
                      display: 'inline-block',
                      backgroundColor: 'white',
                    }}
                  >
                    <Avatar
                      className={`${styles.retailer_product_image} ${styles.rounded_border}`}
                      src={viewData?.logo}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className={` mb-2 pr-0 float-right`}>
                  <div className={`${styles.card}`}>
                    <div className={`${styles.card} mr-5`}>
                      <div className={`card-body`}>
                        <div className="d-flex justify-content-between">
                          <small>Total Revenue</small>
                        </div>
                        <div className={`${styles.numbers}`}>
                          <div>
                            <span>
                              ₦
                              {viewData.totalRevenue
                                ? Number(viewData.totalRevenue).toLocaleString()
                                : 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="loc">Manager's Name</label>
                  <TextField
                    value={viewData?.name}
                    type="text"
                    id="loc"
                    placeholder="Benedict James"
                    size="small"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="loc">Email Address:</label>
                  <TextField
                    value={viewData?.email}
                    type="email"
                    id="loc"
                    size="small"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="loc">Phone Number:</label>
                  <TextField
                    value={viewData?.phone_number}
                    type="tel"
                    id="loc"
                    size="small"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="loc">Location:</label>
                  <TextField
                    value={recordData?.business_address}
                    type="tel"
                    id="loc"
                    placeholder=""
                    size="small"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Categories </label>
                <div className=" d-flex">
                  <div className="mb-3" style={{ width: '100%' }}>
                    {!viewData?.productcategories?.length && (
                      <div className="alert alert-[#af3585] text-center">
                        <h6>No record found</h6>
                      </div>
                    )}

                    {viewData?.productcategories.length &&
                      viewData?.productcategories?.slice(0, 3).map((el, i) => (
                        <span
                          className={`${styles.productcategories} categories ${
                            el?.is_deleted ? 'text-muted' : 'text-dark'
                          }`}
                          key={i}
                        >
                          {el?.category_name}
                        </span>
                      ))}

                    {viewData?.productcategories?.length > 3 && (
                      <div>
                        <span
                          className="text-muted ml-2"
                          style={{
                            fontSize: '13px',
                          }}
                        >
                          +
                          {viewData &&
                            viewData.productcategories &&
                            viewData?.productcategories.length - 3}
                        </span>
                        <span
                          onClick={() => [
                            setViewBrands(true),
                            setFilteredBrandsData(viewData?.productcategories),
                          ]}
                          className={`${styles.view_more}`}
                        >
                          View More
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <label htmlFor="price">Stores </label>
                <div className=" d-flex">
                  <div className="mb-3" style={{ width: '100%' }}>
                    {viewData?.stores.length < 1 && (
                      <div className="alert alert-[#af3585] text-center">
                        <h6>No record found</h6>
                      </div>
                    )}

                    {viewData?.stores.slice(0, 3).map((el, i) => (
                      <span className={`${styles.stores} categories`} key={i}>
                        {el}
                      </span>
                    ))}

                    {viewData?.stores.length > 3 && (
                      <div>
                        <span
                          className="text-muted ml-2"
                          style={{
                            fontSize: '13px',
                          }}
                        >
                          +
                          {viewData &&
                            viewData.stores &&
                            viewData?.stores.length - 3}
                        </span>
                        <span
                          onClick={() => [
                            setViewBrands(true),
                            setFilteredStoreData(viewData?.stores),
                          ]}
                          className={`${styles.view_more}`}
                        >
                          View More
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <label htmlFor="price">P.O Submitted </label>
                <div className=" d-flex">
                  <div className="mb-3" style={{ width: '100%' }}>
                    {viewData?.pos?.length < 1 && (
                      <div className="alert alert-[#af3585] text-center">
                        <h6>No record found</h6>
                      </div>
                    )}

                    {viewData?.pos?.slice(0, 3).map((el, i) => (
                      <span className={`${styles.stores} categories`} key={i}>
                        {el?.product_name}
                      </span>
                    ))}

                    {viewData?.pos?.length > 3 && (
                      <div>
                        <span
                          className="text-muted ml-2"
                          style={{
                            fontSize: '13px',
                          }}
                        >
                          +
                          {viewData &&
                            viewData.pos &&
                            viewData?.pos?.length - 3}
                        </span>
                      </div>
                    )}
                    {viewData?.pos?.length > 1 && (
                      <span
                        onClick={() => [
                          setViewPOs(true),
                          setFilteredData(viewData?.pos),
                        ]}
                        className={`${styles.view_more}`}
                      >
                        View More
                      </span>
                    )}
                  </div>
                </div>

                {viewData?.business_image && (
                  <img
                    src={viewData?.business_image}
                    width={'100%'}
                    height={'200px'}
                    alt="shop"
                  />
                )}
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={handleCloseViewModal}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>

      {/* View Brands Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewBrands}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={viewBrands}>
          <Box sx={style01} className={`mobile_modal_size`}>
            <div className={`po_modal_content`}>
              <div className={`modal_header d-flex`}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}
                >
                  Categories
                </h5>
                <span
                  onClick={handleCloseViewBrands}
                  className="ml-auto"
                  style={{ cursor: 'pointer' }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: '14px',
                }}
              >
                Details of Category
              </p>
              <div className="hold_input4" style={{ width: '50%' }}>
                <img src="/assets/icons/search.svg" alt="" />
                {/* <input
                  type="text"
                  value={searchCategories}
                  onChange={handleSearchCategories}
                  placeholder="Search"
                  style={{ textIndent: "25px", width: "100%" }}
                  className=" form-control"
                /> */}
              </div>

              <div className={`pt-3 pb-3 pl-0 pr-0`}>
                <div className={`row mt-2`}>
                  {filteredBrandsData.length > 0 ? (
                    filteredBrandsData.map((el, i) => (
                      <div key={i} className="col-3 mb-3 mt-3">
                        <p className="mb-0" style={{ fontSize: '14px' }}>
                          <strong className={`${styles.brands}`}>
                            {el?.category_name}
                          </strong>
                        </p>
                      </div>
                    ))
                  ) : (
                    <div className="alert alert-[#af3585] text-center">
                      <h4>No record found</h4>
                    </div>
                  )}
                </div>
                {/* <table className={`${styles.table} table-hover table-striped `} style={{ minWidth: '30em' }}>
                    <thead className={`${styles.thead}`}>
                    </thead>
                    <tbody>
                      <tr className={`${styles.tr} `}>
                      {
                        filteredBrandsData.length > 0 ? (
                          filteredBrandsData.map((el, i) => (
                          <td key={i} className="">
                            <div className="">
                              <p className="mb-0" style={{ fontSize: "14px" }}>
                                <strong>{el?.category_name}</strong>
                              </p>
                            </div>
                          </td>
                        ))
                        ) : (
                          <div className="alert alert-primary text-center">
                          <h4>No record found</h4>
                        </div>
                      )
                    }
                    </tr>
                    </tbody>
                  </table> */}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewPOs}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={viewPOs}>
          <Box sx={style2} className={`mobile_modal_size`}>
            <div className={`po_modal_content`}>
              <div className={`modal_header d-flex`}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}
                >
                  P.O Submitted
                </h5>
                <span
                  onClick={handleCloseViewPOs}
                  className="ml-auto"
                  style={{ cursor: 'pointer' }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: '14px',
                }}
              >
                Details of P.O
              </p>

              <div className="hold_input4" style={{ width: '50%' }}>
                <img src="/assets/icons/search.svg" alt="" />
                <input
                  type="text"
                  value={searchPO}
                  onChange={handleSearchPO}
                  placeholder="Search"
                  style={{ textIndent: '25px', width: '100%' }}
                  className=" form-control"
                />
              </div>

              <div
                className={`pt-3 pb-3 pl-0 pr-0`}
                style={{ maxHeight: '500px', overflowY: 'auto' }}
              >
                <table
                  className={`${styles.table} table-hover table-striped `}
                  style={{ minWidth: '30em' }}
                >
                  <thead className={`${styles.thead}`}>
                    <tr>
                      <th className="text-muted">
                        Product
                        <img
                          className="pl-2"
                          src="/assets/icons/down.svg"
                          alt=""
                        />
                      </th>
                      <th className="text-muted">
                        P.O Amount
                        <img
                          className="pl-2"
                          src="/assets/icons/down.svg"
                          alt=""
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.length > 0 ? (
                      filteredData.map((el, i) => (
                        <tr key={i} className={`${styles.tr} `}>
                          <td>
                            <Avatar
                              className={`${styles.avatrr} mr-3 float-md-left`}
                              alt=""
                              src={el?.po}
                            />
                            <div className=" ">
                              <p className="mb-0" style={{ fontSize: '14px' }}>
                                <strong>{el?.product_name}</strong>
                              </p>
                              <span style={{ fontSize: '10px' }}>
                                {el?.product_spec}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className=" ">
                              <p className="mb-0" style={{ fontSize: '14px' }}>
                                <strong>
                                  ₦{Number(el?.amount).toLocaleString()}
                                </strong>
                              </p>
                            </div>
                          </td>
                          <td>
                            <div className=" ">
                              <a
                                href={el?.po}
                                download
                                target="_blank"
                                className="mb-0"
                                style={{
                                  fontSize: '14px',
                                  cursor: 'pointer',
                                  borderBottom: '2px solid #8C2D91',
                                }}
                                rel="noreferrer"
                              >
                                Download
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div className="alert alert-[#af3585] text-center">
                        <h4>No record found</h4>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* View Stores Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewStores}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={viewStores}>
          <Box sx={style} className={`mobile_modal_size`}>
            <div className={`modal_content`}>
              <div className={`modal_header`}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}
                >
                  Stores
                </h5>
                <span
                  onClick={handleCloseViewStores}
                  style={{ cursor: 'pointer' }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: '14px',
                }}
              >
                Stores this brand has bought from
              </p>

              <div className="hold_input4" style={{ width: '50%' }}>
                <img src="/assets/icons/search.svg" alt="" />
                <input
                  type="text"
                  value={searchStores}
                  onChange={handleSearchStores}
                  placeholder="Search"
                  style={{ textIndent: '25px', width: '100%' }}
                  className=" form-control"
                />
              </div>
              <div className={`row mt-2`}>
                {filteredStoreData.length > 0 &&
                  filteredStoreData.map((el, i) => {
                    return (
                      <div key={i} className="col-4 mb-3 mt-3">
                        <Avatar
                          className={`${styles.avatrr} mr-3 float-md-left`}
                          alt=""
                          src=""
                        />
                        <strong className={`${styles.brands}`}>{el}</strong>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
