

// ----------------------------------------------------------------------
import styles from "./BrandOwner.module.css"
import { useState } from "react"
import TextField from '@mui/material/TextField';
import Shoppers from './components/Shoppers';
import Merchants from './components/Merchants';
import BrandOwner from './components/BrandOwner';
import CustomerReview from './components/CustomerReview';
import {
  Card,
  Avatar,
} from '@mui/material';
import Scrollbar from '../../components/scrollbar';
// sections
// mock
import { useLayoutEffect } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import GlobalModal from "src/Modals/GlobalModal";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor:'#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius:'5px',
  boxShadow: 24,
  p: 3,
};

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
// ptvp=PAGE TO VIEW (PRODUCT)

export default function RetailerCustomers() {


  const [value, setValue] = useState('1');

  const [modalOpen, setModalOpen] = useState(false);


  
  const handleClose = () => {
    setModalOpen(false);
  };


  useLayoutEffect(() => () => {
    localStorage.removeItem('ptvp');

    // Your code here.
  }, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };


  

  
  

  
  
  
  return   (
    <>
      <div className="wrapper">
        <div   className="header d-block d-md-none mb-4">
          <div className={`${styles.product_actions} d-md-flex justify-content-between`}>
            <div className=" ">
              <h4 className={`mb-0 pb-0`}>Customers</h4>
              <p style={{fontSize: '14px'}} className={`mb-0 mt-2 pb-0`}>
                Monitor shoppers transactions, feedback and reviews
              </p>

            </div>
            

          </div>
         

        </div>
        <Box style={{padding:'0'}} className='aa' sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs ">
                <Tab label="Users" value="1" />
                <Tab label="merchants" value="2" />
                <Tab label="Brand Owner" value="3" />
                <Tab label="Customer Review" value="4" />
              </TabList>
            </Box>
            <TabPanel value="1" style={{paddingLeft:'0'}}>
              <Shoppers  />
            </TabPanel>
            <TabPanel value="2" style={{paddingLeft:'0'}}>
              <Merchants  />
            </TabPanel>
            <TabPanel value="3" style={{paddingLeft:'0'}}>
              <BrandOwner  />
            </TabPanel>
            <TabPanel value="4" style={{paddingLeft:'0'}}>
              <CustomerReview  />
            </TabPanel>
            
          </TabContext>
        </Box>
     </div>
     <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position='absolute'
        top= '50%'
        left= '50%'
        transform= 'translate(-50%, -50%)'
        width= '740px !important'
        overflow='auto'
        bgcolor='#F5F5F5'
        border= '1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow= '24'
        p='25px'
      >
        <div  className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
          <h5 style={{
            fontSize: '19px',
            fontWeight: '700',

          }}>Filter</h5>
            <span onClick={handleClose} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>
          

          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="loc">Location</label>
                  <TextField
                    required
                    type='text'
                    id="loc"
                    placeholder="Enter Location"
                    size="small"
                    fullWidth

                  />
                    

                </div>
                <label htmlFor="name">Date </label>

                <div className=" d-flex">
                  <div className="" style={{width:'70%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='date'
                        id="name"
                        placeholder="Start"
                        size="small"

                      />
                      

                    </div>

                  </div>
                  <div className="px-1 d-sflex   align-items-center " style={{heigsht:'40px'}}>
                    {/* sss */}
                    <img src="/assets/icons/line.svg" style={{width:'30px'}} alt="" />
                  </div>
                  <div className="" style={{width:'100%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='date'
                        placeholder="End "
                        size="small"

                      />
                      

                    </div>

                  </div>
                  
                </div>

              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Price </label>

                <div className=" d-flex">
                  <div className="" style={{width:'100%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="price"
                        placeholder="Min"
                        size="small"

                      />
                      

                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{height:'40px'}}>
                    <img src="/assets/icons/line.svg" style={{width:'30px'}} alt="" />
                  </div>
                  <div className=""  style={{width:'100%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"

                      />
                      

                    </div>

                  </div>
                  
                </div>
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{width:'100%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="ounit"
                        placeholder="Min"
                        size="small"

                      />
                      

                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{height:'40px'}}>
                    <img src="/assets/icons/line.svg" style={{width:'30px'}} alt="" />
                  </div>
                  <div className="" style={{width:'100%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"

                      />
                      

                    </div>

                  </div>
                  
                </div>
                
              </div>
            </div>
            <div className="mb-4">
            <button type="button" onClick={handleClose} className={`${styles.modal_btn} btn btn-block`}>
              Proceed

            </button>
            
            
            </div>

          </form>


        </div>
       
       
    </GlobalModal>



    </>
  ) ;
}
