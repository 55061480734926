// ----------------------------------------------------------------------
import styles from '../BrandOwner.module.css';
import ReactApexChart from 'react-apexcharts';
import GlobalModal from 'src/Modals/GlobalModal';

import { Card, CardContent } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import moment from 'moment';
import Filter1 from 'src/pages/Filter/Filter1';
import bus from 'src/bus';
import { BallTriangle } from 'react-loader-spinner';
import { useAuth } from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const { data } = useAuth();
  const { id } = useParams();
  const userData = JSON.parse(localStorage.getItem('auth'));
  const store = JSON.parse(localStorage.getItem('selected_user_insight')) || '';
  const [modalOpen, setModalOpen] = useState(false);
  const [rvModalOpen, setRvModalOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isPending1, setIsPending1] = useState(false);
  const [isPending2, setIsPending2] = useState(false);
  const [isPending3, setIsPending3] = useState(false);
  const [isPending4, setIsPending4] = useState(false);
  const [categoryPending, setCategoryPending] = useState(false);
  const [topBrandStat, setTopBrandStat] = useState([]);
  const [topBrandProducts, setTopBrandProducts] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [categorySelectedIds, setCategorySelectedIds] = useState([]);
  const [searchvalue, setSearchValue] = useState('');
  const [searchvalue1, setSearchValue1] = useState('');
  const [searchvalue2, setSearchValue2] = useState('');
  const [searchvalue3, setSearchValue3] = useState('');
  const [searchvalue4, setSearchValue4] = useState('');
  const [searchCategory, setSearchCategory] = useState('');
  const navigate = useNavigate();
  const notifyError = (err) => toast.error(err);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleCloseRvModal = () => {
    setRvModalOpen(false);
  };

  const handleCategorySearch = (e) => {
    setSearchCategory(e.target.value);
  };

  const handleProceed = () => {
    fetchTopBrandStat();
  };

  const handleBMPProceed = () => {
    fetchBrandPromoStat();
  };

  const handleTSPProceed = () => {
    fetchTopSellingStat();
  };

  const handleCategoryChange = (categoryId) => {
    if (categorySelectedIds.includes(categoryId)) {
      setCategorySelectedIds(
        categorySelectedIds.filter((id) => id !== categoryId)
      );
    } else {
      setCategorySelectedIds([...categorySelectedIds, categoryId]);
    }
  };

  useEffect(() => {
    console.log(categorySelectedIds);
  }, [categorySelectedIds]);

  // ==============================  For tabs  ================================================
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedTab1, setSelectedTab1] = useState(1);
  const [selectedTab2, setSelectedTab2] = useState(1);
  const [selectedTab3, setSelectedTab3] = useState(1);
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const handleTabChange1 = (tab) => {
    setSelectedTab1(tab);
  };
  const handleTabChange2 = (tab) => {
    setSelectedTab2(tab);
  };
  const handleTabChange3 = (tab) => {
    setSelectedTab3(tab);
  };

  // const handleTabChange = (name) => {
  //   return (tab) => {
  //     setSelectedTab((prevTabs) => ({
  //       ...prevTabs,
  //       [name]: tab,
  //     }));
  //   };
  // };

  const [dataFilter, setDataFilter] = useState('alltime');

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return '₦' + Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
          // Assuming series name is the identifier to check if it's orders or not
          const seriesName = w.globals.seriesNames[seriesIndex];
          if (seriesName.toLowerCase().includes('orders')) {
            return Number(val).toLocaleString();
          } else {
            return "₦ " + Number(val).toLocaleString();
          }
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
  });
  const [chartSeries, setChartSeries] = useState([
    {
      name: 'Sales',
      data: [],
    },
    {
      name: 'Order',
      data: [],
    },
  ]);
  const [chartOptions01, setChartOptions01] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    xaxis: {
      categories: [
        'Nestle Milo (Nestle)',
        'Ghana',
        'South Africa',
        'Egypt',
        'Benin',
        'Liberia',
        'Togo',
        'Sierrelone',
        'Senegal',
      ],
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '$ ' + val;
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  });
  const [chartSeries01, setChartSeries01] = useState([
    900, 550, 800, 810, 810, 600, 400, 400,
  ]);
  const [chartOptions1, setChartOptions1] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
  });
  const [chartSeries1, setChartSeries1] = useState([
    {
      name: 'Promo',
      data: [],
    },
  ]);
  const [chartSeries2, setChartSeries2] = useState([
    {
      data: [],
    },
    // {
    //   data: [39],
    // },
  ]);
  const [chartOptions2, setChartOptions2] = useState({
    chart: {
      type: 'bar',
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    xaxis: {
      categories: [],
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  });
  const [chartOptions3, setChartOptions3] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      padding: '20px',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        rowWidth: '20%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    xaxis: {
      categories: ['Your Universe', 'FlexDeals Universe'],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return '₦' + Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '₦' + Number(val).toLocaleString();
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  });
  const [chartSeries3, setChartSeries3] = useState([
    {
      name: 'Your Universe',
      data: [],
    },
    {
      name: 'Rx Universe',
      data: [],
    },
  ]);
  const [chartOptions4, setChartOptions4] = useState({
    chart: {
      type: 'bar',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    // annotations: {
    //   xaxis: [{
    //     x: 500,
    //     borderColor: '#00E396',

    //   }],
    //   yaxis: [{
    //     y: 'July',
    //     y2: 'September',

    //   }]
    // },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
      // maxWidth: '10px'
    },
    xaxis: {
      categories: [
        '8am',
        '9am',
        '10am',
        '11am',
        '12pm',
        '1pm',
        '2pm',
        '3pm',
        '4pm',
        '5pm',
        '6pm',
        '7pm',
      ],
      labels: {
        datetimeFormatter: {
          hour: 'HH:mm',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return '₦' + Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '₦' + Number(val).toLocaleString();
        },
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      reversed: false,
      axisTicks: {
        show: true,
      },
    },
  });
  const [chartSeries4, setChartSeries4] = useState([
    {
      data: [],
    },
  ]);

  function handleClick(event) {
    event.preventDefault();
    navigate(-1);
  }

  const fetchTopBrandStat = (
    dataFilter,
    page = 1,
    pageSize = 10,
    search = searchvalue
  ) => {
    setIsPending(true);
    const data = {
      shop_id: id ? id : userData._id,
      categoryIds: categorySelectedIds,
    };
    axiosInstance
      .post('admin/data-insights/shops-top-selling-brand?', data, {
        params: {
          page,
          pageSize,
          search,
          period: dataFilter,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Orders',
            data: res.data.data.map((el) => el.orders),
          },
          {
            name: 'Sales',
            data: res.data.data.map((el) => el.sales),
          },
        ];

        setChartSeries((prev) => {
          return data;
        });
        setChartOptions((prev) => {
          return {
            ...prev,
            xaxis: {
              categories:  res.data.data.map((el) => `${el.business_name}`)
              // categories:  res.data.data.map((el) => `${el.product_name} \n(${el.business_name})`)
            }
          }
        })
        setCategorySelectedIds([])
      }).catch(err => {

        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsPending(false));
  };

  const fetchBrandPromoStat = (
    dateFilter,
    page = 1,
    pageSize = 10,
    search = searchvalue1
  ) => {
    setIsPending1(true);
    const data = {
      shop_id: id ? id : userData._id,
      categoryIds: categorySelectedIds,
    };
    axiosInstance
      .post('admin/data-insights/shops-with-most-promo?', data, {
        params: {
          page,
          pageSize,
          search,
          period: dateFilter,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Promo',
            data: res.data.data.map((el) => el.promo_count),
          },
        ];

        setChartSeries1((prev) => {
          return data;
        });
        setChartOptions1((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: res.data.data.map((el) => `${el.business_name}`),
            },
          };
        });
        setCategorySelectedIds([]);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsPending(false));
  };

  const fetchTopSellingStat = (
    dateFilter,
    page = 1,
    pageSize = 10,
    search = searchvalue2
  ) => {
    setIsPending2(true);
    const data = {
      shop_id: id ? id : userData._id,
      categoryIds: categorySelectedIds,
    };
    axiosInstance
      .post('admin/data-insights/shops-top-selling-product?', data, {
        params: {
          page,
          pageSize,
          search,
          period: dateFilter,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Top selling',
            data: res.data.data.map((el) => el.total_sold),
          },
        ];

        setChartSeries2((prev) => {
          return data;
        });
        setChartOptions2((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: res.data.data.map(
                (el) => `${el.product_name} \n (${el.business_name})`
              ),
            },
          };
        });
        setCategorySelectedIds([]);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsPending(false));
  };

  const fetchShopperBuyingPowerStat = (
    dateFilter,
    page = 1,
    pageSize = 10,
    search = searchvalue3
  ) => {
    setIsPending3(true);
    const data = {
      shop_id: id ? id : userData._id,
    };
    axiosInstance
      .post('admin/data-insights/shops-shopper-buying-power?', data, {
        params: {
          page,
          pageSize,
          search,
          period: dateFilter,
        },
      })
      .then((res) => {
        const chartSeriesData = [
          {
            name: 'Your Universe',
            data: res.data.data.map((el) => `${el.your_universe}`),
          },
          {
            name: 'Retail Xpress Universe',
            data: res.data.data.map((el) => el.rx_universe),
          },
        ];

        setChartSeries3(chartSeriesData);

        setChartOptions3((prev) => ({
          ...prev,
          xaxis: {
            categories: [''],
          },
        }));
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsPending(false));
  };

  const fetchRevGrowthStat = (
    dateFilter,
    page = 1,
    pageSize = 10,
    search = searchvalue4
  ) => {
    setIsPending4(true);
    const data = {
      shop_id: id ? id : userData._id,
    };
    axiosInstance
      .post('admin/data-insights/shops-revenue-growth?', data, {
        params: {
          page,
          pageSize,
          search,
          period: dateFilter,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Revenue Growth',
            data: res.data.data.revenues.map((el) => `${el}`),
          },
        ];

        setChartSeries4((prev) => {
          return data;
        });

        const iso8601Dates = res.data.data.dates.map((dateStr) =>
          new Date(dateStr).toISOString()
        );

        setChartOptions4((prev) => {
          return {
            ...prev,
            xaxis: {
              categories:
                res.data.data.dates.length > 2
                  ? res.data.data.dates.map((el) => moment(el).format('h A'))
                  : res.data.data.dates.map((el) => {
                      const monthNames = [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ];
                      return moment.utc(el).local().format(`Do MMMM YYYY h A`);
                    }),
            },
            yaxis: {
              labels: {
                formatter: function (val) {
                  return '₦' + Number(val).toLocaleString();
                },
              },
            },
          };
        });
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsPending(false));
  };

  const fetchCategories = (page = 1, perpage = 10, search = searchCategory) => {
    setCategoryPending(true);
    axiosInstance
      .get('/admin/product/category-count', {
        params: {
          page,
          perpage,
          search,
        },
      })
      .then((res) => {
        setCategoriesData(res.data.data.data);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setCategoryPending(false));
  };

  useEffect(() => {
    fetchTopBrandStat(dataFilter);
    fetchBrandPromoStat(dataFilter);
    fetchTopSellingStat(dataFilter);
    fetchShopperBuyingPowerStat(dataFilter);
    fetchRevGrowthStat(dataFilter);
  }, [dataFilter]);

  useEffect(() => {
    bus.on('dataFilter', (el) => {
      setDataFilter(el);
    });
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [searchCategory]);

  return (
    <>
      <div className="pt-2 px-2 mt-5h">
        {data?.user_type === 'admin' && (
          <div>
            <button onClick={handleClick} className="btn pl-0">
              <div
                className="p-1"
                style={{
                  border: '1px solid lightgrey',
                  borderRadius: '3px',
                }}
              >
                <img src="/assets/icons/Chevronleft.svg" alt="" />
              </div>
            </button>
            <div className="row mb-4 p-3">
              <div
                className={`${styles.wallet_actions} d-md-flex justify-content-between`}
              >
                <div className=" ">
                  <h4 className={`mb-0 pb-0 font-weight-bold`}>
                    {store ? store?.name : '--'}
                  </h4>
                  <p style={{ fontSize: '14px' }} className={`mb-0 mt-2 pb-0`}>
                    Access quality insights here
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {data?.merchant_type === 'shop' && (
          <div>
            <div className="row mb-4 p-3">
              <div
                className={`${styles.wallet_actions} d-md-flex justify-content-between`}
              >
                <div className=" ">
                  <h4 className={`mb-0 pb-0 font-weight-bold`}>
                    {store ? store?.name : '--'}
                  </h4>
                  <p
                    style={{ fontSize: '14px' }}
                    className={`mb-0 mt-2 pb-0`}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={`${styles.insight_header} mt-5`}>
          <h5>Statistics from Brand Owners</h5>
        </div>
        <div className="mt-5">
          <Filter1 width="100%" />
        </div>

        <div className="row">
          <div className="col-md-6 mb-2">
            <Card
              className={`${styles.card_wrapper} p-0`}
              style={{
                marginTop: '0px',
                marginBottom: '20px',
                borderRadius: '10px',
              }}
            >
              <CardContent>
                <div className={`${styles.insight_header}`}>
                  <h5>Top Selling Brands</h5>
                  <div className="d-lg-flex justify-content-between">
                    <p>
                      Here are the brands you have sold <br /> the most
                    </p>
                    <div className="row">
                      <div className="col">
                        <div className={`${styles.dropdown_wrap}`}>
                          <button
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className={`btn border w-100`}
                            style={{
                              backgroundColor: '#fff',
                              border: '1px solid #D0D5DD',
                              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                              borderRadius: '4px',
                            }}
                          >
                            <img
                              className=""
                              style={{ display: 'inline', width: '20%' }}
                              src="/assets/icons/filterlines.svg"
                              alt=""
                            />
                            &nbsp;
                            <small className="d-none d-md-inline-block">
                              Filter
                            </small>
                          </button>
                          <div
                            className={`${styles.product_dialog_twoo} dropdown-menu p-1`}
                            aria-labelledby="dropdownMenuButton"
                          >
                            <div
                              className={`${styles.modal_content}`}
                              style={{ width: '300px', padding: '20px' }}
                            >
                              <div className={`${styles.modal_header} mb-0`}>
                                <h5>Categories</h5>
                                <span
                                  onClick={handleCloseRvModal}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <img src="/assets/icons/x.svg" alt="" />
                                </span>
                              </div>
                              <div className={`${styles.modal_content} `}>
                                <div className="allprod_wrapper">
                                  <div
                                    className="modal-header"
                                    style={{ border: 'none' }}
                                  >
                                    <span
                                      className="modal-title ml-0"
                                      id="exampleModalLabel"
                                    >
                                      Select Categories
                                    </span>
                                  </div>
                                  <div className={`${styles.modal_body}`}>
                                    <p>
                                      <input
                                        type="text"
                                        value={searchCategory}
                                        onChange={handleCategorySearch}
                                        className="form-control"
                                        placeholder="Search..."
                                        name=""
                                        id=""
                                      />
                                    </p>
                                    <div className="my-0 py-0">
                                      {categoriesData &&
                                        !categoryPending &&
                                        categoriesData.map((el, i) => (
                                          <div className="py-0 my-0">
                                            <label htmlFor="category">
                                              <input
                                                type="checkbox"
                                                name="category"
                                                id="category"
                                                checked={categorySelectedIds.includes(
                                                  el._id
                                                )}
                                                onChange={() =>
                                                  handleCategoryChange(el._id)
                                                }
                                              />
                                              &nbsp;&nbsp;
                                              {el.category_name}
                                            </label>
                                          </div>
                                        ))}
                                      {!categoriesData.length &&
                                        !categoryPending && (
                                          <div className="alert alert-[#af3585] text-center">
                                            <i>No record found</i>
                                          </div>
                                        )}
                                      <div className="d-flex justify-content-center">
                                        {categoryPending && (
                                          <BallTriangle
                                            height={50}
                                            width={55}
                                            radius={5}
                                            color="#af3585"
                                            ariaLabel="ball-triangle-loading"
                                            wrapperClass={{}}
                                            wrapperStyle=""
                                            visible={true}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-5">
                                <button
                                  type="button"
                                  onClick={() => handleProceed()}
                                  className="btn text-white btn-[#af3585] bg-[#8c2d915d] w-100 d-block"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  style={{ backgroundColor: '#af3585' }}
                                >
                                  Proceed
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      &nbsp;
                      <div className="col-5">
                        <div className="dropleft "></div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-md-0 mt-4">
                    <div className="col-md-6 mb-2 mt-4"></div>
                    <div className="col-md-6">
                      <div className="card p-0 mb-2 mt-4">
                        <div className="card-body d-flex justify-content-around  py-1">
                          <label htmlFor="tab1">
                            <input
                              type="radio"
                              id="tab1"
                              name="top_selling_brands"
                              value={1}
                              checked={selectedTab === 1}
                              onChange={() => handleTabChange(1)}
                              className="mt-2 mr-2"
                            />
                            Bar Chart
                          </label>
                          <label htmlFor="tab2">
                            <input
                              type="radio"
                              id="tab2"
                              name="top_selling_brands"
                              value={2}
                              checked={selectedTab === 2}
                              onChange={() => handleTabChange(2)}
                              className="mt-2 mr-2"
                            />
                            Line Chart
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {selectedTab === 1 && (
                  <div className="chart-container container_adjust">
                    {/* Content for Tab 2 */}
                    <ReactApexChart
                      type="bar"
                      series={chartSeries}
                      options={chartOptions}
                      height={500}
                    />
                  </div>
                )}
                {selectedTab === 2 && (
                  <div className="chart-container container_adjust">
                    {/* Content for Tab 2 */}
                    <ReactApexChart
                      type="line"
                      series={chartSeries}
                      options={chartOptions}
                      height={500}
                    />
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
          <div className="col-md-6 mb-2">
            <Card
              className={`${styles.card_wrapper} p-0`}
              style={{ marginBottom: '20px', borderRadius: '10px' }}
            >
              <CardContent>
                <div className={`${styles.insight_header}`}>
                  <h5>
                    Brand owner with <br /> most promos
                  </h5>
                  <div className="d-lg-flex justify-content-between">
                    <p>
                      View the brand owners with the <br /> most promo running
                      present and past
                    </p>
                    <div className="row">
                      <div className="col">
                        <div className={`${styles.dropdown_wrap}`}>
                          <button
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className={`btn border w-100`}
                            style={{
                              backgroundColor: '#fff',
                              border: '1px solid #D0D5DD',
                              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                              borderRadius: '4px',
                            }}
                          >
                            <img
                              className=""
                              style={{ display: 'inline', width: '20%' }}
                              src="/assets/icons/filterlines.svg"
                              alt=""
                            />
                            &nbsp;
                            <small className="d-none d-md-inline-block">
                              Filter
                            </small>
                          </button>
                          <div
                            className={`${styles.product_dialog_twoo} dropdown-menu p-1`}
                            aria-labelledby="dropdownMenuButton"
                          >
                            <div
                              className={`${styles.modal_content}`}
                              style={{ width: '300px', padding: '20px' }}
                            >
                              <div className={`${styles.modal_header} mb-0`}>
                                <h5>Categories</h5>
                                <span
                                  onClick={handleCloseRvModal}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <img src="/assets/icons/x.svg" alt="" />
                                </span>
                              </div>
                              <div className={`${styles.modal_content} `}>
                                <div className="allprod_wrapper">
                                  <div
                                    className="modal-header"
                                    style={{ border: 'none' }}
                                  >
                                    <span
                                      className="modal-title ml-0"
                                      id="exampleModalLabel"
                                    >
                                      Select Categories
                                    </span>
                                  </div>
                                  <div className={`${styles.modal_body}`}>
                                    <p>
                                      <input
                                        type="text"
                                        value={searchCategory}
                                        onChange={handleCategorySearch}
                                        className="form-control"
                                        placeholder="Search..."
                                        name=""
                                        id=""
                                      />
                                    </p>
                                    <div className="my-0 py-0">
                                      {categoriesData &&
                                        !categoryPending &&
                                        categoriesData.map((el, i) => (
                                          <div className="py-0 my-0">
                                            <label htmlFor="category">
                                              <input
                                                type="checkbox"
                                                name="category"
                                                id="category"
                                                checked={categorySelectedIds.includes(
                                                  el._id
                                                )}
                                                onChange={() =>
                                                  handleCategoryChange(el._id)
                                                }
                                              />
                                              &nbsp;&nbsp;
                                              {el.category_name}
                                            </label>
                                          </div>
                                        ))}
                                      {!categoriesData.length &&
                                        !categoryPending && (
                                          <div className="alert alert-[#af3585] text-center">
                                            <i>No record found</i>
                                          </div>
                                        )}
                                      <div className="d-flex justify-content-center">
                                        {categoryPending && (
                                          <BallTriangle
                                            height={50}
                                            width={55}
                                            radius={5}
                                            color="#af3585"
                                            ariaLabel="ball-triangle-loading"
                                            wrapperClass={{}}
                                            wrapperStyle=""
                                            visible={true}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-5">
                                <button
                                  type="button"
                                  onClick={() => handleBMPProceed()}
                                  className="btn text-white btn-[#af3585] bg-[#8c2d915d] w-100 d-block"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  style={{ backgroundColor: '#af3585' }}
                                >
                                  Proceed
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      &nbsp;
                      <div className="col-5">
                        <div className="dropleft "></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chart-container">
                  <ReactApexChart
                    type="bar"
                    series={chartSeries1}
                    options={chartOptions1}
                    height={550}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-2">
            <div className={`${styles.insight_header}`}>
              <h5>Statistics from Merchants</h5>
            </div>
            <Card
              className={`${styles.card_wrapper} p-0`}
              style={{
                marginTop: '0px',
                marginBottom: '20px',
                borderRadius: '10px',
              }}
            >
              <CardContent>
                <div className={`${styles.insight_header}`}>
                  <h5>Top Selling Products</h5>
                  <div className="d-lg-flex justify-content-between">
                    <p>
                      Here are the products you have sold most <br /> on
                      FlexDeals
                    </p>
                    <div className="row">
                      <div className="col">
                        <div className={`${styles.dropdown_wrap}`}>
                          <button
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className={`btn border w-100`}
                            style={{
                              backgroundColor: '#fff',
                              border: '1px solid #D0D5DD',
                              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                              borderRadius: '4px',
                            }}
                          >
                            <img
                              className=""
                              style={{ display: 'inline', width: '20%' }}
                              src="/assets/icons/filterlines.svg"
                              alt=""
                            />
                            &nbsp;
                            <small className="d-none d-md-inline-block">
                              Filter
                            </small>
                          </button>
                          <div
                            className={`${styles.product_dialog_twoo} dropdown-menu p-1`}
                            aria-labelledby="dropdownMenuButton"
                          >
                            <div
                              className={`${styles.modal_content}`}
                              style={{ width: '300px', padding: '20px' }}
                            >
                              <div className={`${styles.modal_header} mb-0`}>
                                <h5>Categories</h5>
                                <span
                                  onClick={handleCloseRvModal}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <img src="/assets/icons/x.svg" alt="" />
                                </span>
                              </div>
                              <div className={`${styles.modal_content} `}>
                                <div className="allprod_wrapper">
                                  <div
                                    className="modal-header"
                                    style={{ border: 'none' }}
                                  >
                                    <span
                                      className="modal-title ml-0"
                                      id="exampleModalLabel"
                                    >
                                      Select Categories
                                    </span>
                                  </div>
                                  <div className={`${styles.modal_body}`}>
                                    <p>
                                      <input
                                        type="text"
                                        value={searchCategory}
                                        onChange={handleCategorySearch}
                                        className="form-control"
                                        placeholder="Search..."
                                        name=""
                                        id=""
                                      />
                                    </p>
                                    <div className="my-0 py-0">
                                      {categoriesData &&
                                        !categoryPending &&
                                        categoriesData.map((el, i) => (
                                          <div className="py-0 my-0">
                                            <label htmlFor="category">
                                              <input
                                                type="checkbox"
                                                name="category"
                                                id="category"
                                                checked={categorySelectedIds.includes(
                                                  el._id
                                                )}
                                                onChange={() =>
                                                  handleCategoryChange(el._id)
                                                }
                                              />
                                              &nbsp;&nbsp;
                                              {el.category_name}
                                            </label>
                                          </div>
                                        ))}
                                      {!categoriesData.length &&
                                        !categoryPending && (
                                          <div className="alert alert-[#af3585] text-center">
                                            <i>No record found</i>
                                          </div>
                                        )}
                                      <div className="d-flex justify-content-center">
                                        {categoryPending && (
                                          <BallTriangle
                                            height={50}
                                            width={55}
                                            radius={5}
                                            color="#af3585"
                                            ariaLabel="ball-triangle-loading"
                                            wrapperClass={{}}
                                            wrapperStyle=""
                                            visible={true}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-5">
                                <button
                                  type="button"
                                  onClick={() => handleTSPProceed()}
                                  className="btn text-white btn-[#af3585] bg-[#8c2d915d] w-100 d-block"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  style={{ backgroundColor: '#af3585' }}
                                >
                                  Proceed
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      &nbsp;
                      <div className="col-5">
                        <div className="dropleft ">
                          {/* <button
                            id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            className={`${styles.export_btn} btn m-0 mr-5`}
                            style={{
                              fontSize:'11px'
                            }}
                          >
                            <span style={{
                            paddingTop: '8px',
                            }}>
                            <img src="/assets/icons/downloadwhite.png" alt="" /> &nbsp;
                            Export
                            </span>
                            &nbsp;
                            &nbsp;
                            <span style={{
                              borderLeft:'1px solid white',
                              display:'inline-block',
                              height:'inherit',
                              paddingTop: '8px',
                              paddingLeft: '8px',
                              textAlign:'center',
                            }}>
                              <img src="/assets/icons/chevron-down.png" alt="" />
                            </span>
                          </button>
                          <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                            <button type="button"  className="rss dropdown-item btn border-0">CSV</button>
                            <button type="button"    className="rss dropdown-item btn border-0">Excel </button>
                            <button type="button"    className="rss dropdown-item btn border-0">PDF </button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-md-0 mt-4">
                    <div className="col-md-4 mb-2 mt-4"></div>
                    <div className="col-md-8">
                      <div className="card p-0 mb-2 mt-4">
                        <div className="card-body d-flex justify-content-around  py-1">
                          <label htmlFor="tab3">
                            <input
                              type="radio"
                              id="tab3"
                              name="top_selling_product"
                              value={1}
                              checked={selectedTab1 === 1}
                              onChange={() => handleTabChange1(1)}
                              className="mt-2 mr-2"
                            />
                            Bar Chart
                          </label>
                          <label htmlFor="tab4">
                            <input
                              type="radio"
                              id="tab4"
                              name="top_selling_product"
                              value={2}
                              checked={selectedTab1 === 2}
                              onChange={() => handleTabChange1(2)}
                              className="mt-2 mr-2"
                            />
                            Line Chart
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {selectedTab1 === 1 && (
                  <div className="chart-container container_adjust2">
                    {/* Content for Tab 2 */}
                    <ReactApexChart
                      type="bar"
                      series={chartSeries2}
                      options={chartOptions2}
                      height={500}
                    />
                  </div>
                )}
                {selectedTab1 === 2 && (
                  <div className="chart-container container_adjust2">
                    {/* Content for Tab 2 */}
                    <ReactApexChart
                      type="line"
                      series={chartSeries2}
                      options={chartOptions2}
                      height={500}
                    />
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
          <div className="col-md-6 mb-2">
            <div className={`${styles.insight_header}`}>
              <h5>Statistics from shoppers behaviour</h5>
            </div>
            <Card
              className={`${styles.card_wrapper} p-0`}
              style={{
                marginTop: '0px',
                marginBottom: '20px',
                borderRadius: '10px',
              }}
            >
              <CardContent>
                <div className={`${styles.insight_header}`}>
                  <h5>Shopper's purchasing power</h5>
                  <div className="d-lg-flex justify-content-between">
                    <p>An overview of the average amount your shoppers buy</p>
                    <div className="d-flex">
                      {/* <div className="dropleft ">
                        <button
                          id="dropdownMenuButton"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                          className={`${styles.export_btn} btn m-0`}
                          style={{
                            fontSize:'11px'
                          }}
                        >
                          <span style={{
                          paddingTop: '8px',
                          }}>
                          <img src="/assets/icons/downloadwhite.png" alt="" /> &nbsp;
                          Export
                          </span>
                          &nbsp;
                          &nbsp;
                          <span style={{
                            borderLeft:'1px solid white',
                            display:'inline-block',
                            height:'inherit',
                            paddingTop: '8px',
                            paddingLeft: '8px',
                            textAlign:'center',
                          }}>
                            <img src="/assets/icons/chevron-down.png" alt="" />
                          </span>
                        </button>
                        <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                          <button type="button"  className="rss dropdown-item btn border-0">CSV</button>
                          <button type="button"    className="rss dropdown-item btn border-0">Excel </button>
                          <button type="button"    className="rss dropdown-item btn border-0">PDF </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="row mt-md-0 mt-4">
                    <div className="col-md-3 mb-2 mt-4"></div>
                    <div className="col-md-9">
                      <div className="card p-0 mb-2 mt-4">
                        <div className="card-body d-flex justify-content-around  py-1">
                          <label htmlFor="tab5">
                            <input
                              type="radio"
                              id="tab5"
                              name="shoppers_buying_power"
                              value={1}
                              checked={selectedTab2 === 1}
                              onChange={() => handleTabChange2(1)}
                              className="mt-2 mr-2"
                            />
                            Bar Chart
                          </label>
                          <label htmlFor="tab6">
                            <input
                              type="radio"
                              id="tab6"
                              name="shoppers_buying_power"
                              value={2}
                              checked={selectedTab2 === 2}
                              onChange={() => handleTabChange2(2)}
                              className="mt-2 mr-2"
                            />
                            Pie Chart
                          </label>
                          <label htmlFor="tab7">
                            <input
                              type="radio"
                              id="tab7"
                              name="shoppers_buying_power"
                              value={3}
                              checked={selectedTab2 === 3}
                              onChange={() => handleTabChange2(3)}
                              className="mt-2 mr-2"
                            />
                            Line Chart
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {selectedTab2 === 1 && (
                  <div>
                    {/* Content for Tab 4 */}
                    <ReactApexChart
                      type="bar"
                      series={chartSeries3}
                      options={chartOptions3}
                      height={500}
                    />
                  </div>
                )}
                {selectedTab2 === 2 && (
                  <div className="chart-container">
                    {/* Content for Tab 4 */}
                    <ReactApexChart
                      type="pie"
                      series={chartSeries3}
                      options={chartOptions3}
                      height={500}
                    />
                  </div>
                )}

                {selectedTab2 === 3 && (
                  <div className="chart-container">
                    {/* Content for Tab 4 */}
                    <ReactApexChart
                      type="line"
                      series={chartSeries3}
                      options={chartOptions3}
                      height={500}
                    />
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="col-md-12 mb-2 px-0">
          <Card
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              borderRadius: '10px',
            }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Revenue Growth</h5>
                <div className="d-md-flex justify-content-between">
                  <p>Track your store financial growth</p>
                  <div className="d-flex">
                    <div className="dropleft "></div>
                    &nbsp; &nbsp;
                    <div className="dropleft ">
                      {/* <button
                      id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      className={`${styles.export_btn} btn m-0`}
                      style={{
                        fontSize:'11px'
                      }}
                    >
                      <span style={{
                      paddingTop: '8px',
                      }}>
                      <img src="/assets/icons/downloadwhite.png" alt="" /> &nbsp;
                      Export
                      </span>
                      &nbsp;
                      &nbsp;
                      <span style={{
                        borderLeft:'1px solid white',
                        display:'inline-block',
                        height:'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign:'center',

                      }}>
                        <img src="/assets/icons/chevron-down.png" alt="" />
                      </span>
                    </button>

                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      <button type="button" className="rss dropdown-item btn border-0">CSV</button>
                      <button type="button" className="rss dropdown-item btn border-0">Excel </button>
                      <button type="button" className="rss dropdown-item btn border-0">PDF </button>
                    </div> */}
                    </div>
                  </div>
                </div>
                <div className="row mt-md-3 mt-4">
                  <div className="col-md-6 mb-2">
                    <div className="row">
                      <div className="col-md-8"></div>
                    </div>
                  </div>
                  <div className="row ml-auto pr-5 text-md-right">
                    <div className="card p-0">
                      <div className="card-body d-flex justify-content-around  py-1">
                        <label htmlFor="tab3" className="mr-3">
                          <input
                            type="radio"
                            id="tab3"
                            name="revenue_growth"
                            value={1}
                            checked={selectedTab3 === 1}
                            onChange={() => handleTabChange3(1)}
                            className="mt-2 mr-2"
                          />
                          Bar Chart
                        </label>
                        <label htmlFor="tab4">
                          <input
                            type="radio"
                            id="tab4"
                            name="revenue_growth"
                            value={2}
                            checked={selectedTab3 === 2}
                            onChange={() => handleTabChange3(2)}
                            className="mt-2 mr-2"
                          />
                          Line Chart
                        </label>
                      </div>
                    </div>
                    <span className="ml-3 mt-3">
                      <img src="/assets/icons/ordercolor.svg" alt="" />
                      &nbsp; Revenue
                    </span>
                  </div>
                </div>
              </div>

              {selectedTab3 === 1 && (
                <div className="chart-container">
                  <ReactApexChart
                    type="bar"
                    series={chartSeries4}
                    options={chartOptions4}
                    height={500}
                  />
                </div>
              )}
              {selectedTab3 === 2 && (
                <div className="chart-container">
                  <ReactApexChart
                    type="line"
                    series={chartSeries4}
                    options={chartOptions4}
                    height={500}
                  />
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </div>

      <GlobalModal
        open={rvModalOpen}
        onClose={handleCloseRvModal}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        height="auto !important"
        maxHeight="50vh !important"
        overflow="auto"
        bgcolor="#fff"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span onClick={handleCloseRvModal} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>
          <div className={`${styles.modal_content} row`}>
            <div className="col-md-6">
              <div className="allprod_wrapper">
                <div className="modal-header" style={{ border: 'none' }}>
                  <h5 className="modal-title" id="exampleModalLabel">
                    All Products
                  </h5>
                </div>
                <div className={`${styles.modal_body}`}>
                  <div className="row my-0 py-0">
                    <div className="col-7 py-0 my-0">
                      <label htmlFor="allprod">
                        <input type="checkbox" name="allprod" id="allprod" />
                        &nbsp;&nbsp;All Products
                      </label>
                    </div>
                    <div className="col-5 py-0 my-0">
                      <span
                        className={`${styles.span}`}
                        data-toggle="collapse"
                        data-target="#collapseExample04"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        <label>
                          <input type="checkbox" name="" />
                          <span>
                            <i className="bi bi-chevron-down"></i>
                          </span>
                        </label>
                      </span>
                    </div>
                  </div>

                  <div className="collapse" id="collapseExample04">
                    <div
                      className="card card-body pl-5 pb-0"
                      style={{ border: 'none' }}
                    >
                      <p>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          name=""
                          id=""
                        />
                      </p>
                      <p>
                        <label htmlFor="milo1">
                          <input type="checkbox" name="milo1" id="milo1" />
                          &nbsp;&nbsp;<span>Nestle Milo</span>
                        </label>
                      </p>
                      <p>
                        <label htmlFor="water1">
                          <input type="checkbox" name="water1" id="water1" />
                          &nbsp;&nbsp;<span>Pure life table water</span>
                        </label>
                      </p>
                      <p>
                        <label htmlFor="nescafe1">
                          <input
                            type="checkbox"
                            name="nescafe1"
                            id="nescafe1"
                          />
                          &nbsp;&nbsp;<span>Nescafe</span>
                        </label>
                      </p>
                      <p>
                        <label htmlFor="kitkat1">
                          <input type="checkbox" name="kitkat1" id="kitkat1" />
                          &nbsp;&nbsp;<span>Kitkat</span>
                        </label>
                      </p>
                      <p>
                        <label htmlFor="maggi1">
                          <input type="checkbox" name="maggi1" id="maggi1" />
                          &nbsp;&nbsp;<span>Maggi</span>
                        </label>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="allprod_wrapper">
                <div className={`${styles.modal_body}`}>
                  <div className="row my-0 py-0">
                    <div className="col-7 py-0 my-0">
                      <label htmlFor="allprod">
                        <input type="checkbox" name="allprod" id="allprod" />
                        &nbsp;&nbsp;All Category
                      </label>
                    </div>
                    <div className="col-5 py-0 my-0">
                      <span
                        className={`${styles.span}`}
                        data-toggle="collapse"
                        data-target="#collapseExample01"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        <label>
                          <input type="checkbox" name="" />
                          <span>
                            <i className="bi bi-chevron-down"></i>
                          </span>
                        </label>
                      </span>
                    </div>
                  </div>

                  <div className="collapse" id="collapseExample01">
                    <div
                      className="card card-body pl-5 pb-0"
                      style={{ border: 'none' }}
                    >
                      <p>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          name=""
                          id=""
                        />
                      </p>
                      <p>
                        <label htmlFor="milo1">
                          <input type="checkbox" name="milo1" id="milo1" />
                          &nbsp;&nbsp;<span>Beverages</span>
                        </label>
                      </p>
                      <p>
                        <label htmlFor="water1">
                          <input type="checkbox" name="water1" id="water1" />
                          &nbsp;&nbsp;<span>Snacks</span>
                        </label>
                      </p>
                      <p>
                        <label htmlFor="nescafe1">
                          <input
                            type="checkbox"
                            name="nescafe1"
                            id="nescafe1"
                          />
                          &nbsp;&nbsp;<span>Milk</span>
                        </label>
                      </p>
                      <p>
                        <label htmlFor="kitkat1">
                          <input type="checkbox" name="kitkat1" id="kitkat1" />
                          &nbsp;&nbsp;<span>Table Water</span>
                        </label>
                      </p>
                      <p>
                        <label htmlFor="maggi1">
                          <input type="checkbox" name="maggi1" id="maggi1" />
                          &nbsp;&nbsp;<span>Groceries</span>
                        </label>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${styles.bordered_sect} col-md-6`}
              style={{
                borderLeft: '2px solid #ddd',
              }}
            >
              <div className="allprod_wrapper">
                <div className="modal-header" style={{ border: 'none' }}>
                  <h5 className="modal-title" id="exampleModalLabel">
                    Location/Store
                  </h5>
                </div>
                <div className={`${styles.modal_body}`}>
                  <p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      name=""
                      id=""
                    />
                  </p>
                  <div className="row my-0 py-0">
                    <div className="col-5 py-0 my-0">
                      <label htmlFor="country">
                        <input type="checkbox" name="country" id="country" />
                        &nbsp;&nbsp;Nigeria
                      </label>
                    </div>
                    <div className="col-5 py-0 my-0">
                      <span
                        className={`${styles.span}`}
                        data-toggle="collapse"
                        data-target="#collapseExample02"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        <label>
                          <input type="checkbox" name="" />
                          <span>
                            <i className="bi bi-chevron-down"></i>
                          </span>
                        </label>
                      </span>
                    </div>
                  </div>

                  <div className="collapse pl-1" id="collapseExample02">
                    <div
                      className="card card-body pl-5 pb-0"
                      style={{ border: 'none' }}
                    >
                      <p>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          name=""
                          id=""
                        />
                      </p>
                      <div className="row mt-3">
                        <div className="col-5 py-0 my-0">
                          <label htmlFor="state">
                            <input type="checkbox" name="state" id="state" />
                            &nbsp;&nbsp;Lagos
                          </label>
                        </div>
                        <div className="col-5 py-0 my-0">
                          <span
                            className={`${styles.span}`}
                            data-toggle="collapse"
                            data-target="#collapseExample03"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          >
                            <label>
                              <input type="checkbox" name="" />
                              <span>
                                <i className="bi bi-chevron-down"></i>
                              </span>
                            </label>
                          </span>
                        </div>
                      </div>

                      <div className="collapse pl-1" id="collapseExample03">
                        <div
                          className="card card-body pl-5 pb-0"
                          style={{ border: 'none' }}
                        >
                          <p>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                              name=""
                              id=""
                            />
                          </p>
                          <p>
                            <label htmlFor="milo2">
                              <input type="checkbox" name="milo2" id="milo2" />
                              &nbsp;&nbsp;<span>Mama tee superstore</span>
                            </label>
                          </p>
                          <p>
                            <label htmlFor="water2">
                              <input
                                type="checkbox"
                                name="water2"
                                id="water2"
                              />
                              &nbsp;&nbsp;<span>Shoprite</span>
                            </label>
                          </p>
                          <p>
                            <label htmlFor="nescafe2">
                              <input
                                type="checkbox"
                                name="nescafe2"
                                id="nescafe2"
                              />
                              &nbsp;&nbsp;<span>Justrite</span>
                            </label>
                          </p>
                          <p>
                            <label htmlFor="kitkat2">
                              <input
                                type="checkbox"
                                name="kitkat2"
                                id="kitkat2"
                              />
                              &nbsp;&nbsp;<span>Mallmart</span>
                            </label>
                          </p>
                          <p>
                            <label htmlFor="maggi2">
                              <input
                                type="checkbox"
                                name="maggi2"
                                id="maggi2"
                              />
                              &nbsp;&nbsp;<span>Jendol</span>
                            </label>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <button
              className="btn btn-[#af3585] bg-[#8c2d915d] w-100 d-block"
              data-dismiss="modal"
              aria-label="Close"
            >
              Proceed
            </button>
          </div>
        </div>
      </GlobalModal>

      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position="absolute"
        top="50%"
        left="80%"
        transform="translate(-50%, -50%)"
        width="440px !important"
        height="auto !important"
        maxHeight="50vh !important"
        overflow="auto"
        bgcolor="#fff"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>
          <div className={`${styles.modal_content}`}>
            <div className="">
              <div className="allprod_wrapper">
                <div className={`${styles.modal_body}`}>
                  <div className="row my-0 py-0">
                    <div className="col-7 py-0 my-0">
                      <label htmlFor="allprod">
                        <input type="checkbox" name="allprod" id="allprod" />
                        &nbsp;&nbsp;All Products
                      </label>
                    </div>
                    <div className="col-5 py-0 my-0">
                      <span
                        className={`${styles.span}`}
                        data-toggle="collapse"
                        data-target="#collapseExample01"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        <label>
                          <input type="checkbox" name="" />
                          <span>
                            <i className="bi bi-chevron-down"></i>
                          </span>
                        </label>
                      </span>
                    </div>
                  </div>

                  <div className="collapse" id="collapseExample01">
                    <div
                      className="card card-body pl-5 pb-0"
                      style={{ border: 'none' }}
                    >
                      <p>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          name=""
                          id=""
                        />
                      </p>
                      <p>
                        <label htmlFor="milo1">
                          <input type="checkbox" name="milo1" id="milo1" />
                          &nbsp;&nbsp;<span>Nestle Milo</span>
                        </label>
                      </p>
                      <p>
                        <label htmlFor="water1">
                          <input type="checkbox" name="water1" id="water1" />
                          &nbsp;&nbsp;<span>Pure life table water</span>
                        </label>
                      </p>
                      <p>
                        <label htmlFor="nescafe1">
                          <input
                            type="checkbox"
                            name="nescafe1"
                            id="nescafe1"
                          />
                          &nbsp;&nbsp;<span>Nescafe</span>
                        </label>
                      </p>
                      <p>
                        <label htmlFor="kitkat1">
                          <input type="checkbox" name="kitkat1" id="kitkat1" />
                          &nbsp;&nbsp;<span>Kitkat</span>
                        </label>
                      </p>
                      <p>
                        <label htmlFor="maggi1">
                          <input type="checkbox" name="maggi1" id="maggi1" />
                          &nbsp;&nbsp;<span>Maggi</span>
                        </label>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <button
              className="btn btn-[#af3585] bg-[#8c2d915d] w-100 d-block"
              data-dismiss="modal"
              aria-label="Close"
            >
              Proceed
            </button>
          </div>
        </div>
      </GlobalModal>
    </>
  );
}
