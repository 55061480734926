export function createAbbreviation(name) {
  // Split the name by spaces
  const words = name.split(' ');

  // Get the first letter of the first word
  let abbreviation = words[0][0].toUpperCase();

  // Get the first letter of the second word if it exists, or the third if the second doesn't
  if (words.length > 1) {
    abbreviation += words[1][0].toUpperCase();
  } else if (words.length > 2) {
    abbreviation += words[2][0].toUpperCase();
  }

  return abbreviation;
}
