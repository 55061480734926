import React from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import styles from "../../pages/Customers/BrandOwner.module.css";

const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    // height: 190,
    bgcolor: '#FFFFFF',
    border: '1px solid #F5F5F5',
    borderRadius: '5px',
    boxShadow: 24,
    p: 1,
};

const ImagePreviewModal = ({ isOpen, onClose, imageUrl, imageType }) => {

  return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        disableEnforceFocus
    >
        <Fade in={isOpen}>
            <Box className={`mobile_modal_size pt-md-3 ${styles.modal_bg}`}
                sx={style3}>
                <h5 style={{fontWeight: 'bold'}}>{imageType} Preview</h5>
                <div className={`text-center mt-2 ${styles.add_to_cart_content}`}>
                    <img src={imageUrl} alt="Preview" />
                    <div>
                        <button className={`${styles.go_to} d-block mt-3 w-100 `}
                            style={{ fontSize: '14px' }} onClick={() => onClose()}>
                            Close
                        </button>
                    </div>
                </div>
            </Box>
        </Fade>
    </Modal>
  );
};

export default ImagePreviewModal;
