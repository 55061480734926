import { useState } from 'react';
import { useDropDowns } from 'src/hooks/useDropDowns';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import bus from 'src/bus';
let data = {
  'All time': 'all',
  Today: 'today',
  'Past Week': 'pastweek',
  'Past 30 Days': '30days',
  'Past 90 Days': '60days',
  'Past 6 Months': '6months',
  'Past 1 Year': '1year',
};

//  all || pastweek || 30days || 60days || 90days || 6months
export default function Filter1({ width, onChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const dropdownFilters = useDropDowns();

  const open = Boolean(anchorEl);
  const [selectedFilter, setValue] = useState(dropdownFilters[0]?.name);
  const handleClickList = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleSelectFilter = (val, el, i) => {
    switch (i) {
      case 0:
        setAnchorEl(null);
        setValue(el);
        // if (onChange) {
        //   onChange(data[val]);
        // }
        bus.emit('dataFilter', val);

        break;

      default:
        break;
    }
  };

  return (
    <div
      className="text-right"
      style={{ width: width, background: '#EFF6FF', color: '#7a2571' }}
    >
      <div className=" w-100">
        <div className="d-inline-block">
          <span className="" style={{ fontSize: '12px' }}>
            This is your data for
          </span>
          &nbsp;
          <span style={{ fontSize: '12px' }}>{selectedFilter}</span>
          &nbsp;
          <span onClick={handleClickList} className="text-white">
            <img
              style={{ cursor: 'pointer' }}
              src="/assets/icons/darkTrigger.svg"
              alt=""
            />
          </span>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {dropdownFilters.map((item, i) => (
              <MenuItem
                key={i}
                onClick={() => handleSelectFilter(item.key, item.name, 0)}
              >
                {item.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
    </div>
  );
}
