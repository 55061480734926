import React from 'react';
import './LoadingBackground.css';

const LoadingBackground = () => {
  return (
    <div id='loading-bg'>
        <div className="loading">
            <div className="effect-1 effects"></div>
            <div className="effect-2 effects"></div>
            <div className="effect-3 effects"></div>
        </div>
    </div>
  );
};

export default LoadingBackground;