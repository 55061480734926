// ----------------------------------------------------------------------
import styles from './BrandOwner.module.css';
// import { Navigate, NavLink as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import Filter1 from 'src/pages/Filter/Filter1';

import { Card, Avatar } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
// sections
// mock
// import USERLIST from '../../_mock/order';
import { useEffect, useState } from 'react';
import bus from 'src/bus';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import moment from 'moment';
import { BallTriangle } from 'react-loader-spinner';
// import CircularProgress from '@mui/material/CircularProgress';

// ----------------------------------------------------------------------

// const TABLE_HEAD = [
//   { id: 'customerName', label: 'Company', alignRight: false },
//   { id: 'productUnit', label: ' Items', alignRight: false },
//   { id: 'price', label: 'Price', alignRight: false },
//   { id: 'status', label: 'Status', alignRight: false },
//   { id: 'created_at', label: 'Date ordered', alignRight: false },
//   { id: '' },
// ];

export default function SuperAdminPage() {
  const navigate = useNavigate();
  const [chartOptions1, setChartOptions1] = useState({
    chart: {
      type: 'bar',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '45%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  });
  const [chartSeries1, setChartSeries1] = useState([
    {
      name: 'Order stats',
      data: [400, 100, 400, 300, 400, 500],
    },
    {
      name: 'Visitors Stats',
      data: [400, 100, 400, 300, 400, 500],
    },
  ]);
  const [searchvalue, setSearchValue] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    brands: {
      boldCount: 0,
      smallCount: 0,
      percentageDiff: 0,
    },
    orders: {
      boldCount: 0,
      smallCount: 0,
      percentageDiff: 0,
    },
    newUser: {
      boldCount: 0,
      smallCount: 0,
      percentageDiff: 0,
    },
    visitors: {
      boldCount: 0,
      smallCount: 0,
      percentageDiff: 0,
    },
    orderVisitorsStats: {
      orderStats: [],
      visitorsStat: [],
    },
    sales: [],
  });
  const [categoryPagination, setCategoryPagination] = useState('');
  const [dataFilter, setDataFilter] = useState('today');
  // const notify = (msg = null) => toast.success(msg === null ? 'Store saved .' : msg);
  const notifyError = (err) => toast.error(err);

  function navigateTo() {
    navigate(`/app/sales`);
  }
  const handleViewClick = () => {
    localStorage.setItem('ptvo', 1);
    navigateTo();
    // handle view click action
  };
  const handleSendReminderClick = () => {
    // handle send reminder click action
  };

  const fetchData = (
    dateFilter = dataFilter,
    page = 1,
    pageSize = 10,
    search = searchvalue
  ) => {
    setIsPending(true);
    axiosInstance
      .get('/admin/dashboard?', {
        params: {
          page,
          pageSize,
          search,
          dateFilter,
        },
      })
      .then((res) => {
        setDashboardData(res.data.data);
        setCategoryPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          // notifyError("Unable to fetch dashboard records, please try again later");
        }
      })
      .finally(() => setIsPending(false));
  };

  useEffect(() => {
    bus.on('dataFilter', (el) => {
      // console.log(el);
      setDataFilter(el);
    });
    fetchData();
  }, [searchvalue, dataFilter]);

  useEffect(() => {
    // Create variable month
    let months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Create a variable that represent the chart data structure
    let data = [
      {
        name: 'Order stats',
        data: [],
      },
      {
        name: 'Visitors Stats',
        data: [],
      },
    ];

    // Iterate over months
    months.forEach((item) => {
      // Create a variable count to the count of chart data, should be 0 by default
      let count = 0;
      let count2 = 0;
      dashboardData.orderVisitorsStats.orderStats.forEach((el) => {
        if (item === el.month) count = el.total;
      });
      data[0].data.push(count);

      dashboardData.orderVisitorsStats.visitorsStat.forEach((el) => {
        if (item === el.month) count2 = el.count;
      });
      data[1].data.push(count2);
    });

    setChartSeries1(data);
  }, [dashboardData]);

  bus.on('reload', () => {
    fetchData();
  });

  return (
    <>
      <div className={`wrapper mt-md-0 mt-4 pt-2 px-2`}>
        <div className={`${styles.header} d-block d-md-none mb-4`}>
          <h4 className={`mb-0 pb-0`}>Dashboard</h4>
          <p style={{ fontSize: '14px' }} className={`mb-0 pb-0`}>
            An overview of your business performance Here is a quick summary of
            whats happening with your business
          </p>
        </div>
        <div className={`${styles.analytics} mt-4`}>
          <Filter1 width="100%" />
          <div className={``}>
            <div className="row">
              <div className={` mb-4 col-6`}>
                <div className={`${styles.card}`}>
                  <div className={`card-body`}>
                    <small>Brands</small>
                    <div className={`${styles.numbers} mt-3`}>
                      <div>
                        <span>
                          {dashboardData?.brands
                            ? Number(
                                dashboardData?.brands?.boldCount
                              ).toLocaleString()
                            : 0}
                        </span>{' '}
                        <small>
                          from{' '}
                          {dashboardData?.brands
                            ? Number(
                                dashboardData?.brands?.smallCount
                              ).toLocaleString()
                            : 0}
                        </small>
                      </div>
                      <div>
                        {Number(
                          dashboardData?.brands?.boldCount
                        ).toLocaleString() >=
                        Number(
                          dashboardData?.brands?.smallCount
                        ).toLocaleString() ? (
                          <div className={styles.percent}>
                            <img src="/assets/icons/up.svg" alt="" />
                            <small className="pl-1">
                              {dashboardData?.brands
                                ? Math.floor(
                                    Number(
                                      dashboardData?.brands?.percentageDiff
                                    ).toLocaleString()
                                  )
                                : 0}
                              %
                            </small>
                          </div>
                        ) : (
                          <div className={`${styles.percent2} d-flex p-1`}>
                            <img src="/assets/icons/downr.svg" alt="" />
                            <small className="pl-1">
                              {dashboardData?.brands
                                ? Math.abs(
                                    dashboardData?.brands?.percentageDiff
                                  ) + '%'
                                : 0}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`mb-4 col-6`}>
                <div className={`${styles.card}`}>
                  <div className={`card-body`}>
                    <small>Total Visitors</small>
                    <div className={`${styles.numbers}`}>
                      <div>
                        <span>
                          {dashboardData?.visitors
                            ? Number(
                                dashboardData?.visitors?.boldCount
                              ).toLocaleString()
                            : 0}
                        </span>{' '}
                        <small>
                          from{' '}
                          {dashboardData?.visitors
                            ? Number(
                                dashboardData?.visitors?.smallCount
                              ).toLocaleString()
                            : 0}
                        </small>
                      </div>
                      <div>
                        {Number(dashboardData?.visitors?.boldCount) >=
                        Number(dashboardData?.visitors?.smallCount) ? (
                          <div className={styles.percent}>
                            <img
                              src="/assets/icons/up.svg"
                              className=" "
                              alt=""
                            />{' '}
                            <small>
                              {dashboardData?.visitors
                                ? Math.floor(
                                    Number(
                                      dashboardData?.visitors?.percentageDiff
                                    ).toLocaleString()
                                  )
                                : 0}
                              %
                            </small>
                          </div>
                        ) : (
                          <div className={`${styles.percent2} d-flex p-1`}>
                            <img src="/assets/icons/downr.svg" alt="" />
                            <small className="pl-1">
                              {dashboardData?.visitors
                                ? Math.abs(
                                    dashboardData?.visitors?.percentageDiff
                                  ) + '%'
                                : 0}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className={` mb-4 col-6`}>
                <div className={`${styles.card}`}>
                  <div className={`card-body`}>
                    <small>Total Orders</small>
                    <div className={`${styles.numbers} mt-3`}>
                      <div>
                        <span>
                          {dashboardData?.orders
                            ? Number(
                                dashboardData?.orders?.boldCount
                              ).toLocaleString()
                            : 0}
                        </span>{' '}
                        <small>
                          from{' '}
                          {dashboardData?.orders
                            ? Number(
                                dashboardData?.orders?.smallCount
                              ).toLocaleString()
                            : 0}
                        </small>
                      </div>
                      <div>
                        {Number(dashboardData?.orders?.boldCount) >=
                        Number(dashboardData?.orders?.smallCount) ? (
                          <div className={styles.percent}>
                            <img src="/assets/icons/up.svg" alt="" />
                            <small className="pl-1">
                              {dashboardData?.orders
                                ? Math.floor(
                                    Number(
                                      dashboardData?.orders?.percentageDiff
                                    ).toLocaleString()
                                  )
                                : 0}
                              %
                            </small>
                          </div>
                        ) : (
                          <div className={`${styles.percent2} d-flex p-1`}>
                            <img src="/assets/icons/downr.svg" alt="" />
                            <small className="pl-1">
                              {dashboardData?.orders
                                ? Math.abs(
                                    dashboardData?.orders?.percentageDiff
                                  ) + '%'
                                : 0}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`mb-4 col-6`}>
                <div className={`${styles.card}`}>
                  <div className={`card-body`}>
                    <small>New Users</small>
                    <div className={`${styles.numbers}`}>
                      <div>
                        <span>
                          {dashboardData?.newUser
                            ? Number(
                                dashboardData?.newUser?.boldCount
                              ).toLocaleString()
                            : 0}
                        </span>{' '}
                        <small>
                          from{' '}
                          {dashboardData?.newUser
                            ? Number(
                                dashboardData?.newUser?.smallCount
                              ).toLocaleString()
                            : 0}
                        </small>
                      </div>
                      <div>
                        {Number(dashboardData?.newUser?.boldCount) >=
                        Number(dashboardData?.newUser?.smallCount) ? (
                          <div className={styles.percent}>
                            <img
                              src="/assets/icons/up.svg"
                              className=" "
                              alt=""
                            />{' '}
                            <small>
                              {dashboardData?.newUser
                                ? Math.floor(
                                    Number(
                                      dashboardData?.newUser?.percentageDiff
                                    ).toLocaleString()
                                  )
                                : 0}
                              %
                            </small>
                          </div>
                        ) : (
                          <div className={`${styles.percent2} d-flex p-1`}>
                            <img src="/assets/icons/downr.svg" alt="" />
                            <small className="pl-1">
                              {dashboardData?.newUser
                                ? Math.abs(
                                    dashboardData?.newUser?.percentageDiff
                                  ) + '%'
                                : 0}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 mb-2">
                <Card
                  style={{ marginTop: '20px', borderRadius: '10px' }}
                  className="p-0"
                >
                  <div className="p-3 ">
                    <h5 style={{ fontWeight: 'bold', fontSize: '17px' }}>
                      Sales
                    </h5>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <small
                        className={`${styles.sub_text_02}`}
                        style={{ fontSize: '14px' }}
                      >
                        {dataFilter === 'today'
                          ? `Transactions made for Today`
                          : dataFilter === 'alltime'
                          ? `Transactions made for All-Time`
                          : dataFilter === 'pastweek'
                          ? `Transactions made for the Past Week`
                          : ` Transactions made in the last ${dataFilter} `}
                      </small>
                      <small
                        style={{
                          color: '#af3585',
                          cursor: 'pointer',
                        }}
                        onClick={() => navigateTo()}
                      >
                        View all
                        <img src="/assets/icons/arrow-righty.svg" alt="" />
                      </small>
                    </div>
                  </div>
                  <Scrollbar>
                    <div className={`pt-3 pb-3 pl-0 pr-0`}>
                      <table
                        className={`${styles.table} table-hover table-striped `}
                        style={{ minWidth: '65em' }}
                      >
                        <thead className={`${styles.thead}`}>
                          <tr>
                            <th style={{ width: '25%' }} className="text-muted">
                              Customer
                              <span style={{ cursor: 'pointer' }}>
                                <img
                                  className="pl-2"
                                  src="/assets/icons/down.svg"
                                  alt=""
                                />
                              </span>
                            </th>
                            <th className={`table-center`}>Product Items</th>
                            <th>Price</th>
                            <th>Status</th>
                            <th>Date Ordered</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isPending &&
                            dashboardData.sales.length > 0 &&
                            dashboardData.sales.slice(0, 6).map((el, i) => {
                              // const {  customerName,email, category, status, productUnit, customerLocation,price,created_at   } = row;
                              // const customStatus = i%2===0 ? 'Completed' : 'Pending'

                              // const dysrc = i < 3 ? '/assets/outlet1.svg' : (i < 5 ? '/assets/shoprite.svg' : '/assets/blenco.svg')
                              return (
                                <tr
                                  style={{ cursor: 'pointer' }}
                                  className={`${styles.tr}`}
                                  key={el?._id}
                                >
                                  <td onClick={() => handleViewClick(el?._id)}>
                                    <div className=" ">
                                      <Avatar
                                        className={`${styles.avatrr} mr-3 float-md-left`}
                                        alt={el?.name}
                                        src={el?.logo}
                                      />
                                      <div className=" ">
                                        <p
                                          className="mb-0 text-left"
                                          style={{ fontSize: '14px' }}
                                        >
                                          <strong>{el?.name}</strong>
                                        </p>
                                        <p
                                          className="mb-0 text-left"
                                          style={{ fontSize: '10px' }}
                                        >
                                          {el?.email}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td
                                    className={`table-center`}
                                    onClick={() => handleViewClick(el?._id)}
                                  >
                                    <p>{el?.total_items}</p>
                                  </td>
                                  <td onClick={() => handleViewClick(el?._id)}>
                                    <p>
                                      ₦
                                      {el?.cost
                                        ? Number(el?.cost).toLocaleString()
                                        : 0}
                                    </p>
                                  </td>
                                  <td onClick={() => handleViewClick(el?._id)}>
                                    <p>
                                      <span
                                        className={`${
                                          el?.status === 'pending'
                                            ? styles.pending
                                            : styles.customer
                                        }`}
                                      >
                                        {el?.status}
                                        {/* { i === 0 || i ===5 ? 'Completed' : customStatus} */}
                                      </span>
                                    </p>
                                  </td>

                                  <td onClick={() => handleViewClick(el?._id)}>
                                    <p>
                                      {moment(el?.createdAt).format(
                                        'DD/MM/YYYY'
                                      )}
                                    </p>
                                  </td>
                                  {/* <td>
                                  <div className="dropleft ">

                                    <span
                                      id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"

                                      className={`${styles.action_button} px-3 py-2`}  style={{
                                      cursor:'pointer',

                                    }}>
                                      <img src="/assets/icons/action.svg" alt="" />

                                    </span>

                                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                                      <button type="button" onClick={() => handleViewClick(el?._id)} className="rss dropdown-item btn border-0">View</button>
                                      {customStatus === 'Abandoned' &&   <button
                                        className="rss btn border-0 dropdown-item"
                                        type="button"
                                        onClick={handleSendReminderClick}
                                      >
                                        Send Reminder
                                      </button>}
                                    </div>
                                  </div>

                                </td> */}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-center">
                      {isPending && (
                        <BallTriangle
                          height={50}
                          width={55}
                          radius={5}
                          color="#af3585"
                          ariaLabel="ball-triangle-loading"
                          wrapperClass={{}}
                          wrapperStyle=""
                          visible={true}
                        />
                      )}
                    </div>
                    {!dashboardData.sales.length && !isPending && (
                      <div className="alert alert-[#af3585] text-center">
                        <h4>No record found</h4>
                      </div>
                    )}
                  </Scrollbar>

                  {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={USERLIST.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  /> */}
                </Card>
              </div>
              <div className="col-md-4 mb-2">
                <div className="card mt-4 pb-5">
                  <div className="card-body px-0 pb-5">
                    <div className="p-3 ">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <h5 style={{ fontWeight: 'bold', fontSize: '17px' }}>
                          Orders and Visitors Stats
                        </h5>
                      </div>
                      {/* <small className="text-muted" style={{ fontSize: '14px'}}>
                        Track how your orders compares to your visitors average.
                        </small> */}
                      <div
                        className="mt-1"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span
                          className={`${styles.sub_text_02}`}
                          style={{ fontSize: '14px' }}
                        >
                          Track how your orders compare to your visitors average
                        </span>
                      </div>
                    </div>
                    <div className="mt-5 text-md-right pr-2 pb-3">
                      <span>
                        <img src="/assets/icons/ordercolor.svg" alt="" />
                        &nbsp; Order
                      </span>
                      &nbsp; &nbsp;
                      <span>
                        <img
                          src="/assets/icons/instockColor.jpg"
                          alt=""
                          style={{ width: '10px', borderRadius: '100%' }}
                        />
                        &nbsp; Visitors
                      </span>
                    </div>
                    <div className="p-0 chart-container chartOptions2">
                      <ReactApexChart
                        type="bar"
                        series={chartSeries1}
                        options={chartOptions1}
                        height={350}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
