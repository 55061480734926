import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import propTypes from "prop-types";

const DatePickerComponent = (props) => {
  return (
    <div>
      <DatePicker
        showIcon
        selected={props.value || new Date()}
        onChange={(date) => props.onChange(date)}
        dateFormat="dd/MM/yyyy"
        className="form-control"
        placeholderText="Start"
        required={props.required}
      />
    </div>
  );
};

DatePickerComponent.propTypes = {
  value: propTypes.string,
  onChange: propTypes.func,
  required: propTypes.bool,
};
export default DatePickerComponent;
