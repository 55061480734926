

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
import styles from './Page.module.css'
import Logo from "../logo/appLogo"
import { useNavigate } from "react-router-dom";
import bus from 'src/bus'
import { useState, forwardRef } from "react"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useAuth } from "src/hooks/useAuth";

export default function TCPage() {
  const { dispatch, data } = useAuth();
  let navigate = useNavigate();
  const [newValue] = useState(JSON.parse(localStorage.getItem('onRegister')))
  const [StoreID] = useState(JSON.parse(localStorage.getItem('storeID')))
  
  function navigateTo() {
    navigate(`/delete-account-steps`)

  }

  const handleBack = () => {
    bus.emit('back', newValue)
    setTimeout(() => {
      if (StoreID) {

        navigate(`/register?StoreID=${StoreID}`);
      } else {

        navigate('/register');
      }
    }, 1000);

  }

  return (
    <>
      <div className={`row ${styles.sav}`}>
        <div className="col-md-5 pr-md-0 d-none d-md-block">
          <div className={styles.bg}    >
            <div className={styles.content}>
              <h5>
                Welcome, Let's get to know <br /> more about your business
              </h5>

              <ul className={`${styles.features} list-unstyled`}>
                <li>
                  <img src='/assets/check-circle.svg' alt='ic' />
                  <small>
                    Your business becomes public to wholesalers and retailers
                  </small>
                </li>
                <li>
                  <img src='/assets/check-circle.svg' alt='ic' />

                  <small>
                    Businesses with completed profiles are 3x more likely to be <br /> seen by customers

                  </small>
                </li>
                <li>
                  <img src='/assets/check-circle.svg' alt='ic' />

                  <small>
                    Robust dashboard including real time data for making sales <br /> decisions

                  </small>
                </li>
                <li>
                  <img src='/assets/check-circle.svg' alt='ic' />

                  <small>
                    Unlimited access to all the features on your dashboard

                  </small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={`col-md-7 pl-md-0 ${styles.kyc_cover}`}>
          <div className='px-md-5'>
            <div className='py-3'>
              <Logo />
            </div>

            <div className={styles.kyc_content}>
              {/* <h2>
                KYC & MOU AGREEMENT
              </h2>
              <p>
                By entering the following Information information below you have signed 
                they know your customer (KYC) and Memorandum of understanding (MOU) 
                agreement and 
                <br /> 
                henceforth required to comply.
              </p> */}
              <div className={styles.body_content}>
                <h4>
                  PRIVACY NOTICE
                </h4>
                <p style={{lineHeight: '2',}}>
                  This privacy notice for FLEX DEALS ("Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:
                  <br />
                  <br />
                  1.  Download and use our mobile application, or any other application of ours that links to this privacy notice.
                  <br />
                  2.  Engage with us in other related ways, including any sales, marketing, or events.
                  Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at support@flexdeals.com.ng
                </p>
                <h4>
                  SUMMARY OF KEY POINTS
                </h4>
                <p style={{lineHeight: '2',}}>
                  This summary provides key points from our privacy notice, but you can find out more details about any of these topics by using our table of contents below to find the section you are looking for.
                  <br />
                  <br />
                  1.  What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with FLEX DEALS APP and the Services, the choices you make, and the products and features you use.
                  <br />
                  2.  Do we process any sensitive personal information? We do not process sensitive personal information.
                  <br />
                  3.  Do we receive any information from third parties? We may receive information from public databases, marketing partners, social media platforms, and other outside sources.
                  <br />
                  4.  How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.
                  <br />
                  5.  In what situations and with which parties do we share personal information? We may share information in specific situations and with specific third parties.
                  <br />
                  6.  What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.
                  <br />
                  Want to learn more about what RETAIL EXPRESS does with any information we collect? Review the privacy notice in full.
                </p>
                <h4>
                  TABLE OF CONTENTS
                </h4>
                <p style={{ lineHeight: '2', fontWeight: 'bold' }}>
                  1.  <a className='text-dark font-weight-bold' href="#what_info_do_we_collect">WHAT INFORMATION DO WE COLLECT?</a>
                  <br />
                  2.  <a className='text-dark font-weight-bold' href="#how_do_we_process_your_info">HOW DO WE PROCESS YOUR INFORMATION?</a>
                  <br />
                  3.  <a className='text-dark font-weight-bold' href="#when_and_with_whom">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>
                  <br />
                  4.  <a className='text-dark font-weight-bold' href="#how_long_do_we_keep_your_info">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
                  <br />
                  5.  <a className='text-dark font-weight-bold' href="#what_are_your_privacy_rights">WHAT ARE YOUR PRIVACY RIGHTS?</a>
                  <br />
                  6.  <a className='text-dark font-weight-bold' href="#what_happens_to_your_account_info_upon_termination">WHAT HAPPENS TO YOUR ACCOUNT INFORMATION UPON TERMINATION?</a>
                  <br />
                  7.  <a className='text-dark font-weight-bold' href="#do_we_make_updates_to_this_notice">DO WE MAKE UPDATES TO THIS NOTICE?</a>
                  <br />
                  8.  <a className='text-dark font-weight-bold' href="#how_can_you_contact_us">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
                  <br />
                  <br />
                </p>

                <p style={{lineHeight: '2',}}>
                  <div className='' id='what_info_do_we_collect'>
                    <b className='mb-3 d-block'>
                      1.  WHAT INFORMATION DO WE COLLECT?
                    </b>
                    a.  Personal information you disclose to us. We collect personal information that you
                    voluntarily provide to us when you register our application, express an interest in
                    obtaining information about us or our products and Services when you participate in
                    activities on the Services, or otherwise when you contact us.
                    <br />
                    b.  Sensitive Information. We do not process sensitive information. All personal information
                    that you provide to us must be true, complete, and accurate, and you must notify us of any
                    changes to such personal information.
                    <br />
                    c.  Information automatically collected. Some information, such as your Internet Protocol
                    (IP) address and/or browser and device characteristics is collected automatically when
                    you visit our Services.
                    <br />
                    We automatically collect certain information when you visit, use, or navigate our Services.
                    This information does not reveal your specific identity (like your name or contact information)
                    but may include device and usage information, such as your IP address, browser and device
                    characteristics, operating system, language preferences, referring URLs, device name, country,
                    location, information about how and when you use our Services, and other technical information.
                    This information is primarily needed to maintain the security and operation of our Services,
                    and for our internal analytics and reporting purposes.
                    Like many businesses, we also collect information through cookies and similar technologies.
                    <br />
                    <br />
                  </div>

                  <div className='pt-2' id='how_do_we_process_your_info'>
                    <b className='mb-3 d-block'>
                      2.  HOW DO WE PROCESS YOUR INFORMATION?
                    </b>
                    {/* <br /> */}
                    We process your information to provide, improve, and administer our Services, communicate with you,
                    for security and fraud prevention, and to comply with law. We may also process your information
                    for other purposes with your consent.
                    <br />
                    <br />
                  </div>

                  <div className='pt-2' id='when_and_with_whom'>
                    <b className='mb-3 d-block'>
                      3.  WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                    </b>
                    {/* <br /> */}
                    a.  We may share information in specific situations described in this section and/or with the following
                    third parties. We may need to share your personal information in the following situations:
                    participate in
                    activities on the Services, or otherwise when you contact us.
                    <br />
                    b.  Business Transfers. We may share or transfer your information in connection with or during negotiations
                    of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to
                    another company.
                    <br />
                    c.  Affiliates. We may share your information with our affiliates, in which case we will require those
                    affiliates to honour this privacy notice. Affiliates include our parent company and any subsidiaries,
                    joint venture partners, or other companies that we control or that are under common control with us.
                    <br />
                    d.  Business Partners. We may share your information with our business partners to offer you certain products,
                    services, or promotions.
                    <br />
                    <br />
                  </div>

                  <div className='pt-2' id='how_long_do_we_keep_your_info'>
                    <b className='mb-3 d-block'>
                      4.  HOW LONG DO WE KEEP YOUR INFORMATION?
                    </b>
                    {/* <br /> */}
                    a.  We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice
                    unless otherwise required by law.
                    <br />
                    b.  We will only keep your personal information for as long as it is necessary for the purposes set out
                    in this privacy notice unless a longer retention period is required or permitted by law
                    (such as tax, accounting, or other legal requirements).
                    <br />
                    c.  When we have no ongoing legitimate business need to process your personal information, we will either
                    delete or anonymize such information, or, if this is not possible (for example, because your personal information
                    has been stored in backup archives), then we will securely store your personal information and isolate it from any
                    further processing until deletion is possible.
                    <br />
                    <br />
                  </div>

                  <div className='pt-2' id='what_are_your_privacy_rights'>
                    <b className='mb-3 d-block'>
                      5.  WHAT ARE YOUR PRIVACY RIGHTS?
                    </b>
                    {/* <br /> */}
                    a.  You may review, change, or terminate your account at any time.
                    <br />
                    b.  In accordance to Reg 3.1(8) of the NDPR guarantee the right of a data subject to request the data controller,
                    without undue delay, to rectify any inaccurate personal data concerning him or her. The data subject may also request
                    the inclusion of additional personal data to provide complete information by providing a supplementary statement.
                    However, please note that this will not affect the lawfulness of the processing before its withdrawal nor when
                    applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful
                    processing grounds other than consent.
                    <br />
                    c.  Account Information. If you would at any time like to review or change the information in your account or terminate
                    your account, you can. Upon your request to terminate your account, we will deactivate or delete your account and information
                    from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems,
                    assist with any investigations, enforce our legal terms, and/or comply with applicable legal requirements.
                    <br />
                    <br />
                  </div>


                  <div className='pt-2' id='do_we_make_updates_to_this_notice'>
                    <b className='mb-3 d-block'>
                      6.  WHAT HAPPENS TO YOUR ACCOUNT INFORMATION UPON TERMINATION?
                    </b>
                    {/* <br /> */}
                    All user account data and associated activities will be immediately erased upon account deletion. However, this is subject to the emergent needs described in <a className='text-dark font-weight-bold' href="#how_long_do_we_keep_your_info">no.4 above</a>.
                    <br />
                    <br />
                  </div>

                  <div className='pt-2' id='do_we_make_updates_to_this_notice'>
                    <b className='mb-3 d-block'>
                      7.  DO WE MAKE UPDATES TO THIS NOTICE?
                    </b>
                    {/* <br /> */}
                    Yes, we will update this notice as necessary to stay compliant with relevant laws. We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                    <br />
                    <br />
                  </div>

                  <div className='pt-2' id='how_can_you_contact_us'>
                    <b className='mb-3 d-block'>
                      8.  HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </b>
                    {/* <br /> */}
                    If you have questions or comments about this notice, you may email us at support@flexdeals.com.ng
                  </div>
                </p>
                {/* <div>
                  <div className="text-center">
                    <small
                      style={{
                        // color: '#53138D',
                        cursor: 'pointer'
                      }}
                      // onClick={() => navigateTo()}
                      onClick={handleClickOpen}
                      className='p-2 text-danger font-weight-bold'
                    >
                      Delete Account
                    </small>
                  </div>
                </div> */}

              </div>

            </div>
          </div>
          <div className={`${styles.download} px-md-5`}>
            <div style={{ height: '50px' }} className="align-items-center  d-flex  justify-content-between">
              <div className={`${styles.content_control}`}>
                <button type='button' className='btn btn-[#53138D] bg-[#8c2d915d] pl-0' onClick={() => handleBack()}>
                  Back to Signup
                </button>
              </div>

              <div className={`${styles.content_control}`}>
                <button type='button' className='btn btn-[#53138D] bg-[#8c2d915d]' onClick={() => navigateTo()}>
                  Delete Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
