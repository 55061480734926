import React, { useState, useEffect, createContext, useContext } from 'react';
import axiosInstance from 'src/axiosInstance';
import bus from 'src/bus';

const TagContext = createContext([]);

const TagProvider = ({ children }) => {
  const [tagValue, setTagValue] = useState([]);

  const handleProductTags = () => {
    axiosInstance.get('/admin/product/tags').then((res) => {
      setTagValue(res.data.data);
    });
  };

  useEffect(() => {
    bus.on('productTag', () => {
      handleProductTags();
    });

    bus.on('deleteTag', () => {
      handleProductTags();
    });
    // handleProductTags()
  }, []);

  return <TagContext.Provider value={tagValue}>{children}</TagContext.Provider>;
};

const useTagApi = () => useContext(TagContext);

export { TagProvider, useTagApi };
