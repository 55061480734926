import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

const CountdownTimer = ({ onTimerFinish }) => {
  const initialCountdown = 2 * 60; // 5 minutes countdown (5 minutes * 60 seconds)
  const [countdown, setCountdown] = useState(initialCountdown);
  const clockIcon = <FontAwesomeIcon icon={faClock} />;

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  useEffect(() => {
    if (countdown === 0 && onTimerFinish) {
      onTimerFinish(); // Callback function to handle timer finish event
    }
  }, [countdown, onTimerFinish]);

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  return (
    <div>
      <p>{clockIcon} {`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</p>
    </div>
  );
};

export default CountdownTimer;
