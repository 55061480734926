import React from 'react';
import styles from '../../pages/Customers/BrandOwner.module.css';

function Pagination({
  currentPage,
  dataPagination,
  itemsPerPage,
  setCurrentPage,
  fetchData,
}) {
  const getVisiblePageNumbers = () => {
    const totalPages = Math.ceil(
      dataPagination.lastPage ? dataPagination.lastPage : 0 / itemsPerPage
    );
    const maxVisiblePages = 3; // Maximum number of visible pages
    let visiblePages = [];

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        visiblePages.push(i);
      }
    } else {
      // Show active page and adjust visible pages accordingly
      let startPage = Math.max(1, currentPage - 1);
      let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

      if (endPage - startPage < maxVisiblePages - 1) {
        startPage = Math.max(0, endPage - maxVisiblePages + 1);
      }

      // Always show the first page separately
      visiblePages.push(1);

      // Add ellipsis if not starting from the first page
      if (startPage > 2) {
        visiblePages.push('...');
        console.log('E don enter');
      }

      // Show visible pages
      for (let i = startPage; i <= endPage; i++) {
        visiblePages.push(i);
      }

      // Add ellipsis if not reaching the last page
      if (endPage < totalPages) {
        visiblePages.push('...');
      }

      // Always show the last page if not already included
      if (!visiblePages.includes(totalPages)) {
        visiblePages.push(totalPages);
      }
    }

    // Ensure only one occurrence of '1'
    if (visiblePages.filter((page) => page === 1).length > 1) {
      visiblePages = visiblePages.filter((page) => page !== 1);
      visiblePages.unshift(1); // Add '1' back at the beginning
    }

    // console.log('visiblePages:', visiblePages);

    return visiblePages;
  };

  return (
    <div className="card border-0 p-0 m-0">
      <div className="card-body pt-3 mt-0">
        <div className=" pagination__wrap d-sm-flex justify-content-between align-items-center">
          <div className="page__count d-flex align-item-center pt-2">
            Page {currentPage} of
            <b className="pl-1 mr-1">
              {Math.ceil(
                dataPagination.lastPage
                  ? dataPagination.lastPage
                  : 1 / itemsPerPage
              )}
            </b>
          </div>
          <div
            className={`${styles.pagination_button_container} pagination_button_container d-flex justify-content-center align-items-center`}
          >
            <button
              className="btn prev_next_btn mr-1"
              disabled={!dataPagination?.hasPrevious}
              onClick={() => {
                if (dataPagination?.hasPrevious) {
                  setCurrentPage(currentPage - 1);
                  fetchData(currentPage - 1, itemsPerPage);
                }
              }}
            >
              <span className="d-none d-md-block">Previous</span>
              <i className="bi bi-chevron-left d-block d-md-none"></i>
            </button>
            {getVisiblePageNumbers().map((pageNumber, index) => (
              <button
                key={index}
                className={`inserted_btn btn ml-sm-1 mr-1 ${
                  pageNumber === currentPage ? 'active' : ''
                } d-flex justify-content-center align-items-center`}
                onClick={() => {
                  if (typeof pageNumber === 'number') {
                    setCurrentPage(pageNumber);
                    fetchData(pageNumber, itemsPerPage);
                  } else if (pageNumber === '...') {
                    // Handle ellipsis click by setting the active button one step ahead
                    const nextPage = getVisiblePageNumbers()[index + 1];
                    if (nextPage && typeof nextPage === 'number') {
                      setCurrentPage(nextPage);
                      fetchData(nextPage, itemsPerPage);
                    }
                  }
                }}
                disabled={pageNumber === '...'}
                style={{
                  borderRadius: '100%',
                  backgroundColor:
                    pageNumber === currentPage ? '#af3585' : 'inherit',
                  color: pageNumber === currentPage ? '#fff' : 'inherit',
                  minWidth: '38px',
                  minHeight: '38px',
                  maxWidth: '38px',
                  maxHeight: '38px',
                }}
              >
                <span
                  className=""
                  style={{
                    position: 'absolute',
                    lineHeight: '1',
                    verticalAlign: 'middle',
                    fontSize: '13px',
                  }}
                >
                  {pageNumber}
                </span>
              </button>
            ))}
            <button
              className="btn prev_next_btn ml-1"
              disabled={!dataPagination?.hasNext}
              onClick={() => {
                if (dataPagination?.hasNext) {
                  setCurrentPage(currentPage + 1);
                  fetchData(currentPage + 1, itemsPerPage);
                }
              }}
            >
              <span className="d-none d-md-block">Next</span>
              <i className="bi bi-chevron-right d-block d-md-none"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
