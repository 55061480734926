// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
import styles from './Auth.module.css';
import Logo from '../logo/appLogo';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import toast from 'react-hot-toast';

import { useContext, useState } from 'react';
import { BaseUrlContext } from 'src/context/BaseUrlContext';
import axios from 'axios';
import { useAuth } from 'src/hooks/useAuth';
import { textAlign } from '@mui/system';

export default function LoginPage() {
  let navigate = useNavigate();
  const { dispatch } = useAuth();
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = mm + '/' + dd + '/' + yyyy;

  const [signUpError, setSignUpError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const baseUrl = useContext(BaseUrlContext);
  const [showText, setShowText] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const notify = () => toast.success('login successful .');
  const notifyError = () =>
    toast.error("That didn't work please try again later .");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const changeType = () => {
    setShowText(!showText);
  };

  function HandleFormSubmit(e) {
    e.preventDefault();
    let payload = {
      email,
      password,
    };
    const fetchData = async () => {
      try {
        setIsPending(true);
        setSignUpError(null);
        // Make the API request
        const res = await axios({
          method: 'POST',
          url: `${baseUrl}/admin/auth`,
          data: payload,
          headers: {
            Authorization: `x-auth-token ${localStorage.getItem('token')}`,
          },
        });

        notify();
        localStorage.setItem('auth', JSON.stringify(res.data.data));
        localStorage.setItem('dl', today);
        dispatch({ type: 'LOGIN', payload: res.data.data });
        setTimeout(() => {
          window.location.href = '/app/dashboard';
        }, 2000);
      } catch (err) {
        // notifyError(err.response.data.message)
        setSignUpError(err.response.data.message);
        if (err.response.data.message === 'Email is not verified yet') {
          localStorage.setItem('e', payload.email);
          setTimeout(() => {
            navigate('/verify_email');
          }, 2000);
        }
      } finally {
        setIsPending(false);
      }
    };
    fetchData(true);
  }

  return (
    <>
      <div className={`row ${styles.sav}`}>
        <div className="col-md-6 d-none d-md-block">
          <div
            className={styles.bg}
            style={{
              backgroundImage:
                "linear-gradient(209.36deg, rgba(175, 53, 133, 0.75) 0%, rgba(19, 1, 34, 0.75) 100%), url('/assets/happyMan.jpg')",
              backgroundPosition: 'top center',
            }}
          >
            <img className={styles.star} src="/assets/Stars.svg" alt="stars" />
            <div className={styles.content}>
              <p className={`${styles.bg_text}`}>
                Find and manage <br /> customers in one <br /> platform.
              </p>
              <p>
                Create a free account and get full access to all features for
                30-days. <br /> No credit card needed. Get started in 2 minutes.
              </p>
              <div className={styles.icon_n_text}>
                <img
                  className="icons"
                  src="/assets/Avatars.svg"
                  alt="avatars"
                />
                <span style={{ color: '#EAECF0' }}>Join 40,000+ users</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 ">
          <Logo />
          <div className={`${styles.sign_up} container`}>
            <form onSubmit={HandleFormSubmit} className={styles.form}>
              <h4
                className="mb-4"
                style={{
                  fontSize: '36px',
                  fontWeight: '600',
                  color: '#111827',
                }}
              >
                Sign in
              </h4>
              {signUpError && (
                <Alert
                  style={{
                    border: '1px solid red',
                  }}
                  severity="error"
                >
                  {signUpError}
                </Alert>
              )}

              <div className="my-4 inputs_n_label">
                <label htmlFor="email">Login ID*</label>
                <TextField
                  required
                  type="text"
                  id="email"
                  placeholder="Joe@shoprite.com"
                  size="small"
                  value={email}
                  onChange={handleEmailChange}
                  fullWidth
                />
              </div>
              <div className="my-4 inputs_n_label">
                <label htmlFor="password">Password*</label>
                <TextField
                  type={showText ? 'text' : 'password'}
                  required
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="****"
                  size="small"
                  fullWidth
                />
                <div className="icon mt-2" onClick={changeType}>
                  {!showText && <img src="/assets/icons/eye.svg" alt="" />}
                  {showText && <img src="/assets/icons/eye-off.svg" alt="" />}
                </div>
              </div>
              {/* <RouterLink to='/forgot_password'>Forgot password</RouterLink> */}

              <div className={styles.buttons}>
                {!isPending && (
                  <button
                    className={`btn btn-block ${styles.btn_custom_color}`}
                  >
                    <span className={`${styles.span}`}>Sign in</span>
                    {isPending && (
                      <CircularProgress
                        style={{ color: 'white' }}
                        size="1rem"
                      />
                    )}
                  </button>
                )}
                {isPending && (
                  <button
                    disabled
                    className={`btn btn-block ${styles.btn_custom_color}`}
                  >
                    Signing you in &nbsp;
                    {isPending && (
                      <CircularProgress
                        style={{ color: 'white' }}
                        size="1rem"
                      />
                    )}
                  </button>
                )}
              </div>
              <p
                style={{
                  color: '#6B7280',
                  textAlign: 'center',
                }}
              >
                Contact Customer Services{' '}
                <span className="text-[#53138D]">
                  <a href="mailto:support@flexdeals.com.ng">
                    support@flexdeals.com.ng
                  </a>
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
