import styles from './Sales.module.css';
import { useAuth } from 'src/hooks/useAuth';
import { Card } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
// sections
// mock
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axiosInstance from 'src/axiosInstance';
import { BallTriangle } from 'react-loader-spinner';

// ----------------------------------------------------------------------

let TABLE_HEAD = [
  { id: 'details', label: 'Product Details', alignRight: false },
  { id: 'quantity', label: 'Quantity', alignRight: false },
  { id: 'unitPrice', label: 'Unit price', alignRight: false },
  { id: 'totalPrice', label: 'Total price', alignRight: false },
];

// ----------------------------------------------------------------------

export default function OrderPage({ onChange }) {
  const navigate = useNavigate();
  const { data } = useAuth();
  const [newValue, setNewValue] = useState(
    JSON.parse(localStorage.getItem('sales_details')) || {}
  );
  const [salesOrder, setSalesOrder] = useState({
    user: {},
    total_order: 0,
    items: [],
  });
  const [isPending, setIsPending] = useState(false);

  if (data.user_type == 'admin') {
    TABLE_HEAD = [
      { id: 'details', label: 'Product Details', alignRight: false },
      { id: 'brand', label: 'Brand', alignRight: false },
      { id: 'quantity', label: 'Quantity', alignRight: false },
      { id: 'unitPrice', label: 'Unit price', alignRight: false },
      { id: 'totalPrice', label: 'Total price', alignRight: false },
    ];
  }

  function handleClick(event) {
    event.preventDefault();
    window.history.back();
    // navigate(-1)
    // navigate('/app/sales')
  }

  const salesDetails = () => {
    setIsPending(true);
    axiosInstance
      .get(
        '/admin/sales/get-sales/' + newValue.order_id + '/' + newValue.shop_id
      )
      .then((res) => {
        setSalesOrder(res.data.data[0]);
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  useEffect(() => {
    // console.log(newValue);
    salesDetails();
  }, [newValue]);

  return (
    <>
      <div className={` px-2`}>
        <button onClick={handleClick} className="btn d-flex">
          <div
            className="p-2 d-flex"
            style={{
              border: '1px solid lightgrey',
              borderRadius: '3px',
            }}
          >
            <img src="/assets/icons/Chevronleft.svg" alt="" />
          </div>
          &nbsp;
          <span class="mt-1" style={{ fontSize: '18px' }}>
            Sales
          </span>
        </button>
        <div className={`${styles.header}   mt-4`}>
          <img
            src={
              newValue?.store_logo
                ? newValue?.store_logo
                : '/assets/shoprite.svg'
            }
            className="float-md-left"
            alt=""
          />
          <div>
            <span
              className={`${styles.sales_title}`}
              style={{
                display: 'inline-block !important',
              }}
            >
              <div className="">
                <div>
                  <h3
                    className="font-weight-bold"
                    style={{
                      display: 'inline !important',
                    }}
                  >
                    {salesOrder?.user.full_name}
                  </h3>
                </div>
              </div>
            </span>
            <p className="mb-1 text-muted">
              <small>
                <img
                  className="d-inline-block mr-1"
                  src="/assets/icons/mail2.svg"
                  alt=""
                />
                {salesOrder?.user.email}
              </small>
              &nbsp;&nbsp;&nbsp;
              <small>
                <img
                  className="d-inline-block mr-1"
                  src="/assets/icons/phone.svg"
                  alt=""
                />
                {salesOrder?.user.phone_number}
              </small>
            </p>
            <p className="pl-2">
              <small className="text-muted">
                <img
                  className="d-inline-block mr-1 text-muted"
                  src="/assets/icons/map.svg"
                  alt=""
                />
                {salesOrder?.address}
              </small>
            </p>
          </div>
        </div>
        <Card
          style={{ marginTop: '50px', borderRadius: '10px' }}
          className="p-0"
        >
          <Scrollbar style={{ overflowX: 'hidden' }}>
            <div className="row">
              <div className="col-md-8 mb-2">
                {data.user_type != 'admin' && (
                  <div className="pl-3 pr-3 pt-3 pb-0 mb-0">
                    <h3>Sales</h3>
                    <small style={{ fontWeight: '200', fontSize: '12px' }}>
                      Order Number: {salesOrder?.order_number}
                    </small>
                    <small style={{ display: 'inline-block', float: 'right' }}>
                      {salesOrder?.items.length}
                      {salesOrder?.items.length <= 1 ? 'Item' : 'Items'}
                    </small>
                  </div>
                )}
                {data.user_type == 'admin' && (
                  <div className="pl-3 pr-3 pt-3 pb-0 mb-0">
                    <h3>
                      <b>Orders</b>
                    </h3>
                    <span
                      className="text-muted"
                      style={{
                        fontWeight: '200',
                        fontSize: '16px',
                      }}
                    >
                      Order Number: {salesOrder?.order_number}
                    </span>
                    <small style={{ display: 'inline-block', float: 'right' }}>
                      {salesOrder?.items.length}
                      {salesOrder?.items.length <= 1 ? 'Item' : 'Items'}
                    </small>
                  </div>
                )}
                {!isPending && salesOrder?.items.length > 0 && !isPending && (
                  <div
                    className={`table-responsive-sm mt-0 p-3`}
                    style={{
                      paddingTop: '0 !important',
                    }}
                  >
                    <table className={`${styles.table} mt-0`}>
                      <thead className={`${styles.thead}`}>
                        <tr>
                          {TABLE_HEAD.map((el, i) => {
                            return (
                              <th
                                key={i}
                                style={{
                                  fontSize: '16px !important',
                                }}
                              >
                                {el.label}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {salesOrder.items.map((el, i) => (
                          <tr key={i} className={`${styles.tr}`}>
                            <td>
                              <div className=" ">
                                <div className=" ">
                                  <img
                                    className="flsoat-left"
                                    src={el.product_info.image}
                                    alt=""
                                    style={{
                                      width: '20%',
                                    }}
                                  />
                                  &nbsp; &nbsp;
                                  <div
                                    style={{
                                      display: 'inline-block',
                                    }}
                                  >
                                    <p
                                      className="mb-0"
                                      style={{ fontSize: '14px' }}
                                    >
                                      <span>{el.product_info.name}</span>
                                    </p>
                                    <span style={{ fontSize: '10px' }}></span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            {data.user_type == 'admin' && (
                              <td>{el.product_info.brand_info.name}</td>
                            )}
                            <td>{el?.quantity}</td>
                            <td>
                              ₦{' '}
                              {el.price ? Number(el.price).toLocaleString() : 0}
                            </td>
                            <td>
                              ₦{' '}
                              {el.total_amount
                                ? Number(el.total_amount).toLocaleString()
                                : 0}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="d-flex justify-content-center">
                  {isPending && (
                    <BallTriangle
                      height={50}
                      width={55}
                      radius={5}
                      color="#af3585"
                      ariaLabel="ball-triangle-loading"
                      wrapperClass={{}}
                      wrapperStyle=""
                      visible={true}
                    />
                  )}
                </div>
                {!salesOrder.items.length && !isPending && (
                  <div className="alert alert-[#af3585] text-center">
                    <h4>No record found</h4>
                  </div>
                )}
              </div>
              <div
                className={`${styles.container_checkout} col-md-4 ${styles.height} `}
                style={{
                  background: '#E5E7EB',
                  minHeight: '30em',
                }}
              >
                <div
                  className=" pt-4  px-2 border-bottom"
                  style={{ background: 'inherit !important' }}
                >
                  <h4 className="font-weight-bold">Summary</h4>
                </div>
                <div className="border-bottom-white mt-4 px-2">
                  <div className="d-flex justify-content-between">
                    <p className={`${styles.summarytitles}`}>Total Order</p>
                    <p className={`${styles.summaryValues}`}>
                      ₦{' '}
                      {!isPending
                        ? Number(salesOrder.total_order).toLocaleString()
                        : 0}
                    </p>
                  </div>
                  {/* {
                                        data.user_type == 'admin' &&
                                        <div>
                                            <p className={`${styles.summarytitles} ${styles.inline_block}`}>
                                                Promo Code
                                            </p>
                                            <p className={`${styles.summaryValues} ${styles.inline_block} float-right`}
                                                style={{
                                                    backgroundColor: "white",
                                                    padding: '5px'
                                                }}>
                                                KYL231
                                            </p>
                                            <table width={'100%'}>
                                                <tbody>
                                                    <tr>
                                                        <td width={'50%'}>
                                                            <p className={`${styles.summarytitles}`}>
                                                                Promo Discount
                                                            </p>
                                                        </td>
                                                        <td width={'50%'}>
                                                            <p
                                                                className={`${styles.summaryValues} ${styles.inline_block} float-right`}
                                                                style={{
                                                                    backgroundColor: "white",
                                                                    padding: '5px',
                                                                    width: '65px',
                                                                    textAlign: 'center'
                                                                }}>
                                                                20%
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    } */}
                </div>
                {data.user_type == 'admin' &&
                  salesOrder.items.status == 'completed' && (
                    <div className={`${styles.margin_top}`}>
                      <button className="btn btn-[#af3585] text-white form-control">
                        Refund
                      </button>
                    </div>
                  )}
                {data.user_type === 'admin' && (
                  <div>
                    <div className={`${styles.sub_total} px-2`}>
                      <div
                        className=" border-top"
                        style={{
                          marginTop: '100px',
                        }}
                      >
                        {/* <div className="d-flex justify-content-between">
                                                    <p style={{
                                                        fontWeight: '500',
                                                        fontSize: '14px',
                                                        lineHeight: ' 150%',
                                                        color: '#1F2937',
                                                        marginBottom: '0px',
                                                    }}>
                                                        Shipping fee:
                                                    </p>
                                                    <p style={{
                                                        fontWeight: '400',
                                                        fontSize: '15px',
                                                        lineHeight: ' 150%',
                                                        color: '##374151',
                                                        marginBottom: '0px',
                                                    }}>
                                                        ₦ 7,000
                                                    </p>

                                                </div> */}
                        <p
                          className="my-2"
                          style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '150%',
                            color: '#4B5563',
                          }}
                        >
                          {salesOrder.address}
                        </p>
                      </div>
                      <div
                        className="pt-4 "
                        style={{ borderTop: '2px solid #FFFFFF' }}
                      >
                        <div className="d-flex justify-content-between">
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                              lineHeight: ' 150%',
                              color: '#1F2937',
                            }}
                          >
                            Total
                          </p>
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: '20px',
                              lineHeight: ' 150%',
                              color: '##374151',
                            }}
                          >
                            ₦{' '}
                            {!isPending
                              ? Number(salesOrder.total_order).toLocaleString()
                              : 0}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {(data.merchant_type === 'brand' ||
                  data.merchant_type === 'shop') &&
                  !isPending && (
                    <div>
                      <div
                        className={`${styles.sub_total} px-2 mb-2`}
                        style={{
                          position: 'absolute',
                          bottom: '70px',
                          width: '100%',
                        }}
                      >
                        <div
                          className=" border-top"
                          style={{
                            marginTop: '100px',
                          }}
                        >
                          {/* <div className="d-flex justify-content-between">
                                                    <p style={{
                                                        fontWeight: '500',
                                                        fontSize: '14px',
                                                        lineHeight: ' 150%',
                                                        color: '#1F2937',
                                                        marginBottom: '0px',
                                                        fontWeight: '700'
                                                    }}>
                                                        Shipping fee:
                                                    </p>
                                                    <p style={{
                                                        fontWeight: '400',
                                                        fontSize: '15px',
                                                        lineHeight: ' 150%',
                                                        color: '##374151',
                                                        marginBottom: '0px',
                                                        marginRight: '35px'
                                                    }}>
                                                        ₦ 7,000
                                                    </p>

                                                </div> */}
                          <p
                            className="my-2"
                            style={{
                              fontFamily: 'Roboto',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              fontSize: '12px',
                              lineHeight: '150%',
                              color: '#4B5563',
                            }}
                          >
                            {salesOrder.address}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                {/* if not admin */}
                {data.user_type !== 'admin' && (
                  <div
                    className="pt-4 mt-4 "
                    style={{
                      borderTop: '2px solid #FFFFFF',
                      position: 'absolute',
                      bottom: '0',
                      width: '100%',
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          lineHeight: ' 150%',
                          color: '#1F2937',
                        }}
                      >
                        <strong>Total:</strong>
                      </p>
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: '13px',
                          lineHeight: ' 150%',
                          color: '#374151',
                          marginRight: '70px',
                          marginBottom: '30px',
                        }}
                      >
                        ₦{' '}
                        {!isPending
                          ? Number(salesOrder.total_order).toLocaleString()
                          : 0}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Scrollbar>
        </Card>
      </div>
    </>
  );
}
