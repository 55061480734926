import styles from "./Fileinput.module.css";

const ImagePreview = ({data}) => {
  return (
    <div className={styles.file_input_container}>
            <img style={{marginBottom: '40px'}} src={data} alt="" />
    
    </div>
  );
};

export default ImagePreview;
