import React from 'react';

function ActionName({ actionValue }) {
  // Split the name into sentences
  const sentences = actionValue.split('. ');
  console.log(sentences, sentences.length)

  // Extract the first word of each sentence
  const firstWordOfFirstSentence = sentences[0].split(' ')[0];
  const firstWordOfSecondSentence = sentences[0].split(' ')[1];
  console.log(firstWordOfFirstSentence,)

  // If the first words are the same, return only the first word
  if (firstWordOfFirstSentence === firstWordOfSecondSentence) {
    return <b>{firstWordOfFirstSentence}</b>;
  } else {
    // Otherwise, return the entire name
    return <b>{actionValue}</b>;
  }
}

export default ActionName;
