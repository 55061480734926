import styles from '../Sales.module.css';

import { Card } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import bus from 'src/bus';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LiveSelect from 'react-select';
import SalesDetails from '../SalesDetails';
import { useEffect, useState } from 'react';
import { useLayoutEffect } from 'react';
import GlobalModal from 'src/Modals/GlobalModal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import ViewModal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import moment from 'moment';
import { BallTriangle } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';
import { downloadFile2 } from 'src/utils';
import { Pagination } from 'src/components';
import WithdrawalLimitsCard from './components/WithdrawalLimitsCard';
// ----------------------------------------------------------------------
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import logo from 'src/components/logo';

const TABLE_HEAD = [
  { id: 'sn', label: 'S/N', alignRight: false },
  { id: 'customerName', label: 'Customer Name', alignRight: false },
  { id: 'withdrawAmount', label: 'Withdraw Amount', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 2,
};

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 2,
};

// ----------------------------------------------------------------------

// ptvs=PAGE TO VIEW (SALES)

let app_view = localStorage.getItem('ptvs') ?? 0;

export default function WithdrawRequest() {
  const [appView] = useState(app_view);
  const navigate = useNavigate();
  const [selectedOption] = useState(null);
  const [searchvalue, setSearchvalue] = useState('');
  const [setLimit, setSetLimit] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [deletePending, setDeletePending] = useState(false);
  const [isGetLimitPending, setIsGetLimitPending] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [updateId, setUpdateId] = useState('');
  const [updateValue, setUpdateValue] = useState({});
  const [requestsData, setRequestsData] = useState([]);
  const [getLimitByCategory, setGetLimitByCategory] = useState([]);
  const [dataPagination, setDataPagination] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  // const [cardsData, setCardsData] = useState([
  //   { title: 'Kiosk', amount: 1000000, boldCount: 200000, smallCount: 300000 },
  //   { title: 'Supermarket', amount: 2000000, boldCount: 400000, smallCount: 500000 },
  // ]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      !getLimitByCategory.length || getLimitByCategory.length < 2 ? 1 : 2, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    centerMode: true, // Enables center mode
    centerPadding: '20px', // Adjust padding to see a portion of the next slide
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '20px',
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '20px',
          initialSlide: 1,
        },
      },
    ],
  };

  const [reason, setReason] = useState({
    reason: '',
  });
  const [filterSearch, setFilterSearch] = useState({
    store_category: 'Kiosk',
    income_schedule: '',
    withdraw_limit: '',
    shop_id: '',
    amount: '',
    status: '',
    income_min_level: '',
    income_max_level: '',
    dateStart: '',
    dateEnd: '',
  });
  const notify = (msg = null) =>
    toast.success(msg === null ? 'Withdrawal Approved.' : msg);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [currentId, setCurrentId] = useState({});
  const [withdrawalStatusReason, setWithdrawalStatusReason] = useState(false);
  const notifyError = (err) => toast.error(err);
  const [modalOpen, setModalOpen] = useState(false);
  const [setUpModalOpen, setSetUpModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewLimitModalOpen, setViewLimitModalOpen] = useState(false);
  const [statusPending, setStatusPending] = useState({});
  const [declinePending, setDeclinePending] = useState({});
  const [storeOwnersData, setStoreOwnersData] = useState([]);
  const [banks, setBanks] = useState([]);
  const [bankName, setBankName] = useState('');
  const [loadingBanks, setLoadingBanks] = useState(false);
  const [viewModalValue, setViewModalValue] = useState({
    amount: {},
  });

  const handleClose = () => {
    setEditStatus(false);
    setModalOpen(false);
    setFilterSearch({
      status: '',
      shop_id: '',
      withdraw_limit: '',
      amount: '',
      income_schedule: '',
      dateEnd: '',
      dateStart: '',
      income_max_level: '',
      withdrawal_max_amount: '',
      income_min_level: '',
      withdrawal_min_amount: '',
    });
  };

  const handleSetUpClose = () => {
    setSetUpModalOpen(false);
    setFilterSearch({
      store_category: '',
      withdrawal_amount_frequency: '',
      amount: '',
      income_level_frequency: '',
      dateEnd: '',
      dateStart: '',
      income_max_level: '',
      income_min_level: '',
    });
  };

  const handleCloseStatusModal = () => {
    setWithdrawalStatusReason(false);
    setViewModalOpen(false);
    setReason('');
  };

  const handleStatusReason = (key, value) => {
    setReason((reason) => ({
      ...reason,
      [key]: value,
    }));
  };

  const handleCloseViewModal = () => {
    setViewModalOpen(false);
    setViewLimitModalOpen(false);
  };

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvs');
    },
    []
  );

  const handleViewClick = (d) => {
    navigate('/app/sales_details');
    // handle view click action
  };

  const handleChange = (/* args passed from the child component */) => {
    handleViewClick(0);
  };

  const setViewModal = (val) => {
    setViewModalOpen(true);
    setViewModalValue(val);
  };

  const searchFilter = (e) => {
    setSearchvalue(e.target.value);
  };

  const handleFilterChange = (key, value) => {
    setFilterSearch((filterSearch) => ({
      ...filterSearch,
      [key]: value,
    }));
    if (key === 'store_category') {
      setIsGetLimitPending(true);
    }
  };

  const handleDownloadFile = async (extension) => {
    setExportLoading(true);
    try {
      const result = `/admin/transactions/stores?page=${dataPagination?.currentPage}&perpage=${dataPagination?.total}&export_type=${extension}`;
      await downloadFile2('Stores Transaction', result, extension);
    } catch (err) {
      notifyError(err.message);
    } finally {
      setExportLoading(false);
    }
  };

  const fetchShopOwners = () => {
    axiosInstance
      .post('/admin/customers/get-storeowners')
      .then((res) => {
        setStoreOwnersData(res.data.data.data);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to approve transaction, try again later');
        }
      })
      .finally(() => {});
  };

  const handleTransactionApprove = (id) => {
    setStatusPending((prev) => ({ ...prev, [id]: true }));
    axiosInstance
      .get('/admin/shop-withdrawal-requests/approve/' + id, {
        status: 'approved',
      })
      .then((res) => {
        notify(res.data.message);
        fetchData();
        bus.emit('refetch');
        handleCloseStatusModal();
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
          setStatusPending((prev) => ({ ...prev, [id]: false }));
        } catch (error) {
          notifyError('Unable to approve transaction, try again later');
        }
      })
      .finally(() => setStatusPending((prev) => ({ ...prev, [id]: false })));
  };

  const handleTransactionDecline = (id) => {
    if (reason === '') return notifyError('The reason field is required!');
    setDeclinePending((prev) => ({ ...prev, [id]: true }));
    axiosInstance
      .post('/admin/shop-withdrawal-requests/decline/' + id, {
        status: 'declined',
        reason: reason.reason,
      })
      .then((res) => {
        notify(res.data.message);
        fetchData();
        bus.emit('refetch');
        handleCloseStatusModal();
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
          setDeclinePending((prev) => ({ ...prev, [id]: false }));
        } catch (error) {
          notifyError('Unable to approve transaction, try again later');
        }
      })
      .finally(() => setDeclinePending((prev) => ({ ...prev, [id]: false })));
  };

  const fetchData = (page = 1, pageSize = 10, file = null) => {
    let filter_payload = {};
    if (filterSearch.income_min_level) {
      filter_payload.income_min_level = filterSearch.income_min_level;
    }
    if (filterSearch.income_max_level) {
      filter_payload.income_max_level = filterSearch.income_max_level;
    }
    if (filterSearch.withdrawal_min_amount) {
      filter_payload.min_amount = filterSearch.withdrawal_min_amount;
    }
    if (filterSearch.withdrawal_max_amount) {
      filter_payload.max_amount = filterSearch.withdrawal_max_amount;
    }
    if (filterSearch.store_name) {
      setSearchvalue('');
      filter_payload.search = filterSearch.store_name;
    }
    if (filterSearch.withdraw_limit) {
      filter_payload.withdraw_limit = filterSearch.withdraw_limit;
    }
    if (searchvalue !== '') {
      filter_payload.search = searchvalue;
    }
    if (startDate !== '') {
      filter_payload.dateFrom = startDate;
    }
    if (endDate !== '') {
      filter_payload.dateTo = endDate;
    }
    if (filterSearch.income_schedule !== '') {
      filter_payload.income_schedule = filterSearch.income_schedule;
    }
    if (filterSearch.status !== '' && filterSearch.status !== 'all') {
      filter_payload.status = filterSearch.status;
    }

    const params = {
      page,
      pageSize,
      file,
      ...filter_payload,
    };

    setIsPending(true);
    axiosInstance
      .get('/admin/shop-withdrawal-requests', {
        params: params,
      })
      .then((res) => {
        const sortedData = res.data.data.data.sort((a, b) => {
          if (a.status === 'pending' && b.status !== 'pending') {
            return -1;
          }
          if (a.status !== 'pending' && b.status === 'pending') {
            return 1;
          }
          return 0;
        });

        setRequestsData(sortedData);
        setDataPagination(res.data.data.pagination);
        setModalOpen(false);
        setSetLimit(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          // Handle the nested error if needed
        }
      })
      .finally(() => setIsPending(false));
  };

  useEffect(() => {
    const fetchBanks = async () => {
      const banksApi = await fetch('https://api.paystack.co/bank');
      const banks = await banksApi.json();
      setLoadingBanks(true);
      if (banks.status) {
        setLoadingBanks(false);
        setBanks(banks.data);
      }
    };
    fetchBanks();
  }, []);

  const getBankName = (code) => {
    const bank = banks.find((bank) => bank.code === code);
    return bank ? bank.name : 'Unknown Bank';
  };

  useEffect(() => {
    if (viewModalValue?.data) {
      console.log(viewModalValue?.data);
      const name = getBankName(viewModalValue.data?.code);
      setBankName(name);
    }
  }, [viewModalValue]);

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSetLimit = (update_status) => {
    let filter_payload = {
      income_level: {
        from: '',
        to: '',
      },
    };
    if (filterSearch.store_category) {
      filter_payload.store_category = filterSearch.store_category;
    }
    if (filterSearch.income_min_level) {
      filter_payload.income_level.from = filterSearch.income_min_level;
    }
    if (filterSearch.income_max_level) {
      filter_payload.income_level.to = filterSearch.income_max_level;
    }
    if (filterSearch.amount) {
      filter_payload.amount = filterSearch.amount;
    }
    if (filterSearch.withdrawal_amount_frequency) {
      filter_payload.withdrawal_amount_frequency =
        filterSearch.withdrawal_amount_frequency;
    }
    if (filterSearch.income_level_frequency) {
      filter_payload.income_level_frequency =
        filterSearch.income_level_frequency;
    }

    const params = {
      ...filter_payload,
    };
    setIsPending(true);
    const endpoint = !update_status
      ? '/admin/shop-withdrawal-limits/create'
      : `/admin/shop-withdrawal-limits/${updateId}`;
    axiosInstance[!update_status ? 'post' : 'put'](endpoint, params)
      .then((res) => {
        if (res.data.success) {
          setEditStatus(false);
          setIsGetLimitPending(true);
        }
        notify(res.data.message);
        handleGetLimitByCategory();
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
        setIsPending(false);
      })
      .finally(() => setIsPending(false));
  };

  const handleGetLimitByCategory = () => {
    axiosInstance
      .get(`/admin/shop-withdrawal-limits/${filterSearch.store_category}`)
      .then((res) => {
        setGetLimitByCategory(res.data.data);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
        setIsGetLimitPending(false);
      })
      .finally(() => setIsGetLimitPending(false));
  };

  const handleEdit = (val) => {
    setEditStatus(true);
    setUpdateId(val?._id);
    setFilterSearch({
      store_category: val?.store_category,
      withdrawal_amount_frequency: val?.withdrawal_amount_frequency,
      amount: val?.amount?.$numberDecimal,
      income_level_frequency: val?.income_level_frequency,
      income_min_level: val?.income_level?.from?.$numberDecimal,
      income_max_level: val?.income_level?.to?.$numberDecimal,
    });
    setFilterSearch((filterSearch) => ({
      ...filterSearch,
      store_category: val?.store_category,
      income_level_frequency: val?.income_level_frequency,
      withdrawal_amount_frequency: val?.withdrawal_amount_frequency,
    }));
  };

  const handleDelete = (val) => {
    setViewLimitModalOpen(true);
    setUpdateValue(val);
  };

  const handleDeleteLimit = (id) => {
    setDeletePending(true);
    axiosInstance
      .delete(`/admin/shop-withdrawal-limits/${id}`)
      .then((res) => {
        notify(res.data.message);
        setDeletePending(false);
        setViewLimitModalOpen(false);
        handleGetLimitByCategory();
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setDeletePending(false));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchvalue]);

  useEffect(() => {
    if (filterSearch.store_category) {
      handleGetLimitByCategory();
      setFilterSearch((filterSearch) => ({
        ...filterSearch,
      }));
    }
  }, [filterSearch.store_category]);

  useEffect(() => {
    fetchShopOwners();
  }, []);

  const hasPermission = (permission) => {
    const authData = JSON.parse(localStorage.getItem('auth')) || [];

    const hasPermission = authData?.direct_permissions?.some((el) => {
      // console.log('el.permission_alias:', el.permission_alias);
      // console.log('permission:', permission);
      return el.permission_alias === permission;
    });

    return hasPermission;
  };

  useEffect(() => {
    hasPermission('');
  }, []);

  return Number(appView) === 0 ? (
    <>
      <div className="pt-4">
        <div className={`${styles.analytics}  `}></div>
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-md-3 col-6   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={searchvalue}
                onChange={searchFilter}
                placeholder="Search"
                style={{
                  textIndent: '25px',
                  width: '100%',
                }}
                className=" form-control"
              />
            </div>
            <div className="col-md-9 col-6  text-right">
              <button
                className="btn  border"
                onClick={() => setModalOpen(true)}
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px',
                }}
              >
                <img
                  className=""
                  style={{
                    display: 'inline',
                    width: '28%',
                  }}
                  src="/assets/icons/filterlines.svg"
                  alt=""
                />
                &nbsp;
                <small className="d-none d-md-inline-block">Filters</small>
              </button>
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: '50px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-3 ">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">Withdrawal Request</h5>
                <small style={{ fontWeight: '200', fontSize: '14px' }}>
                  View withdrawal request for merchant
                </small>
              </div>
              <div className="pt-md-2">
                <div className="">
                  {hasPermission('manage-shop-withdrawal-limit') && (
                    <button
                      id="dropdownMenuButton"
                      className={`${styles.export_btns} btn btn_primary m-0`}
                      onClick={() => setSetUpModalOpen(true)}
                    >
                      <span
                        style={{
                          paddingTop: '8px',
                        }}
                      >
                        <i className="bi bi-gear bi_gear"></i> &nbsp; Set Up
                        Limit
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`pt-3 pb-3 pl-0 pr-0`}>
              <table
                className={`${styles.table} table-hover table-striped`}
                style={{
                  minWidth: '70em',
                }}
              >
                <thead className={`${styles.thead}`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      let sIndex = i === 1 ? 1 : false;
                      return (
                        <th key={i} className="text-muted">
                          {el.label}{' '}
                          {sIndex && (
                            <span style={{ cursor: 'pointer' }}>
                              <img
                                className="pl-2"
                                src="/assets/icons/down.svg"
                                alt=""
                              />
                            </span>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {!isPending &&
                    requestsData.map((el, i) => {
                      return (
                        <tr key={i} className={`${styles.tr}`}>
                          <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                          <td>
                            <div className=" ">
                              <div className=" ">
                                <p
                                  className="mb-0"
                                  style={{ fontSize: '14px' }}
                                >
                                  <strong>{el?.shop?.store_name}</strong>
                                </p>
                                <span style={{ fontSize: '13px' }}>
                                  {el?.shop?.shop_iD}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            ₦
                            {Number(el?.amount.$numberDecimal).toLocaleString()}
                          </td>
                          <td>
                            <span>
                              {moment(el?.createdAt).format('DD/MM/YYYY')}
                            </span>
                            <br />
                            <small>
                              {moment(el?.createdAt)
                                .subtract(1, 'hours')
                                .format('hh:mm A')}
                            </small>
                          </td>
                          <td>
                            {el.status === 'approved' ? (
                              <span className="verified_badge">
                                {capitalizeFirstLetter(el.status)}
                              </span>
                            ) : el.status === 'pending' ? (
                              <span className="pending_badge">
                                {capitalizeFirstLetter(el.status)}
                              </span>
                            ) : (
                              <span className="not_verified_badge">
                                {capitalizeFirstLetter(el.status)}
                              </span>
                            )}
                          </td>
                          <td>
                            <div className="d-inline-block dropleft ">
                              <button
                                onClick={() => {
                                  handleTransactionApprove(el?._id);
                                  setSelectedStatus('approve');
                                  setCurrentId(el);
                                }}
                                type="button"
                                className={`btn btn_primary`}
                                disabled={el?.status !== 'pending'}
                              >
                                Approve
                                {statusPending[el?._id] && (
                                  <CircularProgress
                                    style={{
                                      color: '#fff',
                                    }}
                                    size="0.80rem"
                                    className="ml-1"
                                  />
                                )}
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  setWithdrawalStatusReason(true);
                                  setSelectedStatus('decline');
                                  setCurrentId(el);
                                }}
                                className="btn btn-danger ml-3 border-0"
                                disabled={el?.status !== 'pending'}
                              >
                                Decline
                              </button>
                              {hasPermission('view-shop-withdrawal-limit') && (
                                <>
                                  <span
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    className={`${styles.action_button} px-3 py-2`}
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <img
                                      src="/assets/icons/action.svg"
                                      alt=""
                                    />
                                  </span>

                                  <div
                                    className="dropdown-menu drop-left"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <button
                                      type="button"
                                      onClick={() => setViewModal(el)}
                                      className="rss dropdown-item btn border-0"
                                    >
                                      View
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!requestsData.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>

          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={dataPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>

      {/* Filter */}
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#FFFFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <label htmlFor="store_name">Store Name</label>
                <div className="" style={{ width: '100%' }}>
                  <div className="mb-3" style={{ width: '100%' }}>
                    <TextField
                      required
                      type="text"
                      id="store_name"
                      className="w-100"
                      placeholder=""
                      size="small"
                      name="store_name"
                      value={filterSearch.store_name}
                      onChange={(e) =>
                        handleFilterChange('store_name', e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="name">Status</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={filterSearch.status}
                    name="status"
                    required
                    onChange={(e) =>
                      handleFilterChange('status', e.target.value)
                    }
                  >
                    <MenuItem value="approved">Approved</MenuItem>
                    <MenuItem value="declined">Declined</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                  </Select>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Date </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
                <label htmlFor="ounit">Withdrawal Amount </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        name="withdrawal_min_amount"
                        value={filterSearch.withdrawal_min_amount}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange(
                              'withdrawal_min_amount',
                              inputValue
                            );
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        placeholder="Max"
                        size="small"
                        name="withdrawal_max_amount"
                        value={filterSearch.withdrawal_max_amount}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange(
                              'withdrawal_max_amount',
                              inputValue
                            );
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={() => fetchData()}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
                {isPending && (
                  <CircularProgress
                    style={{ color: '#fff' }}
                    size="0.8rem"
                    className="ml-1"
                  />
                )}
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>

      {/* Set Up Limit */}
      <GlobalModal
        open={setUpModalOpen}
        onClose={handleSetUpClose}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="600px !important"
        overflow="auto"
        bgcolor="#FFFFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <div style={{ lineHeight: '0px' }}>
              <h5
                style={{
                  fontSize: '19px',
                  fontWeight: '700',
                }}
              >
                Withdrawal Limit
              </h5>
              <p>Create withdrawal limit for merchants</p>
            </div>
            <span onClick={handleSetUpClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form action="" className="mt-3">
            <div className="row">
              <div className="col-md-12 mb-1">
                <div className="mb-3">
                  <div className="d-flex">
                    <div className="" style={{ width: '80%' }}>
                      <div className="">
                        {/* Select Store */}
                        Select Store Category
                      </div>
                    </div>
                    <div className="" style={{ width: '80%' }}>
                      <div className="">
                        {/* <LiveSelect
                                    defaultValue={selectedOption}
                                    onChange={(e) => handleFilterChange('shop_id', e.value)}
                                    options={storeOwnersData
                                      .filter(el => el.store_name !== null)
                                      .map((el, i) => ({
                                        value: el?._id,
                                        label: el?.store_name,
                                    }))}
                                  /> */}
                        <Select
                          labelId="s"
                          id="s"
                          fullWidth
                          size="small"
                          value={filterSearch.store_category}
                          name="store_category"
                          required
                          onChange={(e) =>
                            handleFilterChange('store_category', e.target.value)
                          }
                        >
                          <MenuItem value="E-Commerce">E-Commerce</MenuItem>
                          <MenuItem value="Kiosk">Kiosk</MenuItem>
                          <MenuItem value="Neighbourhood">
                            Neighbourhood Store
                          </MenuItem>
                          <MenuItem value="Supermarket">Supermarket</MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="ounit">Set Income Level </label>
                  <div className=" d-flex">
                    <div className="" style={{ width: '70%' }}>
                      <div className="mb-3">
                        <TextField
                          required
                          type="number"
                          id="ounit"
                          size="small"
                          name="income_min_level"
                          value={filterSearch.income_min_level}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              inputValue === '' ||
                              parseFloat(inputValue) >= 0
                            ) {
                              handleFilterChange(
                                'income_min_level',
                                inputValue
                              );
                            }
                          }}
                          inputProps={{
                            inputMode: 'decimal',
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === '-' ||
                              e.key === 'e' ||
                              e.key === '.'
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="px-1 d-flex align-items-center "
                      style={{ height: '40px' }}
                    >
                      <img
                        src="/assets/icons/line.svg"
                        style={{ width: '30px' }}
                        alt=""
                      />
                    </div>
                    <div className="" style={{ width: '80%' }}>
                      <div className="mb-3">
                        <TextField
                          required
                          type="number"
                          size="small"
                          name="income_max_level"
                          value={filterSearch.income_max_level}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              inputValue === '' ||
                              parseFloat(inputValue) >= 0
                            ) {
                              handleFilterChange(
                                'income_max_level',
                                inputValue
                              );
                            }
                          }}
                          inputProps={{
                            inputMode: 'decimal',
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === '-' ||
                              e.key === 'e' ||
                              e.key === '.'
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="" style={{ width: '50%' }}>
                      <div className="mb-3">
                        <Select
                          labelId="s"
                          id="s"
                          fullWidth
                          size="small"
                          value={filterSearch?.income_level_frequency}
                          name="income_level_frequency"
                          required
                          onChange={(e) =>
                            handleFilterChange(
                              'income_level_frequency',
                              e.target.value
                            )
                          }
                        >
                          <MenuItem value="daily">Daily</MenuItem>
                          <MenuItem value="weekly">Weekly</MenuItem>
                          <MenuItem value="monthly">Monthly</MenuItem>
                          {/* <MenuItem value='Yearly'>Yearly</MenuItem> */}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="d-flex">
                    <div className="" style={{ width: '80%' }}>
                      <div className="mb-3">Withdrawal Limit</div>
                    </div>
                    <div className="" style={{ width: '80%' }}>
                      <div className="mb-3">
                        <TextField
                          required
                          type="number"
                          size="small"
                          name="amount"
                          value={filterSearch.amount}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              inputValue === '' ||
                              parseFloat(inputValue) >= 0
                            ) {
                              handleFilterChange('amount', inputValue);
                            }
                          }}
                          inputProps={{
                            inputMode: 'decimal',
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === '-' ||
                              e.key === 'e' ||
                              e.key === '.'
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="" style={{ width: '50%' }}>
                      <div className="mb-3">
                        <Select
                          labelId="s"
                          id="s"
                          fullWidth
                          size="small"
                          value={filterSearch?.withdrawal_amount_frequency}
                          name="withdrawal_amount_frequency"
                          required
                          onChange={(e) =>
                            handleFilterChange(
                              'withdrawal_amount_frequency',
                              e.target.value
                            )
                          }
                        >
                          <MenuItem value="daily">Daily</MenuItem>
                          <MenuItem value="weekly">Weekly</MenuItem>
                          <MenuItem value="monthly">Monthly</MenuItem>
                          {/* <MenuItem value='Yearly'>Yearly</MenuItem> */}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={() => {
                  handleSetLimit(editStatus);
                }}
                className={`${styles.modal_btn} btn btn-block`}
              >
                {!editStatus ? 'Save' : 'Update'}
                {isPending && (
                  <CircularProgress
                    style={{ color: '#fff' }}
                    size="0.8rem"
                    className="ml-1"
                  />
                )}
              </button>
            </div>
            <div className="mb-5">
              <b>Saved limit</b>
              {!getLimitByCategory.length && !isGetLimitPending && (
                <div className="alert alert-[#af3585] text-center">
                  <h5>No record found</h5>
                </div>
              )}
              {isGetLimitPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                  style={{ alignItem: 'center', justifyContent: 'center' }}
                />
              )}
              <Slider {...settings}>
                {getLimitByCategory &&
                  !isGetLimitPending &&
                  getLimitByCategory.map((el, i) => {
                    return (
                      <div>
                        <WithdrawalLimitsCard
                          title={el?.store_category}
                          amount={el?.amount?.$numberDecimal}
                          frequency={el?.withdrawal_amount_frequency}
                          smallCount={el?.income_level?.to?.$numberDecimal}
                          boldCount={el?.income_level?.from?.$numberDecimal}
                          lg={
                            getLimitByCategory?.length < 2
                              ? 'col-lg-6'
                              : 'col-lg-10'
                          }
                          onEdit={() => handleEdit(el)}
                          onDelete={() => handleDelete(el)}
                        />
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </form>
        </div>
      </GlobalModal>

      {/* Withdrawal Details */}
      <ViewModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewModalOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={viewModalOpen}>
          <Box sx={style}>
            <div className={`modal_content`}>
              <div className={`modal_header p-3`}>
                <h4 style={{ fontWeight: 'bold' }}>Withdrawal Details</h4>
                <span
                  onClick={handleCloseViewModal}
                  style={{ cursor: 'pointer' }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <div className="mt-5">
                <div className="row text-muted border_buttom  mt-4 mb-4">
                  <div className="col-md-6">Merchant</div>
                  <div className="col-md-6">
                    {viewModalValue?.shop?.store_name}
                  </div>
                  <hr />
                </div>
                <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                  <div className="col-md-6">Email Address</div>
                  <div className="col-md-6 ">
                    <i className="bi bi-envelope bi_envelope"></i> &nbsp;
                    {viewModalValue?.shop?.email}
                  </div>
                  <hr />
                </div>
                <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                  <div className="col-md-6">Bank</div>
                  <div className="col-md-6 ">{bankName}</div>
                  <hr />
                </div>
                <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                  <div className="col-md-6">Account Number</div>
                  <div className="col-md-6 ">
                    <span>{viewModalValue?.data?.account_number}</span>
                  </div>
                  <hr />
                </div>
                <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                  <div className="col-md-6">Account Name</div>
                  <div className="col-md-6 ">
                    <span>{viewModalValue?.data?.account_name}</span>
                  </div>
                  <hr />
                </div>
                <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                  <div className="col-md-6">Amount</div>
                  <div className="col-md-6 ">
                    <small>
                      ₦{' '}
                      {viewModalValue?.amount
                        ? Number(
                            viewModalValue.amount.$numberDecimal
                          ).toLocaleString()
                        : 0}
                    </small>
                  </div>
                  <hr />
                </div>
                <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                  <div className="col-md-6">Status</div>
                  <div className="col-md-6 ">
                    <span>
                      {viewModalValue?.status === 'approved' ? (
                        <span className="verified_badge">
                          {capitalizeFirstLetter(viewModalValue?.status)}
                        </span>
                      ) : viewModalValue?.status === 'pending' ? (
                        <span className="pending_badge">
                          {capitalizeFirstLetter(viewModalValue?.status)}
                        </span>
                      ) : (
                        <span className="not_verified_badge">
                          {capitalizeFirstLetter(viewModalValue?.status)}
                        </span>
                      )}
                    </span>
                  </div>
                  <hr />
                </div>
              </div>
              <div className="row mt-3 text-muted mt-4 mb-4">
                <div className="col-md-6">
                  <button
                    onClick={() => {
                      setWithdrawalStatusReason(true);
                      setSelectedStatus('decline');
                      setCurrentId(viewModalValue);
                    }}
                    className="btn btn-outline-secondary form-control"
                    disabled={viewModalValue.status !== 'pending'}
                  >
                    Decline
                    {declinePending[viewModalValue?._id] && (
                      <CircularProgress
                        style={{
                          color: '#fff',
                        }}
                        size="1rem"
                      />
                    )}
                  </button>
                </div>
                <div className="col-md-6 ">
                  <button
                    onClick={() => {
                      handleTransactionApprove(viewModalValue?._id);
                      setSelectedStatus('approve');
                      setCurrentId(viewModalValue);
                    }}
                    className="btn btn_primary form-control"
                    disabled={viewModalValue.status !== 'pending'}
                  >
                    Approve
                    {statusPending[viewModalValue?._id] && (
                      <CircularProgress
                        style={{
                          color: '#fff',
                        }}
                        size="0.80rem"
                        className="ml-1"
                      />
                    )}
                  </button>
                </div>
                <hr />
              </div>
            </div>
          </Box>
        </Fade>
      </ViewModal>

      {/* Remove Limit */}
      <ViewModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewLimitModalOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={viewLimitModalOpen}>
          <Box sx={style}>
            <div className={`modal_content`}>
              <div className={`modal_header p-3`}>
                <h4 style={{ fontWeight: 'bold' }}>Delete Withdrawal Limit</h4>
                <span
                  onClick={handleCloseViewModal}
                  style={{ cursor: 'pointer' }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <div className="mt-5">
                <div className="row text-muted border_buttom  mt-4 mb-4">
                  <div className="col-md-6">Store Category</div>
                  <div className="col-md-6">{updateValue?.store_category}</div>
                  <hr />
                </div>
                <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                  <div className="col-md-6">Amount</div>
                  <div className="col-md-6 ">
                    <b>
                      ₦{' '}
                      {updateValue?.amount
                        ? Number(
                            updateValue.amount.$numberDecimal
                          ).toLocaleString()
                        : 0}
                      /{' '}
                      {updateValue?.withdrawal_amount_frequency
                        ?.charAt(0)
                        .toUpperCase() +
                        updateValue?.withdrawal_amount_frequency?.slice(1)}
                    </b>
                  </div>
                  <hr />
                </div>
                <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                  <div className="col-md-6">Income Level (Min)</div>
                  <div className="col-md-6">
                    <b>
                      ₦{' '}
                      {Number(
                        updateValue?.income_level?.from?.$numberDecimal
                      ).toLocaleString() || 0}
                    </b>
                  </div>
                  <hr />
                </div>
                <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                  <div className="col-md-6">Income Level (Max)</div>
                  <div className="col-md-6 ">
                    <span>
                      <b>
                        ₦{' '}
                        {Number(
                          updateValue?.income_level?.to?.$numberDecimal
                        ).toLocaleString() || 0}
                      </b>
                    </span>
                  </div>
                  <hr />
                </div>
                <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                  <div className="col-md-6">Income Level Frequency</div>
                  <div className="col-md-6 ">
                    <span>
                      {updateValue?.income_level_frequency
                        ?.charAt(0)
                        .toUpperCase() +
                        updateValue?.income_level_frequency?.slice(1)}
                    </span>
                  </div>
                  <hr />
                </div>
              </div>
              <div className="row mt-3 text-muted mt-4 mb-4">
                <div className="col-md-6">
                  <button
                    onClick={handleCloseViewModal}
                    className="btn btn-outline-secondary form-control"
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-md-6 ">
                  <button
                    onClick={() => {
                      handleDeleteLimit(updateValue?._id);
                    }}
                    className="btn btn-danger form-control"
                  >
                    Delete
                    {deletePending && (
                      <CircularProgress
                        style={{
                          color: '#fff',
                        }}
                        size="0.80rem"
                        className="ml-1"
                      />
                    )}
                  </button>
                </div>
                <hr />
              </div>
            </div>
          </Box>
        </Fade>
      </ViewModal>

      {/* Approve Withdrawal */}
      <ViewModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={withdrawalStatusReason}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={withdrawalStatusReason}>
          <Box sx={style2}>
            <div className={`modal_content`}>
              <div className={`modal_header p-2`}>
                <h4 style={{ fontWeight: 'bold' }}>
                  {currentId?.shop?.store_name}
                  <br />
                  <small style={{ fontSize: '11px' }}>
                    {currentId?.shop?.shop_iD}
                  </small>
                </h4>
                <span
                  onClick={handleCloseStatusModal}
                  style={{ cursor: 'pointer' }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <div className="mt-5 pl-2">
                <div className="mb-3">
                  <label htmlFor="reason">Reason:</label>
                  <TextField
                    required
                    type="text"
                    size="small"
                    name="reason"
                    value={reason.reason}
                    onChange={(e) =>
                      handleStatusReason('reason', e.target.value)
                    }
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
              <div className="row mt-3 text-muted mt-4 mb-4">
                {selectedStatus === 'decline' && (
                  <div className="col-12">
                    <button
                      onClick={() => handleTransactionDecline(currentId?._id)}
                      className="btn btn-danger form-control"
                    >
                      Decline
                      {declinePending[currentId?._id] && (
                        <CircularProgress
                          style={{
                            color: '#fff',
                          }}
                          size="0.80rem"
                          className="ml-1"
                        />
                      )}
                    </button>
                  </div>
                )}
                {selectedStatus === 'approve' && (
                  <div className="col-12 ">
                    <button
                      onClick={() => handleTransactionApprove(currentId?._id)}
                      className="btn btn_primary form-control"
                    >
                      Approve
                      {statusPending[currentId?._id] && (
                        <CircularProgress
                          style={{
                            color: '#fff',
                          }}
                          size="0.80rem"
                          className="ml-1"
                        />
                      )}
                    </button>
                  </div>
                )}
                <hr />
              </div>
            </div>
          </Box>
        </Fade>
      </ViewModal>
    </>
  ) : (
    <SalesDetails key={1} onChange={handleChange} />
  );
}
