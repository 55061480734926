// ----------------------------------------------------------------------
import styles from '../Sales.module.css';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Card, Avatar } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LiveLocation from 'src/pages/LiveLocation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// mock
import { useLayoutEffect } from 'react';
import GlobalModal from 'src/Modals/GlobalModal';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useAuth } from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import Filter1 from 'src/pages/Filter/Filter1';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import moment from 'moment';
import { BallTriangle } from 'react-loader-spinner';
// import CircularProgress from '@mui/material/CircularProgress';
import bus from 'src/bus';
import { downloadFile2 } from 'src/utils';
import CircularProgress from '@mui/material/CircularProgress';
import { Pagination } from 'src/components';

// ----------------------------------------------------------------------
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

// ptvp=PAGE TO VIEW (PRODUCT)

export default function Shoppers() {
  const { data } = useAuth();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [showDiv] = useState(data.merchant_type === 'brand');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [Pending, setPending] = useState(false);
  const [shoppersData, setData] = useState([]);
  const [searchvalue, setSearchvalue] = useState([]);
  const [dataFilter, setDataFilter] = useState('today');
  const [dataPagination, setDataPagination] = useState({ total: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [tooltipData, setToolTipData] = useState({ product_items: [] });
  const [tooltipLoading, setToolTipLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  // const notify = (msg =null) => toast.success(msg === null ?'Store saved .' : msg);
  const notifyError = (err) => toast.error(err);
  const [filterSearch, setFilterSearch] = useState({
    min_patronage: '',
    max_patronage: '',
    min_price: 0,
    max_price: 0,
    min_order_unit: '',
    max_order_unit: '',
    min_amount: '',
    max_amount: '',
    payment_mode: '',
    location: '',
  });
  const [dataDetails, setDetailsData] = useState({
    total_sales: {
      current_total_sales: 0,
      previous_total_sales: 0,
      percentage_sales: 0,
    },
    total_products: {
      current_total_products: 0,
      previous_total_product: 0,
      percentage_abandoned: 0,
    },
  });

  const handleStatusChange = (key, value) => {
    setFilterSearch((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const searchFilter = (e) => {
    setSearchvalue(e.target.value);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvp');

      // Your code here.
    },
    []
  );

  const handleViewClick = (d) => {
    navigate('/app/sales_details/' + d._id);
    // handle view click action
  };

  const processLocation = (e) => {
    // console.log(e);
  };

  const fetchCardDetails = (period = dataFilter) => {
    setIsPending(true);
    let periodValue = typeof period === 'object' ? 'today' : period;

    axiosInstance
      .get('/brand/sales/shoppers/cards?period=' + periodValue)
      .then((res) => {
        setDetailsData(res.data.data);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError(
            'Unable to fetch shoppers analytics, please try again later'
          );
        }
      })
      .finally(() => setIsPending(false));
  };
  useEffect(() => {
    fetchCardDetails();
  }, [dataFilter]);

  const getHoverProduct = (id) => {
    setToolTipLoading(true);
    setToolTipData({ product_items: [] });
    axiosInstance
      .get(`/brand/sales/shoppers/on-hover/${id}`)
      .then((res) => {
        setToolTipData(res.data.data[0] || { product_items: [] });
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Something went wrong , please try again later');
        }
      })
      .finally(() => setToolTipLoading(false));
  };

  const fetchData = async (
    page = 1,
    perpage = 10,
    search = searchvalue,
    location = filterSearch.location,
    dateFrom = startDate,
    dateTo = endDate,
    min_price = filterSearch.min_price,
    max_price = filterSearch.max_price,
    status = filterSearch.status,
    payment_method = filterSearch.payment_mode,
    file = null
  ) => {
    let queryParams = new URLSearchParams({
      min_price,
      max_price,
      export_type: file,
    }).toString();

    if (file) {
      setExporting(true);

      try {
        await downloadFile2(
          'Sales',
          `/brand/sales/shoppers?${queryParams}`,
          file
        );
      } finally {
        // alert()
        setExporting(false);
      }
      return;
    }
    setPending(true);
    handleClose(false);
    setModalOpen(false);
    axiosInstance
      .get('/brand/sales/shoppers?', {
        params: {
          page,
          perpage,
          search,
          location,
          dateFrom,
          dateTo,
          min_price,
          max_price,
          status,
          payment_method,
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        if (res.status < 300) {
          setData(res.data.data.data);
        }
        setDataPagination(res.data.data.pagination);
        setStartDate(null);
        setEndDate(null);
        setFilterSearch({
          min_patronage: '',
          max_patronage: '',
          min_price: 0,
          max_price: 0,
          min_order_unit: '',
          max_order_unit: '',
          min_amount: '',
          max_amount: '',
          payment_mode: '',
          location: '',
        });
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch subscriptions, try again later');
        }
      })
      .finally(() => setPending(false));
  };

  useEffect(() => {
    bus.on('dataFilter', (el) => {
      console.log(el);
      setDataFilter(el);
    });
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchvalue]);

  return (
    <>
      <div className="pt-4 px-2">
        <div className={`${styles.analytics}  `}>
          <Filter1 width="100%" />

          <div className={`row`}>
            <div className={`col-md-12 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Total Product sold</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        {Number(
                          dataDetails?.total_products?.current_total_products
                        ).toLocaleString()}
                      </span>
                      <small className="pl-1">
                        from{' '}
                        {Number(
                          dataDetails?.total_products?.previous_total_product
                        ).toLocaleString()}
                      </small>
                    </div>
                    <div>
                      <div className={styles.percent}>
                        <small>
                          {Number(
                            dataDetails?.total_products?.current_total_products
                          ).toLocaleString() >=
                          Number(
                            dataDetails?.total_products?.previous_total_product
                          ).toLocaleString() ? (
                            <div className={styles.percent}>
                              <img src="/assets/icons/up.svg" alt="" />
                              <small className="pl-1">
                                {
                                  dataDetails?.total_products
                                    ?.percentage_abandoned
                                }
                                %
                              </small>
                            </div>
                          ) : (
                            <div className={`${styles.percent2}`}>
                              <img src="/assets/icons/downr.svg" alt="" />
                              <small className="pl-1">
                                {Math.abs(
                                  dataDetails?.total_products
                                    ?.percentage_abandoned
                                )}
                                %
                              </small>
                            </div>
                          )}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Total Sales</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        ₦{Number(dataDetails?.total_sales?.current_total_sales).toLocaleString()}
                      </span>
                      <small className="pl-1">
                        from ₦ {Number(dataDetails?.total_sales?.previous_total_sales).toLocaleString()}
                      </small>
                    </div>
                    <div>
                      {
                        Number(dataDetails?.total_sales?.current_total_sales).toLocaleString() >=
                          Number(dataDetails?.total_sales?.previous_total_sales).toLocaleString() ?
                          <div className={styles.percent}>
                            <img src="/assets/icons/up.svg" alt="" />
                            <small className="pl-1">
                              {dataDetails?.total_sales?.percentage_sales}%
                            </small>
                          </div>
                          :
                          <div className={`${styles.percent2}`}>
                            <img src="/assets/icons/downr.svg" alt="" />
                            <small className="pl-1">
                              {Math.abs(dataDetails?.total_sales?.percentage_sales)}%
                            </small>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-md-3 col-6  `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                value={searchvalue}
                onChange={searchFilter}
                type="text"
                placeholder="Search"
                style={{ textIndent: '25px', width: '100%' }}
                className=" form-control"
              />
            </div>
            <div className="col-md-9 col-6 text-right">
              <button
                onClick={() => setModalOpen(true)}
                className="btn  border"
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px',
                }}
              >
                <img
                  className=""
                  style={{ display: 'inline', width: '17px' }}
                  src="/assets/icons/filterlines.svg"
                  alt=""
                />
                &nbsp;
                <small className="d-none d-md-inline-block">Filters</small>
              </button>
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: '50px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-4 ">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">Shoppers</h5>
                <small style={{ fontWeight: '200', fontSize: '12px' }}>
                  Customers who have purchased from your outlets recently
                </small>
              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`${styles.export_btn} btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: '8px',
                      }}
                    >
                      <img src="/assets/icons/downloadblue.svg" alt="" /> &nbsp;
                      {!exporting && <span>Export</span>}
                      {exporting && (
                        <CircularProgress
                          style={{
                            color: 'white',
                            display: 'inline-block',
                          }}
                          size="1rem"
                        />
                      )}
                    </span>
                    &nbsp; &nbsp;
                    <span
                      style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <img src="/assets/icons/chevron-downblue.svg" alt="" />
                    </span>
                  </button>

                  <div
                    className="dropdown-menu drop-left"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      onClick={() =>
                        fetchData(
                          currentPage,
                          500000000,
                          '',
                          '',
                          '',
                          '',
                          '',
                          '',
                          '',
                          '',
                          'csv'
                        )
                      }
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      CSV
                    </button>
                    <button
                      onClick={() =>
                        fetchData(
                          currentPage,
                          500000000,
                          '',
                          '',
                          '',
                          '',
                          '',
                          '',
                          '',
                          '',
                          'excel'
                        )
                      }
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      Excel{' '}
                    </button>
                    <button
                      onClick={() =>
                        fetchData(
                          currentPage,
                          500000000,
                          '',
                          '',
                          '',
                          '',
                          '',
                          '',
                          '',
                          '',
                          'pdf'
                        )
                      }
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      PDF{' '}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`pt-3 pb-3 pl-0 pr-0`}>
              <table
                className={`${styles.table} table-hover table-striped`}
                style={{ minWidth: '100em' }}
              >
                <thead className={`${styles.thead}`}>
                  <tr>
                    <th className="text-muted">
                      Shoppers
                      <img
                        className="pl-2"
                        src="/assets/icons/down.svg"
                        alt=""
                      />
                    </th>
                    <th className="text-muted">Customer Location</th>
                    <th className="text-muted">Product Purchased</th>
                    <th className="text-muted">Mode Of Payment</th>

                    <th className="text-muted">Date</th>
                    {/* <th className="text-muted">
                      Price

                    </th> */}
                    <th className="text-muted">Total Quantity</th>
                    {/* <th className="text-muted">
                      Total Amount

                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {!Pending &&
                    shoppersData.length > 0 &&
                    shoppersData.map((el, i) => (
                      <tr className={`${styles.tr} `}>
                        <td>
                          <Avatar
                            className={`${styles.avatrr} mr-3 float-md-left`}
                            alt=""
                            src={el?.image ? el?.image : '/assets/image1.svg'}
                          />
                          <div className=" ">
                            <p
                              className="mb-0"
                              style={{
                                fontSize: '14px',
                              }}
                            >
                              {el?.full_name}
                            </p>
                            <span style={{ fontSize: '10px' }}>
                              {el?.email}
                            </span>
                          </div>
                        </td>
                        <td>
                          <p>{el.location}</p>
                        </td>
                        <td>
                          <BootstrapTooltip
                            title={
                              <div>
                                {!tooltipLoading &&
                                  tooltipData.product_items.length > 0 &&
                                  tooltipData.product_items.map(
                                    (tooltip, ind) => (
                                      <span key={ind} className="mr-1">
                                        {tooltip.product_name},
                                      </span>
                                    )
                                  )}
                                {!tooltipLoading &&
                                  tooltipData.product_items.length < 1 && (
                                    <span>Empty</span>
                                  )}
                                {tooltipLoading && <span>Loading</span>}
                              </div>
                            }
                          >
                            <span onMouseEnter={() => getHoverProduct(el._id)}>
                              {el?.product_units}
                            </span>
                          </BootstrapTooltip>
                        </td>
                        <td>{el?.payment_method.toUpperCase()}</td>
                        <td>{moment(el?.date_ordered).format('DD/MM/YYYY')}</td>
                        {/* <td>
                        ₦{el?.total_cost ? Number(el?.total_cost).toLocaleString() : 0}
                      </td> */}
                        <td>
                          {el?.total_quantity
                            ? Number(el?.total_quantity).toLocaleString()
                            : 0}
                        </td>
                        {/* <td>
                        ₦{el?.cumulative_amount ? Number(el?.cumulative_amount).toLocaleString() : 0}
                      </td> */}
                        {/* <td>
                        <div className="d-inline-block dropleft ">

                          <span
                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"

                            className={`${styles.action_button} px-3 py-2`}  style={{
                              cursor: 'pointer',

                            }}>
                            <img src="/assets/icons/action.svg" alt="" />

                          </span>

                          <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                            <button type="button" onClick={() => handleViewClick(el)} className="rss dropdown-item btn border-0">View</button>


                          </div>
                        </div>
                      </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {Pending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!shoppersData.length && !Pending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={dataPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>
      <GlobalModal
        open={modalOpen}
        onClose={() => fetchData()}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#FFFFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span onClick={() => fetchData()} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form action="" className="mt-0 form_label">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <LiveLocation label="Location" passData={processLocation} />
                </div>

                <div className="mb-3">
                  <label htmlFor="name">Payment mode</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    placeholder="Payment Mode"
                    required
                    value={filterSearch.payment_mode}
                    onChange={(e) =>
                      handleStatusChange('payment_mode', e.target.value)
                    }
                    name="payment_mode"
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="cash">Cash</MenuItem>
                    <MenuItem value="wallet">Mobile Transfer</MenuItem>
                    <MenuItem value="card">Card Payment</MenuItem>
                  </Select>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                {!showDiv && (
                  <div>
                    <label htmlFor="ora">Total amount </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            id="ora"
                            placeholder="Min"
                            size="small"
                            value={filterSearch.min_amount}
                            name="min_amount"
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                handleStatusChange('min_amount', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="px-1 d-flex   align-items-center "
                        style={{ height: '40px' }}
                      >
                        <img
                          src="/assets/icons/line.svg"
                          style={{ width: '30px' }}
                          alt=""
                        />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            placeholder="Max "
                            size="small"
                            value={filterSearch.max_amount}
                            name="max_amount"
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                handleStatusChange('max_amount', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <label htmlFor="name">Date </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '48%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    {/* sss */}
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '14px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '48%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        value={filterSearch.min_order_unit}
                        name="min_order_unit"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleStatusChange('min_order_unit', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        placeholder="Max "
                        size="small"
                        value={filterSearch.max_order_unit}
                        name="max_order_unit"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleStatusChange('max_order_unit', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                {!showDiv && (
                  <div>
                    <label htmlFor="patronage">Patronage </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            id="patronage"
                            placeholder="Min"
                            size="small"
                            value={filterSearch.min_patronage}
                            name="min_patronage"
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                handleStatusChange('min_patronage', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="px-1 d-flex   align-items-center "
                        style={{ height: '40px' }}
                      >
                        <img
                          src="/assets/icons/line.svg"
                          style={{ width: '30px' }}
                          alt=""
                        />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            placeholder="Max "
                            size="small"
                            value={filterSearch.max_patronage}
                            name="max_patronage"
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                handleStatusChange('max_patronage', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={() => fetchData()}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
    </>
  );
}
