import { useAuth } from "src/hooks/useAuth";
import Brand from "./BrandOwner";
import Retailer from "./More";

export default function IncrementalInsight() {
  const {data} = useAuth();

    
    return  (
      <Brand />  
    )
}