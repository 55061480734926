

// ----------------------------------------------------------------------
import styles from "./BrandOwner.module.css"
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from "react";
import Products from "./BrandOwnerComponent/Products";
import SingleView from "./BrandOwnerComponent/SingleView";
import PromoAndDiscount from "./BrandOwnerComponent/PromoAndDiscount";
import SponsoredAd from "./BrandOwnerComponent/SponsoredAd";
import { useAuth } from "src/hooks/useAuth";
import { useLocation, useParams } from "react-router-dom";

export default function ProductBrandOwnerPage() {

  const [value, setValue] = useState('1');
  const { data } = useAuth();

  const { categoryId } = useParams();


  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };



  return (
    <>
      <div className="wrapper mt-4 mt-md-0 px-2">
        <div className="header d-block d-md-none mb-4">
          <div className={`${styles.product_actions} d-md-flex justify-content-between`}>
            <div className=" ">
              <h4 className={`mb-0 pb-0`}>Products</h4>
              <p style={{ fontSize: '14px' }} className={`mb-0 mt-2 pb-0`}>
                Review and manage product categories
              </p>

            </div>
            {/* <div className={`${styles.product_button} mt-3 mt-md-0`}>
            <button type="button"  className={`btn ${styles.top_up_button}`}>
              <span>
              <img  src="/assets/icons/plus.svg" className="pr-1" alt="" />

              Add Category
              </span>
            </button>
            

          </div> */}


          </div>


        </div>
        <TabContext value={value}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs ">
            <Tab label="Products" value="1" />
            <Tab label="Promos and Discounts " value="2" />
            {/* {data.user_type !== "admin" &&
              <Tab label="Sponsored Ads " value="3" />

            } */}
          </TabList>
          <TabPanel className="mt-0 pt-0" value="1" style={{ paddingLeft: '0' }}>
            {categoryId === undefined &&
              <Products key={3} />
            }
            {categoryId !== undefined &&
              <SingleView categoryId={categoryId} key={3} />
            }



          </TabPanel>
          <TabPanel className="mt-0 pt-0" value="2" style={{ paddingLeft: '0' }}>
            <PromoAndDiscount key={4} />



          </TabPanel>
          {/* {data.user_type !== "admin" &&
            <TabPanel className="mt-0 pt-0" value="3" style={{ paddingLeft: '0' }}>
              <SponsoredAd key={6} />



            </TabPanel>

          } */}
        </TabContext>


      </div>

    </>
  );
}
