// ----------------------------------------------------------------------
import styles from '../styles.module.css';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Card, Avatar } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
import { useNavigate } from 'react-router-dom';
// sections
// mock
import { useLayoutEffect } from 'react';
import GlobalModal from 'src/Modals/GlobalModal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { createRef } from 'react';
import { Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import moment from 'moment';
import { BallTriangle } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateFormat from 'src/hooks/useDateFormat';
import LiveLocation from 'src/pages/LiveLocation';
import { downloadFile3 } from 'src/utils';
import { Pagination } from 'src/components';

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3,
};

export default function Referrals() {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [vouchersData, setVouchersData] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [isPending, setIsPending] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [currentParticipant, setCurrentParticipant] = useState(false);
  const [viewCurrentVoucher, setViewCurrentVoucher] = useState(false);
  const [dataPagination, setDataPagination] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const notify = (msg) => toast.success(msg);
  const notifyError = (err) => toast.error(err);
  const [filter, setFilter] = useState({
    search: '',
  });
  const handleFilterChange = (key, value) => {
    setFilter((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleCloseFilter = () => {
    setFilterModalOpen(false);
  };

  const processLocation = (e) => {
    handleFilterChange('location', e.formatted_address);
    handleFilterChange('latitude', e.lat);
    handleFilterChange('longitude', e.lng);
  };

  const handleSubmitFilter = () => {
    handleCloseFilter();
    // setSubmitFilter(!submitFilter)
  };

  const handleDownloadFile = async (extension) => {
    setExportLoading(true);
    try {
      const result = `/admin/customers/get-storeowners?page=${dataPagination.currentPage}&perpage=${dataPagination.total}&export_type=${extension}`;
      await downloadFile3('Customer - Stores', result, extension);
    } catch (err) {
      notifyError(err.message);
    } finally {
      setExportLoading(false);
    }
  };

  const fetchData = () => {};

  const handleViewCurrentParticipant = () => {};

  function handleClick(event) {
    event.preventDefault();
    navigate('/app/promotions');
  }
  return (
    <>
      <div className="px-2">
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-6   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={filter.search}
                onChange={(e) => handleFilterChange('search', e.target.value)}
                placeholder="Search"
                style={{ textIndent: '25px', width: '60%' }}
                className=" form-control"
              />
            </div>
            <div className="col-6  text-right">
              <button
                onClick={() => setFilterModalOpen(true)}
                className="btn  border"
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px',
                }}
              >
                <img
                  className=""
                  style={{ display: 'inline', width: '17px' }}
                  src="/assets/icons/filterlines.svg"
                  alt=""
                />
                &nbsp;
                <small className="d-none d-md-inline-block">Filters</small>
              </button>
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: '50px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-4 border-bottom">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                {!currentParticipant && (
                  <>
                    <h5 className="table_desc_header">Vouchers</h5>
                    <small style={{ fontWeight: '200', fontSize: '14px' }}>
                      Details of sales made by stores on FlexDeals
                    </small>
                  </>
                )}
                {currentParticipant && (
                  <>
                    <button onClick={handleClick} className="btn">
                      <div
                        className="p-2"
                        style={{
                          border: '1px solid lightgrey',
                          borderRadius: '3px',
                        }}
                      >
                        <img src="/assets/icons/Chevronleft.svg" alt="" />
                      </div>
                    </button>
                    <h5 className="table_desc_header">Vouchers</h5>
                    <small style={{ fontWeight: '200', fontSize: '14px' }}>
                      View customers who used the voucher
                    </small>
                  </>
                )}
              </div>
              <div className="pt-md-2 d-md-flex">
                <div className="">
                  <button
                    className="btn btn_primaryh"
                    onClick={() => setFilterModalOpen(true)}
                    style={{
                      border: '1px solid #af3585',
                      color: '#af3585',
                      background: '#8C2D9129',
                      borderRadius: '7px',
                      minHeight: '40px',
                    }}
                  >
                    + <small>Create Vouchers</small>
                  </button>
                </div>
                &nbsp; &nbsp;
                <div className="dropleft ">
                  <button
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`${styles.export_btn} btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: '8px',
                      }}
                    >
                      <img src="/assets/icons/downloadblue.svg" alt="" /> &nbsp;
                      Export
                    </span>
                    &nbsp; &nbsp;
                    <span
                      style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <img src="/assets/icons/chevron-downblue.svg" alt="" />
                    </span>
                  </button>
                  <div
                    className="dropdown-menu drop-left"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('csv')}
                    >
                      CSV
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('excel')}
                    >
                      Excel
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('pdf')}
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!currentParticipant && (
            <Scrollbar>
              <div className={`pt-3 pb-3 pl-0 pr-0`}>
                <table
                  className={`${styles.table} table-hover table-striped `}
                  style={{ minWidth: '100em' }}
                >
                  <thead className={`${styles.thead}`}>
                    <tr>
                      <th className="text-muted">S/N</th>
                      <th className="text-muted" style={{ width: '16rem' }}>
                        Voucher Code
                        <img
                          className="pl-2"
                          src="/assets/icons/down.svg"
                          alt=""
                        />
                      </th>
                      <th className="text-muted">Voucher Type</th>
                      <th className="text-muted">Target Users</th>
                      <th className="text-muted">Status</th>
                      <th className="text-muted">Number of Participant</th>
                      <th className="text-muted">Start Date</th>
                      <th className="text-muted">Expiry Date</th>
                      <th className="text-muted">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isPending &&
                      vouchersData.length >= 1 &&
                      vouchersData.map((el, i) => (
                        <tr className={`${styles.tr} `}>
                          <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                          <td>
                            <p>{el?.voucher_code ? el.voucher_code : '---'}</p>
                          </td>
                          <td>
                            <p>{el?.voucher_type ? el.voucher_type : '---'}</p>
                          </td>
                          <td>
                            <p>{el?.target_users ? el.target_users : '---'}</p>
                          </td>
                          <td>
                            <span className="">
                              {el?.is_verified === true ? (
                                <span className="verified_badge">
                                  <img
                                    src="/assets/icons/check-circlel.svg"
                                    alt=""
                                  />
                                  Active
                                </span>
                              ) : el?.is_verified === false ? (
                                <span className="not_verified_badge">
                                  Ended
                                </span>
                              ) : (
                                <span className="pending_verified_badge">
                                  Pending
                                </span>
                              )}
                            </span>
                          </td>
                          <td>
                            {el?.number_of_participants
                              ? el.number_of_participants
                              : 0}
                          </td>
                          <td>
                            <span>
                              {el?.date_registered
                                ? moment(el?.date_registered).format(
                                    'DD/MM/YYYY'
                                  )
                                : 'NOT SET'}
                            </span>{' '}
                            <br />
                            <small>
                              {el?.date_registered
                                ? moment(el?.date_registered).format('HH:mm')
                                : 'NOT SET'}{' '}
                              PM
                            </small>
                          </td>
                          <td>
                            <span>
                              {el?.date_expired
                                ? moment(el?.date_expired).format('DD/MM/YYYY')
                                : 'NOT SET'}
                            </span>{' '}
                            <br />
                            <small>
                              {el?.date_expired
                                ? moment(el?.date_expired).format('HH:mm')
                                : 'NOT SET'}{' '}
                              PM
                            </small>
                          </td>
                          <td>
                            <div className="dropleft ">
                              <span
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                className={`${styles.action_button} px-3 py-2`}
                                style={{
                                  cursor: 'pointer',
                                }}
                              >
                                <img src="/assets/icons/action.svg" alt="" />
                              </span>

                              <div
                                className="dropdown-menu drop-left"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleViewCurrentParticipant(el);
                                    setCurrentParticipant(true);
                                  }}
                                  className="rss dropdown-item btn border-0"
                                >
                                  View Participant
                                </button>
                                <button
                                  type="button"
                                  onClick={() => {
                                    setCurrentData(el);
                                    setViewCurrentVoucher(true);
                                  }}
                                  className="rss dropdown-item btn border-0"
                                >
                                  View
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center">
                {isPending && (
                  <BallTriangle
                    height={50}
                    width={55}
                    radius={5}
                    color="#af3585"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />
                )}
              </div>
              {!vouchersData.length && !isPending && (
                <div className="alert alert-[#af3585] text-center">
                  <h4>No record found</h4>
                </div>
              )}
            </Scrollbar>
          )}
          {currentParticipant && (
            <Scrollbar>
              <div className={`pt-3 pb-3 pl-0 pr-0`}>
                <table
                  className={`${styles.table} table-hover table-striped `}
                  style={{ minWidth: '100em' }}
                >
                  <thead className={`${styles.thead}`}>
                    <tr>
                      <th className="text-muted">S/N</th>
                      <th className="text-muted" style={{ width: '16rem' }}>
                        Shopper Name
                        <img
                          className="pl-2"
                          src="/assets/icons/down.svg"
                          alt=""
                        />
                      </th>
                      <th className="text-muted">Phone Number</th>
                      <th className="text-muted">Address</th>
                      <th className="text-muted">Total Order</th>
                      <th className="text-muted">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isPending &&
                      vouchersData.length >= 1 &&
                      vouchersData.map((el, i) => (
                        <tr className={`${styles.tr} `}>
                          <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                          <td>
                            <div className="d-flex justify-content-start align-items-center">
                              <Avatar
                                className={`${styles.avatrr} mr-3 float-md-left`}
                                alt=""
                                src={el?.store_logo}
                              />

                              <div className="">
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: '14px',
                                    whiteSpace: 'wrap',
                                  }}
                                >
                                  {el?.onboarded_by && (
                                    <small
                                      className="px-2 py-0 rounded text-[#af3585] d-block"
                                      style={{
                                        width: 'fit-content',
                                        fontSize: '11px',
                                        background: '#E8F3FB',
                                        letterSpacing: '1px',
                                      }}
                                    >
                                      Invite
                                    </small>
                                  )}
                                  {el?.store_name ? el.store_name : '---'}
                                </p>
                                <span>{el?.storeID ? el.storeID : '---'}</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>{el?.phone ? el.phone : '---'}</p>
                          </td>
                          <td>
                            <p>{el?.address ? el.address : '---'}</p>
                          </td>
                          <td>
                            {el?.number_of_participants
                              ? el.number_of_participants
                              : 0}
                          </td>
                          <td>
                            <span>
                              {el?.date_registered
                                ? moment(el?.date_registered).format(
                                    'DD/MM/YYYY'
                                  )
                                : 'NOT SET'}
                            </span>{' '}
                            <br />
                            <small>
                              {el?.date_registered
                                ? moment(el?.date_registered).format('HH:mm')
                                : 'NOT SET'}{' '}
                              PM
                            </small>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center">
                {isPending && (
                  <BallTriangle
                    height={50}
                    width={55}
                    radius={5}
                    color="#af3585"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />
                )}
              </div>
              {!vouchersData.length && !isPending && (
                <div className="alert alert-[#af3585] text-center">
                  <h4>No record found</h4>
                </div>
              )}
            </Scrollbar>
          )}
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={dataPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>

      {/* Fiter Modal */}
      {/* <GlobalModal
                open={filterModalOpen}
                onClose={handleCloseFilter}
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                width="740px !important"
                overflow="auto"
                bgcolor="#FFFFFF"
                border="1px solid #F5F5F5"
                borderRadius="5px"
                boxShadow="24"
                p="25px"
            >
                <div className={`${styles.modal_content}`}>
                <div className={`${styles.modal_header} mb-0`}>
                    <h5
                        style={{
                            fontSize: "19px",
                            fontWeight: "700",
                        }}
                    >
                        Filter
                    </h5>
                    <span onClick={handleCloseFilter} style={{ cursor: "pointer" }}>
                    <img src="/assets/icons/x.svg" alt="" />
                    </span>
                </div>

                <form action="" className="mt-0">
                    <div className="row">
                    <div className="col-md-6 mb-1">
                        <div className="mb-3">
                        <label htmlFor="name">Verification</label>
                        <Select
                            labelId="s"
                            id="s"
                            fullWidth
                            size="small"
                            value={filter.verified}
                            onChange={(e) => handleFilterChange('verified', e.target.value)}
                        >
                            <MenuItem value="select">Select Customer Status</MenuItem>
                            <MenuItem value={true}>Verified</MenuItem>
                            <MenuItem value={false}>Unverified</MenuItem>
                        </Select>
                        </div>

                        <div className="">
                        <div className="mb-3">
                            <LiveLocation label='Location' passData={processLocation} />
                        </div>
                        </div>

                        <label htmlFor="ounit">Brands </label>
                        <div className=" d-flex">
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <TextField
                                required
                                type="number"
                                value={filter.min_brands}
                                id="ounit"
                                placeholder=""
                                size="small"
                                onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleFilterChange('min_brands', inputValue);
                                }
                                }}
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        <div
                            className="px-1 d-flex   align-items-center "
                            style={{ height: "40px" }}
                        >
                            <img
                            src="/assets/icons/line.svg"
                            style={{ width: "30px" }}
                            alt=""
                            />
                        </div>
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <TextField
                                required
                                type="number"
                                value={filter.max_brands}
                                placeholder=""
                                size="small"
                                onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleFilterChange('max_brands', inputValue);
                                }
                                }}
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-1">
                        <label htmlFor="price">Date </label>

                        <div className=" d-flex">
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <DatePicker
                                showIcon
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                                placeholderText="Start"
                                required
                                maxDate={new Date()}
                            />
                            </div>
                        </div>
                        <div
                            className="px-1 d-flex   align-items-center "
                            style={{ height: "40px" }}
                        >
                            <img
                            src="/assets/icons/line.svg"
                            style={{ width: "30px" }}
                            alt=""
                            />
                        </div>
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <DatePicker
                                showIcon
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                                placeholderText="End"
                                required
                                maxDate={new Date()}
                            />
                            </div>
                        </div>
                        </div>

                        <label htmlFor="ounit">Wallet Balance </label>
                        <div className=" d-flex">
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <TextField
                                required
                                type="number"
                                value={filter.min_balance}
                                id="ounit"
                                placeholder="Min"
                                size="small"
                                onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleFilterChange('min_balance', inputValue);
                                }
                                }}
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        <div
                            className="px-1 d-flex   align-items-center "
                            style={{ height: "40px" }}
                        >
                            <img
                            src="/assets/icons/line.svg"
                            style={{ width: "30px" }}
                            alt=""
                            />
                        </div>
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <TextField
                                required
                                value={filter.max_balance}
                                type="number"
                                placeholder="Max "
                                size="small"
                                onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleFilterChange('max_balance', inputValue);
                                }
                                }}
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        </div>

                        <label htmlFor="ounit">Order Unit </label>
                        <div className=" d-flex">
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <TextField
                                required
                                value={filter.min_order_unit}
                                type="number"
                                id="ounit"
                                placeholder="Min"
                                size="small"
                                onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleFilterChange('min_order_unit', inputValue);
                                }
                                }}
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        <div
                            className="px-1 d-flex   align-items-center "
                            style={{ height: "40px" }}
                        >
                            <img
                            src="/assets/icons/line.svg"
                            style={{ width: "30px" }}
                            alt=""
                            />
                        </div>
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <TextField
                                required
                                type="number"
                                value={filter.max_order_unit}
                                placeholder="Max "
                                size="small"
                                onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleFilterChange('max_order_unit', inputValue);
                                }
                                }}
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        </div>

                        <label htmlFor="ounit">Product Categories </label>
                        <div className=" d-flex">
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <TextField
                                required
                                value={filter.min_categories}
                                type="number"
                                id="ounit"
                                placeholder=""
                                size="small"
                                onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleFilterChange('min_categories', inputValue);
                                }
                                }}
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        <div
                            className="px-1 d-flex   align-items-center "
                            style={{ height: "40px" }}
                        >
                            <img
                            src="/assets/icons/line.svg"
                            style={{ width: "30px" }}
                            alt=""
                            />
                        </div>
                        <div className="" style={{ width: "100%" }}>
                            <div className="mb-3">
                            <TextField
                                required
                                type="number"
                                value={filter.max_categories}
                                placeholder=""
                                size="small"
                                onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                    handleFilterChange('max_categories', inputValue);
                                }
                                }}
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="mb-4">
                    <button
                        type="button"
                        onClick={() => {
                        handleCloseFilter();
                        fetchData()
                        }}
                        className={`${styles.modal_btn} btn btn-block`}
                    >
                        Proceed{" "}
                        {isPending && (
                        <CircularProgress style={{ color: "#fff" }} size="1rem" />
                        )}
                    </button>
                    </div>
                </form>
                </div>
            </GlobalModal> */}

      {/* View Current */}
      <GlobalModal
        open={viewCurrentVoucher}
        onClose={() => setViewCurrentVoucher(false)}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#FFFFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="">
                <img
                  style={{ width: '40px' }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <h4>Verify {currentData.store_name}</h4>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6B7280',
                  }}
                >
                  Are you sure you want to perform this operation
                </p>
              </div>
            </div>

            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={() => setViewCurrentVoucher(false)}
              >
                Cancel
              </Button>
              <Button
                className={`${styles.delete_product}`}
                // onClick={verifyUser}
              >
                Verify{' '}
                {loadingState && (
                  <CircularProgress style={{ color: '#af3585' }} size="1rem" />
                )}
              </Button>
            </DialogActions>
          </div>
        </div>
      </GlobalModal>

      <GlobalModal
        open={filterModalOpen}
        onClose={handleCloseFilter}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#FFFFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span onClick={handleCloseFilter} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <LiveLocation label="Location" passData={processLocation} />
                </div>
                <div className="mb-3">
                  <label className="font-weight-boldd" htmlFor="name">
                    Status
                  </label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={filter.category}
                    defaultValue={'select'}
                    name="category"
                    required
                    onChange={(e) =>
                      handleFilterChange('category', e.target.value)
                    }
                  >
                    <MenuItem value="select">Select Status</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                  </Select>
                </div>

                <div className="mb-3">
                  <label className="font-weight-boldd" htmlFor="name">
                    Payment Method
                  </label>
                  <Select
                    labelId="mod"
                    id="mod"
                    fullWidth
                    size="small"
                    name="filterStatus"
                    value={filter.filterStatus}
                    defaultValue={'select'}
                    required
                    onChange={(e) =>
                      handleFilterChange('filterStatus', e.target.value)
                    }
                  >
                    <MenuItem value="select">Select Payment Method</MenuItem>
                    <MenuItem value="cash">Cash</MenuItem>
                    <MenuItem value="card">Card</MenuItem>
                    <MenuItem value="transfer">Transfer</MenuItem>
                    <MenuItem value="wallet">Wallet</MenuItem>
                  </Select>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Date </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <label htmlFor="patronage">Price </label>
                  <div className=" d-flex">
                    <div className="" style={{ width: '100%' }}>
                      <div className="mb-3">
                        <TextField
                          required
                          type="number"
                          id="price"
                          placeholder="Min"
                          size="small"
                          name="priceFilter"
                          value={filter.priceFilter}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              inputValue === '' ||
                              parseFloat(inputValue) >= 0
                            ) {
                              handleFilterChange('priceFilter', inputValue);
                            }
                          }}
                          inputProps={{
                            inputMode: 'decimal',
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === '-' ||
                              e.key === 'e' ||
                              e.key === '.'
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="px-1 d-flex   align-items-center "
                      style={{ height: '40px' }}
                    >
                      <img
                        src="/assets/icons/line.svg"
                        style={{ width: '30px' }}
                        alt=""
                      />
                    </div>
                    <div className="" style={{ width: '100%' }}>
                      <div className="mb-3">
                        <TextField
                          required
                          type="number"
                          placeholder="Max "
                          size="small"
                          name="priceToFilter"
                          value={filter.priceToFilter}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              inputValue === '' ||
                              parseFloat(inputValue) >= 0
                            ) {
                              handleFilterChange('priceToFilter', inputValue);
                            }
                          }}
                          inputProps={{
                            inputMode: 'decimal',
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === '-' ||
                              e.key === 'e' ||
                              e.key === '.'
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        name="orderUnitFilter"
                        value={filter.orderUnitFilter}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('orderUnitFilter', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        name="orderUnitToFilter"
                        value={filter.orderUnitToFilter}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('orderUnitToFilter', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={handleSubmitFilter}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
    </>
  );
}
