import React, { useState, useEffect, createContext, useContext } from 'react';
import axiosInstance from 'src/axiosInstance';
import bus from 'src/bus';

const CategoryContext = createContext([]);

const CategoryProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);

  const handleCategories = () => {
    axiosInstance
      .get('/product-category/all?perpage=1000', {
        headers: {
          'x-auth-token': ` ${JSON.parse(localStorage.getItem('auth'))?.token}`,
        },
      })
      .then((res) => {
        setCategories(res.data.data);
      });
  };

  useEffect(() => {
    bus.on('fetchProductCategory', () => {
      handleCategories();
    });
    // handleCategories()
  }, []);

  return (
    <CategoryContext.Provider value={categories}>
      {children}
    </CategoryContext.Provider>
  );
};

const useCategoriesApi = () => useContext(CategoryContext);

export { CategoryProvider, useCategoriesApi };
