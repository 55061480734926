import styles from './Outlet.module.css';
import {
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import Scrollbar from '../../components/scrollbar';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import GlobalModal from 'src/Modals/GlobalModal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { forwardRef, useEffect } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import LiveLocation from 'src/pages/LiveLocation';
import queryString from 'query-string';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import LiveSelect from 'react-select';
import { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Select from '@mui/material/Select';
import bus from 'src/bus';
import CircularProgress from '@mui/material/CircularProgress';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import Filter1 from 'src/pages/Filter/Filter1';
import { downloadFile2, shortenText } from 'src/utils';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import RemoveMinus from '../../hooks/useRemoveNegatives';
import { Pagination } from 'src/components';

// ----------------------------------------------------------------------
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const TABLE_HEAD = [
  { id: 'customerName', label: 'Customer Name', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'outletManager', label: 'Store Manager', alignRight: false },
  { id: 'email', label: 'Email Address', alignRight: false },
  { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'outletLocation', label: 'Store Location', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 580,
  overflow: 'scroll',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

const scrollbar = {
  overflow: 'scroll',
};

export default function AllStores() {
  const [open, setOpen] = useState(false);

  const [analyticsData, setAnalytics] = useState({
    stores_count: {
      bold: 0,
      percentageDiff: 0,
      small: 0,
    },
    stores_stock_count: {
      bold: 0,
      percentagDiff: 0,
      small: 0,
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [exporting, setExporting] = useState(false);

  const [text, setText] = useState({
    title: 'Add Store',
    sub: 'Add information about this store',
  });
  const [isPending, setIsPending] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const notify = () => toast.success('Store saved .');
  const notifyError = (err) => toast.error(err);
  const [search, setSearch] = useState({
    search: '',
    category: '',
    location: '',
    status: '',
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [endpoint, changeEndPoint] = useState('/brand/store/add');
  const [outlets, setOutLets] = useState({});
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [states, setStates] = useState([]);
  const [modalCSVOpen, setModalCSVOpen] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [storeReload, setStoreReload] = useState(false);
  const [dataFilter, setDataFilter] = useState('today');
  const [formValues, setFormValues] = useState({
    store_name: '',
    store_email: '',
    store_manager: '',
    store_phone_number: '',
    outlet_location: '',
    store_category: '',
    country: '',
    state: '',
    store_address: '',
    latitude: '',
    longitude: '',
  });
  const [selectedOption] = useState(formValues.country);
  const [selectedOption1] = useState(formValues.state);
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    console.log(selectedOption);
  }, [selectedOption]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((formValues) => ({
      ...formValues,
      [name]: value,
    }));
  };

  const handleChange2 = (event) => {
    // console.log(event.value);
    setCountry(event.value);
  };

  const handleChange3 = (event) => {
    setState(event.value);
  };

  const fetchCountries = async () => {
    const countriesApi = await fetch('https://restcountries.com/v3.1/all');
    const countriesJson = await countriesApi.json();
    const countriesData = countriesJson.map((country) => ({
      code: country.cca2,
      name: country.name.common,
    }));
    // console.log(countries);
    setCountries(countriesData.sort((a, b) => a.name.localeCompare(b.name)));
  };

  const fetchStates = async () => {
    if (country === '') return;
    setStates([]);
    setState('');
    setLoadingState(true);
    const statesFetch = await fetch(
      `https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/states.json`,
      {}
    );
    const statesJson = await statesFetch.json();

    const statesData = statesJson;

    setStates(() => {
      return statesData
        .filter(
          (el, i) => el.country_name.toLowerCase() === country.toLowerCase()
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    });
    setLoadingState(false);
  };

  useEffect(() => {
    fetchCountries();
    fetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const { lat, lng } = await getLatLng(results[0]);

    setFormValues((prev) => {
      return {
        ...prev,
        latitude: lat,
        longitude: lng,
        store_address: results[0].formatted_address,
      };
    });
    setAddr(value);
  };

  const setAddr = (val) => {
    setFormValues((formValues) => ({
      ...formValues,
      store_address: val,
    }));
  };

  const handleOpen = (data = null) => {
    if (data !== null) {
      setText({
        title: 'Edit Store',
        sub: 'Edit information about this store',
      });
      changeEndPoint(`/brand/store/edit-store/${data._id}`);
      if (!data.hasOwnProperty('store_category')) {
        data.store_category = 1;
        data.country = 'select';
        data.state = 'select';
      }

      setFormValues(data);
      console.log(data);
      setOpen(true);
    } else {
      changeEndPoint('/brand/store/add');
      setText({
        title: 'Add Store',
        sub: 'Add information about this store',
      });
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setModalCSVOpen(false);
  };

  const handleClose2 = () => {
    fetchData({
      location: search.location,
      status: search.status,
      search: search.search,
      page: currentPage,
      perpage: 10,
      store_category: search.store_category,
      export_type: '',
    });
    setModalOpen(false);
  };

  const [openDialog, setDiologOpen] = useState(false);

  const handleClickOpen = (data) => {
    setOutLets(data);
    changeEndPoint('/brand/delete-store/' + data._id);
    setDiologOpen(true);
  };

  const handleDialogClose = () => {
    setDiologOpen(false);
  };

  useEffect(() => {
    bus.on('open-outlet', () => {
      changeEndPoint('/brand/store/add');
      setOpen(true);
    });

    bus.on('dataFilter', (el) => {
      setDataFilter(el);
    });

    fetchAnalytics();
    fetchData({
      location: '',
      status: '',
      search: search.search,
      page: currentPage,
      perpage: 10,
      category: '',
      export_type: '',
    });

    return () => {
      bus.off('open-outlet', () => {
        changeEndPoint('/brand/store/add');
      });
    };
  }, [search.search, storeReload, dataFilter]);

  // useEffect(() => {
  //   fetchData({
  //     location: "",
  //     status: "",
  //     search: search.search,
  //     page: currentPage,
  //     perpage: 10,
  //     export_type: "",
  //   });

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [search.search, storeReload, dataFilter]);

  const fetchAnalytics = async () => {
    setIsPending(true);
    try {
      let periodValue =
        typeof dataFilter === 'object' ? 'All time' : dataFilter;

      const res = await axiosInstance.post(
        `brand/store/get-stores-card?period=${periodValue}`
      );
      setIsPending(false);
      setAnalytics(res.data.data);
    } catch (err) {
      setIsPending(false);
      notifyError(err.response.data.message);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log("FROM, VALUES ===", formValues);

    setIsPending(true);
    let payload = formValues;
    payload.outlet_location = payload.store_address;
    payload.state = state;
    payload.country = country;

    let notAllowed = [
      '_id',
      'shopID',
      'is_approved',
      'createdAt',
      'outlet_location',
    ];
    let finalValue = {};
    for (let key in payload) {
      if (!notAllowed.includes(key)) {
        finalValue[key] = payload[key];
      }
    }

    delete payload.outlet_location;
    axiosInstance[formValues.hasOwnProperty('_id') ? 'put' : 'post'](
      endpoint,
      finalValue,
      state,
      country
    )
      .then((res) => {
        if (res.status === 201) {
          notify(res.data.message);
          bus.emit('update-store', res.data.data);
          fetchData(currentPage, itemsPerPage);
          setFormValues({
            store_name: '',
            store_email: '',
            store_manager: '',
            store_address: '',
            store_phone_number: '',
            store_category: '',
            country: 'select',
            state: 'select',
          });
        }
        fetchData({
          location: '',
          status: '',
          search: '',
          page: 1,
          perpage: 10,
          export_type: '',
        });
        setOpen(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to save store, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const handleDelete = () => {
    setIsDeleting(true);
    axiosInstance
      .delete(endpoint)
      .then((res) => {
        if (res.status < 300) {
          notify(res.data.message);
          fetchData(currentPage, itemsPerPage);
          handleClose();
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to save store, try again later');
        }
      })
      .finally(() => {
        setIsDeleting(false);
        handleDialogClose();
      });
  };

  const processFilter = (e) => {
    const { name, value } = e.target;
    setSearch(() => ({
      ...search,
      [name]: value,
    }));
  };

  const processLocation = (value) => {
    setSearch(() => ({
      ...search,
      // eslint-disable-next-line no-useless-computed-key
      ['location']: value.formatted_address,
    }));
  };

  const [storeData, setStoreData] = useState([]);

  const [pagination, setSalesPagination] = useState({
    hasPrevious: false,
    prevPage: 0,
    hasNext: true,
    next: 1,
    currentPage: 1,
    total: 12,
    pageSize: 10,
    lastPage: 0,
  });

  bus.on('reload', () => {
    fetchData();
  });

  const fetchData = async (
    param = {
      location: '',
      status: '',
      search: '',
      page: 1,
      perpage: 10,
      export_type: '',
    },
    file = null
  ) => {
    const params = queryString.stringify(param);

    if (file) {
      let queryParams = new URLSearchParams({
        ...params,
        export_type: file,
      }).toString();
      setExporting(true);

      try {
        await downloadFile2(
          'All stores',
          `brand/store/get-stores?${queryParams}`,
          file
        );
      } finally {
        // alert()
        setExporting(false);
      }
      return;
    }
    setIsPending(true);

    try {
      const res = await axiosInstance.get(`brand/store/get-stores?${params}`);
      setStoreData(res.data.data.data);
      setSalesPagination(res.data.data.pagination);

      setIsPending(false);
    } catch (err) {
      setIsPending(false);
      notifyError(err.response.data.message);
    }
  };
  const handleCsvFile = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
    // const selectedFile = e.target.files[0];
  };

  bus.on('open-csv', () => {
    // setExportFile('csv')
    setModalCSVOpen(true);
  });

  const handleSubmitCSV = () => {
    if (selectedFile) {
      setExportLoading(true);
      setModalCSVOpen(false);
      const formData = new FormData();
      formData.append('file', selectedFile);

      axiosInstance
        .post('/admin/stores/import-csv', formData, state, country)
        .then((res) => {
          if (res.status < 300) {
            notify(res.data.message);
            setStoreReload(!storeReload);
            // create file link in browser's memory
            const href = URL.createObjectURL(res.data);
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'CSV.csv');
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          }
        })
        .finally(() => {
          setExportLoading(false);
        });
    }
  };
  return (
    <>
      <div className=" mt-4 px-2">
        <div className="header d-block d-md-none mb-4">
          <div className="d-md-flex justify-content-between">
            <div className="mb-3">
              <h4 className={`mb-1 mb-md-0 pb-0`}>Stores</h4>

              <p style={{ fontSize: '14px' }} className={`mb-0 pb-0`}>
                An overview of all stores
              </p>
            </div>
            <div className="">
              <button className={`btn ${styles.import}`}>
                Import CSV File
              </button>
            </div>
          </div>
        </div>

        <div className={`${styles.analytics}  `}>
          <Filter1 width="100%" onChange={(val) => fetchAnalytics(val)} />
          <div className={`row`}>
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Added Stores</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>{analyticsData.stores_count.bold}</span>
                      <small> from {analyticsData.stores_count.small}</small>
                    </div>
                    <div>
                      {Number(analyticsData.stores_count.bold) >=
                      Number(analyticsData.stores_count.small) ? (
                        <div className={styles.percent}>
                          <img
                            src="/assets/icons/up.svg"
                            className=" "
                            alt=""
                          />
                          <small>
                            {analyticsData.stores_stock_count
                              ? Math.abs(
                                  analyticsData.stores_count.percentageDiff
                                )
                              : 0}
                            %
                          </small>
                        </div>
                      ) : (
                        <div className={`${styles.percent2}`}>
                          <img
                            src="/assets/icons/downr.svg"
                            className=" "
                            alt=""
                          />
                          <small>
                            {analyticsData.stores_stock_count
                              ? Math.abs(
                                  analyticsData.stores_count.percentageDiff
                                )
                              : 0}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Active Stores</small>
                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        {analyticsData.stores_count?.activeShopBold || 0}
                      </span>
                      <small>
                        {' '}
                        from {analyticsData.stores_count?.activeShopSmall || 0}
                      </small>
                    </div>
                    <div>
                      {Number(analyticsData.stores_count?.activeShopBold) >=
                      Number(analyticsData.stores_count?.activeShopSmall) ? (
                        <div className={styles.percent}>
                          <img
                            src="/assets/icons/up.svg"
                            className=" "
                            alt=""
                          />
                          <small>
                            {analyticsData.stores_count
                              ? Math.abs(
                                  analyticsData.stores_count
                                    ?.activeShopPercentageDiff
                                )
                              : 0}
                            %
                          </small>
                        </div>
                      ) : (
                        <div className={`${styles.percent2}`}>
                          <img
                            src="/assets/icons/downr.svg"
                            className=" "
                            alt=""
                          />
                          <small>
                            {analyticsData.stores_count
                              ? Math.abs(
                                  analyticsData.stores_count
                                    ?.activeShopPercentageDiff
                                )
                              : 0}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-md-3 col-6   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                placeholder="Search"
                name="search"
                value={search.search}
                onChange={processFilter}
                style={{ textIndent: '25px', width: '100%' }}
                className=" form-control"
              />
            </div>
            <div className="col-md-9 col-6  text-right">
              <button
                className="btn  border"
                onClick={() => setModalOpen(true)}
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px',
                }}
              >
                <img
                  className=""
                  style={{ display: 'inline', width: '17px' }}
                  src="/assets/icons/filterlines.svg"
                  alt=""
                />
                &nbsp;
                <small className="d-none d-md-inline-block">Filters</small>
              </button>
            </div>
          </div>
        </div>

        <Card
          style={{ marginTop: '50px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-3 ">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">Stores</h5>
                <small
                  style={{
                    fontWeight: '200',
                    fontSize: '12px',
                    display: 'inline-block',
                    marginTop: '0px',
                  }}
                >
                  Detailed information of all stores
                </small>
              </div>
              <div className="pt-md-2">
                <div className="dropleft d-inline-block">
                  <button
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`${styles.export_btn1} btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: '8px',
                      }}
                    >
                      <img src="/assets/icons/downloadblue.svg" alt="" /> &nbsp;
                      {!exporting && <span>Export</span>}
                      {exporting && (
                        <CircularProgress
                          style={{
                            color: 'white',
                            display: 'inline-block',
                          }}
                          size="1rem"
                        />
                      )}
                    </span>
                    &nbsp; &nbsp;
                    <span
                      style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <img src="/assets/icons/chevron-downblue.svg" alt="" />
                    </span>
                  </button>

                  <div
                    className="dropdown-menu drop-left"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      onClick={() =>
                        fetchData(
                          {
                            location: '',
                            status: '',
                            search: '',
                            page: currentPage - 1,
                            perpage: 200000,
                            export_type: '',
                          },
                          'csv'
                        )
                      }
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      CSV
                    </button>
                    <button
                      onClick={() =>
                        fetchData(
                          {
                            location: '',
                            status: '',
                            search: '',
                            page: currentPage - 1,
                            perpage: 200000,
                            export_type: '',
                          },
                          'excel'
                        )
                      }
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      Excel{' '}
                    </button>
                    <button
                      onClick={() =>
                        fetchData(
                          {
                            location: '',
                            status: '',
                            search: '',
                            page: currentPage - 1,
                            perpage: 200000,
                            export_type: '',
                          },
                          'pdf'
                        )
                      }
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      PDF{' '}
                    </button>
                  </div>
                </div>
                &nbsp; &nbsp; &nbsp;
                <button
                  onClick={() => handleOpen()}
                  className={`btn mt-2 mt-md-0 ${styles.add_outlet}`}
                >
                  <img src="/assets/icons/plus.svg" className="pr-1" alt="" />
                  Add New Store
                </button>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`pt-3 pb-3 pl-0 pr-0`}>
              <table
                className={`${styles.table} table-hover table-striped `}
                style={{ minWidth: '100em' }}
              >
                <thead className={`${styles.thead}`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      let sIndex = i < 1 ? 1 : false;
                      return (
                        <th key={i} className="text-muted px-4">
                          {el.label}{' '}
                          {sIndex && (
                            <span style={{ cursor: 'pointer' }}>
                              <img
                                className="pl-2"
                                src="/assets/icons/down.svg"
                                alt=""
                              />
                            </span>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {!isPending &&
                    storeData.length > 0 &&
                    storeData?.map((el, i) => {
                      return (
                        <tr key={i} className={`${styles.tr} `}>
                          <td component="th" scope="el">
                            <div className=" ">
                              <p className="mb-0" style={{ fontSize: '12px' }}>
                                <strong>{el?.store_name}</strong>
                              </p>
                              <small>{el?.shopID}</small>
                            </div>
                          </td>
                          <td>{el?.store_category}</td>
                          <td>{el?.store_manager}</td>
                          <td>
                            <p>
                              <BootstrapTooltip
                                title={
                                  <div>
                                    <span>{el?.store_email}</span>
                                  </div>
                                }
                              >
                                <span>
                                  {el?.store_email
                                    ? shortenText(el?.store_email)
                                    : '---'}
                                </span>
                              </BootstrapTooltip>
                            </p>
                          </td>
                          <td>{el?.store_phone_number}</td>
                          {/* <td>
                          <span
                            className={
                              el?.is_approved === "verified"
                                ? "verified_badge"
                                : "not_verified_badge"
                            }
                          >
                            {el?.is_approved === "verified" && (
                              <img
                                src="/assets/icons/check-circlel.svg"
                                alt=""
                              />
                            )}
                            &nbsp;
                            {el?.is_approved}
                          </span>
                        </td> */}
                          <td>
                            <span
                              className={
                                el?.is_approved
                                  ? 'verified_badge'
                                  : 'not_verified_badge'
                              }
                            >
                              {el?.is_approved ? (
                                <>
                                  <img
                                    src="/assets/icons/check-circlel.svg"
                                    alt=""
                                  />
                                  &nbsp; Verified
                                </>
                              ) : (
                                'Not Verified'
                              )}
                            </span>
                          </td>

                          <td>{shortenText(el?.store_address)}</td>
                          <td>
                            <span
                              className="dropleft px-4"
                              style={{
                                cursor: 'pointer',
                                zIndex: '999999999',
                              }}
                            >
                              <span
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                className={`${styles.action_button} px-3 py-2`}
                                style={{
                                  cursor: 'pointer',
                                }}
                              >
                                <img src="/assets/icons/action.svg" alt="" />
                              </span>

                              <div
                                className="dropdown-menu drop-left"
                                aria-labelledby="dropdownMenuButton"
                              >
                                {/* <button
                              type="button"
                              onClick={() => handleOpen(el)}
                              className="rss dropdown-item btn border-0"
                            >
                              Verify
                            </button> */}
                                <button
                                  type="button"
                                  onClick={() => handleOpen(el)}
                                  className="rss dropdown-item btn border-0"
                                >
                                  Edit
                                </button>
                                {/* <button
                                type="button"
                                onClick={() => handleClickOpen(el)}
                                className="rss dropdown-item btn border-0"
                              >
                                Delete
                              </button> */}
                              </div>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!storeData?.length && !isPending && (
              <div className="alert alert-[#af3585] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={pagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>

        {/* Modals */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={open}>
            <Box sx={style} className={`mobile_modal_size`}>
              <div className={`${styles.modal_content}`}>
                <div className={`${styles.modal_header} mb-0`}>
                  <div>
                    <h5
                      style={{
                        fontSize: '19px',
                        fontWeight: '700',
                      }}
                    >
                      {text.title}
                    </h5>
                    <p
                      style={{
                        fontSize: '11px',
                        color: '#71717A',
                      }}
                    >
                      {text.sub}
                    </p>
                  </div>
                  <div>
                    <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                      <img src="/assets/icons/x.svg" alt="" />
                    </span>
                  </div>
                </div>

                <form onSubmit={handleSubmit} className="mt-0 form_label">
                  <div className="mb-3">
                    <label htmlFor="name">Store Name*</label>
                    <TextField
                      required
                      type="text"
                      value={formValues.store_name}
                      name="store_name"
                      onChange={handleChange}
                      id="name"
                      placeholder="Store 1"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name"> Category</label>
                    <Select
                      labelId="s"
                      id="s"
                      fullWidth
                      size="small"
                      value={formValues.store_category}
                      name="store_category"
                      required
                      onChange={handleChange}
                    >
                      <MenuItem value="">Select Category</MenuItem>
                      <MenuItem value="Kiosk">Kiosk</MenuItem>
                      <MenuItem value="Neighborhood store">
                        Neighborhood store
                      </MenuItem>
                      <MenuItem value="Supermarket">Supermarket</MenuItem>
                      <MenuItem value="E-commerce store">
                        E-commerce store
                      </MenuItem>
                    </Select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="mname">Store Manager Name</label>
                    <TextField
                      required
                      value={formValues.store_manager}
                      name="store_manager"
                      onChange={handleChange}
                      type="text"
                      id="mname"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email">Email</label>
                    <TextField
                      required
                      type="email"
                      value={formValues.store_email}
                      name="store_email"
                      onChange={handleChange}
                      id="email"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone">Phone Number*</label>
                    <TextField
                      required
                      type="phone"
                      value={formValues.store_phone_number}
                      name="store_phone_number"
                      onChange={handleChange}
                      id="phone"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3">
                    <PlacesAutocomplete
                      value={formValues.store_address}
                      onChange={setAddr}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <div className="my-4 inputs_n_label">
                            <label htmlFor="loc">Address*</label>
                            <TextField
                              id="loc"
                              placeholder="Input your location"
                              size="small"
                              fullWidth
                              {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'location-search-input',
                              })}
                            />
                          </div>

                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, i) => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: '#fafafa',
                                    cursor: 'pointer',
                                  }
                                : {
                                    backgroundColor: '#ffffff',
                                    cursor: 'pointer',
                                  };
                              return (
                                <div
                                  key={i}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-1">
                      <label htmlFor="s">Country*</label>
                      <LiveSelect
                        defaultValue={selectedOption}
                        onChange={handleChange2}
                        options={countries?.map((option) => ({
                          value: option.name,
                          label: option.name,
                        }))}
                      />
                    </div>
                    <div className="col-md-6 mb-1">
                      <label htmlFor="state">State*</label>
                      &nbsp;
                      {loadingState && (
                        <CircularProgress
                          style={{ color: '#af3585' }}
                          size="1rem"
                        />
                      )}
                      <LiveSelect
                        defaultValue={selectedOption1}
                        onChange={handleChange3}
                        options={states?.map((option) => ({
                          value: option.name,
                          label: option.name,
                        }))}
                      />
                    </div>
                  </div>
                  <div className="mb-1 mt-4">
                    {!isPending && (
                      <button
                        type="submit"
                        className={`${styles.modal_btn} btn btn-block`}
                      >
                        Save
                      </button>
                    )}
                    {isPending && (
                      <button
                        disabled
                        className={`btn btn-block ${styles.modal_btn}`}
                      >
                        Submitting &nbsp;
                        {isPending && (
                          <CircularProgress
                            style={{ color: 'white' }}
                            size="1rem"
                          />
                        )}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </Box>
          </Fade>
        </Modal>

        <GlobalModal
          open={modalCSVOpen}
          onClose={handleClose}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="740px !important"
          overflow="auto"
          bgcolor="#FFFF"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          p="25px"
        >
          <div className={`${styles.modal_content}`}>
            <div className={`${styles.modal_header} mb-0`}>
              <h5
                style={{
                  fontSize: '19px',
                  fontWeight: '700',
                }}
              >
                Import CSV File
              </h5>
              <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                <img src="/assets/icons/x.svg" alt="" />
              </span>
            </div>

            <form action="" className="mt-0">
              <div className="row">
                <div className="col-md-12 mb-1">
                  <div className="mb-3">
                    <label htmlFor="loc">
                      Note: File is expected to come in the format below
                    </label>
                    <p>
                      Store Name, Store Category, Store Manager, Store Email,
                      Store Phone Number, Store Address, Country, State.
                    </p>
                  </div>
                  <input
                    className="d-block"
                    type="file"
                    name="file"
                    accept=".csv"
                    onChange={handleCsvFile}
                  />
                </div>
              </div>
              <div className="mb-4">
                <button
                  type="button"
                  onClick={handleSubmitCSV}
                  className={`${styles.modal_btn} btn btn-block mt-4`}
                >
                  Proceed{' '}
                  {exportLoading && (
                    <CircularProgress style={{ color: 'white' }} size="1rem" />
                  )}
                </button>
              </div>
            </form>
          </div>
        </GlobalModal>
        <GlobalModal
          open={modalOpen}
          onClose={handleClose2}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="740px !important"
          overflow="auto"
          bgcolor="#F5F5F5"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          p="25px"
        >
          <div className={`${styles.modal_content}`}>
            <div className={`${styles.modal_header} mb-0`}>
              <h5
                style={{
                  fontSize: '19px',
                  fontWeight: '700',
                }}
              >
                Filter
              </h5>
              <span onClick={handleClose2} style={{ cursor: 'pointer' }}>
                <img src="/assets/icons/x.svg" alt="" />
              </span>
            </div>

            <form action="" className="mt-0">
              <div className="row">
                <div className="col-md-6 mb-1">
                  <div className="mb-3">
                    <LiveLocation label="Location" passData={processLocation} />
                  </div>
                </div>
                <div className="col-md-6 mb-1">
                  <label htmlFor="cat">Category</label>
                  <Select
                    labelId="s"
                    id="cat"
                    fullWidth
                    size="small"
                    value={search.store_category}
                    name="store_category"
                    onChange={processFilter}
                  >
                    <MenuItem value="select">Select Category</MenuItem>
                    <MenuItem value="Kiosk">Kiosk</MenuItem>
                    <MenuItem value="Neighborhood store">
                      Neighborhood store
                    </MenuItem>
                    <MenuItem value="Supermarket">Supermarket</MenuItem>
                    <MenuItem value="E-commerce store">
                      E-commerce store
                    </MenuItem>
                  </Select>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="cat">Status</label>
                  <Select
                    labelId="s"
                    id="cat"
                    fullWidth
                    size="small"
                    value={search.status}
                    name="status"
                    onChange={processFilter}
                  >
                    <MenuItem value="">Select Status</MenuItem>
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="verified">Verified</MenuItem>
                    <MenuItem value="not-verified">Not Verified</MenuItem>
                  </Select>
                </div>
              </div>
              <div className="mb-4">
                <button
                  type="button"
                  onClick={handleClose2}
                  className={`${styles.modal_btn} btn btn-block`}
                >
                  Proceed
                </button>
              </div>
            </form>
          </div>
        </GlobalModal>
        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleDialogClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="card border-0">
            <div className="card-body border-0 ">
              <div className={`${styles.customized_row}`}>
                <div className="text-center">
                  <img
                    style={{ width: '40px' }}
                    src="/assets/icons/caution.svg"
                    alt=""
                  />
                </div>
                <div>
                  <h5>Delete {outlets.outlet_name} outlet</h5>
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#6B7280',
                    }}
                  >
                    Are you sure you want to delete this outlet? All your data
                    will be permanently removed from our servers forever. This
                    action cannot be undone.
                  </p>
                </div>
              </div>

              <DialogActions>
                <Button
                  className={`${styles.cancel_delete}`}
                  onClick={handleDialogClose}
                >
                  Cancel
                </Button>
                {!isDeleting && (
                  <Button
                    className={`${styles.delete_product}`}
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                )}

                {isDeleting && (
                  <button disabled className={`btn ${styles.delete_product}`}>
                    {isDeleting && (
                      <CircularProgress
                        style={{ color: 'white' }}
                        size="1rem"
                      />
                    )}
                  </button>
                )}
              </DialogActions>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}
