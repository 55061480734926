

// sections
// mock
import OrderDetails from "./OrderDetailsBrandOwner"
import MenuItem from '@mui/material/MenuItem';
import { useState } from "react"
import { useLayoutEffect } from "react";
import GlobalModal from "src/Modals/GlobalModal";
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MyOrder from './components/MyOrder';
import CustomerOrder from './components/CustomerOrder';
import { useAuth } from 'src/hooks/useAuth';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

function changeView(d){
  localStorage.setItem('ptvo',d);
}

// ptvo=PAGE TO VIEW (ORDER)
let app_view = localStorage.getItem('ptvo') ?? 0;
export default function OrderRetailerPage() {
  const {data} = useAuth();
  const [category, setCategory] = useState(1);
  const [value, setValue] = useState('1');


  const [appView, setAppview] = useState(app_view);
  const [orderType, setOrderType] = useState('All');

  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };



  useLayoutEffect(() => () => {
    localStorage.removeItem('ptvo')
  }, [])

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  
  
  function navigate(d) {
    changeView(d)
    setAppview(d) 
  }

  const handleViewClick = (d) => {
    navigate(d)
    // handle view click action
  };
  
  const handleChange = (/* args passed from the child component */) => {
    handleViewClick(0)
  }
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  

  return  Number(appView) === 0 ?  (
    <>
     <div className="wrapper mt-md-2  mt-4 px-2">
        <div   className="header d-block d-md-none mb-3">
            <h4 className={`mb-0 pb-0`}>{value == 1 ? 'My Orders' : ' Customer Orders'}</h4>
            
            {/* <p style={{fontSize: '14px'}} className={`mb-0 pb-0`}>
            Manage and monitor your orders here.
            </p> */}

        </div>
        {
          data.merchant_type === 'shop' && 
          <CustomerOrder />
        }
        {
          data.merchant_type !== 'shop' && (
          <TabContext value={value}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs ">
                  <Tab label="Customer Orders " value="1" />
                  {
                    data.merchant_type !== 'shop' && 
                      <Tab label="My Orders" value="2" />
                  }
              </TabList>
              <TabPanel value="2" style={{paddingLeft:'0'}}>
                  <MyOrder key={3}  viewChange={navigate} />
              </TabPanel>
              <TabPanel value="1" style={{paddingLeft:'0'}}>
                  <CustomerOrder key={4}  viewChange={navigate} />
              </TabPanel>
          </TabContext>
        )}
        

     </div>
     <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position='absolute'
        top= '50%'
        left= '50%'
        transform= 'translate(-50%, -50%)'
        width= '500px !important'
        overflow='auto'
        bgcolor='#F5F5F5'
        border= '1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow= '24'
        p= '4'
        key={2}
      >
        <div className={`modal_content`}>
          <div className={`modal_header`}>
            <h3>Choose  Category</h3>
            <span onClick={handleClose} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>
          

          <form action="">
          <div className="mb-3">
            <label htmlFor="name">Category Name</label>
            <Select
                labelId="s"
                id="s"
                fullWidth
                size="small"
                value={category}
                required
                onChange={handleCategoryChange}
              >
                
                <MenuItem value={1}>Breveges</MenuItem>
                <MenuItem value={2}>Table water</MenuItem>
                <MenuItem value={3}>Snacks</MenuItem>
            </Select>
            

            </div>
            <div className="mb-4">
            <button type="button" onClick={handleClose} className={`modal_btn btn btn-block`}>
              Add

            </button>
            
            
            </div>

          </form>


        </div>
       
       
      </GlobalModal>
    </>
  ) : <OrderDetails key={1} onChange={handleChange} />
}
